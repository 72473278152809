








import { Component, Prop } from 'vue-property-decorator'
import { QueryRuleSelectConfig } from '@/types/query-builder/rules'
import { RuleMixin } from './rule.mixin'

@Component
export default class SelectRule extends RuleMixin<string | number> {
  @Prop({ required: true }) rule!: QueryRuleSelectConfig
}
