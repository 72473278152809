import {
  CAMPAIGN_FILTERED_TABLE_VIEWS,
  CAMPAIGN_TABLE_VIEWS,
  SCORING_HAS_LOCATIONS,
  SCORING_NODES_WERE_UPDATED
} from '@/store/getter-types'
import { SET_CAMPAIGN_TABLE_VIEWS } from '@/store/mutation-types'
import { SAVE_TABLE_VIEW, REMOVE_TABLE_VIEW, UPDATE_TABLE_VIEW, SAVE_CAMPAIGN } from '@/store/action-types'
import { PlanningTableView, PlanningTableViews, UpdatedPlanningTableView } from '@/types/planning'
import { ObjectId } from '@/types/common'
import { isDynamicColumn } from '@/constants/unit/utils'
import { omit } from 'lodash'

const state = {
  tableViews: []
}

const getters = {
  [CAMPAIGN_TABLE_VIEWS] (state) {
    return state.tableViews
  },
  [CAMPAIGN_FILTERED_TABLE_VIEWS] (_state, getters) {
    const campaignWasUpdated = getters[SCORING_NODES_WERE_UPDATED]
    const campaignWasScored = getters[SCORING_HAS_LOCATIONS]

    if (!campaignWasUpdated || !campaignWasScored) {
      return getters[CAMPAIGN_TABLE_VIEWS]
    }

    return getters[CAMPAIGN_TABLE_VIEWS].map((view: PlanningTableView) => {
      const { name, settings } = view

      const updatedSettings = { ...settings }

      updatedSettings.columnState = updatedSettings.columnState.filter(state => !isDynamicColumn(state.colId as string))

      return {
        name,
        settings: updatedSettings
      }
    })
  }
}

const actions = {
  async [SAVE_TABLE_VIEW] ({ commit, getters, dispatch }, tableView: PlanningTableView) {
    const tableViews = getters[CAMPAIGN_TABLE_VIEWS]

    commit(SET_CAMPAIGN_TABLE_VIEWS, [...tableViews, tableView])

    await dispatch(SAVE_CAMPAIGN)
  },
  async [UPDATE_TABLE_VIEW] ({ commit, getters, dispatch }, tableView: UpdatedPlanningTableView) {
    const tableViews: PlanningTableViews = getters[CAMPAIGN_TABLE_VIEWS]
    const viewIndex = tableViews.findIndex(view => view.name === tableView.previousName)

    if (viewIndex > -1) {
      tableViews[viewIndex] = omit(tableView, 'previousName')
      commit(SET_CAMPAIGN_TABLE_VIEWS, tableViews)

      await dispatch(SAVE_CAMPAIGN)
    }
  },
  async [REMOVE_TABLE_VIEW] ({ commit, getters, dispatch }, tableViewName: ObjectId) {
    const tableViews: PlanningTableViews = getters[CAMPAIGN_TABLE_VIEWS].filter(view => view.name !== tableViewName)

    commit(SET_CAMPAIGN_TABLE_VIEWS, tableViews)

    await dispatch(SAVE_CAMPAIGN)
  }
}

const mutations = {
  [SET_CAMPAIGN_TABLE_VIEWS] (state, settings) {
    state.tableViews = settings
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
