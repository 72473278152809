import { LatLng, LatLngBounds } from 'leaflet'
import { VisualizationLayer } from '@/types/visualization/layer'
import { Geometries } from '@/types/common'
import {
  isCirclesLayer,
  isLinesLayer,
  isPointsLayer,
  isPolygonsLayer
} from '@/types/visualization/layer/guards'

type LatLngVisitor = {
  visitLatLng (point: LatLng): void;
  getBounds (): LatLngBounds;
}

function createVisitor (defaultBounds?: LatLngBounds): LatLngVisitor {
  let minLat = Infinity
  let maxLat = -Infinity
  let minLong = Infinity
  let maxLong = -Infinity

  const visitLatLng = (latLng: LatLng): void => {
    minLat = Math.min(minLat, latLng.lat)
    maxLat = Math.max(maxLat, latLng.lat)
    minLong = Math.min(minLong, latLng.lng)
    maxLong = Math.max(maxLong, latLng.lng)
  }

  const getBounds = (): LatLngBounds => {
    if (minLat === Infinity) {
      if (defaultBounds !== undefined) {
        return defaultBounds
      }

      return new LatLngBounds(DEFAULT_MAP_BOUNDS.northWest, DEFAULT_MAP_BOUNDS.southEast)
    }

    return new LatLngBounds([
      [minLat, minLong],
      [maxLat, maxLong]
    ])
  }

  return {
    visitLatLng,
    getBounds
  }
}

export function getBoundsForLayers (layers: VisualizationLayer[], defaultBounds?: LatLngBounds): LatLngBounds {
  const { getBounds, visitLatLng } = createVisitor(defaultBounds)

  for (const layer of layers) {
    if (isPointsLayer(layer)) {
      for (const point of layer.geometry) {
        visitLatLng(point.latLng)
      }
    }

    if (isCirclesLayer(layer)) {
      for (const center of layer.geometry) {
        const radius = center.meta[layer.style.radiusColumn]

        if (radius != null) {
          const circleBounds = center.latLng.toBounds(+radius)

          visitLatLng(circleBounds.getSouthWest())
          visitLatLng(circleBounds.getNorthEast())
        }
      }
    }

    if (isPolygonsLayer(layer)) {
      for (const polygon of layer.geometry) {
        polygon.paths[0].forEach(visitLatLng)
      }
    }

    if (isLinesLayer(layer)) {
      for (const line of layer.geometry) {
        line.paths.forEach(visitLatLng)
      }
    }
  }

  return getBounds()
}

export function getLayerBounds (layer: VisualizationLayer, defaultBounds?: LatLngBounds): LatLngBounds {
  return getBoundsForLayers([layer], defaultBounds)
}

export function getBoundForGeometries (geometries: Geometries, defaultBounds?: LatLngBounds) {
  const { getBounds, visitLatLng } = createVisitor(defaultBounds)

  geometries.forEach((geometry) => {
    (geometry.paths?.[0] ?? []).forEach(visitLatLng)
  })

  return getBounds()
}
