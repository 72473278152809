export const PRIMARY_FIELD = 'ExternalID'
export const CITY_FIELD = 'City'
export const SITE_TYPE_COUNT_FIELD = 'SiteTypeCount'
export const BOOKING_GRANULARITY_FIELD = 'BookingGranularity'
export const TIME_BLOCK_COLUMN = 'Block'
export const LOCATIONS_FIELD = 'Locations'
export const MEDIA_OWNER_COLUMN = 'MediaOwner'
export const MEDIA_OWNER_NAME_COLUMN = 'MediaName'
export const DAILY_PRICE_COLUMN = 'BasePrice'
export const UNITS_PER_RECORD_COLUMN = 'UnitsPerRecord'
export const TOTAL_PRICE_COLUMN = 'GesamtPreis'
export const POTENTIAL_PRICE_COLUMN = 'PotenzialPreis'
export const PRICE_SURROUNDING_COLUMN = 'PriceSurrounding'
export const UNIT_TYPE_COLUMN = 'UnitType'
export const POSITIONS_PER_SITE_COLUMN = 'Stellen'
export const POSITION_COLUMN = 'Position'
export const PATH_NAME_COLUMN = 'Pfadbezeichner'
export const POPULATION_COLUMN = 'Population'
export const PLZ5_POPULATION_COLUMN = 'PLZ5Population'
export const PLZ8_POPULATION_COLUMN = 'PLZ8Population'
export const MEDIA_TYPE_COLUMN = 'Stellenart'
export const MEDIA_SUB_TYPE_COLUMN = 'UnterStellenart'
export const BUILDTYPE_COLUMN = 'Bauart'
export const MARKETING_TEXT_COLUMN = 'MarketingText'
export const NETWORK_SIZE_COLUMN = 'NetworkSize'
export const NETWORK_SIZE_SURROUNDINGS = 'NetworkSizeSurrounding'
export const TIME_FRAME_COLUMNS = 'TIME_FRAME_COLUMNS'
export const AMOUNT_OF_WEEK_DECADES_COLUMNS = 'Buchungsdauer (Wochen/Dekaden)'
export const LATITUDE_COLUMN = 'Latitude'
export const LONGITUDE_COLUMN = 'Longitude'
export const MAIN_SITE_TYPES_COLUMN = 'MainSiteTypes'
export const ALL_OVER_POPULATION_COLUMN = 'AllOverPopulation'
export const LOCATION_DESCRIPTION_COLUMN = 'Bezeichnung'
export const NETWORK_DESCRIPTION_COLUMN = 'NetworkDescription'
export const NETWORK_TYPE_COLUMN = 'NetworkType'
export const SUBNET_COLUMN = 'Subnet'
export const ILLUMINATION_COLUMN = 'Illumination'
export const BANNED_PRODUCTS_COLUMN = 'BannedProducts'
export const DISTANCE_COLUMN = 'Entfernung'
export const QID_COLUMN = 'QID'
export const BID_COLUMN = 'BID'
export const OKZ_COLUMN = 'OKZ'
export const PLZ_COLUMN = 'PLZ5'
export const PLZ8_COLUMN = 'PLZ8'
export const NIELSEN_COLUMN = 'Nielsen'
export const BUNDESLAND_COLUMN = 'Bundesland'
export const LANDKREIS_COLUMN = 'Landkreis'
export const BEZIRK_COLUMN = 'Regierungsbezirk'
export const PPS_COLUMN = 'PPS'
export const GRP_COLUMN = 'GRP'
export const SDAW_NUMMER_COLUMN = 'SDAWNummer'
export const FOTO_LINK_COLUMN = 'FotoLink'
export const BOOKING_GRANULARITY_PRICE_FIELD = 'TotalPricePerPeriod'
export const ALLGEMEIN_STELLE_SELECTION_COLUMN = 'AllgemeinStelleSelection'
export const ALLGEMEIN_STELLE_COLUMN = 'AllgemeinStelle'
export const BOOKABLE_FROM_COLUMN = 'BookableFrom'
export const BOOKABLE_TO_COLUMN = 'BookableTo'
export const MAIN_LOCATION_POPULATION_COLUMN = 'MainLocationPopulation'
export const MAIN_SITE_TYPE_COLUMN = 'GeneralSiteType'
export const COUNTRY_COLUMN = 'Country'
export const NETWORK_NUMBER_COLUMN = 'NetworkNumber'
export const BRUTTO_PREIS_COLUMN = 'Bruttopreis'

export const mediaOwnerNetworkColumnNameTranslations = {
  [PRIMARY_FIELD]: 'Externe ID',
  [MEDIA_OWNER_COLUMN]: 'Pächter Nr.',
  [MEDIA_OWNER_NAME_COLUMN]: 'Pächter Name',
  [NETWORK_DESCRIPTION_COLUMN]: 'Netzwerk Beschreibung',
  [NETWORK_SIZE_COLUMN]: 'Netz Größe',
  [NETWORK_SIZE_SURROUNDINGS]: 'Netz Größe Umland',
  [CITY_FIELD]: 'Stadt',
  [ALL_OVER_POPULATION_COLUMN]: 'Ges. Einwohner Subnetze',
  [SUBNET_COLUMN]: 'Subnetz',
  [ILLUMINATION_COLUMN]: 'Beleuchtung',
  [DAILY_PRICE_COLUMN]: 'Brutto Tagespreis',
  [PRICE_SURROUNDING_COLUMN]: 'Bruttopreis Umland',
  [BANNED_PRODUCTS_COLUMN]: 'Produktsperren',
  [MAIN_SITE_TYPES_COLUMN]: 'Netz Stellenart',
  [SITE_TYPE_COUNT_FIELD]: 'Netzgröße pro Stellenart',
  [NIELSEN_COLUMN]: 'Nielsengebiet',
  [FOTO_LINK_COLUMN]: 'Foto link',
  [BOOKING_GRANULARITY_PRICE_FIELD]: 'Zeit Preis',
  [ALLGEMEIN_STELLE_SELECTION_COLUMN]: 'Format',
  [ALLGEMEIN_STELLE_COLUMN]: 'Format',
  [POTENTIAL_PRICE_COLUMN]: 'Potenzial Preis',
  [MAIN_LOCATION_POPULATION_COLUMN]: 'Einwohner',
  [MAIN_SITE_TYPE_COLUMN]: 'Netz Hauptstellenart',
  [COUNTRY_COLUMN]: 'Land',
  [POPULATION_COLUMN]: 'Einwohner',
  [PLZ5_POPULATION_COLUMN]: 'Einwohner (PLZ5)',
  [PLZ8_POPULATION_COLUMN]: 'Einwohner (PLZ8)',
  [BOOKING_GRANULARITY_FIELD]: 'Buchungsart',
  [MARKETING_TEXT_COLUMN]: 'Marketing Text',
  [NETWORK_TYPE_COLUMN]: 'Touchpoint',
  [NETWORK_NUMBER_COLUMN]: 'Netz Nr.',
  [LOCATIONS_FIELD]: 'Standorte',
  [UNIT_TYPE_COLUMN]: 'Stellenart',
  [LATITUDE_COLUMN]: 'Breitengrad',
  [LONGITUDE_COLUMN]: 'Längengrad',
  [UNITS_PER_RECORD_COLUMN]: 'Gesamtflächen',
  [TOTAL_PRICE_COLUMN]: 'Brutto Gesamtpreis',
  NetworkType1: 'Touchpoint 1',
  NetworkType2: 'Touchpoint 2',
  NetworkType3: 'Touchpoint 3'
}
