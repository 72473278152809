






















import { Component, Mixins } from 'vue-property-decorator'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { Validations } from '@/decorators'
import { required } from 'vuelidate/lib/validators'
import { isEmpty, uniq } from 'lodash'
import { DatasetRows } from '@/types/common'
import ImportNetworks from './import-networks.vue'
import ImportNetworksColumns from './import-networks-columns.vue'
import MatchRowsProcessing from '@/components/planning/wizard/filter-editor/ui-controls/components/match-rows-processing.vue'
import { MatchRowsHelper } from '@/utils/rows-matching-helper/match-rows-helper'
import { MatchDatasetMixin } from '@/components/planning/wizard/filter-editor/ui-controls/mixins/match-dataset.mixin'
import MatchDatasetModal from '@/components/shared/match-dataset-modal/match-dataset-modal.vue'

@Component({
  components: { MatchDatasetModal, ImportNetworksColumns, ImportNetworks, MatchRowsProcessing }
})
export default class ImportNetworksModal extends Mixins<MatchDatasetMixin>(MatchDatasetMixin) {
  private bidColumn: string | null = null

  @Validations()
  private validations (): RuleDecl {
    return {
      dataset: { required },
      bidColumn: { required }
    }
  }

  protected override resetOwnProperties (): void {
    this.bidColumn = null
  }

  protected override datasetHasEmptyValues (): boolean {
    const validationCallback = (row) => {
      return !row[this.bidColumn as string] || isEmpty(row[this.bidColumn as string].toString().trim())
    }

    return this.dataset?.datasetRows.some(validationCallback) as boolean
  }

  protected override runImportProcess () {
    const _helper = new MatchRowsHelper(this.datasetRows)

    const { foundValues, notFoundValues } = _helper.importNetworksFromDataset(this.dataset?.datasetRows as DatasetRows, this.bidColumn as string)

    if (!isEmpty(notFoundValues)) {
      const bids = notFoundValues.map(item => Object.values(item).join('-'))
      const message = this.$t('planning.filters.errors.networks-not-found', { bids: uniq(bids).join(', ') })
      this.$toastError(message as string)
    }

    this.$emit('import', foundValues)
    this.showModal = false
  }

  protected override showFormInvalidErrorMsg (): void {
    this.$toastWarning(this.$t('planning.filters.errors.import-dataset-error', { columns: 'City, OKZ' }) as string)
  }
}
