































import { PLAN_WIZARD_FILTER_BY_ID } from '@/store/getter-types'
import { areaFiltersDataPath, supportedGeometrysetsForMON } from '@/store/planning-wizard/media-owner-networks-store/constant'
import { Dataset, GeometryBase } from '@/types/common'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { GetFilterByIdGetter } from '@/types/store/planning-wizard'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { matchGeometryToNetworkLocations } from '@/utils/planning/media-owner-network/media-owner-networks'
import { LocationInfo } from '@/types/planning/scoring/locations'

@Component({
  computed: {
    ...mapGetters({
      getFilterById: PLAN_WIZARD_FILTER_BY_ID
    })
  }
})
export default class MissingNetworksFeedback extends Vue {
  @Prop({ default: () => [] }) private mediaOwnerNetworks!: Dataset
  @Prop({ default: () => null }) private filterNode!: ScoringFunctionNode

  // mapGetters
  private getFilterById!: GetFilterByIdGetter

  private get isFeedbackVisible () {
    return this.areasWithoutNetworks.length > 0
  }

  private get areasWithoutNetworks () {
    const areaFilter = this.getFilterById(this.filterNode.areaId as string) as ScoringFunctionNode
    const selectedAreas = get(areaFilter.scoringFunction.data, areaFiltersDataPath[areaFilter.scoringFunction.name], []) as GeometryBase[]

    return selectedAreas
      .filter(area => supportedGeometrysetsForMON.includes(area.parentGeometrySetId))
      .filter(area => this.mediaOwnerNetworks.datasetRows.every(row => !matchGeometryToNetworkLocations(area, row.Locations as LocationInfo[])))
  }
}
