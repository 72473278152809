
































import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import { ModalMixin } from '@/mixins/modal.mixin'
import { Component, Prop } from 'vue-property-decorator'
import { LegendValues } from '@/types/visualization/map-legend'

@Component({
  components: { OverlayScrollbars }
})
export default class LegendDetailsModal extends ModalMixin {
  @Prop({ required: true }) private readonly legendValues!: LegendValues
}
