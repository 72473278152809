import { RankingImportWizardState } from '@/store/ranking-import-wizard/index'
import { Dataset } from '@/types/common'
import { QueryGroup } from '@/types/query-builder'

export const RANKING_IMPORT_WIZARD_SET_DATASET = 'ranking-import/setDataset'
export const RANKING_IMPORT_WIZARD_SET_QUERY_DATA = 'ranking-import/setQueryData'
export const RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_PLZ_COLUMN = 'ranking-import/key-columns-step/setCoordinateColumns'
export const RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_RATING_COLUMN = 'ranking-import/key-columns-step/setNameColumn'

export const mutations = {
  [RANKING_IMPORT_WIZARD_SET_DATASET] (state: RankingImportWizardState, dataset: Dataset | null) {
    state.sourceDataset = dataset
  },
  [RANKING_IMPORT_WIZARD_SET_QUERY_DATA] (state: RankingImportWizardState, query: QueryGroup) {
    state.query = query
  },
  [RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_PLZ_COLUMN] (state: RankingImportWizardState, column: string) {
    state.plzColumn = column
  },
  [RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_RATING_COLUMN] (state: RankingImportWizardState, column: string) {
    state.ratingColumn = column
  }
}
