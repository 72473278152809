import { BorderedGeometryLayer, VisualizationLayer } from '@/types/visualization/layer'
import { isFillAwareStyle, isLinesLayer } from '@/types/visualization/layer/guards'

export function getMainLayerStyleProp (layer: VisualizationLayer) {
  if (isLinesLayer(layer)) {
    return 'path'
  }

  return 'fill'
}

export function getMainLayerFillOptions (layer: BorderedGeometryLayer, customStyle: BorderedGeometryLayer['style'] | null = null) {
  const sourceStyle = customStyle !== null ? customStyle : layer.style

  if (isFillAwareStyle(sourceStyle)) {
    return sourceStyle.fill
  }

  return sourceStyle.path
}
