import { DropdownSelectorOption } from '@/components/shared/dropdown-selector/types/dropdown-selector'
import { Properties } from 'csstype'

function getFontStyle (value: number): Properties {
  return { fontSize: value + 'px' }
}

export const DEFAULT_POLYGON_LABEL_FONT_COLOR = '#0000ff'
export const DEFAULT_POLYGON_LABEL_STROKE_COLOR = '#ffffff'
export const DEFAULT_POLYGON_LABEL_STROKE_WIDTH = 4.0
export const DEFAULT_POLYGON_LABEL_FONT_SIZE = 12

export const LABEL_SIZE_OPTIONS: DropdownSelectorOption[] = [
  {
    text: 'Small',
    value: 10,
    get style () {
      return getFontStyle(this.value)
    }
  },
  {
    text: 'Normal',
    value: DEFAULT_POLYGON_LABEL_FONT_SIZE,
    get style () {
      return getFontStyle(this.value)
    }
  },
  {
    text: 'Large',
    value: 16,
    get style () {
      return getFontStyle(this.value)
    }
  }
]

export const LABEL_STROKE_WIDTH = [0, 1, 2, 3, 4]
export const MAX_COLUMNS_IN_LABEL = 3
