import { AclRule } from '@/types/auth'
import { hasAnyRole } from '@/acl/utils/rule-builders'
import { AuthRole } from '@/types/auth/roles'
import { AuthPermission } from '@/types/auth/permissions'

const canCreateDataset: AclRule = hasAnyRole(AuthRole.Planning)

const canViewDataset: AclRule = hasAnyRole(AuthRole.Planning)

const canEditDataset: AclRule = hasAnyRole(AuthRole.Planning)

export const analyticsRules = {
  [AuthPermission.CreateDataset]: canCreateDataset,
  [AuthPermission.ViewDataset]: canViewDataset,
  [AuthPermission.EditDataset]: canEditDataset
}
