import ClusteringAwareMixin from './clustering-aware.mixin'
import { Component } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { LayerGeometry } from '@/types/visualization/layer/geometry'
import { Color } from 'csstype'
import { LayerColorGetter, SubLayerConfig } from '@/types/visualization/layer/style'

@Component
export default class ClusteringPathMixin<TLayer extends BorderedGeometryLayer> extends ClusteringAwareMixin<TLayer> {
  protected get clusteringPathDataRows (): LayerGeometry[] {
    const col = this.layer.style.path.gradientColumn

    return this.getClusteringDataRows(col, this.layer.style.path as SubLayerConfig)
  }

  protected get clusteringPathColorFunc (): LayerColorGetter<LayerGeometry> {
    const gradientColumn = this.layer.style.path.gradientColumn

    if (!gradientColumn) {
      return () => null
    }

    return row => this.getClusteringPathColor(row.meta[gradientColumn] as string)
  }

  protected get clusteringPathUniqueValues () {
    return this.getUniqueClusteringValues(this.layer.style.path.gradientColumn)
  }

  protected get quantityOfPathValuesForCluster () {
    return this.calculateQuantityOfValuesForCluster(this.clusteringPathUniqueValues, this.layer.style.path.gradientColumn)
  }

  protected getClusteringPathColor (clusterKey: string): Color {
    return this.calculateClusteringColor(this.layer.style.path as SubLayerConfig, clusterKey, this.clusteringPathUniqueValues)
  }
}
