












import { SourceSelectorMode } from '@/types/enums'
import { Component, Model, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SourceSwitcher extends Vue {
  @Model('change', { required: true }) readonly value!: string
  @Prop({ default: '' }) readonly allLabel!: string
  @Prop({ default: '' }) readonly selectedLabel!: string

  private get options () {
    return [
      { text: this.allLabel, value: SourceSelectorMode.All },
      { text: this.selectedLabel, value: SourceSelectorMode.Selected }
    ]
  }
}
