import { ApiResponse } from '@/api/types'
import { VisualizationObject } from '@/types/visualization'
import { createTransformResponse } from '@/api/utils'

export const transformVisualizationResponse = createTransformResponse<VisualizationObject | ApiResponse['data']>((response: ApiResponse['data']): VisualizationObject | ApiResponse['data'] => {
  const visualization = JSON.parse(response)

  visualization.savedLocations = JSON.parse(visualization.savedLocations) ?? []

  visualization.layers = (visualization.layers || []).map(layer => {
    // Visualization style comes as a JSON encoded string
    // and we need to decode object from this string.
    layer.style = layer.style ? JSON.parse(layer.style) : null
    layer.settings = layer.settings ? JSON.parse(layer.settings) : null

    return layer
  })

  return visualization
})
