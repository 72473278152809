

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ObjectTag } from '@/types/common'
import { VueClassDef } from '@/types/vue'

@Component
export default class Tags extends Vue {
  @Prop({ required: true }) readonly tags!: ObjectTag[]
  @Prop({ default: false }) readonly noWrap!: boolean
  @Prop({ default: false }) readonly reverse!: boolean
  @Prop({ default: 'secondary' }) readonly variant!: string

  private get containerClass (): VueClassDef {
    return {
      'flex-wrap': !this.noWrap,
      'flex-row-m-1': !this.reverse,
      'flex-row-reverse': this.reverse,
      'flex-row-reverse-m-1': this.reverse
    }
  }
}
