import {
  WIZARD_TAB_ACTIVATE,
  WIZARD_TAB_GO_NEXT,
  WIZARD_TAB_GO_PREV
} from '@/store/action-types'
import { DynamicStoreMixin } from '@/mixins/dynamic-store.mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class WizardStepsNavigatorMixin extends Mixins<DynamicStoreMixin>(DynamicStoreMixin) {
  protected goPrev (): Promise<unknown> {
    return this.dispatchDynamicStoreAction(WIZARD_TAB_GO_PREV)
  }

  protected goNext (): Promise<unknown> {
    return this.dispatchDynamicStoreAction(WIZARD_TAB_GO_NEXT)
  }

  protected activateStep (stepNumber: number): Promise<unknown> {
    return this.dispatchDynamicStoreAction(WIZARD_TAB_ACTIVATE, stepNumber)
  }

  protected moveNext (): Promise<unknown> {
    return this.goNext()
      .catch(errors => this.showStepErrors(errors))
  }

  protected moveBack (): Promise<unknown> {
    return this.goPrev()
      .catch(errors => this.showStepErrors(errors))
  }

  protected navigateToStep (stepNumber): Promise<unknown> {
    return this.activateStep(stepNumber)
      .catch(errors => this.showStepErrors(errors))
  }

  protected showStepErrors (errors): void {
    errors.forEach(err => {
      const errorMsg = err || this.$t('planning.wizard.errors.validation-error-default')
      this.$toastError(errorMsg)
    })
  }
}
