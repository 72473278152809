import { Component, Vue } from 'vue-property-decorator'
import axios, { CancelTokenSource } from 'axios'
import { canceled, geocoding, GeocodingResults } from '@/utils/geocoding'
import { GeocodingState } from '../types/enum'
import { GeocodingDetails } from '@/components/shared/coordinates-service/types'

let geocodingCancelToken: CancelTokenSource | null = null

@Component
export class AddressesGeocodingMixin extends Vue {
  totalAddressesCount = 0
  successAddressesCount = 0
  errorAddressesCount = 0
  geocodingState: GeocodingState | null = null
  geocodingDetails: GeocodingDetails[] = []

  get geocodingInProgress (): boolean {
    return this.geocodingState === GeocodingState.inProgress
  }

  get geocodingInitialized (): boolean {
    return this.geocodingState !== null
  }

  get geocodingFinished (): boolean {
    return this.geocodingState === GeocodingState.finished
  }

  resetGeocode (): void {
    geocodingCancelToken = null
    this.geocodingState = null
    this.totalAddressesCount = 0
    this.successAddressesCount = 0
    this.errorAddressesCount = 0
    this.geocodingDetails = []
    this.$emit('reset')
  }

  cancelGeocode (): void {
    geocodingCancelToken && geocodingCancelToken.cancel()
    this.resetGeocode()
  }

  geocodeAddresses (addresses): Promise<GeocodingDetails[]> {
    this.totalAddressesCount = addresses.length
    this.successAddressesCount = 0
    this.errorAddressesCount = 0
    this.geocodingState = GeocodingState.inProgress

    geocodingCancelToken = axios.CancelToken.source()

    return geocoding(
      addresses,
      (geocodingDetails: GeocodingDetails) => {
        this.geocodingDetails.push(geocodingDetails)
        this.successAddressesCount++
      },
      (geocodingDetails: GeocodingDetails) => {
        this.geocodingDetails.push(geocodingDetails)
        this.errorAddressesCount++
      },
      geocodingCancelToken
    ).then((results: GeocodingResults) => {
      if (results.some(result => result === canceled)) {
        throw Error
      }

      this.geocodingState = GeocodingState.finished
      return (results as GeocodingDetails[])
        .filter(item => item !== null)
    }).catch(error => {
      this.totalAddressesCount = 0
      this.successAddressesCount = 0
      this.errorAddressesCount = 0
      this.geocodingDetails = []
      this.geocodingState = null
      return Promise.reject(error)
    })
  }
}
