import { FilterSelectConfig, SelectOption } from '@/types/planning/wizard/filter-editor'
import { SelectionOptionsConfig } from '@/utils/filter-editor/value-config/select/selection-options-config'
import { i18n } from '@/locales/i18n'

export class IlluminationTypeConfig extends SelectionOptionsConfig<string> implements FilterSelectConfig<string> {
  protected override _defValue = 'true'

  override get options (): SelectOption[] {
    return [
      { value: 'true', get text () { return i18n.t('planning.filters.labels.illumination.with-illumination') as string } },
      { value: 'false', get text () { return i18n.t('planning.filters.labels.illumination.without-illumination') as string } }
    ]
  }
}
