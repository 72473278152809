


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueClassDef } from '@/types/vue'
import img404 from '@/assets/img/image_404.jpg'
import LogoSpinner from '@/components/shared/logo-spinner.vue'

enum ImageState {
  Loading = 0,
  Loaded = 1,
  Error = 2,
}

@Component({
  components: { LogoSpinner }
})
export default class AdvImage extends Vue {
  @Prop({ required: true }) readonly src!: string
  @Prop({ default: img404 }) readonly errorImage!: string
  @Prop({ default: '' }) readonly imageClass!: VueClassDef

  private state = ImageState.Loading

  private get loading (): boolean {
    return this.state === ImageState.Loading
  }

  private get computedSrc (): string {
    if (this.state === ImageState.Loaded) {
      return this.src
    }

    return this.errorImage
  }

  @Watch('src', { immediate: true })
  private onSrcChange (newSrc: string): void {
    this.state = ImageState.Loading

    const img = new Image()

    img.onload = () => (this.state = ImageState.Loaded)
    img.onerror = () => (this.state = ImageState.Error)

    img.src = newSrc
  }
}
