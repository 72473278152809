













import { Vue, Component, Prop } from 'vue-property-decorator'
import LogoSpinner from '@/components/shared/logo-spinner.vue'

@Component({
  components: { LogoSpinner }
})
export default class LogoOverlay extends Vue {
  @Prop({ required: true }) readonly isShown!: boolean
}
