import { constantsApi } from '@/api/rest/constants/constants.api'
import { BANNED_PRODUCTS, BANNED_PRODUCTS_INITIALIZED } from '@/store/getter-types'
import { SET_BANNED_PRODUCTS } from '@/store/mutation-types'
import { LOAD_BANNED_PRODUCTS } from '@/store/action-types'

const state = {
  initialized: false,
  collection: []
}

// getters
const getters = {
  [BANNED_PRODUCTS] (state) {
    return state.collection
  },
  [BANNED_PRODUCTS_INITIALIZED] (state) {
    return state.initialized
  }
}

// actions
const actions = {
  [LOAD_BANNED_PRODUCTS] ({ commit, getters }) {
    if (getters[BANNED_PRODUCTS_INITIALIZED]) {
      return Promise.resolve(getters[BANNED_PRODUCTS])
    } else {
      return constantsApi.getBannedProducts()
        .then(collection => {
          commit(SET_BANNED_PRODUCTS, collection)

          return getters[BANNED_PRODUCTS]
        })
    }
  }
}

// mutations
const mutations = {
  [SET_BANNED_PRODUCTS] (state, payload) {
    if (Array.isArray(payload)) {
      state.collection = payload
      state.initialized = true
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
