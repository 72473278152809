import { LocaleMessages } from 'vue-i18n'

export default {
  en: {
    'run-wizard': 'Choose the ranking data set'
  },
  de: {
    'run-wizard': 'Ranking-Datensatz auswählen'
  }
} as LocaleMessages
