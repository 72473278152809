










import LegendValuesMixin from '@/components/visualization/legend/mixins/legend-values.mixin'
import LegendSublayerMixin from '@/components/visualization/legend/mixins/legend-sublayer.mixin'
import { MAX_VISIBLE_LEGEND_VALUES } from '@/components/visualization/legend/constants'
import DraggableLegendValues from '../draggable-legend-values.vue'
import LayerTypeEditorMixin from '../layer-types/layer-type-editor.mixin'
import { Component, Mixins } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { LegendValues } from '@/types/visualization/map-legend'

@Component({
  components: { DraggableLegendValues }
})
export default class ClusteringLegendEditor extends
  Mixins<LegendValuesMixin<BorderedGeometryLayer>, LayerTypeEditorMixin, LegendSublayerMixin>(LegendValuesMixin, LayerTypeEditorMixin, LegendSublayerMixin) {
  private get visibleLegendValues (): LegendValues {
    return this.legendValues.slice(0, MAX_VISIBLE_LEGEND_VALUES)
  }
}
