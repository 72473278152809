import { ApiRequestConfig } from '@/api/types'
import { ApiPersistenceObject, ObjectId } from '@/types/common'
import { ApiClient } from '@/api/api-client'
import { transformVisualizationRequestBody as transformRequest } from './transform-request/visualization-request.transform'
import { transformVisualizationResponse as transformResponse } from './transform-response/visualization-response.transform'

class VisualizationApi extends ApiClient {
  getVisualizations (): Promise<ApiPersistenceObject[]> {
    return this.searchVisualizations('')
  }

  searchVisualizations (query: string): Promise<ApiPersistenceObject[]> {
    return this.post(`search/*${query}*`)
  }

  getVisualization (id: ObjectId): Promise<ApiPersistenceObject[]> {
    const config: ApiRequestConfig = {
      transformResponse,
      blockingRequest: true
    }

    return this.get(id, config)
  }

  createVisualization (data): Promise<void> {
    const config: ApiRequestConfig = {
      transformRequest,
      transformResponse,
      blockingRequest: true
    }

    return this.post('', data, config)
  }

  updateVisualization (data): Promise<void> {
    const config: ApiRequestConfig = {
      transformRequest,
      blockingRequest: true
    }

    return this.put('', data, config)
  }

  deleteVisualization (id: ObjectId): Promise<void> {
    return this.delete(id)
  }
}

export const visualizationApi = new VisualizationApi({
  baseURL: API_CONF.api + '/api/Persistence/Visualization/'
})
