import Vue from 'vue'
import { nanoid } from 'nanoid'
import { maxBy, merge, sortBy } from 'lodash'
import {
  ADD_VISUALIZATION_LAYER,
  ADD_VISUALIZATION_SLIDE,
  REMOVE_VISUALIZATION_LAYER,
  REMOVE_VISUALIZATION_SLIDE,
  REPLACE_VISUALIZATION_LAYER,
  SET_VISUALIZATION_CREATED_BY,
  SET_VISUALIZATION_ID,
  SET_VISUALIZATION_LAYER_GEOMETRIES,
  SET_VISUALIZATION_LAYER_NAME,
  SET_VISUALIZATION_LAYER_STYLE,
  SET_VISUALIZATION_LAYERS,
  SET_VISUALIZATION_LINKED_PLANNING,
  SET_VISUALIZATION_LINKED_PLANNING_FUNCTIONS,
  SET_VISUALIZATION_NAME,
  SET_VISUALIZATION_REPORTS,
  SET_VISUALIZATION_SLIDES,
  SET_VISUALIZATION_UPDATED_STATE,
  SET_VISUALIZATION_VALIDATED_STATE,
  UPDATE_VISUALIZATION_LAYER_STYLE,
  UPDATE_VISUALIZATION_RANGE_QUERY
} from '@/store/mutation-types'
import { freezeRecursive } from '@/utils/object'
import { applyStyleChanges } from '@/components/visualization/layer-settings/utils'

function transformLayer (layer) {
  freezeRecursive(layer.geometry)

  return layer
}

const mutations = {
  [SET_VISUALIZATION_ID] (state, id) {
    state.id = id
  },
  [SET_VISUALIZATION_NAME] (state, name) {
    state.name = name
  },
  [SET_VISUALIZATION_CREATED_BY] (state, userName) {
    state.createdBy = userName
  },
  [SET_VISUALIZATION_LAYERS] (state, layers) {
    // Normalize z-index values from 1 to n.
    sortBy(layers, layer => layer.style.zIndex)
      .forEach((layer, index) => {
        layer.style.zIndex = index + 1
        transformLayer(layer)
      })

    state.layers = layers
  },
  [SET_VISUALIZATION_LINKED_PLANNING] (state, linkedPlanning) {
    state.linkedPlanningIDs = linkedPlanning || []
  },
  [SET_VISUALIZATION_LINKED_PLANNING_FUNCTIONS] (state, linkedPlanningFunctions) {
    state.linkedPlanningFunctions = linkedPlanningFunctions || []
  },
  [SET_VISUALIZATION_REPORTS] (state, reports) {
    state.reports = reports
  },
  [ADD_VISUALIZATION_LAYER] (state, layer) {
    const maxZIndexLayer = maxBy(state.layers, layer => layer.style.zIndex)

    layer.id = nanoid()
    layer.style.zIndex = maxZIndexLayer ? maxZIndexLayer.style.zIndex + 1 : 1

    state.layers.push(transformLayer(layer))
  },
  [REMOVE_VISUALIZATION_LAYER] (state, layer) {
    const layerIdx = state.layers.indexOf(layer)
    layerIdx === -1 || state.layers.splice(layerIdx, 1)
  },
  [REPLACE_VISUALIZATION_LAYER] (state, layer) {
    const replacementIndex = state.layers.findIndex(stateLayer => stateLayer.id === layer.id)
    replacementIndex === -1 || state.layers.splice(replacementIndex, 1, transformLayer(layer))
  },
  [ADD_VISUALIZATION_SLIDE] (state, slide) {
    state.slides.push(merge(slide, { id: nanoid(6) }))
  },
  [SET_VISUALIZATION_SLIDES] (state, slides) {
    state.slides = slides
  },
  [REMOVE_VISUALIZATION_SLIDE] (state, slide) {
    const slideIdx = state.slides.findIndex(item => slide === item)
    slideIdx === -1 || state.slides.splice(slideIdx, 1)
  },
  [SET_VISUALIZATION_LAYER_NAME] (state, { layer, name }) {
    Vue.set(layer, 'name', name)
  },
  [SET_VISUALIZATION_VALIDATED_STATE] (state, flag) {
    state.wasValidated = flag
  },
  [SET_VISUALIZATION_UPDATED_STATE] (state, flag) {
    state.wasUpdated = flag
  },
  [SET_VISUALIZATION_LAYER_STYLE] (state, { layer, style }) {
    Vue.set(layer, 'style', style)
  },
  [UPDATE_VISUALIZATION_LAYER_STYLE] (state, { layer, style }) {
    if (layer.style === null) {
      Vue.set(layer, 'style', {})
    }

    applyStyleChanges(layer.style, style)
  },
  [SET_VISUALIZATION_LAYER_GEOMETRIES] (state, { layer, geometries }) {
    Vue.set(layer, 'geometry', geometries)
  },
  [UPDATE_VISUALIZATION_RANGE_QUERY] (state, { layer, query }) {
    layer.settings.query = query
  }
}

export default mutations
