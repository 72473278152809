import setDatasetStep from './steps/set-dataset-step.store'
import queryDatasetStep from './steps/query-dataset-step.store'
import parseDatasetStep from './steps/parse-dataset-step.store'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    setDatasetStep,
    queryDatasetStep,
    parseDatasetStep
  }
}
