import { AnalyticsTag } from '@/modules/analytics/analytics-tags-editor/domain-objects/AnalyticsTag'
import { IsTagAlreadyAddedGetter, TagsEditorStore, TagValuesOptionsGetter } from '@/modules/analytics/analytics-tags-editor/_store/tags-editor/types'
import { TagsByCategoryGetter } from '@/modules/analytics/analytics-tags-editor/_store/analytics-tags-cache/types'
import { GET_TAGS_BY_CATEGORY, GET_TAGS_CATEGORIES } from '@/modules/analytics/analytics-tags-editor/_store/analytics-tags-cache/analytics-tags-cache.getters'
import { SelectOptionObject } from '@/types/bootstrap'
import { sortBy } from 'lodash'
import { ObjectTag } from '@/types/common'

export const TAGS_EDITOR_USER_TAGS = 'tagsEditor/getters/localTags'
export const TAGS_EDITOR_GET_OBJECT_TAGS = 'tagsEditor/getters/objectTags'
export const TAGS_EDITOR_TAGS_CATEGORIES_OPTIONS = 'tagsEditor/getters/tagsCategoriesOptions'
export const TAGS_EDITOR_GET_TAGS_OPTIONS_BY_CATEGORY = 'tagsEditor/getters/getTagsOptionsByCategory'
export const TAGS_EDITOR_IS_TAG_ALREADY_ADDED = 'tagsEditor/getters/getTagIsAddedAlready'

export const getters = {
  [TAGS_EDITOR_TAGS_CATEGORIES_OPTIONS] (_state: TagsEditorStore, getters): SelectOptionObject[] {
    const categories: string[] = getters[GET_TAGS_CATEGORIES]

    return categories
      .sort()
      .map(cat => ({ value: cat, text: cat }))
  },

  [TAGS_EDITOR_GET_TAGS_OPTIONS_BY_CATEGORY] (_state: TagsEditorStore, getters): TagValuesOptionsGetter {
    return (category: string) => {
      const tagsByCategoryGetter: TagsByCategoryGetter = getters[GET_TAGS_BY_CATEGORY]
      const tagsByCategory = tagsByCategoryGetter(category)

      return sortBy(tagsByCategory, [(tag: AnalyticsTag) => tag.displayValue.toLowerCase()])
        .map(tag => (
          {
            value: tag.displayValue,
            text: tag.displayValue,
            disabled: getters[TAGS_EDITOR_IS_TAG_ALREADY_ADDED](tag)
          }
        ))
    }
  },

  [TAGS_EDITOR_IS_TAG_ALREADY_ADDED] (state: TagsEditorStore): IsTagAlreadyAddedGetter {
    return (tag: AnalyticsTag) => state.userTags.some(userTag => userTag.compare(tag))
  },

  [TAGS_EDITOR_USER_TAGS] (state: TagsEditorStore): AnalyticsTag[] {
    return state.userTags
  },

  [TAGS_EDITOR_GET_OBJECT_TAGS] (state: TagsEditorStore): ObjectTag[] {
    return state.userTags.map(tag => tag.toObjectTag())
  }
}
