import { FilterFunction } from '@/types/query-builder'
import { GroupOperator } from '@/types/query-builder/enums'

type GroupFilterFunction = (children: FilterFunction[]) => FilterFunction
type GroupLogicImplementation = { [key in GroupOperator]: GroupFilterFunction }

export const GroupLogicImpl: GroupLogicImplementation = {
  [GroupOperator.AND]: function (array: FilterFunction[]): FilterFunction {
    return (row) => array.every((func) => func(row))
  },
  [GroupOperator.OR]: function (array: FilterFunction[]): FilterFunction {
    return (row) => array.some((func) => func(row))
  }
}
