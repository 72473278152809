import { ReportsList } from '@/types/report'
import { ReportVisibility } from '@/types/report-enums'
import { AuthRole } from '@/types/auth/roles'

function getRoleForVisibility (visibility) {
  switch (visibility) {
    case ReportVisibility.User:
      return AuthRole.User
    case ReportVisibility.Developer:
      return AuthRole.Developer
    default:
      return AuthRole.User
  }
}

export function filterReportByVisibility (reports: ReportsList, roles: Array<string>): ReportsList {
  return reports.filter(report => {
    const roleForVisibility = getRoleForVisibility(report.visibility)
    return roles.includes(roleForVisibility)
  })
}
