






















































import { Component, Mixins, Ref } from 'vue-property-decorator'
import { FilterUIComponent, TagCollectionItem } from '@/types/planning/wizard/filter-editor'
import TagsAutocomplete from '@/directives/tags-autocomplete'
import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import { TagsUiMixin } from '../mixins/tags-ui.mixin'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'

@Component({
  components: { FilterParamName, OverlayScrollbars },
  directives: { TagsAutocomplete }
})
export default class SiteTypesUIElement extends Mixins<TagsUiMixin>(TagsUiMixin) implements FilterUIComponent<string[]> {
  @Ref('filterParamName') filterParamNameRef!: FilterParamName

  override get autocompleteItems (): TagCollectionItem[] {
    const searchString = this.tag.toLowerCase()

    return this.options.filter(i => {
      return i.text && i.text.toLowerCase().indexOf(searchString) !== -1
    })
  }

  override tagsChanged (newTags: TagCollectionItem[]): void {
    this.tags = this.getTagsWithClassName(newTags)
  }

  override initTags (tagsValue: string[]): void {
    if (this.settings.autocomplete) {
      const tags = this.options.filter(option => (option.value && tagsValue.indexOf(option.value) > -1))
      this.tags = this.getTagsWithClassName(tags)
      // This change is not from user interaction so reset validation state.
      this.$v.selected.$reset()
    }
  }

  getTagsWithClassName (newTags: TagCollectionItem[]): TagCollectionItem[] {
    const selectedParentTags = newTags.filter(tag => !tag.text?.includes(' '))

    return newTags
      .map(tag => {
        let classes = tag.text?.includes(' ') ? 'child' : 'parent'

        if (selectedParentTags.some(p => tag.text.indexOf(p.text) === 0)) {
          classes += ' parent-selected'
        }

        return {
          ...tag,
          classes: classes
        }
      })
  }

  getItemClass (item: TagCollectionItem) {
    return this.isSubType(item) ? 'pl-4' : 'pl-1'
  }

  isSubType (item: TagCollectionItem) {
    return item.value?.indexOf(' ') !== -1
  }

  isDisabled (item: TagCollectionItem) {
    return this.tags.some(p => item.text.indexOf(p.text) === 0)
  }
}
