import L from 'leaflet'
import { MultiPolygon } from '../layer/multi-polygons/multi-polygon/multi-polygon'

L.MarkerClusterGroup.include({
  _showCoverage: function (e) {
    const map = this._map
    if (this._inZoomAnimation) {
      return
    }
    if (this._shownPolygon) {
      map.removeLayer(this._shownPolygon)
    }
    if (e.layer.getChildCount() > 2 && e.layer !== this._spiderfied) {
      this._shownPolygon = new MultiPolygon([[e.layer.getConvexHull()]], this.options.polygonOptions)
      map.addLayer(this._shownPolygon)
    }
  }
})
