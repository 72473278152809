




























import VStyle from '@/components/shared/v-style.vue'
import { ResizeObserver } from 'vue-resize'
import WizardStepLink from './wizard-step-link.vue'
import { DynamicStoreNamespaceMixin } from '@/mixins/dynamic-store/dynamic-store-namespace.mixin'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { WizardSteps } from '@/types/wizard'
import { nanoid } from 'nanoid'
// import { NodeListOf } from 'typescript-dom'

@Component({
  components: { WizardStepLink, ResizeObserver, VStyle }
})
export default class WizardStepper extends Mixins<DynamicStoreNamespaceMixin>(DynamicStoreNamespaceMixin) {
  @Prop({ required: true }) private readonly steps!: WizardSteps

  private lineLeft = 0
  private lineRight = 0

  private get wrapperUniqClass (): string {
    return `stepper-wrapper-${nanoid()}`
  }

  private calculateLineSize (): void {
    // eslint-disable-next-line no-undef
    const stepElements = this.$el.querySelectorAll('.step__number') as NodeListOf<HTMLElement>
    let min = Infinity
    let max = -Infinity

    for (const stepEl of stepElements) {
      min = Math.min(min, stepEl.offsetLeft)
      max = Math.max(max, stepEl.offsetLeft + stepEl.offsetWidth)
    }

    this.lineLeft = min
    this.lineRight = (this.$el as HTMLElement).offsetWidth - max
  }
}
