import { Store } from '@/store/store'
import { PLAN_WIZARD_MEDIA_NETWORKS_COLLECTION } from '@/store/getter-types'
import { CollectionItemArray } from '@/types/planning/wizard/filter-editor'
import { NetworksCollectionConfig } from './collection-config'

export class MediaOwnerNetworksCollection extends NetworksCollectionConfig {
  get options (): CollectionItemArray {
    return Store.getters[PLAN_WIZARD_MEDIA_NETWORKS_COLLECTION]
  }

  async initCollection (): Promise<void> {
    // Depends on CityFilter so can't be initialized here.
  }
}
