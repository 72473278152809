


















import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { merge } from 'lodash'
import { FormInputSize } from '@/types/bootstrap'
import { QueryGroup } from '@/types/query-builder'
import { QueryRuleConfig } from '@/types/query-builder/rules'
import { ConfiguredRule } from './types'
import { BuilderSlotScope } from './types/slots'
import QueryBuilderGroup from './components/query-builder-group.vue'
import { ruleTypes } from './constants/rule-types'

@Component({
  components: { QueryBuilderGroup }
})
export default class QueryBuilder extends Vue {
  @Model('change', { required: true }) readonly value!: QueryGroup
  @Prop({ required: true }) readonly rules!: QueryRuleConfig[]
  @Prop({ default: 3 }) readonly maxDepth!: number
  @Prop({ default: 'md' }) readonly size!: FormInputSize

  private get mergedRules (): ConfiguredRule[] {
    return this.rules.map((rule) => {
      return merge({}, rule, ruleTypes[rule.type])
    })
  }

  private get slotScope (): BuilderSlotScope {
    return {
      query: this.value,
      rules: this.rules,
      maxDepth: this.maxDepth,
      size: this.size
    }
  }
}
