import layerStyleActions from './layer-style-actions/layer-style-actions'
import layerDataActions from './layer-data-actions/layer-data-actions'

// actions
const actions = {
  ...layerStyleActions,
  ...layerDataActions
}

export default actions
