import { SiteDetails } from '@/types/planning'
import { createTransformResponse } from '@/api/utils'
import { parseUnitLocations } from '@/utils/planning/media-owner-network/media-owner-networks'
import { isEmpty, assign } from 'lodash'
import { CITY_FIELD, OKZ_COLUMN } from '@/constants/unit/fields'

export const getSiteDetailsTransformResponse = createTransformResponse<SiteDetails>((response: string): SiteDetails => {
  const parsedResponse = JSON.parse(response)

  if (!parsedResponse) {
    return {} as SiteDetails
  }

  if (!isEmpty(parsedResponse.sections.Ort)) {
    const locations = parseUnitLocations(parsedResponse.sections.Ort.Locations)

    if (locations.length === 1) {
      parsedResponse.sections.Ort = locations[0]
    } else {
      parsedResponse.sections.Ort = locations
        .reduce((sum, loc) => assign(sum, { [loc[OKZ_COLUMN]]: loc[CITY_FIELD] }), {})
    }
  }

  return { ...parsedResponse }
})
