import {
  DATASET_WIZARD_TAB_GEOCODING_IS_ENABLED,
  DATASET_WIZARD_TAB_GEOCODING_IS_EMPTY,
  DATASET_WIZARD_TAB_GEOCODING_IS_INVALID,
  DATASET_WIZARD_TAB_GEOCODING_ERRORS,
  DATASET_WIZARD_DATASET_TYPE,
  DATASET_WIZARD_TAB_GEOCODING_STEP_ID
} from '@/store/getter-types'
import {
  DATASET_WIZARD_TAB_GEOCODING_VALIDATE,
  DATASET_WIZARD_TAB_GEOCODING_LEAVE,
  DATASET_WIZARD_GEOCODING_VALIDATE
} from '@/store/action-types'
import { DATASET_WIZARD_STEP_GEOCODING } from '@/components/analytics/dataset-wizard/constants/steps'

// initial state
const state = {
  stepId: DATASET_WIZARD_STEP_GEOCODING
}

// getters
const getters = {
  [DATASET_WIZARD_TAB_GEOCODING_IS_ENABLED] () {
    return true
  },
  [DATASET_WIZARD_TAB_GEOCODING_IS_EMPTY] (_state, getters) {
    return getters[DATASET_WIZARD_DATASET_TYPE] == null
  },
  [DATASET_WIZARD_TAB_GEOCODING_IS_INVALID] (_state, getters) {
    return getters[DATASET_WIZARD_TAB_GEOCODING_ERRORS].length > 0
  },
  [DATASET_WIZARD_TAB_GEOCODING_ERRORS] () {
    return []
  },
  [DATASET_WIZARD_TAB_GEOCODING_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  async [DATASET_WIZARD_TAB_GEOCODING_VALIDATE] ({ getters, dispatch }) {
    const errors = getters[DATASET_WIZARD_TAB_GEOCODING_ERRORS]
    return errors.concat(await dispatch(DATASET_WIZARD_GEOCODING_VALIDATE))
  },
  [DATASET_WIZARD_TAB_GEOCODING_LEAVE] () {
    // leave action
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
