import wizardNavigationStore from './wizard-navigation-store/wizard-navigation.store'
import wizardStepsStore from './planning-wizard-steps/planning-wizard-steps.store'
import mediaOwnerNetworksStore from './media-owner-networks-store/index'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { OrderSystem } from '@/types/enums'
import { Customer } from '@/views/planning/children/campaign-export/types'

// TODO get rid of 'any' types
export type WizardStore = {
  campaignId: number | null,
  campaignName: string,
  customer: Customer | null,
  orderSystem: OrderSystem,
  discount: number,
  campaignStatus: string | null
  forceContinuousDisplay: boolean,
  considerUnknownAvailability: boolean,
  startDate: Date | null,
  endDate: Date | null,
  filters: ScoringFunctionNode[],
  wasUpdated: boolean,
  activeFilter: ScoringFunctionNode | null,
  wizardNamespace: string,
  stepLeaveCallback: null | (() => Promise<boolean>)
}

const state: WizardStore = {
  campaignId: null,
  campaignName: '',
  customer: null,
  orderSystem: OrderSystem.NONE,
  discount: 0,
  campaignStatus: null,
  forceContinuousDisplay: false,
  considerUnknownAvailability: false,
  startDate: null,
  endDate: null,
  filters: [],
  wasUpdated: false,
  activeFilter: null,
  wizardNamespace: 'planning-wizard',
  stepLeaveCallback: null
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    wizardNavigation: wizardNavigationStore,
    wizardSteps: wizardStepsStore,
    mediaOwnerNetworksStore
  }
}
