import { EXCLUDE_SITE_FROM_CAMPAIGN, INCLUDE_SITE_TO_CAMPAIGN, SAVE_CAMPAIGN } from '@/store/action-types'
import { ScoringFunctionData, ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { CAMPAIGN_FILTERS, CAMPAIGN_GET_EXCLUDED_SITES } from '@/store/getter-types'
import { ADD_CAMPAIGN_FILTER, UPDATE_CAMPAIGN_FILTER, REMOVE_CAMPAIGN_FILTER } from '@/store/mutation-types'
import { FILTER_EXCLUSION } from '@/constants/scoring'
import { generateFilterId } from '@/store/planning-wizard/utils'
import { cloneDeep } from 'lodash'

const state = {}

const getters = {
  [CAMPAIGN_GET_EXCLUDED_SITES] (_state, getters) {
    const exclusionFilter: ScoringFunctionNode = getters[CAMPAIGN_FILTERS]
      .find((filter: ScoringFunctionNode) => filter.scoringFunction.name === FILTER_EXCLUSION)

    if (exclusionFilter) {
      return exclusionFilter.scoringFunction.data[0].ExternalIDs
    }

    return []
  }
}

const actions = {
  async [EXCLUDE_SITE_FROM_CAMPAIGN] ({ dispatch, getters, commit }, siteId) {
    let exclusionFilter: ScoringFunctionNode = getters[CAMPAIGN_FILTERS]
      .find((filter: ScoringFunctionNode) => filter.scoringFunction.name === FILTER_EXCLUSION)

    exclusionFilter = cloneDeep(exclusionFilter)

    if (exclusionFilter) {
      (exclusionFilter.scoringFunction.data[0].ExternalIDs as string[]).push(siteId)
      commit(UPDATE_CAMPAIGN_FILTER, exclusionFilter)
    } else {
      const newFilter: ScoringFunctionNode = {
        id: generateFilterId(),
        name: FILTER_EXCLUSION,
        scoringFunction: {
          name: FILTER_EXCLUSION,
          data: [{
            ExternalIDs: [siteId]
          }]
        }
      }
      commit(ADD_CAMPAIGN_FILTER, newFilter)
    }

    await dispatch(SAVE_CAMPAIGN)
  },
  async [INCLUDE_SITE_TO_CAMPAIGN] ({ dispatch, getters, commit }, siteId) {
    let exclusionFilter: ScoringFunctionNode = getters[CAMPAIGN_FILTERS]
      .find((filter: ScoringFunctionNode) => filter.scoringFunction.name === FILTER_EXCLUSION)

    exclusionFilter = cloneDeep(exclusionFilter)
    if (exclusionFilter) {
      const externalIds = (exclusionFilter.scoringFunction.data[0].ExternalIDs as string[]).filter(id => id !== siteId)
      if (externalIds.length > 0) {
        exclusionFilter.scoringFunction.data = [{
          ExternalIDs: externalIds
        }] as ScoringFunctionData[]
        commit(UPDATE_CAMPAIGN_FILTER, exclusionFilter)
      } else {
        commit(REMOVE_CAMPAIGN_FILTER, exclusionFilter)
      }

      await dispatch(SAVE_CAMPAIGN)
    }
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
