



















import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { saveBlob } from '@/utils/save'
import { Component, Vue } from 'vue-property-decorator'
import moment from '@/lib/moment'

@Component
export default class LoadMONInformationBtn extends Vue {
  private isLoading = false

  private generateMONFileName (name: string): string {
    const currentDate = moment()
    const date = currentDate.format('YYYY-MM-DD')

    return `${name} ${date}.xlsx`
  }

  private async downloadMediaOwnerNetworkInformation () {
    this.isLoading = true
    try {
      const { data, fileName } = await dataDeliveryApi.downloadMediaOwnerNetworksIWA()

      saveBlob(new Blob([data]), this.generateMONFileName(fileName))
    } catch {
      this.$toastError(this.$t('planning.wizard.errors.loading-mon-information') as string)
    } finally {
      this.isLoading = false
    }
  }
}
