import { pick } from 'lodash'
import { i18n } from '@/locales/i18n'
import { ExcelRow } from '@/types/common'
import {
  TagsImporter,
  TagCollectionItem,
  TagImportSettings,
  FilterListConfig
} from '@/types/planning/wizard/filter-editor'
import { AbstractCollectionConfig } from './collection-config'

export class StringListCollection extends AbstractCollectionConfig<string, string>
  implements TagsImporter, FilterListConfig<string> {
  options: Array<string> = [];

  formatCollectionOption (option: string): string {
    return option
  }

  async initCollection (): Promise<void> {
    return Promise.resolve()
  }

  isValueOptionEqual (value: string, option: string): boolean {
    return value === option
  }

  tagImportSettings: TagImportSettings = {
    onlyAutocomplete: false,
    columns: [
      i18n.t('planning.filters.labels.id-column') as string,
      i18n.t('planning.filters.labels.position-column') as string
    ]
  }

  parseRowToTag (row: ExcelRow, columns: Array<string>): TagCollectionItem {
    return {
      text: Object.values(pick(row, columns)).join('_')
    }
  }

  formatList (values: string[]): string[] {
    return values
  }
}
