import { Store } from '@/store/store'
import { BOOKING_TIME } from '@/store/getter-types'
import { BookingTimeFrame, BookingTimeFrameBase } from '@/types/planning/booking-time'
import { AbstractCollectionConfig } from './collection-config'
import { fixEndDateInTimeFrame } from '@/utils/booking-time'

export class BookingTimeCollection extends AbstractCollectionConfig<BookingTimeFrameBase, BookingTimeFrame> {
  get options (): BookingTimeFrame[] {
    return Store.getters[BOOKING_TIME].map(bookingTime => {
      return {
        ...bookingTime,
        end: fixEndDateInTimeFrame(bookingTime.end)
      }
    })
  }

  async initCollection (): Promise<void> {
    return Promise.resolve()
  }

  isValueOptionEqual (value: BookingTimeFrameBase, option: BookingTimeFrame): boolean {
    return option.value === value.value
  }

  formatCollectionOption (option: BookingTimeFrame): string {
    return option.value
  }
}
