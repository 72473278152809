import { ColDef } from 'ag-grid-community'
import { CallColumnApi, CallGridApi } from '@/components/shared/ag-grid/types'

export function CommonColDef (commonColDef: ColDef) {
  return function (_target: unknown, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value

    descriptor.value = function () {
      const result = originalMethod.call(this)

      return result.map(def => ({ ...def, ...commonColDef }))
    }
    return descriptor
  }
}

type TypedTest = (columnApi: CallColumnApi, gridApi: CallGridApi)=> Promise<void>

export function RenderViewEvent () {
  return function (_target: unknown, _propertyKey: string, descriptor: TypedPropertyDescriptor<TypedTest>) {
    const originalMethod = descriptor.value as TypedTest

    descriptor.value = async function (columnApi, gridApi) {
      await originalMethod.call(this, columnApi, gridApi)

      await gridApi(gridApi => {
        gridApi.dispatchEvent({ type: 'viewWasRendered' })
      })
    }
  }
}
