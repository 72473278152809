


































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import TagsAutocomplete from '@/directives/tags-autocomplete'
import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
import ImportCitiesModal from './components/cities-selector-modal/import/import-cities-modal.vue'
import CitiesSelectorModal from './components/cities-selector-modal/cities-selector-modal.vue'
import { UiControlMixin } from '@/components/planning/wizard/filter-editor/ui-controls/mixins/ui-control.mixin'
import { Validations } from '@/decorators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import { CityNamesConfig } from '@/utils/filter-editor/value-config/simple/city-names-config'
import { DatasetRows, GeometryBase } from '@/types/common'
import { PathType } from '@/types/enums'
import { ColDef } from 'ag-grid-community'

@Component({
  components: { FilterParamName, OverlayScrollbars, ImportCitiesModal, CitiesSelectorModal },
  directives: { TagsAutocomplete }
})
export default class CityNamesUiElement extends Mixins<UiControlMixin<GeometryBase[]>>(UiControlMixin) implements FilterUIComponent<GeometryBase[]> {
  @Prop({ default: () => [] }) declare value: GeometryBase[]
  @Prop({ required: true }) declare readonly config: CityNamesConfig
  @Ref('filterParamName') filterParamNameRef!: FilterParamName

  @Validations()
  protected validations (): RuleDecl {
    return {
      selected: {
        required,
        minLength: minLength(1),
        $each: { required }
      }
    }
  }

  @Watch('selected')
  private onSelectedChange (value: string[]): void {
    this.$emit('change', value)
  }

  private get datasetRows (): Readonly<DatasetRows> {
    return this.config.options
  }

  private get columnDefs (): ColDef[] {
    return this.config.columnDefs
  }

  private get selectedRows (): DatasetRows {
    return this.datasetRows.filter(item => this.selected.some(geometry => geometry.name === item.OKZ as string))
  }

  private get citiesLabel (): string {
    const amountOfCities = this.selected.length
    return this.$tc('planning.filters.labels.select-cities-label', amountOfCities, { city: this.selected[0] ? this.selected[0].name : '', amount: amountOfCities - 1 })
  }

  private handleSelectCitiesEvent (cities) {
    const updatedSelected = cities.map(row => {
      return {
        id: '',
        name: row.OKZ,
        parentGeometrySetId: PathType.OKZ
      } as GeometryBase
    })

    this.setSelected(updatedSelected)
  }
}
