import { isEmpty, omit } from 'lodash'
import { LatLngBounds, latLngBounds } from 'leaflet'
import { nanoid } from 'nanoid'
import { visualizationApi } from '@/api/rest/visualization/visualization.api'
import { visualizationDataApi } from '@/api/rest/visualization/visualization-data.api'
import {
  CREATE_NEW_VISUALIZATION,
  LOAD_VISUALIZATION_DATA,
  NEW_CUSTOM_VISUALIZATION,
  RESET_VISUALIZATION,
  SAVE_VISUALIZATION,
  SAVE_VISUALIZATION_AS,
  SET_VISUALIZATION,
  SET_VISUALIZATION_BY_ID,
  DELETE_VISUALIZATION_BY_ID,
  VALIDATE_VISUALIZATION
} from '@/store/action-types'
import {
  VISUALIZATION_GET_ERRORS,
  VISUALIZATION_ID,
  VISUALIZATION_REQUEST_DATA
} from '@/store/getter-types'
import {
  SET_VISUALIZATION_CREATED_BY,
  SET_VISUALIZATION_ID,
  SET_VISUALIZATION_LAYERS,
  SET_VISUALIZATION_LINKED_PLANNING,
  SET_VISUALIZATION_LINKED_PLANNING_FUNCTIONS,
  SET_VISUALIZATION_LOCATIONS,
  SET_VISUALIZATION_NAME,
  SET_VISUALIZATION_REPORTS,
  SET_VISUALIZATION_SLIDES,
  SET_VISUALIZATION_VALIDATED_STATE
} from '@/store/mutation-types'
import { VisualizationLayer } from '@/types/visualization/layer'
import axios from 'axios'

// actions
const actions = {
  [NEW_CUSTOM_VISUALIZATION] ({ dispatch }, name) {
    const data = {
      name,
      linkedPlanningIDs: [],
      layers: [],
      savedLocations: []
    }

    return dispatch(CREATE_NEW_VISUALIZATION, data)
  },
  async [CREATE_NEW_VISUALIZATION] (_actions, data) {
    return visualizationApi.createVisualization(data)
  },
  [SET_VISUALIZATION] ({ commit, dispatch }, visualization) {
    commit(SET_VISUALIZATION_ID, visualization.id)
    commit(SET_VISUALIZATION_NAME, visualization.name)
    commit(SET_VISUALIZATION_CREATED_BY, visualization.createdBy)
    commit(SET_VISUALIZATION_LINKED_PLANNING, visualization.linkedPlanningIDs)
    commit(SET_VISUALIZATION_LINKED_PLANNING_FUNCTIONS, visualization.linkedPlanningFunctions)
    commit(SET_VISUALIZATION_LOCATIONS, visualization.savedLocations)

    return dispatch(LOAD_VISUALIZATION_DATA, { layers: visualization.layers })
      .then(data => {
        commit(SET_VISUALIZATION_REPORTS, data.reports)
        commit(SET_VISUALIZATION_LAYERS, data.layers)
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          return Promise.reject(Error('visualization.errors.load-visualization-data-failed'))
        }

        return err
      })
  },
  [SET_VISUALIZATION_BY_ID] ({ dispatch }, id) {
    return visualizationApi.getVisualization(id)
      .then(visualization => dispatch(SET_VISUALIZATION, visualization))
  },
  [DELETE_VISUALIZATION_BY_ID] ({ dispatch, getters }, id) {
    return visualizationApi.deleteVisualization(id).then(() => {
      if (id === getters[VISUALIZATION_ID]) {
        dispatch(RESET_VISUALIZATION)
      }
    })
  },
  [LOAD_VISUALIZATION_DATA] ({ getters }, { layers = null, bounds = null }: { layers: VisualizationLayer[] | null, bounds: LatLngBounds | null }) {
    // Use default map bounds for now.
    bounds = bounds || latLngBounds(DEFAULT_MAP_BOUNDS.northWest, DEFAULT_MAP_BOUNDS.southEast)

    const request = getters[VISUALIZATION_REQUEST_DATA]

    // Override layers in the request if got custom layers.
    if (layers) {
      request.layers = layers
    }

    return visualizationDataApi.getVisualizationData(request, bounds as LatLngBounds)
  },
  [SAVE_VISUALIZATION] ({ getters, dispatch }) {
    if (getters[VISUALIZATION_ID]) {
      return visualizationApi.updateVisualization(getters[VISUALIZATION_REQUEST_DATA])
    }

    return dispatch(CREATE_NEW_VISUALIZATION, getters[VISUALIZATION_REQUEST_DATA])
  },
  async [SAVE_VISUALIZATION_AS] ({ getters, dispatch }, name) {
    const requestData = omit(getters[VISUALIZATION_REQUEST_DATA], ['id'])

    requestData.name = name
    requestData.layers = requestData.layers.map(item => {
      return Object.assign({}, { id: nanoid() }, item)
    })

    const copiedVisualization = await visualizationApi.createVisualization(requestData)
    await dispatch(SET_VISUALIZATION, copiedVisualization)

    return copiedVisualization
  },
  [VALIDATE_VISUALIZATION] ({ commit, getters }, visualization) {
    isEmpty(visualization) && commit(SET_VISUALIZATION_VALIDATED_STATE, true)

    const errors = getters[VISUALIZATION_GET_ERRORS](visualization)

    return new Promise((resolve, reject) => {
      return errors.length ? reject(errors) : resolve(true)
    })
  },
  [RESET_VISUALIZATION] ({ commit }) {
    commit(SET_VISUALIZATION_ID, null)
    commit(SET_VISUALIZATION_NAME, '')
    commit(SET_VISUALIZATION_CREATED_BY, null)
    commit(SET_VISUALIZATION_LAYERS, [])
    commit(SET_VISUALIZATION_REPORTS, [])
    commit(SET_VISUALIZATION_SLIDES, [])
    commit(SET_VISUALIZATION_LINKED_PLANNING, [])
    commit(SET_VISUALIZATION_LINKED_PLANNING_FUNCTIONS, [])
    commit(SET_VISUALIZATION_LOCATIONS, [])
  }
}

export default actions
