import { ScoringFunction } from '@/types/planning/scoring/functions'
import { ScoringFunctionsTypes } from '@/constants/functions/scoring-functions-types'

export function isLocationFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.AreaFilter
}

export function isSingleMediaTypeFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.TypeFilter
}

export function isNetworkMediaTypeFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.NetworkFilter
}

export function isFlightFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.FlightFilter
}

export function isFilterScoringFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.Filter
}

export function isRankingScoringFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.Ranking
}

export function isGoalFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.Goal
}

export function isCommonGoalFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.OverallGoal
}

export function isManualScoringFunction (func: ScoringFunction): boolean {
  return func.type === ScoringFunctionsTypes.ManualScoring
}

export function isMediaTypeFunction (func: ScoringFunction): boolean {
  return isSingleMediaTypeFunction(func) || isNetworkMediaTypeFunction(func)
}

export function isCommonSettingsFunction (func: ScoringFunction): boolean {
  return isCommonGoalFunction(func) || isManualScoringFunction(func)
}
