






















import Checkboard from './checkboard.vue'
import { DirectionAwareMixin } from '../mixin/direction-aware.mixin'
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { VueDynamicClassDef } from '@/types/vue'
import { BackgroundProperty } from 'csstype'

@Component({
  components: { Checkboard }
})
export default class ColorCompare extends Mixins(DirectionAwareMixin) {
  @Prop({ required: true }) oldColor!: BackgroundProperty<string>
  @Prop({ required: true }) newColor!: BackgroundProperty<string>

  private get compareClass (): VueDynamicClassDef {
    return {
      'color-picker__compare--vertical': this.isVertical,
      'color-picker__compare--horizontal': !this.isVertical
    }
  }
}
