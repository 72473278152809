import { Routes } from '@/router/constants/routes'
import { AuthPermission } from '@/types/auth/permissions'
import { i18n } from '@/locales/i18n'
import { UniqueRouteConfig } from '@/router/types'
const CampaignDashboard = () => import('@/views/planning/children/campaign-dashboard/Campaign-dashboard.vue')
const Campaign = () => import('@/views/planning/children/Campaign.vue')
const CampaignDetails = () => import('@/views/planning/children/campaign-details/Campaign-details.vue')
const PlanningExport = () => import('@/views/planning/children/campaign-export/Campaign-export.vue')

export const planningRoutes: Array<UniqueRouteConfig> = [
  {
    path: '',
    name: Routes.PLANNING_DASHBOARD,
    component: CampaignDashboard,
    meta: {
      routeId: Routes.PLANNING_DASHBOARD,
      get name () { return i18n.t('common.labels.planning') },
      can: AuthPermission.ViewPlanning
    }
  },
  {
    path: ':id',
    component: Campaign,
    props: true,
    meta: {
      routeId: Routes.CAMPAIGN_CONTAINER,
      can: AuthPermission.ViewPlanning
    },
    children: [
      {
        path: '',
        name: Routes.CAMPAIGN_DETAILS,
        component: CampaignDetails,
        props: true,
        meta: {
          routeId: Routes.CAMPAIGN_DETAILS,
          can: AuthPermission.ViewPlanning
        }
      },
      {
        path: 'export',
        name: Routes.CAMPAIGN_EXPORT,
        component: PlanningExport,
        props: true,
        meta: {
          routeId: Routes.CAMPAIGN_EXPORT,
          get name () { return i18n.t('planning.sidebar.labels.export-plan') },
          can: AuthPermission.ViewPlanning
        }
      }
    ]
  }
]
