import { Route } from 'vue-router/types/router'
import { UserToken } from '@/views/login/types'
import { CLEAR_AUTH_TOKEN, SET_AUTH_TOKEN } from '@/store/auth/auth-mutations'

export const SET_AUTHENTICATION = 'auth/setAuthentication'
export const LOGOUT = 'auth/logout'

export const actions = {
  [SET_AUTHENTICATION] ({ commit }, { token }: UserToken): void {
    commit(SET_AUTH_TOKEN, token)
  },
  [LOGOUT] ({ commit }): Promise<Route> | void {
    commit(CLEAR_AUTH_TOKEN)
  }
}
