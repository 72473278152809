import { i18n } from '@/locales/i18n'
import {
  LAYER_WIZARD_LAYER_TYPE,
  LAYER_WIZARD_LINK_NAMESPACE,
  LAYER_WIZARD_STEP_GEOMETRY_IS_VALID,
  LAYER_WIZARD_TAB_GEOMETRY_ERRORS,
  LAYER_WIZARD_TAB_GEOMETRY_IS_EMPTY,
  LAYER_WIZARD_TAB_GEOMETRY_IS_ENABLED,
  LAYER_WIZARD_TAB_GEOMETRY_IS_HIDDEN,
  LAYER_WIZARD_TAB_GEOMETRY_IS_INVALID,
  LAYER_WIZARD_TAB_GEOMETRY_STEP_ID,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID
} from '@/store/getter-types'
import { LAYER_WIZARD_TAB_GEOMETRY_LEAVE, LAYER_WIZARD_TAB_GEOMETRY_VALIDATE } from '@/store/action-types'
import { LAYER_WIZARD_STEP_GEOMETRY } from '@/components/visualization/layer-wizard/constants/steps'
import { LayerType } from '@/types/visualization/layer/enums'

// initial state
const state = {
  stepId: LAYER_WIZARD_STEP_GEOMETRY
}

// getters
const getters = {
  [LAYER_WIZARD_TAB_GEOMETRY_IS_HIDDEN] (state, getters) {
    return getters[LAYER_WIZARD_LAYER_TYPE] !== LayerType.Shapes
  },
  [LAYER_WIZARD_TAB_GEOMETRY_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId)
  },
  [LAYER_WIZARD_TAB_GEOMETRY_IS_EMPTY] (state, getters) {
    return getters[LAYER_WIZARD_LAYER_TYPE] == null
  },
  [LAYER_WIZARD_TAB_GEOMETRY_IS_INVALID] (state, getters) {
    return getters[LAYER_WIZARD_TAB_GEOMETRY_ERRORS].length > 0
  },
  [LAYER_WIZARD_TAB_GEOMETRY_ERRORS] (state, getters) {
    const errors = []

    if (!getters[LAYER_WIZARD_STEP_GEOMETRY_IS_VALID]) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.select-entrypoint'))
    }

    return errors
  },
  [LAYER_WIZARD_TAB_GEOMETRY_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [LAYER_WIZARD_TAB_GEOMETRY_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[LAYER_WIZARD_TAB_GEOMETRY_ERRORS])
  },
  [LAYER_WIZARD_TAB_GEOMETRY_LEAVE] () {
    // Need this for src/store/layer-wizard/plugins/listen-to-step-change.js
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
