import { get, isEmpty, isEqual, isObjectLike } from 'lodash'
import Vue from 'vue'
import { FillType, PathStyle } from '@/types/visualization/layer/enums'
import { FILL_MODE_CONFIGS, PATH_STYLE_CONFIGS } from './constants'
import { VisualizationLayer } from '@/types/visualization/layer'
import { DataColumn } from '@/types/common'
import { isLinesLayer, isPolygonsLayer } from '@/types/visualization/layer/guards'

export function dashArray (pathStyle: PathStyle, width: number): number[] {
  return PATH_STYLE_CONFIGS[pathStyle].patternForWidth(width)
}

// TODO - find better way to type layer style and its part
export function applyStyleChanges<T> (style: Partial<T>, changes: T): void {
  Object.entries(changes).forEach(([prop, value]) => {
    if (!isObjectLike(value) || Array.isArray(value)) {
      // Don't apply change if there is nothing changed actually.
      if (!isEqual(style[prop], value)) {
        Vue.set(style, prop, value)
      }

      return
    }

    if (typeof style[prop] !== 'object') {
      Vue.set(style, prop, {})
    }

    if (isEmpty(value) && !isEmpty(style[prop])) {
      // If new value is empty just set it and return without recursion.
      Vue.set(style, prop, {})
      return
    }

    // Apply style changes recursively if they are not empty.
    applyStyleChanges(style[prop], value)
  })
}

export function canBeEditable (layer: VisualizationLayer): boolean {
  return !isEmpty(layer.dataSource.datasetId) && layer.columns.length > 0
}

export function canBeLinked (layer: VisualizationLayer): boolean {
  if (!isPolygonsLayer(layer) && !isLinesLayer(layer)) {
    return false
  }

  if (layer.dataSource.datasetId) {
    return true
  }

  for (const geometry of layer.geometry) {
    if (geometry.key !== null) {
      return true
    }
  }
  return false
}

export function fillModeSupportsColumn (fillMode: FillType, column: DataColumn): boolean {
  const testColumnFunc = FILL_MODE_CONFIGS[fillMode].testColumn

  return testColumnFunc ? testColumnFunc(column) : false
}

export function fillModeSupportsSubLayers (fillMode: FillType): boolean {
  return get(FILL_MODE_CONFIGS, [fillMode, 'supportSublayers'], false)
}
