import { ApiRequestConfig } from '@/api/types'
import { ApiClient } from '@/api/api-client'

class CustomersApi extends ApiClient {
  getCustomers (config?: ApiRequestConfig) {
    return this.get('customers', { ...config })
  }
}

export const customersApi = new CustomersApi({
  baseURL: API_CONF.api + '/api/crmgateway/'
})
