import { Component } from 'vue-property-decorator'
import { Layer } from 'leaflet'
import { MapCompProp } from '@/decorators'
import { LayerStyle } from '@/types/visualization/layer/style'
import { ZIndexLayerOptions } from '@/types/leaflet'
import { LayerMixin } from '@/components/map/mixins/layer-mixin/layer.mixin'

type SetLayerStyleFunc = (style: Partial<LayerStyle>) => void
type StyledLayer = Layer & { setStyle?: SetLayerStyleFunc };

@Component
export class ZIndexMixin<TLayer extends Layer> extends LayerMixin<TLayer> {
  @MapCompProp({ layerProp: true, default: 1 }) zIndex!: number

  protected get zIndexOptions (): ZIndexLayerOptions {
    return {
      zIndex: this.zIndex
    }
  }

  protected setZIndex (newVal: number): void {
    const styledLayer = (this.mapObject as StyledLayer)
    styledLayer.setStyle && styledLayer.setStyle({ zIndex: newVal })
  }
}
