import moment from '@/lib/moment'
import { ApiRequestConfig } from '@/api/types'
import { ApiClient } from '@/api/api-client'
import {
  ApiDataObject,
  Dataset,
  Geometries,
  Geometry,
  GeometryBase,
  Geometryset,
  ObjectId,
  ObjectIds,
  ObjectTag,
  PointCoordinate
} from '@/types/common'
import { TAG_KEY_GRANULARITY, TAG_OBJ_ACCESS_GLOBAL, TAG_OBJ_GRANULARITY_DE } from '@/constants/tags'
import { parseImportDatasetResponse } from './transform-response/import-dataset-response.transform'
import { getGeometryTransformResponse } from './transform-response/get-geometry-response.transform'
import { parseImportGeometryResponse } from './transform-response/import-geometry-response.transform'
import { getDatasetTransformResponse } from './transform-response/get-dataset-response.transform'
import { getPriceTableTransformResponse } from './transform-response/get-price-table-response.transform'
import { getMONDatasetTransformResponse } from './transform-response/mon-dataset-response.transform'
import { BlobFileResponseWithName, SearchRequest } from '@/types/api/data-delivery'
import { ImportProgressCallback } from '@/types/file-upload'
import { AxiosResponse } from 'axios'
import { extractFileNameFromContentDisposition } from '@/utils/api/helpers'

type ImportDataType = 'dataset' | 'geometryset'

class DataDeliveryApi extends ApiClient {
  importDataLayer (file: File, dataType: ImportDataType, uploadCallback: ImportProgressCallback) {
    const formData = new FormData()
    formData.append('File', file, file.name)
    formData.append('Name', file.name)

    const onUploadProgress = (progress) => {
      const uploadPercent = Math.round(progress.loaded / progress.total * 100)
      uploadCallback(uploadPercent)
    }

    const transformResponse = dataType === 'dataset' ? parseImportDatasetResponse : parseImportGeometryResponse

    return this.post(`import${dataType}`, formData, { onUploadProgress, transformResponse })
  }

  globalSearch<T extends ApiDataObject> (request: SearchRequest): Promise<T[]> {
    const { type, query = '*', tags = [], cancelToken, maxResults } = request
    const tagsWithGlobal = [...tags, TAG_OBJ_ACCESS_GLOBAL]

    return this.search<T>({ type, query, tags: tagsWithGlobal, cancelToken, maxResults })
  }

  searchGranularity ({ granularity, cancelToken }): Promise<Geometryset | null> {
    const tags: ObjectTag[] = [{ key: TAG_KEY_GRANULARITY, value: granularity }]

    return this.globalSearch<Geometryset>({ type: 'geometryset', tags, cancelToken })
      .then((geometrysets) => {
        return geometrysets.length ? geometrysets[0] : null
      })
  }

  private search<T extends ApiDataObject> (request: SearchRequest): Promise<T[]> {
    let { type, query = '*', tags = [], cancelToken, maxResults = 100 } = request
    query = encodeURIComponent(query)
    const config: ApiRequestConfig = { cancelToken }
    const data = { mustIncludeTags: tags, maxResults }

    return this.post(`${type}/search/${query}`, data, config)
  }

  getDataset (id: ObjectId): Promise<Dataset> {
    return this.get(`iwdataset/${id}`, { transformResponse: getDatasetTransformResponse })
  }

  removeDataset (id: ObjectId): Promise<void> {
    return this.delete(`dataset/${id}`)
  }

  updateDataset (dataset, id) {
    const config: ApiRequestConfig = {
      blockingRequest: true
    }

    // TODO: TypeScript this.
    const dto = {
      ...dataset,
      id
    }

    return this.put('updateDataset', dto, config)
  }

  createDataset (dataset) {
    const config: ApiRequestConfig = {
      blockingRequest: true
    }
    // TODO: TypeScript this.
    return this.put('writeDataset', dataset, config)
  }

  getGeometrySet (id: ObjectId): Promise<Geometryset> {
    return this.get(`geometryset/${id}`)
  }

  getGeometry (id: ObjectId): Promise<Geometry> {
    return this.get(`geometry/${id}`, { transformResponse: getGeometryTransformResponse })
  }

  getAreaKeysForPoint (coordinate: PointCoordinate): Promise<GeometryBase[]> {
    return this.post('GetAreaKeysForPoint', coordinate)
  }

  getAreaKeysForPolygon (geometrysetId: ObjectId, geometryIds: ObjectIds): Promise<ObjectIds> {
    return this.post(`GetAreaKeysForPolygons/${geometrysetId}`, geometryIds)
  }

  getAvailableGranularities (): Promise<Geometryset[]> {
    const request: SearchRequest = {
      type: 'geometryset',
      query: '*',
      tags: [TAG_OBJ_GRANULARITY_DE]
    }

    return this.search<Geometryset>(request)
  }

  getPriceTable (datasetId: ObjectId, mediumId: string) {
    // TODO: TypeScript this.
    return this.get(`GetPriceTable/${datasetId}/${mediumId}`, { transformResponse: getPriceTableTransformResponse })
  }

  getGeometriesByKeys (geometrysetId: ObjectId, areaKeys: string[]): Promise<Geometries> {
    return this.post(`GetGeometriesByKeys/${geometrysetId}`, areaKeys)
  }

  getMediaOwnerNetworkByGeometries (geometries: GeometryBase[], startDate: Date, endDate: Date): Promise<Dataset> {
    const startDateString = moment(startDate).format('YYYY-MM-DD')
    const endDateString = moment(endDate).format('YYYY-MM-DD')

    const config: ApiRequestConfig = {
      transformResponse: getMONDatasetTransformResponse
    }

    return this.post(`MediaOwnerNetworkDatasetByGeometriesDTO/${startDateString}/${endDateString}`, geometries, config)
  }

  getCitiesDataset (): Promise<Dataset> {
    const config: ApiRequestConfig = {
      transformResponse: getDatasetTransformResponse
    }
    return this.get('GetAllCitiesWithLocations', config)
  }

  downloadMediaOwnerNetworksIWA (): Promise<BlobFileResponseWithName> {
    return this.http.get('DownloadMediaOwnerNetworksIWA', this.makeRequestConfig({ responseType: 'blob' }))
      .then(({ data, headers }: AxiosResponse) => {
        const fileName = extractFileNameFromContentDisposition(headers)
        return {
          data,
          fileName
        }
      })
  }
}

export const dataDeliveryApi = new DataDeliveryApi({
  baseURL: API_CONF.api + '/api/datadelivery'
})
