import { Component, Mixins } from 'vue-property-decorator'
import ColorLegendMixin from '@/components/visualization/legend/mixins/color-legend.mixin'
import LayerFillMixin from '@/components/visualization/map/mixins/layer-fill.mixin'

@Component
export default class ColorFillLegendMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  Mixins<ColorLegendMixin<_TLayer>, LayerFillMixin<_TLayer>>(ColorLegendMixin, LayerFillMixin) {
  protected get colorFillLegendValues () {
    return this.makeColorLegendValues(
      this.layer.name,
      this.layerFillColor,
      this.layerFillOpacity
    )
  }
}
