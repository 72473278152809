



















































import { Component, Mixins, Model, Prop, Watch } from 'vue-property-decorator'
import FileUpload from '@/components/file-upload/file-upload.vue'
import { FormInputSize, FormInputState } from '@/types/bootstrap'
import { ExcelRow, Geometries } from '@/types/common'
import ImportAreasResults from '@/plugins/import-areas/import-areas-results.vue'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { Validations } from '@/decorators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { required } from 'vuelidate/lib/validators'
import { uniq, uniqBy } from 'lodash'
import { findStringMatch } from '@/utils/similarity'
import { ValidationProviderMixin } from '@/mixins/validation/validation-provider.mixin'

@Component({
  components: { ImportAreasResults, FileUpload }
})
export default class ImportAreasSettings extends Mixins<ValidationProviderMixin>(ValidationProviderMixin) {
  @Model('change', { default: [], required: true }) private readonly geometries!: Geometries
  @Prop({ default: [] }) private readonly fileRows!: ExcelRow[]

  private size: FormInputSize = 'sm'
  private granularityColumn: string | null = null
  private keyColumn: string | null = null
  private granularities: Geometries | null = null

  @Validations()
  protected validations (): RuleDecl {
    return {
      granularityColumn: { required },
      keyColumn: { required }
    }
  }

  private get fileColumns (): string[] {
    return Object.keys(this.fileRows[0] ?? {})
  }

  private get columnsSelectionDisabled (): boolean {
    return this.fileRows.length === 0
  }

  private get importDisabled (): boolean {
    return !this.granularityColumn || !this.keyColumn
  }

  private get granularityState (): FormInputState {
    return this.validationModelToInputState(this.$v.granularityColumn)
  }

  private get keyColumnState (): FormInputState {
    return this.validationModelToInputState(this.$v.keyColumn)
  }

  private get invalidGranularityFeedback (): string {
    return this.$t('validation.errors.required', { attribute: this.$t('common.areas-import.granularity-label') }) as string
  }

  private get invalidKeyColumnFeedback (): string {
    return this.$t('validation.errors.required', { attribute: this.$t('common.areas-import.key-column-label') }) as string
  }

  @Watch('fileRows')
  private onFileDataChange (): void {
    this.granularityColumn = null
    this.keyColumn = null
    this.$emit('change', [])
  }

  async uploadGeometries (): Promise<void> {
    let geometries = [] as Geometries

    const granularityKeys = this.fileRows.map((row) => String(row[this.granularityColumn as string])) ?? []
    const uniqueGranularityKeys = uniq(granularityKeys) as string[]

    const foundValues = findStringMatch(uniqueGranularityKeys, (this.granularities ?? []).map(g => g.name))

    for await (const granularityKey of Object.keys(foundValues)) {
      const areaKeysFromDataset: string[] = this.fileRows
        .filter(row => String(row[this.granularityColumn as string] as string) === granularityKey)
        .map((row) => String(row[this.keyColumn as string] as string))

      const matchingGranularity = this.granularities?.find(granularity => granularity.name === foundValues[granularityKey])

      if (matchingGranularity) {
        const uploadedGeometries = await dataDeliveryApi.getGeometriesByKeys(matchingGranularity.id, areaKeysFromDataset)

        const validatedGeometries = uniqBy(uploadedGeometries, 'name')
          .filter(item => areaKeysFromDataset.includes(item.name))

        geometries = geometries.concat(validatedGeometries)
      }
    }

    this.$emit('change', geometries)
  }

  override async mounted () {
    this.granularities = await dataDeliveryApi.getAvailableGranularities() as Geometries
  }

  validateComponent (): boolean {
    this.$v.$touch()
    return !this.$v.$invalid
  }
}
