import commonTabStore from './tabs/wizard-tab-common.store'
import locationTabStore from './tabs/wizard-tab-location.store'
import mediaTypesTabStore from './tabs/wizard-tab-media-types.store'
import flightsTabStore from './tabs/wizard-tab-flights.store'
import filterFunctionsTabStore from './tabs/wizard-tab-filters.store'
import rankingFunctionsTabStore from './tabs/wizard-tab-rankings.store'
import goalsTabStore from './tabs/wizard-tab-goals.store'
import commonSettingsTabStore from './tabs/wizard-tab-common-settings.store'

// initial state
const state = {
  config: null,
  steps: []
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    commonTab: commonTabStore,
    locationTab: locationTabStore,
    mediaTypesTab: mediaTypesTabStore,
    flightsTab: flightsTabStore,
    filterFunctionsTab: filterFunctionsTabStore,
    rankingFunctionsTab: rankingFunctionsTabStore,
    goalsTab: goalsTabStore,
    commonSettingsTab: commonSettingsTabStore
  }
}
