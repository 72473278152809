import imgEn from '@/assets/img/en.png'
import imgDe from '@/assets/img/de.png'

interface Locale {
    [key: string]: string
}

interface Locales {
    [key: string]: Locale
}

export const locales = {
  en: { title: 'English', image: imgEn },
  de: { title: 'Deutsch', image: imgDe }
}

export const getLocales = (): Locales => {
  return locales
}
