import { Store } from '@/store/store'
import { isApiError } from '@/api/guards'
import { InterceptorsConfig } from './types'
import VueRouter from 'vue-router'
import { LogoutPayload } from '@/store/auth/types/auth.store'
import { LOGOUT } from '@/store/auth/auth-actions'
import { AUTH_TOKEN, TEMP_TOKEN } from '@/store/auth/auth-getters'

export const authInterceptors: InterceptorsConfig = {
  request: {
    onSuccess: function (config) {
      const token = Store.getters[TEMP_TOKEN] || Store.getters[AUTH_TOKEN]

      if (!config.noAuth && token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    }
  },
  response: {
    onReject: function (error) {
      if (isApiError(error) && error.response?.status === 401 && !error.config.noAuth) {
        Store.dispatch(LOGOUT, { needReturn: true } as LogoutPayload)
          .catch(error => {
          // it is a hack to take correct type of the navigation failure (problem with importing)
            const duplicated = 4
            if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType[duplicated])) {
              return
            }

            throw error
          })
      }

      return Promise.reject(error)
    }
  }
}
