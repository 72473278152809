












































































import { Component, Mixins, Model, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { SelectOption } from '@/types/bootstrap'
import {
  QueryBuilderGroup as IQueryBuilderGroup,
  QueryBuilderItem,
  QueryBuilderRule,
  QueryGroup
} from '@/types/query-builder'
import { GroupOperator } from '@/types/query-builder/enums'
import { ConfiguredRule } from '../types'
import { QueryGroupMixin } from '../mixins/query-group.mixin'
import QueryBuilderChildren from './query-builder-children.vue'

@Component({
  components: { QueryBuilderChildren }
})
export default class QueryBuilderGroup extends Mixins<QueryGroupMixin>(QueryGroupMixin) {
  @Model('change', { required: true }) readonly declare value: QueryGroup

  // @ts-ignore - TS-2729 value already initialized here as Vue uses its own class initialization logic
  private query: QueryGroup = cloneDeep(this.value)
  private selectedRule: ConfiguredRule | null = this.rules[0] ?? null

  private get operatorOptions (): SelectOption[] {
    return [
      { value: GroupOperator.AND, text: this.$t('query-builder.operators.and') as string },
      { value: GroupOperator.OR, text: this.$t('query-builder.operators.or') as string }
    ]
  }

  private get ruleOptions (): SelectOption<ConfiguredRule>[] {
    return this.rules.map((rule) => ({
      value: rule,
      text: rule.label
    }))
  }

  private get canRemove (): boolean {
    return this.depth > 1
  }

  private get canAddGroup (): boolean {
    return this.depth < this.maxDepth
  }

  @Watch('value')
  private onValueChange (value: QueryGroup): void {
    this.query = cloneDeep(value)
  }

  @Watch('rules')
  private onRulesChange (rules: ConfiguredRule[]) {
    if (!rules.length) {
      this.selectedRule = null
      return
    }

    if (!this.selectedRule) {
      this.selectedRule = rules[0]
      return
    }

    const ruleIndex = rules.indexOf(this.selectedRule)

    if (ruleIndex === -1) {
      this.selectedRule = rules[0]
    }
  }

  private addRule (): void {
    if (!this.selectedRule) {
      return
    }

    const newRule: QueryBuilderRule = {
      type: 'rule',
      query: {
        rule: this.selectedRule.id,
        operator: this.selectedRule.operators[0],
        operand: this.selectedRule.operands?.[0] ?? this.selectedRule.label,
        value: this.selectedRule.default // TODO: Set default value for radio/checkbox and others.
      }
    }

    this.addChild(newRule)
  }

  private addGroup (): void {
    if (!this.canAddGroup) {
      return
    }

    const newGroup: IQueryBuilderGroup = {
      type: 'group',
      query: {
        operator: GroupOperator.AND,
        children: []
      }
    }

    this.addChild(newGroup)
  }

  private emitChange (query: QueryGroup): void {
    this.$emit('change', query)
  }

  private addChild (child: QueryBuilderItem): void {
    const query = cloneDeep(this.query)
    query.children.push(child)
    this.emitChange(query)
  }

  private onChildRemove (index: number): void {
    const query = cloneDeep(this.query)
    query.children.splice(index, 1)
    this.emitChange(query)
  }

  private onChildChange (index: number, child: QueryBuilderItem): void {
    const query = cloneDeep(this.query)
    query.children[index] = child
    this.emitChange(query)
  }
}

