import { StatisticObjectDTO, Statistics } from '@/types/planning/statistics'
import moment from '@/lib/moment'

export function parseStatisticsData (statistics: StatisticObjectDTO[]): Statistics {
  return statistics.map(statistic => {
    const updatedDescriptions = statistic.descriptions.map(item => ({
      ...item,
      timeRange: item.timeRange && {
        start: moment(item.timeRange.start),
        end: moment(item.timeRange.end).subtract({ ms: 1 })
      }
    }))

    return {
      ...statistic,
      descriptions: updatedDescriptions,
      goalType: parseGoalType(statistic.goalType)
    }
  })
}

function parseGoalType (type: string): string {
  return type.split(',')[0].split('.').pop() as string
}
