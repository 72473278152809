import { render, staticRenderFns } from "./resolve-unclear-assignments.vue?vue&type=template&id=5371547a&scoped=true&"
import script from "./resolve-unclear-assignments.vue?vue&type=script&lang=ts&"
export * from "./resolve-unclear-assignments.vue?vue&type=script&lang=ts&"
import style0 from "./resolve-unclear-assignments.vue?vue&type=style&index=0&id=5371547a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5371547a",
  null
  
)

export default component.exports