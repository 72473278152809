





























import { debounce } from 'lodash'
import axios, { CancelTokenSource, CancelToken } from 'axios'
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import TagsTable from '@/components/shared/tags-table/tags-table.vue'
import { BTable } from 'bootstrap-vue'
import { TranslateResult } from 'vue-i18n'
import { ApiDataObject } from '@/types/common'
import { FormInputState } from '@/types/bootstrap'

type ApplyFilterFunc = (query: string) => void

@Component({
  components: { TagsTable, OverlayScrollbars }
})
export default class SearchableList extends Vue {
  @Prop({ default: 'single' }) selectMode!: string;
  @Prop({ default: null }) state!: FormInputState

  private query = ''
  private requestCancelToken: null | CancelTokenSource = null
  private busy = false
  private $filterFunc!: ApplyFilterFunc

  protected get tableItems (): BTable['items'] {
    return []
  }

  protected get placeholder (): TranslateResult {
    return this.$t('common.placeholders.search')
  }

  private get isSearchActive (): boolean {
    return this.query.length > 0
  }

  @Watch('query')
  private onQueryChange (value: string): void {
    this.$filterFunc(value)
  }

  mounted () {
    this.$filterFunc = debounce(query => this.filterItems(query), 500)
  }

  protected isValidQuery (query: string): boolean {
    return query.length >= 3
  }

  protected clearItems (): void { /* */ }

  private filterItems (query: string): void {
    this.selectRows([])

    if (!this.isValidQuery(query)) {
      return this.clearItems()
    }

    this.busy = true

    // Cancel pending request.
    if (this.requestCancelToken) {
      this.requestCancelToken.cancel()
    }

    this.requestCancelToken = axios.CancelToken.source()

    this.requestApi(query, this.requestCancelToken.token)
      .finally(() => { this.busy = false })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected requestApi (_query: string, _cancelToken: CancelToken): Promise<void> {
    return Promise.resolve()
  }

  protected selectRows (rows: ApiDataObject[]): void {
    this.$emit('selected', rows)
  }
}
