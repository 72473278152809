import { LOAD_GEOMETRIES_COORDS } from '@/store/action-types'
import { GET_GEOMETRIES } from '@/store/getter-types'
import { ADD_GEOMETRIES_TO_CACHE, RESET_GEOMETRIES_CACHE } from '@/store/mutation-types'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { differenceBy, intersectionBy } from 'lodash'
import { Geometries, Geometry } from '@/types/common'
import { i18n } from '@/locales/i18n'

const state = {
  geometries: []
}

// getters
const getters = {
  [GET_GEOMETRIES] (state) {
    return state.geometries
  }
}

const actions = {
  async [LOAD_GEOMETRIES_COORDS] ({ commit, getters }, geometries: Geometries) {
    const geometriesToUpload: Geometries = differenceBy(geometries, getters[GET_GEOMETRIES], 'id')

    if (geometriesToUpload.length) {
      try {
        const promises: Promise<Geometry>[] = []
        geometriesToUpload.forEach(geometry => promises.push(dataDeliveryApi.getGeometry(geometry.id)))

        const uploadedGeometries = await Promise.all(promises)
        commit(ADD_GEOMETRIES_TO_CACHE, uploadedGeometries)
      } catch (error) {
        return Promise.reject(Error(i18n.t('visualization.errors.load-geometries-data-failed') as string))
      }
    }

    return Promise.resolve(intersectionBy(getters[GET_GEOMETRIES], geometries, 'id'))
  }
}

// mutations
const mutations = {
  [ADD_GEOMETRIES_TO_CACHE] (state, geometries) {
    state.geometries = state.geometries.concat(geometries)
  },
  [RESET_GEOMETRIES_CACHE] (state) {
    state.geometries = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
