import { Bounds, LatLng, LatLngBounds, Path, PathOptions, Point } from 'leaflet'
import { ContextMenuCallbackEvent, ZIndexLayerOptions } from '@/types/leaflet'
import { CustomMapRenderer } from '@/lib/leaflet/renderer'
import { ProjectedShape } from '@/lib/leaflet/layer/projected-shape'

export interface ZIndexedPathOptions extends PathOptions, ZIndexLayerOptions {
  contextmenuItems?: { disabled: boolean }[]
}

export type LayerOrder = {
  // eslint-disable-next-line no-use-before-define
  layer: ZIndexedLayer,
  zIndex: number,
  prev: LayerOrder | null,
  next: LayerOrder | null
}

export abstract class ZIndexedLayer<TElement = ProjectedShape, TOptions extends ZIndexedPathOptions = ZIndexedPathOptions> extends Path {
  // @ts-ignore - declaration of missing PATH constructor
  constructor (latlngs: LatLng[] | LatLng[][] | LatLng[][][], options: ZIndexedPathOptions)

  public declare options: TOptions

  protected declare _latlngs: LatLng[] | LatLng[][] | LatLng[][][]

  protected declare _bounds: LatLngBounds
  protected _pxBounds!: Bounds
  protected declare _rawPxBounds: Bounds

  protected declare _renderer: CustomMapRenderer
  public declare _parts: TElement[]

  public _order!: LayerOrder

  protected declare _clickTolerance: () => number

  abstract _showContextMenu (e: ContextMenuCallbackEvent): void

  protected abstract _containsPoint (p: Point): boolean
  public abstract getPointIndex (p: Point): number

  protected abstract projectLatlngs (latlngs: LatLng[] | LatLng[][] | LatLng[][][]): Bounds
  protected abstract _updateBounds (): void

  protected abstract _update (): void
  protected abstract clipShapes (): Bounds | null
  protected abstract clipShapesToBounds (bounds: Bounds | null): void
  protected abstract _updatePath (): void

  public _project (): void {
    const pxBounds = this.projectLatlngs(this._latlngs)

    // @ts-ignore Leaflet types are incorrect here, isValid() does exist in Bounds type.
    if (this._bounds.isValid() && pxBounds.isValid()) {
      this._rawPxBounds = pxBounds
      this._updateBounds()
    }
  }
}
