import { Component, Vue } from 'vue-property-decorator'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { Geometries, ObjectIds } from '@/types/common'
import { LatLng } from 'leaflet'

@Component
export class GeometriesListMixin extends Vue {
  protected async getGeometriesForPoint (point: LatLng): Promise<Geometries> {
    const geometryIds = await this.getAreaIdsForPoint(point)
    const promises = geometryIds.map(id => dataDeliveryApi.getGeometry(id))
    return Promise.all(promises)
  }

  protected async getAreaIdsForPoint (point: LatLng): Promise<ObjectIds> {
    const { lat: latitude, lng: longitude } = point
    const geometries = await dataDeliveryApi.getAreaKeysForPoint({ latitude, longitude })

    return geometries.map(geometry => geometry.id)
  }
}
