import { i18n } from '@/locales/i18n'
import {
  PLAN_WIZARD_LINK_NAMESPACE,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID,
  WIZARD_STEP_FLIGHTS_ALL_AREAS_HAVE_FLIGHTS,
  WIZARD_STEP_FLIGHTS_SELECTED_FLIGHTS,
  WIZARD_TAB_FLIGHTS_ERRORS,
  WIZARD_TAB_FLIGHTS_IS_EMPTY,
  WIZARD_TAB_FLIGHTS_IS_ENABLED,
  WIZARD_TAB_FLIGHTS_IS_INVALID
} from '@/store/getter-types'
import {
  PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK,
  WIZARD_TAB_FLIGHTS_LEAVE,
  WIZARD_TAB_FLIGHTS_VALIDATE
} from '@/store/action-types'
import { WIZARD_STEP_FLIGHTS } from '@/components/planning/wizard/constants/steps'

// initial state
const state = {
  stepId: WIZARD_STEP_FLIGHTS
}

// getters
const getters = {
  [WIZARD_TAB_FLIGHTS_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[PLAN_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId)
  },
  [WIZARD_TAB_FLIGHTS_IS_EMPTY] (state, getters, rootState, rootGetters) {
    return rootGetters[WIZARD_STEP_FLIGHTS_SELECTED_FLIGHTS].length === 0
  },
  [WIZARD_TAB_FLIGHTS_IS_INVALID] (state, getters) {
    return getters[WIZARD_TAB_FLIGHTS_ERRORS].length > 0
  },
  [WIZARD_TAB_FLIGHTS_ERRORS] (state, getters) {
    const errors = []

    if (!getters[WIZARD_STEP_FLIGHTS_ALL_AREAS_HAVE_FLIGHTS]) {
      errors.push(i18n.t('planning.wizard.errors.flights-not-assigned'))
    }

    return errors
  }
}

// actions
const actions = {
  [WIZARD_TAB_FLIGHTS_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[WIZARD_TAB_FLIGHTS_ERRORS])
  },
  [WIZARD_TAB_FLIGHTS_LEAVE] ({ dispatch, state }) {
    return dispatch(PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK, state.stepId)
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
