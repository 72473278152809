import { clone } from 'lodash'
import { USER_FIRST_NAME, USER_LAST_NAME, USER_ROLES, USER_USERNAME } from '@/store/getter-types'
import {
  CLEAR_USER_DATA,
  SET_USER_FIRST_NAME,
  SET_USER_LAST_NAME,
  SET_USER_ROLES,
  SET_USER_USERNAME
} from '@/store/mutation-types'
import { SET_USER } from '@/store/action-types'

const defaultState = {
  username: null,
  firstName: null,
  lastName: null,
  roles: []
}

const state = clone(defaultState)

const getters = {
  [USER_USERNAME] (state) {
    return state.username
  },
  [USER_FIRST_NAME] (state) {
    return state.firstName
  },
  [USER_LAST_NAME] (state) {
    return state.lastName
  },
  [USER_ROLES] (state) {
    return state.roles
  }
}

const mutations = {
  [SET_USER_USERNAME] (state, payload) {
    state.username = payload
  },
  [SET_USER_FIRST_NAME] (state, payload) {
    state.firstName = payload
  },
  [SET_USER_LAST_NAME] (state, payload) {
    state.lastName = payload
  },
  [SET_USER_ROLES] (state, payload) {
    state.roles = payload
  },
  [CLEAR_USER_DATA] (state) {
    Object.entries(defaultState).forEach(([prop, value]) => {
      state[prop] = value
    })
  }
}

const actions = {
  [SET_USER] ({ commit }, { username, lastName, firstName, roles }) {
    commit(SET_USER_USERNAME, username)
    commit(SET_USER_FIRST_NAME, firstName)
    commit(SET_USER_LAST_NAME, lastName)
    commit(SET_USER_ROLES, roles)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
