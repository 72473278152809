import { Component, Inject, Vue } from 'vue-property-decorator'
import { AddGridEventListener, CallColumnApi, CallGridApi, GridEventListener } from '../types'
import { AgGridEvent } from 'ag-grid-community'

@Component
export class GridChildMixin extends Vue {
  @Inject('callGridApi') protected readonly callGridApi!: CallGridApi
  @Inject('onGridApi') private readonly _onGridApi!: AddGridEventListener
  @Inject('callColumnApi') protected readonly callColumnApi!: CallColumnApi

  onGridApi<E extends AgGridEvent = AgGridEvent> (event: string, handler: GridEventListener<E>): Promise<void> {
    return this._onGridApi(event, handler as GridEventListener<AgGridEvent>)
  }
}
