import commonStepStore from './steps/wizard-step-common.store'
import locationStepStore from './steps/wizard-step-location.store'
import mediaTypesStepStore from './steps/wizard-step-media-types.store'
import flightsStepStore from './steps/wizard-step-filghts.store'
import filtersStepStore from './steps/wizard-step-filters.store'
import rankingsStepStore from './steps/wizard-step-rankings.store'
import goalsStepStore from './steps/wizard-step-goals.store'
import commonSettingsStepStore from './steps/wizard-step-common-settings.store'

// initial state
const state = {}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    commonStep: commonStepStore,
    locationStep: locationStepStore,
    mediaTypesStep: mediaTypesStepStore,
    flightsStep: flightsStepStore,
    filtersStep: filtersStepStore,
    rankingsStep: rankingsStepStore,
    goalsStep: goalsStepStore,
    commonSettingsStep: commonSettingsStepStore
  }
}
