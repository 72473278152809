import { Point } from 'leaflet'
import { ProjectedShape } from '@/lib/leaflet/layer/projected-shape'

export class ProjectedMarker implements ProjectedShape {
  readonly point: Point
  readonly size: number
  readonly index: number

  constructor (point: Point, size: number, markerIndex: number) {
    this.point = point
    this.size = size
    this.index = markerIndex
  }

  containsPoint (p: Point, tolerance: number): boolean {
    return p.distanceTo(this.point) <= this.size + tolerance
  }
}
