import SearchableGeometryList from '@/components/shared/searchable-geometry-list/searchable-geometry-list.vue'
import GeometriesListByCoords from './components/geometries-list-by-coords.vue'
import { VueComponentMap } from '@/types/vue'

export const FIND_GEOMETRY = 'find-geometry'
export const SEARCH_BY_COORDS = 'search-by-coords'
export const SEARCH_BY_NAME = 'search-by-name'

export const searchMapping: VueComponentMap = {
  [SEARCH_BY_NAME]: SearchableGeometryList,
  [SEARCH_BY_COORDS]: GeometriesListByCoords
}
