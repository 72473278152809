import { constantsApi } from '@/api/rest/constants/constants.api'
import { SITE_TYPES, SITE_TYPES_GROUPED_BY_PARENT, SITE_TYPES_INITIALIZED } from '@/store/getter-types'
import { LOAD_SITE_TYPES } from '@/store/action-types'
import { SET_SITE_TYPES } from '@/store/mutation-types'
import { isNetworkMediaTypeFilterNode } from '@/utils/plan-wizard-steps'

const state = {
  initialized: false,
  collection: []
}

// getters
const getters = {
  [SITE_TYPES] (state) {
    return state.collection
  },
  [SITE_TYPES_GROUPED_BY_PARENT] (state, getters) {
    return (selectedMediaTypes) => {
      if (selectedMediaTypes.some(type => isNetworkMediaTypeFilterNode(type))) {
        return { MediaOwnerNetworks: selectedMediaTypes }
      }

      const parentTypes = getters[SITE_TYPES]
        .filter(siteType => siteType.value.indexOf(' ') === -1)

      const siteTypesGrouped = parentTypes
        .map(parentSiteType => ([parentSiteType.value, selectedMediaTypes.filter(selectedMediaType => selectedMediaType.name.indexOf(parentSiteType.value) === 0)]))
        .filter(siteTypesGroup => siteTypesGroup[1].length > 0)

      return Object.fromEntries(siteTypesGrouped)
    }
  },
  [SITE_TYPES_INITIALIZED] (state) {
    return state.initialized
  }
}

// actions
const actions = {
  [LOAD_SITE_TYPES] ({ commit, getters }) {
    if (getters[SITE_TYPES_INITIALIZED]) {
      return Promise.resolve(getters[SITE_TYPES])
    } else {
      return constantsApi.getSiteTypesWithBookingInfo()
        .then(collection => {
          commit(SET_SITE_TYPES, collection)

          return getters[SITE_TYPES]
        })
    }
  }
}

// mutations
const mutations = {
  [SET_SITE_TYPES] (state, payload) {
    if (Array.isArray(payload)) {
      state.collection = payload
      state.initialized = true
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
