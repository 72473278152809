import { Geometries, Geometry, GeometryBase } from '@/types/common'
import { findIndex, sortBy } from 'lodash'

export class GeometryCore {
  private geometries: Geometries = []

  constructor (geometries?: Geometries) {
    this.geometries = geometries ?? []
  }

  static compareGeometries (geometry1: GeometryBase, geometry2: GeometryBase): boolean {
    return geometry1.name === geometry2.name && geometry1.parentGeometrySetId === geometry2.parentGeometrySetId
  }

  private getGeometryById (geometryId: Geometry['id']): Geometry | undefined {
    return this.geometries.find(geometry => geometry.id === geometryId)
  }

  public setGeometries (geometries) {
    this.geometries = geometries
  }

  public get getGeometries (): Geometries {
    return this.geometries
  }

  public get filteredGeometries (): Geometries {
    const filteredGeometries = this.geometries
      .reduce((results, current) => {
        return findIndex(results, item => GeometryCore.compareGeometries(current, item)) !== -1 ? results : [...results, current]
      }, [] as Geometries)

    return sortBy(filteredGeometries, ['name'])
  }

  public findGeometry (geometryId: Geometry['id']): Geometries {
    const geometry = this.getGeometryById(geometryId)
    if (geometry) {
      return this.geometries.filter(item => GeometryCore.compareGeometries(geometry, item))
    }

    return []
  }
}
