import { GRID_COLUMN_TYPE_NUMBER, GRID_COLUMN_TYPE_STRING } from '@/components/shared/ag-grid/constants'
import { ColDef, ValueGetterParams } from 'ag-grid-community'
import { customValueWrapper } from '@/components/shared/ag-grid/utils'
import { capitalize, isNull } from 'lodash'

export enum PointsFields {
  Name = 'name',
  Latitude = 'latitude',
  Longitude = 'longitude'
}

export type PointsSettings = {
  [key: string]: ColDef
}

const COORDINATES_FIX_NUMBER = 4

const coordinatesValueGetter = (params: ValueGetterParams) => {
  const callback = (params: ValueGetterParams) => {
    const value = params.data[params.colDef.field as string]
    return !isNull(value) ? Number(value).toFixed(COORDINATES_FIX_NUMBER) : null
  }
  return customValueWrapper(params, callback)
}

export const pointListFieldsSettings: PointsSettings = {
  [PointsFields.Name]: {
    field: PointsFields.Name,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerName: capitalize(PointsFields.Name),
    type: GRID_COLUMN_TYPE_STRING
  },
  [PointsFields.Latitude]: {
    field: PointsFields.Latitude,
    headerName: capitalize(PointsFields.Latitude),
    type: GRID_COLUMN_TYPE_NUMBER,
    valueGetter: coordinatesValueGetter
  },
  [PointsFields.Longitude]: {
    field: PointsFields.Longitude,
    headerName: capitalize(PointsFields.Longitude),
    type: GRID_COLUMN_TYPE_NUMBER,
    valueGetter: coordinatesValueGetter
  }
}
