












import { WIZARD_NAVIGATION_PREV_DISABLED } from '@/store/getter-types'
import WizardStepNavigatorMixin from '../../mixins/wizard-steps-navigator.mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class WizardPrevButton extends Mixins<WizardStepNavigatorMixin>(WizardStepNavigatorMixin) {
  protected get isPrevDisabled (): boolean {
    return this.dynamicStoreGetter<boolean>(WIZARD_NAVIGATION_PREV_DISABLED)
  }
}
