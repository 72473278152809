import { Component, Mixins } from 'vue-property-decorator'
import { GridApiMixin } from './grid-api.mixin'

@Component
export class GridOverlayMixin extends Mixins(GridApiMixin) {
  mounted () {
    this.listenOnGridApi('rowDataChanged', this.updateGridOverlay)
    this.listenOnGridApi('rowDataUpdated', this.updateGridOverlay)
  }

  updateGridOverlay (): void {
    this.$nextTick(() => {
      this.callGridApiOrQueue((gridApi) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowData: never[] = (this as any).rowDataModel || (this as any).rowData

        if (!rowData) {
          gridApi.showLoadingOverlay()
        } else if (!rowData.length) {
          gridApi.showNoRowsOverlay()
        } else {
          gridApi.hideOverlay()
        }
      })
    })
  }
}
