import { TranslateResult } from 'vue-i18n'
import { RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_ERRORS } from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-select-key-columns/getters'
import { RANKING_IMPORT_WIZARD_KEY_COLUMNS_RESET } from '@/store/ranking-import-wizard/actions'

export const RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_INVALIDATE = 'ranking-import/key-columns-tab/invalidate'
export const RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_VALIDATE = 'ranking-import/key-columns-tab/validate'

export const actions = {
  async [RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_VALIDATE] ({ getters }): Promise<TranslateResult[]> {
    return getters[RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_ERRORS]
  },
  [RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_INVALIDATE] ({ dispatch }) {
    return dispatch(RANKING_IMPORT_WIZARD_KEY_COLUMNS_RESET)
  }
}
