import { PluginObject } from 'vue'
import ImagePickerComponent from './image-picker.vue'

type PickImageResult = {
  submitted: boolean;
  image?: File;
}

type PickImageFunction = () => Promise<PickImageResult>;

declare module 'vue/types/vue' {
  interface Vue {
    $pickImage: PickImageFunction;
  }
}

export class ImagePickerPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$pickImage = async function (): Promise<PickImageResult> {
      const pickerVNode = this.$createElement(ImagePickerComponent)

      const dialogProps = {
        size: 'md',
        centered: true
      }

      const dialogSlots = {
        'modal-title': this.$t('visualization.layer-settings-modal.labels.upload-image')
      }

      const submitted = await this.$modalDialog.msgBoxConfirm(pickerVNode, dialogProps, dialogSlots)

      return {
        submitted,
        image: submitted ? pickerVNode.componentInstance.fileData : null
      }
    }
  }
}
