
















































































import { assign } from 'lodash'
import tinycolor from 'tinycolor2'
import { PickerMixin } from './mixin/picker.mixin'
import { PickerPropsMixin } from './mixin/picker-props.mixin'
import EditableInput from './common/editable-input.vue'
import Saturation from './common/saturation.vue'
import Hue from './common/hue.vue'
import Alpha from './common/alpha.vue'
import Presets from './common/presets.vue'
import ColorCompare from './common/color-compare.vue'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { BackgroundProperty } from 'csstype'
import { VueDynamicClassDef } from '@/types/vue'
import { ColorFormatHEX, ColorInputData } from '@/types/color-data'

@Component({
  components: {
    ColorCompare,
    Presets,
    Saturation,
    Hue,
    Alpha,
    EditableInput
  }
})
export default class ColorPicker extends Mixins<PickerMixin, PickerPropsMixin>(PickerMixin, PickerPropsMixin) {
  @Prop({ default: false }) noBorder!: boolean
  @Prop({ default: false }) disableHex!: boolean

  private get activeColor (): BackgroundProperty<string> {
    const rgba = this.colorModel.rgba

    return 'rgba(' + [rgba.r, rgba.g, rgba.b, rgba.a].join(',') + ')'
  }

  private get pickerClass (): VueDynamicClassDef {
    return {
      'color-picker--no-border': this.noBorder
    }
  }

  private handlePreset (color: ColorFormatHEX['hex']): void {
    if (this.presetMode === 'color') {
      // Keep current alpha if we want only color value from preset.
      color = tinycolor(color).setAlpha(this.colorModel.a).toHex8String()
    }

    this.colorChange({
      hex: color,
      source: 'hex'
    })
  }

  private childChange (data: ColorInputData): void {
    this.colorChange(data)
  }

  private inputChange (data: ColorInputData): void {
    if (!data) {
      return
    }

    if ('hex' in data && data.hex) {
      this.isValidHex(data.hex) && this.colorChange({
        hex: data.hex,
        source: 'hex'
      })
    } else {
      this.colorChange(assign({ source: 'rgba' }, this.colorModel.rgba, data))
    }
  }
}
