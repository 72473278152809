














import { Component, Mixins } from 'vue-property-decorator'
import { CellRendererMixin } from '@/components/shared/ag-grid/mixins/grid-components/cell-renderer.mixin'

@Component
export default class PairsActionsCell extends Mixins(CellRendererMixin) {
  private removeRow (): void {
    this.params.api.applyTransaction({
      remove: [this.params.data]
    })
  }
}
