import { LatLng, LatLngBounds, setOptions } from 'leaflet'
import { MultiPointsLayer, MultiPointsLayerOptions } from '@/lib/leaflet/layer/multi-markers/multi-points-layer'
import { ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

export class MultiCircleMarker<TOptions extends MultiPointsLayerOptions = MultiPointsLayerOptions> extends MultiPointsLayer<TOptions> {
  initialize (latlngs: LatLng[], options: ZIndexedPathOptions): void {
    const mergedOptions: MultiPointsLayerOptions = {
      fill: true,
      size: 10,
      zIndex: 1,
      ...options
    }

    setOptions(this, mergedOptions)
    this._setLatLngs(latlngs)
  }

  public getBounds (): LatLngBounds {
    return this._bounds
  }

  protected _updatePath () {
    this._renderer.updateMultiCircleMarker(this)
  }

  public getMarkerSize (index: number): number {
    let radius = this.options.size as number

    if (Array.isArray(radius)) {
      radius = radius[index]
    }

    return radius
  }
}
