import RangeAwareMixin from './range-aware.mixin'
import { FilledGeometryLayer } from '@/types/visualization/layer'
import { RangeFillValueConfig } from '@/types/visualization/layer/style'
import { Color } from 'csstype'
import { Component } from 'vue-property-decorator'
import { LayerGeometry } from '@/types/visualization/layer/geometry'

@Component
export default class RangeFillMixin<TLayer extends FilledGeometryLayer> extends RangeAwareMixin<TLayer> {
  protected get rangeFillDataRows (): LayerGeometry[] {
    const col = this.layer.style.fill.gradientColumn
    return this.getRangeDataRows(col, this.layer.style.fill, this.rangeFillAbsoluteValues)
  }

  protected get rangeFillColorFunc () {
    const gradientColumn = this.layer.style.fill.gradientColumn

    if (typeof gradientColumn === 'undefined') {
      return () => null
    }

    return row => this.rangeFillColor(row.meta[gradientColumn])
  }

  protected get rangeFillOpacityFunc () {
    const gradientColumn = this.layer.style.fill.gradientColumn

    if (typeof gradientColumn === 'undefined') {
      return () => null
    }

    return row => this.rangeFillOpacity(row.meta[gradientColumn])
  }

  protected get rangeFillAbsoluteValues (): RangeFillValueConfig[] {
    return this.layer.style.fill.range?.values ?? []
  }

  protected get quantityOfFillValuesForRange (): number[] {
    return this.calculateQuantityOfValuesForRange(this.rangeFillAbsoluteValues, this.layer.style.fill.gradientColumn)
  }

  private rangeFillColor (rangeValue: number): Color {
    return this.calculateRangeFillOptions(rangeValue).color as Color
  }

  private rangeFillOpacity (rangeValue: number): number {
    return this.calculateRangeFillOptions(rangeValue).opacity as number
  }

  private calculateRangeFillOptions (rangeValue: number): RangeFillValueConfig {
    return this.calculateRangeColor(rangeValue, this.rangeFillAbsoluteValues)
  }
}
