import { createTransformResponse } from '@/api/utils'
import { AxiosResponse } from 'axios'
import { TextValuePair } from '@/types/common'

export const constantsResponseTransform = createTransformResponse<TextValuePair[]>((response: AxiosResponse['data']): TextValuePair[] => {
  let fromServer = JSON.parse(response)
  const parsedResponse: TextValuePair[] = []

  Array.isArray(fromServer) && fromServer.forEach((constant) => {
    // TODO - Possible improvement. Ask back-end to send array of format [ {text, value} ] here
    parsedResponse.push({
      text: constant.constantName,
      value: constant.constantValue
    })
  })
  fromServer = null

  return parsedResponse
})
