import { createDecorator, VueDecorator } from 'vue-class-component'
import Vue, { ComponentOptions } from 'vue'
import { RuleDecl, ValidatorsDefinition } from '@/vendor/types/vuelidate'

type ValidationsDef = ValidatorsDefinition & {
  __validations__?: string;
}

declare module 'vue/types/options' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ComponentOptions<V extends Vue> {
    __validations?: ValidationsDef;
  }
}

function createValidations (this: Vue): RuleDecl {
  const validationDefs: ValidationsDef = this.$options.__validations ?? {}
  const validations: RuleDecl = { }

  for (const key in validationDefs) {
    if (!Object.prototype.hasOwnProperty.call(validationDefs, key)) {
      continue
    }

    if (key === '__validations__') {
      const propName: string | undefined = validationDefs[key]

      if (!propName) {
        continue
      }

      let rules: ValidatorsDefinition = this[propName]

      if (typeof rules === 'function') {
        rules = rules.call(this)
      }

      Object.assign(validations, rules)

      continue
    }

    const rule = validationDefs[key]
    validations[key] = typeof rule === 'function' ? rule.call(this) : rule
  }

  return validations
}

function initOptionsValidation (options: ComponentOptions<Vue>): ValidationsDef {
  if (!options.__validations) {
    options.__validations = {}
  }

  options.validations = createValidations

  return options.__validations
}

export function Validations (): VueDecorator {
  return createDecorator((options: ComponentOptions<Vue>, key: string): void => {
    const validations: ValidationsDef = initOptionsValidation(options)

    validations.__validations__ = key
  })
}

export function Validation (rule: ValidatorsDefinition): VueDecorator {
  return createDecorator((options: ComponentOptions<Vue>, key: string): void => {
    const validations: ValidationsDef = initOptionsValidation(options)

    validations[key] = rule
  })
}
