import { geocodingApi } from '@/api/rest/geocoding/geocoding.api'
import axios from 'axios'
import { GeocodingDetails } from '@/components/shared/coordinates-service/types'

export type GeocodingResults = (GeocodingDetails | symbol)[]

export const canceled = Symbol('cancelGeocode')
export function geocoding (addresses, successCallback, errorCallback, cancelToken): Promise<GeocodingResults> {
  const requests = addresses.map((address) => {
    return geocodingApi.geocode(address, cancelToken)
      .then(data => {
        successCallback(data)
        return data
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return canceled
        }
        errorCallback(error)
        return null
      })
  })

  return Promise.all(requests)
}
