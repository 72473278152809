
























import { Component, Mixins } from 'vue-property-decorator'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { Validations } from '@/decorators'
import { required } from 'vuelidate/lib/validators'
import ImportCitiesColumns from './import-cities-columns.vue'
import ImportCities from './import-cities.vue'
import MatchRowsProcessing from '@/components/planning/wizard/filter-editor/ui-controls/components/match-rows-processing.vue'
import { isEmpty, uniq } from 'lodash'
import { MatchRowsHelper } from '@/utils/rows-matching-helper/match-rows-helper'
import { DatasetRows } from '@/types/common'
import { MatchDatasetMixin } from '@/components/planning/wizard/filter-editor/ui-controls/mixins/match-dataset.mixin'
import MatchDatasetModal from '@/components/shared/match-dataset-modal/match-dataset-modal.vue'

@Component({
  components: { MatchDatasetModal, ImportCities, MatchRowsProcessing, ImportCitiesColumns }
})
export default class ImportCitiesModal extends Mixins<MatchDatasetMixin>(MatchDatasetMixin) {
  private cityColumn: string | null = null
  private okzColumn: string | null = null

  @Validations()
  private validations (): RuleDecl {
    return {
      dataset: { required },
      cityColumn: { required }
    }
  }

  protected override resetOwnProperties (): void {
    this.cityColumn = null
    this.okzColumn = null
  }

  protected override datasetHasEmptyValues (): boolean {
    const validationCallback = (row) => {
      const cityColumnIsEmpty = typeof row[this.cityColumn as string] !== 'string' || isEmpty(row[this.cityColumn as string].trim())

      return this.okzColumn ? cityColumnIsEmpty && isEmpty(row[this.okzColumn].trim()) : cityColumnIsEmpty
    }

    return this.dataset?.datasetRows.some(validationCallback) as boolean
  }

  protected override runImportProcess () {
    const _helper = new MatchRowsHelper(this.datasetRows)
    const { foundValues, notFoundValues, supposedValues } = _helper.importCitiesFromDataset(this.dataset?.datasetRows as DatasetRows, this.cityColumn as string, this.okzColumn)

    if (supposedValues !== undefined && !isEmpty(supposedValues)) {
      const cities = supposedValues.map(item => Object.values(item).join('-'))
      const message = this.$t('planning.filters.errors.cities-import-imprecise', { cities: uniq(cities).join(', ') })
      this.$toastWarning(message as string)
    }

    if (!isEmpty(notFoundValues)) {
      const cities = notFoundValues.map(item => Object.values(item).join('-'))
      const message = this.$t('planning.filters.errors.cities-not-found', { cities: uniq(cities).join(', ') })
      this.$toastError(message as string)
    }

    this.$emit('import', foundValues)
    this.showModal = false
  }

  protected override showFormInvalidErrorMsg (): void {
    this.$toastWarning(this.$t('planning.filters.errors.import-dataset-error', { columns: 'City, OKZ' }) as string)
  }
}
