





























import { Component, Mixins } from 'vue-property-decorator'
import { ModalMixin } from '@/mixins/modal.mixin'
import ModalWizard from '@/components/shared/wizard/modal-wizard.vue'
import { WIZARD_INIT } from '@/store/action-types'
import { stepsConfig } from './constants/steps-config'
import { DynamicStoreMappingsMixin } from '@/mixins/dynamic-store/dynamic-store-mappings.mixin'
import { VueComponentDeclaration } from '@/types/vue'
import { WizardStepConfig } from '@/types/wizard'
import { RANKING_IMPORT_NAMESPACE } from '@/store/ranking-import-wizard/getters'
import { RANKING_IMPORT_WIZARD_GET_RESULTS, RANKING_IMPORT_WIZARD_RESET } from '@/store/ranking-import-wizard/actions'
import { DatasetRows } from '@/types/common'
import { mapGetters } from 'vuex'

@Component({
  components: { ModalWizard },
  computed: {
    ...mapGetters({
      namespace: RANKING_IMPORT_NAMESPACE
    })
  }
})
export default class RankingImportWizard extends Mixins<ModalMixin, DynamicStoreMappingsMixin>(ModalMixin, DynamicStoreMappingsMixin) {
  private initWizard (): void {
    this.dispatchDynamicStoreAction(WIZARD_INIT, { steps: stepsConfig })
  }

  protected stepComponent (stepId: string): VueComponentDeclaration | null {
    return stepsConfig[stepId]?.component ?? null
  }

  protected getWizardTitle (step: WizardStepConfig | null): string | undefined {
    return step?.getName()
  }

  private resetWizard (): Promise<void> {
    return this.$store.dispatch(RANKING_IMPORT_WIZARD_RESET)
  }

  private getResultRows (): Promise<DatasetRows> {
    return this.$store.dispatch(RANKING_IMPORT_WIZARD_GET_RESULTS)
  }

  private async onFinish (): Promise<void> {
    const results = await this.getResultRows()
    this.$emit('wizard-finished', results)

    await this.closeModal()
  }
}
