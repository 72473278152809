import { i18n } from '@/locales/i18n'
import {
  VICINITY_IMPORT_LINK_NAMESPACE,
  VICINITY_IMPORT_WIZARD_DATASET,
  VICINITY_IMPORT_WIZARD_DATASET_WAS_UPDATED,
  VICINITY_IMPORT_WIZARD_TAB_DATASET_CAN_SKIP,
  VICINITY_IMPORT_WIZARD_TAB_DATASET_ERRORS,
  VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY,
  VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_ENABLED,
  VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_INVALID,
  VICINITY_IMPORT_WIZARD_TAB_DATASET_STEP_ID
} from '@/store/getter-types'
import {
  VICINITY_IMPORT_WIZARD_TAB_DATASET_LEAVE,
  VICINITY_IMPORT_WIZARD_TAB_DATASET_VALIDATE, WIZARD_INVALIDATE_AFTER
} from '@/store/action-types'
import { VICINITY_IMPORT_DATASET } from '@/components/planning/vicinity-import-wizard/constants/steps'
import { VICINITY_IMPORT_WIZARD_DATASET_SET_UPDATE_STATE } from '@/store/mutation-types'

// initial state
const state = {
  stepId: VICINITY_IMPORT_DATASET
}

// getters
const getters = {
  [VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_ENABLED] () {
    return true
  },
  [VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY] (state, getters, rootState, rootGetters) {
    return !rootGetters[VICINITY_IMPORT_WIZARD_DATASET]
  },
  [VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_INVALID] (state, getters) {
    return getters[VICINITY_IMPORT_WIZARD_TAB_DATASET_ERRORS].length > 0
  },
  [VICINITY_IMPORT_WIZARD_TAB_DATASET_CAN_SKIP] () {
    return false
  },
  [VICINITY_IMPORT_WIZARD_TAB_DATASET_ERRORS] (state, getters) {
    const errors = []

    if (getters[VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY]) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.select-dataset'))
    }

    return errors
  },
  [VICINITY_IMPORT_WIZARD_TAB_DATASET_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [VICINITY_IMPORT_WIZARD_TAB_DATASET_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[VICINITY_IMPORT_WIZARD_TAB_DATASET_ERRORS])
  },
  async [VICINITY_IMPORT_WIZARD_TAB_DATASET_LEAVE] ({ getters, commit, dispatch }) {
    if (getters[VICINITY_IMPORT_WIZARD_DATASET_WAS_UPDATED]) {
      const namespacedAction = getters[VICINITY_IMPORT_LINK_NAMESPACE](WIZARD_INVALIDATE_AFTER)
      await dispatch(namespacedAction)
      commit(VICINITY_IMPORT_WIZARD_DATASET_SET_UPDATE_STATE, false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
