import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { isEqual } from 'lodash'
import { FormInputSize } from '@/types/bootstrap'

@Component
export class RuleMixin<TValue> extends Vue {
  @Model('input', { required: true }) value!: TValue
  @Prop({ required: true }) readonly size!: FormInputSize

  get internalValue (): TValue {
    return this.value
  }

  set internalValue (newValue: TValue) {
    if (!isEqual(newValue, this.value)) {
      this.$emit('input', newValue)
    }
  }
}
