export enum LayerType {
  Shapes = 'DrawPolygonShapes',
  Circles = 'DrawCircleShapes',
  Points = 'DrawPoints'
}

export enum ShapeType {
  Polygon = 'polygon',
  Line = 'line',
}

export enum PathStyle {
  None = 'none',
  Solid = 'solid',
  Dotted = 'dotted',
  Dashed = 'dashed',
}

export enum StaticLayerPosition {
  TopLeft = 'topleft',
  TopRight = 'topright',
  BottomRight = 'bottomright',
  BottomLeft = 'bottomleft',
}

export enum FillType {
  Color = 'color',
  Gradient = 'gradient',
  Heatmap = 'heatmap',
  Median = 'median',
  Quartile = 'quartile',
  Decile = 'decile',
  Clustering = 'cluster',
  Icon = 'icon',
  Range = 'range'
}

export enum MarkerType {
  Circle,
  Triangle,
  ReverseTriangle,
  Square,
  Pin
}
