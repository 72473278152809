








































import { Vue, Component, Prop } from 'vue-property-decorator'
import { BTable } from 'bootstrap-vue'
import Tags from '@/components/shared/tags/tags.vue'

@Component({
  components: { Tags }
})
export default class TagsTable extends Vue {
  @Prop({ default: () => [] }) tableItems!: BTable['items'];
  @Prop({ default: () => ({}) }) tableOptions!: BTable;

  private get fields (): BTable['fields'] {
    return ([
      { key: 'name', class: 'text-break' },
      { key: 'tags' }
    ])
  }
}
