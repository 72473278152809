import QuantileAwareMixin from './quantile-aware.mixin'
import { Component } from 'vue-property-decorator'
import { FilledGeometryLayer } from '@/types/visualization/layer'
import { LayerGeometry, LayerPoint, LayerPolygon } from '@/types/visualization/layer/geometry'
import { ColorModel } from '@/types/color-data'
import { LayerColorGetter, LayerOpacityGetter, QuantileFillConfig } from '@/types/visualization/layer/style'
import { Color } from 'csstype'
import { DatasetColumnKey } from '@/types/common'

@Component
export default class QuantileFillMixin<TLayer extends FilledGeometryLayer> extends QuantileAwareMixin<TLayer> {
  protected get quantileFillDataRows (): LayerGeometry[] {
    const col = this.layer.style.fill.gradientColumn
    const fillValues = this.quantileFillValues

    return this.getQuantileDataRows(col, this.layer.style.fill, fillValues)
  }

  protected get quantileFillStartRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.fill.gradientStartColor, this.layer.style.fill.gradientStartOpacity)
  }

  protected get quantileFillEndRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.fill.gradientEndColor, this.layer.style.fill.gradientEndOpacity)
  }

  protected minQuantileFillValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMinGradientValue(gradientColumn)
  }

  protected maxQuantileFillValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMaxGradientValue(gradientColumn)
  }

  protected get quantileFillColorFunc (): LayerColorGetter<LayerPolygon | LayerPoint> {
    const gradientColumn = this.layer.style.fill.gradientColumn
    // Make this property depend on fill mode, gradient column, start and end colors.
    if (
      this.layer.style.fill.type === undefined ||
      gradientColumn === undefined ||
      this.quantileFillStartRgb === undefined ||
      this.quantileFillEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.quantileFillColor(row.meta[gradientColumn])
  }

  protected get quantileFillOpacityFunc (): LayerOpacityGetter<LayerPolygon | LayerPoint> {
    const gradientColumn = this.layer.style.fill.gradientColumn
    // Make this property depend on fill mode, gradient column, start and end colors.
    if (
      this.layer.style.fill.type === undefined ||
      gradientColumn === undefined ||
      this.quantileFillStartRgb === undefined ||
      this.quantileFillEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.quantileFillOpacity(row.meta[gradientColumn])
  }

  protected get quantileFillColors (): QuantileFillConfig[] {
    return this.calculateQuantileColors(this.fillQuantiles, this.quantileFillStartRgb, this.quantileFillEndRgb)
  }

  protected get fillQuantiles (): number[] {
    return this.calculateQuantiles(this.layer.style.fill.type)
  }

  protected get quantileFillValues (): number[] {
    return this.calculateQuantileValues(this.fillQuantiles, this.layer.style.fill.gradientColumn)
  }

  protected get quantityOfFillValuesForQuantile (): number[] {
    return this.calculateQuantityOfValuesForQuantile(this.quantileFillValues, this.layer.style.fill.gradientColumn)
  }

  protected quantileFillColor (quantileValue): Color {
    return this.calculateQuantileColorByValue(quantileValue, this.quantileFillValues, this.quantileFillColors).color as Color
  }

  protected quantileFillOpacity (quantileValue): ColorModel['a'] {
    return this.calculateQuantileColorByValue(quantileValue, this.quantileFillValues, this.quantileFillColors).opacity as ColorModel['a']
  }
}
