import { FilterSelectConfig, SelectOption } from '@/types/planning/wizard/filter-editor'
import { SelectionOptionsConfig } from '@/utils/filter-editor/value-config/select/selection-options-config'
import { PpsFilterModes } from '@/types/planning/wizard/enums'
import { i18n } from '@/locales/i18n'

export class PpsFilterModeConfig extends SelectionOptionsConfig<PpsFilterModes> implements FilterSelectConfig<PpsFilterModes> {
  protected override _defValue = PpsFilterModes.Absolute

  override get options (): SelectOption[] {
    return [
      { value: PpsFilterModes.Absolute, get text () { return i18n.t('planning.filters.labels.pps.mode-range') as string } },
      { value: PpsFilterModes.Relative, get text () { return i18n.t('planning.filters.labels.pps.mode-relative') as string } }
    ]
  }
}
