import { Component, Mixins, Model, Prop } from 'vue-property-decorator'
import { FilterConfig, FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import { VModel } from '@/vendor/types/vuelidate/model'
import { FormInputState } from '@/types/bootstrap'
import { ValidationProviderMixin } from '@/mixins/validation/validation-provider.mixin'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { cloneDeep } from 'lodash'

@Component
export class UiControlMixin<TValue, TConfig extends FilterConfig<TValue> = FilterConfig<TValue>> extends Mixins(ValidationProviderMixin) implements FilterUIComponent<TValue> {
  @Model('change', { required: true }) readonly value!: TValue
  @Prop({ default: '' }) readonly paramName!: string
  @Prop({ required: true }) readonly config!: TConfig
  @Prop() readonly filterNode!: ScoringFunctionNode
  @Prop({ default: '' }) readonly funcName!: string

  selected: TValue = {} as TValue

  created () {
    this.selected = cloneDeep(this.value ?? this.config.defaultValue) as TValue
  }

  override $v!: VModel<UiControlMixin<TValue, TConfig>, 'selected'>

  protected get selectedInputState (): FormInputState {
    return this.getFieldValidationState('selected')
  }

  protected override initValidationObserver (): void {
    if (this.$v?.selected) {
      this.observeValidationModel(this.paramName, this.$v.selected)
    }
  }

  protected setSelected (value: TValue, reset = false): void {
    this.$v.selected.$model = value

    if (reset) {
      this.$v.selected.$reset()
    }
  }
}
