import RangeFillMixin from '@/components/visualization/map/mixins/range-fill.mixin'
import RangeLegendMixin from '../mixins/range-legend.mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class RangeFillLegendMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  Mixins<RangeFillMixin<_TLayer>, RangeLegendMixin<_TLayer>>(RangeFillMixin, RangeLegendMixin) {
  protected get rangeFillLegendValues () {
    const values = this.rangeFillAbsoluteValues
    return this.makeRangeLegendValues(values)
  }
}
