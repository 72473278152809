
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { ApiDataObject } from '@/types/common'
import { SearchType } from '@/types/api/data-delivery'
import SearchableTaggableList from '@/components/shared/searchable-list/searchable-taggable-list/searchable-taggable-list.vue'

@Component
export default class SearchableDatasetList extends SearchableTaggableList {
  @Prop({ default: false, type: Boolean }) fullDataset!: boolean
  @PropSync('data-loading', { default: false }) isDataLoading!: boolean

  protected override searchType: SearchType = 'iwdataset'

  protected override get placeholder (): TranslateResult {
    return this.$t('common.placeholders.search-dataset')
  }

  protected override selectRows (rows: ApiDataObject[]): void {
    rows = this.items.filter(item => rows.some(row => item.id === row.id))

    if (!this.fullDataset) {
      this.$emit('selected', rows)
      return
    }

    this.isDataLoading = true

    Promise.all(rows.map(item => dataDeliveryApi.getDataset(item.id)))
      .then((rows) => {
        this.isDataLoading = false
        this.$emit('selected', rows)
      })
  }
}
