
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class GridActionsMenu extends Vue {
  @Prop({ default: false }) private disabled!: boolean
}
