import { ApiColumnDataProp, ApiColumnType, ApiDataColumnType, ApiGeometryColumnType, DataColumnType } from '@/types/enums'
import { LayerColumns } from '@/types/visualization/layer/columns'
import { CircleLayerStyle, LatLngLayerStyle } from '@/types/visualization/layer/style'
import { DataColumn, NumberColumn } from '@/types/common'
import { isNumberColumn, isTextColumn } from '@/types/guards'
import {
  SUPPORTED_LATITUDE_COLUMNS,
  SUPPORTED_LONGITUDE_COLUMNS
} from '@/components/shared/coordinates-service/components/constants'

type ApiColumnMap = {
  [key in ApiDataColumnType]: DataColumnType;
}

type ApiColumnDataPropMap = {
  [key in ApiColumnType]: ApiColumnDataProp;
}

const API_COLUMN_TYPE_MAPPING: ApiColumnMap = {
  [ApiDataColumnType.Text]: DataColumnType.Text,
  [ApiDataColumnType.Number]: DataColumnType.Number
}

const API_COLUMN_DATA_PROP_MAPPING: ApiColumnDataPropMap = {
  [ApiDataColumnType.Text]: ApiColumnDataProp.Text,
  [ApiDataColumnType.Number]: ApiColumnDataProp.Number,
  [ApiGeometryColumnType.Polygon]: ApiColumnDataProp.Polygon,
  [ApiGeometryColumnType.Linestring]: ApiColumnDataProp.Linestring
}

const POSSIBLE_RADIUS_COLUMNS: string[] = ['radius']

/**
 * Get lowercase column type from column object.
 */
export function getApiColumnType (column): ApiColumnType | null {
  return column.columnType ? column.columnType.toLowerCase() : null
}

export function columnDataProp (column): ApiColumnDataProp {
  const columnType: ApiColumnType | null = getApiColumnType(column)
  const dataProp = columnType ? API_COLUMN_DATA_PROP_MAPPING[columnType] : undefined

  if (!dataProp) {
    throw new Error(`Unsupported column data type "${columnType}"`)
  }

  return dataProp
}

export function parseLayerColumns (columnTypes): LayerColumns {
  const columns: DataColumn[] = []

  for (let cc = columnTypes.length - 1; cc >= 0; cc--) {
    if (!API_COLUMN_TYPE_MAPPING[columnTypes[cc].type]) {
      continue
    }

    // Use trim() because coloumnId contains line breaks sometimes.
    columns.push({
      name: columnTypes[cc].name.trim(),
      type: API_COLUMN_TYPE_MAPPING[columnTypes[cc].type],
      min: columnTypes[cc].minimum,
      max: columnTypes[cc].maximum
    })
  }

  return {
    columns,
    numberColumns: columns.filter(isNumberColumn),
    textColumns: columns.filter(isTextColumn)
  }
}

export function guessLatLngColumns (layerStyle: LatLngLayerStyle, columns: NumberColumn[]): void {
  if (!layerStyle.latitudeColumn && !layerStyle.longitudeColumn) {
    trySetLatLngColumns(layerStyle, columns)
  }
}

function trySetLatLngColumns (layerStyle: LatLngLayerStyle, columns: NumberColumn[]): void {
  const latitudeColumn = columns.find((column) => {
    return SUPPORTED_LATITUDE_COLUMNS.includes(column.name.toLowerCase())
  })
  const longitudeColumn = columns.find((column) => {
    return SUPPORTED_LONGITUDE_COLUMNS.includes(column.name.toLowerCase())
  })

  // Both columns should be valid. If only one found we can't use it.
  if (!latitudeColumn || !longitudeColumn) {
    return
  }

  layerStyle.latitudeColumn = latitudeColumn.name
  layerStyle.longitudeColumn = longitudeColumn.name
}

export function guessRadiusColumn (layerStyle: CircleLayerStyle, columns: NumberColumn[]): void {
  if (!layerStyle.radiusColumn) {
    trySetRadiusColumn(layerStyle, columns)
  }
}

function trySetRadiusColumn (layerStyle: CircleLayerStyle, columns: NumberColumn[]): void {
  const radiusColumn = columns.find((column) => {
    return POSSIBLE_RADIUS_COLUMNS.includes(column.name.toLowerCase())
  })

  if (!radiusColumn) {
    return
  }

  layerStyle.radiusColumn = radiusColumn.name
}
