





























import {
  WIZARD_NAVIGATION_ACTIVE_ID,
  WIZARD_NAVIGATION_ACTIVE_IS_FIRST,
  WIZARD_NAVIGATION_ACTIVE_IS_LAST,
  WIZARD_NAVIGATION_CAN_SKIP_STEP
} from '@/store/getter-types'
import { WizardNavProps } from '../mixins/wizard-nav-props.mixin'
import WizardNextButton from './action-buttons/wizard-next-button.vue'
import WizardPrevButton from './action-buttons/wizard-prev-button.vue'
import WizardSkipButton from './action-buttons/wizard-skip-button.vue'
import WizardFinishButton from './action-buttons/wizard-finish-button.vue'
import { DynamicStoreMixin } from '@/mixins/dynamic-store.mixin'
import { Component, Mixins } from 'vue-property-decorator'
import { WizardStoreGetterCallback } from '@/types/wizard'

@Component({
  components: { WizardFinishButton, WizardPrevButton, WizardNextButton, WizardSkipButton }
})
export default class WizardFooterNav extends Mixins<WizardNavProps, DynamicStoreMixin>(WizardNavProps, DynamicStoreMixin) {
  protected get isFirstStep (): boolean {
    return this.dynamicStoreGetter<boolean>(WIZARD_NAVIGATION_ACTIVE_IS_FIRST)
  }

  protected get isLastStep (): boolean {
    return this.dynamicStoreGetter<boolean>(WIZARD_NAVIGATION_ACTIVE_IS_LAST)
  }

  protected get activeStepId (): string {
    return this.dynamicStoreGetter<string>(WIZARD_NAVIGATION_ACTIVE_ID)
  }

  protected get canSkipStep (): WizardStoreGetterCallback<boolean, string> {
    return this.dynamicStoreGetter<WizardStoreGetterCallback<boolean, string>>(WIZARD_NAVIGATION_CAN_SKIP_STEP)
  }

  protected get showBackBtn (): boolean {
    return !this.autoHideBackBtn || !this.isFirstStep
  }

  protected get showSkipBtn (): boolean {
    return this.autoShowSkipBtn && this.canSkipStep(this.activeStepId)
  }

  protected get showFinishBtn (): boolean {
    return this.isLastStep
  }
}
