import { User } from '@/types/auth'
import { Store } from '@/store/store'
import { USER_ROLES, USER_USERNAME } from '@/store/getter-types'

export function getAuthUser (): User {
  const roles = Store.getters[USER_ROLES]

  return {
    username: Store.getters[USER_USERNAME],
    roles
  }
}
