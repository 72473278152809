import Vue, { VNode, VNodeChildren } from 'vue'
import { BButton } from 'bootstrap-vue'
import { MsgBoxProps, MsgBoxResolver, MsgBoxSlots } from '../types'
import { makeMsgBox } from './msg-box'

export type ConfirmSaveResult = 'save' | 'close' | null

const confirmResolver: MsgBoxResolver<ConfirmSaveResult> = function (bvModalEvt) {
  switch (bvModalEvt.trigger) {
    case 'msg-box-save':
      return 'save'
    case 'msg-box-close':
      return 'close'
    default:
      return null
  }
}

export function msgBoxConfirmSave (
  parent: Vue,
  message: VNodeChildren,
  props: MsgBoxProps = {},
  slots: MsgBoxSlots = {}
): Promise<ConfirmSaveResult> {
  // Set the modal props we support from options
  const msgBoxProps = {
    ...props,
    // Add in overrides and our content prop
    hideFooter: false
  }

  const footer = (footerScope): VNode[] => {
    const h = parent.$createElement

    const saveButton = h(
      BButton, {
        props: {
          variant: 'primary'
        },
        on: { click: () => footerScope.hide('msg-box-save') }
      },
      [parent.$t('common.confirm.buttons.save-changes') as string]
    )

    const closeButton = h(
      BButton, {
        props: {
          variant: 'danger'
        },
        on: { click: () => footerScope.hide('msg-box-close') }
      },
      [parent.$t('common.confirm.buttons.ignore-changes') as string]
    )

    const cancelButton = h(
      BButton, {
        props: {
          variant: 'secondary'
        },
        on: { click: () => footerScope.hide('msg-box-cancel') }
      },
      [parent.$t('common.buttons.cancel') as string]
    )

    return [saveButton, closeButton, cancelButton]
  }

  const msgBoxSlots: MsgBoxSlots = {
    ...slots,
    'modal-footer': footer
  }

  return makeMsgBox<ConfirmSaveResult>(parent, message, confirmResolver, msgBoxProps, msgBoxSlots)
}
