import { get } from 'lodash'
import { getQuantilesForFillMode, getQuantileValues } from '@/utils/visualization'
import GradientAwareMixin from './gradient-aware.mixin'
import { Component } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { FillType } from '@/types/visualization/layer/enums'
import { QuantileFillConfig, SubLayerConfig } from '@/types/visualization/layer/style'
import { DatasetColumnKey } from '@/types/common'
import { ColorModel } from '@/types/color-data'
import { LayerGeometry } from '@/types/visualization/layer/geometry'

@Component
export default class QuantileAwareMixin<TLayer extends BorderedGeometryLayer> extends GradientAwareMixin<TLayer> {
  protected getQuantileDataRows (column: DatasetColumnKey | undefined, style: SubLayerConfig, quantileValues: number[]): LayerGeometry[] {
    if (column === undefined) {
      return []
    }

    const subLayers = style.subLayers ?? {}
    const hiddenSubLayers = new Set()

    for (const sublayer in subLayers) {
      if (subLayers[sublayer].visible === false) {
        hiddenSubLayers.add(sublayer)
      }
    }

    return this.dataRows.filter(row => {
      const rowIndex = this.calculateQuantileRowIndex(row.meta[column] as number, quantileValues)
      return !hiddenSubLayers.has(rowIndex.toString())
    })
  }

  protected calculateQuantileRowIndex (quantileValue: number, quantileValues: number[]): number {
    let index = quantileValues.length - 1

    while (index > 0) {
      if (quantileValue >= quantileValues[index]) {
        break
      }
      index--
    }

    return index
  }

  protected calculateQuantiles (fillMode: FillType): number[] {
    return getQuantilesForFillMode(fillMode)
  }

  protected calculateQuantileValues (quantiles: number[], column: DatasetColumnKey | undefined): number[] {
    return getQuantileValues(this.dataRows, column, quantiles)
  }

  protected calculateQuantileColors (quantiles: number[], startRgb: ColorModel['rgba'] | undefined, endRgb: ColorModel['rgba'] | undefined): QuantileFillConfig[] {
    if (startRgb === undefined || endRgb === undefined) {
      return []
    }

    const distance = quantiles[quantiles.length - 1] - quantiles[0]

    return quantiles
      .map(p => p / distance)
      .map(ratio => {
        const color = this.calculateGradientColor(ratio, startRgb, endRgb)
        const opacity = this.calculateGradientOpacity(ratio, startRgb, endRgb)

        return {
          color: color.toHexString(),
          opacity
        }
      })
  }

  protected calculateQuantileColorByValue (quantileValue: number, values: number[], quantileColors: QuantileFillConfig[]): QuantileFillConfig {
    const rowIndex = this.calculateQuantileRowIndex(quantileValue, values)
    return this.calculateQuantileColorByIndex(rowIndex, quantileColors)
  }

  // TODO - referring to concrete style param in generic mixin. Need to check for errors
  protected calculateQuantileColorByIndex (index: number, quantileColors: QuantileFillConfig[]): QuantileFillConfig {
    const customQuantile = get(this.layer.style, 'fill.subLayers', {})
    if (customQuantile[index] && customQuantile[index].color) {
      return { color: customQuantile[index].color, opacity: quantileColors[index].opacity }
    }
    return quantileColors[index]
  }

  protected calculateQuantityOfValuesForQuantile (quantileValues: number[], quantileColumn: DatasetColumnKey | undefined): number[] {
    if (!quantileColumn) {
      return []
    }

    const quantityOfValues = new Array(quantileValues.length).fill(0)

    this.dataRows.forEach(row => {
      const index = this.calculateQuantileRowIndex(row.meta[quantileColumn] as number, quantileValues)

      quantityOfValues[index] += 1
    })

    return quantityOfValues
  }
}
