import entrypointStep from './steps/entrypoint-step/entrypoint-step.store'
import geocodingStep from './steps/geocoding-step/geocoding-step.store'
import { Module } from 'vuex'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    entrypointStep,
    geocodingStep
  }
} as Module<unknown, unknown>
