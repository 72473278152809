import { get } from 'lodash'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { freezeRecursive } from '@/utils/object'
import {
  LAYER_WIZARD_CHANGE_DATASET,
  LAYER_WIZARD_CHANGE_GEOMETRYSET,
  LAYER_WIZARD_IMPORT_DATASET,
  LAYER_WIZARD_IMPORT_GEOMETRYSET,
  LAYER_WIZARD_INIT_LAYER_DATA,
  LAYER_WIZARD_RESET,
  LAYER_WIZARD_RESET_DATA,
  LAYER_WIZARD_SHOW,
  LAYER_WIZARD_LOAD_DATASET_DETAILS,
  LAYER_WIZARD_POINTS_RESET
} from '@/store/action-types'
import {
  LAYER_WIZARD_DATASET,
  LAYER_WIZARD_DATASET_UPDATE,
  LAYER_WIZARD_GEOMETRYSET,
  LAYER_WIZARD_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_LAYER_NAME,
  LAYER_WIZARD_LAYER_STYLE,
  LAYER_WIZARD_LAYER_TYPE,
  LAYER_WIZARD_LINK_COLUMN,
  LAYER_WIZARD_SURROUNDING_GEOMETRIES
} from '@/store/getter-types'
import {
  LAYER_WIZARD_SET_DATASET,
  LAYER_WIZARD_SET_DATASET_UPDATE,
  LAYER_WIZARD_SET_ENTRYPOINT,
  LAYER_WIZARD_SET_GEOMETRYSET,
  LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_SET_LAYER_NAME,
  LAYER_WIZARD_SET_LAYER_STYLE,
  LAYER_WIZARD_SET_LAYER_TYPE,
  LAYER_WIZARD_SET_LINK_COLUMN,
  LAYER_WIZARD_SET_SURROUNDING_GEOMETRIES,
  LAYER_WIZARD_SET_VISIBLE
} from '@/store/mutation-types'
import { DEFAULT_LAYER_SETTINGS } from '@/components/visualization/layer-settings/constants/layer-settings'

export default {
  async [LAYER_WIZARD_INIT_LAYER_DATA] ({ getters }) {
    const dataset = getters[LAYER_WIZARD_DATASET]
    const datasetUpdate = getters[LAYER_WIZARD_DATASET_UPDATE]

    if (dataset && datasetUpdate) {
      await dataDeliveryApi.updateDataset({ ...dataset, ...datasetUpdate }, dataset.id)
    }

    const geometryset = getters[LAYER_WIZARD_GEOMETRYSET]
    let geometrysetId = get(geometryset, 'id', null)

    const granularityGeometrysetId = get(getters[LAYER_WIZARD_GRANULARITY_GEOMETRYSET], 'id', null)
    let surroundingIds = getters[LAYER_WIZARD_SURROUNDING_GEOMETRIES].map(geometry => geometry.id)

    // Just use granularity geometryset if no custom is provided.
    if (!geometrysetId && granularityGeometrysetId) {
      geometrysetId = granularityGeometrysetId
    }

    // If both geometryset and granularity are provided use
    //  1. granularity as geometryset
    //  2. custom geometryset as bounding box (surrounding geometries)
    if (surroundingIds.length === 0 && geometrysetId && granularityGeometrysetId) {
      let geometryList = geometryset.geometryList
      if (!geometryList) {
        const freshGeometrySet = await dataDeliveryApi.getGeometrySet(geometrysetId)
        geometryList = freshGeometrySet.geometryList
      }
      geometrysetId = granularityGeometrysetId
      surroundingIds = geometryList.map(geometry => geometry.id)
    }

    return {
      name: getters[LAYER_WIZARD_LAYER_NAME],
      type: getters[LAYER_WIZARD_LAYER_TYPE],
      dataSource: {
        datasetId: get(dataset, 'id', null),
        geometrysetId,
        linkingDatasetColumnId: getters[LAYER_WIZARD_LINK_COLUMN],
        sorroundingGeometryIDs: surroundingIds
      },
      style: getters[LAYER_WIZARD_LAYER_STYLE] || {},
      settings: DEFAULT_LAYER_SETTINGS
    }
  },
  [LAYER_WIZARD_RESET] ({ commit, dispatch }) {
    commit(LAYER_WIZARD_SET_ENTRYPOINT, null)
    commit(LAYER_WIZARD_SET_LAYER_TYPE, null)

    return dispatch(LAYER_WIZARD_RESET_DATA).then(() => {
      return dispatch(LAYER_WIZARD_POINTS_RESET)
    })
  },
  [LAYER_WIZARD_RESET_DATA] ({ commit }) {
    commit(LAYER_WIZARD_SET_LAYER_NAME, null)
    commit(LAYER_WIZARD_SET_SURROUNDING_GEOMETRIES, [])
    commit(LAYER_WIZARD_SET_DATASET, null)
    commit(LAYER_WIZARD_SET_DATASET_UPDATE, null)
    commit(LAYER_WIZARD_SET_LINK_COLUMN, null)
    commit(LAYER_WIZARD_SET_LAYER_STYLE, null)
    commit(LAYER_WIZARD_SET_GEOMETRYSET, null)
    commit(LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET, null)
  },
  [LAYER_WIZARD_SHOW] ({ commit }) {
    commit(LAYER_WIZARD_SET_VISIBLE, true)
  },
  [LAYER_WIZARD_CHANGE_DATASET] ({ commit }, dataset) {
    if (dataset && dataset.datasetRows) {
      freezeRecursive(dataset.datasetRows)
    }

    commit(LAYER_WIZARD_SET_DATASET, dataset)
    commit(LAYER_WIZARD_SET_LINK_COLUMN, null)
  },
  [LAYER_WIZARD_CHANGE_GEOMETRYSET] ({ commit }, geometryset) {
    if (geometryset && geometryset.geometryList) {
      freezeRecursive(geometryset.geometryList)
    }
    commit(LAYER_WIZARD_SET_GEOMETRYSET, geometryset)
  },
  async [LAYER_WIZARD_IMPORT_DATASET] ({ dispatch, getters }, { file, callback }) {
    const data = await dataDeliveryApi.importDataLayer(file, 'dataset', callback)
    await dispatch(LAYER_WIZARD_CHANGE_DATASET, data)

    return getters[LAYER_WIZARD_DATASET]
  },
  async [LAYER_WIZARD_IMPORT_GEOMETRYSET] ({ dispatch, getters }, { file, callback }) {
    const data = await dataDeliveryApi.importDataLayer(file, 'geometryset', callback)
    await dispatch(LAYER_WIZARD_CHANGE_GEOMETRYSET, data)

    return getters[LAYER_WIZARD_GEOMETRYSET]
  },
  async [LAYER_WIZARD_LOAD_DATASET_DETAILS] ({ dispatch, getters }) {
    const dataset = getters[LAYER_WIZARD_DATASET]
    const datasetDetails = await dataDeliveryApi.getDataset(dataset.id)

    await dispatch(LAYER_WIZARD_CHANGE_DATASET, datasetDetails)
  }
}
