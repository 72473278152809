import { getQueryFilterFunction } from '@/components/shared/query-builder/filter-func'
import { ListPair } from '@/types/planning/wizard/value-types.d'
import { Dataset, DatasetRows } from '@/types/common.d'
import { RankingImportWizardState } from '@/store/ranking-import-wizard/index'
import { QueryGroup } from '@/types/query-builder'

export const RANKING_IMPORT_WIZARD_DATASET = 'ranking-import/getDatasetRows'
export const RANKING_IMPORT_FILTERED_DATASET = 'ranking-import/getFilteredDatasetRows'
export const RANKING_IMPORT_NAMESPACE = 'ranking-import/storeNamespace'
export const RANKING_IMPORT_LINK_NAMESPACE = 'ranking-import/linkNamespace'
export const RANKING_IMPORT_WIZARD_QUERY_DATA = 'ranking-import/getQueryData'
export const RANKING_IMPORT_WIZARD_KEY_COLUMNS_PLZ_COLUMN = 'ranking-import/key-columns-step/plzColumn'
export const RANKING_IMPORT_WIZARD_KEY_COLUMNS_RATING_COLUMN = 'ranking-import/key-columns-step/ratingColumn'
export const RANKING_IMPORT_WIZARD_RESULT_TABLE = 'ranking-import/key-columns-step/resultTable'

export const getters = {
  [RANKING_IMPORT_WIZARD_DATASET] (state: RankingImportWizardState): Dataset | null {
    return state.sourceDataset
  },
  [RANKING_IMPORT_FILTERED_DATASET] (_state: RankingImportWizardState, getters): DatasetRows {
    const query = getters[RANKING_IMPORT_WIZARD_QUERY_DATA] as QueryGroup
    const dataset = getters[RANKING_IMPORT_WIZARD_DATASET] as Dataset | null
    const rows = dataset?.datasetRows ?? []

    const queryFilterFunc = getQueryFilterFunction(query)
    return rows.filter(row => queryFilterFunc(row))
  },
  [RANKING_IMPORT_WIZARD_QUERY_DATA] (state: RankingImportWizardState): QueryGroup {
    return state.query
  },
  [RANKING_IMPORT_WIZARD_KEY_COLUMNS_PLZ_COLUMN] (state: RankingImportWizardState): string | null {
    return state.plzColumn
  },
  [RANKING_IMPORT_WIZARD_KEY_COLUMNS_RATING_COLUMN] (state: RankingImportWizardState): string | null {
    return state.ratingColumn
  },
  [RANKING_IMPORT_WIZARD_RESULT_TABLE] (_state: RankingImportWizardState, getters): ListPair[] {
    return (getters[RANKING_IMPORT_FILTERED_DATASET] as DatasetRows)
      .map(row => ({
        key: row[getters[RANKING_IMPORT_WIZARD_KEY_COLUMNS_PLZ_COLUMN]] as string,
        value: row[getters[RANKING_IMPORT_WIZARD_KEY_COLUMNS_RATING_COLUMN]] as string
      }))
  },
  [RANKING_IMPORT_NAMESPACE] (state: RankingImportWizardState): string {
    return state.wizardNamespace
  },
  [RANKING_IMPORT_LINK_NAMESPACE] (_state: RankingImportWizardState, getters): (getterId: string) => string {
    return (getterId) => {
      return getters[RANKING_IMPORT_NAMESPACE] + '/' + getterId
    }
  }
}
