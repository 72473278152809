import { ApiPersistenceObject } from '@/types/common'
import { DEFAULT_DATETIME_FORMAT } from '@/constants/formats'
import moment from '@/lib/moment'
import { GridSortModel } from '@/components/shared/ag-grid/types'
import { orderBy, isNaN } from 'lodash'
import { SortType } from '@/types/enums'

export const customValueWrapper = (params, callback) => {
  if ((!params.node.group && params.data) || params.value) {
    return callback(params)
  }
}

export const parseNumber = (params) => {
  const number = Number(params.newValue)
  return isNaN(number) ? 0 : number
}

export const numberFormatter = (params) => {
  const callback = (params) => {
    const value = params.value
    if (!value) {
      return ''
    }

    return new Intl.NumberFormat('de-DE').format(parseGermanNumber(value))
  }
  return customValueWrapper(params, callback)
}

export const parseDate = (params) => {
  const callback = (params) => {
    const obj: ApiPersistenceObject = params.data
    return moment(obj[params.colDef.field]).format(DEFAULT_DATETIME_FORMAT)
  }

  return customValueWrapper(params, callback)
}

export const numberComparator = (valueA, valueB) => {
  return valueA - valueB
}

export const dateComparator = (valueA, valueB) => {
  return moment(valueA, [DEFAULT_DATETIME_FORMAT]) > moment(valueB, [DEFAULT_DATETIME_FORMAT]) ? 1 : -1
}

export const currencyFormatter = (params) => {
  const callback = (params) => {
    if (typeof params.value === 'number') {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(params.value)
    }

    return ''
  }
  return customValueWrapper(params, callback)
}

export const parseCurrency = (params) => {
  const callback = (params) => {
    const obj: ApiPersistenceObject = params.data
    const value = obj[params.colDef.field]

    if (value) {
      const result = parseGermanNumber(value)

      return isNaN(result) ? '' : result
    }

    return ''
  }

  return customValueWrapper(params, callback)
}

export const parseGermanNumber = (value) => Number(value.toString().replace(',', '.'))

export const sortByModels = <T = unknown>(rows: T[], sortModels: GridSortModel[]): T[] => {
  const { columns, orders } = sortModels.reduce((setting, model) => {
    const iterateFunc = (data) => {
      const value = model.sortValue ? model.sortValue(data) : data[model.colId as string]

      return typeof value === 'string' ? value.toLowerCase() : value
    }

    setting.columns.push(iterateFunc)
    setting.orders.push(model.sort)
    return setting
  }, { columns: [] as unknown[], orders: [] as SortType[] })

  return orderBy(rows, columns, orders) as T[]
}
