import {
  GRID_COLUMN_TYPE_NUMBER,
  GRID_COLUMN_TYPE_STRING,
  GRID_COLUMN_TYPE_DATE,
  GRID_COLUMN_TYPE_LINK,
  GRID_COLUMN_TYPE_CURRENCY,
  GRID_COLUMN_TYPE_AGG
} from '../constants'
import GridCellLink from '@/components/shared/ag-grid/components/grid-cell-link.vue'
import {
  currencyFormatter,
  dateComparator,
  numberComparator,
  numberFormatter,
  parseCurrency,
  parseDate,
  parseNumber
} from '@/components/shared/ag-grid/utils'
import { ColDef } from 'ag-grid-community'

const numberFilterParams = {
  filters: [
    {
      filter: 'agNumberColumnFilter'
    },
    {
      filter: 'agSetColumnFilter'
    }
  ]
}

const textFilterParams = {
  filters: [
    {
      filter: 'agTextColumnFilter'
    },
    {
      filter: 'agSetColumnFilter'
    }
  ]
}

export const defaultColumnTypes: Record<string, ColDef> = {
  [GRID_COLUMN_TYPE_NUMBER]: {
    width: 120,
    cellClass: 'text-right',
    valueParser: parseNumber,
    valueFormatter: numberFormatter,
    comparator: numberComparator,
    filterParams: numberFilterParams
  },
  [GRID_COLUMN_TYPE_STRING]: {
    filterParams: textFilterParams
  },
  [GRID_COLUMN_TYPE_DATE]: {
    valueGetter: parseDate,
    comparator: dateComparator
  },
  [GRID_COLUMN_TYPE_LINK]: {
    cellRendererFramework: GridCellLink
  },
  [GRID_COLUMN_TYPE_CURRENCY]: {
    valueFormatter: currencyFormatter,
    valueGetter: parseCurrency,
    filterParams: numberFilterParams,
    cellClass: 'text-right'
  },
  [GRID_COLUMN_TYPE_AGG]: {
    filterParams: numberFilterParams
  }
}
