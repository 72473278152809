import { Component, Mixins, Prop } from 'vue-property-decorator'
import { assign, groupBy } from 'lodash'
import { ItemsGroup, ItemsGroupMapper } from '../../types'
import { GroupEventsMixin } from './group-events.mixin'
import { ZIndexedLayer, ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

type GroupKeyFunction<T> = (item: T) => string

@Component
export class GroupByMixin<TItem,
  _TLayer extends ZIndexedLayer,
  TOptions extends ZIndexedPathOptions>
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  extends Mixins<GroupEventsMixin<TItem, _TLayer>>(GroupEventsMixin) {
  @Prop({ required: true }) readonly items!: TItem[]
  @Prop({ default: null }) readonly groupBy!: GroupKeyFunction<TItem>

  protected get groupedItems (): ItemsGroup<TItem, TOptions>[] {
    let groups: TItem[][]

    if (this.groupBy) {
      groups = Object.values(groupBy(this.items, this.groupBy))
    } else {
      groups = [this.items]
    }

    return groups.map(this.groupMapper)
  }

  protected get groupMapper (): ItemsGroupMapper<TItem, TOptions> {
    return (items: TItem[]): ItemsGroup<TItem, TOptions> => ({
      options: {} as TOptions,
      items
    })
  }

  protected makeGroupOptions (group: ItemsGroup<TItem, TOptions>, options: TOptions): TOptions {
    return assign({}, options, group.options) as TOptions
  }
}
