import { Dataset, DatasetRow, DatasetRows, GeometryBase } from '@/types/common'
import { pick } from 'lodash'
import { LocationInfo } from '@/types/planning/scoring/locations'
import { PathType } from '@/types/enums'
import {
  CITY_FIELD, MEDIA_OWNER_COLUMN, MEDIA_OWNER_NAME_COLUMN,
  NETWORK_DESCRIPTION_COLUMN, NETWORK_SIZE_COLUMN
} from '@/constants/unit/fields'

const parseMONDataString = <T>(data: string, callback: (data: string[]) => T): T[] => {
  return data
    ?.match(/\(.*?\)/g)
    ?.map(item => {
      const parsedData = item.replace('(', '').replace(')', '').split(' | ')
      return callback(parsedData)
    }) ?? [] as T[]
}

export const parseUnitLocations = (locationsString: string) => {
  return parseMONDataString<LocationInfo>(locationsString,
    ([Country, KKZ, OKZ, Bundesland, LandKreis, Nielsen, RegierungsBezirk, City, Population, PLZ5, PLZ5Population, PLZ8, PLZ8Population, SiteTypeCount, GRP]: string[]) =>
      ({ Country, KKZ, OKZ, Bundesland, LandKreis, Nielsen, RegierungsBezirk, City, Population, PLZ5, PLZ5Population, PLZ8, PLZ8Population, SiteTypeCount, GRP }))
}

export const parseMONDataset = function (dataset: Dataset): void {
  dataset.datasetRows = dataset.datasetRows.map(row => {
    const locationData = parseUnitLocations(row.Locations as string)

    const siteTypeData = parseMONDataString<string>(row.SiteTypeCount as string,
      (data: string[]) => data.join('-')).join('; ')

    return {
      ...row,
      Locations: locationData,
      SiteTypeCount: siteTypeData
    }
  }) as DatasetRows
}

export const generateMediaOwnerNetworkFilterName = function (networkObj: DatasetRow): string {
  const keys: string[] = [MEDIA_OWNER_COLUMN, MEDIA_OWNER_NAME_COLUMN, NETWORK_SIZE_COLUMN, CITY_FIELD, NETWORK_DESCRIPTION_COLUMN]
  return Object.values(pick(networkObj, keys)).filter(value => value).join(' - ')
}

export const matchGeometryToNetworkLocations = function makeMatch (geometry: GeometryBase, locations: LocationInfo[]): boolean {
  let getter: (loc: LocationInfo) => boolean

  switch (geometry.parentGeometrySetId) {
    case PathType.Country:
      getter = location => location.Country === geometry.name
      break
    case PathType.Bundesland:
      getter = location => location.Bundesland === geometry.name
      break
    case PathType.Nielsen:
      getter = location => location.Nielsen === geometry.name
      break
    case PathType.LandKreis:
      getter = location => location.LandKreis === geometry.name
      break
    case PathType.Regierungsbezirk:
      getter = location => location.RegierungsBezirk === geometry.name
      break
    case PathType.City:
      getter = location => location.City === geometry.name
      break
    case PathType.KKZ:
      getter = location => location.KKZ === geometry.name
      break
    case PathType.OKZ:
    default:
      getter = location => location.OKZ === geometry.name
      break
  }

  return locations.some(location => getter(location))
}
