import { Component, Vue } from 'vue-property-decorator'
import { Layer, LayerOptions, Util } from 'leaflet'
import { castArray, upperFirst } from 'lodash'
import { MapCompProp } from '@/decorators'
import { ParentContainer } from '@/components/map/mixins/layer-mixin/types'

@Component
export class LayerMixin<TLayer extends Layer> extends Vue {
  @MapCompProp({ layerProp: true, default: 'overlayPane' }) readonly pane!: string
  @MapCompProp({ layerProp: true }) readonly name!: string
  @MapCompProp({ layerProp: true }) readonly layerType?: string
  @MapCompProp({ layerProp: true, default: true }) readonly visible!: boolean

  protected parentContainer!: ParentContainer<LayerMixin<TLayer>>
  protected mapObject!: TLayer

  protected get layerOptions (): LayerOptions {
    return {
      pane: this.pane
    }
  }

  beforeDestroy (): void {
    this.cleanupLayer()
    this.parentContainer.removeLayer(this)
  }

  protected setName (): void {
    this.parentContainer.removeLayer(this)

    if (this.visible) {
      this.parentContainer.addLayer(this)
    }
  }

  protected setLayerType (): void {
    this.parentContainer.removeLayer(this)

    if (this.visible) {
      this.parentContainer.addLayer(this)
    }
  }

  protected setVisible (newVal: boolean): void {
    if (this.mapObject) {
      if (newVal) {
        this.parentContainer.addLayer(this)
      } else {
        this.parentContainer.removeLayer(this)
      }
    }
  }

  protected unbindTooltip (): void {
    if (!this.mapObject) {
      return
    }

    this.mapObject.unbindTooltip()
  }

  protected unbindPopup (): void {
    if (!this.mapObject) {
      return
    }

    this.mapObject.unbindPopup()
  }

  protected bindProps (): void {
    const props = this.$options.props

    for (const key in props) {
      const setMethodName = 'set' + upperFirst(key)
      const deepValue = castArray(props[key].type).some(propType => (propType === Object) || (propType === Array))
      const config = { deep: deepValue }

      if (!(props[key]).layerProp) {
        continue
      }

      if (this[setMethodName]) {
        this.$watch(key, (newVal, oldVal) => this[setMethodName](newVal, oldVal), config)
      } else {
        this.$watch(key, newVal => this.updateLayerProp(key, newVal), config)
      }
    }
  }

  protected cleanupLayer (): void {
    this.unbindPopup()
    this.unbindTooltip()
  }

  protected updateLayerProp (prop: string, newVal: unknown): void {
    const setMethodName = 'set' + upperFirst(prop)

    if (setMethodName === 'setOptions') {
      Util.setOptions(this.mapObject, newVal)
    } else if (this.mapObject[setMethodName]) {
      this.mapObject[setMethodName](newVal)
    }
  }
}
