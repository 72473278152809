import { i18n } from '@/locales/i18n'
import {
  PLAN_WIZARD_LINK_NAMESPACE,
  PLAN_WIZARD_SELECTED_LOCATIONS,
  PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID,
  WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES,
  WIZARD_STEP_MEDIA_TYPE_LOCATIONS_HAVE_TYPES,
  WIZARD_TAB_MEDIA_TYPE_ERRORS,
  WIZARD_TAB_MEDIA_TYPE_IS_EMPTY,
  WIZARD_TAB_MEDIA_TYPE_IS_ENABLED,
  WIZARD_TAB_MEDIA_TYPE_IS_INVALID
} from '@/store/getter-types'
import {
  PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK,
  WIZARD_TAB_MEDIA_TYPE_LEAVE,
  WIZARD_TAB_MEDIA_TYPE_VALIDATE
} from '@/store/action-types'
import { WIZARD_STEP_MEDIA_TYPE } from '@/components/planning/wizard/constants/steps'

// initial state
const state = {
  stepId: WIZARD_STEP_MEDIA_TYPE
}

// getters
const getters = {
  [WIZARD_TAB_MEDIA_TYPE_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[PLAN_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId) && getters[PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE].length > 0
  },
  [WIZARD_TAB_MEDIA_TYPE_IS_EMPTY] (state, getters) {
    const getAreaTypes = getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES]
    const areasWithoutTypes = getters[PLAN_WIZARD_SELECTED_LOCATIONS]
      .filter(area => getAreaTypes(area.id).length === 0)

    return areasWithoutTypes.length === getters[PLAN_WIZARD_SELECTED_LOCATIONS].length
  },
  [WIZARD_TAB_MEDIA_TYPE_IS_INVALID] (state, getters) {
    return getters[WIZARD_TAB_MEDIA_TYPE_ERRORS].length > 0
  },
  [WIZARD_TAB_MEDIA_TYPE_ERRORS] (state, getters) {
    const errors = []

    if (!getters[WIZARD_STEP_MEDIA_TYPE_LOCATIONS_HAVE_TYPES]) {
      errors.push(i18n.t('planning.wizard.errors.types-not-assigned'))
    }

    return errors
  }
}

// actions
const actions = {
  [WIZARD_TAB_MEDIA_TYPE_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[WIZARD_TAB_MEDIA_TYPE_ERRORS])
  },
  async [WIZARD_TAB_MEDIA_TYPE_LEAVE] ({ dispatch, state }) {
    return dispatch(PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK, state.stepId)
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
