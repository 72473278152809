import { SliderConfig } from './slider-config'
import { RangeOptions } from '@/types/planning/wizard/filter-editor'
import { ScoringFunctionData } from '@/types/planning/scoring/functions'
import { PpsFilterModes } from '@/types/planning/wizard/enums'
import { PPS_FILTER_MODE } from '@/constants/functions/scoring-functions-param-names'

export class PpsToAverageConfig extends SliderConfig {
  protected override _defValue = 0

  protected override rangeOptions: RangeOptions = {
    rangeMin: -100,
    rangeMax: 100,
    rangeStep: 1,
    formatter: val => `${val}%`
  }

  protected override setDefaultValue (value: number | string): void {
    super.setDefaultValue(parseInt(value as string))
  }

  override get displayMarkers () {
    return true
  }

  override get displayInput () {
    return true
  }

  override formatValue (value: number): string {
    return this.rangeOptions.formatter(value)
  }

  override isVisibleInGridView (data: ScoringFunctionData): boolean {
    return data[PPS_FILTER_MODE] === PpsFilterModes.Relative
  }
}
