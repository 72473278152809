






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import GeometriesMapPreview from './components/geometries-map-preview.vue'
import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import { FIND_GEOMETRY, SEARCH_BY_NAME, SEARCH_BY_COORDS, searchMapping } from './constants'
import { Geometries, Geometry } from '@/types/common'
import { VueComponentMap, VueInstanceListeners, VueInstanceProps } from '@/types/vue'
import { LatLng } from 'leaflet'
import { GeometryCore } from '@/utils/geometry-core'

@Component({
  components: { OverlayScrollbars, GeometriesMapPreview }
})
export default class SurroundingGeometries extends Vue {
  @Prop({ default: () => [] }) private geometries!: Geometries

  private selectedPoint: null | LatLng = null
  searchMapping: VueComponentMap = searchMapping
  searchType: string = SEARCH_BY_NAME

  private geometryCore: GeometryCore = {} as GeometryCore

  created () {
    this.geometryCore = new GeometryCore(this.geometries)
  }

  @Watch('geometries', { deep: true })
  private onGeometriesUpdate (geometries): void {
    this.geometryCore.setGeometries(geometries)
  }

  private get hasGeometries (): boolean {
    return this.geometryCore.getGeometries.length > 0
  }

  private findGeometry (geometry: Geometry): void {
    const similarGeometries = this.geometryCore.findGeometry(geometry.id)
    this.$emit(FIND_GEOMETRY, similarGeometries)
  }

  private handleCapturePoint (point: LatLng): void {
    this.selectedPoint = point
    this.searchType = SEARCH_BY_COORDS
  }

  private makeSearchProps (type): VueInstanceProps {
    switch (type) {
      case SEARCH_BY_NAME:
        return {}
      case SEARCH_BY_COORDS:
        return {
          point: this.selectedPoint
        }
      default:
        return {}
    }
  }

  private makeSearchListeners (type): VueInstanceListeners {
    const listeners: VueInstanceListeners = {
      selected: this.handleSelection
    }

    if (type === SEARCH_BY_COORDS) {
      listeners.finish = () => {
        this.searchType = SEARCH_BY_NAME
        this.selectedPoint = null
      }
    }

    return listeners
  }

  private handleSelection (event): void {
    this.$emit('add-geometry', event)
  }

  private handleRemoveGeometry (event): void {
    this.$emit('remove-geometry', event)
  }
}
