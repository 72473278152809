import ColorPickerComponent from '@/components/shared/color-picker/color-picker-popover.vue'
import { i18n } from '@/locales/i18n'
import { PluginObject } from 'vue'
import { ColorModel } from '@/types/color-data'
import { ColorPickerOptions } from '@/plugins/color-picker/type'

type ColorPickerFunction = (options: ColorPickerOptions) => Promise<ColorModel>

declare module 'vue/types/vue' {
  interface Vue {
    $pickColor: ColorPickerFunction
  }
}

export class ColorPickerPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$pickColor = function ({
      color,
      target,
      disableAlpha = false,
      placement = 'right',
      presetMode = 'color'
    }: ColorPickerOptions): Promise<ColorModel> {
      return new Promise((resolve) => {
        const popoverContainer = document.createElement('div')
        document.body.appendChild(popoverContainer)

        const instance = new Vue({
          i18n,
          render: h => h(ColorPickerComponent, {
            props: {
              value: color,
              target,
              placement,
              presetMode,
              disableAlpha
            },
            on: {
              input (value) {
                resolve(value)
              },
              close () {
                instance.$destroy()
                popoverContainer.remove()
              }
            }
          })
        }).$mount(popoverContainer)

        this.$nextTick(() => {
          instance._vnode.componentInstance.openPopover()
        })
      })
    }
  }
}
