










import { WIZARD_NAVIGATION_ACTIVE_ID } from '@/store/getter-types'
import { WIZARD_TAB_LEAVE, WIZARD_TAB_VALIDATE } from '@/store/action-types'
import WizardStepNavigatorMixin from '../../mixins/wizard-steps-navigator.mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class WizardFinishButton extends Mixins<WizardStepNavigatorMixin>(WizardStepNavigatorMixin) {
  protected get activeStepId (): string {
    return this.dynamicStoreGetter<string>(WIZARD_NAVIGATION_ACTIVE_ID)
  }

  protected async validateStep (stepId): Promise<boolean> {
    const canLeave = await this.dispatchDynamicStoreAction(WIZARD_TAB_LEAVE, stepId)

    if (canLeave === false) {
      return false
    }

    await this.dispatchDynamicStoreAction(WIZARD_TAB_VALIDATE, stepId)
    return true
  }

  protected async finishWizard (): Promise<void> {
    let valid = false
    try {
      valid = await this.validateStep(this.activeStepId)
    } catch (errors) {
      this.showStepErrors(errors)
      return
    }

    if (valid) {
      this.$emit('finish')
    }
  }
}
