import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { PointsConfig } from '@/utils/filter-editor/value-config/dataset/points-config'
import { Dataset, PointCoordinates } from '@/types/common'
import { formatDatasetRows, parseDatasetRows } from '@/utils/parse-dataset-rows'
import { cloneDeep, isArray } from 'lodash'
import { DatasetDTO } from '@/types/api'

const pointsListKey = 'Points'
const pointsDatasetKey = 'POIs'

const defaultName = (index) => (`Point ${index}`)

export function vicinityParser (func: ScoringFunctionNode) {
  const updatedFunc = cloneDeep(func)
  const funcData = updatedFunc.scoringFunction.data

  funcData.forEach(data => {
    if (pointsListKey in data) {
      const pointsConfig = new PointsConfig(null)
      const updatedData = pointsConfig.defaultValue

      updatedData.datasetRows = (data[pointsListKey] as PointCoordinates)
        .map((row, index) => ({ ...row, name: defaultName(index) }))

      delete data[pointsListKey]

      data[pointsDatasetKey] = updatedData
      return
    }

    const datasetRows = (data[pointsDatasetKey] as Dataset).datasetRows || (data[pointsDatasetKey] as DatasetDTO).rows
    if (datasetRows.some(row => isArray(row))) {
      const updatedDataset = {
        ...(data[pointsDatasetKey] as Dataset),
        datasetRows
      }
      data[pointsDatasetKey] = parseDatasetRows(updatedDataset)
    }
  })

  return updatedFunc
}

export function vicinityFormatter (func: ScoringFunctionNode) {
  const updatedFunc = cloneDeep(func)

  updatedFunc.scoringFunction.data = updatedFunc.scoringFunction.data.map(data => {
    const pointsDataset = data[pointsDatasetKey] as Dataset

    return {
      ...data,
      [pointsDatasetKey]: formatDatasetRows(pointsDataset)
    }
  })

  return updatedFunc
}
