import mediaOwnerNames from './filter-dropdown-collections/media-owner-names.store'
import bannedProducts from './filter-dropdown-collections/banned-products.store'
import siteTypes from './filter-dropdown-collections/site-types.store'
import cityNames from './datasets/city-names.store'
import mediaFilter from './filter-dropdown-collections/media-filter.store'
import goalType from './combined-goal-enums/goal-type-enum'

const collectionsStore = {
  name: 'collections',
  modules: {
    bannedProducts,
    mediaOwnerNames,
    siteTypes,
    cityNames,
    mediaFilter,
    goalType
  }
}

export default collectionsStore
