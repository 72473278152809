import { FillType, PathStyle } from '@/types/visualization/layer/enums'
import GradientPathMixin from './gradient-path.mixin'
import QuantilePathMixin from './quantile-path.mixin'
import ClusteringPathMixin from './clustering-path.mixin'
import RangePathMixin from './range-path.mixin'
import GroupItemsMixin from './group-items.mixin'
import { dashArray } from '@/components/visualization/layer-settings/utils'
import { Component, Mixins } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'

@Component
export default class LayerPathMixin<_TLayer extends BorderedGeometryLayer> extends Mixins<
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  GradientPathMixin<_TLayer>, QuantilePathMixin<_TLayer>, ClusteringPathMixin<_TLayer>, RangePathMixin<_TLayer>, GroupItemsMixin
    >(GradientPathMixin, QuantilePathMixin, ClusteringPathMixin, RangePathMixin, GroupItemsMixin) {
  protected get pathDataRows () {
    switch (this.layer.style.path.type) {
      case FillType.Median:
      case FillType.Quartile:
      case FillType.Decile:
        return this.quantilePathDataRows

      case FillType.Clustering:
        return this.clusteringPathDataRows

      case FillType.Range:
        return this.rangePathDataRows
    }

    return this.dataRows
  }

  protected get layerPathColor () {
    switch (this.layer.style.path.type) {
      case FillType.Gradient:
        return this.gradientPathColorFunc || this.layer.style.path.color

      case FillType.Median:
      case FillType.Quartile:
      case FillType.Decile:
        return this.quantilePathColorFunc || this.layer.style.path.color

      case FillType.Clustering:
        return this.clusteringPathColorFunc || this.layer.style.path.color

      case FillType.Range:
        return this.rangePathColorFunc || this.layer.style.path.color
    }

    return this.layer.style.path.color as string
  }

  protected get layerPathOpacity () {
    switch (this.layer.style.path.type) {
      case FillType.Gradient:
        return this.gradientPathOpacityFunc || this.layer.style.path.opacity

      case FillType.Median:
      case FillType.Quartile:
      case FillType.Decile:
        return this.quantilePathOpacityFunc || this.layer.style.path.opacity

      case FillType.Range:
        return this.rangePathOpacityFunc || this.layer.style.path.opacity
    }

    return this.layer.style.path.opacity as number
  }

  protected get layerPathGroupFunc () {
    if (this.layer.style.path.style === PathStyle.None) {
      return () => 'none'
    }

    if (this.layer.style.path.type === FillType.Clustering) {
      return this.makeClusterGroupFunc(this.layer.style.path.gradientColumn)
    }

    return this.makeColorGroupFunc(this.layerPathColor, this.layerPathOpacity)
  }

  protected get layerDashArray () {
    return dashArray(this.layer.style.path.style, this.layer.style.path.width)
  }
}
