import { customersApi } from '@/api/rest/customers/customers.api'
import { SET_CUSTOMERS } from '@/store/mutation-types'
import {
  CUSTOMER_BY_VALUE,
  CUSTOMERS,
  CUSTOMERS_BY_ORDER_SYSTEM,
  CUSTOMERS_BY_ORDER_SYSTEM_SALES_OFFICE,
  CUSTOMERS_INITIALIZED, CUSTOMERS_SALES_OFFICES,
  IW_ORDER_SYSTEMS
} from '@/store/getter-types'
import { LOAD_CUSTOMERS } from '@/store/action-types'
import { uniq, cloneDeep } from 'lodash'
import { CustomerStore } from '@/store/customers/customers.store.types'
import { TextValuePair } from '@/types/common'
import { Customer, CustomerDTO } from '@/views/planning/children/campaign-export/types'

export type SalesOfficesGetter = (orderSystem: string) => TextValuePair[]
export type AvailableCustomersGetter = (orderSystem: string, salesOffice: string) => TextValuePair[]
export type SelectedCustomerGetter = (customer: Customer | null) => CustomerDTO | null

// initial state
const state: CustomerStore = {
  allCustomers: [],
  initialized: false
}

let request: Promise<CustomerDTO[]> | null

// getters
const getters = {
  [CUSTOMERS] (state: CustomerStore) {
    return state.allCustomers
  },
  [IW_ORDER_SYSTEMS] (_state: CustomerStore, getters): TextValuePair[] {
    const allCustomers = getters[CUSTOMERS] as CustomerDTO[]
    const allOrderSystems = allCustomers.map((customerDto: CustomerDTO) => customerDto.orderSystem)
    const uniqueOrderSystems = uniq<string>(allOrderSystems)
    return uniqueOrderSystems.map(orderSystem => ({ value: orderSystem, text: orderSystem }))
  },
  [CUSTOMERS_BY_ORDER_SYSTEM] (_state: CustomerStore, getters): (orderSystem: string) => CustomerDTO[] {
    return (os: string) => {
      const allCustomers = getters[CUSTOMERS] as CustomerDTO[]
      return allCustomers.filter(customerDto => customerDto.orderSystem === os)
    }
  },
  [CUSTOMERS_SALES_OFFICES] (_state: CustomerStore, getters): SalesOfficesGetter {
    return (os: string) => {
      const customersByOrderSystem = getters[CUSTOMERS_BY_ORDER_SYSTEM](os) as CustomerDTO[]
      const allSalesOffices = customersByOrderSystem.map((customerDto: CustomerDTO) => customerDto.salesOffice)
      const uniqueSalesOffices = uniq<string>(allSalesOffices)
      return uniqueSalesOffices.map(salesOffice => ({ value: salesOffice, text: salesOffice }))
    }
  },
  [CUSTOMERS_BY_ORDER_SYSTEM_SALES_OFFICE] (_state: CustomerStore, getters): AvailableCustomersGetter {
    return (os: string, salesOffice: string) => {
      const customersByOrderSystem = getters[CUSTOMERS_BY_ORDER_SYSTEM](os) as CustomerDTO[]
      const customersByOrderSystemSalesOffice = customersByOrderSystem.filter(customerDto => customerDto.salesOffice === salesOffice)
      return customersByOrderSystemSalesOffice.map(customer => ({ value: customer.customerId, text: customer.displayName }))
    }
  },
  [CUSTOMER_BY_VALUE] (_state: CustomerStore, getters): SelectedCustomerGetter {
    return (selectedCustomer) => {
      const allCustomers = getters[CUSTOMERS] as CustomerDTO[]
      const matchingCustomer = allCustomers.find(customer =>
        customer.customerId === selectedCustomer?.customerId &&
        customer.salesOffice === selectedCustomer?.salesOffice &&
        customer.orderSystem === selectedCustomer?.orderSystem
      ) ?? null

      return cloneDeep(matchingCustomer)
    }
  },
  [CUSTOMERS_INITIALIZED] (state: CustomerStore): boolean {
    return state.initialized
  }
}

// actions
const actions = {
  async [LOAD_CUSTOMERS] ({ getters, commit }) {
    if (getters[CUSTOMERS_INITIALIZED]) {
      return getters[CUSTOMERS]
    }

    if (request) {
      return request
    }

    const customers = await customersApi.getCustomers()

    commit(SET_CUSTOMERS, customers)
    request = null

    return getters[CUSTOMERS]
  }
}

// mutations
const mutations = {
  [SET_CUSTOMERS] (state: CustomerStore, customers: CustomerDTO[]) {
    if (Array.isArray(customers)) {
      state.allCustomers = customers
      state.initialized = true
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
