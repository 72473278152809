

























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { isEqual, cloneDeep } from 'lodash'
import { ModalMixin } from '@/mixins/modal.mixin'
import AgGrid from '@/components/shared/ag-grid/ag-grid.vue'
import SelectorGrid from './components/selector-grid.vue'
import { DatasetRows } from '@/types/common'
import { VueInstanceProps } from '@/types/vue'
import { ModelUpdatedEvent } from 'ag-grid-community'

@Component({
  components: { AgGrid, SelectorGrid }
})
export default class SelectorModal extends Mixins<ModalMixin>(ModalMixin) {
  @Prop({ default: () => [] }) protected selected!: DatasetRows

  protected selectedItems: DatasetRows = []
  protected selectedWasChanged = false

  override openModal () {
    this.selectedWasChanged = false
    this.selectedItems = cloneDeep(this.selected)
    this.showModal = true

    const _unwatch = this.$watch('selectedItems', () => {
      if (!isEqual(this.selected, this.selectedItems)) {
        this.selectedWasChanged = true
        _unwatch()
      }
    })
  }

  private handleItemsUpdate (items: DatasetRows) {
    this.selectedItems = [...new Set(this.selectedItems.concat(items))]
  }

  private saveSelectedItems (): void {
    this.$emit('select', this.selectedItems)
  }

  protected get sourceSwitcherAllLabel (): string {
    return this.$t('common.labels.all') as string
  }

  protected get sourceSwitcherSelectedLabel (): string {
    return this.$t('common.ag-grid.selectedRows') as string
  }

  protected get confirmTitle (): string {
    return this.$t('common.confirm.labels.unsaved-confirm-title') as string
  }

  protected get confirmMessage (): string {
    return this.$t('planning.confirm.labels.unsaved-selector-message') as string
  }

  protected get tableProps (): VueInstanceProps {
    return {}
  }

  protected get tableListeners (): VueInstanceProps {
    return {}
  }

  protected get modalTitle (): string {
    return 'Selector table'
  }

  private finishSelectProcess (): void {
    this.selectedWasChanged = false
    this.saveSelectedItems()
    this.closeModal()
  }

  private checkForUnsavedChanges (): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const dialogProps = {
        size: 'md',
        okVariant: 'danger',
        footerClass: 'p-3',
        centered: true,
        noCloseOnBackdrop: true
      }
      const dialogSlots = {
        'modal-title': this.confirmTitle
      }

      this.$modalDialog.msgBoxConfirmSave(this.confirmMessage, dialogProps, dialogSlots)
        .then(res => {
          switch (res) {
            case 'save':
              this.saveSelectedItems()
              return resolve()
            case 'close':
              return resolve()
            default:
              reject(new Error('cancel'))
          }
        })
    })
  }

  protected handleModelUpdatedEvent (event: ModelUpdatedEvent) {
    event.api.sizeColumnsToFit()
  }

  private handleCloseModal (event): Promise<void> {
    if (this.selectedWasChanged) {
      event.preventDefault()

      this.checkForUnsavedChanges().then(() => {
        this.selectedWasChanged = false
        this.closeModal()
      })
    }

    return Promise.resolve()
  }
}
