import { Component, Mixins } from 'vue-property-decorator'
import { FillRule, LineCapShape, LineJoinShape, Path, PathOptions } from 'leaflet'
import { Color } from 'csstype'
import { MapCompProp } from '@/decorators'
import { MapObjectOptionComputed } from '../../types'
import { InteractiveLayerMixin } from '../interactive-layer.mixin'
import { LayerGroupMixin } from './layer-group.mixin'

@Component
export class PathsMixin<TItem,
  _TLayer extends Path,
  TOptions extends PathOptions = PathOptions>
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  extends Mixins<LayerGroupMixin<TItem, _TLayer, TOptions>, InteractiveLayerMixin>(
    LayerGroupMixin,
    InteractiveLayerMixin
  ) {
  @MapCompProp({ layerProp: true, default: true }) readonly stroke!: boolean
  @MapCompProp({ layerProp: true, default: '#3388ff' }) readonly pathColor!: MapObjectOptionComputed<TItem, Color>
  @MapCompProp({ layerProp: true, default: 3 }) readonly weight!: number
  @MapCompProp({ layerProp: true, default: 1.0 }) readonly pathOpacity!: MapObjectOptionComputed<TItem, number>
  @MapCompProp({ layerProp: true, default: 'butt' }) readonly lineCap!: LineCapShape
  @MapCompProp({ layerProp: true, default: 'round' }) readonly lineJoin!: LineJoinShape
  @MapCompProp({ layerProp: true, default: undefined }) readonly dashArray?: number[]
  @MapCompProp({ layerProp: true, default: undefined }) readonly dashOffset?: string
  @MapCompProp({ layerProp: true, default: false }) readonly fill!: boolean
  @MapCompProp({ layerProp: true, default: '#3388ff' }) readonly fillColor!: MapObjectOptionComputed<TItem, Color>
  @MapCompProp({ layerProp: true, default: 0.2 }) readonly fillOpacity!: MapObjectOptionComputed<TItem, number>
  @MapCompProp({ layerProp: true, default: 'evenodd' }) readonly fillRule!: FillRule
  @MapCompProp({ layerProp: true, default: undefined }) readonly className?: string

  protected get pathOptions (): TOptions {
    return {
      ...this.layerOptions,
      ...this.interactiveLayerOptions,
      ...this.zIndexOptions,
      stroke: this.stroke,
      color: this.pathColor as string,
      weight: this.weight,
      opacity: this.pathOpacity as number,
      lineCap: this.lineCap,
      lineJoin: this.lineJoin,
      dashArray: this.dashArray,
      dashOffset: this.dashOffset,
      fill: this.fill,
      fillColor: this.fillColor as string,
      fillOpacity: this.fillOpacity as number,
      fillRule: this.fillRule,
      className: this.className
    } as TOptions
  }

  protected setStroke (newVal: boolean): void {
    this.mapObject.setStyle({ stroke: newVal })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected setPathColor (_newVal: MapObjectOptionComputed<TItem, Color>): void {
    // Handled by grouping.
  }

  protected setWeight (newVal: number): void {
    this.mapObject.setStyle({ weight: newVal })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected setPathOpacity (_newVal: MapObjectOptionComputed<TItem, number>): void {
    // Handled by grouping.
  }

  protected setLineCap (newVal: LineCapShape): void {
    this.mapObject.setStyle({ lineCap: newVal })
  }

  protected setLineJoin (newVal: LineJoinShape): void {
    this.mapObject.setStyle({ lineJoin: newVal })
  }

  protected setDashArray (newVal: number[] | undefined): void {
    this.mapObject.setStyle({ dashArray: newVal })
  }

  protected setDashOffset (newVal: string | undefined): void {
    this.mapObject.setStyle({ dashOffset: newVal })
  }

  protected setFill (newVal: boolean): void {
    this.mapObject.setStyle({ fill: newVal })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected setFillColor (_newVal: MapObjectOptionComputed<TItem, Color>): void {
    // Handled by grouping.
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected setFillOpacity (_newVal: MapObjectOptionComputed<TItem, number>): void {
    // Handled by grouping.
  }

  protected setFillRule (newVal: FillRule): void {
    this.mapObject.setStyle({ fillRule: newVal })
  }

  protected setClassName (newVal: string | undefined): void {
    this.mapObject.setStyle({ className: newVal })
  }
}
