import { CREATE_VISUALIZATION_FROM_PLANNING } from '@/store/action-types'
import { scoringLocationsApi } from '@/api/rest/scoring/scoring-locations.api'

// actions
const actions = {
  async [CREATE_VISUALIZATION_FROM_PLANNING] (actions, { plans, name }) {
    return new Promise((resolve, reject) => {
      // Temporary solution. Wait changes endpoint on back-end
      const planId = plans[0].id

      scoringLocationsApi.getVisualizationForPlanning({ id: planId, name })
        .then((visualizationId) => {
          // Delay to creating visualization
          setTimeout(() => {
            resolve({ id: visualizationId })
          }, 1000)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default actions
