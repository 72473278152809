import { defaults } from 'lodash'
import { AxiosInstance } from 'axios'
import { ApiRequestConfig, ApiResponse } from '@/api/types'
import { errorCallback, successCallback } from '@/api/config/callbacks'
import { createApiClient } from './http-client'

const defaultConfig: ApiRequestConfig = {
  toastOnError: true
}

export class ApiClient {
  protected readonly http: AxiosInstance;
  private readonly config: ApiRequestConfig;

  constructor (config: ApiRequestConfig) {
    this.config = defaults(undefined, defaultConfig, config)
    this.http = createApiClient(this.config)
  }

  protected makeRequestConfig (config?: ApiRequestConfig): ApiRequestConfig {
    return defaults(config, this.config)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private applyDefaultCallbacks<T = any> (request: Promise<ApiResponse<T>>): Promise<T> {
    return request.then(successCallback, errorCallback)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected get<T = any> (url: string, config?: ApiRequestConfig): Promise<T> {
    return this.applyDefaultCallbacks(
      this.http.get(url, this.makeRequestConfig(config))
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected delete<T = any> (url: string, config?: ApiRequestConfig): Promise<T> {
    return this.applyDefaultCallbacks(
      this.http.delete(url, this.makeRequestConfig(config))
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected post<T = any> (url: string, data: any = null, config?: ApiRequestConfig): Promise<T> {
    return this.applyDefaultCallbacks(
      this.http.post(url, data, this.makeRequestConfig(config))
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected put<T = any> (url: string, data: any = null, config?: ApiRequestConfig): Promise<T> {
    return this.applyDefaultCallbacks(
      this.http.put(url, data, this.makeRequestConfig(config))
    )
  }
}
