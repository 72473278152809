import { get } from 'lodash'
import {
  LAYER_WIZARD_DATASET,
  LAYER_WIZARD_DATASET_COLUMNS,
  LAYER_WIZARD_DATASET_UPDATE,
  LAYER_WIZARD_ENTRYPOINT,
  LAYER_WIZARD_ENTRYPOINT_IS_AREA_SELECT,
  LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD,
  LAYER_WIZARD_ENTRYPOINT_IS_GEOMETRY,
  LAYER_WIZARD_ENTRYPOINT_IS_LINKED_DATASET,
  LAYER_WIZARD_ENTRYPOINT_IS_POI,
  LAYER_WIZARD_GEOMETRYSET,
  LAYER_WIZARD_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_LAYER_NAME,
  LAYER_WIZARD_LAYER_STYLE,
  LAYER_WIZARD_LAYER_TYPE,
  LAYER_WIZARD_LINK_COLUMN,
  LAYER_WIZARD_STEP_GEOMETRY_IS_VALID,
  LAYER_WIZARD_SURROUNDING_GEOMETRIES,
  LAYER_WIZARD_VISIBLE,
  LAYER_WIZARD_NEED_TO_UPLOAD_DATASET_DETAILS,
  LAYER_WIZARD_NAMESPACE,
  LAYER_WIZARD_LINK_NAMESPACE
} from '@/store/getter-types'
import {
  ENTRYPOINT_AREA_DATASET,
  ENTRYPOINT_AREA_SELECT,
  ENTRYPOINT_AREA_UPLOAD,
  ENTRYPOINT_GEOMETRY,
  ENTRYPOINT_POI
} from './constants/entrypoints'

export default {
  [LAYER_WIZARD_VISIBLE] (state) {
    return state.visible
  },
  [LAYER_WIZARD_LAYER_TYPE] (state) {
    return state.layerType
  },
  [LAYER_WIZARD_LAYER_NAME] (state) {
    return state.layerName
  },
  [LAYER_WIZARD_SURROUNDING_GEOMETRIES] (state) {
    return state.surroundingGeometries
  },
  [LAYER_WIZARD_DATASET] (state) {
    return state.dataset
  },
  [LAYER_WIZARD_DATASET_UPDATE] (state) {
    return state.datasetUpdate
  },
  [LAYER_WIZARD_DATASET_COLUMNS] (state) {
    const columnTypes = get(state.dataset, 'columnInfos', [])

    return columnTypes.map(column => column.name.trim())
  },
  [LAYER_WIZARD_GEOMETRYSET] (state) {
    return state.geometryset
  },
  [LAYER_WIZARD_GRANULARITY_GEOMETRYSET] (state) {
    return state.granularityGeometryset
  },
  [LAYER_WIZARD_LINK_COLUMN] (state) {
    return state.linkColumn
  },
  [LAYER_WIZARD_LAYER_STYLE] (state) {
    return state.layerStyle
  },
  [LAYER_WIZARD_ENTRYPOINT] (state) {
    return state.entrypoint
  },
  [LAYER_WIZARD_ENTRYPOINT_IS_GEOMETRY] (state, getters) {
    return getters[LAYER_WIZARD_ENTRYPOINT] === ENTRYPOINT_GEOMETRY || getters[LAYER_WIZARD_STEP_GEOMETRY_IS_VALID]
  },
  [LAYER_WIZARD_ENTRYPOINT_IS_LINKED_DATASET] (state, getters) {
    return getters[LAYER_WIZARD_ENTRYPOINT] === ENTRYPOINT_AREA_DATASET
  },
  [LAYER_WIZARD_ENTRYPOINT_IS_POI] (state, getters) {
    return getters[LAYER_WIZARD_ENTRYPOINT] === ENTRYPOINT_POI
  },
  [LAYER_WIZARD_ENTRYPOINT_IS_AREA_SELECT] (state, getters) {
    return getters[LAYER_WIZARD_ENTRYPOINT] === ENTRYPOINT_AREA_SELECT
  },
  [LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD] (state, getters) {
    return getters[LAYER_WIZARD_ENTRYPOINT] === ENTRYPOINT_AREA_UPLOAD
  },
  [LAYER_WIZARD_NEED_TO_UPLOAD_DATASET_DETAILS] (state, getters) {
    const dataset = getters[LAYER_WIZARD_DATASET]
    return dataset && !dataset.datasetRows && getters[LAYER_WIZARD_DATASET_COLUMNS].length === 0
  },
  [LAYER_WIZARD_NAMESPACE] (state) {
    return state.wizardNamespace
  },
  [LAYER_WIZARD_LINK_NAMESPACE] (state, getters) {
    return (getter) => {
      return getters[LAYER_WIZARD_NAMESPACE] + '/' + getter
    }
  }
}
