
































import { Component, Watch, Prop } from 'vue-property-decorator'
import TagsTable from '@/components/shared/tags-table/tags-table.vue'
import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import LogoSpinner from '@/components/shared/logo-spinner.vue'
import { GeometriesListMixin } from '../mixins/geometries-list.mixin'
import { LatLng } from 'leaflet'
import { Geometries } from '@/types/common'
import { BTable } from 'bootstrap-vue'

@Component({
  components: { LogoSpinner, OverlayScrollbars, TagsTable }
})
export default class GeometriesListByCoords extends GeometriesListMixin {
  @Prop({ required: true }) private point!: LatLng

  private busy = false
  private geometries: Geometries = []

  private get tableOptions (): Partial<BTable> {
    return {
      busy: this.busy
    }
  }

  @Watch('point', { immediate: true, deep: true })
  private onPointChange (newPoint: LatLng): void {
    this.searchGeometries(newPoint)
  }

  private searchGeometries (point: LatLng): void {
    this.busy = true
    this.getGeometriesForPoint(point)
      .then(results => {
        this.geometries = results
      }).finally(() => {
        this.busy = false
      })
  }

  private selectRows (rows: Geometries): void {
    this.$emit('selected', rows)
  }
}
