import { AuthStoreState } from '@/store/auth/types/auth.store'

export const SET_AUTH_TOKEN = 'auth/setToken'
export const CLEAR_AUTH_TOKEN = 'auth/clearToken'
export const SET_TEMP_TOKEN = 'auth/setTempToken'
export const CLEAR_TEMP_TOKEN = 'auth/clearTempToken'

export const mutations = {
  [SET_AUTH_TOKEN] (state: AuthStoreState, token: string) {
    state.token = token
  },
  [CLEAR_AUTH_TOKEN] (state: AuthStoreState) {
    state.token = null
  },
  [SET_TEMP_TOKEN] (state: AuthStoreState, tempToken: string) {
    state.tempToken = tempToken
  },
  [CLEAR_TEMP_TOKEN] (state: AuthStoreState) {
    state.tempToken = null
  }
}
