import Vue from 'vue'
import { applyStyleChanges } from '@/components/visualization/layer-settings/utils'
import {
  LAYER_WIZARD_ADD_SURROUNDING_GEOMETRY,
  LAYER_WIZARD_REMOVE_SURROUNDING_GEOMETRY,
  LAYER_WIZARD_SET_DATASET,
  LAYER_WIZARD_SET_DATASET_UPDATE,
  LAYER_WIZARD_SET_ENTRYPOINT,
  LAYER_WIZARD_SET_GEOMETRYSET,
  LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_SET_LAYER_NAME,
  LAYER_WIZARD_SET_LAYER_STYLE,
  LAYER_WIZARD_SET_LAYER_TYPE,
  LAYER_WIZARD_SET_LINK_COLUMN,
  LAYER_WIZARD_SET_SURROUNDING_GEOMETRIES,
  LAYER_WIZARD_SET_VISIBLE,
  LAYER_WIZARD_UPDATE_LAYER_STYLE
} from '@/store/mutation-types'

export default {
  [LAYER_WIZARD_SET_VISIBLE] (state, visible) {
    state.visible = visible
  },
  [LAYER_WIZARD_SET_LAYER_TYPE] (state, layerType) {
    state.layerType = layerType
  },
  [LAYER_WIZARD_SET_LAYER_NAME] (state, layerName) {
    state.layerName = layerName
  },
  [LAYER_WIZARD_ADD_SURROUNDING_GEOMETRY] (state, geometry) {
    state.surroundingGeometries.push(geometry)
  },
  [LAYER_WIZARD_REMOVE_SURROUNDING_GEOMETRY] (state, geometry) {
    const idx = state.surroundingGeometries.indexOf(geometry)

    idx > -1 && state.surroundingGeometries.splice(idx, 1)
  },
  [LAYER_WIZARD_SET_SURROUNDING_GEOMETRIES] (state, geometries) {
    state.surroundingGeometries = geometries
  },
  [LAYER_WIZARD_SET_DATASET] (state, dataset) {
    state.dataset = dataset
  },
  [LAYER_WIZARD_SET_DATASET_UPDATE] (state, datasetUpdate) {
    state.datasetUpdate = datasetUpdate
  },
  [LAYER_WIZARD_SET_GEOMETRYSET] (state, geometryset) {
    state.geometryset = geometryset
  },
  [LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET] (state, granularityGeometryset) {
    state.granularityGeometryset = granularityGeometryset
  },
  [LAYER_WIZARD_SET_LINK_COLUMN] (state, linkColumn) {
    state.linkColumn = linkColumn
  },
  [LAYER_WIZARD_SET_LAYER_STYLE] (state, style) {
    state.layerStyle = style
  },
  [LAYER_WIZARD_UPDATE_LAYER_STYLE] (state, style) {
    if (state.layerStyle === null) {
      Vue.set(state, 'layerStyle', {})
    }

    applyStyleChanges(state.layerStyle, style)
  },
  [LAYER_WIZARD_SET_ENTRYPOINT] (state, entrypoint) {
    state.entrypoint = entrypoint
  }
}
