



















import { Component, Mixins } from 'vue-property-decorator'
import { GridPaginationMixin } from '../mixins/grid-pagination.mixin'

@Component
export default class GridPagination extends Mixins(GridPaginationMixin) {
  private get paginationLabel (): string {
    const total = this.paginationState.totalRows
    const from = this.paginationState.currentPage * this.paginationState.perPage + 1
    let to = from + this.paginationState.perPage - 1

    if (to > total) {
      to = total
    }

    return this.$t('common.ag-grid.pagination-label', { from, to, total }) as string
  }
}
