import { Component } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { getDataRows } from '@/utils/visualization'
import { GeometryLayerMixin } from './geometry-layer.mixin'
import { LayerGeometry } from '@/types/visualization/layer/geometry'

@Component
// @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
export class DatasetLayerMixin<TLayer extends BorderedGeometryLayer> extends GeometryLayerMixin<TLayer> {
  protected get dataRows (): LayerGeometry[] {
    return getDataRows(this.layer)
  }
}
