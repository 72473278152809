
















































import { Component, Mixins } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import { maxValue, minValue, required } from 'vuelidate/lib/validators'
import { FormInputState } from '@/types/bootstrap'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { ValidationModel } from '@/vendor/types/vuelidate/model'
import { FilterUIComponent, ValueRange } from '@/types/planning/wizard/filter-editor'
import { Validations } from '@/decorators'
import { DotOption } from 'vue-slider-component/typings/typings'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
import { SliderUiMixin } from '@/components/planning/wizard/filter-editor/ui-controls/mixins/slider-ui.mixin'
import { cloneDeep } from 'lodash'

const INVALID_MARKER_COLOR = 'rgb(248,0,22)'

@Component({
  components: { FilterParamName, VueSlider }
})
export default class RangeUiElement extends Mixins<SliderUiMixin<ValueRange>>(SliderUiMixin) implements FilterUIComponent<ValueRange> {
  override selected: ValueRange = (this.value && this.value.length) ? cloneDeep(this.value) : cloneDeep(this.config.defaultValue)

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: {
        required,
        $each: {
          required,
          minValue: minValue(this.config.rangeMin),
          maxValue: maxValue(this.config.rangeMax),
          correctOrder: () => {
            return this.selected[0] < this.selected[1]
          }
        }
      }
    }
  }

  private get minInputState (): FormInputState {
    const model: ValidationModel = this.$v.selected.$each[0]
    return this.validationModelToInputState(model)
  }

  private get maxInputState (): FormInputState {
    const model: ValidationModel = this.$v.selected.$each[1]
    return this.validationModelToInputState(model)
  }

  private get sliderValues (): ValueRange {
    return this.selected.map(item => {
      if (item < this.config.rangeMin) {
        return this.config.rangeMin
      }

      if (item > this.config.rangeMax) {
        return this.config.rangeMax
      }

      return item
    }) as ValueRange
  }

  private set sliderValues (values: ValueRange) {
    this.setSelected(values)
  }

  private get sliderDotOptions (): DotOption[] {
    return this.selected.map((_value, index) => {
      const dotOptions: DotOption = {
        disabled: false
      }

      this.$v.selected.$each[index].$error && (this.$v.selected.$each[index] as unknown as RuleDecl).correctOrder &&
       (dotOptions.style = { backgroundColor: INVALID_MARKER_COLOR })

      return dotOptions
    })
  }

  private onInputBlur (index: number): void {
    // TODO: find a solution to fix issue is related with validation
    const values: ValueRange = (this.selected as unknown[] as string[]).map(parseFloat) as ValueRange
    this.setSelected(values as ValueRange)
    this.$v.selected.$each[index].$touch()
  }
}
