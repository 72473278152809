import { i18n } from '@/locales/i18n'
import { TranslateResult } from 'vue-i18n'
import { RANKING_IMPORT_WIZARD_DATASET } from '@/store/ranking-import-wizard/getters'

export const RANKING_IMPORT_WIZARD_TAB_DATASET_ERRORS = 'ranking-import/dataset-tab/errors'
export const RANKING_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY = 'ranking-import/dataset-tab/isEmpty'
export const RANKING_IMPORT_WIZARD_TAB_DATASET_IS_ENABLED = 'ranking-import/dataset-tab/isEnabled'
export const RANKING_IMPORT_WIZARD_TAB_DATASET_IS_INVALID = 'ranking-import/dataset-tab/isInvalid'
export const RANKING_IMPORT_WIZARD_TAB_DATASET_CAN_SKIP = 'ranking-import/dataset-tab/canSkip'

export const getters = {
  [RANKING_IMPORT_WIZARD_TAB_DATASET_IS_ENABLED] () {
    return true
  },
  [RANKING_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY] (_state, _getters, _rootState, rootGetters) {
    return !rootGetters[RANKING_IMPORT_WIZARD_DATASET]
  },
  [RANKING_IMPORT_WIZARD_TAB_DATASET_IS_INVALID] (_state, getters) {
    return getters[RANKING_IMPORT_WIZARD_TAB_DATASET_ERRORS].length > 0
  },
  [RANKING_IMPORT_WIZARD_TAB_DATASET_CAN_SKIP] () {
    return false
  },
  [RANKING_IMPORT_WIZARD_TAB_DATASET_ERRORS] (_state, getters): TranslateResult[] {
    const errors: TranslateResult[] = []

    if (getters[RANKING_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY]) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.select-dataset'))
    }

    return errors
  }
}
