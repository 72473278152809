import { ColDef } from 'ag-grid-community'
import {
  GRID_COLUMN_TYPE_AGG,
  GRID_COLUMN_TYPE_CURRENCY,
  GRID_COLUMN_TYPE_NUMBER,
  GRID_COLUMN_TYPE_RIGHT_ALIGNED,
  GRID_COLUMN_TYPE_STRING
} from '@/components/shared/ag-grid/constants'
import { bookedUnitsColor } from '@/assets/style-variables'
import {
  getColumnValueType,
  isTimeFrameColumn
} from '@/constants/unit/utils'
import { Dataset } from '@/types/common'
import { DataColumnType } from '@/types/enums'
import {
  DAILY_PRICE_COLUMN, mediaOwnerNetworkColumnNameTranslations, POTENTIAL_PRICE_COLUMN,
  TOTAL_PRICE_COLUMN
} from '@/constants/unit/fields'
import { ADVANCED_COLUMN_DEFS_MAP } from '@/constants/unit/column-defs-map'

const DECADES_COLUMN_STYLE = {
  cellStyle: (params) => {
    if (params.value) {
      return { backgroundColor: bookedUnitsColor }
    }

    return {}
  }
}

const ADVANCED_COLUMN_DEFS: ColDef[] = Object.values(ADVANCED_COLUMN_DEFS_MAP)

const getColumnDefinition = function (column) {
  return ADVANCED_COLUMN_DEFS.find(def => def.field === column) ?? {}
}

const getColumnsStyles = function (column) {
  return isTimeFrameColumn(column) ? DECADES_COLUMN_STYLE : {}
}

const getColumnType = function (column: string, columnsInfos: Dataset['columnInfos']) {
  const columnValueType = getColumnValueType(column, columnsInfos as Dataset['columnInfos']) as string
  const columnTypes: ColDef['type'] = []

  if (columnValueType === DataColumnType.Currency) {
    columnTypes.push(GRID_COLUMN_TYPE_CURRENCY, GRID_COLUMN_TYPE_RIGHT_ALIGNED)
  }

  if (columnValueType === DataColumnType.Number) {
    columnTypes.push(GRID_COLUMN_TYPE_NUMBER, GRID_COLUMN_TYPE_RIGHT_ALIGNED)
  }

  if ([TOTAL_PRICE_COLUMN, DAILY_PRICE_COLUMN, POTENTIAL_PRICE_COLUMN].includes(column)) {
    columnTypes.push(GRID_COLUMN_TYPE_AGG)
  }

  return columnTypes.length ? columnTypes : [GRID_COLUMN_TYPE_STRING]
}

function getColumnCellClass (column: string, columnsInfos: Dataset['columnInfos']) {
  const columnValueType = getColumnValueType(column, columnsInfos as Dataset['columnInfos']) as string
  const cellClass: string[] = [columnValueType, column]

  if (columnValueType === DataColumnType.Currency || columnValueType === DataColumnType.Number) {
    cellClass.push('text-right')
  }

  return cellClass
}

export const getColumnDefs = function (columns: string[], columnsInfos: Dataset['columnInfos']): ColDef[] {
  return columns.map((column) => ({
    field: column,
    headerName: mediaOwnerNetworkColumnNameTranslations[column] ?? column,
    cellClass: getColumnCellClass(column, columnsInfos),
    type: getColumnType(column, columnsInfos),
    ...getColumnDefinition(column),
    ...getColumnsStyles(column)
  }))
}
