import entryPointStep from './steps/entrypoint-step.store'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    entryPointStep
  }
}
