import { round } from 'lodash'
import { nanoid } from 'nanoid'
import moment from '@/lib/moment'
import { UserComment } from '@/types/common'
import { Order, OrderDocument, OrderItem } from '@/types/orders'
import { OrderStatus } from '@/types/orders/enums'
import { BookingTimeFrameGranularity } from '@/types/planning/enums'
import { ORDER_DOCUMENT_TYPES } from '@/utils/file'

function generateComments (count = 10): UserComment[] {
  return (new Array(count))
    .fill(null)
    .map((_val, i) => {
      const stampCreate = moment().subtract({ minutes: Math.trunc(Math.random() * 10000) })

      return ({
        id: nanoid(),
        name: 'comment',
        tags: [],
        createdBy: 'Andrey.Baranov',
        stampCreate: stampCreate.toDate(),
        stampEdit: stampCreate.toDate(),
        content: `<p>Comment <b>${i}</b> text is <i>here</i>.</p>`,
        deleted: false
      })
    })
}

export function generateOrderItems (count = 1000): OrderItem[] {
  return (new Array(count))
    .fill(null)
    .map(() => {
      const timeFrameStart = moment().add({ minutes: Math.trunc(Math.random() * 10000) }).startOf('day')
      const timeFrameEnd = timeFrameStart.clone().add({ weeks: 1 }).endOf('day')
      const weekNum = timeFrameStart.week()

      return ({
        bookableUnitId: nanoid(),
        mediaOwnerId: nanoid(),
        grossPrice: round(Math.random() * 100, 2),
        iwScore: Math.random() * 1000,
        timeFrame: {
          value: `W ${weekNum}`,
          text: `Week ${weekNum} name`,
          start: timeFrameStart.toDate(),
          end: timeFrameEnd.toDate(),
          numberOfYear: Math.round(Math.random() * 50),
          type: BookingTimeFrameGranularity.Week,
          year: 2020
        }
      })
    })
}

function randomArrayItem<T> (items: T[]): T {
  return items[Math.floor(Math.random() * items.length - 0.001)]
}

function randomStatus (): OrderStatus {
  return randomArrayItem(Object.values(OrderStatus))
}

function randomTimestamps () {
  const stampCreate = moment().subtract({ week: Math.ceil(Math.random() * 10) })
  const stampEdit = stampCreate.clone().add({ day: Math.ceil(Math.random() * 60) })

  return { stampCreate, stampEdit }
}

const usernames = ['Andrey.Baranov', 'Artsem.Dudinskij', 'Michael.Gubich']

function randomUsername (): string {
  return randomArrayItem(usernames)
}

const documentTypes = ['pdf', 'xlsx', 'jpg', 'zip', 'pptx', 'doc', 'mp4', 'kml']

function randomDocumentType (): string {
  return randomArrayItem(documentTypes)
}

function mimeTypeByDocumentType (type: string): string {
  return ORDER_DOCUMENT_TYPES[type].mime[0]
}

export function generateOrders (count = 10): Order[] {
  return (new Array(count))
    .fill(null)
    .map((_, index) => ({
      id: `${index}`,
      name: `test order ${index}`,
      status: randomStatus(),
      ...randomTimestamps(),
      tags: [{ key: '1', value: 'Tag 1' }, { key: '22', value: 'Second tag' }],
      assignedTo: randomUsername(),
      watchingUsers: [randomUsername()],
      planningId: '65b8cd7c-9c31-4e22-a580-b93375ecda5e',
      internalCompany: 'Exposit',
      userComments: generateComments(Math.floor(Math.random() * 10))
    }))
}

export function generateDocuments (count = 5): OrderDocument[] {
  return (new Array(count))
    .fill(null)
    .map((_, index) => {
      const docType = randomDocumentType()
      return {
        id: nanoid(),
        name: `test document ${index}`,
        type: docType,
        mimeType: mimeTypeByDocumentType(docType),
        ...randomTimestamps(),
        tags: [{ key: '1', value: 'Tag 1' }, { key: '22', value: 'Second tag' }]
      }
    })
}
