



















import { Component, Mixins, Watch } from 'vue-property-decorator'
import { FormInputState } from '@/types/bootstrap'
import { ReadExcelFileMixin } from '@/mixins/read-file.mixin'

@Component
export default class BookableUnitsImport extends Mixins<ReadExcelFileMixin>(ReadExcelFileMixin) {
  protected override get fileState (): FormInputState {
    return this.file ? this.validateFile() : null
  }

  private get fileLabel () {
    return this.$t('planning.bookable-units-import.labels.add-from-file')
  }

  @Watch('fileRows')
  private onFileRowsChange (rows: Record<string, unknown>[]) {
    this.$emit('rows-uploaded', rows)
  }
}
