import { Bounds, bounds, Canvas as CanvasRenderer, Layer, Point, point, RendererOptions, stamp } from 'leaflet'
import { mapLabelFont, mapLabelLineHeight, mapClusterFont } from '@/assets/style-variables'
import ProjectedPolygon from '../layer/multi-polygons/multi-polygon/projected-polygon/projected-polygon'
import { MultiPolygon } from '@/lib/leaflet/layer/multi-polygons/multi-polygon/multi-polygon'
import { MultiCircle } from '@/lib/leaflet/layer/multi-polygons/multi-circle/multi-circle'
import { MultiTriangleMarker } from '@/lib/leaflet/layer/multi-markers/shape-markers/multi-triangle-marker/multi-triangle-marker'
import { TriangleMarker } from '@/lib/leaflet/layer/single-marker/triangle-marker'
import { MultiPinMarker } from '@/lib/leaflet/layer/multi-markers/shape-markers/multi-pin-marker/multi-pin-marker'
import { SpriteMarkerCluster } from '@/lib/leaflet/layer/sprite-markers/sprite-marker-cluster'
import { CustomMapRenderer } from '@/lib/leaflet/renderer/index'
import { SpriteMarkers } from '@/lib/leaflet/layer/sprite-markers/sprite-markers'
import { SpriteIndex } from '@/lib/leaflet/layer/sprite-markers/types'
import { MultiSquareMarker } from '@/lib/leaflet/layer/multi-markers/shape-markers/multi-square-marker/multi-square-marker'
import { LayerOrder, ZIndexedLayer } from '@/lib/leaflet/layer/z-indexed-layer'
import { HatchingFactory } from '@/lib/leaflet/hatching/hatching-factory'

const BOUNDS_CORNERS = ['getTopLeft', 'getTopRight', 'getBottomRight', 'getBottomLeft']

class CustomCanvasRenderer extends CanvasRenderer implements CustomMapRenderer {
  private _zIndexCache!: LayerOrder[]
  private _drawLast!: LayerOrder | null
  private _drawFirst!: LayerOrder | null

  private declare _drawing: boolean
  private declare _ctx: CanvasRenderingContext2D
  private declare _layers: ZIndexedLayer[]

  private declare _updateDashArray: (layer: ZIndexedLayer) => void
  private declare _requestRedraw: (layer: ZIndexedLayer) => void
  private declare _fillStroke: (ctx: CanvasRenderingContext2D, layer: Layer) => void

  declare _bounds: Bounds

  _initContainer (): void {
    this._zIndexCache = this._zIndexCache || []
    // @ts-ignore - method _initContainer really exists in renderer
    CanvasRenderer.prototype._initContainer.call(this)
  }

  _destroyContainer (): void {
    // @ts-ignore - method _destroyContainer really exists in renderer
    CanvasRenderer.prototype._destroyContainer.call(this)
    this._zIndexCache = []
  }

  _initPath (layer: ZIndexedLayer): void {
    this._updateDashArray(layer)
    this._layers[stamp(layer)] = layer

    layer._order = {
      layer: layer,
      zIndex: layer.options.zIndex as number,
      prev: null,
      next: null
    }

    this._insertZOrder(layer._order)
  }

  _removePath (layer: ZIndexedLayer): void {
    this._removeZOrderFromCache(layer._order)
    // @ts-ignore - method _removePath really exists in renderer
    CanvasRenderer.prototype._removePath.call(this, layer)
  }

  _updateStyle (layer: ZIndexedLayer): void {
    if ('zIndex' in layer.options && layer._order && layer.options.zIndex !== layer._order.zIndex) {
      this._updatePathZOrder(layer)
    }

    // @ts-ignore - method _updateStyle really exists in renderer
    CanvasRenderer.prototype._updateStyle.call(this, layer)
  }

  _updatePathZOrder (layer: ZIndexedLayer): void {
    const prev = layer._order.prev
    const next = layer._order.next

    this._removeZOrderFromCache(layer._order)

    if (next) {
      next.prev = prev
    } else {
      this._drawLast = prev
    }

    if (prev) {
      prev.next = next
    } else {
      this._drawFirst = next
    }

    layer._order.prev = null
    layer._order.next = null
    layer._order.zIndex = layer.options.zIndex as number

    this._insertZOrder(layer._order)
    this._requestRedraw(layer)
  }

  _insertZOrder (layerOrder: LayerOrder): void {
    // Specific case for very first path added.
    if (!this._drawLast) {
      this._drawLast = layerOrder
      this._drawFirst = layerOrder
      // Assume that cache is empty and just add value there.
      this._zIndexCache.push(layerOrder)

      return
    }

    // Specific case when new path has the lowest z-index and should be inserted first.
    if (this._drawFirst && this._drawFirst.zIndex > layerOrder.zIndex) {
      layerOrder.next = this._drawFirst
      this._drawFirst.prev = layerOrder
      this._drawFirst = layerOrder
      // Insert to the first position in the cache.
      this._zIndexCache.splice(0, 0, layerOrder)

      return
    }

    // Specific case when new path has the highest z-index and should be inserted last.
    if (this._drawLast.zIndex <= layerOrder.zIndex) {
      if (this._drawLast.zIndex < layerOrder.zIndex) {
        // Current layer z-index is higher than maximal one from the cache.
        // So we just append new layer to the end of the cache.
        this._zIndexCache.push(layerOrder)
      } else {
        // Current layer has the same z-index as the cached one.
        // Update cache to set current layer as the last one with this z-index.
        this._zIndexCache[this._zIndexCache.length - 1] = layerOrder
      }

      layerOrder.prev = this._drawLast
      this._drawLast.next = layerOrder
      this._drawLast = layerOrder

      return
    }

    for (let i = this._zIndexCache.length - 1; i >= 0; i--) {
      if (this._zIndexCache[i].zIndex > layerOrder.zIndex) {
        continue
      }

      layerOrder.next = this._zIndexCache[i].next
      this._zIndexCache[i].next = layerOrder
      layerOrder.prev = this._zIndexCache[i]

      if (layerOrder.next) {
        layerOrder.next.prev = layerOrder
      }

      if (this._zIndexCache[i].zIndex < layerOrder.zIndex) {
        // New layer has z-index that doesn't exist in the cache
        // so just insert it to the proper position.
        this._zIndexCache.splice(i + 1, 0, layerOrder)
      } else {
        // New layer has the same z-index as the cached item.
        // Update cache to set current layer as the last one with this z-index.
        this._zIndexCache[i] = layerOrder
      }

      break
    }
  }

  _removeZOrderFromCache (order: LayerOrder): void {
    const cacheIndex = this._zIndexCache.indexOf(order)

    if (cacheIndex !== -1) {
      if (order.prev && order.prev.zIndex === order.zIndex) {
        // Replace value in the cache if there is another layer that has same z-index.
        this._zIndexCache[cacheIndex] = order.prev
      } else {
        // Otherwise just remove current layer from the cache.
        this._zIndexCache.splice(cacheIndex, 1)
      }
    }
  }

  updateMultiPolygon (layer: MultiPolygon): void {
    if (!this._drawing) {
      return
    }

    const len = layer._drawParts.length

    if (!len) {
      return
    }

    const ctx = this._ctx

    // Iterate over polygon.
    for (let i = 0; i < len; i++) {
      const polygon = layer._drawParts[i]

      ctx.beginPath()
      // Iterate over polygon rings.
      for (let j = 0; j < polygon.rings.length; j++) {
        const ring = polygon.rings[j]

        for (let k = 0; k < ring.length; k++) {
          const p = ring[k]

          ctx[k ? 'lineTo' : 'moveTo'](p.x, p.y)
        }
        ctx.closePath()
      }
      this._fillStroke(ctx, layer)
      this._applyPattern(ctx, layer)
      this._updateMultiPolygonLabel(layer, polygon)
    }
  }

  _updateMultiPolygonLabel (layer: MultiPolygon, projectedPolygon: ProjectedPolygon): void {
    const text = layer.getLabelTextForPoly(projectedPolygon)
    const pos = projectedPolygon.getLabelPos(layer.options.forceLabel)

    if (!text || !pos) {
      return
    }

    this._ctx.save()

    const fontSingleProps = mapLabelFont.split(' ')
    const newFontProps = [`${layer.options.labelTextSize}px`, ...fontSingleProps.slice(1)]

    this._ctx.font = newFontProps.join(' ')
    this._ctx.globalAlpha = 1
    this._ctx.textBaseline = 'middle'
    this._ctx.textAlign = 'center'
    this._ctx.fillStyle = layer.options.labelFontColor

    const lines = text
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0)

    const labelVisible = layer.options.forceLabel || this._labelFitsPolygon(layer, projectedPolygon, lines, pos)

    if (labelVisible) {
      const len = lines.length
      let i, y

      for (i = 0; i < len; i++) {
        y = pos.y + (i - (len - 1) / 2) * mapLabelLineHeight

        if (layer.options.labelStrokeWidth) {
          this._ctx.lineWidth = layer.options.labelStrokeWidth
          this._ctx.strokeStyle = layer.options.labelStrokeColor

          this._ctx.strokeText(lines[i], pos.x, y)
        }

        this._ctx.fillText(lines[i], pos.x, y)
      }
    }

    this._ctx.restore()
  }

  _labelFitsPolygon (layer: MultiPolygon, projectedPolygon: ProjectedPolygon, textLines: string[], labelPos: Point): boolean {
    const len = textLines.length
    let i
    let getCorner
    let width, y, lineBounds

    for (i = 0; i < len; i++) {
      y = labelPos.y + (i - (len - 1) / 2) * mapLabelLineHeight
      width = this._ctx.measureText(textLines[i]).width + 2 * layer.options.labelStrokeWidth

      lineBounds = bounds(
        point(labelPos.x - width / 2, y - mapLabelLineHeight / 2 - layer.options.labelStrokeWidth),
        point(labelPos.x + width / 2, y + mapLabelLineHeight / 2 + layer.options.labelStrokeWidth)
      )

      for (getCorner of BOUNDS_CORNERS) {
        if (!projectedPolygon.containsPoint(lineBounds[getCorner](), this.options.tolerance as number)) {
          return false
        }
      }
    }

    return true
  }

  updateMultiCircleMarker (layer: MultiCircle): void {
    const len = layer._parts.length

    if (!this._drawing || len === 0) {
      return
    }

    const ctx = this._ctx

    for (let i = 0; i < len; i++) {
      const pCircle = layer._parts[i]

      ctx.beginPath()
      ctx.arc(pCircle.point.x, pCircle.point.y, pCircle.size, 0, Math.PI * 2, false)

      this._fillStroke(ctx, layer)
    }
  }

  updateMultiCircle (layer: MultiCircle): void {
    const len = layer._parts.length

    if (!this._drawing || len === 0) {
      return
    }

    const ctx = this._ctx

    for (let i = 0; i < len; i++) {
      const pCircle = layer._parts[i]

      ctx.beginPath()
      ctx.arc(pCircle.point.x, pCircle.point.y, pCircle.size, 0, Math.PI * 2, false)

      this._fillStroke(ctx, layer)
      this._applyPattern(ctx, layer)
    }
  }

  updateMultiTriangle (layer: MultiTriangleMarker): void {
    const len = layer._parts.length

    if (!this._drawing || len === 0) {
      return
    }

    const ctx = this._ctx
    const innerRadius = layer.getMarkerSize()
    const outerRadius = innerRadius * 3 / Math.sqrt(3)
    const xOffset = innerRadius * 2

    // rotation angle from deg to rad
    const rotation = layer.options.rotation * (Math.PI * 2) / 360

    for (let i = 0; i < len; i++) {
      const pTriangle = layer._parts[i]

      // rotate canvas on triangle center before drawing
      ctx.save()
      ctx.translate(pTriangle.point.x, pTriangle.point.y)
      ctx.rotate(rotation)

      ctx.beginPath()
      ctx.moveTo(0, -xOffset)
      ctx.lineTo(outerRadius, innerRadius)
      ctx.lineTo(-outerRadius, innerRadius)

      ctx.closePath()
      // rotate back and restore position
      ctx.restore()

      this._fillStroke(ctx, layer)
    }
  }

  updateMultiSquare (layer: MultiSquareMarker): void {
    const len = layer._parts.length

    if (!this._drawing || len === 0) {
      return
    }

    const ctx = this._ctx
    const size = layer.getMarkerSize() * 2

    for (let i = 0; i < len; i++) {
      const centerPoint = layer._parts[i].point
      const basePoint = new Point(centerPoint.x - size / 2, centerPoint.y - size / 2)

      ctx.beginPath()
      ctx.moveTo(basePoint.x, basePoint.y)
      ctx.lineTo(basePoint.x + size, basePoint.y)
      ctx.lineTo(basePoint.x + size, basePoint.y + size)
      ctx.lineTo(basePoint.x, basePoint.y + size)
      ctx.closePath()

      this._fillStroke(ctx, layer)
    }
  }

  updateTriangle (layer: TriangleMarker): void {
    if (!this._drawing || layer._empty()) {
      return
    }

    const p = layer.point
    const ctx = this._ctx
    const wh = layer.getSize() / 2
    const hh = layer.getSize() / 2
    // rotation angle from deg to rad
    const r = layer.getRotation() * (Math.PI * 2) / 360

    // rotate canvas on triangle center before drawing
    ctx.save()
    ctx.translate(p.x, p.y)
    ctx.rotate(r)
    // draw triangle
    ctx.beginPath()
    ctx.moveTo(0, -hh)
    ctx.lineTo(wh, hh * 2)
    ctx.lineTo(-wh, hh * 2)
    ctx.closePath()
    // rotate back and restore position
    ctx.restore()

    this._fillStroke(ctx, layer)
  }

  updateMultiMarker (layer: MultiPinMarker): void {
    const len = layer._parts.length

    if (!this._drawing || len === 0) {
      return
    }

    const ctx = this._ctx
    const width = layer.getMarkerSize()
    const height = width * layer.heightRatio

    ctx.save()
    ctx.translate(-width / 2, -height)

    for (let i = 0; i < len; i++) {
      const p = layer._parts[i]

      const center = new Point(p.point.x + width / 2, p.point.y + height / 2)

      this._drawMarkerShadow(layer, ctx, p.point, center, width, height)
      this._drawMarker(layer, ctx, p.point, center, width, height)
    }

    ctx.restore()
  }

  _drawMarkerShadow (_layer: MultiPinMarker, ctx: CanvasRenderingContext2D, p: Point, center: Point, width: number, height: number): void {
    const shadow = {
      width: width / 2,
      height: height / 2,
      start: {
        x: center.x - width / 4,
        y: p.y + height - height / 2
      }
    }

    ctx.save()
    ctx.beginPath()

    const shadowGradient = ctx.createRadialGradient(
      shadow.start.x + shadow.width / 2,
      shadow.start.y + shadow.height / 2,
      0,
      shadow.start.x + shadow.width / 2,
      shadow.start.y + shadow.height / 2,
      shadow.width / 2
    )
    shadowGradient.addColorStop(0, 'rgba(0,0,0,.4)')
    shadowGradient.addColorStop(1, 'rgba(0,0,0,0)')

    ctx.scale(1, 0.5)
    ctx.translate(0, p.y + height + shadow.height / 2)
    ctx.fillStyle = shadowGradient
    ctx.fillRect(shadow.start.x, shadow.start.y, shadow.width, shadow.height)
    ctx.fill()
    ctx.closePath()
    ctx.restore()
  }

  _drawMarker (layer: MultiPinMarker, ctx: CanvasRenderingContext2D, p: Point, center: Point, width: number, height: number): void {
    // Top arc
    ctx.beginPath()
    ctx.arc(center.x, p.y + height / 3, width / 2, Math.PI, 0, false)

    // Right bend
    ctx.bezierCurveTo(
      p.x + width,
      p.y + (height / 3) + height / 4,
      center.x + width / 3,
      center.y,
      center.x,
      p.y + height
    )

    // Left bend
    ctx.moveTo(p.x, p.y + height / 3)
    ctx.bezierCurveTo(
      p.x,
      p.y + (height / 3) + height / 4,
      center.x - width / 3,
      center.y,
      center.x,
      p.y + height
    )

    this._fillStroke(ctx, layer)
  }

  updateSpriteIcons (layer: SpriteMarkers): void {
    this._drawUnclusteredSpriteIcons(layer)
    this._drawClusteredSpriteIcons(layer)
  }

  _drawUnclusteredSpriteIcons (layer: SpriteMarkers): void {
    const ctx = this._ctx
    let spriteIndex

    ctx.save()
    ctx.globalAlpha = 1

    layer._gridUnclustered.eachObject(markerObj => {
      const { point: p, data: marker } = markerObj

      spriteIndex = layer.getMarkerSpriteIndex(marker)

      if (spriteIndex) {
        this._drawSpriteIcon(spriteIndex, p, ctx)
      }
    })

    ctx.restore()
  }

  _drawClusteredSpriteIcons (layer: SpriteMarkers): void {
    if (!layer.options.enableClustering) {
      return
    }

    const endAngle = Math.PI * 2
    const clusterRadius = layer.options.clusterIconRadius

    const ctx = this._ctx
    let pos

    ctx.save()

    ctx.globalAlpha = 1
    ctx.font = mapClusterFont
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'

    layer._gridClustered.eachObject(cluster => {
      pos = cluster.centerPoint
      ctx.beginPath()
      ctx.arc(pos.x, pos.y, clusterRadius, 0, endAngle)
      ctx.fillStyle = this._getClusterFillColor(layer, cluster)
      ctx.fill()

      ctx.fillStyle = layer.options.clusterFontColor
      ctx.fillText(cluster.markersCount, pos.x, pos.y)
    })

    ctx.restore()
  }

  _getClusterFillColor (layer: SpriteMarkers, cluster: SpriteMarkerCluster): string {
    if (typeof layer.options.clusterFillColor !== 'function') {
      return layer.options.clusterFillColor
    }

    return layer.options.clusterFillColor(layer, cluster)
  }

  _drawSpriteIcon (spriteIndex: SpriteIndex, p: Point, ctx: CanvasRenderingContext2D): void {
    // We want icon centered to coordinates but image is drawn from top left corner
    // so we have to offset top left corner by a half of width/height to make icon appear over the center point.
    const pos = p.subtract([Math.round(spriteIndex.width / 2), Math.round(spriteIndex.height / 2)])

    ctx.drawImage(
      spriteIndex.image,
      spriteIndex.x, spriteIndex.y, spriteIndex.width, spriteIndex.height,
      pos.x, pos.y, spriteIndex.scaledWidth, spriteIndex.scaledHeight
    )
  }

  private _applyPattern (ctx, layer): void {
    const hatching = HatchingFactory.createHatchingBlock(layer.options.hatching)
    if (hatching !== null) {
      ctx.fillStyle = ctx.createPattern(hatching.getHatching(), 'repeat') as CanvasPattern
      ctx.fill()
    }
  }
}

export function canvas (options: RendererOptions) {
  // @ts-ignore Accepts one argument - renderer options.
  return new CustomCanvasRenderer(options)
}
