import { Dataset, ExcelRow } from '@/types/common'
import { DataColumnType } from '@/types/enums'

export function getColumnInfosFromData (rows: ExcelRow[] = []): Dataset['columnInfos'] {
  if (!Array.isArray(rows) || rows.length === 0) {
    return []
  }

  return Object.keys(rows[0]).map(key => {
    const isNumber = typeof rows[0][key] === 'number'

    return {
      name: key,
      type: isNumber ? DataColumnType.Number : DataColumnType.Text,
      min: isNumber ? Math.min(...rows.map(row => row[key] as number)) : 0,
      max: isNumber ? Math.max(...rows.map(row => row[key] as number)) : 0
    }
  })
}
