export enum GeocodingState {
  inProgress,
  finished
}

export enum GeocodingConfidence {
  Bad = 'Bad',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Ambigous = 'Ambigous'
}

export enum ContentType {
  AddressesContentType = 'addresses',
  PointsContentType = 'points'
}
