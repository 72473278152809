import { LOGOUT } from '@/store/auth/auth-actions'
import { router } from '@/router/router'
import { Routes } from '@/router/constants/routes'
import { Location } from 'vue-router/types/router'
import { LogoutPayload } from '@/store/auth/types/auth.store'

const requests = [LOGOUT]

export default store => {
  store.subscribeAction({
    after: (action) => {
      if (requests.includes(action.type)) {
        if (router.currentRoute.name !== Routes.LOGIN) {
          const payload = action.payload as LogoutPayload | null

          const newLocation: Location = {
            name: 'login'
          }

          if (payload?.needReturn) {
            newLocation.query = { return_url: router.currentRoute.fullPath }
          }

          return router.push(newLocation)
        }
      }

      return undefined
    }
  })
}
