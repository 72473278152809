import { SET_SCORING_STATISTICS } from '@/store/mutation-types'
import {
  CAMPAIGN_FILTERS,
  SCORING_STATISTICS,
  SCORING_STATISTICS_GET_PARENT_FILTERS,
  SCORING_STATISTICS_GET_GOAL_FILTER,
  CAMPAIGN_GET_PARENT_FILTER,
  CAMPAIGN_GET_FILTER_CHILDREN,
  SCORING_STATISTICS_GET_PATH_STATISTICS,
  SCORING_STATISTICS_GET_OVERALL_STATISTIC
} from '@/store/getter-types'
import { getGoalFilter } from '@/utils/scoring/goals'
import { isGoalFilterNode } from '@/utils/plan-wizard-steps'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { uniqBy } from 'lodash'

const state = {
  statistics: []
}

const getters = {
  [SCORING_STATISTICS] (state) {
    return state.statistics
  },
  [SCORING_STATISTICS_GET_GOAL_FILTER] (_state, getters) {
    return (goalId) => getGoalFilter(goalId, getters[CAMPAIGN_FILTERS])
  },
  [SCORING_STATISTICS_GET_PARENT_FILTERS] (_state, getters) {
    const filters = getters[SCORING_STATISTICS]
      .map(statistic => {
        const goalFilter = getters[SCORING_STATISTICS_GET_GOAL_FILTER](statistic.goalId)
        return getters[CAMPAIGN_GET_PARENT_FILTER](goalFilter) ?? goalFilter
      })
      .filter(filter => filter !== null)

    return uniqBy(filters, 'id')
  },
  [SCORING_STATISTICS_GET_PATH_STATISTICS] (_state, getters) {
    return (filterNode) => getters[CAMPAIGN_GET_FILTER_CHILDREN](filterNode)
      .filter(filter => isGoalFilterNode(filter))
      .map((filter: ScoringFunctionNode) => {
        const goalId = filter.scoringFunction.data[0].id

        return getters[SCORING_STATISTICS].find(statistic => statistic.goalId === goalId)
      })
  },
  [SCORING_STATISTICS_GET_OVERALL_STATISTIC] (_state, getters) {
    return getters[SCORING_STATISTICS].find(statistic => statistic.goalType.includes('BudgetOverallGoal'))
  }
}

const mutations = {
  [SET_SCORING_STATISTICS] (state, statistics) {
    state.statistics = statistics
  }
}

const actions = {

}

export default {
  state,
  getters,
  mutations,
  actions
}
