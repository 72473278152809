import {
  DATASET_WIZARD_SET_DATASET,
  DATASET_WIZARD_SET_ENTRYPOINT,
  DATASET_WIZARD_SET_DATASET_NAME,
  DATASET_WIZARD_SET_VISIBLE,
  DATASET_WIZARD_SET_DATASET_TYPE,
  DATASET_WIZARD_SET_DATASET_UPDATE
} from '@/store/mutation-types'

export default {
  [DATASET_WIZARD_SET_VISIBLE] (state, visible) {
    state.visible = visible
  },
  [DATASET_WIZARD_SET_DATASET_NAME] (state, datasetName) {
    state.datasetName = datasetName
  },
  [DATASET_WIZARD_SET_DATASET] (state, dataset) {
    state.dataset = dataset
  },
  [DATASET_WIZARD_SET_DATASET_UPDATE] (state, dataset) {
    state.datasetUpdate = dataset
  },
  [DATASET_WIZARD_SET_ENTRYPOINT] (state, entrypoint) {
    state.entrypoint = entrypoint
  },
  [DATASET_WIZARD_SET_DATASET_TYPE] (state, type) {
    state.datasetType = type
  }
}
