import { Store } from '@/store/store'
import { LOAD_MEDIA_FILTER } from '@/store/action-types'
import { MEDIA_FILTER } from '@/store/getter-types'
import { CollectionItem, FilterListConfig } from '@/types/planning/wizard/filter-editor'
import { MediaFilterParameter } from '@/types/planning/wizard/value-types'
import { AbstractCollectionConfig } from './collection-config'

export class MediaFilterCollection
  extends AbstractCollectionConfig<MediaFilterParameter, CollectionItem>
  implements FilterListConfig<MediaFilterParameter> {
  get options (): CollectionItem[] {
    return Store.getters[MEDIA_FILTER]
  }

  isValueOptionEqual (value: MediaFilterParameter, option: CollectionItem): boolean {
    return value.key === option.value
  }

  formatCollectionOption (option: CollectionItem): string {
    return option.text
  }

  async initCollection (): Promise<void> {
    return Store.dispatch(LOAD_MEDIA_FILTER)
  }

  formatList (values: MediaFilterParameter[]): string[] {
    return values.map((item) => {
      const option = this.options.find((option) => option.value === item.key)
      return `${option?.text ?? item.key} - ${item.value}`
    })
  }
}
