




































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import AgGrid from '@/components/shared/ag-grid/ag-grid.vue'
import { CellValueChangedEvent, ColDef } from 'ag-grid-community'
import GridActionRemove from '@/components/shared/ag-grid/components/actions/grid-action-remove.vue'
import GridActionsMenu from '@/components/shared/ag-grid/components/actions/grid-actions-menu.vue'
import PickLocationAction from './actions/pick-location-action.vue'
import { NamedPoints } from '@/types/common'
import UploadFromFileAction from './actions/upload-from-file-action.vue'
import AddNewPointAction from './actions/add-new-point-action.vue'
import RemoveRowAction from './actions/remove-row-action.vue'
import { VueInstanceProps } from '@/types/vue'
import { PointsSettings } from '../points-settings'
import { countBy } from 'lodash'

@Component({
  components: { RemoveRowAction, AddNewPointAction, UploadFromFileAction, PickLocationAction, GridActionsMenu, GridActionRemove, AgGrid }
})
export default class PointsGrid extends Vue {
  @Model('change', { required: true }) readonly value!: NamedPoints
  @Prop({ required: true }) fields!: PointsSettings

  private readonly defaultColDef: ColDef = {
    sortable: true,
    editable: true,
    resizable: false,
    flex: 1,
    minWidth: 100
  }

  private rowClassRules = {
    'bg-warning': this.isRowDuplicate
  }

  private isRowDuplicate (params) {
    const rowIsEmpty = Object.keys(this.fields).some(field => !params.data[field])
    const hasDuplicateNames = this.amountOfNamesOccurrences[params.data.name] !== 1

    return rowIsEmpty || hasDuplicateNames
  }

  get amountOfNamesOccurrences () {
    return countBy(this.value, item => item.name)
  }

  private get columnDefs (): ColDef[] {
    return Object.values(this.fields)
  }

  private get actionsProps (): VueInstanceProps {
    return {
      value: this.value,
      fields: this.fields
    }
  }

  private updateValue (updatedValue) {
    this.$emit('change', updatedValue)
  }

  handleCellValueChanged (event: CellValueChangedEvent) {
    // Need to redraw row after cell edit to update row styles
    event.api.redrawRows()
  }
}
