











import { Component } from 'vue-property-decorator'
import { DatasetSlotConsumerMixin } from '../mixins/dataset-slot-consumer.mixin'

@Component
export default class QueryBuilderStatistics extends DatasetSlotConsumerMixin {
  private get filteredCount (): number {
    return this.filteredRows.length
  }

  private get totalCount (): number {
    return this.rows.length
  }

  private get totalVariant (): string {
    return this.totalCount > 0 ? 'success' : 'danger'
  }

  private get filteredVariant (): string {
    if (this.filteredCount === this.totalCount) {
      return 'success'
    }

    if (this.filteredCount === 0) {
      return 'danger'
    }

    return 'info'
  }
}
