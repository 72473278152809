































import { Component, Mixins, PropSync, Prop } from 'vue-property-decorator'
import { acceptFileFilters, EXCEL_FILE_TYPES, FileTypeConfigs } from '@/utils/file'
import { ExcelRow } from '@/types/common'
import { ImportCitiesDataset } from '@/types/planning/wizard/filter-editor'
import { TranslateResult } from 'vue-i18n'
import { getColumnInfosFromData } from '@/utils/dataset'
import FileProcess from '@/components/file-process/file-process.vue'
import SearchableDatasetList from '@/components/shared/searchable-dataset-list/searchable-dataset-list.vue'
import { ReadExcelFileMixin } from '@/mixins/read-file.mixin'
import { FormInputState } from '@/types/bootstrap'

const fileFilters = acceptFileFilters(EXCEL_FILE_TYPES)

@Component({
  components: { SearchableDatasetList, FileProcess }
})
export default class ImportCities extends Mixins<ReadExcelFileMixin>(ReadExcelFileMixin) {
  @PropSync('dataset', { default: () => null }) private datasetValue!: ImportCitiesDataset
  @Prop({ default: null }) private state!: FormInputState
  protected override allowedTypes: string[] = fileFilters.allowedTypes
  protected override allowedExtensions: string[] = fileFilters.allowedExtensions

  private fileTypes: FileTypeConfigs = EXCEL_FILE_TYPES
  private datasetLoading = false

  private get searchPrompt (): TranslateResult {
    return this.$t('planning.wizard.labels.import-cities-search-prompt')
  }

  private get uploadPrompt (): TranslateResult {
    return this.$t('planning.wizard.labels.import-cities-upload-prompt')
  }

  private fileUploadCallback (fileContent): Promise<void> {
    this.file = fileContent.file

    return this.readExcelFile(this.file).then((result: ExcelRow[]) => {
      return this.setDataset({ datasetRows: result, columnInfos: getColumnInfosFromData(result) })
    })
  }

  private setDataset (dataset: ImportCitiesDataset): void {
    this.datasetValue = dataset
  }
}
