import { LAYER_WIZARD_STEP_POI_DATASET_SET_UPDATE_STATE } from '@/store/mutation-types'
import { LAYER_WIZARD_CHANGE_DATASET, LAYER_WIZARD_STEP_POI_DATASET_INVALIDATE } from '@/store/action-types'
import { LAYER_WIZARD_STEP_POI_DATASET_WAS_UPDATED } from '@/store/getter-types'

// initial state
const state = {
  wasUpdated: false
}

// getters
const getters = {
  [LAYER_WIZARD_STEP_POI_DATASET_WAS_UPDATED] (state) {
    return state.wasUpdated
  }
}

// mutations
const mutations = {
  [LAYER_WIZARD_STEP_POI_DATASET_SET_UPDATE_STATE] (state, wasUpdated) {
    state.wasUpdated = wasUpdated
  }
}

// actions
const actions = {
  [LAYER_WIZARD_STEP_POI_DATASET_INVALIDATE] ({ dispatch }) {
    return dispatch(LAYER_WIZARD_CHANGE_DATASET, null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
