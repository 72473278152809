import {
  PLAN_WIZARD_ADD_FILTER_NODE,
  PLAN_WIZARD_REMOVE_FILTER_NODE,
  PLAN_WIZARD_REPLACE_FILTER_NODE,
  PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER,
  PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT,
  PLAN_WIZARD_SET_CAMPAIGN_ID,
  PLAN_WIZARD_SET_CAMPAIGN_NAME,
  PLAN_WIZARD_SET_CAMPAIGN_STATUS,
  PLAN_WIZARD_SET_START_DATE,
  PLAN_WIZARD_SET_END_DATE,
  PLAN_WIZARD_SET_FILTERS,
  PLAN_WIZARD_SET_STEP_LEAVE_CALLBACK,
  SET_PLAN_WIZARD_ACTIVE_FILTER,
  SET_PLAN_WIZARD_UPDATED_STATE,
  PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG,
  PLAN_WIZARD_SET_CONSIDER_STATUS_UNKNOWN_FLAG
} from '@/store/mutation-types'
import { nanoid } from 'nanoid'
import { get, cloneDeep } from 'lodash'
import { WizardStore } from './index'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { Customer } from '@/views/planning/children/campaign-export/types'

export default {
  [PLAN_WIZARD_SET_CAMPAIGN_ID] (state: WizardStore, id: number) {
    state.campaignId = id
  },
  [PLAN_WIZARD_SET_CAMPAIGN_NAME] (state: WizardStore, name: string) {
    state.campaignName = name
  },
  [PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER] (state: WizardStore, customer: Customer) {
    state.customer = customer
  },
  [PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT] (state: WizardStore, discount: number) {
    state.discount = discount
  },
  [PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG] (state: WizardStore, flag: boolean) {
    state.forceContinuousDisplay = flag
  },
  [PLAN_WIZARD_SET_CONSIDER_STATUS_UNKNOWN_FLAG] (state: WizardStore, flag: boolean) {
    state.considerUnknownAvailability = flag
  },

  [PLAN_WIZARD_SET_CAMPAIGN_STATUS] (state: WizardStore, status: string) {
    state.campaignStatus = status
  },
  [PLAN_WIZARD_SET_START_DATE] (state: WizardStore, startDate: Date) {
    state.startDate = startDate
  },
  [PLAN_WIZARD_SET_END_DATE] (state: WizardStore, endDate: Date) {
    state.endDate = endDate
  },
  [PLAN_WIZARD_SET_FILTERS] (state: WizardStore, filters: Array<ScoringFunctionNode>) {
    state.filters = filters
  },
  [PLAN_WIZARD_ADD_FILTER_NODE] (state: WizardStore, filter: ScoringFunctionNode) {
    filter.scoringFunction.data.forEach(scoringFunction => {
      scoringFunction.id = nanoid()
    })

    state.filters.push(filter)
  },
  [PLAN_WIZARD_REMOVE_FILTER_NODE] (state: WizardStore, filter: ScoringFunctionNode) {
    const filterIndex = state.filters.findIndex(item => item.id === filter.id)
    filterIndex !== -1 && state.filters.splice(filterIndex, 1)
  },
  // TODO add type to parameter
  [PLAN_WIZARD_REPLACE_FILTER_NODE] (state: WizardStore, { oldNode, newNode }) {
    const index = state.filters.indexOf(oldNode)

    newNode.scoringFunction.data.forEach((sf, index) => {
      sf.id = get(oldNode.scoringFunction.data[index], 'id', sf.id)
    })

    index !== -1 && state.filters.splice(index, 1, cloneDeep(newNode))
  },
  [SET_PLAN_WIZARD_UPDATED_STATE] (state: WizardStore, flag: boolean) {
    state.wasUpdated = flag
  },
  [SET_PLAN_WIZARD_ACTIVE_FILTER] (state: WizardStore, filter: ScoringFunctionNode) {
    state.activeFilter = filter
  },
  [PLAN_WIZARD_SET_STEP_LEAVE_CALLBACK] (state: WizardStore, callback: () => Promise<boolean>) {
    state.stepLeaveCallback = callback
  }
}
