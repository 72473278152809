import { i18n } from '@/locales/i18n'
import { PluginObject } from 'vue'
import { VueComponentDeclaration, VueInstanceProps } from '@/types/vue'
import { LocationEvent, PopupOptions } from 'leaflet'

interface LayerPopupContent {
  component: VueComponentDeclaration,
  props: VueInstanceProps
}

interface LayerPopupProps {
  content: LayerPopupContent,
  event: LocationEvent,
  popupOptions: PopupOptions
}

type LayerPopupFunction = (props: LayerPopupProps) => void

declare module 'vue/types/vue' {
  interface Vue {
    $layerPopup: LayerPopupFunction;
  }
}

export class LayerPopupPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$layerPopup = function ({ content, event, popupOptions = { minWidth: 400 } }): void {
      const layerPopup = new Vue({
        i18n,
        render: h => h(content.component, {
          props: {
            ...content.props
          }
        })
      }).$mount()

      const popupTarget = event.target

      popupTarget.bindPopup(layerPopup.$el, popupOptions).addTo(popupTarget._map)

      this.$nextTick(function () {
        popupTarget.openPopup(event.latlng)
      })

      const unbindPopup = () => {
        popupTarget.unbindPopup()
        popupTarget._map.off('popupclose')
      }

      popupTarget._map.on('popupclose', () => {
        unbindPopup()
      })
    }
  }
}
