import { Bounds, LatLng, Point, setOptions } from 'leaflet'
import Flatbush from '@/lib/flatbush/index'
import ClipperLib from '@/lib/clipper/index'
import { MultiPolyline } from '../multi-polyline/multi-polyline'
import { ShapesLayer } from '@/lib/leaflet/layer/shapes-layer'
import { PathPolygon } from '@/types/visualization/layer/geometry'
import ProjectedPolygon from '@/lib/leaflet/layer/multi-polygons/multi-polygon/projected-polygon/projected-polygon'
import { ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'
import { DEFAULT_POLYGON_HATCHING } from '@/components/visualization/layer-settings/constants'
import { HatchingConfig } from '@/types/visualization/layer/style'

export interface MultiPolygonOptions extends ZIndexedPathOptions {
  smoothFactor?: number,
  noClip?: boolean,
  union: boolean,
  forceLabel: boolean,
  labelText: string | null,
  labelTextSize: number,
  labelFontColor: string,
  labelStrokeWidth: number,
  labelStrokeColor: string,
  hatching: HatchingConfig
}

export class MultiPolygon extends ShapesLayer<ProjectedPolygon, MultiPolygonOptions> {
  private _polygons!: ProjectedPolygon[]
  public _drawParts!: ProjectedPolygon[]

  initialize (latlngs: LatLng[], options: ZIndexedPathOptions): void {
    const mergedOptions: MultiPolygonOptions = {
      smoothFactor: 1.0,
      noClip: false,
      fill: true,
      union: false,
      zIndex: 1,
      labelText: null,
      labelFontColor: 'blue',
      labelStrokeColor: 'white',
      labelStrokeWidth: 4.0,
      labelTextSize: 12,
      forceLabel: false,
      hatching: DEFAULT_POLYGON_HATCHING,
      ...options
    }

    setOptions(this, mergedOptions)
    this._setLatLngs(latlngs)
  }

  protected _convertLatLngs (latlngs: LatLng[] | LatLng[][]): LatLng[] | LatLng[][] {
    const result: LatLng[] | LatLng[][] = []

    let len = latlngs.length

    for (let i = 0; i < len; i++) {
      if (MultiPolygon.isFlat(latlngs)) {
        result[i] = latlngs[i]
        this._bounds.extend(latlngs[i])
      } else {
        result[i] = this._convertLatLngs(latlngs[i]) as LatLng[]
      }
    }

    len = result.length

    // remove last point if it equals first one
    if (MultiPolygon.isFlat(result) && len >= 2 && result[0].equals(result[len - 1])) {
      result.pop()
    }

    return result
  }

  private setContextMenuItemDisabled (index: number, isDisabled: boolean): void {
    MultiPolyline.prototype.setContextMenuItemDisabled.call(this, index, isDisabled)
  }

  private setUnion (value: boolean): void {
    if (value === this.options.union) {
      return
    }

    this.options.union = value

    // Union is affected by polygon simplification (needs simplification with zero tolerance)
    // so we have to run full update cycle in order to simplify polygons before actually calling union operation.
    // The easiest way to achieve this is just to call redraw().
    this.redraw()
  }

  protected projectLatlngs (latlngs: LatLng[] | PathPolygon | PathPolygon[]): Bounds {
    const projectedBounds = new Bounds([])
    this._polygons = []

    const polygonsLength = latlngs.length

    for (let i = 0; i < polygonsLength; i++) {
      const polygon = latlngs[i] as LatLng[][]
      const ringsLength = polygon.length
      const rings: Point[][] = []

      for (let j = 0; j < ringsLength; j++) {
        const ring = polygon[j]
        const pointsLength = ring.length

        rings[j] = []

        for (let k = 0; k < pointsLength; k++) {
          rings[j][k] = this._map.latLngToLayerPoint(ring[k])
        }
      }

      this._polygons[i] = new ProjectedPolygon(rings, i)
      projectedBounds.extend(this._polygons[i].bounds.min as Point)
      projectedBounds.extend(this._polygons[i].bounds.max as Point)
    }

    return projectedBounds
  }

  protected _update (): void {
    if (!this._map) {
      return
    }

    const bounds = this.clipShapes()
    this.clipShapesToBounds(bounds)
    this._simplifyPolygons()
    this._unionPolygons()
    this._updatePath()
  }

  protected clipShapesToBounds (bounds: Bounds | null): void {
    if (bounds == null) {
      return
    }

    if (this.options.noClip) {
      this._parts = this._polygons
      return
    }

    for (let i = 0; i < this._polygons.length; i++) {
      const clipped = this._polygons[i].clip(bounds)

      if (clipped) {
        this._parts.push(clipped)
      }
    }
  }

  private _simplifyPolygons (): void {
    let tolerance = this.options.smoothFactor as number

    // We need to disable tolerance to let union algorithm work properly.
    // Also disable tolerance if border is disabled to avoid invalid holes between two near polygons.
    if (this.options.union || !this.options.stroke || this.options.weight === 0) {
      tolerance = 0
    }

    const len = this._parts.length

    for (let i = 0; i < len; i++) {
      this._parts[i].simplify(tolerance)
    }
  }

  private _unionPolygons (): void {
    if (!this.options.union) {
      this._drawParts = this._parts
      return
    }

    const index = this._indexPolygons()
    const rings = this._unionTree(index)

    const pointRings: Point[][] = rings
      .map(ringArray => ringArray
        .map(pointTuple => new Point(pointTuple[0], pointTuple[1])
        )
      )

    this._drawParts = [new ProjectedPolygon(pointRings, -1)]
  }

  private _unionTree (tree): number[][][] {
    if (tree.length === 0) {
      return []
    }

    const polygons: number[][][][] = tree.map(branch => {
      if (Array.isArray(branch)) {
        return this._unionTree(branch)
      } else {
        return this._parts[branch].ringsToArray(true)
      }
    })

    const pointsPolygons: { X: number, Y: number }[][][] = polygons
      .map(polygon => polygon
        .map(rings => rings
          .map(ring => ({ X: ring[0], Y: ring[1] }))
        )
      )

    const solution: { X: number, Y: number }[][] = []
    const clipper = new ClipperLib.Clipper(0)

    clipper.AddPaths(pointsPolygons[0], ClipperLib.PolyType.ptSubject, true)

    for (let i = 1; i < pointsPolygons.length; i++) {
      clipper.AddPaths(pointsPolygons[i], ClipperLib.PolyType.ptClip, true)
    }

    clipper.Execute(
      ClipperLib.ClipType.ctUnion,
      solution,
      ClipperLib.PolyFillType.pftEvenOdd,
      ClipperLib.PolyFillType.pftEvenOdd
    )

    return solution
      .map(rings => rings
        .map(ring => ([ring.X, ring.Y]))
      )
  }

  private _indexPolygons () {
    const polygonsLength = this._parts.length

    if (polygonsLength === 0) {
      return []
    }

    const bounds = this._renderer._bounds
    const index = new Flatbush(polygonsLength, 4, Int16Array)

    for (let i = 0; i < this._parts.length; i++) {
      const polygon = this._parts[i]
      const ringsLength = polygon.rings.length

      let minY = bounds.max?.y ?? Infinity
      let maxY = bounds.min?.y ?? 0
      let minX = bounds.max?.x ?? Infinity
      let maxX = bounds.min?.x ?? 0

      for (let j = 0; j < ringsLength; j++) {
        const ring = polygon.rings[j]
        const pointsLength = ring.length

        for (let k = 0; k < pointsLength; k++) {
          const p = ring[k]

          minY = Math.min(minY, p.y)
          maxY = Math.max(minY, p.y)
          minX = Math.min(minX, p.x)
          maxX = Math.max(minX, p.x)
        }
      }

      index.add(minX, minY, maxX, maxY)
    }

    index.finish()

    return index.itemsTree()
  }

  protected _updatePath (): void {
    this._renderer.updateMultiPolygon(this)
  }

  public getLabelTextForPoly (projectedPolygon: ProjectedPolygon): string | null {
    if (!this.options.labelText) {
      return null
    }

    if (Array.isArray(this.options.labelText)) {
      if (projectedPolygon.index >= 0 && projectedPolygon.index < this.options.labelText.length) {
        return this.options.labelText[projectedPolygon.index]
      } else {
        return null
      }
    }

    return this.options.labelText
  }

  private static isFlat (latlngs: LatLng[] | LatLng[][]): latlngs is LatLng[] {
    return latlngs[0] instanceof LatLng
  }
}
