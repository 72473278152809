











import { Component, Mixins, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash'
import { GridChildMixin } from '../mixins/grid-child.mixin'
import { FilterChangedEvent, FilterManager } from 'ag-grid-community'

type ApplyFilterFunc = (text: string) => void

@Component
export default class GridQuickFilter extends Mixins(GridChildMixin) {
  private filterText = ''
  private _setGridFilter!: ApplyFilterFunc | null

  @Watch('filterText')
  private onFilterTextChange (newValue: string, oldValue: string): void {
    if (newValue !== oldValue) {
      this.setGridFilter(newValue)
    }
  }

  created (): void {
    this.initGridFilter()
  }

  mounted (): void {
    this.onGridApi('filterChanged', this.onFilterChanged)
  }

  private initGridFilter (): void {
    const filterFunc: ApplyFilterFunc = (filterText: string) => {
      this.callGridApi((gridApi) => gridApi.setQuickFilter(filterText))
    }
    this._setGridFilter = debounce(filterFunc, 200)
  }

  private setGridFilter (value: string): void {
    this._setGridFilter && this._setGridFilter(value)
  }

  private onFilterChanged (event: FilterChangedEvent): void {
    if (!event.api.isQuickFilterPresent()) {
      this.filterText = ''
    } else {
      // @ts-ignore: Private, but we really need this pleeeease.
      const newFilter = (event.api.filterManager as FilterManager).quickFilter

      // Quick filter inside grid is stored in upper case.
      // So this is an ugly way to check if quick filter change is
      // caused by this component or is external to us.
      // If change is internal we keep filterText as is without unexpected case change.
      if (newFilter !== this.filterText.toUpperCase()) {
        this.filterText = newFilter
      }
    }
  }
}
