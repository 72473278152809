import VuexPersistence from 'vuex-persist'
import { CLEAR_AUTH_TOKEN, SET_AUTH_TOKEN } from '@/store/auth/auth-mutations'

const authMutations = [SET_AUTH_TOKEN, CLEAR_AUTH_TOKEN]

const persistence = new VuexPersistence({
  key: 'auth',
  modules: ['auth'],
  filter: mutation => {
    return authMutations.includes(mutation.type)
  }
})

export default persistence.plugin
