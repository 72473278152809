import { Component, Mixins } from 'vue-property-decorator'
import { Path } from 'leaflet'
import { Color } from 'csstype'
import { ItemsGroup, ItemsGroupMapper, MapObjectOptionComputed, MapObjectOptionGetter } from '../../types'
import { GroupByMixin } from './group-by.mixin'
import { PathsMixin } from './paths.mixin'
import { ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

function makeGetter<TItem, TVal> (
  possibleFunc: MapObjectOptionComputed<TItem, TVal>
): MapObjectOptionGetter<TItem, TVal> {
  if (typeof possibleFunc === 'function') {
    return possibleFunc as MapObjectOptionGetter<TItem, TVal>
  }

  return (): TVal => possibleFunc
}

@Component
export class PathsColorGroupMixin<TItem, TLayer extends Path, TOptions extends ZIndexedPathOptions>
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  extends Mixins<GroupByMixin<TItem, TLayer, TOptions>>(GroupByMixin) {
  protected override get groupMapper (): ItemsGroupMapper<TItem, TOptions> {
    const thisAsPath = (this as unknown as PathsMixin<TItem, TLayer>)
    const getFillColor = makeGetter<TItem, Color>(thisAsPath.fillColor)
    const getFillOpacity = makeGetter(thisAsPath.fillOpacity)
    const getColor = makeGetter(thisAsPath.pathColor)
    const getOpacity = makeGetter(thisAsPath.pathOpacity)

    return (items: TItem[]): ItemsGroup<TItem, TOptions> => {
      const fillColor = getFillColor(items[0])
      const fillOpacity = getFillOpacity(items[0])
      const color = getColor(items[0])
      const opacity = getOpacity(items[0])

      const options = {
        fillColor,
        fillOpacity,
        color,
        opacity
      } as TOptions

      return {
        items,
        options
      }
    }
  }
}
