import { CollectionItemArray, SelectOption } from '@/types/planning/wizard/filter-editor'
import { CollectionConfig } from './collection-config'
import { padStart } from 'lodash'
import { i18n } from '@/locales/i18n'
import { FILTER_MULTISITE_LOCATION } from '@/constants/scoring'

const MAX_POSITIONS = 15

export class SitesPerLocationCollection extends CollectionConfig {
  async initCollection (): Promise<void> {
    return Promise.resolve()
  }

  get options (): CollectionItemArray {
    return SitesPerLocationCollection.getOptions()
  }

  formatList (values: string[]): string[] {
    return this.options
      .filter(option => values.some(value => value === option.value))
      .map(option => option.text)
  }

  private static getOptions (): SelectOption[] {
    const options: SelectOption[] = [
      { text: i18n.t(`planning.functions.${FILTER_MULTISITE_LOCATION}.values.single-location`) as string, value: '01' }
    ]

    for (let ic = 2; ic <= MAX_POSITIONS; ic++) {
      options.push({
        text: i18n.t(`planning.functions.${FILTER_MULTISITE_LOCATION}.values.n-sites`, { amount: ic }) as string,
        value: padStart(`${ic}`, 2, '0')
      })
    }

    return options
  }
}
