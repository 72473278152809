import { DATASET_WIZARD_DATASET, DATASET_WIZARD_DATASET_TYPE } from '@/store/getter-types'

export const DATASET_WIZARD_TAB_TAGS_IS_ENABLED = 'datasetWizard/tabs/tags/isEnabled'
export const DATASET_WIZARD_TAB_TAGS_IS_EMPTY = 'datasetWizard/tabs/tags/isEmpty'
export const DATASET_WIZARD_TAB_TAGS_IS_INVALID = 'datasetWizard/tabs/tags/isInvalid'
export const DATASET_WIZARD_TAB_TAGS_ERRORS = 'datasetWizard/tabs/tags/errors'
const DATASET_WIZARD_TAB_TAGS_STEP_ID = 'datasetWizard/tabs/tags/stepId'

export const getters = {
  [DATASET_WIZARD_TAB_TAGS_IS_ENABLED] () {
    return getters[DATASET_WIZARD_DATASET_TYPE] !== null
  },
  [DATASET_WIZARD_TAB_TAGS_IS_EMPTY] (_state, getters) {
    return getters[DATASET_WIZARD_DATASET_TYPE] === null || getters[DATASET_WIZARD_DATASET] === null
  },
  [DATASET_WIZARD_TAB_TAGS_IS_INVALID] (_state, getters) {
    return getters[DATASET_WIZARD_TAB_TAGS_ERRORS].length > 0
  },
  [DATASET_WIZARD_TAB_TAGS_ERRORS] () {
    return []
  },
  [DATASET_WIZARD_TAB_TAGS_STEP_ID] (state) {
    return state.stepId
  }
}
