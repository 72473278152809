import { Component, Inject, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { FormInputState } from '@/types/bootstrap'
import { ValidationModel } from '@/vendor/types/vuelidate/model'
import { ObserveModelFunc, UnobserveModelFunc, ValidationResult } from './types'

type GetFields<T extends Vue> = keyof T

@Component({
  mixins: [validationMixin]
})
export class ValidationProviderMixin extends Vue {
  @Inject({ from: 'observeValidationModel', default: undefined })
  private readonly _observeValidationModel?: ObserveModelFunc

  @Inject({ from: 'unobserveValidationModel', default: undefined })
  private readonly _unobserveValidationModel?: UnobserveModelFunc

  private _registeredModels?: Set<string>

  mounted (): void {
    this.initValidationObserver()
  }

  beforeDestroy (): void {
    const registeredModels = this._registeredModels ?? []
    for (const key of registeredModels) {
      this.unobserveValidationModel(key)
    }
  }

  protected observeValidationModel (key: string, model: ValidationResult): void {
    if (!this._registeredModels) {
      this._registeredModels = new Set<string>()
    }

    this._registeredModels.add(key)
    this._observeValidationModel?.(key, model)
  }

  protected unobserveValidationModel (key: string): void {
    this._registeredModels?.delete(key)
    this._unobserveValidationModel?.(key)
  }

  protected getFieldValidationState (key: GetFields<this>): FormInputState {
    const model = this.$v[key as string] as ValidationModel
    return this.validationModelToInputState(model)
  }

  protected validationModelToInputState (model: ValidationModel): FormInputState {
    if (model.$error) {
      return false
    }

    if (!model.$dirty || model.$pending) {
      return null
    }

    return !model.$invalid
  }

  protected initValidationObserver (): void { /* */ }
}
