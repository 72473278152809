import { ApiPlanningNodeDTO, ApiPlanningNodeFunctionDTO, ApiPlanningTransitionDTO } from '@/types/api'
import { ScoringFunctionConfig, ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { NODE_ID_END, NODE_ID_MERGE, NODE_ID_START, NODE_ID_INTERMEDIATE } from '@/constants/scoring-graph'
import { getNodeChildren } from '@/utils/scoring/nodes'
import {
  isLocationFilterNode,
  isMergedFilterNode,
  isSequentialNode,
  getFilterFunctionName,
  isExclusionFilterNode
} from '@/utils/plan-wizard-steps/filter-node-steps'

interface NodesTransitionsDTO {
  nodes: ApiPlanningNodeDTO[];
  transitions: ApiPlanningTransitionDTO[];
}

export class NodesTransitionsGenerator {
  private readonly startNode: ScoringFunctionNode = {
    id: NODE_ID_START,
    name: NODE_ID_START,
    scoringFunction: {} as ScoringFunctionConfig
  }

  private readonly intermediateNode: ScoringFunctionNode = {
    id: NODE_ID_INTERMEDIATE,
    name: NODE_ID_INTERMEDIATE,
    scoringFunction: {} as ScoringFunctionConfig
  }

  private readonly mergeNode = {
    id: NODE_ID_MERGE,
    name: NODE_ID_MERGE,
    scoringFunction: {} as ScoringFunctionConfig
  }

  private readonly endNode = {
    id: NODE_ID_END,
    name: NODE_ID_END,
    scoringFunction: {} as ScoringFunctionConfig
  }

  private readonly initialNodes: ScoringFunctionNode[]
  private processedNodes: ScoringFunctionNode[] = []
  private transitions: ApiPlanningTransitionDTO[] = []

  constructor (nodes: ScoringFunctionNode[]) {
    this.initialNodes = nodes
  }

  private addTransition (from: ScoringFunctionNode, to: ScoringFunctionNode): void {
    this.transitions.push({
      id: 'T' + (this.transitions.length),
      from: from.id,
      to: to.id
    })
  }

  private processNode (node: ScoringFunctionNode, exitNode: ScoringFunctionNode): void {
    this.processedNodes.push(node)
    const children: ScoringFunctionNode[] = getNodeChildren(node, this.initialNodes)

    // Node itself is leave (has no children)
    // so connect it directly to the exit node nad stop processing.
    if (!children.length) {
      this.addTransition(node, exitNode)
      return
    }

    const parallelChildren = children.filter((node) => !isSequentialNode(node))
    const sequentialChildren = children.filter((node) => isSequentialNode(node))

    const parallelExitNode = sequentialChildren[0] ?? exitNode

    for (const child of parallelChildren) {
      this.addTransition(node, child)
      this.processNode(child, parallelExitNode)
    }

    if (sequentialChildren.length) {
      this.processedNodes.push(sequentialChildren[0])
      this.processSequentialNodes(sequentialChildren[0], sequentialChildren.slice(1), exitNode)
    }
  }

  private processStartNodes (startNodes: ScoringFunctionNode[], startNode: ScoringFunctionNode = this.startNode): void {
    for (const node of startNodes) {
      this.addTransition(startNode, node)
      this.processNode(node, this.mergeNode)
    }
  }

  private processExclusionNode (nodes: ScoringFunctionNode[]): void {
    for (const node of nodes) {
      this.addTransition(this.startNode, node)
      this.processNode(node, this.intermediateNode)
    }
  }

  private processMergedNodes (mergedNodes: ScoringFunctionNode[]): void {
    this.processSequentialNodes(this.mergeNode, mergedNodes, this.endNode)
  }

  private processSequentialNodes (
    sequenceStart: ScoringFunctionNode,
    sequence: ScoringFunctionNode[],
    sequenceEnd: ScoringFunctionNode
  ): void {
    for (const node of sequence) {
      this.processedNodes.push(node)
    }

    const nodesOrder: ScoringFunctionNode[] = [sequenceStart, ...sequence, sequenceEnd]

    for (let i = 0; i < nodesOrder.length - 1; i++) {
      this.addTransition(nodesOrder[i], nodesOrder[i + 1])
    }
  }

  private getNodesDTO (): ApiPlanningNodeDTO[] {
    return this.processedNodes.map((node) => {
      const planningFunctions: ApiPlanningNodeFunctionDTO[] = []

      const funcClassName = getFilterFunctionName(node)

      if (funcClassName) {
        node.scoringFunction.data.forEach((funcData) => {
          planningFunctions.push({
            className: funcClassName,
            content: funcData ? JSON.stringify(funcData) : ''
          })
        })
      }

      return {
        id: node.id,
        name: node.name,
        planningFunctions
      }
    })
  }

  generate (): NodesTransitionsDTO {
    const locationNodes = this.initialNodes.filter(isLocationFilterNode)
    const mergedNodes = this.initialNodes.filter(isMergedFilterNode)
    const exclusionNodes = this.initialNodes.filter(isExclusionFilterNode)

    if (exclusionNodes.length) {
      this.processExclusionNode(exclusionNodes)
      this.processStartNodes(locationNodes, this.intermediateNode)
    } else {
      this.processStartNodes(locationNodes)
    }

    this.processMergedNodes(mergedNodes)

    if (this.processedNodes.length) {
      this.processedNodes = [this.startNode, this.mergeNode, this.endNode, ...this.processedNodes]

      exclusionNodes.length && (this.processedNodes.unshift(this.intermediateNode))
    }

    return {
      nodes: this.getNodesDTO(),
      transitions: this.transitions
    }
  }
}
