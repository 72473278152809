import { get } from 'lodash'
import { i18n } from '@/locales/i18n'
import {
  LAYER_WIZARD_DATASET,
  LAYER_WIZARD_LAYER_STYLE,
  LAYER_WIZARD_POINTS_CONTENT_TYPE,
  LAYER_WIZARD_POINTS_GEOCODING_COLUMNS,
  LAYER_WIZARD_POINTS_GEOCODING_FINISHED,
  LAYER_WIZARD_POINTS_LATITUDE_COLUMN,
  LAYER_WIZARD_POINTS_LONGITUDE_COLUMN
} from '@/store/getter-types'
import {
  LAYER_WIZARD_POINTS_CHANGE_CONTENT_TYPE,
  LAYER_WIZARD_POINTS_HANDLE_RESULTS,
  LAYER_WIZARD_POINTS_RESET,
  LAYER_WIZARD_POINTS_SET_LATITUDE_COLUMN,
  LAYER_WIZARD_POINTS_SET_LONGITUDE_COLUMN,
  LAYER_WIZARD_POINTS_VALIDATE,
  LAYER_WIZARD_POINTS_RESET_GEOCODE_RESULTS
} from '@/store/action-types'
import {
  LAYER_WIZARD_SET_DATASET_UPDATE,
  LAYER_WIZARD_POINTS_SET_CONTENT_TYPE,
  LAYER_WIZARD_POINTS_SET_GEOCODING_COLUMNS,
  LAYER_WIZARD_POINTS_SET_GEOCODING_STATE,
  LAYER_WIZARD_UPDATE_LAYER_STYLE
} from '@/store/mutation-types'
import { ADDRESSES_CONTENT_TYPE, POINTS_CONTENT_TYPE } from '@/store/layer-wizard/constants/points-content-types'

const DEFAULT_CONTENT_TYPE = POINTS_CONTENT_TYPE

// initial state
const state = {
  geocodingColumns: [],
  geocodingFinished: true,
  contentType: DEFAULT_CONTENT_TYPE
}

// getters
const getters = {
  [LAYER_WIZARD_POINTS_LATITUDE_COLUMN] (state, getters, rootState, rootGetters) {
    return get(rootGetters[LAYER_WIZARD_LAYER_STYLE], 'latitudeColumn')
  },
  [LAYER_WIZARD_POINTS_LONGITUDE_COLUMN] (state, getters, rootState, rootGetters) {
    return get(rootGetters[LAYER_WIZARD_LAYER_STYLE], 'longitudeColumn')
  },
  [LAYER_WIZARD_POINTS_GEOCODING_COLUMNS] (state) {
    return state.geocodingColumns
  },
  [LAYER_WIZARD_POINTS_GEOCODING_FINISHED] (state) {
    return state.geocodingFinished
  },
  [LAYER_WIZARD_POINTS_CONTENT_TYPE] (state) {
    return state.contentType
  }
}

// mutations
const mutations = {
  [LAYER_WIZARD_POINTS_SET_GEOCODING_STATE] (state, finished) {
    state.geocodingFinished = finished
  },
  [LAYER_WIZARD_POINTS_SET_GEOCODING_COLUMNS] (state, columns) {
    state.geocodingColumns = columns
  },
  [LAYER_WIZARD_POINTS_SET_CONTENT_TYPE] (state, contentType) {
    state.contentType = contentType
  }
}

// actions
const actions = {
  [LAYER_WIZARD_POINTS_SET_LATITUDE_COLUMN] ({ commit }, column) {
    commit(LAYER_WIZARD_UPDATE_LAYER_STYLE, { latitudeColumn: column }, { root: true })
  },
  [LAYER_WIZARD_POINTS_SET_LONGITUDE_COLUMN] ({ commit }, column) {
    commit(LAYER_WIZARD_UPDATE_LAYER_STYLE, { longitudeColumn: column }, { root: true })
  },
  async [LAYER_WIZARD_POINTS_HANDLE_RESULTS] ({ commit, dispatch, getters, rootGetters }, { coordinateColumns, rowData, selectedColumns }) {
    if (getters[LAYER_WIZARD_POINTS_CONTENT_TYPE] === ADDRESSES_CONTENT_TYPE) {
      const dataset = rootGetters[LAYER_WIZARD_DATASET]

      const tableWithLatLng = dataset.datasetRows.map((item, index) => {
        return Object.values(item).concat(rowData[index] != null ? Object.values(rowData[index]) : [])
      })

      const newColumns = Object.values(coordinateColumns).map((name) => ({ name, type: 'number' }))
      const columnInfos = [...dataset.columnInfos, ...newColumns]

      commit(LAYER_WIZARD_SET_DATASET_UPDATE, { rows: tableWithLatLng, columnInfos })
      commit(LAYER_WIZARD_POINTS_SET_GEOCODING_STATE, true)
    }

    commit(LAYER_WIZARD_POINTS_SET_GEOCODING_COLUMNS, selectedColumns)

    await dispatch(LAYER_WIZARD_POINTS_SET_LATITUDE_COLUMN, coordinateColumns.latitudeColumn)
    await dispatch(LAYER_WIZARD_POINTS_SET_LONGITUDE_COLUMN, coordinateColumns.longitudeColumn)
  },
  async [LAYER_WIZARD_POINTS_RESET_GEOCODE_RESULTS] ({ commit, dispatch }) {
    commit(LAYER_WIZARD_POINTS_SET_GEOCODING_STATE, false)
    commit(LAYER_WIZARD_SET_DATASET_UPDATE, null)
    await dispatch(LAYER_WIZARD_POINTS_SET_LATITUDE_COLUMN, null)
    await dispatch(LAYER_WIZARD_POINTS_SET_LONGITUDE_COLUMN, null)
  },
  [LAYER_WIZARD_POINTS_VALIDATE] ({ getters }) {
    const errors = []
    const contentType = getters[LAYER_WIZARD_POINTS_CONTENT_TYPE]

    switch (contentType) {
      case POINTS_CONTENT_TYPE:
        if (!getters[LAYER_WIZARD_POINTS_LATITUDE_COLUMN] || !getters[LAYER_WIZARD_POINTS_LONGITUDE_COLUMN]) {
          errors.push(i18n.t('visualization.new-layer-modal.errors.no-latitude-longitude-selected'))
        }
        break
      case ADDRESSES_CONTENT_TYPE:
        if (!getters[LAYER_WIZARD_POINTS_GEOCODING_FINISHED]) {
          errors.push(i18n.t('visualization.new-layer-modal.errors.addresses-not-geocoded'))
        }
        break
    }

    return Promise.resolve(errors)
  },
  async [LAYER_WIZARD_POINTS_CHANGE_CONTENT_TYPE] ({ commit, dispatch }, contentType) {
    await dispatch(LAYER_WIZARD_POINTS_RESET)
    commit(LAYER_WIZARD_POINTS_SET_CONTENT_TYPE, contentType)
  },
  async [LAYER_WIZARD_POINTS_RESET] ({ commit, dispatch }) {
    commit(LAYER_WIZARD_POINTS_SET_CONTENT_TYPE, DEFAULT_CONTENT_TYPE)
    commit(LAYER_WIZARD_POINTS_SET_GEOCODING_COLUMNS, [])
    await dispatch(LAYER_WIZARD_POINTS_RESET_GEOCODE_RESULTS)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
