



























import { Component, Vue } from 'vue-property-decorator'
import Disclaimer from '@/components/shared/disclaimer/disclaimer.vue'
import { IHeaderParams } from 'ag-grid-community'

@Component({
  components: { Disclaimer }
})
export default class GridHeaderDisclaimer extends Vue {
  protected params!: IHeaderParams
}
