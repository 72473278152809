import { PluginObject } from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $toastError: (errorMessage: string, autoHideDelay?: number) => void;
    $toastWarning: (errorMessage: string, autoHideDelay?: number) => void;
  }
}

export class ToastPlugin implements PluginObject<never> {
  install (Vue): void {
    Vue.prototype.$toastError = function (errorMessage: string, autoHideDelay = 10000): void {
      this.$bvToast.toast(errorMessage, {
        variant: 'danger',
        title: this.$t('common.labels.error') as string,
        autoHideDelay: autoHideDelay
      })
    }

    Vue.prototype.$toastWarning = function (errorMessage: string, autoHideDelay = 5000): void {
      this.$bvToast.toast(errorMessage, {
        variant: 'warning',
        title: this.$t('common.labels.warning') as string,
        autoHideDelay: autoHideDelay
      })
    }
  }
}
