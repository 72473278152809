import { RESET_QUICK_FILTERS, UPDATE_CUSTOM_TIME_FRAME } from '@/store/action-types'
import {
  CAMPAIGN_CUSTOM_TIME_FRAME,
  CAMPAIGN_DISABLED_FILTER_IDS,
  CAMPAIGN_ENABLED_FILTERS,
  CAMPAIGN_END_DATE,
  CAMPAIGN_FILTERED_AVAILABILITY_FILTER,
  CAMPAIGN_FILTERING_ENABLED,
  CAMPAIGN_FILTERS,
  CAMPAIGN_FLIGHTS_DISABLED,
  CAMPAIGN_GOALS_DISABLED,
  CAMPAIGN_IS_FILTER_ENABLED,
  CAMPAIGN_START_DATE
} from '@/store/getter-types'
import {
  DISABLE_CAMPAIGN_FILTER_IDS,
  ENABLE_CAMPAIGN_FILTER_IDS,
  SET_CAMPAIGN_DISABLED_FILTER_IDS,
  SET_CAMPAIGN_FLIGHTS_DISABLED,
  SET_CAMPAIGN_CUSTOM_TIME_FRAME,
  SET_CAMPAIGN_GOALS_DISABLED
} from '@/store/mutation-types'
import { get } from 'lodash'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { isCommonGoalFilterNode, isGoalFilterNode } from '@/utils/plan-wizard-steps'
import { isSelfOrParentKey } from '@/utils/scoring/nodes'
import { QuickFilterSettings } from '@/types/store/planning-wizard/quick-filters'

import moment from '@/lib/moment'

// initial state
const state: QuickFilterSettings = {
  customTimeFrame: null,
  disableGoals: false,
  disableFlights: false,
  disabledFilterIds: []
}

const getters = {
  [CAMPAIGN_GOALS_DISABLED] (state: QuickFilterSettings): boolean {
    return state.disableGoals
  },
  [CAMPAIGN_FLIGHTS_DISABLED] (state: QuickFilterSettings): boolean {
    return state.disableFlights
  },
  [CAMPAIGN_CUSTOM_TIME_FRAME] (state: QuickFilterSettings): Date[] | null {
    return state.customTimeFrame
  },
  [CAMPAIGN_DISABLED_FILTER_IDS] (state: QuickFilterSettings): string[] {
    return state.disabledFilterIds
  },
  [CAMPAIGN_IS_FILTER_ENABLED] (state): (node: ScoringFunctionNode) => boolean {
    return (filterNode) => {
      if (state.disableGoals && (isGoalFilterNode(filterNode) || isCommonGoalFilterNode(filterNode))) {
        return false
      }

      const isKeyDisabled = isSelfOrParentKey.bind(undefined, filterNode)

      return !state.disabledFilterIds.some(isKeyDisabled)
    }
  },
  [CAMPAIGN_FILTERED_AVAILABILITY_FILTER] (_state: QuickFilterSettings, getters) {
    return (availabilityNode: ScoringFunctionNode) => {
      if (availabilityNode) {
        const customTimeFrame = getters[CAMPAIGN_CUSTOM_TIME_FRAME]
        const range = moment.range(customTimeFrame.start, customTimeFrame.end)

        availabilityNode.scoringFunction.data[0].BookingTimeFrames = (availabilityNode.scoringFunction.data[0].BookingTimeFrames as [])
          .filter(({ start, end }) =>
            range.contains(moment(start)) || range.contains(moment(end).subtract({ ms: 1 })))
      }
    }
  },
  [CAMPAIGN_ENABLED_FILTERS] (_state: QuickFilterSettings, getters): ScoringFunctionNode[] {
    const filters = getters[CAMPAIGN_FILTERS]
    const isFilterEnabled = getters[CAMPAIGN_IS_FILTER_ENABLED]

    return filters.filter(isFilterEnabled)
  },
  [CAMPAIGN_FILTERING_ENABLED] (_state: QuickFilterSettings, getters): boolean {
    if (getters[CAMPAIGN_FLIGHTS_DISABLED]) {
      return true
    }

    const filters = getters[CAMPAIGN_FILTERS]
    const isFilterEnabled = getters[CAMPAIGN_IS_FILTER_ENABLED]

    return !filters.every(isFilterEnabled)
  }
}

const actions = {
  [RESET_QUICK_FILTERS] ({ commit }) {
    commit(SET_CAMPAIGN_GOALS_DISABLED, false)
    commit(SET_CAMPAIGN_FLIGHTS_DISABLED, false)
    commit(SET_CAMPAIGN_DISABLED_FILTER_IDS, [])
    commit(SET_CAMPAIGN_CUSTOM_TIME_FRAME, null)
  },
  [UPDATE_CUSTOM_TIME_FRAME] ({ getters, commit }, timeFrame: Date[] | null) {
    const start = get(timeFrame, '0', getters[CAMPAIGN_START_DATE])
    const end = get(timeFrame, '1', getters[CAMPAIGN_END_DATE])

    commit(SET_CAMPAIGN_CUSTOM_TIME_FRAME, { start, end })
  }
}

const mutations = {
  [SET_CAMPAIGN_GOALS_DISABLED] (state: QuickFilterSettings, disabled: boolean) {
    state.disableGoals = disabled
  },
  [SET_CAMPAIGN_FLIGHTS_DISABLED] (state: QuickFilterSettings, disabled: boolean) {
    state.disableFlights = disabled
  },
  [SET_CAMPAIGN_CUSTOM_TIME_FRAME] (state: QuickFilterSettings, timeFrame: Date[] | null) {
    state.customTimeFrame = timeFrame
  },
  [SET_CAMPAIGN_DISABLED_FILTER_IDS] (state: QuickFilterSettings, filterIds: string[]) {
    state.disabledFilterIds = [...new Set(filterIds)]
  },
  [DISABLE_CAMPAIGN_FILTER_IDS] (state: QuickFilterSettings, filterIds: string[]) {
    state.disabledFilterIds = [...new Set([...state.disabledFilterIds, ...filterIds])]
  },
  [ENABLE_CAMPAIGN_FILTER_IDS] (state: QuickFilterSettings, filterIds: string[]) {
    const disabledIds = new Set(state.disabledFilterIds)

    for (const filterId of filterIds) {
      disabledIds.delete(filterId)
    }

    state.disabledFilterIds = [...disabledIds]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
