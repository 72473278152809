<script>
import { FeatureGroup } from 'leaflet'
import { findRealParent } from 'vue2-leaflet'

export default {
  name: 'LayerContextMenu',
  props: {
    active: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    inheritItems: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.parentContainer = findRealParent(this.$parent)
    this.initLayerContextMenu(this.parentContainer.mapObject)
  },
  methods: {
    initLayerContextMenu (layer) {
      if (layer instanceof FeatureGroup) {
        layer.on('layeradd', e => this.initLayerContextMenu(e.layer))
        layer.eachLayer(this.initLayerContextMenu)

        return
      }

      if (!layer.bindContextMenu) {
        return
      }

      const menuOptions = {
        contextmenu: this.active,
        contextmenuItems: this.items,
        contextmenuInheritItems: this.inheritItems
      }

      layer.bindContextMenu(menuOptions)
    }
  },
  render (h) {
    return h()
  }
}
</script>
