import { LocaleMessageObject } from 'vue-i18n'

export function getAllTranslationPaths (translationObj: LocaleMessageObject, langId: string): string[] {
  const paths: string[] = []

  function findObjPathRecursive (obj, prop, path = '') {
    const keys = Object.keys(obj)

    keys.forEach(key => {
      const newPath = path ? path + '.' + key : key

      if (key === prop) {
        paths.push(newPath)
      } else if (obj[key] && typeof obj[key] === 'object') {
        findObjPathRecursive(obj[key], prop, newPath)
      }
    })
  }

  findObjPathRecursive(translationObj, langId)

  return paths
}
