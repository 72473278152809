import { PlanningStatus } from '@/types/planning/enums'
import campaignGeometriesCache from '@/store/campaign/campaign-geometries-cache/campaign-geometries-cache.store'
import campaignQuickFilters from '@/store/campaign/campaign-quick-filters/quick-filters.store'
import campaignSiteManipulation from '@/store/campaign/campaign-site-manimulation/campaign-site-manipulation.store'
import campaignExcludedAssignments from '@/store/campaign/excluded-assignments/excluded-assignments.store'
import campaignTableViews from '@/store/campaign/campaign-table-views/campaign-table-views.store'
import {
  CAMPAIGN_CREATED_BY,
  CAMPAIGN_CUSTOMER,
  CAMPAIGN_DISCOUNT,
  CAMPAIGN_ENABLED_FILTERS,
  CAMPAIGN_END_DATE,
  CAMPAIGN_EXCLUDED_AREA_TAGS,
  CAMPAIGN_FILTERS,
  CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG,
  CAMPAIGN_GET_FILTER_BY_KEY,
  CAMPAIGN_GET_FILTER_CHILDREN,
  CAMPAIGN_GET_FILTER_KEY,
  CAMPAIGN_GET_PARENT_FILTER,
  CAMPAIGN_HAS_LINKED_OFFER,
  CAMPAIGN_ID,
  CAMPAIGN_NAME,
  CAMPAIGN_NAME_IS_UNIQUE,
  CAMPAIGN_REQUEST_DATA,
  CAMPAIGN_SCORING_REQUEST_DATA,
  CAMPAIGN_SELECTED_COMMON_SETTINGS,
  CAMPAIGN_SELECTED_LOCATIONS,
  CAMPAIGN_START_DATE,
  CAMPAIGN_STATUS,
  CAMPAIGN_TIME_FRAME,
  PLAN_WIZARD_CAMPAIGN_CUSTOMER,
  PLAN_WIZARD_CAMPAIGN_DISCOUNT,
  PLAN_WIZARD_FORCE_CONTINUOUS_DISPLAY_FLAG,
  PLAN_WIZARD_CAMPAIGN_ID,
  PLAN_WIZARD_CAMPAIGN_NAME,
  PLAN_WIZARD_CAMPAIGN_STATUS_INVALIDATED,
  PLAN_WIZARD_END_DATE,
  PLAN_WIZARD_FILTERS,
  PLAN_WIZARD_IS_NEW_CAMPAIGN,
  PLAN_WIZARD_START_DATE,
  PLANS,
  CAMPAIGN_FILTERED_TABLE_VIEWS,
  CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG,
  PLAN_WIZARD_CONSIDER_STATUS_UNKNOWN_FLAG
} from '@/store/getter-types'
import {
  EXPORT_TO_OPS_BOOKED_NETWORK_TIMEFRAMES,
  INIT_WIZARD_FROM_CAMPAIGN,
  LOAD_CAMPAIGN_BY_ID,
  LOAD_CITY_DATASET,
  LOAD_DECADES,
  LOAD_PLAN_BY_ID,
  LOAD_SITE_TYPES,
  PLAN_WIZARD_INIT,
  RELOAD_CAMPAIGN_DEPENDENCIES,
  RELOAD_CAMPAIGN_TIMEFRAME,
  REMOVE_PLAN_BY_ID,
  RESET_CAMPAIGN,
  RESET_QUICK_FILTERS,
  RESET_SCORES_DATA,
  SAVE_CAMPAIGN, SAVE_CAMPAIGN_AS,
  SET_CAMPAIGN_AFTER_LOAD,
  SET_CAMPAIGN_FROM_WIZARD,
  VALIDATE_CAMPAIGN
} from '@/store/action-types'
import {
  SET_CAMPAIGN_CACHE,
  SET_CAMPAIGN_CREATED_BY,
  SET_CAMPAIGN_CUSTOMER,
  SET_CAMPAIGN_DISCOUNT,
  SET_CAMPAIGN_END_DATE,
  SET_CAMPAIGN_FILTERS,
  SET_CAMPAIGN_HAS_LINKED_OFFER,
  SET_CAMPAIGN_ID,
  SET_CAMPAIGN_NAME,
  SET_CAMPAIGN_START_DATE,
  SET_CAMPAIGN_STATUS,
  SET_EXCLUDED_AREA_ASSIGNMENTS,
  ADD_CAMPAIGN_FILTER,
  REMOVE_CAMPAIGN_FILTER,
  UPDATE_CAMPAIGN_FILTER,
  SET_CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG,
  SET_CAMPAIGN_TABLE_VIEWS,
  SET_CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG
} from '@/store/mutation-types'
import { PlanObject } from '@/types/planning'
import { planningApi } from '@/api/rest/planning/planning.api'
import { isCommonSettingsFilterNode, isLocationFilterNode } from '@/utils/plan-wizard-steps'
import { getNodeChildren, getNodeKeyAsString } from '@/utils/scoring/nodes'
import { CampaignRequestBody, CampaignTimeFrameGetter } from '@/types/store/campaign'
import { cloneDeep, get, isEmpty, isNull, omit } from 'lodash'
import moment from '@/lib/moment'
import { scoringLocationsApi } from '@/api/rest/scoring/scoring-locations.api'
import { saveBlob } from '@/utils/save'
import { BlobFileResponseWithName } from '@/types/api/data-delivery'
import { OrderSystem } from '@/types/enums'

// initial state
const state = {
  id: null,
  name: '',
  customer: null,
  orderSystem: OrderSystem.NONE,
  discount: 0,
  forceContinuousDisplay: false,
  considerUnknownAvailability: false,
  status: PlanningStatus.Draft,
  startDate: null,
  endDate: null,
  filters: [],
  hasLinkedOffer: false,
  createdBy: null
}

// getters
const getters = {
  [CAMPAIGN_ID] (state) {
    return state.id
  },
  [CAMPAIGN_FILTERS] (state) {
    return state.filters
  },
  [CAMPAIGN_SELECTED_LOCATIONS] (state) {
    return state.filters.filter(isLocationFilterNode)
  },
  [CAMPAIGN_SELECTED_COMMON_SETTINGS] (state) {
    return state.filters.filter(isCommonSettingsFilterNode)
  },
  [CAMPAIGN_GET_FILTER_KEY] () {
    return (filter) => getNodeKeyAsString(filter)
  },
  [CAMPAIGN_GET_FILTER_CHILDREN] (_state, getters) {
    return (parent) => getNodeChildren(parent, getters[CAMPAIGN_FILTERS])
  },
  [CAMPAIGN_GET_PARENT_FILTER] (_state, getters) {
    return (filter) => {
      const filterKey = getNodeKeyAsString(filter)
      const idx = filterKey.lastIndexOf('|')

      if (idx === -1) {
        return null
      }

      const parentKey = filterKey.slice(0, idx)

      return getters[CAMPAIGN_GET_FILTER_BY_KEY](parentKey)
    }
  },
  [CAMPAIGN_GET_FILTER_BY_KEY] (_state, getters) {
    return (key) => getters[CAMPAIGN_FILTERS].find(f => key === getters[CAMPAIGN_GET_FILTER_KEY](f)) || null
  },
  [CAMPAIGN_NAME] (state) {
    return state.name
  },
  [CAMPAIGN_CUSTOMER] (state) {
    return state.customer
  },
  [CAMPAIGN_DISCOUNT] (state) {
    return state.discount
  },
  [CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG] (state) {
    return state.forceContinuousDisplay
  },
  [CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG] (state) {
    return state.considerUnknownAvailability
  },

  [CAMPAIGN_START_DATE] (state) {
    return state.startDate
  },
  [CAMPAIGN_END_DATE] (state) {
    return state.endDate
  },
  [CAMPAIGN_TIME_FRAME] (_state, getters): CampaignTimeFrameGetter {
    const start = getters[CAMPAIGN_START_DATE]
    const end = getters[CAMPAIGN_END_DATE]

    if (start && end) {
      return { start, end }
    }

    return null
  },
  [CAMPAIGN_NAME_IS_UNIQUE] (_state, getters) {
    return (name) => !getters[PLANS].some(plan => plan.name === name)
  },
  [CAMPAIGN_REQUEST_DATA] (_state, getters): CampaignRequestBody {
    const request: CampaignRequestBody = {
      name: getters[CAMPAIGN_NAME],
      status: getters[CAMPAIGN_STATUS],
      nodes: getters[CAMPAIGN_FILTERS],
      tags: [],
      metaData: {
        planningTimeFrames: [],
        customer: getters[CAMPAIGN_CUSTOMER],
        discount: getters[CAMPAIGN_DISCOUNT],
        excludedByArea: getters[CAMPAIGN_EXCLUDED_AREA_TAGS],
        forceContinuousDisplay: getters[CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG],
        considerUnknownAvailability: getters[CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG],
        tableViews: getters[CAMPAIGN_FILTERED_TABLE_VIEWS]
      },
      createdBy: getters[CAMPAIGN_CREATED_BY]
    }
    if (getters[CAMPAIGN_ID]) {
      request.id = getters[CAMPAIGN_ID]
    }

    if (getters[CAMPAIGN_TIME_FRAME]) {
      request.metaData.planningTimeFrames.push(getters[CAMPAIGN_TIME_FRAME])
    }

    return request
  },
  [CAMPAIGN_SCORING_REQUEST_DATA] (_state, getters): CampaignRequestBody {
    return {
      ...getters[CAMPAIGN_REQUEST_DATA],
      nodes: getters[CAMPAIGN_ENABLED_FILTERS]
    }
  },
  [CAMPAIGN_STATUS] (state) {
    if (state.hasLinkedOffer) {
      return PlanningStatus.InBuying
    }
    return state.status
  },
  [CAMPAIGN_CREATED_BY] (state) {
    return state.createdBy
  },
  [CAMPAIGN_HAS_LINKED_OFFER] (state) {
    return state.hasLinkedOffer
  }
}
// actions
const actions = {
  [EXPORT_TO_OPS_BOOKED_NETWORK_TIMEFRAMES] ({ getters }): Promise<void | BlobFileResponseWithName> {
    const data = getters[CAMPAIGN_SCORING_REQUEST_DATA]
    return scoringLocationsApi.getExcelFileFromBookedNetworkTimeFrames(data)
      .then(({ data, fileName }) => {
        saveBlob(new Blob([data]), `${fileName}.xlsx`)
      })
  },

  [LOAD_PLAN_BY_ID] (_, id): Promise<PlanObject> {
    return planningApi.getPlan(id, true)
  },
  [REMOVE_PLAN_BY_ID] ({ getters, dispatch }, id) {
    return planningApi.removePlan(id)
      .then(() => {
        // Reset campaign if the actual one was removed
        if (getters[CAMPAIGN_ID] === id) {
          dispatch(RESET_CAMPAIGN)
        }
      })
  },
  [LOAD_CAMPAIGN_BY_ID] ({ dispatch }, id) {
    dispatch(RESET_SCORES_DATA)

    return dispatch(LOAD_PLAN_BY_ID, id).then((plan) => {
      dispatch(SET_CAMPAIGN_AFTER_LOAD, plan)

      return dispatch(RELOAD_CAMPAIGN_DEPENDENCIES, plan)
    })
  },
  async [SAVE_CAMPAIGN] ({ getters, dispatch, rootGetters }): Promise<void> {
    if (getters[CAMPAIGN_ID]) {
      await planningApi.updatePlan(getters[CAMPAIGN_REQUEST_DATA])
      return
    }

    dispatch(RESET_SCORES_DATA)

    // Set campaign data to correct work with wizard
    // We should have campaign id to update campaign second time instead of save duplicate of it
    // We also should update wizard date after save NEW campaign
    const createdCampaign = await planningApi.storePlan(getters[CAMPAIGN_REQUEST_DATA])
    dispatch(SET_CAMPAIGN_AFTER_LOAD, createdCampaign)

    if (rootGetters[PLAN_WIZARD_IS_NEW_CAMPAIGN]) {
      dispatch(INIT_WIZARD_FROM_CAMPAIGN)
    }
  },
  async [RESET_CAMPAIGN] ({ commit, dispatch }) {
    await dispatch(RESET_SCORES_DATA)
    await dispatch(RESET_QUICK_FILTERS)
    commit(SET_CAMPAIGN_ID, null)
    commit(SET_CAMPAIGN_NAME, '')
    commit(SET_CAMPAIGN_CUSTOMER, {})
    commit(SET_CAMPAIGN_DISCOUNT, 0)
    commit(SET_CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG, false)
    commit(SET_CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG, true)
    commit(SET_CAMPAIGN_STATUS, PlanningStatus.Draft)
    commit(SET_CAMPAIGN_FILTERS, [])
    commit(SET_CAMPAIGN_CACHE, {})
    commit(SET_CAMPAIGN_START_DATE, null)
    commit(SET_CAMPAIGN_END_DATE, null)
    commit(SET_CAMPAIGN_HAS_LINKED_OFFER, false)
    commit(SET_CAMPAIGN_CREATED_BY, null)
    commit(SET_EXCLUDED_AREA_ASSIGNMENTS, [])
    commit(SET_CAMPAIGN_TABLE_VIEWS, [])
  },
  async [RELOAD_CAMPAIGN_DEPENDENCIES] ({ dispatch }, plan) {
    await dispatch(RELOAD_CAMPAIGN_TIMEFRAME, plan)
    await dispatch(LOAD_CITY_DATASET)
    return dispatch(LOAD_SITE_TYPES)
  },
  [RELOAD_CAMPAIGN_TIMEFRAME] ({ commit, dispatch }, plan) {
    const startDate = get(plan, 'metaData.planningTimeFrames.0.start', null)
    const endDate = get(plan, 'metaData.planningTimeFrames.0.end', null)

    const requestObj = {
      startDate: moment(startDate || new Date()).subtract({ months: 1 }).startOf('month'),
      endDate: moment(endDate || new Date()).add({ months: 1 }).endOf('month')
    }

    return dispatch(LOAD_DECADES, requestObj).then(() => {
      commit(SET_CAMPAIGN_START_DATE, startDate ? new Date(startDate) : null)
      commit(SET_CAMPAIGN_END_DATE, endDate ? new Date(endDate) : null)
    })
  },
  [INIT_WIZARD_FROM_CAMPAIGN] ({ dispatch, getters }) {
    return dispatch(PLAN_WIZARD_INIT, {
      id: getters[CAMPAIGN_ID],
      name: getters[CAMPAIGN_NAME],
      customer: getters[CAMPAIGN_CUSTOMER],
      discount: getters[CAMPAIGN_DISCOUNT] || 0,
      forceContinuousDisplay: getters[CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG],
      considerUnknownAvailability: getters[CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG],
      status: getters[CAMPAIGN_STATUS],
      startDate: getters[CAMPAIGN_START_DATE],
      endDate: getters[CAMPAIGN_END_DATE],
      filters: cloneDeep(getters[CAMPAIGN_FILTERS])
    })
  },
  async [SET_CAMPAIGN_FROM_WIZARD] ({ commit, dispatch, rootGetters }) {
    commit(SET_CAMPAIGN_ID, rootGetters[PLAN_WIZARD_CAMPAIGN_ID])
    commit(SET_CAMPAIGN_NAME, rootGetters[PLAN_WIZARD_CAMPAIGN_NAME])
    commit(SET_CAMPAIGN_CUSTOMER, rootGetters[PLAN_WIZARD_CAMPAIGN_CUSTOMER])
    commit(SET_CAMPAIGN_DISCOUNT, rootGetters[PLAN_WIZARD_CAMPAIGN_DISCOUNT])
    commit(SET_CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG, rootGetters[PLAN_WIZARD_FORCE_CONTINUOUS_DISPLAY_FLAG])
    commit(SET_CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG, rootGetters[PLAN_WIZARD_CONSIDER_STATUS_UNKNOWN_FLAG])
    commit(SET_CAMPAIGN_STATUS, rootGetters[PLAN_WIZARD_CAMPAIGN_STATUS_INVALIDATED])
    commit(SET_CAMPAIGN_START_DATE, rootGetters[PLAN_WIZARD_START_DATE])
    commit(SET_CAMPAIGN_END_DATE, rootGetters[PLAN_WIZARD_END_DATE])
    commit(SET_CAMPAIGN_FILTERS, cloneDeep(rootGetters[PLAN_WIZARD_FILTERS]))
    commit(SET_CAMPAIGN_CACHE, {})
    await dispatch(RESET_QUICK_FILTERS)
  },
  async [SAVE_CAMPAIGN_AS] ({ getters, dispatch }, campaignName) {
    const requestData = omit(getters[CAMPAIGN_REQUEST_DATA], ['id', 'orderId']) as CampaignRequestBody

    requestData.name = campaignName
    // Reset campaign status to "Completed" if there is a linked offer
    if (getters[CAMPAIGN_HAS_LINKED_OFFER]) {
      requestData.status = PlanningStatus.Completed
    }

    const copiedPlan = await planningApi.storePlan(requestData)
    await dispatch(RESET_SCORES_DATA)
    await dispatch(SET_CAMPAIGN_AFTER_LOAD, copiedPlan)

    return copiedPlan
  },
  // eslint-disable-next-line no-empty-pattern
  [VALIDATE_CAMPAIGN] ({ }, campaignName) {
    const errors: string[] = []

    if (isEmpty(campaignName)) {
      errors.push('planning.wizard.errors.set-up-campaign')
    }

    return new Promise((resolve, reject) => {
      return errors.length ? reject(errors) : resolve([])
    })
  },
  async [SET_CAMPAIGN_AFTER_LOAD] ({ commit, dispatch }, plan: PlanObject) {
    commit(SET_CAMPAIGN_ID, plan.id)
    commit(SET_CAMPAIGN_NAME, plan.name)
    commit(SET_CAMPAIGN_STATUS, plan.status)
    commit(SET_CAMPAIGN_FILTERS, plan.nodes)
    commit(SET_CAMPAIGN_CUSTOMER, plan.metaData.customer)
    commit(SET_CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG, plan.metaData.forceContinuousDisplay)
    commit(SET_CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG, plan.metaData.considerUnknownAvailability)
    commit(SET_CAMPAIGN_DISCOUNT, plan.metaData.discount)
    commit(SET_EXCLUDED_AREA_ASSIGNMENTS, plan.metaData.excludedByArea)
    commit(SET_CAMPAIGN_TABLE_VIEWS, plan.metaData.tableViews)
    commit(SET_CAMPAIGN_HAS_LINKED_OFFER, !isNull(plan.orderId))
    commit(SET_CAMPAIGN_CREATED_BY, plan.createdBy)
    await dispatch(RESET_QUICK_FILTERS)
  }
}

const mutations = {
  [SET_CAMPAIGN_FILTERS] (state, filter) {
    state.filters = filter
  },
  [SET_CAMPAIGN_ID] (state, id) {
    state.id = id
  },
  [SET_CAMPAIGN_NAME] (state, name) {
    state.name = name
  },
  [SET_CAMPAIGN_CUSTOMER] (state, customer) {
    state.customer = customer
  },
  [SET_CAMPAIGN_DISCOUNT] (state, discount) {
    state.discount = discount
  },
  [SET_CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG] (state, flag) {
    state.forceContinuousDisplay = flag
  },
  [SET_CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG] (state, flag) {
    state.considerUnknownAvailability = flag
  },
  [SET_CAMPAIGN_START_DATE] (state, date) {
    state.startDate = date
  },
  [SET_CAMPAIGN_END_DATE] (state, date) {
    state.endDate = date
  },
  [SET_CAMPAIGN_STATUS] (state, status) {
    state.status = status
  },
  [SET_CAMPAIGN_HAS_LINKED_OFFER] (state, flag) {
    state.hasLinkedOffer = flag
  },
  [SET_CAMPAIGN_CREATED_BY] (state, createdBy) {
    state.createdBy = createdBy
  },
  [ADD_CAMPAIGN_FILTER] (state, filter) {
    state.filters.push(filter)
  },
  [REMOVE_CAMPAIGN_FILTER] (state, filter) {
    state.filters = state.filters.filter(item => item.id !== filter.id)
  },
  [UPDATE_CAMPAIGN_FILTER] (state, filter) {
    const filterIndex = state.filters.findIndex(item => filter.id === item.id)

    if (filterIndex >= 0) {
      state.filters.splice(filterIndex, 1, filter)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    campaignExcludedAssignments,
    campaignGeometriesCache,
    campaignQuickFilters,
    campaignSiteManipulation,
    campaignTableViews
  }
}
