import QuantilePathMixin from '@/components/visualization/map/mixins/quantile-path.mixin'
import { QuantileLegendMixin } from './quantile-legend-mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class QuantilePathLegendMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  Mixins<QuantilePathMixin<_TLayer>, QuantileLegendMixin<_TLayer>>(QuantilePathMixin, QuantileLegendMixin) {
  protected get quantilePathLegendValues () {
    const quantileColorGetter = (index) => ({
      color: this.calculateQuantileColorByIndex(index, this.quantilePathColors).color,
      opacity: this.calculateQuantileColorByIndex(index, this.quantilePathColors).opacity
    })

    return this.makeQuantileLegendValues(
      this.quantilePathValues,
      quantileColorGetter,
      this.maxQuantilePathValue(this.layer.style.path.gradientColumn)
    )
  }
}
