export enum Routes {
  HOME_CONTAINER = 'home container',
  HOME = 'home',
  PLANNING_CONTAINER = 'planning container',
  PLANNING_DASHBOARD = 'planning',
  CAMPAIGN_CONTAINER = 'campaign details container',
  CAMPAIGN_DETAILS = 'campaign details',
  CAMPAIGN_EXPORT = 'campaign export',
  LOGIN = 'login',
  VISUALIZATION_CONTAINER = 'visualization container',
  VISUALIZATION_DASHBOARD = 'visualization dashboard',
  VISUALIZATION_DETAILS = 'visualization details',
  ORDERS_CONTAINER = 'orders container',
  ORDERS_DASHBOARD = 'orders dashboard',
  ORDER_DETAILS = 'order details',
  ANALYTICS_CONTAINER = 'analytics container',
  ANALYTICS_DASHBOARD = 'analytics dashboard',
  ANALYTICS_DETAILS = 'analytics details',
  SHARED_VISUALIZATION = 'shared visualization',
  ERROR404 = 'error-404',
}
