import { parseDatasetRows } from '@/utils/parse-dataset-rows'
import { AxiosResponse } from 'axios'
import { VisualizationObject } from '@/types/visualization'
import { createTransformResponse } from '@/api/utils'

export const transformSingleDatasetResponse = createTransformResponse<VisualizationObject>((response: AxiosResponse['data']): VisualizationObject => {
  const parsedResponse = JSON.parse(response)

  parsedResponse.layers.forEach(layer => parseDatasetRows(layer.data[0]))

  return parsedResponse
})
