import { mergeWith } from 'lodash'
import {
  Bold,
  Focus,
  History,
  Image,
  Italic,
  TableCell,
  TableHeader,
  TableRow,
  TrailingNode,
  Underline
} from 'tiptap-extensions'
import { Table } from '@/components/shared/tiptap/Table'

function editorConfigMergeCustomizer (objValue, srcValue, key, _object, _source, stack): unknown[] | void {
  if (stack.size === 0 && key === 'extensions') {
    // Just add custom extensions to the list, nothing to merge deeper.
    return [...objValue, ...srcValue]
  }
}

export function defaultEditorExtensions () {
  return [
    // Font styles.
    new Bold(),
    new Italic(),
    new Underline(),

    // Custom blocks.
    // new Image(),

    // Useful extensions.
    new History()
  ]
}

export function editorImageExtensions () {
  return [
    // Custom blocks.
    new Image()
  ]
}

export function editorTableExtensions () {
  return [
    // Tables support.
    new TableCell(),
    new TableRow(),
    new TableHeader(),
    new Table({
      resizable: true
    })
  ]
}

export function editorEditExtensions () {
  return [
    new TrailingNode({
      node: 'paragraph',
      notAfter: ['paragraph']
    }),
    new Focus({
      className: 'has-focus',
      nested: true
    })
  ]
}

export function mergeEditorConfig (defaultConfig, ...configs) {
  return mergeWith(defaultConfig, ...configs, editorConfigMergeCustomizer)
}
