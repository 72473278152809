// Campaign module
export const CAMPAIGN_ID = 'campaign/getId'
export const CAMPAIGN_FILTERS = 'campaign/getFilters'
export const CAMPAIGN_GOALS_DISABLED = 'campaign/goalsDisabled'
export const CAMPAIGN_FLIGHTS_DISABLED = 'campaign/flightsDisabled'
export const CAMPAIGN_DISABLED_FILTER_IDS = 'campaign/disabledFilterIds'
export const CAMPAIGN_CUSTOM_TIME_FRAME = 'campaign/customTimeFrame'
export const CAMPAIGN_FILTERED_AVAILABILITY_FILTER = 'campaign/filteredAvailabilityFilter'
export const CAMPAIGN_SELECTED_LOCATIONS = 'campaign/locations'
export const CAMPAIGN_SELECTED_COMMON_SETTINGS = 'campaign/commonSettings'
export const CAMPAIGN_GET_FILTER_KEY = 'campaign/getFilterKey'
export const CAMPAIGN_GET_FILTER_CHILDREN = 'campaign/getFilterChildren'
export const CAMPAIGN_GET_PARENT_FILTER = 'campaign/getFilterParent'
export const CAMPAIGN_GET_FILTER_BY_KEY = 'campaign/getFilterByKey'
export const CAMPAIGN_NAME = 'campaign/getName'
export const CAMPAIGN_CUSTOMER = 'campaign/getCustomer'
export const CAMPAIGN_DISCOUNT = 'campaign/getDiscount'
export const CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG = 'campaign/forceContinuousDisplayFlag'
export const CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG = 'campaign/considerStatusUnknownFlag'
export const CAMPAIGN_START_DATE = 'campaign/getStartDate'
export const CAMPAIGN_END_DATE = 'campaign/getEndDate'
export const CAMPAIGN_TIME_FRAME = 'campaign/getTimeFrame'
export const CAMPAIGN_NAME_IS_UNIQUE = 'campaign/nameNotUnique'
export const CAMPAIGN_REQUEST_DATA = 'campaign/requestData'
export const CAMPAIGN_SCORING_REQUEST_DATA = 'campaign/scoringRequestData'
export const CAMPAIGN_CACHE = 'campaign/getCache'
export const CAMPAIGN_IS_FILTER_ENABLED = 'campaign/isFilterEnabled'
export const CAMPAIGN_ENABLED_FILTERS = 'campaign/enabledFilters'
export const CAMPAIGN_FILTERING_ENABLED = 'campaign/isFilteringEnabled'
export const CAMPAIGN_STATUS = 'campaign/status'
export const CAMPAIGN_GET_EXCLUDED_SITES = 'campaign/getExcludedSites'
export const CAMPAIGN_HAS_LINKED_OFFER = 'campaign/campaignHasLinkedOffer'
export const CAMPAIGN_CREATED_BY = 'campaign/campaignCreatedBy'
export const CAMPAIGN_EXCLUDED_AREA_TAGS = 'campaign/excludedAreaTags'
export const CAMPAIGN_EXCLUDED_AREA_TAGS_SHOULD_CLEAR = 'campaign/excludedAreaTagShouldClear'
export const CAMPAIGN_TABLE_VIEWS = 'campaign/tableViews'
export const CAMPAIGN_FILTERED_TABLE_VIEWS = 'campaign/filteredTableViews'

// Collections module
export const BANNED_PRODUCTS = 'collections/bannedProducts/collection'
export const BANNED_PRODUCTS_INITIALIZED = 'collections/bannedProducts/initialized'
export const CITY_DATASET = 'collections/cityNames/dataset'
export const CITY_DATASET_INITIALIZED = 'collections/cityNames/initialized'
export const MEDIA_OWNER_NAMES = 'collections/mediaOwnerNames/collection'
export const MEDIA_OWNER_NAMES_INITIALIZED = 'collections/mediaOwnerNames/initialized'
export const SITE_TYPES = 'collections/siteTypes/collection'
export const SITE_TYPES_GROUPED_BY_PARENT = 'collections/siteTypes/groupedByParentGetter'
export const SITE_TYPES_INITIALIZED = 'collections/siteTypes/initialized'
export const MEDIA_FILTER = 'collections/mediaFilter/collection'
export const MEDIA_FILTER_INITIALIZED = 'collections/mediaFilter/initialized'

// Collections module (Combined goals)
export const GOAL_TYPES = 'collections/planningGoalTypes/collection'
export const GOAL_TYPES_INITIALIZED = 'collections/planningGoalTypes/initialized'

// Decades module
export const DECADES = 'decades/getDecades'

// Customers module
export const CUSTOMERS = 'customers/getCustomers'
export const CUSTOMERS_BY_ORDER_SYSTEM = 'customers/getCustomersByOrderSystem'
export const CUSTOMERS_SALES_OFFICES = 'customers/getCustomersSalesOffices'
export const CUSTOMERS_BY_ORDER_SYSTEM_SALES_OFFICE = 'customers/getCustomersBySalesOfficeOrderSystem'
export const CUSTOMERS_INITIALIZED = 'customers/isInitialized'
export const IW_ORDER_SYSTEMS = 'customers/legacyOrderSystems'
export const CUSTOMER_BY_VALUE = 'customers/getCustomersByValue'

// Plans module
export const PLANS = 'plans/getPlans'

// Scoring module
export const SCORING_LOCATIONS = 'scoring/locations'
export const SCORING_LAYERS = 'scoring/layers'
export const SCORING_HAS_LOCATIONS = 'scoring/hasLocations'
export const SCORING_BOUNDS = 'scoring/getBounds'
export const SCORING_SITE_DETAILS = 'scoring/siteDetails/getDetails'
export const SCORING_DETAILS = 'scoring/scoringDetails'
export const SCORING_UNITS_TO_RESOLVE = 'scoring/scoresToResolve'
export const SCORING_STATISTICS = 'scoring/statistics/scoringStatistic'
export const SCORING_STATISTICS_GET_PARENT_FILTERS = 'scoring/statistics/getParentFilters'
export const SCORING_STATISTICS_GET_GOAL_FILTER = 'scoring/statistics/getGoalFilter'
export const SCORING_STATISTICS_GET_PATH_STATISTICS = 'scoring/statistics/pathStatistics'
export const SCORING_STATISTICS_GET_OVERALL_STATISTIC = 'scoring/statistics/overallStatistic'
export const SCORING_CANCEL_CALLBACK = 'scoring/getCancelCallback'
export const SCORING_REPORTS = 'scoring/scoringReports'
export const SCORING_GET_LAYERS_BY_FILTER = 'scoring/getLayersByFilter'
export const SCORING_AVAILABILITY_DISABLED_FLAG_UPDATED = 'scoring/appliedScoring/availabilityFlagUpdated'
export const SCORING_TIME_FRAME_UPDATED_IN_WIZARD = 'scoring/appliedScoring/timeFrameWillBeUpdated'
export const SCORING_TIME_FRAME_UPDATED_IN_CAMPAIGN = 'scoring/appliedScoring/timeFrameWasUpdated'
export const SCORING_CONTINUOUS_DISPLAY_UPDATED_IN_WIZARD = 'scoring/appliedScoring/forceContinousDisplayWillBeUpdated'
export const SCORING_CONSIDER_STATUS_UNKNOWN_UPDATED_IN_WIZARD = 'scoring/appliedScoring/considerStatusUnknownWillBeUpdated'
export const SCORING_NODES_WILL_BE_UPDATED = 'scoring/appliedScoring/nodesWillBeUpdated'
export const SCORING_NODES_WERE_UPDATED = 'scoring/appliedScoring/nodesWereUpdated'
export const SCORING_IS_INVALIDATED = 'campaign/appliedScoring/scoringIsInvalidated'
export const SCORING_WILL_BE_UPDATED = 'campaign/appliedScoring/scoringGoingToUpdate'
export const SCORING_META_DATA_UPDATED = 'campaign/appliedScoring/scoringMetaDataUpdated'

// User module
export const USER_USERNAME = 'user/getUsername'
export const USER_FIRST_NAME = 'user/getFirstName'
export const USER_LAST_NAME = 'user/getLastName'
export const USER_ROLES = 'user/getRoles'

// Visualization module - current visualization
export const VISUALIZATION_ID = 'visualizations/getId'
export const VISUALIZATION_NAME = 'visualizations/getName'
export const VISUALIZATION_LAYERS = 'visualizations/getLayers'
export const VISUALIZATION_VISIBLE_MAP_LAYERS = 'visualizations/getVisibleMapLayers'
export const VISUALIZATION_BOUNDS = 'visualizations/getBounds'
export const VISUALIZATION_REQUEST_DATA = 'visualizations/getRequestData'
export const VISUALIZATION_SLIDES = 'visualizations/getSlides'
export const VISUALIZATION_IS_SET = 'visualizations/isSet'
export const VISUALIZATION_HAS_ERRORS = 'visualizations/current/hasErrors'
export const VISUALIZATION_GET_ERRORS = 'visualizations/current/getErrors'
export const VISUALIZATION_WAS_VALIDATED = 'visualizations/wasValidated'
export const VISUALIZATION_WAS_UPDATED = 'visualizations/wasUpdated'
export const VISUALIZATION_GET_SUBLAYER_OPTION = 'visualizations/getSublayerOption'
export const VISUALIZATION_IS_SUBLAYER_VISIBLE = 'visualizations/isSublayerVisible'
export const VISUALIZATION_LAYER_GET_ICONS = 'visualizations/layerGetIcons'
export const VISUALIZATION_LAYER_GET_REPORTS = 'visualizations/layerReports'
export const VISUALIZATION_CREATED_BY = 'visualizations/createdBy'

// Requests module
export const HAS_ACTIVE_REQUEST = 'requests/hasActiveRequests'
export const CANCEL_CALLBACKS = 'requests/cancelCallbacks'
export const CANCEL_CALLBACK_BY_QUERY = 'requests/cancelCallbackByQuery'

// Booking time frame module
export const BOOKING_TIME = 'bookingTime/getBookingTime'
export const BOOKING_TIME_VALUES = 'bookingTime/getBookingTimeValues'
export const BOOKING_TIME_WEEKS = 'bookingTime/getBookingTimeWeeks'

// Planning wizard module
export const PLAN_WIZARD_CAMPAIGN_ID = 'planningWizard/campaignId'
export const PLAN_WIZARD_IS_NEW_CAMPAIGN = 'planningWizard/isNewCampaign'
export const PLAN_WIZARD_CAMPAIGN_NAME = 'planningWizard/campaignName'
export const PLAN_WIZARD_CAMPAIGN_CUSTOMER = 'planningWizard/campaignCustomer'
export const PLAN_WIZARD_CAMPAIGN_DISCOUNT = 'planningWizard/campaignDiscount'
export const PLAN_WIZARD_FORCE_CONTINUOUS_DISPLAY_FLAG = 'planningWizard/forceContinuousDisplayFlag'
export const PLAN_WIZARD_CONSIDER_STATUS_UNKNOWN_FLAG = 'planningWizard/considerStatusUnknownFlag'
export const PLAN_WIZARD_START_DATE = 'planningWizard/startDate'
export const PLAN_WIZARD_END_DATE = 'planningWizard/endDate'
export const PLAN_WIZARD_FILTER_BY_ID = 'planningWizard/getFilterById'
export const PLAN_WIZARD_SELECTED_LOCATIONS = 'planningWizard/selectedLocations'
export const PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE = 'planningWizard/selectedFilterableLocations'
export const PLAN_WIZARD_SELECTED_TYPES = 'planningWizard/selectedMediaTypes'
export const PLAN_WIZARD_SELECTED_FLIGHTS = 'planningWizard/selectedFlights'
export const PLAN_WIZARD_SELECTED_FILTERS = 'planningWizard/selectedScoringFilters'
export const PLAN_WIZARD_SELECTED_RANKINGS = 'planningWizard/selectedRankingFunctions'
export const PLAN_WIZARD_SELECTED_GOALS = 'planningWizard/selectedScoringGoals'
export const PLAN_WIZARD_SELECTED_COMMON_SETTINGS = 'planningWizard/selectedCommonSettings'
export const PLAN_WIZARD_FILTERS = 'planningWizard/filters'
export const PLAN_WIZARD_ACTIVE_FILTER = 'planningWizard/activeFilter'
export const PLAN_WIZARD_GENERATE_FILTER_ID = 'planningWizard/generateFilterId'
export const PLAN_WIZARD_IS_FILTER_NAME_UNIQUE = 'planningWizard/isFilterNameUnique'
export const PLAN_WIZARD_WAS_UPDATED = 'planningWizard/wasUpdated'
export const PLAN_WIZARD_IS_VALID = 'planningWizard/isValid'
export const PLAN_WIZARD_GET_BOOKING_TIME_BY_MEDIA_TYPE = 'planningWizard/getBookingTimeByMediaType'
export const PLAN_WIZARD_NAMESPACE = 'planningWizard/wizardNameSpace'
export const PLAN_WIZARD_LINK_NAMESPACE = 'planningWizard/namespacedGetter'
export const PLAN_WIZARD_STEP_LEAVE_CALLBACK = 'planningWizard/stepLeaveCallback'
export const PLAN_WIZARD_MEDIA_NETWORKS_COLLECTION = 'planningWizard/mediaNetworksCollection'
export const PLAN_WIZARD_MEDIA_NETWORKS_NEEDS_UPDATE = 'planningWizard/mediaNetworksNeedUpdate'
export const PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA = 'planningWizard/mediaNetworksDatasetByArea'
export const PLAN_WIZARD_CAMPAIGN_STATUS = 'planningWizard/campaignStatus'
export const PLAN_WIZARD_CAMPAIGN_STATUS_INVALIDATED = 'planningWizard/campaignStatusInvalidated'

// Planning wizard steps - common
export const WIZARD_STEP_COMMON_NAME_VALID = 'planningWizard/steps/common/nameValid'
export const WIZARD_STEP_COMMON_PERIOD_IS_SET = 'planningWizard/steps/common/periodSet'
export const WIZARD_STEP_COMMON_PERIOD_IS_VALID = 'planningWizard/steps/common/periodValid'
export const WIZARD_STEP_COMMON_PERIOD_HAS_DECADES = 'planningWizard/steps/common/periodHasDecades'
export const WIZARD_STEP_COMMON_IS_EMPTY = 'planningWizard/steps/common/stepIsEmpty'

// Planning wizard steps - location
export const WIZARD_STEP_LOCATION_SELECTED_LOCATIONS = 'planningWizard/steps/location/selectedLocations'
export const WIZARD_STEP_LOCATION_HAS_LOCATIONS = 'planningWizard/steps/location/hasLocations'
export const WIZARD_STEP_LOCATION_FUNCTIONS = 'planningWizard/steps/location/locationScoringFunctions'
export const WIZARD_STEP_LOCATION_ASSIGNED_FILTERS = 'planningWizard/steps/location/assignedMediaType'
export const WIZARD_STEP_LOCATION_HAS_ASSIGNED_FILTERS = 'planningWizard/steps/location/hasAssignedMediaType'
export const WIZARD_STEP_LOCATION_ALL_LOCATIONS_VALID = 'planningWizard/steps/location/allLocationValid'
export const WIZARD_STEP_LOCATION_IS_UNIQUE_NAME = 'planningWizard/steps/location/isUniqueName'
export const WIZARD_STEP_LOCATION_ALL_NAMES_UNIQUE = 'planningWizard/steps/location/allNamesUnique'

// Planning wizard steps - types
export const WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES = 'planningWizard/steps/mediaType/getAreaTypes'
export const WIZARD_STEP_MEDIA_TYPE_GET_AREA_SINGLE_TYPES = 'planningWizard/steps/mediaType/getAreaSingleTypes'
export const WIZARD_STEP_MEDIA_TYPE_GET_AREA_NETWORK_TYPES = 'planningWizard/steps/mediaType/getAreaNetworkTypes'
export const WIZARD_STEP_MEDIA_TYPE_GET_MERGED_AREA_SINGLE_TYPE = 'planningWizard/steps/mediaType/getMergedAreaSingleType'
export const WIZARD_STEP_MEDIA_TYPE_GET_MERGED_AREA_NETWORK_TYPE = 'planningWizard/steps/mediaType/getMergedAreaNetworkType'
export const WIZARD_STEP_MEDIA_TYPE_LOCATIONS_HAVE_TYPES = 'planningWizard/steps/mediaType/doAllLocationsHaveTypes'
export const WIZARD_STEP_MEDIA_TYPE_IS_NETWORK_AVAILABLE = 'planningWizard/steps/mediaType/isNetworkSelectionAvailable'

// Planning wizard steps - flights
export const WIZARD_STEP_FLIGHTS_SELECTED_FLIGHTS = 'planningWizard/steps/flights/selectedFlights'
export const WIZARD_STEP_FLIGHTS_TYPE_PER_AREA = 'planningWizard/steps/flights/typesPerArea'
export const WIZARD_STEP_FLIGHTS_SINGLE_TYPES_PER_AREA = 'planningWizard/steps/flights/singleTypesPerArea'
export const WIZARD_STEP_FLIGHTS_NETWORK_TYPES_PER_AREA = 'planningWizard/steps/flights/networkTypesPerArea'
export const WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA = 'planningWizard/steps/flights/flightsPerArea'
export const WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA_TYPE = 'planningWizard/steps/flights/flightsPerAreaType'
export const WIZARD_STEP_FLIGHTS_GET_FLIGHTS_FOR_MEDIA_TYPES = 'planningWizard/steps/flights/getFlightsForMediaTypes'
export const WIZARD_STEP_FLIGHTS_ARE_MEDIA_TYPES_SYNCHRONIZED = 'planningWizard/steps/flights/areMediaTypesSynchronized'
export const WIZARD_STEP_FLIGHTS_MON_TYPES_CAN_BE_SYNCHRONIZED = 'planningWizard/steps/flights/canMONTypesBeSynchronized'
export const WIZARD_STEP_FLIGHTS_SINGLE_UNIT_TYPES_CAN_BE_SYNCHRONIZED = 'planningWizard/steps/flights/canSingleUnitTypesBeSynchronized'
export const WIZARD_STEP_FLIGHTS_MEDIA_TYPES_CAN_BE_SYNCHRONIZED = 'planningWizard/steps/flights/canMediaTypesBeSynchronized'
export const WIZARD_STEP_FLIGHTS_LOCATION_HAS_FLIGHTS = 'planningWizard/steps/flights/areaHasFlights'
export const WIZARD_STEP_FLIGHTS_ALL_AREAS_HAVE_FLIGHTS = 'planningWizard/steps/flights/doAllAreasHaveFlights'
export const WIZARD_STEP_FLIGHTS_AVAILABLE_BOOKING_TIME = 'planningWizard/steps/flights/availableDecades'

// Planning wizard steps - filters
export const WIZARD_STEP_FILTERS_SELECTED_FILTERS = 'planningWizard/steps/filters/selectedFilters'
export const WIZARD_STEP_FILTERS_FILTER_PER_LOCATION = 'planningWizard/steps/filters/filterPerArea'
export const WIZARD_STEP_FILTERS_ALL_FILTERS = 'planningWizard/steps/filters/availableFilters'
export const WIZARD_STEP_FILTERS_UNIQUE_SELECTED_FILTERS = 'planningWizard/steps/filters/uniqueSelectedFilters'
export const WIZARD_STEP_FILTERS_HAS_LINKED_LOCATIONS = 'planningWizard/steps/filters/hasLinkedLocations'
export const WIZARD_STEP_FILTERS_ALL_FILTERS_VALID = 'planningWizard/steps/filters/allFiltersValid'
export const WIZARD_STEP_FILTERS_ALL_NAMES_UNIQUE = 'planningWizard/steps/filters/allNamesUnique'

// Planning wizard steps - rankings
export const WIZARD_STEP_RANKINGS_SELECTED_RANKINGS = 'planningWizard/steps/rankings/selectedRankings'
export const WIZARD_STEP_RANKINGS_RANKING_PER_LOCATION = 'planningWizard/steps/rankings/rankingPerArea'
export const WIZARD_STEP_RANKINGS_ALL_RANKINGS = 'planningWizard/steps/rankings/availableRankings'
export const WIZARD_STEP_RANKINGS_UNIQUE_SELECTED_RANKINGS = 'planningWizard/rankings/filters/uniqueSelectedRankings'
export const WIZARD_STEP_RANKINGS_HAS_LINKED_LOCATIONS = 'planningWizard/steps/rankings/hasLinkedLocations'
export const WIZARD_STEP_RANKINGS_ALL_RANKINGS_VALID = 'planningWizard/steps/rankings/allRankingsValid'
export const WIZARD_STEP_RANKINGS_ALL_NAMES_UNIQUE = 'planningWizard/steps/rankings/allNamesUnique'

// Planning wizard steps - goals
export const WIZARD_STEP_GOALS_SELECTED_GOALS = 'planningWizard/steps/goals/selectedGoals'
export const WIZARD_STEP_GOALS_GOAL_PER_LOCATION = 'planningWizard/steps/goals/goalPerArea'
export const WIZARD_STEP_GOALS_ALL_GOALS = 'planningWizard/steps/goals/availableGoals'
export const WIZARD_STEP_GOALS_UNIQUE_SELECTED_GOALS = 'planningWizard/steps/goals/uniqueSelectedGoals'
export const WIZARD_STEP_GOALS_HAS_LINKED_LOCATIONS = 'planningWizard/steps/goals/hasLinkedLocations'
export const WIZARD_STEP_GOALS_ALL_GOALS_VALID = 'planningWizard/steps/goals/allGoalsValid'
export const WIZARD_STEP_GOALS_IS_UNIQUE_NAME = 'planningWizard/steps/goals/isUniqueName'
export const WIZARD_STEP_GOALS_ALL_NAMES_UNIQUE = 'planningWizard/steps/goals/allNamesUnique'

// Planning wizard steps - common goals
export const WIZARD_STEP_COMMON_SETTINGS_FUNCTIONS = 'planningWizard/steps/commonSettings/settingsFunctions'
export const WIZARD_STEP_COMMON_SETTINGS_SELECTED_SETTINGS = 'planningWizard/steps/commonSettings/selectedSettings'
export const WIZARD_STEP_COMMON_SETTINGS_ALL_SETTINGS_VALID = 'planningWizard/steps/commonSettings/allSettingsValid'
export const WIZARD_STEP_COMMON_SETTINGS_IS_UNIQUE_NAME = 'planningWizard/steps/commonSettings/isUniqueName'
export const WIZARD_STEP_COMMON_SETTINGS_ALL_NAMES_UNIQUE = 'planningWizard/steps/commonSettings/allNamesUnique'

// Planning wizard tabs - common
export const WIZARD_TAB_COMMON_IS_ENABLED = 'planningWizard/tabs/common/isEnabled'
export const WIZARD_TAB_COMMON_IS_EMPTY = 'planningWizard/tabs/common/isEmpty'
export const WIZARD_TAB_COMMON_IS_INVALID = 'planningWizard/tabs/common/isInvalid'
export const WIZARD_TAB_COMMON_ERRORS = 'planningWizard/tabs/common/errors'

// Planning wizard tabs - location
export const WIZARD_TAB_LOCATION_IS_ENABLED = 'planningWizard/tabs/location/isEnabled'
export const WIZARD_TAB_LOCATION_IS_EMPTY = 'planningWizard/tabs/location/isEmpty'
export const WIZARD_TAB_LOCATION_IS_INVALID = 'planningWizard/tabs/location/isInvalid'
export const WIZARD_TAB_LOCATION_ERRORS = 'planningWizard/tabs/location/errors'

// Planning wizard tabs - media-type
export const WIZARD_TAB_MEDIA_TYPE_IS_ENABLED = 'planningWizard/tabs/mediaType/isEnabled'
export const WIZARD_TAB_MEDIA_TYPE_IS_EMPTY = 'planningWizard/tabs/mediaType/isEmpty'
export const WIZARD_TAB_MEDIA_TYPE_IS_INVALID = 'planningWizard/tabs/mediaType/isInvalid'
export const WIZARD_TAB_MEDIA_TYPE_ERRORS = 'planningWizard/tabs/mediaType/errors'

// Planning wizard tabs - flights
export const WIZARD_TAB_FLIGHTS_IS_ENABLED = 'planningWizard/tabs/flights/isEnabled'
export const WIZARD_TAB_FLIGHTS_IS_EMPTY = 'planningWizard/tabs/flights/isEmpty'
export const WIZARD_TAB_FLIGHTS_IS_INVALID = 'planningWizard/tabs/flights/isInvalid'
export const WIZARD_TAB_FLIGHTS_ERRORS = 'planningWizard/tabs/flights/errors'

// Planning wizard tabs - scoring-filters
export const WIZARD_TAB_FILTERS_IS_ENABLED = 'planningWizard/tabs/filters/isEnabled'
export const WIZARD_TAB_FILTERS_IS_EMPTY = 'planningWizard/tabs/filters/isEmpty'
export const WIZARD_TAB_FILTERS_IS_INVALID = 'planningWizard/tabs/filters/isInvalid'
export const WIZARD_TAB_FILTERS_ERRORS = 'planningWizard/tabs/filters/errors'

// Planning wizard tabs - ranking functions
export const WIZARD_TAB_RANKINGS_IS_ENABLED = 'planningWizard/tabs/rankings/isEnabled'
export const WIZARD_TAB_RANKINGS_IS_EMPTY = 'planningWizard/tabs/rankings/isEmpty'
export const WIZARD_TAB_RANKINGS_IS_INVALID = 'planningWizard/tabs/rankings/isInvalid'
export const WIZARD_TAB_RANKINGS_ERRORS = 'planningWizard/tabs/rankings/errors'

// Planning wizard tabs - planning-goals
export const WIZARD_TAB_GOALS_IS_ENABLED = 'planningWizard/tabs/goals/isEnabled'
export const WIZARD_TAB_GOALS_IS_EMPTY = 'planningWizard/tabs/goals/isEmpty'
export const WIZARD_TAB_GOALS_IS_INVALID = 'planningWizard/tabs/goals/isInvalid'
export const WIZARD_TAB_GOALS_ERRORS = 'planningWizard/tabs/goals/errors'

// Planning wizard tabs - common settings
export const WIZARD_TAB_COMMON_SETTINGS_IS_ENABLED = 'planningWizard/tabs/commonSettings/isEnabled'
export const WIZARD_TAB_COMMON_SETTINGS_IS_EMPTY = 'planningWizard/tabs/commonSettings/isEmpty'
export const WIZARD_TAB_COMMON_SETTINGS_IS_INVALID = 'planningWizard/tabs/commonSettings/isInvalid'
export const WIZARD_TAB_COMMON_SETTINGS_ERRORS = 'planningWizard/tabs/commonSettings/errors'

// Vicinity import wizard
export const VICINITY_IMPORT_WIZARD_VISIBLE = 'vicinity-import/wizardIsVisible'
export const VICINITY_IMPORT_WIZARD_DATASET = 'vicinity-import/getDatasetRows'
export const VICINITY_IMPORT_FILTERED_DATASET = 'vicinity-import/getFilteredDatasetRows'
export const VICINITY_IMPORT_NAMESPACE = 'vicinity-import/storeNamespace'
export const VICINITY_IMPORT_LINK_NAMESPACE = 'vicinity-import/linkNamespace'
export const VICINITY_IMPORT_WIZARD_QUERY_DATA = 'vicinity-import/getQueryData'

// Vicinity import - dataset tab
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_ERRORS = 'vicinity-import/dataset-tab/errors'
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY = 'vicinity-import/dataset-tab/isEmpty'
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_ENABLED = 'vicinity-import/dataset-tab/isEnabled'
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_IS_INVALID = 'vicinity-import/dataset-tab/isInvalid'
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_CAN_SKIP = 'vicinity-import/dataset-tab/canSkip'
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_STEP_ID = 'vicinity-import/dataset-tab/stepId'

// Vicinity import - query dataset tab
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS = 'vicinity-import/query-dataset-tab/errors'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY = 'vicinity-import/query-dataset-tab/isEmpty'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_ENABLED = 'vicinity-import/query-dataset-tab/isEnabled'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_INVALID = 'vicinity-import/query-dataset-tab/isInvalid'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_CAN_SKIP = 'vicinity-import/query-dataset-tab/canSkip'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_STEP_ID = 'vicinity-import/query-dataset-tab/stepId'

// Vicinity import - parse dataset tab
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_ERRORS = 'vicinity-import/parse-dataset-tab/errors'
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_EMPTY = 'vicinity-import/parse-dataset-tab/isEmpty'
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_ENABLED = 'vicinity-import/parse-dataset-tab/isEnabled'
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_INVALID = 'vicinity-import/parse-dataset-tab/isInvalid'
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_CAN_SKIP = 'vicinity-import/parse-dataset-tab/canSkip'
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_STEP_ID = 'vicinity-import/parse-dataset-tab/stepId'

// Vicinity import - set dataset step
export const VICINITY_IMPORT_WIZARD_DATASET_WAS_UPDATED = 'vicinity-import/dataset-step/wasUpdated'

// Vicinity import - query dataset step
export const VICINITY_IMPORT_QUERY_UPDATED_STATE = 'vicinity-import/query-dataset-step/wasUpdated'

// Vicinity-import - parse dataset step
export const VICINITY_IMPORT_WIZARD_POINTS_CONTENT_TYPE = 'vicinity-import/parse-dataset-step/contentType'
export const VICINITY_IMPORT_WIZARD_POINTS_GEOCODING_COLUMNS = 'vicinity-import/parse-dataset-step/geocodingColumns'
export const VICINITY_IMPORT_WIZARD_POINTS_GEOCODING_FINISHED = 'vicinity-import/parse-dataset-step/geocodingFinished'
export const VICINITY_IMPORT_WIZARD_POINTS_LATITUDE_COLUMN = 'vicinity-import/parse-dataset-step/latitudeColumn'
export const VICINITY_IMPORT_WIZARD_POINTS_LONGITUDE_COLUMN = 'vicinity-import/parse-dataset-step/longitudeColumn'
export const VICINITY_IMPORT_WIZARD_POINTS_NAME_COLUMN = 'vicinity-import/parse-dataset-step/nameColumn'
export const VICINITY_IMPORT_WIZARD_POINTS_LATLNG_TABLE = 'vicinity-import/parse-dataset-step/latLngTable'

// New visualization wizard
export const NEW_VISUALIZATION_WIZARD_VISIBLE = 'new-visualization/wizardIsVisible'
export const NEW_VISUALIZATION_WIZARD_PLANS = 'new-visualization/getPlan'
export const NEW_VISUALIZATION_WIZARD_NAME = 'new-visualization/getName'
export const NEW_VISUALIZATION_NAMESPACE = 'new-visualization/storeNamespace'
export const NEW_VISUALIZATION_LINK_NAMESPACE = 'new-visualization/linkNamespace'
export const NEW_VISUALIZATION_WIZARD_ENTRYPOINT = 'new-visualization/entryPoint'
export const NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_CUSTOM_VISUALIZATION = 'new-visualization/entrypointIsCustomVisualization'
export const NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_VIS_FROM_PLAN = 'new-visualization/entrypointIsVisFromPlan'

// New visualization - entrypoint tab
export const NEW_VISUALIZATION_WIZARD_TAB_ENTRYPOINT_IS_ENABLED = 'new-visualization/entrypoint-tab/isEnabled'
export const NEW_VISUALIZATION_WIZARD_TAB_ENTRYPOINT_IS_EMPTY = 'new-visualization/entrypoint-tab/isEmpty'
export const NEW_VISUALIZATION_WIZARD_TAB_ENTRYPOINT_IS_INVALID = 'new-visualization/entrypoint-tab/isInvalid'
export const NEW_VISUALIZATION_WIZARD_TAB_ENTRYPOINT_ERRORS = 'new-visualization/entrypoint-tab/errors'
export const NEW_VISUALIZATION_WIZARD_TAB_ENTRYPOINT_STEP_ID = 'new-visualization/entrypoint-tab/stepId'

// New visualization - plans tab
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_ERRORS = 'new-visualization/plans-tab/errors'
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_EMPTY = 'new-visualization/plans-tab/isEmpty'
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_ENABLED = 'new-visualization/plans-tab/isEnabled'
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_INVALID = 'new-visualization/plans-tab/isInvalid'
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_STEP_ID = 'new-visualization/plans-tab/stepId'
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_HIDDEN = 'new-visualization/plans-tab/isHidden'

// New visualization - props tab
export const NEW_VISUALIZATION_WIZARD_TAB_PROPS_ERRORS = 'new-visualization/props-tab/errors'
export const NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_EMPTY = 'new-visualization/props-tab/isEmpty'
export const NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_ENABLED = 'new-visualization/props-tab/isEnabled'
export const NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_INVALID = 'new-visualization/props-tab/isInvalid'
export const NEW_VISUALIZATION_WIZARD_TAB_PROPS_STEP_ID = 'new-visualization/props-tab/stepId'

// Wizard navigation module
export const WIZARD_NAVIGATION_VISIBLE_STEPS = 'wizard/visibleSteps'
export const WIZARD_NAVIGATION_STEP_BY_ID = 'wizard/stepById'
export const WIZARD_NAVIGATION_ACTIVE_INDEX = 'wizard/activeStepIndex'
export const WIZARD_NAVIGATION_ACTIVE_ID = 'wizard/activeStepId'
export const WIZARD_NAVIGATION_ACTIVE_STEP = 'wizard/activeStep'
export const WIZARD_NAVIGATION_LAST_ENABLED_STEP = 'wizard/lastEnabledStep'
export const WIZARD_NAVIGATION_ACTIVE_IS_FIRST = 'wizard/activeIsFirst'
export const WIZARD_NAVIGATION_ACTIVE_IS_LAST = 'wizard/activeIsLast'
export const WIZARD_NAVIGATION_PREV_DISABLED = 'wizard/isPrevDisabled'
export const WIZARD_NAVIGATION_NEXT_DISABLED = 'wizard/isNextDisabled'
export const WIZARD_NAVIGATION_IS_STEP_ENABLED = 'wizard/isStepEnabled'
export const WIZARD_NAVIGATION_IS_STEP_EMPTY = 'wizard/isStepEmpty'
export const WIZARD_NAVIGATION_IS_STEP_INVALID = 'wizard/isStepInvalid'
export const WIZARD_NAVIGATION_CAN_SKIP_STEP = 'wizard/canSkipStep'
export const WIZARD_NAVIGATION_START_INDEX = 'wizard/startIndex'
export const WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID = 'wizard/isAnyStepBeforeInvalid'

// Layer wizard module
export const LAYER_WIZARD_VISIBLE = 'layerWizard/visible'
export const LAYER_WIZARD_LAYER_TYPE = 'layerWizard/layerType'
export const LAYER_WIZARD_LAYER_NAME = 'layerWizard/layerName'
export const LAYER_WIZARD_SURROUNDING_GEOMETRIES = 'layerWizard/surroundingGeometries'
export const LAYER_WIZARD_DATASET = 'layerWizard/dataset'
export const LAYER_WIZARD_DATASET_UPDATE = 'layerWizard/datasetUpdate'
export const LAYER_WIZARD_DATASET_COLUMNS = 'layerWizard/datasetColumns'
export const LAYER_WIZARD_GEOMETRYSET = 'layerWizard/geometryset'
export const LAYER_WIZARD_GRANULARITY_GEOMETRYSET = 'layerWizard/granularityGeometryset'
export const LAYER_WIZARD_LINK_COLUMN = 'layerWizard/linkColumn'
export const LAYER_WIZARD_LAYER_STYLE = 'layerWizard/layerStyle'
export const LAYER_WIZARD_ENTRYPOINT = 'layerWizard/entryPoint'
export const LAYER_WIZARD_ENTRYPOINT_IS_GEOMETRY = 'layerWizard/entrypointIsGeometry'
export const LAYER_WIZARD_ENTRYPOINT_IS_LINKED_DATASET = 'layerWizard/entrypointIsLinkedDataset'
export const LAYER_WIZARD_ENTRYPOINT_IS_POI = 'layerWizard/entrypointIsPOI'
export const LAYER_WIZARD_ENTRYPOINT_IS_AREA_SELECT = 'layerWizard/entrypointIsAreaSelect'
export const LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD = 'layerWizard/entrypointIsAreaUpload'
export const LAYER_WIZARD_NEED_TO_UPLOAD_DATASET_DETAILS = 'layerWizard/needToUploadDatasetDetails'
export const LAYER_WIZARD_NAMESPACE = 'layerWizard/wizardNameSpace'
export const LAYER_WIZARD_LINK_NAMESPACE = 'layerWizard/linkToNameSpace'

// Layer wizard tabs - layer type
export const LAYER_WIZARD_TAB_ENTRYPOINT_IS_ENABLED = 'layerWizard/tabs/entrypoint/isEnabled'
export const LAYER_WIZARD_TAB_ENTRYPOINT_IS_EMPTY = 'layerWizard/tabs/entrypoint/isEmpty'
export const LAYER_WIZARD_TAB_ENTRYPOINT_IS_INVALID = 'layerWizard/tabs/entrypoint/isInvalid'
export const LAYER_WIZARD_TAB_ENTRYPOINT_ERRORS = 'layerWizard/tabs/entrypoint/errors'
export const LAYER_WIZARD_TAB_ENTRYPOINT_STEP_ID = 'layerWizard/tabs/entrypoint/stepId'

// Layer wizard tabs - geometry
export const LAYER_WIZARD_TAB_GEOMETRY_IS_HIDDEN = 'layerWizard/tabs/geometry/isHidden'
export const LAYER_WIZARD_TAB_GEOMETRY_IS_ENABLED = 'layerWizard/tabs/geometry/isEnabled'
export const LAYER_WIZARD_TAB_GEOMETRY_IS_EMPTY = 'layerWizard/tabs/geometry/isEmpty'
export const LAYER_WIZARD_TAB_GEOMETRY_IS_INVALID = 'layerWizard/tabs/geometry/isInvalid'
export const LAYER_WIZARD_TAB_GEOMETRY_ERRORS = 'layerWizard/tabs/geometry/errors'
export const LAYER_WIZARD_TAB_GEOMETRY_STEP_ID = 'layerWizard/tabs/geometry/stepId'

// Layer wizard tabs - surrounding geometries
export const LAYER_WIZARD_TAB_SURROUNDING_IS_HIDDEN = 'layerWizard/tabs/surrounding/isHidden'
export const LAYER_WIZARD_TAB_SURROUNDING_IS_ENABLED = 'layerWizard/tabs/surrounding/isEnabled'
export const LAYER_WIZARD_TAB_SURROUNDING_IS_EMPTY = 'layerWizard/tabs/surrounding/isEmpty'
export const LAYER_WIZARD_TAB_SURROUNDING_IS_INVALID = 'layerWizard/tabs/surrounding/isInvalid'
export const LAYER_WIZARD_TAB_SURROUNDING_ERRORS = 'layerWizard/tabs/surrounding/errors'
export const LAYER_WIZARD_TAB_SURROUNDING_STEP_ID = 'layerWizard/tabs/surrounding/stepId'

// Layer wizard tabs - area upload
export const LAYER_WIZARD_TAB_AREA_UPLOAD_IS_HIDDEN = 'layerWizard/tabs/areaUpload/isHidden'
export const LAYER_WIZARD_TAB_AREA_UPLOAD_IS_ENABLED = 'layerWizard/tabs/areaUpload/isEnabled'
export const LAYER_WIZARD_TAB_AREA_UPLOAD_IS_EMPTY = 'layerWizard/tabs/areaUpload/isEmpty'
export const LAYER_WIZARD_TAB_AREA_UPLOAD_IS_INVALID = 'layerWizard/tabs/areaUpload/isInvalid'
export const LAYER_WIZARD_TAB_AREA_UPLOAD_ERRORS = 'layerWizard/tabs/areaUpload/errors'
export const LAYER_WIZARD_TAB_AREA_UPLOAD_STEP_ID = 'layerWizard/tabs/areaUpload/stepId'

// Layer wizard tabs - granularity
export const LAYER_WIZARD_TAB_GRANULARITY_IS_HIDDEN = 'layerWizard/tabs/granularity/isHidden'
export const LAYER_WIZARD_TAB_GRANULARITY_IS_ENABLED = 'layerWizard/tabs/granularity/isEnabled'
export const LAYER_WIZARD_TAB_GRANULARITY_IS_EMPTY = 'layerWizard/tabs/granularity/isEmpty'
export const LAYER_WIZARD_TAB_GRANULARITY_IS_INVALID = 'layerWizard/tabs/granularity/isInvalid'
export const LAYER_WIZARD_TAB_GRANULARITY_CAN_SKIP = 'layerWizard/tabs/granularity/canSkip'
export const LAYER_WIZARD_TAB_GRANULARITY_ERRORS = 'layerWizard/tabs/granularity/errors'
export const LAYER_WIZARD_TAB_GRANULARITY_STEP_ID = 'layerWizard/tabs/granularity/stepId'

// Layer wizard tabs - POI dataset
export const LAYER_WIZARD_TAB_POI_DATASET_IS_HIDDEN = 'layerWizard/tabs/poi-dataset/isHidden'
export const LAYER_WIZARD_TAB_POI_DATASET_IS_ENABLED = 'layerWizard/tabs/poi-dataset/isEnabled'
export const LAYER_WIZARD_TAB_POI_DATASET_IS_EMPTY = 'layerWizard/tabs/poi-dataset/isEmpty'
export const LAYER_WIZARD_TAB_POI_DATASET_IS_INVALID = 'layerWizard/tabs/poi-dataset/isInvalid'
export const LAYER_WIZARD_TAB_POI_DATASET_ERRORS = 'layerWizard/tabs/poi-dataset/errors'
export const LAYER_WIZARD_TAB_POI_DATASET_STEP_ID = 'layerWizard/tabs/poi-dataset/stepId'

// Layer wizard tabs - area dataset
export const LAYER_WIZARD_TAB_AREA_DATASET_IS_HIDDEN = 'layerWizard/tabs/area-dataset/isHidden'
export const LAYER_WIZARD_TAB_AREA_DATASET_IS_ENABLED = 'layerWizard/tabs/area-dataset/isEnabled'
export const LAYER_WIZARD_TAB_AREA_DATASET_IS_EMPTY = 'layerWizard/tabs/area-dataset/isEmpty'
export const LAYER_WIZARD_TAB_AREA_DATASET_IS_INVALID = 'layerWizard/tabs/area-dataset/isInvalid'
export const LAYER_WIZARD_TAB_AREA_DATASET_CAN_SKIP = 'layerWizard/tabs/area-dataset/canSkip'
export const LAYER_WIZARD_TAB_AREA_DATASET_ERRORS = 'layerWizard/tabs/area-dataset/errors'
export const LAYER_WIZARD_TAB_AREA_DATASET_STEP_ID = 'layerWizard/tabs/area-dataset/stepId'
export const LAYER_WIZARD_TAB_AREA_DATASET_STEP_WAS_SKIPPED = 'layerWizard/tabs/area-dataset/wasSkipped'

// Layer wizard tabs - area link
export const LAYER_WIZARD_TAB_AREA_LINK_IS_HIDDEN = 'layerWizard/tabs/area-link/isHidden'
export const LAYER_WIZARD_TAB_AREA_LINK_IS_ENABLED = 'layerWizard/tabs/area-link/isEnabled'
export const LAYER_WIZARD_TAB_AREA_LINK_IS_EMPTY = 'layerWizard/tabs/area-link/isEmpty'
export const LAYER_WIZARD_TAB_AREA_LINK_IS_INVALID = 'layerWizard/tabs/area-link/isInvalid'
export const LAYER_WIZARD_TAB_AREA_LINK_ERRORS = 'layerWizard/tabs/area-link/errors'
export const LAYER_WIZARD_TAB_AREA_LINK_STEP_ID = 'layerWizard/tabs/area-link/stepId'

// Layer wizard tabs - layer props
export const LAYER_WIZARD_TAB_PROPS_IS_ENABLED = 'layerWizard/tabs/props/isEnabled'
export const LAYER_WIZARD_TAB_PROPS_IS_EMPTY = 'layerWizard/tabs/props/isEmpty'
export const LAYER_WIZARD_TAB_PROPS_IS_HIDDEN = 'layerWizard/tabs/props/isHidden'
export const LAYER_WIZARD_TAB_PROPS_IS_INVALID = 'layerWizard/tabs/props/isInvalid'
export const LAYER_WIZARD_TAB_PROPS_ERRORS = 'layerWizard/tabs/props/errors'
export const LAYER_WIZARD_TAB_PROPS_STEP_ID = 'layerWizard/tabs/props/stepId'

// Layer wizard steps - geometry options
export const LAYER_WIZARD_STEP_GEOMETRY_IS_VALID = 'layerWizard/steps/geometry/isValid'

// Layer wizard steps - granularyti
export const LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSETS = 'layerWizard/steps/granularity/geometrysets'
export const LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSET = 'layerWizard/steps/granularity/geometryset'
export const LAYER_WIZARD_STEP_GRANULARITY_WAS_UPDATED = 'layerWizard/steps/granularity/wasUpdated'

// Layer wizard steps - surrounding
export const LAYER_WIZARD_STEP_SURROUNDING_WAS_UPDATED = 'layerWizard/steps/surrounding/wasUpdated'

// Layer wizard steps - poi dataset
export const LAYER_WIZARD_STEP_POI_DATASET_WAS_UPDATED = 'layerWizard/steps/poi-dataset/wasUpdated'

// Layer wizard steps - area link
export const LAYER_WIZARD_STEP_AREA_LINK_WAS_UPDATED = 'layerWizard/steps/area-link/wasUpdated'

// Layer wizard steps - area link
export const LAYER_WIZARD_STEP_AREA_UPLOAD_WAS_UPDATED = 'layerWizard/steps/area-upload/wasUpdated'

// Layer wizard steps - area dataset
export const LAYER_WIZARD_STEP_AREA_DATASET_WAS_UPDATED = 'layerWizard/steps/area-dataset/wasUpdated'

// Layer wizard steps - layer props
export const LAYER_WIZARD_STEP_PROPS_WAS_UPDATED = 'layerWizard/steps/props/wasUpdated'

// Layer wizard steps - layer props - points
export const LAYER_WIZARD_POINTS_LATITUDE_COLUMN = 'layerWizard/steps/props/points/latitudeColumn'
export const LAYER_WIZARD_POINTS_LONGITUDE_COLUMN = 'layerWizard/steps/props/points/longitudeColumn'
export const LAYER_WIZARD_POINTS_GEOCODING_COLUMNS = 'layerWizard/steps/props/points/geocodingColumns'
export const LAYER_WIZARD_POINTS_GEOCODING_FINISHED = 'layerWizard/steps/props/points/geocodingFinished'
export const LAYER_WIZARD_POINTS_CONTENT_TYPE = 'layerWizard/steps/props/points/contentType'

// Layer wizard steps - layer props - geometry
export const LAYER_WIZARD_GEOMETRY_IS_LINK_COLUMN_REQUIRED = 'layerWizard/steps/props/geometry/isLinkColumnRequired'

// Geometries module
export const GET_GEOMETRIES = 'geometriesCache/getGeometries'

// Orders module
export const ALL_ORDERS = 'orders/getAllOrders'
export const ORDERS_INITIALIZED = 'orders/isInitialized'
export const DRAGGED_ORDER = 'orders/draggedOrder'
export const PENDING_DROP_RESULT = 'orders/wasDropPerformed'
export const GET_ORDERS_BY_STATUS = 'orders/getByStatus'
export const ACTIVE_ORDER = 'orders/activeOrder'
export const ACTIVE_ORDER_ITEMS = 'orders/activeOrderItems'
export const ACTIVE_ORDER_DETAILS_VISIBLE = 'orders/activeOrderDetailsVisible'
export const ACTIVE_ORDER_ITEMS_VISIBLE = 'orders/activeOrderItemsVisible'

// visualization-location
export const GET_VISUALISATION_LOCATIONS = 'visualization-location/getVisualisationLocations'
export const GET_CURRENT_VISUALISATION_LOCATION = 'visualization-location/getCurrentVisualisationLocation'

// Analytics module
export const ANALYTICS_DATASET = 'analytics/getDataset'
export const ANALYTICS_DATASET_ID = 'analytics/getDatasetId'
export const ANALYTICS_DATASET_NAME = 'analytics/getDatasetName'
export const ANALYTICS_DATASET_EDITABLE = 'analytics/isDatasetEditable'

// New dataset wizard
export const DATASET_WIZARD_VISIBLE = 'datasetWizard/visible'
export const DATASET_WIZARD_DATASET_NAME = 'datasetWizard/datasetName'
export const DATASET_WIZARD_DATASET = 'datasetWizard/dataset'
export const DATASET_WIZARD_DATASET_UPDATE = 'datasetWizard/datasetUpdate'
export const DATASET_WIZARD_ENTRYPOINT = 'datasetWizard/entrypoint'
export const DATASET_WIZARD_ENTRYPOINT_IS_GEOCODING = 'datasetWizard/isGeocoding'
export const DATASET_WIZARD_ENTRYPOINT_IS_TDLINX = 'datasetWizard/isTdlinx'
export const DATASET_WIZARD_ENTRYPOINT_IS_GEOMETRIES = 'datasetWizard/isGeometries'
export const DATASET_WIZARD_NAMESPACE = 'datasetWizard/namespace'
export const DATASET_WIZARD_LINK_NAMESPACE = 'datasetWizard/linkNamespace'
export const DATASET_WIZARD_DATASET_TYPE = 'datasetWizard/datasetType'

// New dataset wizard tabs - entrypoint
export const DATASET_WIZARD_TAB_ENTRYPOINT_IS_ENABLED = 'datasetWizard/tabs/entrypoint/isEnabled'
export const DATASET_WIZARD_TAB_ENTRYPOINT_IS_EMPTY = 'datasetWizard/tabs/entrypoint/isEmpty'
export const DATASET_WIZARD_TAB_ENTRYPOINT_IS_INVALID = 'datasetWizard/tabs/entrypoint/isInvalid'
export const DATASET_WIZARD_TAB_ENTRYPOINT_ERRORS = 'datasetWizard/tabs/entrypoint/errors'
export const DATASET_WIZARD_TAB_ENTRYPOINT_STEP_ID = 'datasetWizard/tabs/entrypoint/stepId'

// New dataset wizard tabs - geocoding
export const DATASET_WIZARD_TAB_GEOCODING_IS_ENABLED = 'datasetWizard/tabs/geocoding/isEnabled'
export const DATASET_WIZARD_TAB_GEOCODING_IS_EMPTY = 'datasetWizard/tabs/geocoding/isEmpty'
export const DATASET_WIZARD_TAB_GEOCODING_IS_INVALID = 'datasetWizard/tabs/geocoding/isInvalid'
export const DATASET_WIZARD_TAB_GEOCODING_ERRORS = 'datasetWizard/tabs/geocoding/errors'
export const DATASET_WIZARD_TAB_GEOCODING_STEP_ID = 'datasetWizard/tabs/geocoding/stepId'

// New dataset wizard tabs - set dataset
export const DATASET_WIZARD_TAB_SET_DATASET_IS_ENABLED = 'datasetWizard/tabs/setDataset/isEnabled'
export const DATASET_WIZARD_TAB_SET_DATASET_IS_EMPTY = 'datasetWizard/tabs/setDataset/isEmpty'
export const DATASET_WIZARD_TAB_SET_DATASET_IS_INVALID = 'datasetWizard/tabs/setDataset/isInvalid'
export const DATASET_WIZARD_TAB_SET_DATASET_ERRORS = 'datasetWizard/tabs/setDataset/errors'
export const DATASET_WIZARD_TAB_SET_DATASET_STEP_ID = 'datasetWizard/tabs/setDataset/stepId'

// New dataset wizard tabs - dataset props
export const DATASET_WIZARD_TAB_DATASET_PROPS_IS_ENABLED = 'datasetWizard/tabs/datasetProps/isEnabled'
export const DATASET_WIZARD_TAB_DATASET_PROPS_IS_EMPTY = 'datasetWizard/tabs/datasetProps/isEmpty'
export const DATASET_WIZARD_TAB_DATASET_PROPS_IS_INVALID = 'datasetWizard/tabs/datasetProps/isInvalid'
export const DATASET_WIZARD_TAB_DATASET_PROPS_ERRORS = 'datasetWizard/tabs/datasetProps/errors'
export const DATASET_WIZARD_TAB_DATASET_PROPS_STEP_ID = 'datasetWizard/tabs/datasetProps/stepId'

// New dataset wizard steps - geocoding
export const DATASET_WIZARD_GEOCODED_DATA = 'datasetWizard/steps/geocoding/geocodedData'
export const DATASET_WIZARD_GEOCODING_FINISHED = 'datasetWizard/steps/geocoding/geocodingFinished'
export const DATASET_WIZARD_GEOCODING_SELECTED_COLUMNS = 'datasetWizard/steps/geocoding/selectedColumns'
