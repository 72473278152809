import Vue from 'vue'
import { dateFilter } from './date.filter'
import { capitalizeFilter } from './capitalize.filter'
import { fixedFilter } from './fixed.filter'
import { relativeToNowFilter } from '@/filters/from-now'

Vue.filter('date', dateFilter)
Vue.filter('capitalize', capitalizeFilter)
Vue.filter('fixed', fixedFilter)
Vue.filter('relativeToNow', relativeToNowFilter)
