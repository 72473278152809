import { Node } from 'tiptap'
import LayerLegendView from './layer-legend-view/layer-legend-view.vue'
import { NodeAttributes, Commands, NodeOptions } from '@/components/shared/tiptap/types'
import { TextSelection } from 'prosemirror-state'
import { DOMOutputSpec, NodeSpec } from 'prosemirror-model'
import { VueComponentDeclaration } from '@/types/vue'
import { LegendType } from '@/types/enums'

function getLegendNodeAttrs (): NodeAttributes {
  return {
    sortOrder: { default: [] },
    maxDecimals: { default: null },
    legendType: { default: LegendType.FillLegend },
    legendValues: { default: [] }
  }
}

export class LayerLegend extends Node {
  // eslint-disable-next-line no-useless-constructor
  constructor (options: NodeOptions) {
    super(options)
  }

  get name () {
    return 'layerLegend'
  }

  get schema (): NodeSpec {
    return {
      group: 'block',
      inline: false,
      selectable: true,
      draggable: true,
      attrs: getLegendNodeAttrs(),
      parseDOM: [{
        tag: 'legend-component',
        getAttrs: (domNode) => this.getAttrsFromDomNode(domNode)
      }],
      toDOM: (node) => this.toDOM(node)
    }
  }

  commands ({ type }): Commands {
    return {
      addMetaData: (legendType) =>
        (state, dispatch) => {
          const { selection }: { selection: TextSelection } = state
          const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos

          const node = type.create('legend-component', null)
          node.attrs.legendType = legendType
          const transaction = state.tr.insert(position + 1, node)
          dispatch(transaction)
        }
    }
  }

  stopEvent (/* event */) {
    // Stopping prevents focus from work correctly.
    return false
  }

  getAttrsFromDomNode (domNode): NodeAttributes {
    let sortOrder = domNode.dataset.sortOrder
    sortOrder = sortOrder ? JSON.parse(domNode.dataset.sortOrder) : undefined

    let legendValues = domNode.dataset.legendValues
    legendValues = legendValues ? JSON.parse(legendValues) : undefined

    const maxDecimals = domNode.dataset.maxDecimals == null ? null : parseInt(domNode.dataset.maxDecimals)
    const legendType = domNode.dataset.legendType

    return { sortOrder, maxDecimals, legendType, legendValues }
  }

  toDOM (node): DOMOutputSpec {
    const { sortOrder, maxDecimals, legendType, legendValues } = node.attrs

    return ['legend-component', {
      'data-sort-order': JSON.stringify(sortOrder),
      'data-max-decimals': maxDecimals,
      'data-legend-type': legendType,
      'data-legend-values': JSON.stringify(legendValues)
    }]
  }

  get view (): VueComponentDeclaration {
    return LayerLegendView
  }
}
