import { Store } from '@/store/store'
import { LOAD_BANNED_PRODUCTS } from '@/store/action-types'
import { BANNED_PRODUCTS } from '@/store/getter-types'
import { CollectionItemArray } from '@/types/planning/wizard/filter-editor'
import { CollectionConfig } from './collection-config'

export class BannedProductsCollection extends CollectionConfig {
  get options (): CollectionItemArray {
    return Store.getters[BANNED_PRODUCTS]
  }

  async initCollection (): Promise<void> {
    await Store.dispatch(LOAD_BANNED_PRODUCTS)
  }
}
