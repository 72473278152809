import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import {
  isCommonSettingsFilterNode,
  isFilterFunctionNode,
  isFlightFilterNode,
  isGoalFilterNode,
  isLocationFilterNode,
  isMediaTypeFilterNode,
  isRankingFunctionFilterNode
} from '@/utils/plan-wizard-steps'

const nodesOrder = [isLocationFilterNode, isMediaTypeFilterNode, isFlightFilterNode, isFilterFunctionNode, isRankingFunctionFilterNode, isGoalFilterNode, isCommonSettingsFilterNode]

export function sortNodes (nodes: ScoringFunctionNode[]) {
  return nodesOrder.map(orderCallback => {
    return nodes.filter(node => orderCallback(node))
  }).flat()
}
