
























import { Component, Vue } from 'vue-property-decorator'
import { get } from 'lodash'
import { SelectOption } from '@/types/planning/wizard/filter-editor'
import { LocalTranslations } from '@/decorators/local-translations'
import messages from './locales/locales'
import {
  RANKING_IMPORT_FILTERED_DATASET, RANKING_IMPORT_WIZARD_KEY_COLUMNS_PLZ_COLUMN,
  RANKING_IMPORT_WIZARD_KEY_COLUMNS_RATING_COLUMN
} from '@/store/ranking-import-wizard/getters'
import {
  RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_PLZ_COLUMN, RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_RATING_COLUMN
} from '@/store/ranking-import-wizard/mutations'

@Component
@LocalTranslations(messages)
export default class SelectColumnsStep extends Vue {
  private get options (): SelectOption[] {
    const row = get(this.$store.getters[RANKING_IMPORT_FILTERED_DATASET], 0, {})

    return Object.keys(row)
      .map(column => ({
        text: column,
        value: column
      }))
  }

  private get keyColumn (): string {
    return this.$store.getters[RANKING_IMPORT_WIZARD_KEY_COLUMNS_PLZ_COLUMN]
  }

  private set keyColumn (value: string) {
    this.$store.commit(RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_PLZ_COLUMN, value)
  }

  private get valueColumn (): string {
    return this.$store.getters[RANKING_IMPORT_WIZARD_KEY_COLUMNS_RATING_COLUMN]
  }

  private set valueColumn (value: string) {
    this.$store.commit(RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_RATING_COLUMN, value)
  }
}
