import { i18n } from '@/locales/i18n'
import {
  LAYER_WIZARD_DATASET,
  LAYER_WIZARD_GEOMETRY_IS_LINK_COLUMN_REQUIRED,
  LAYER_WIZARD_LINK_COLUMN
} from '@/store/getter-types'
import { LAYER_WIZARD_GEOMETRY_VALIDATE, LAYER_WIZARD_GEOMETRY_RESET } from '@/store/action-types'
import { LAYER_WIZARD_SET_LINK_COLUMN } from '@/store/mutation-types'

// initial state
const state = {}

// getters
const getters = {
  [LAYER_WIZARD_GEOMETRY_IS_LINK_COLUMN_REQUIRED] (state, getters) {
    return !!getters[LAYER_WIZARD_DATASET]
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [LAYER_WIZARD_GEOMETRY_VALIDATE] ({ getters }) {
    const errors = []

    if (getters[LAYER_WIZARD_GEOMETRY_IS_LINK_COLUMN_REQUIRED] && !getters[LAYER_WIZARD_LINK_COLUMN]) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.link-column-not-selected'))
    }

    return errors
  },
  async [LAYER_WIZARD_GEOMETRY_RESET] ({ commit }) {
    await commit(LAYER_WIZARD_SET_LINK_COLUMN, null, { root: true })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
