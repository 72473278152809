import {
  WIZARD_INIT_STEPS,
  WIZARD_SET_START_INDEX,
  WIZARD_SET_STEP_ACTIVE,
  WIZARD_SET_STEP_TOUCHED
} from '@/store/mutation-types'

export const mutations = {
  [WIZARD_SET_STEP_ACTIVE] (state, { step, active }) {
    step.active = active
  },
  [WIZARD_SET_STEP_TOUCHED] (state, { step, touched }) {
    step.touched = step.touched || touched
  },
  [WIZARD_INIT_STEPS] (state, steps) {
    state.steps = Object.values(steps).map(step => ({
      ...step,
      active: false,
      touched: false
    }))
  },
  [WIZARD_SET_START_INDEX] (state, index) {
    state.startIndex = index
  }
}
