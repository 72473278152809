import { LayerType } from '@/types/visualization/layer/enums'
import {
  LAYER_WIZARD_STEP_ENTRYPOINT_INIT_GEOMETRY,
  LAYER_WIZARD_STEP_ENTRYPOINT_INIT_POI
} from '@/store/action-types'
import { LAYER_WIZARD_SET_ENTRYPOINT, LAYER_WIZARD_SET_LAYER_TYPE } from '@/store/mutation-types'
import { ENTRYPOINT_GEOMETRY, ENTRYPOINT_POI } from '../../constants/entrypoints'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {
  [LAYER_WIZARD_STEP_ENTRYPOINT_INIT_GEOMETRY] ({ commit }) {
    commit(LAYER_WIZARD_SET_LAYER_TYPE, LayerType.Shapes)
    commit(LAYER_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_GEOMETRY)
  },
  [LAYER_WIZARD_STEP_ENTRYPOINT_INIT_POI] ({ commit }) {
    commit(LAYER_WIZARD_SET_LAYER_TYPE, LayerType.Points)
    commit(LAYER_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_POI)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
