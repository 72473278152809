


















import Slider from './slider.vue'
import { DirectionAwareMixin } from '../mixin/direction-aware.mixin'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ColorModel } from '@/types/color-data'
import { VueDynamicClassDef } from '@/types/vue'

@Component({
  components: { Slider }
})
export default class Hue extends Mixins(DirectionAwareMixin) {
  @Prop({ required: true }) value!: ColorModel

  private pullDirection: null | string = null

  private get sliderValue (): number {
    if (this.pullDirection === 'end' && this.value.hsl.h === 0) {
      return 360
    }

    return this.value.hsl.h
  }

  private set sliderValue (value: number) {
    if (this.value.hsl.h === value) {
      return
    }

    if (value > this.value.hsl.h) {
      this.pullDirection = 'end'
    }

    if (value < this.value.hsl.h) {
      this.pullDirection = 'start'
    }

    this.$emit('change', {
      h: value,
      s: this.value.hsl.s,
      l: this.value.hsl.l,
      a: this.value.hsl.a,
      source: 'hsl'
    })
  }

  private get gradientClass (): VueDynamicClassDef {
    return {
      'color-picker__hue__gradient--horizontal': !this.isVertical,
      'color-picker__hue__gradient--vertical': this.isVertical
    }
  }
}
