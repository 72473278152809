















import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import DatasetQueryBuilder from '@/components/shared/query-builder/dataset-query-builder.vue'
import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import { QueryGroup } from '@/types/query-builder'
import { DataColumn, Dataset, DatasetRows } from '@/types/common'
import { RANKING_IMPORT_WIZARD_DATASET, RANKING_IMPORT_WIZARD_QUERY_DATA } from '@/store/ranking-import-wizard/getters'
import { RANKING_IMPORT_WIZARD_CHANGE_QUERY_DATA } from '@/store/ranking-import-wizard/actions'

@Component({
  components: { DatasetQueryBuilder, OverlayScrollbars },
  computed: {
    ...mapGetters({
      dataset: RANKING_IMPORT_WIZARD_DATASET
    })
  }
})
export default class QueryDatasetStep extends Vue {
  private dataset!: Dataset | null

  get query (): QueryGroup {
    return this.$store.getters[RANKING_IMPORT_WIZARD_QUERY_DATA]
  }

  set query (query: QueryGroup) {
    this.$store.dispatch(RANKING_IMPORT_WIZARD_CHANGE_QUERY_DATA, query)
  }

  get results (): DatasetRows {
    return this.dataset?.datasetRows ?? []
  }

  get columns (): DataColumn[] {
    return this.dataset?.columnInfos ?? []
  }
}
