import { Component, Vue } from 'vue-property-decorator'
import { LegendValue } from '@/types/visualization/map-legend'

@Component
export default class ColorLegendMixin<TLayer> extends Vue {
  protected layer!: TLayer

  protected makeColorLegendValues (value, color, opacity): LegendValue[] {
    return [{
      label: value,
      color: color,
      opacity,
      key: value,
      allLabels: [value]
    }]
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected isSublayerVisible (_layer: TLayer, _value: string) {
    return true
  }
}
