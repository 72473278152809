import { PlanObject } from '@/types/planning'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { cloneDeep } from 'lodash'
import { scoringFuncProcess, ScoringFuncProcessType } from './constant'
import { sortNodes } from './sort-nodes'

export function formatScoringFunc<T extends Partial<PlanObject>> (plan: T) {
  const copiedPlan = cloneDeep(plan)
  const processMapping = scoringFuncProcess[ScoringFuncProcessType.Format]

  copiedPlan.nodes = copiedPlan.nodes?.map(node => {
    const scoringFuncName = node.scoringFunction.name
    const scoringFuncParser = processMapping[scoringFuncName]

    if (scoringFuncParser) {
      return scoringFuncParser(node, copiedPlan)
    }

    return node
  })

  copiedPlan.nodes = sortNodes(copiedPlan.nodes as ScoringFunctionNode[])

  return copiedPlan
}
