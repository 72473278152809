import { FILTER_CLUSTERING, FILTER_VICINITY } from '@/constants/scoring'
import { vicinityFormatter, vicinityParser } from '@/utils/planning/parse-planning/vicinity-filter'

export enum ScoringFuncProcessType {
  Parse = 'Parse',
  Format = 'Format'
}

const scoringFuncDataFormatter = {
  [FILTER_VICINITY]: vicinityFormatter,
  [FILTER_CLUSTERING]: vicinityFormatter
}

const scoringFuncDataParser = {
  [FILTER_VICINITY]: vicinityParser,
  [FILTER_CLUSTERING]: vicinityParser
}

export const scoringFuncProcess = {
  [ScoringFuncProcessType.Parse]: scoringFuncDataParser,
  [ScoringFuncProcessType.Format]: scoringFuncDataFormatter
}
