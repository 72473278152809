import { siteDetailsApi } from '@/api/rest/site-details/site-details.api'
import { SCORING_SITE_DETAILS } from '@/store/getter-types'
import { GET_SCORING_SITE_DETAILS, LOAD_SCORING_SITE_DETAILS } from '@/store/action-types'
import { ADD_SCORING_SITE_DETAILS } from '@/store/mutation-types'

// initial state
const state = {
  siteDetails: new Map([])
}

// getters
const getters = {
  [SCORING_SITE_DETAILS] (state) {
    return state.siteDetails
  }
}

// actions
const actions = {
  [GET_SCORING_SITE_DETAILS] ({ dispatch, getters }, siteId) {
    const details = getters[SCORING_SITE_DETAILS].get(siteId)

    if (details) {
      return Promise.resolve(details)
    }

    return dispatch(LOAD_SCORING_SITE_DETAILS, siteId)
  },
  [LOAD_SCORING_SITE_DETAILS] ({ commit, getters }, siteId) {
    return siteDetailsApi.getSiteDetails(siteId)
      .then(locationDetails => {
        commit(ADD_SCORING_SITE_DETAILS, locationDetails)

        return getters[SCORING_SITE_DETAILS].get(siteId)
      })
  }
}

// mutations
const mutations = {
  [ADD_SCORING_SITE_DETAILS] (state, siteDetail) {
    state.siteDetails.set(siteDetail.externalId, siteDetail)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
