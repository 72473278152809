import { PlanObject } from '@/types/planning'
import { cloneDeep, get } from 'lodash'
import { scoringFuncProcess, ScoringFuncProcessType } from '@/utils/planning/parse-planning/constant'
import { sortNodes } from '@/utils/planning/parse-planning/sort-nodes'

function isPromise (p) {
  return p && Object.prototype.toString.call(p) === '[object Promise]'
}

function task (generatorFn: () => IterableIterator<Promise<unknown> | unknown>): Promise<void> {
  const promise = new Promise<void>((resolve) => {
    const iterator = generatorFn()

    const nextStep = (prevValue) => {
      const { done, value } = iterator.next(prevValue)
      if (done) {
        resolve()
        return
      }
      return isPromise(value) ? value.then(result => { nextStep(result) }) : nextStep(value)
    }

    nextStep(undefined)
  })

  return promise
}

export async function processScoringFunc<T extends Partial<PlanObject>> (plan: T) {
  const copiedPlan = cloneDeep(plan)
  const nodes = get(copiedPlan, 'nodes', [])
  const processMapping = scoringFuncProcess[ScoringFuncProcessType.Parse]
  const sortedNodes = sortNodes(nodes)

  function * parseScoringFunctions () {
    for (let index = 0; index < sortedNodes.length; index++) {
      const scoringFuncName = sortedNodes[index].scoringFunction.name
      const scoringFuncParser = processMapping[scoringFuncName]

      if (scoringFuncParser) {
        const updatedNode = yield scoringFuncParser(sortedNodes[index], copiedPlan)
        const nodeIndex = copiedPlan.nodes?.findIndex(node => node.id === updatedNode.id) as number
        copiedPlan.nodes && copiedPlan.nodes.splice(nodeIndex, 1, updatedNode)
      }
    }
  }

  await task(parseScoringFunctions)

  return copiedPlan
}
