import { PluginObject } from 'vue'
import { VueComponentDeclaration } from '@/types/vue'
import { i18n } from '@/locales/i18n'
import { Store as store } from '@/store/store'

type AsyncModalProps = {
  component: VueComponentDeclaration,
  props?: {
    [key: string]: unknown
  }
}

type AsyncModalFunctions = (options: AsyncModalProps) => Promise<void>

declare module 'vue/types/vue' {
  interface Vue {
    $asyncModal: AsyncModalFunctions;
  }
}

export class AsyncModalPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$asyncModal = async function ({ component, props }: AsyncModalProps): Promise<void> {
      const modalInstance = new Vue({
        i18n,
        store,
        render: createElement => createElement(component, { props })
      }).$mount()

      modalInstance.$root.$emit = (event, args) => this.$root.$emit(event, args)

      this.$nextTick(() => {
        modalInstance._vnode.componentInstance.openModal()
      })
    }
  }
}
