import RangeAwareMixin from './range-aware.mixin'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { LayerGeometry } from '@/types/visualization/layer/geometry'
import { Color } from 'csstype'
import { RangeFillValueConfig, SubLayerConfig } from '@/types/visualization/layer/style'
import { Component } from 'vue-property-decorator'

@Component
export default class RangePathMixin<TLayer extends BorderedGeometryLayer> extends RangeAwareMixin<TLayer> {
  protected get rangePathDataRows (): LayerGeometry[] {
    const col = this.layer.style.path.gradientColumn
    return this.getRangeDataRows(col, this.layer.style.path as SubLayerConfig, this.rangePathAbsoluteValues)
  }

  protected get rangePathColorFunc () {
    const gradientColumn = this.layer.style.path.gradientColumn

    if (typeof gradientColumn === 'undefined') {
      return () => null
    }

    return row => this.rangePathColor(row.meta[gradientColumn])
  }

  protected get rangePathOpacityFunc () {
    const gradientColumn = this.layer.style.path.gradientColumn

    if (typeof gradientColumn === 'undefined') {
      return () => null
    }

    return row => this.rangePathOpacity(row.meta[gradientColumn])
  }

  protected get rangePathAbsoluteValues (): RangeFillValueConfig[] {
    return this.layer.style.path.range?.values ?? []
  }

  protected get quantityOfPathValuesForRange (): number[] {
    return this.calculateQuantityOfValuesForRange(this.rangePathAbsoluteValues, this.layer.style.path.gradientColumn)
  }

  private rangePathColor (rangeValue: number): Color {
    return this.calculateRangePathOptions(rangeValue).color as Color
  }

  private rangePathOpacity (rangeValue: number): number {
    return this.calculateRangePathOptions(rangeValue).opacity as number
  }

  private calculateRangePathOptions (rangeValue: number): RangeFillValueConfig {
    return this.calculateRangeColor(rangeValue, this.rangePathAbsoluteValues)
  }
}
