import moment from '@/lib/moment'

export function relativeToNowFilter (possibleDate): string {
  if (!moment.isDate(possibleDate) && !moment.isMoment(possibleDate)) {
    return possibleDate
  }

  const date = moment(possibleDate)
  const now = moment()

  return date.isAfter(now) ? date.toNow() : date.fromNow()
}
