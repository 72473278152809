



















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { uniqBy, uniqWith, pick } from 'lodash'
import { minLength, required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { Geometries, GeometryBase } from '@/types/common'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { ADD_TO_CACHE, REMOVE_FROM_CACHE } from '@/store/action-types'
import { FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import { AreaKeysConfig } from '@/utils/filter-editor/value-config/simple/area-keys-config'
import { Validations } from '@/decorators'
import { UiControlMixin } from './mixins/ui-control.mixin'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
import { ImportAreasResult } from '@/plugins/import-areas'
import { PickGeometryOptions } from '@/plugins/geometry-picker'
import { GeometryCore } from '@/utils/geometry-core'

@Component({
  components: { FilterParamName },
  methods: {
    ...mapActions({
      addToCache: ADD_TO_CACHE,
      removeFromCache: REMOVE_FROM_CACHE
    })
  }
})
export default class GeometriesUiElement extends Mixins<UiControlMixin<GeometryBase[]>>(UiControlMixin) implements FilterUIComponent<GeometryBase[]> {
  @Prop({ required: true }) declare config: AreaKeysConfig
  @Prop({ default: () => [] }) declare value: GeometryBase[]

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: { required, minLength: minLength(1) }
    }
  }

  get formattedGeometryItems (): GeometryBase[] {
    return uniqWith(this.selected, GeometryCore.compareGeometries)
  }

  // Store actions.
  private addToCache!: (cacheData: Record<string, unknown>) => Promise<void>
  private removeFromCache!: (geometryId: string) => Promise<void>

  private removeGeometry (geometry: GeometryBase): void {
    this.setSelected(this.selected.filter(item => item.id !== geometry.id))
    this.removeFromCache(geometry.id)
  }

  private async addGeometries (getGeometries: (options: PickGeometryOptions) => Promise<ImportAreasResult>, geometriesToMerge: Geometries): Promise<void> {
    const { submitted, geometries } = await getGeometries.call(this, { geometries: this.formattedGeometryItems as PickGeometryOptions['geometries'] })

    if (!submitted) {
      return
    }

    // Todo - do not pass id of the geometry - as there can be many geometries with the same name
    const uniqueGeometries = uniqWith(geometries, GeometryCore.compareGeometries)

    this.cacheGeometries(uniqueGeometries)

    const newSelected = uniqBy([...geometriesToMerge, ...uniqueGeometries], (geometry) => geometry.id)
      .map(geometry => pick(geometry, ['id', 'name', 'parentGeometrySetId']))

    this.setSelected(newSelected as GeometryBase[])
  }

  private cacheGeometries (geometries: Geometries): void {
    const cacheData = geometries.reduce((cacheData, geometry) => {
      cacheData[`${this.paramName}/${geometry.id}`] = geometry
      return cacheData
    }, {})

    this.addToCache(cacheData)
  }
}
