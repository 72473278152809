export enum ReportVisibility {
  Unknown = 'unknown',
  None = 'none',
  User = 'user',
  Tester = 'tester',
  Developer = 'developer'
}

export enum ReportType {
  None = 'none',
  Error = 'error',
  CriticalError = 'critical error',
  Warning = 'warning',
  Hint = 'hint',
  Information = 'information',
  Trace = 'trace',
  ToDo = 'todo',
}

export enum ReportMsgId {
  IDS_DATADELIVERY_IMPORT_COMPLETED_WITH_WARNINGS = 'IDS_DATADELIVERY_IMPORT_COMPLETED_WITH_WARNINGS',
  IDS_DATADELIVERY_IMPORT_ERROR = 'IDS_DATADELIVERY_IMPORT_ERROR',
  IDS_DATADELIVERY_IMPORT_STOP_WITH_ERRORS = 'IDS_DATADELIVERY_IMPORT_STOP_WITH_ERRORS',
  IDS_DATADELIVERY_IMPORT_SUCCESS = 'IDS_DATADELIVERY_IMPORT_SUCCESS',
  IDS_SHAPE_IMPORT_COORDINATE_ERROR = 'IDS_SHAPE_IMPORT_COORDINATE_ERROR',
  IDS_DATADELIVERY_CONTAINS_EMTPY_ROWS = 'IDS_DATADELIVERY_CONTAINS_EMTPY_ROWS',
  IDS_DATADELIVERY_IMPORT_CSV = 'IDS_DATADELIVERY_IMPORT_CSV',
  IDS_DATADELIVERY_IMPORT_MATCHING_COLUMNCOUNT = 'IDS_DATADELIVERY_IMPORT_MATCHING_COLUMNCOUNT',
  IDS_DATADELIVERY_HEADER_FOUND_IN_LINE = 'IDS_DATADELIVERY_HEADER_FOUND_IN_LINE',
  IDS_SCORING_SCORINGFUNCTION_EXCEPTION = 'IDS_SCORING_SCORINGFUNCTION_EXCEPTION',
  IDS_SCORING_SCORINGCALCULATOR_INITIALIZATION_ERROR = 'IDS_SCORING_SCORINGCALCULATOR_INITIALIZATION_ERROR'
}
