




















import { PropOptions } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { latLng, LatLngExpression, MapOptions } from 'leaflet'
import { LeafletMapAccessor } from '@/types/leaflet'
import { LMap } from 'vue2-leaflet'
import { ResizeObserver } from 'vue-resize'
import LAttributionControl from '@/components/map/controls/l-attribution-control.vue'
import { providersConfig } from '@/lib/leaflet/control/tiles-source-control/providers'

const DEFAULT_MAP_ZOOM = 10
const MIN_ALLOWED_MAP_ZOOM = 3
const ZOOM_SNAP = 0
const ZOOM_DELTA = 0.2
const WHEEL_PX_PER_ZOOM_LEVEL = 100

function defaultMapOptions (): MapOptions {
  return {
    preferCanvas: true,
    attributionControl: false,
    contextmenu: true,
    zoom: DEFAULT_MAP_ZOOM,
    minZoom: MIN_ALLOWED_MAP_ZOOM,
    zoomSnap: ZOOM_SNAP,
    zoomDelta: ZOOM_DELTA,
    wheelPxPerZoomLevel: WHEEL_PX_PER_ZOOM_LEVEL
  } as MapOptions
}

@Component({
  components: { LAttributionControl, ResizeObserver }
})
export default class MapView extends (Vue.extend(LMap) as typeof LMap) {
  @Prop({ default: defaultMapOptions }) readonly declare options: MapOptions
  @Prop({ custom: true, default: () => latLng(DEFAULT_MAP_CENTER) } as PropOptions) readonly declare center: LatLngExpression
  @Prop({ default: true }) readonly customAttribution!: boolean

  private readonly mapProviderOptions = providersConfig.mapProviders.options
    .find(provider => provider.url === DEFAULT_MAP_TILE_URL)

  private onResize (): void {
    (this.mapObject as LeafletMapAccessor)._onResize()
  }
}
