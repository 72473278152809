import { Component, Mixins } from 'vue-property-decorator'
import { Path, PolylineOptions } from 'leaflet'
import { MultiPolyline, MultiPolylineOptions } from '@/lib/leaflet/layer/multi-polygons/multi-polyline/multi-polyline'
import { MapCompProp } from '@/decorators'
import { PathsMixin } from './paths.mixin'
import { PathsColorGroupMixin } from './paths-color-group.mixin'

@Component
export class MultiPolylinesMixin<_TItem, _TLayer extends Path = MultiPolyline, TOptions extends MultiPolylineOptions = MultiPolylineOptions>
  extends Mixins<
    // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    PathsMixin<_TItem, _TLayer>,
    // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    PathsColorGroupMixin<_TItem, _TLayer, TOptions>
  >(PathsMixin, PathsColorGroupMixin) {
  @MapCompProp({ layerProp: true, default: 1.0 }) readonly smoothFactor!: number
  @MapCompProp({ layerProp: true, default: false }) readonly noClip!: boolean

  protected get polyLineOptions (): TOptions {
    return {
      ...this.pathOptions,
      smoothFactor: this.smoothFactor,
      noClip: this.noClip
    } as TOptions
  }

  protected setSmoothFactor (newVal: number): void {
    this.mapObject.setStyle({ smoothFactor: newVal } as PolylineOptions)
  }

  protected setNoClip (newVal: boolean): void {
    this.mapObject.setStyle({ noClip: newVal } as PolylineOptions)
  }
}
