import moment from '@/lib/moment'
import { ApiRequestConfig } from '@/api/types'
import { ApiClient } from '@/api/api-client'
import { BookingTimeFrame } from '@/types/planning/booking-time'
import { bookingTimeFramesResponseTransform as transformResponse } from './transform-response/booking-time-frames-response.transform'

class BookingTimeApi extends ApiClient {
  getBookingTimeFrames (startDate: Date, endDate: Date): Promise<BookingTimeFrame[]> {
    const startDateString = moment(startDate).format('YYYY-MM-DD')
    const endDateString = moment(endDate).format('YYYY-MM-DD')

    const url = `GetBookingTimeFrames/${startDateString}/${endDateString}`
    const config: ApiRequestConfig = {
      transformResponse
    }

    return this.get(url, config)
  }
}

export const bookingTimeApi = new BookingTimeApi({
  baseURL: API_CONF.api + '/api/Constants/'
})
