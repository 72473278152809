import { LAYER_ICON_SIZE } from '@/components/visualization/layer-settings/constants'
import { IconConfig, IconFillConfig } from '@/types/visualization/layer/style'
import { DataImages } from '@/types/visualization/data-images/data-images'

type IndexedImage = {
  index: string,
  img: HTMLImageElement,
  size?: number
}

const MAX_SPRITE_SIZE = 1536

/**
 * Resize image (from data url) to the given width and height and keep an aspect ratio.
 *
 * @param {String} imageDataUrl
 * @param {Number} width
 * @param {Number} height
 * @param {Boolean} keepAspectRatio
 * @returns {Promise<String>}
 */
export function resizeImageData (imageDataUrl: string, width: number, height: number, keepAspectRatio = true): Promise<string> {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  const img = new Image()

  return new Promise(resolve => {
    img.onload = () => {
      let newWidth = width || img.naturalWidth
      let newHeight = height || img.naturalHeight

      // Return image as is if it already fits given size.
      if (img.naturalWidth <= newWidth && img.naturalHeight <= newHeight) {
        return resolve(imageDataUrl)
      }

      if (keepAspectRatio) {
        newHeight = newWidth * (img.naturalHeight / img.naturalWidth)

        if (height && newHeight > height) {
          newHeight = height || img.naturalHeight
          newWidth = newHeight * (img.naturalWidth / img.naturalHeight)
        }
      }

      canvas.width = newWidth
      canvas.height = newHeight

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      resolve(canvas.toDataURL())
    }

    img.src = imageDataUrl
  })
}

/**
 * Change canvas size without changing it's content. Just extend borders.
 *
 * @param {HTMLCanvasElement} canvas
 * @param {Number} width
 * @param {Number} height
 */
export function changeCanvasSize (canvas: HTMLCanvasElement, width: number, height: number) {
  if (canvas.width === 0 || canvas.height === 0) {
    canvas.width = width
    canvas.height = height

    return
  }

  const tempCanvas = document.createElement('canvas')
  const tempCtx = tempCanvas.getContext('2d') as CanvasRenderingContext2D
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

  tempCanvas.width = canvas.width
  tempCanvas.height = canvas.height
  tempCtx.drawImage(canvas, 0, 0)

  canvas.width = width
  canvas.height = height
  ctx.drawImage(tempCanvas, 0, 0)
}

export function getImagesFromSprite (fillStyle: IconFillConfig): Promise<DataImages> {
  return new Promise(resolve => {
    const dataImages: DataImages = {}

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const img = new Image()

    img.onload = () => {
      Object.entries(fillStyle.icon?.spriteIndex ?? {}).forEach(([value, indexData]) => {
        canvas.width = indexData.w
        canvas.height = indexData.h

        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(
          img,
          indexData.x, indexData.y, indexData.w, indexData.h,
          0, 0, indexData.w, indexData.h
        )

        dataImages[value] = {
          src: canvas.toDataURL(),
          size: indexData.size
        }
      })

      resolve(dataImages)
    }

    img.src = fillStyle.icon?.spriteImage ?? ''
  })
}

/**
 *
 * @param dataImages<Array>
 * @returns {Promise<{spriteImage: String, spriteIndex: Object}>}
 */

export function redrawAndReturnSprite (dataImages: DataImages): Promise<Partial<IconConfig>> {
  const spriteIndex = {}
  const canvas = document.createElement('canvas')
  canvas.width = 0
  canvas.height = 0

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

  let [x, y] = [0, 0]

  const images = Object.entries(dataImages).reduce((images, [index, imageData]) => {
    if (!imageData.src) {
      return images
    }

    const promise: Promise<IndexedImage> = new Promise(resolve => {
      const img = new Image()

      img.onload = () => {
        return resolve({ index, img, size: imageData.size || LAYER_ICON_SIZE })
      }

      img.src = imageData.src
    })

    return images.concat([promise])
  }, [] as Promise<IndexedImage>[])

  return Promise.all(images).then(images => {
    images.forEach(({ index, img, size }) => {
      spriteIndex[index] = { x, y, w: img.naturalWidth, h: img.naturalHeight, size }

      changeCanvasSize(canvas, Math.max(canvas.width, x + img.naturalWidth), Math.max(canvas.height, y + img.naturalHeight))

      ctx.drawImage(img, x, y)

      x += img.naturalWidth

      if (x > MAX_SPRITE_SIZE) {
        x = 0
        y = canvas.height
      }
    })

    return { spriteIndex, spriteImage: canvas.toDataURL() }
  })
}
