import { freezeRecursive } from '@/utils/object'
import { getDefaultQuery } from '@/utils/query-builder'
import { Dataset, DatasetRows } from '@/types/common'
import { QueryGroup } from '@/types/query-builder'
import { RANKING_IMPORT_WIZARD_RESULT_TABLE } from '@/store/ranking-import-wizard/getters'
import {
  RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_PLZ_COLUMN,
  RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_RATING_COLUMN,
  RANKING_IMPORT_WIZARD_SET_DATASET,
  RANKING_IMPORT_WIZARD_SET_QUERY_DATA
} from '@/store/ranking-import-wizard/mutations'

export const RANKING_IMPORT_WIZARD_CHANGE_DATASET = 'ranking-import/changeDataset'
export const RANKING_IMPORT_WIZARD_RESET = 'ranking-import/resetWizard'
export const RANKING_IMPORT_WIZARD_CHANGE_QUERY_DATA = 'ranking-import/changeQuery'
export const RANKING_IMPORT_WIZARD_RESET_QUERY_DATA = 'ranking-import/resetQuery'
export const RANKING_IMPORT_WIZARD_GET_RESULTS = 'ranking-import/getResults'
export const RANKING_IMPORT_WIZARD_KEY_COLUMNS_RESET = 'ranking-import/reset'

export const actions = {
  async [RANKING_IMPORT_WIZARD_RESET] ({ dispatch }): Promise<void> {
    await dispatch(RANKING_IMPORT_WIZARD_CHANGE_DATASET, null)
    await dispatch(RANKING_IMPORT_WIZARD_RESET_QUERY_DATA)
    await dispatch(RANKING_IMPORT_WIZARD_KEY_COLUMNS_RESET)
  },
  async [RANKING_IMPORT_WIZARD_CHANGE_DATASET] ({ commit, dispatch }, dataset: Dataset | null) {
    if (dataset?.datasetRows) {
      freezeRecursive(dataset.datasetRows)
    }

    await dispatch(RANKING_IMPORT_WIZARD_RESET_QUERY_DATA)
    commit(RANKING_IMPORT_WIZARD_SET_DATASET, dataset)
  },
  [RANKING_IMPORT_WIZARD_CHANGE_QUERY_DATA] ({ commit }, query: QueryGroup) {
    commit(RANKING_IMPORT_WIZARD_SET_QUERY_DATA, query)
  },
  [RANKING_IMPORT_WIZARD_RESET_QUERY_DATA] ({ commit }) {
    commit(RANKING_IMPORT_WIZARD_SET_QUERY_DATA, getDefaultQuery())
  },
  async [RANKING_IMPORT_WIZARD_KEY_COLUMNS_RESET] ({ commit }) {
    commit(RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_PLZ_COLUMN, null)
    commit(RANKING_IMPORT_WIZARD_KEY_COLUMNS_SET_RATING_COLUMN, null)
  },
  [RANKING_IMPORT_WIZARD_GET_RESULTS] ({ getters }): DatasetRows {
    return getters[RANKING_IMPORT_WIZARD_RESULT_TABLE]
  }
}
