import { Evented, latLngBounds, LatLngBoundsExpression, LatLngExpression, Util } from 'leaflet'
import { ObjectId } from '@/types/common'

interface Suggestion {
  text: string,
  attr: string[],
  id: ObjectId,
  coordinates?: LatLngExpression[]
}

export const SearchCore = Evented.extend({
  suggestions: [],
  options: {
    zoomToResult: true,
    searchBounds: null,
    searchCallback: null,
    uploadCoordinatesCallback: null
  },

  initialize (control, options): void {
    Util.setOptions(this, options)
    this._control = control
  },

  _search (query: string): Suggestion[] {
    if (!(this.options.searchCallback instanceof Function)) {
      return []
    }

    return this.options.searchCallback(query)
      .then(results => {
        this.suggestions = results
        this._control._finalizeSuggestions(false, results)
        this._control._renderSuggestions(results)
      })
  },

  _clearSuggestions (): void {
    this.suggestions = []
  },

  _boundsFromCoordinates (coordinates: Suggestion['coordinates']): LatLngBoundsExpression | undefined {
    if (!coordinates?.length) {
      return
    }

    return latLngBounds(coordinates)
  },

  _getSuggestionById (id: string): Suggestion {
    return this.suggestions.find(suggestion => suggestion.id === id)
  },

  async _uploadCoordinatesToSuggestion (suggestion: Suggestion): Promise<void> {
    if (this.options.uploadCoordinatesCallback instanceof Function) {
      const coordinates = await this.options.uploadCoordinatesCallback(suggestion.id)
      this.searchBounds = this._boundsFromCoordinates(coordinates)
      this._flyToSelectedSuggestion()

      this.fire('load')
    }
  },

  _flyToSelectedSuggestion (): void {
    if (this.searchBounds.isValid()) {
      this._control._map.flyToBounds(this.searchBounds)
    }
  }
})

export function searchCore (control, options) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new SearchCore(control, options)
}
