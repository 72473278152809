import {
  NEW_VISUALIZATION_WIZARD_VISIBLE,
  NEW_VISUALIZATION_WIZARD_PLANS,
  NEW_VISUALIZATION_WIZARD_NAME,
  NEW_VISUALIZATION_NAMESPACE,
  NEW_VISUALIZATION_LINK_NAMESPACE,
  NEW_VISUALIZATION_WIZARD_ENTRYPOINT,
  NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_CUSTOM_VISUALIZATION,
  NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_VIS_FROM_PLAN
} from '@/store/getter-types'
import {
  ENTRYPOINT_CUSTOM_VISUALIZATION,
  ENTRYPOINT_VIS_FROM_PLAN
} from './constants/entrypoints'

export default {
  [NEW_VISUALIZATION_WIZARD_VISIBLE] (state) {
    return state.visible
  },
  [NEW_VISUALIZATION_WIZARD_PLANS] (state) {
    return state.plans
  },
  [NEW_VISUALIZATION_WIZARD_NAME] (state) {
    return state.name
  },
  [NEW_VISUALIZATION_NAMESPACE] (state) {
    return state.wizardNamespace
  },
  [NEW_VISUALIZATION_LINK_NAMESPACE] (state, getters) {
    return (getter) => {
      return getters[NEW_VISUALIZATION_NAMESPACE] + '/' + getter
    }
  },
  [NEW_VISUALIZATION_WIZARD_ENTRYPOINT] (state) {
    return state.entrypoint
  },
  [NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_CUSTOM_VISUALIZATION] (state, getters) {
    return getters[NEW_VISUALIZATION_WIZARD_ENTRYPOINT] === ENTRYPOINT_CUSTOM_VISUALIZATION
  },
  [NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_VIS_FROM_PLAN] (state, getters) {
    return getters[NEW_VISUALIZATION_WIZARD_ENTRYPOINT] === ENTRYPOINT_VIS_FROM_PLAN
  }
}
