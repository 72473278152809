import { quantileSorted } from 'd3-array'
import { FILL_MODE_CONFIGS } from '@/components/visualization/layer-settings/constants'
import { getDataRows } from './data-rows'
import { getMainLayerFillOptions } from '@/utils/visualization/style'

export function getQuantilesForFillMode (fillMode) {
  const quantiles = FILL_MODE_CONFIGS[fillMode].quantiles

  if (Array.isArray(quantiles)) {
    return [0, ...quantiles]
  }

  return [0]
}

export function getQuantileValues (dataRows, quantileColumn, quantiles) {
  const values = dataRows.map(row => row.meta[quantileColumn]).sort((a, b) => a - b)

  return quantiles.map(p => quantileSorted(values, p))
}

export function getLayerQuantileValues (layer) {
  const fillOptions = getMainLayerFillOptions(layer)
  const fillMode = fillOptions.type

  if (!FILL_MODE_CONFIGS[fillMode].quantiles) {
    throw new Error(`Fill mode ${fillMode} is not a quantile.`)
  }

  const dataRows = getDataRows(layer)
  const quantiles = getQuantilesForFillMode(fillMode)

  return getQuantileValues(dataRows, fillOptions.gradientColumn, quantiles)
}
