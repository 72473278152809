import Vue from 'vue'
import { get, uniq } from 'lodash'
import { FillType } from '@/types/visualization/layer/enums'
import { getDataRows, getMainLayerFillOptions, getMainLayerStyleProp } from '@/utils/visualization'
import { formatLegendValue } from '@/components/visualization/tiptap/utils'
import { fillModeSupportsSubLayers } from '@/components/visualization/layer-settings/utils'
import SublayerObjectsMixin from '@/components/visualization/layers-tree/mixins/sublayer-objects.mixin'
import { BORDER_LEGEND, FILL_LEGEND } from '@/components/visualization/legend/constants'

function subLayersToIcon (layer) {
  const keyColumn = layer.style.fill.gradientColumn
  return uniq(getDataRows(layer).map(geometry => get(geometry, ['meta', keyColumn])))
    .map(key => ({
      id: key,
      layer,
      draggable: false,
      sublayerLabel: key,
      header: 'sublayer'
    }))
}

function defaultSubLayers (layer) {
  const LayerValues = Vue.extend({
    mixins: [SublayerObjectsMixin],
    render: () => ''
  })

  const instance = new LayerValues({
    propsData: {
      layer,
      legendType: getLegendType(layer),
      legendValueFormatter: (value) => formatLegendValue(value, 2)
    }
  }).$mount()

  return instance.legendValues.map(({ label, key, color }) => {
    return {
      id: key,
      layer,
      draggable: false,
      sublayerLabel: label,
      header: 'sublayer',
      color: color,
      quantityOfObjects: instance.getQuantityOfObjectsInSublayer(key)
    }
  })
}

function getLegendType (layer) {
  const styleProp = getMainLayerStyleProp(layer)

  switch (styleProp) {
    case 'path':
      return BORDER_LEGEND
    case 'fill':
    default:
      return FILL_LEGEND
  }
}

export function getSubLayers (layer) {
  const fillOptions = getMainLayerFillOptions(layer)

  if (!fillModeSupportsSubLayers(fillOptions.type)) {
    return []
  }

  if (!fillOptions.showSubLayers) {
    return []
  }

  if (fillOptions.type === FillType.Icon && get(fillOptions, 'icon.useDefault', false)) {
    return []
  }

  return fillOptions.type === FillType.Icon ? subLayersToIcon(layer) : defaultSubLayers(layer)
}

export function isFiltered (layer) {
  return layer.geometry.length !== getDataRows(layer).length
}
