import { LayerType } from '@/types/visualization/layer/enums'
import { parseShapesLayer } from './shapes-layer'
import { parseCirclesLayer } from './circles-layer'
import { parsePointsLayer } from './points-layer'

const LAYER_TYPE_PARSERS = {
  [LayerType.Points]: parsePointsLayer,
  [LayerType.Shapes]: parseShapesLayer,
  [LayerType.Circles]: parseCirclesLayer
}

export function parseLayer (layer) {
  const parser = LAYER_TYPE_PARSERS[layer.type]

  if (parser) {
    return parser(layer)
  }

  throw new Error(`Unsupported layer type ${layer.type}.`)
}

export function parseLayers (layers) {
  return layers.map(parseLayer)
}
