import entrypointTab from './tabs/new-visualization-wizard-tab-entrypoint.store'
import plansTab from './tabs/new-visualization-wizard-tab-plans.store'
import propsTab from './tabs/new-visualization-wizard-tab-props.store'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    entrypointTab,
    plansTab,
    propsTab
  }
}
