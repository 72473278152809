import { cloneDeep, get, intersection, intersectionBy, isEqual, uniq } from 'lodash'
import {
  BOOKING_TIME_VALUES,
  PLAN_WIZARD_FILTER_BY_ID,
  PLAN_WIZARD_GET_BOOKING_TIME_BY_MEDIA_TYPE,
  PLAN_WIZARD_SELECTED_FLIGHTS,
  SITE_TYPES,
  WIZARD_STEP_FLIGHTS_ALL_AREAS_HAVE_FLIGHTS,
  WIZARD_STEP_FLIGHTS_ARE_MEDIA_TYPES_SYNCHRONIZED,
  WIZARD_STEP_FLIGHTS_AVAILABLE_BOOKING_TIME,
  WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA,
  WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA_TYPE,
  WIZARD_STEP_FLIGHTS_GET_FLIGHTS_FOR_MEDIA_TYPES,
  WIZARD_STEP_FLIGHTS_LOCATION_HAS_FLIGHTS,
  WIZARD_STEP_FLIGHTS_MON_TYPES_CAN_BE_SYNCHRONIZED,
  WIZARD_STEP_FLIGHTS_SINGLE_UNIT_TYPES_CAN_BE_SYNCHRONIZED,
  WIZARD_STEP_FLIGHTS_MEDIA_TYPES_CAN_BE_SYNCHRONIZED,
  WIZARD_STEP_FLIGHTS_NETWORK_TYPES_PER_AREA,
  WIZARD_STEP_FLIGHTS_SELECTED_FLIGHTS,
  WIZARD_STEP_FLIGHTS_SINGLE_TYPES_PER_AREA,
  WIZARD_STEP_FLIGHTS_TYPE_PER_AREA,
  WIZARD_STEP_LOCATION_SELECTED_LOCATIONS,
  WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES,
  PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA
} from '@/store/getter-types'
import {
  PLAN_WIZARD_ADD_FILTER,
  PLAN_WIZARD_SET_FILTER_DATA,
  WIZARD_STEP_FLIGHTS_CREATE_FLIGHT,
  WIZARD_STEP_FLIGHTS_INIT_FLIGHTS_FOR_LOCATIONS,
  WIZARD_STEP_FLIGHTS_REFRESH_FLIGHTS_DATA,
  WIZARD_STEP_FLIGHTS_SYNC_MEDIA_FLIGHTS,
  WIZARD_STEP_FLIGHTS_UPDATE_FLIGHT,
  WIZARD_STEP_FLIGHTS_UPDATE_SYNC_FLIGHTS
} from '@/store/action-types'
import { PLAN_WIZARD_REPLACE_FILTER_NODE } from '@/store/mutation-types'
import { FILTER_AVAILABILITY } from '@/constants/scoring'
import { isNetworkMediaTypeFilterNode, isSingleMediaTypeFilterNode } from '@/utils/plan-wizard-steps'
import { fixEndDateInTimeFrame } from '@/utils/booking-time'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { BookingTimeFrame } from '@/types/planning/booking-time'
import { SiteTypeItem } from '@/types/planning/wizard/filter-editor'
import { Dataset } from '@/types/common'

function getTimeFramesFromFlightNode (flightNode: ScoringFunctionNode): BookingTimeFrame[] {
  return get(flightNode, 'scoringFunction.data.0.BookingTimeFrames', [] as BookingTimeFrame[])
}

function getTimeFrameValuesFromFlightNode (flightNode: ScoringFunctionNode): string[] {
  return getTimeFramesFromFlightNode(flightNode)
    .map((frame) => frame.value)
    .sort()
}

// initial state
const state = {}

// getters
const getters = {
  [WIZARD_STEP_FLIGHTS_SELECTED_FLIGHTS] (_state, getters): ScoringFunctionNode[] {
    return getters[PLAN_WIZARD_SELECTED_FLIGHTS]
  },
  [WIZARD_STEP_FLIGHTS_TYPE_PER_AREA] (_state, getters): ScoringFunctionNode[] {
    return getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES]
  },
  [WIZARD_STEP_FLIGHTS_SINGLE_TYPES_PER_AREA] (_state, getters): (locationId: string) => ScoringFunctionNode[] {
    return (locationId) => {
      const typeNodes = getters[WIZARD_STEP_FLIGHTS_TYPE_PER_AREA](locationId)
      return typeNodes.filter(isSingleMediaTypeFilterNode)
    }
  },
  [WIZARD_STEP_FLIGHTS_NETWORK_TYPES_PER_AREA] (_state, getters): (locationId: string) => ScoringFunctionNode[] {
    return (locationId) => {
      const typeNodes = getters[WIZARD_STEP_FLIGHTS_TYPE_PER_AREA](locationId)
      return typeNodes.filter(isNetworkMediaTypeFilterNode)
    }
  },
  [WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA] (_state, getters): (locationId: string) => ScoringFunctionNode[] {
    return (locationId) => {
      return getters[PLAN_WIZARD_SELECTED_FLIGHTS].filter(flight => flight.areaId === locationId)
    }
  },
  [WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA_TYPE] (_state, getters): (type: ScoringFunctionNode) => ScoringFunctionNode {
    return (type) => {
      return getters[WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA](type.areaId).find(flight => flight.typeId === type.id)
    }
  },
  [WIZARD_STEP_FLIGHTS_GET_FLIGHTS_FOR_MEDIA_TYPES] (_state, getters): (mediaTypes: ScoringFunctionNode[]) => ScoringFunctionNode[] {
    return (mediaTypes) => {
      const getMediaTypeFlights = getters[WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA_TYPE]
      return mediaTypes.reduce((accum, mediaType) => [...accum, getMediaTypeFlights(mediaType)], [] as ScoringFunctionNode[])
    }
  },
  [WIZARD_STEP_FLIGHTS_ARE_MEDIA_TYPES_SYNCHRONIZED] (_state, getters): (mediaTypes: ScoringFunctionNode[]) => boolean {
    return (mediaTypes) => {
      const flightNodes = getters[WIZARD_STEP_FLIGHTS_GET_FLIGHTS_FOR_MEDIA_TYPES](mediaTypes) as ScoringFunctionNode[]

      if (flightNodes.length !== mediaTypes.length) {
        return false
      }

      const values = getTimeFrameValuesFromFlightNode(flightNodes[0])

      return flightNodes.every((flightNode) => isEqual(getTimeFrameValuesFromFlightNode(flightNode), values))
    }
  },
  [WIZARD_STEP_FLIGHTS_SINGLE_UNIT_TYPES_CAN_BE_SYNCHRONIZED] (_state, getters): (mediaTypes: ScoringFunctionNode[]) => boolean {
    return (mediaTypes) => {
      const allSiteTypes = (getters[SITE_TYPES] as SiteTypeItem[])

      const originalSiteTypes = allSiteTypes
        .filter(siteType => mediaTypes
          .map(type => type.name)
          .includes(siteType.value as string)
        )

      const allBookingTypes = originalSiteTypes.map(siteType => siteType.bookingTypes)
      return intersection(...allBookingTypes).length > 0
    }
  },
  [WIZARD_STEP_FLIGHTS_MON_TYPES_CAN_BE_SYNCHRONIZED] (_state, getters): (mediaTypes: ScoringFunctionNode[]) => boolean {
    return (mediaTypes) => {
      // Took areaId from the first media type as all types have similar parent filter
      const areaId = mediaTypes[0].areaId
      const dataset = getters[PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA](areaId) as Dataset
      const networkIds = mediaTypes.map(type => get(type.scoringFunction.data[0], ['MediaOwnerNetworks', '0', 'id'], '') as string)

      const bookingGranularities = dataset.datasetRows
        .filter(row => networkIds.includes(row.ExternalID as string))
        .map(row => row.BookingGranularity as string)

      return uniq(bookingGranularities).length < 2
    }
  },
  [WIZARD_STEP_FLIGHTS_MEDIA_TYPES_CAN_BE_SYNCHRONIZED] (_state, getters): (mediaTypes: ScoringFunctionNode[]) => boolean {
    return (mediaTypes) => {
      if (mediaTypes.length < 2) {
        return false
      }

      if (mediaTypes.some(type => isNetworkMediaTypeFilterNode(type))) {
        return getters[WIZARD_STEP_FLIGHTS_MON_TYPES_CAN_BE_SYNCHRONIZED](mediaTypes)
      }

      return getters[WIZARD_STEP_FLIGHTS_SINGLE_UNIT_TYPES_CAN_BE_SYNCHRONIZED](mediaTypes)
    }
  },
  [WIZARD_STEP_FLIGHTS_LOCATION_HAS_FLIGHTS] (_state, getters): (node: ScoringFunctionNode) => boolean {
    return ({ id }) => {
      const areaFlights = getters[WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA](id) as ScoringFunctionNode[]
      const areaTypes = getters[WIZARD_STEP_FLIGHTS_TYPE_PER_AREA](id) as ScoringFunctionNode[]

      const hasFlights = areaFlights.length !== 0
      const flightsAssigned = areaFlights.length === areaTypes.length
      const flightsNotEmpty = areaFlights.every(flight => getTimeFramesFromFlightNode(flight).length !== 0)

      return hasFlights && flightsAssigned && flightsNotEmpty
    }
  },
  [WIZARD_STEP_FLIGHTS_ALL_AREAS_HAVE_FLIGHTS] (_state, getters): boolean {
    const selectedLocations = getters[WIZARD_STEP_LOCATION_SELECTED_LOCATIONS] as ScoringFunctionNode[]

    return selectedLocations.length === 0 || selectedLocations.every(area => {
      return getters[WIZARD_STEP_FLIGHTS_LOCATION_HAS_FLIGHTS](area)
    })
  },
  [WIZARD_STEP_FLIGHTS_AVAILABLE_BOOKING_TIME] (_state, getters): (typeNode: ScoringFunctionNode) => BookingTimeFrame[] {
    return (typeNode) => {
      const timeValues = getters[PLAN_WIZARD_GET_BOOKING_TIME_BY_MEDIA_TYPE](typeNode).map(time => time.value)
      return getters[BOOKING_TIME_VALUES](timeValues)
    }
  }
}

// actions
const actions = {
  [WIZARD_STEP_FLIGHTS_CREATE_FLIGHT] ({ dispatch }, { type, data }): Promise<void> {
    const filter = {
      name: type.name,
      areaId: type.areaId,
      typeId: type.id,
      scoringFunction: {
        name: FILTER_AVAILABILITY,
        data: [data]
      }
    }

    return dispatch(PLAN_WIZARD_ADD_FILTER, filter)
  },
  [WIZARD_STEP_FLIGHTS_UPDATE_FLIGHT] ({ commit, getters }, flightNode: ScoringFunctionNode): void {
    const oldNode = getters[PLAN_WIZARD_FILTER_BY_ID](flightNode.id)

    commit(PLAN_WIZARD_REPLACE_FILTER_NODE, {
      oldNode,
      newNode: flightNode
    })
  },
  [WIZARD_STEP_FLIGHTS_REFRESH_FLIGHTS_DATA] ({ getters, dispatch }): Promise<void[]> {
    const promises = [] as Promise<void>[]

    getters[WIZARD_STEP_FLIGHTS_SELECTED_FLIGHTS].forEach((flightNode: ScoringFunctionNode) => {
      const flightTimes = getTimeFramesFromFlightNode(flightNode)
      const typeNode = getters[PLAN_WIZARD_FILTER_BY_ID](flightNode.typeId)
      const updatedTimes = getters[WIZARD_STEP_FLIGHTS_AVAILABLE_BOOKING_TIME](typeNode)

      const bookedTimes = flightTimes.filter(time => {
        return updatedTimes.some(item => item.value === time.value)
      })

      promises.push(dispatch(PLAN_WIZARD_SET_FILTER_DATA, {
        query: { id: flightNode.id },
        name: flightNode.name,
        data: [{
          BookingTimeFrames: bookedTimes
        }]
      }))
    })

    return Promise.all(promises)
  },
  [WIZARD_STEP_FLIGHTS_INIT_FLIGHTS_FOR_LOCATIONS] ({ getters, dispatch }): Promise<void[]> {
    const promises = [] as Promise<void>[]

    getters[WIZARD_STEP_LOCATION_SELECTED_LOCATIONS].forEach((location: ScoringFunctionNode) => {
      const typesByArea = getters[WIZARD_STEP_FLIGHTS_TYPE_PER_AREA](location.id)

      typesByArea.forEach(typeNode => {
        const typeHasFlights = Boolean(getters[WIZARD_STEP_FLIGHTS_FLIGHTS_PER_AREA_TYPE](typeNode))

        if (!typeHasFlights) {
          const timeFrames = getters[WIZARD_STEP_FLIGHTS_AVAILABLE_BOOKING_TIME](typeNode) as BookingTimeFrame[]

          // SW-1316 reverting 1ms subtraction done in booking-time-frames response transformer
          const flightsData = {
            BookingTimeFrames: timeFrames.map(flight => ({ ...flight, end: fixEndDateInTimeFrame(flight.end) }))
          }

          promises.push(dispatch(WIZARD_STEP_FLIGHTS_CREATE_FLIGHT, {
            type: typeNode,
            data: flightsData
          }))
        }
      })
    })

    return Promise.all(promises)
  },
  async [WIZARD_STEP_FLIGHTS_SYNC_MEDIA_FLIGHTS] ({ dispatch, getters }, mediaTypes: ScoringFunctionNode[]): Promise<void> {
    const flightNodes = getters[WIZARD_STEP_FLIGHTS_GET_FLIGHTS_FOR_MEDIA_TYPES](mediaTypes) as ScoringFunctionNode[]
    const commonTimeFrames = intersectionBy(...flightNodes.map(getTimeFramesFromFlightNode), 'value')
    const commonTimeFrameValues = commonTimeFrames.map((frame) => frame.value).sort()

    for await (const flightNode of flightNodes) {
      if (isEqual(commonTimeFrameValues, getTimeFrameValuesFromFlightNode(flightNode))) {
        // Do not trigger unnecessary updates.
        continue
      }

      const synchronizedFlight = cloneDeep(flightNode)
      synchronizedFlight.scoringFunction.data = [{
        BookingTimeFrames: cloneDeep(commonTimeFrames)
      }]
      await dispatch(WIZARD_STEP_FLIGHTS_UPDATE_FLIGHT, synchronizedFlight)
    }
  },
  async [WIZARD_STEP_FLIGHTS_UPDATE_SYNC_FLIGHTS] ({ dispatch, getters }, { mediaTypes, data }): Promise<void> {
    const flightNodes = getters[WIZARD_STEP_FLIGHTS_GET_FLIGHTS_FOR_MEDIA_TYPES](mediaTypes) as ScoringFunctionNode[]

    for await (const oldFlightNode of flightNodes) {
      const newFlightNode = cloneDeep(oldFlightNode)
      newFlightNode.scoringFunction.data = cloneDeep(data)

      await dispatch(WIZARD_STEP_FLIGHTS_UPDATE_FLIGHT, newFlightNode)
    }
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
