

































import { Component, Mixins, Ref } from 'vue-property-decorator'
import { FileMixin } from '@/mixins/file.mixin'
import { acceptFileFilters, EXCEL_FILE_TYPES, readExcelFile } from '@/utils/file'
import FileUpload from '@/components/file-upload/file-upload.vue'
import { FormInputSize, FormInputState } from '@/types/bootstrap'
import { ExcelRow, Geometries } from '@/types/common'
import { ValidatableModalComponent } from '@/plugins/modal-dialog/types'
import ImportAreasResults from '@/plugins/import-areas/import-areas-results.vue'
import { Validations } from '@/decorators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { required } from 'vuelidate/lib/validators'
import { ValidationProviderMixin } from '@/mixins/validation/validation-provider.mixin'
import ImportAreasSettings from '@/plugins/import-areas/import-areas-settings.vue'

@Component({
  components: { ImportAreasSettings, ImportAreasResults, FileUpload }
})
export default class ImportAreas extends Mixins(FileMixin, ValidationProviderMixin) implements ValidatableModalComponent {
  @Ref('importer') readonly importComponent!: ImportAreasSettings

  private size: FormInputSize = 'sm'
  private fileData: ExcelRow[] | null = null
  geometries: Geometries = []

  data () {
    return { ...acceptFileFilters(EXCEL_FILE_TYPES) }
  }

  @Validations()
  protected validations (): RuleDecl {
    return {
      fileData: { required }
    }
  }

  private get fileRows (): ExcelRow[] {
    return this.fileData ?? []
  }

  private get fileInputState (): FormInputState {
    return this.validationModelToInputState(this.$v.fileData)
  }

  private get invalidFileFeedback (): string {
    return this.$t('validation.errors.required', {
      attribute: this.$t('common.areas-import.file-label')
    }) as string
  }

  private async importFile (file: File): Promise<void> {
    this.file = file

    if (!file) {
      this.fileData = null
      return
    }

    try {
      this.fileData = await readExcelFile(file)
    } catch (e) {
      typeof e === 'string' && this.$toastError(e)
    }
  }

  protected fileUploadCallback (): Promise<boolean> {
    return Promise.resolve(true)
  }

  async validateModal (): Promise<boolean> {
    this.$v.$touch()

    const importValid = this.importComponent.validateComponent()

    if (this.$v.$invalid || !importValid) {
      return false
    }

    if (this.geometries.length === 0) {
      this.$toastError(this.$t('common.areas-import.areas-import-need-to-import') as string)
      return false
    }

    return true
  }
}
