import { Component, Prop, Vue } from 'vue-property-decorator'
import { FormInputSize } from '@/types/bootstrap'
import { ConfiguredRule } from '../types'

@Component
export class QueryCompMixin extends Vue {
  @Prop({ required: true }) readonly rules!: ConfiguredRule[]
  @Prop({ required: true }) readonly depth!: number
  @Prop({ required: true }) readonly maxDepth!: number
  @Prop({ required: true }) readonly size!: FormInputSize
}
