import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { DatasetRows } from '@/types/common'

@Component
export class ComponentSettingsMixin extends Vue {
  @Prop({ default: () => [] }) tableData!: DatasetRows
  @Prop({ default: false }) saveTableColumns!: boolean

  get columns (): string[] {
    return this.tableData ? Object.keys(this.tableData[0] || {}) : []
  }

  @Watch('tableData')
  onTableDataChange (): void {
    this.resetComponentData()
  }

  resetComponentData () { /* */ }
}
