import { PluginObject } from 'vue'
import RouteLoadingService from '@/plugins/route-loading/service'
import RouteLoadingOverlay from '@/plugins/route-loading/route-loading-overlay.vue'
import { VueRouter } from 'vue-router/types/router'

type RouteLoadingPluginProps = {
  router: VueRouter,
  delay: number
}

export class RouteLoadingPlugin implements PluginObject<RouteLoadingPluginProps> {
  install (Vue, params?: RouteLoadingPluginProps) {
    const { router, delay } = params as RouteLoadingPluginProps

    const loading = new RouteLoadingService(RouteLoadingOverlay, delay, Vue)

    if (router) {
      router.beforeEach((_to, _from, next) => {
        loading.start()
        next()
      })
      router.beforeResolve((_to, _from, next) => {
        loading.stop()
        next()
      })
    }
  }
}
