






























import { Component, Mixins } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import { acceptFileFilters, EXCEL_FILE_TYPES, FileTypeConfigs } from '@/utils/file'
import SearchableDatasetList from '@/components/shared/searchable-dataset-list/searchable-dataset-list.vue'
import FileProcess from '@/components/file-process/file-process.vue'
import { ReadExcelFileMixin } from '@/mixins/read-file.mixin'
import { getColumnInfosFromData } from '@/utils/dataset'
import { ExcelRow } from '@/types/common'
import { RANKING_IMPORT_WIZARD_CHANGE_DATASET } from '@/store/ranking-import-wizard/actions'
import { LocalTranslations } from '@/decorators/local-translations'
import messages from './locales/locales'

const fileFilters = acceptFileFilters(EXCEL_FILE_TYPES)

@Component({
  components: { FileProcess, SearchableDatasetList },
  methods: {
    ...mapActions({
      setDataset: RANKING_IMPORT_WIZARD_CHANGE_DATASET
    })
  }
})
@LocalTranslations(messages)
export default class SetDatasetStep extends Mixins<ReadExcelFileMixin>(ReadExcelFileMixin) {
  protected override allowedTypes: string[] = fileFilters.allowedTypes
  protected override allowedExtensions: string[] = fileFilters.allowedExtensions

  private fileTypes: FileTypeConfigs = EXCEL_FILE_TYPES
  private datasetLoading = false

  private setDataset!: ({ datasetRows, columnInfos }) => Promise<void>

  fileUploadCallback (fileContent): Promise<void> {
    this.file = fileContent.file

    return this.readExcelFile(this.file).then((result: ExcelRow[]) => {
      return this.setDataset({ datasetRows: result, columnInfos: getColumnInfosFromData(result) })
    })
  }
}
