




































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { BFormFile } from 'bootstrap-vue'
import { FormInputState } from '@/types/bootstrap'

@Component
export default class FileUploadZone extends Vue {
  @Prop({ default: '' }) fileAccept!: string
  @Prop({ default: false }) multiple!: boolean
  @Prop({ default: null }) state!: FormInputState

  @Ref('fileUpload') readonly fileInput!: BFormFile

  file: File | File[] | null = null
  isDragging = false

  get zoneClass (): { [key: string]: boolean } {
    return {
      'is-dragging': this.isDragging,
      invalid: this.state === false,
      valid: this.state === true
    }
  }

  @Watch('file')
  onFileChange (value): void {
    this.$emit('upload-files', value)
  }

  dropFile (e: DragEvent): void {
    this.isDragging = false
    const files = Array.from(e.dataTransfer?.files ?? [])
    this.file = this.multiple ? files : files[0]
  }

  inputClick (): void {
    (this.fileInput.$refs.input as HTMLInputElement).click()
  }
}
