










import Draggable from 'vuedraggable'
import { animationDuration } from '@/assets/style-variables'
import LegendValuesTable from '@/components/visualization/legend/legend-views/legend-values-table.vue'
import { sortLegendValues } from '@/components/visualization/tiptap/utils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LegendValues } from '@/types/visualization/map-legend'
import { SortType } from '@/types/enums'
import { VueComponentDeclaration, VueInstanceListeners, VueInstanceProps } from '@/types/vue'

@Component({
  components: { LegendValuesTable }
})
export default class DraggableLegendValues extends Vue {
  @Prop({ required: true }) private readonly legendValues!: LegendValues
  @Prop({ required: true }) private readonly sortOrder!: string[] | SortType

  private readonly valuesWrapper: VueComponentDeclaration = Draggable

  private get sortedLegendValues (): LegendValues {
    return sortLegendValues(this.legendValues, this.sortOrder)
  }

  private get draggableOptions (): VueInstanceProps {
    return {
      draggable: '.legend__item',
      handle: '.legend__color',
      group: 'legend-values',
      animation: animationDuration,
      revertOnSpill: true,
      value: this.sortedLegendValues
    }
  }

  private get draggableListeners (): VueInstanceListeners {
    return {
      change: (e) => {
        if (e.moved) {
          this.moveLegendValue(e.moved)
        }
      }
    }
  }

  private moveLegendValue ({ element, oldIndex, newIndex }): void {
    const items = this.sortedLegendValues.slice()
    items.splice(oldIndex, 1)
    items.splice(newIndex, 0, element)
    this.$emit('update:sort-order', items.map(item => item.label))
  }
}
