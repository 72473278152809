/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator'
import { cloneDeep, defaultsDeep, merge, pick } from 'lodash'
import { GridOptions } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue'
import GridLoadingOverlay from '../components/grid-loading-overlay.vue'
import { defaultColumnTypes } from '@/components/shared/ag-grid/constants/column-types'

@Component({
  model: cloneDeep((AgGridVue as any).options.model),
  props: cloneDeep((AgGridVue as any).options.props)
})
export class GridPropsMixin extends Vue {
  @Prop({ default: false }) quickFilter!: boolean

  defaultGridProps: GridOptions = {
    suppressScrollOnNewData: false,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    columnTypes: defaultColumnTypes,
    defaultColDef: {
      filter: 'agMultiColumnFilter'
    }
  }

  forceGridProps: GridOptions = {
    frameworkComponents: {
      agLoadingOverlay: GridLoadingOverlay
    },
    suppressPaginationPanel: true
  }

  get gridProps (): GridOptions {
    const gridPropKeys = Object.keys((AgGridVue as any).options.props)
    const gridProps = defaultsDeep(pick(this.$props, gridPropKeys), this.defaultGridProps)

    // We have some props that must be fixed. Override them here.
    merge(gridProps, this.forceGridProps)

    return gridProps
  }
}
