
import { Component, Mixins } from 'vue-property-decorator'
import { AgEvent, ICellRendererParams } from 'ag-grid-community'
import { CellRendererMixin } from '@/components/shared/ag-grid/mixins/grid-components/cell-renderer.mixin'
import { CreateElement, VNode } from 'vue'
import { ExternalId, ScoringFunctionId, UnclearAssignmentVariant } from '@/types/planning/unclear-assignments'
import { FormInputState, SelectOptionObject } from '@/types/bootstrap'
import { DatasetRow } from '@/types/common'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { isEqual } from 'lodash'

type AssignmentSelectionOptions = {
  externalId: ExternalId,
  unclearAssignments: UnclearAssignmentVariant[],
  locations: ScoringFunctionNode[],
  selected: ScoringFunctionId | undefined,
  validated: boolean
}

interface ActionsCellParams extends ICellRendererParams {
  getOptions: (item: DatasetRow) => AssignmentSelectionOptions
}

@Component
export default class AreasSelector extends Mixins<CellRendererMixin<ActionsCellParams>>(CellRendererMixin) {
  private cellConfig: AssignmentSelectionOptions = {} as AssignmentSelectionOptions;

  private get item (): UnclearAssignmentVariant[] {
    return this.cellConfig.unclearAssignments
  }

  private get options (): SelectOptionObject[] {
    const options = this.cellConfig.locations
      .filter(loc => this.item.some(value => value.scoringFunctionId === loc.scoringFunction.data[0].id))
      .map(loc => ({
        value: loc.scoringFunction.data[0].id as string,
        text: loc.name
      }))

    options.unshift({ text: this.$t('planning.bookable-units-resolve.labels.not-assigned') as string, value: this.cellConfig.externalId })

    return options
  }

  private get selected (): ScoringFunctionId {
    return this.cellConfig.selected ?? this.cellConfig.externalId
  }

  private get inputState (): FormInputState {
    return this.cellConfig.validated ? this.selected !== this.cellConfig.externalId : null
  }

  created () {
    this.cellConfig = this.params.getOptions(this.params.data)

    const watch = this.$watch(() => this.params.getOptions(this.params.data), (newConfig, oldConfig) => {
      this.cellConfig = isEqual(oldConfig, newConfig) ? this.cellConfig : newConfig
    })

    this.$once('hook:destroyed', watch)
  }

  private emitChangeEvent (type: string, functionId: ScoringFunctionId): void {
    const selectedVariant = this.item.find(variant => variant.scoringFunctionId === functionId)

    this.params.api.dispatchEvent({
      type,
      id: this.cellConfig.externalId,
      variant: selectedVariant
    } as AgEvent)
  }

  renderActions (h: CreateElement): VNode[] {
    const selectBtn = h(
      'b-form-select',
      {
        class: 'assignments-selector my-1',
        props: {
          state: this.inputState,
          value: this.selected,
          options: this.options,
          size: 'sm'
        },
        on: {
          change: (selected) => this.emitChangeEvent('action:change', selected)
        }
      }
    )

    return [selectBtn]
  }

  render (h: CreateElement): VNode {
    return h('div', {
      staticClass: 'actions-cell'
    }, [...this.renderActions(h) ?? []])
  }
}
