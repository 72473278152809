<script>
import { Map } from 'leaflet'
import { findRealParent } from 'vue2-leaflet'

export default {
  name: 'MapContextMenu',
  props: {
    active: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    active () {
      this.updateMenu()
    }
  },
  mounted () {
    this.parentContainer = findRealParent(this.$parent)
    this.updateMenu()
  },
  methods: {
    getMap () {
      if (!this.parentContainer || !this.parentContainer.mapObject) {
        return null
      }

      const map = this.parentContainer.mapObject

      if (map && map instanceof Map && typeof map.contextmenu === 'object') {
        return map
      }

      return null
    },
    enableMenu () {
      const map = this.getMap()

      if (!map) {
        return
      }

      this.items.forEach(item => { map.contextmenu.addItem(item) })

      map.contextmenu.enable()
    },
    disableMenu () {
      const map = this.getMap()

      if (!map) {
        return
      }

      this.items.forEach(item => { map.contextmenu.removeItem(item) })

      map.contextmenu.disable()
    },
    updateMenu () {
      if (this.active) {
        this.enableMenu()
      } else {
        this.disableMenu()
      }
    }
  },
  render (h) {
    return h()
  }
}
</script>
