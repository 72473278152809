




















































































import { mapGetters, mapActions } from 'vuex'
import { USER_FIRST_NAME, USER_LAST_NAME } from '@/store/getter-types'
import { LOGOUT } from '@/store/auth/auth-actions'
import { IS_AUTHENTICATED } from '@/store/auth/auth-getters'
import { setLocale } from '@/locales/i18n'
import { Component, Vue } from 'vue-property-decorator'
import { User } from '@/types/auth'
import { getLocales } from '@/constants/languages'
import { getModules } from '@/constants/modules'
import staveLogo from '@/assets/img/logo_no_text.svg'
import logoutImage from '@/assets/img/logout.svg'
import personImage from '@/assets/img/person.svg'
import { ClickOutside, ClickOutsideOptions } from '@/directives/click-outside'
import InlineSvg from 'vue-inline-svg'

@Component({
  computed: {
    ...mapGetters({
      isAuthenticated: IS_AUTHENTICATED,
      firstName: USER_FIRST_NAME,
      lastName: USER_LAST_NAME
    })
  },
  methods: {
    ...mapActions({
      logout: LOGOUT
    })
  },
  components: { InlineSvg },
  directives: { ClickOutside }
})
export default class MainNav extends Vue {
  // mapGetters
  private readonly isAuthenticated!: boolean
  private readonly firstName!: User['username']
  private readonly lastName!: User['username']
  private menuSelected = false;

  private readonly logoutImage = logoutImage
  private readonly personImage = personImage
  private readonly staveLogo = staveLogo

  // mapActions
  private logout!: () => void

  private readonly activeModules = getModules()
  private readonly localeTitles = getLocales()

  private onLogout () {
    this.logout()
  }

  private setLocale = setLocale

  private get clickOutsideOptions (): ClickOutsideOptions {
    return {
      active: this.menuSelected,
      callback: (e: MouseEvent) => {
        e.stopPropagation()
        this.menuSelected = false
      },
      capture: true
    }
  }
}
