import QuantileAwareMixin from './quantile-aware.mixin'
import { Component } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { LayerGeometry } from '@/types/visualization/layer/geometry'
import { ColorModel } from '@/types/color-data'
import {
  LayerColorGetter,
  LayerOpacityGetter,
  QuantileFillConfig,
  SubLayerConfig
} from '@/types/visualization/layer/style'
import { Color } from 'csstype'
import { DatasetColumnKey } from '@/types/common'

@Component
export default class QuantilePathMixin<TLayer extends BorderedGeometryLayer> extends QuantileAwareMixin<TLayer> {
  protected get quantilePathDataRows (): LayerGeometry[] {
    const column = this.layer.style.path.gradientColumn
    const pathValues = this.quantilePathValues

    return this.getQuantileDataRows(column, this.layer.style.path as SubLayerConfig, pathValues)
  }

  protected get quantilePathStartRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.path.gradientStartColor, this.layer.style.path.gradientStartOpacity)
  }

  protected get quantilePathEndRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.path.gradientEndColor, this.layer.style.path.gradientEndOpacity)
  }

  protected minQuantilePathValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMinGradientValue(gradientColumn)
  }

  protected maxQuantilePathValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMaxGradientValue(gradientColumn)
  }

  protected get quantilePathColorFunc (): LayerColorGetter<LayerGeometry> {
    const gradientColumn = this.layer.style.path.gradientColumn
    // Make this property depend on path type, gradient column, start and end colors.
    if (
      this.layer.style.path.type === undefined ||
      gradientColumn === undefined ||
      this.quantilePathStartRgb === undefined ||
      this.quantilePathEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.quantilePathColor(row.meta[gradientColumn] as number)
  }

  protected get quantilePathOpacityFunc (): LayerOpacityGetter<LayerGeometry> {
    const gradientColumn = this.layer.style.path.gradientColumn
    // Make this property depend on path type, gradient column, start and end colors.
    if (
      this.layer.style.path.type === undefined ||
      gradientColumn === undefined ||
      this.quantilePathStartRgb === undefined ||
      this.quantilePathEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.quantilePathOpacity(row.meta[gradientColumn])
  }

  protected get quantilePathColors (): QuantileFillConfig[] {
    return this.calculateQuantileColors(this.pathQuantiles, this.quantilePathStartRgb, this.quantilePathEndRgb)
  }

  protected get pathQuantiles (): number[] {
    return this.calculateQuantiles(this.layer.style.path.type)
  }

  protected get quantilePathValues (): number[] {
    return this.calculateQuantileValues(this.pathQuantiles, this.layer.style.path.gradientColumn)
  }

  protected get quantityOfPathValuesForQuantile (): number[] {
    return this.calculateQuantityOfValuesForQuantile(this.quantilePathValues, this.layer.style.path.gradientColumn)
  }

  protected quantilePathColor (quantileValue: number): Color {
    return this.calculateQuantileColorByValue(quantileValue, this.quantilePathValues, this.quantilePathColors).color as Color
  }

  protected quantilePathOpacity (quantileValue): ColorModel['a'] {
    return this.calculateQuantileColorByValue(quantileValue, this.quantilePathValues, this.quantilePathColors).opacity as ColorModel['a']
  }
}
