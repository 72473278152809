import {
  DATASET_WIZARD_DATASET_TYPE,
  DATASET_WIZARD_TAB_DATASET_PROPS_ERRORS,
  DATASET_WIZARD_TAB_DATASET_PROPS_IS_EMPTY,
  DATASET_WIZARD_TAB_DATASET_PROPS_IS_ENABLED,
  DATASET_WIZARD_TAB_DATASET_PROPS_IS_INVALID,
  DATASET_WIZARD_TAB_DATASET_PROPS_STEP_ID,
  DATASET_WIZARD_DATASET_NAME
} from '@/store/getter-types'
import { DATASET_WIZARD_TAB_DATASET_PROPS_VALIDATE, DATASET_WIZARD_TAB_DATASET_PROPS_LEAVE } from '@/store/action-types'
import { DATASET_WIZARD_STEP_DATASET_PROPS } from '@/components/analytics/dataset-wizard/constants/steps'
import { i18n } from '@/locales/i18n'
import { isEmpty } from 'lodash'

// initial state
const state = {
  stepId: DATASET_WIZARD_STEP_DATASET_PROPS
}

// getters
const getters = {
  [DATASET_WIZARD_TAB_DATASET_PROPS_IS_ENABLED] () {
    return true
  },
  [DATASET_WIZARD_TAB_DATASET_PROPS_IS_EMPTY] (_state, getters) {
    return getters[DATASET_WIZARD_DATASET_TYPE] == null
  },
  [DATASET_WIZARD_TAB_DATASET_PROPS_IS_INVALID] (_state, getters) {
    return getters[DATASET_WIZARD_TAB_DATASET_PROPS_ERRORS].length > 0
  },
  [DATASET_WIZARD_TAB_DATASET_PROPS_ERRORS] (_state, getters) {
    const errors: string[] = []

    if (!getters[DATASET_WIZARD_DATASET_NAME] || isEmpty(getters[DATASET_WIZARD_DATASET_NAME].trim()) || getters[DATASET_WIZARD_DATASET_NAME].includes('.')) {
      errors.push(i18n.t('analytics.new-dataset-wizard.errors.enter-valid-dataset-name') as string)
    }

    return errors
  },
  [DATASET_WIZARD_TAB_DATASET_PROPS_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [DATASET_WIZARD_TAB_DATASET_PROPS_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[DATASET_WIZARD_TAB_DATASET_PROPS_ERRORS])
  },
  [DATASET_WIZARD_TAB_DATASET_PROPS_LEAVE] () {
    // leave action
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
