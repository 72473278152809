import pointsProps from './points-props.store'
import geometryProps from './geometry-props.store'
import { LAYER_WIZARD_SET_LAYER_NAME, LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE } from '@/store/mutation-types'
import {
  LAYER_WIZARD_GEOMETRY_RESET,
  LAYER_WIZARD_POINTS_RESET,
  LAYER_WIZARD_STEP_PROPS_INVALIDATE
} from '@/store/action-types.ts'
import { LAYER_WIZARD_STEP_PROPS_WAS_UPDATED } from '@/store/getter-types.ts'

// initial state
const state = {
  wasUpdated: false
}

// getters
const getters = {
  [LAYER_WIZARD_STEP_PROPS_WAS_UPDATED] (state) {
    return state.wasUpdated
  }
}

// mutations
const mutations = {
  [LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE] (state, wasUpdated) {
    state.wasUpdated = wasUpdated
  }
}

// actions
const actions = {
  async [LAYER_WIZARD_STEP_PROPS_INVALIDATE] ({ commit, dispatch }) {
    commit(LAYER_WIZARD_SET_LAYER_NAME, null)
    await dispatch(LAYER_WIZARD_GEOMETRY_RESET)
    await dispatch(LAYER_WIZARD_POINTS_RESET)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    pointsProps,
    geometryProps
  }
}
