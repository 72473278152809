import { i18n } from '@/locales/i18n'
import {
  LAYER_WIZARD_LAYER_TYPE,
  LAYER_WIZARD_TAB_ENTRYPOINT_ERRORS,
  LAYER_WIZARD_TAB_ENTRYPOINT_IS_EMPTY,
  LAYER_WIZARD_TAB_ENTRYPOINT_IS_ENABLED,
  LAYER_WIZARD_TAB_ENTRYPOINT_IS_INVALID,
  LAYER_WIZARD_TAB_ENTRYPOINT_STEP_ID
} from '@/store/getter-types'
import { LAYER_WIZARD_TAB_ENTRYPOINT_LEAVE, LAYER_WIZARD_TAB_ENTRYPOINT_VALIDATE } from '@/store/action-types'
import { LAYER_WIZARD_STEP_ENTRYPOINT } from '@/components/visualization/layer-wizard/constants/steps'

// initial state
const state = {
  stepId: LAYER_WIZARD_STEP_ENTRYPOINT
}

// getters
const getters = {
  [LAYER_WIZARD_TAB_ENTRYPOINT_IS_ENABLED] () {
    return true
  },
  [LAYER_WIZARD_TAB_ENTRYPOINT_IS_EMPTY] (state, getters) {
    return getters[LAYER_WIZARD_LAYER_TYPE] == null
  },
  [LAYER_WIZARD_TAB_ENTRYPOINT_IS_INVALID] (state, getters) {
    return getters[LAYER_WIZARD_TAB_ENTRYPOINT_ERRORS].length > 0
  },
  [LAYER_WIZARD_TAB_ENTRYPOINT_ERRORS] (state, getters) {
    const errors = []

    if (getters[LAYER_WIZARD_LAYER_TYPE] == null) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.select-entrypoint'))
    }

    return errors
  },
  [LAYER_WIZARD_TAB_ENTRYPOINT_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [LAYER_WIZARD_TAB_ENTRYPOINT_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[LAYER_WIZARD_TAB_ENTRYPOINT_ERRORS])
  },
  [LAYER_WIZARD_TAB_ENTRYPOINT_LEAVE] () {
    return Promise.resolve()
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
