







import LegendValuesMixin from '../legend/mixins/legend-values.mixin'
import LegendSublayerMixin from '../legend/mixins/legend-sublayer.mixin'
import LegendView from './legend-views/legend-view.vue'
import { MAX_LEGEND_COLUMNS, MAX_LEGEND_NOWRAP_ROWS } from './constants'
import { Component, Mixins } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'

@Component({
  components: { LegendView }
})
export default class LayerLegend extends
  Mixins<LegendValuesMixin<BorderedGeometryLayer>, LegendSublayerMixin>(LegendValuesMixin, LegendSublayerMixin) {
  protected get legendWrapLabels () {
    return this.sortedLegendValues.length < MAX_LEGEND_COLUMNS * MAX_LEGEND_NOWRAP_ROWS
  }
}
