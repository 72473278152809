import { DATASET_WIZARD_TAB_TAGS_ERRORS } from '@/store/dataset-wizard/navigation/tabs/tags-tab/tags-tab.getters'

export const DATASET_WIZARD_TAB_TAGS_VALIDATE = 'datasetWizard/tabs/tags/validate'
export const DATASET_WIZARD_TAB_TAGS_LEAVE = 'datasetWizard/tabs/tags/leave'

// actions
export const actions = {
  async [DATASET_WIZARD_TAB_TAGS_VALIDATE] ({ getters }) {
    return getters[DATASET_WIZARD_TAB_TAGS_ERRORS]
  },
  [DATASET_WIZARD_TAB_TAGS_LEAVE] () {
    // leave action
  }
}
