import { QueryOperator } from '@/types/query-builder/enums'

type CompareFunc = (value, operand) => boolean
type ComparisonImplementation = { [key in QueryOperator]: CompareFunc }

const isLess: CompareFunc = (value: number, operand: number): boolean => value - operand < 0

const isGreater: CompareFunc = (value: number, operand: number): boolean => value - operand > 0

const isLessOrEqual: CompareFunc = (value: number, operand: number): boolean => value - operand <= 0

const isGreaterOrEqual: CompareFunc = (value: number, operand: number): boolean => value - operand >= 0

const isEqual: CompareFunc = (value: unknown, operand: unknown): boolean => {
  if (operand == null) {
    return value == null || value === ''
  }

  return String(value) === String(operand)
}

const isNotEqual: CompareFunc = (value, operand): boolean => !isEqual(value, operand)

const isIn: CompareFunc = (value: unknown, operand: unknown[] | null): boolean => {
  return Array.isArray(operand) && operand.some((val) => isEqual(val, value))
}

export const ComparisonImpl: ComparisonImplementation = {
  [QueryOperator.Less]: isLess,
  [QueryOperator.Greater]: isGreater,
  [QueryOperator.LessOrEqual]: isLessOrEqual,
  [QueryOperator.GreaterOrEqual]: isGreaterOrEqual,
  [QueryOperator.Equal]: isEqual,
  [QueryOperator.NotEqual]: isNotEqual,
  [QueryOperator.In]: isIn
}
