import { Component, Vue } from 'vue-property-decorator'
import { ContextMenuCallback, ContextMenuItem } from '@/types/leaflet'
import { LeafletMouseEvent } from 'leaflet'

@Component
export class GeometriesMapContextMenuMixin extends Vue {
  protected get mapContextMenuItems (): ContextMenuItem[] {
    return [{
      text: this.$t('visualization.new-layer-modal.labels.what-is-here') as string,
      callback: this.emitCapturePoint as ContextMenuCallback
    }]
  }

  protected emitCapturePoint ({ latlng }: LeafletMouseEvent): void {
    this.$emit('captured-point', latlng)
  }
}
