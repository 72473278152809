import { PluginObject } from 'vue'
import Acl from 'browser-acl'
import VueAcl from 'vue-browser-acl'
import { router } from '@/router/router'
import { AuthPermission } from '@/types/auth/permissions'
import { globalRules } from './rules'
import { getAuthUser } from './utils/user'
import { anyRule, hasAnyRole } from './utils/rule-builders'
import { AuthRole } from '@/types/auth/roles'
import { AclHelper } from 'vue-browser-acl/types'

declare module 'vue/types/vue' {
  interface Vue {
    $perms: typeof AuthPermission;
    $can: AclHelper
  }
}

const acl = new Acl()

// We don't use ES6 classes for objects that are checked against permissions
// so every rule is registered as global.
acl.verbObjectMapper = () => Acl.GlobalRule

for (const permission in globalRules) {
  if (!Object.prototype.hasOwnProperty.call(globalRules, permission)) {
    continue
  }

  // Everything is allowed for an admin or developer. So prepend admin check to every rule here.
  acl.rule(permission, Acl.GlobalRule, anyRule(hasAnyRole(AuthRole.Admin, AuthRole.Developer), globalRules[permission]))
}

export class AclPlugin implements PluginObject<never> {
  install (Vue) {
    // Connect ACL plugin.
    Vue.use(VueAcl, getAuthUser, acl, { router })

    // Add permissions helper for easier usage.
    Vue.prototype.$perms = AuthPermission
  }
}
