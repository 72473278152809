import LegendValuesMixin from '@/components/visualization/legend/mixins/legend-values.mixin'
import { FillType, ShapeType } from '@/types/visualization/layer/enums'
import { Component } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'

type QuantityObjectsInSublayer = number | number[] | Record<string, number>

@Component
export default class SublayerObjectsMixin<TLayer extends BorderedGeometryLayer = BorderedGeometryLayer>
  extends LegendValuesMixin<TLayer> {
  protected get getQuantityOfFillObjectsInSublayer (): QuantityObjectsInSublayer {
    switch (this.layer.style.fill.type) {
      case FillType.Decile:
      case FillType.Median:
      case FillType.Quartile:
        return this.quantityOfFillValuesForQuantile

      case FillType.Clustering:
        return this.quantityOfFillValuesForCluster
      case FillType.Range:
        return this.quantityOfFillValuesForRange

      default:
        return -1
    }
  }

  protected get getQuantityOfPathObjectsInSublayer (): QuantityObjectsInSublayer {
    switch (this.layer.style.path.type) {
      case FillType.Decile:
      case FillType.Median:
      case FillType.Quartile:
        return this.quantityOfPathValuesForQuantile

      case FillType.Clustering:
        return this.quantityOfPathValuesForCluster
      case FillType.Range:
        return this.quantityOfPathValuesForRange

      default:
        return -1
    }
  }

  protected getQuantityOfObjectsInSublayer (key): number {
    switch (this.layer.shapeType) {
      case ShapeType.Polygon:
        return this.getQuantityOfFillObjectsInSublayer[key]

      case ShapeType.Line:
        return this.getQuantityOfPathObjectsInSublayer[key]

      default:
        return this.getQuantityOfFillObjectsInSublayer[key]
    }
  }
}
