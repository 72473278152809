import { Component, Mixins, Watch } from 'vue-property-decorator'
import { GridChildMixin } from './grid-child.mixin'
import { GridApi, PaginationChangedEvent } from 'ag-grid-community'

type PaginationState = {
  totalRows: number;
  perPage: number;
  currentPage: number;
}

@Component
export class GridPaginationMixin extends Mixins(GridChildMixin) {
  protected paginationState: PaginationState = {
    totalRows: 0,
    perPage: 0,
    currentPage: 0
  }

  private get paginationModel (): number {
    // ag-Grid uses 0-based pagination and here we convert it to 1-based for Bootstrap.
    return (this.paginationState.currentPage || 0) + 1
  }

  private set paginationModel (value: number) {
    // Convert to 0-based pagination for ag-Grid.
    this.paginationState.currentPage = value - 1
  }

  mounted () {
    // 'paginationChange' event is emitted before grid is ready.
    // To not to miss this event we force refresh manually here asap.
    this.callGridApi(this.updatePaginationState)
    this.onGridApi('paginationChanged', this.onPaginationChanged)
  }

  @Watch('paginationState.currentPage')
  private onCurrentPageChange (value: number): void {
    this.callGridApi((gridApi) => gridApi.paginationGoToPage(value))
  }

  private onPaginationChanged (e: PaginationChangedEvent): void {
    this.updatePaginationState(e.api)
  }

  private updatePaginationState (gridApi: GridApi): void {
    this.paginationState.perPage = gridApi.paginationGetPageSize()
    this.paginationState.totalRows = gridApi.paginationGetRowCount()
    this.paginationState.currentPage = gridApi.paginationGetCurrentPage()
  }
}
