import { render, staticRenderFns } from "./wizard-tab.vue?vue&type=template&id=dc8dde5a&scoped=true&"
import script from "./wizard-tab.vue?vue&type=script&lang=ts&"
export * from "./wizard-tab.vue?vue&type=script&lang=ts&"
import style0 from "./wizard-tab.vue?vue&type=style&index=0&id=dc8dde5a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc8dde5a",
  null
  
)

export default component.exports