import entrypointTab from './tabs/entrypoint-tab/dataset-wizard-tab-entrypoint.store'
import setDatasetTab from './tabs/set-dataset-tab/dataset-wizard-tab-set-dataset.store'
import geocodingDatasetTab from './tabs/geocoding-tab/dataset-wizard-tab-geocoding.store'
import tagsStep from './tabs/tags-tab/tags-tab.store'
import datasetPropsTab from './tabs/dataset-props-tab/dataset-wizard-tab-dataset-props.store'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    entrypointTab,
    setDatasetTab,
    geocodingDatasetTab,
    tagsStep,
    datasetPropsTab
  }
}
