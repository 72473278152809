import { CircleLayerStyle, LineLayerStyle, PointLayerStyle, PolygonLayerStyle } from '@/types/visualization/layer/style'
import { MarkerType } from '@/types/visualization/layer/enums'
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_FILL_MODE,
  DEFAULT_BORDER_OPACITY,
  DEFAULT_BORDER_STYLE,
  DEFAULT_BORDER_WIDTH
} from './border'
import { LAYER_ICON_SIZE } from './layer'
import {
  DEFAULT_POLYGON_COLOR,
  DEFAULT_POLYGON_FILL_MODE,
  DEFAULT_POLYGON_OPACITY, DEFAULT_POLYGON_HATCHING
} from './polygon'
import {
  DEFAULT_LINE_COLOR,
  DEFAULT_LINE_FILL_MODE,
  DEFAULT_LINE_OPACITY,
  DEFAULT_LINE_STYLE,
  DEFAULT_LINE_WIDTH
} from './line'
import {
  DEFAULT_POINT_BORDER_COLOR,
  DEFAULT_POINT_BORDER_OPACITY,
  DEFAULT_POINT_BORDER_STYLE,
  DEFAULT_POINT_BORDER_WIDTH,
  DEFAULT_POINT_COLOR,
  DEFAULT_POINT_FILL_MODE,
  DEFAULT_POINT_OPACITY
} from './point'
import { DEFAULT_CIRCLE_COLOR, DEFAULT_CIRCLE_FILL_MODE, DEFAULT_CIRCLE_OPACITY, DEFAULT_CIRCLE_HATCHING } from './circle'
import {
  DEFAULT_POLYGON_LABEL_FONT_COLOR,
  DEFAULT_POLYGON_LABEL_FONT_SIZE,
  DEFAULT_POLYGON_LABEL_STROKE_COLOR,
  DEFAULT_POLYGON_LABEL_STROKE_WIDTH
} from '@/components/visualization/layer-settings/constants/label'

export const DEFAULT_POLYGON_LAYER_STYLE: PolygonLayerStyle = {
  hatching: DEFAULT_POLYGON_HATCHING,
  visible: true,
  zIndex: 1,
  union: false,
  path: {
    width: DEFAULT_BORDER_WIDTH,
    style: DEFAULT_BORDER_STYLE,
    type: DEFAULT_BORDER_FILL_MODE,
    color: DEFAULT_BORDER_COLOR,
    opacity: DEFAULT_BORDER_OPACITY,
    gradientStartColor: DEFAULT_BORDER_COLOR,
    gradientStartOpacity: DEFAULT_BORDER_OPACITY,
    gradientEndColor: DEFAULT_BORDER_COLOR,
    gradientEndOpacity: DEFAULT_BORDER_OPACITY,
    range: {
      values: [{
        label: '',
        from: 0,
        color: DEFAULT_POINT_COLOR,
        opacity: DEFAULT_POINT_OPACITY
      }]
    }
  },
  fill: {
    type: DEFAULT_POLYGON_FILL_MODE,
    color: DEFAULT_POLYGON_COLOR,
    opacity: DEFAULT_POLYGON_OPACITY,
    gradientStartColor: DEFAULT_POLYGON_COLOR,
    gradientStartOpacity: DEFAULT_POLYGON_OPACITY,
    gradientEndColor: DEFAULT_POLYGON_COLOR,
    gradientEndOpacity: DEFAULT_POLYGON_OPACITY,
    range: {
      values: [{
        label: '',
        from: 0,
        color: DEFAULT_POLYGON_COLOR,
        opacity: DEFAULT_POLYGON_OPACITY
      }]
    },
    showSubLayers: true,
    showDuplicates: false,
    subLayers: {}
  },
  legend: {
    enabled: false,
    content: ''
  },
  popup: {
    showAllFields: true,
    columns: []
  },
  label: {
    columns: [],
    force: false,
    fontSize: DEFAULT_POLYGON_LABEL_FONT_SIZE,
    fontColor: DEFAULT_POLYGON_LABEL_FONT_COLOR,
    strokeColor: DEFAULT_POLYGON_LABEL_STROKE_COLOR,
    strokeWidth: DEFAULT_POLYGON_LABEL_STROKE_WIDTH
  }
}

export const DEFAULT_LINE_LAYER_STYLE: LineLayerStyle = {
  visible: true,
  zIndex: 1,
  path: {
    width: DEFAULT_LINE_WIDTH,
    style: DEFAULT_LINE_STYLE,
    type: DEFAULT_LINE_FILL_MODE,
    color: DEFAULT_LINE_COLOR,
    opacity: DEFAULT_LINE_OPACITY,
    gradientStartColor: DEFAULT_LINE_COLOR,
    gradientStartOpacity: DEFAULT_LINE_OPACITY,
    gradientEndColor: DEFAULT_LINE_COLOR,
    gradientEndOpacity: DEFAULT_LINE_OPACITY,
    range: {
      values: [{
        label: '',
        from: 0,
        color: DEFAULT_LINE_COLOR,
        opacity: DEFAULT_LINE_OPACITY
      }]
    },
    showSubLayers: true,
    showDuplicates: false,
    subLayers: {}
  },
  legend: {
    enabled: false,
    content: ''
  },
  popup: {
    showAllFields: true,
    columns: []
  }
}

export const DEFAULT_POINT_LAYER_STYLE: PointLayerStyle = {
  visible: true,
  zIndex: 1,
  path: {
    width: DEFAULT_POINT_BORDER_WIDTH,
    style: DEFAULT_POINT_BORDER_STYLE,
    type: DEFAULT_BORDER_FILL_MODE,
    color: DEFAULT_POINT_BORDER_COLOR,
    opacity: DEFAULT_POINT_BORDER_OPACITY,
    gradientStartColor: DEFAULT_BORDER_COLOR,
    gradientStartOpacity: DEFAULT_BORDER_OPACITY,
    gradientEndColor: DEFAULT_BORDER_COLOR,
    gradientEndOpacity: DEFAULT_BORDER_OPACITY,
    range: {
      values: [{
        label: '',
        from: 0,
        color: DEFAULT_POINT_COLOR,
        opacity: DEFAULT_POINT_OPACITY
      }]
    }
  },
  latitudeColumn: '',
  longitudeColumn: '',
  size: 3,
  markerType: MarkerType.Circle,
  fill: {
    type: DEFAULT_POINT_FILL_MODE,
    color: DEFAULT_POINT_COLOR,
    opacity: DEFAULT_POINT_OPACITY,
    gradientStartColor: DEFAULT_POINT_COLOR,
    gradientStartOpacity: DEFAULT_POINT_OPACITY,
    gradientEndColor: DEFAULT_POINT_COLOR,
    gradientEndOpacity: DEFAULT_POINT_OPACITY,
    range: {
      values: [{
        label: '',
        from: 0,
        color: DEFAULT_POINT_COLOR,
        opacity: DEFAULT_POINT_OPACITY
      }]
    },
    showSubLayers: true,
    showDuplicates: false,
    subLayers: {},
    icon: {
      size: LAYER_ICON_SIZE,
      clustering: false,
      useDefault: true,
      default: undefined,
      spriteImage: undefined,
      spriteIndex: {}
    }
  },
  legend: {
    enabled: false,
    content: ''
  },
  popup: {
    showAllFields: true,
    columns: []
  }
}

export const DEFAULT_CIRCLE_LAYER_STYLE: CircleLayerStyle = {
  hatching: DEFAULT_CIRCLE_HATCHING,
  visible: true,
  zIndex: 1,
  latitudeColumn: '',
  longitudeColumn: '',
  radiusColumn: '',
  path: {
    width: DEFAULT_BORDER_WIDTH,
    style: DEFAULT_BORDER_STYLE,
    type: DEFAULT_BORDER_FILL_MODE,
    color: DEFAULT_BORDER_COLOR,
    opacity: DEFAULT_BORDER_OPACITY,
    gradientStartColor: DEFAULT_BORDER_COLOR,
    gradientStartOpacity: DEFAULT_BORDER_OPACITY,
    gradientEndColor: DEFAULT_BORDER_COLOR,
    gradientEndOpacity: DEFAULT_BORDER_OPACITY,
    range: {
      values: [{
        label: '',
        from: 0,
        color: DEFAULT_POINT_COLOR,
        opacity: DEFAULT_POINT_OPACITY
      }]
    }
  },
  fill: {
    type: DEFAULT_CIRCLE_FILL_MODE,
    color: DEFAULT_CIRCLE_COLOR,
    opacity: DEFAULT_CIRCLE_OPACITY,
    gradientStartColor: DEFAULT_CIRCLE_COLOR,
    gradientStartOpacity: DEFAULT_CIRCLE_OPACITY,
    gradientEndColor: DEFAULT_CIRCLE_COLOR,
    gradientEndOpacity: DEFAULT_CIRCLE_OPACITY,
    range: {
      values: [{
        label: '',
        from: 0,
        color: DEFAULT_CIRCLE_COLOR,
        opacity: DEFAULT_CIRCLE_OPACITY
      }]
    },
    showSubLayers: true,
    showDuplicates: false,
    subLayers: {}
  },
  legend: {
    enabled: false,
    content: ''
  },
  popup: {
    showAllFields: true,
    columns: []
  }
}
