






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { FormInputState, SelectOption } from '@/types/bootstrap'

type ColumnsMapping = {
  key: string,
  label: string,
  value: string | null,
  state: FormInputState
}[]

@Component
export default class ImportNetworksColumns extends Vue {
  @Prop({ default: null }) private bidColumn!: string | null
  @Prop({ default: null }) private bidColumnState!: FormInputState
  @Prop({ default: () => [] }) private options!: SelectOption[]

  private get columnsMapping (): ColumnsMapping {
    return [{
      key: 'bid-column',
      label: this.$t('planning.filters.labels.bid-column') as string,
      value: this.bidColumn,
      state: this.bidColumnState
    }]
  }

  private get isDisabled (): boolean {
    return this.options.length === 0
  }

  private handleColumnSelect (key: string, column: string): void {
    this.$emit(`update:${key}`, column)
  }
}
