







import MainNav from '@/components/header/main-nav.vue'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: { MainNav }
})

export default class Home extends Vue { }
