import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { TimeFrameObj } from '@/types/common'
import { Store } from '@/store/store'
import { BOOKING_TIME } from '@/store/getter-types'
import { BookingTimeFrame } from '@/types/planning/booking-time'
import moment from '@/lib/moment'

export function getGoalFilter (goalId: string, filters: ScoringFunctionNode[]) {
  return filters.find(filter => filter.scoringFunction.data[0].id === goalId)
}

export function getTimeFrameByTimeRange (timeRange: TimeFrameObj) {
  const bookingTimeFrames: BookingTimeFrame[] = Store.getters[BOOKING_TIME]
  const time = bookingTimeFrames.find(time => moment(time.start).isSame(timeRange.start) && moment(time.end).isSame(timeRange.end))

  return time ? time.value : `${moment(timeRange.start).format('DD/MM')}-${moment(timeRange.end).format('DD/MM')}`
}
