










import { Component } from 'vue-property-decorator'
import GridActionsMixin from '../grid-actions.mixin'

@Component
export default class AddNewPointAction extends GridActionsMixin {
  private addNewPointsRow () {
    const newRow = Object.keys(this.fields).reduce((obj, key) => ({ ...obj, [key]: null }), {})
    this.$emit('update', [...this.value, newRow])
  }
}
