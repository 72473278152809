import { FilterRangeConfig, FilterRangeTypes, RangeOptions, ValueRange } from '@/types/planning/wizard/filter-editor'
import { BaseConfig } from '../base-config'
import { ScoringElementOptions } from '@/types/planning/scoring/functions'

export abstract class AbstractRangeConfig<T extends FilterRangeTypes>
  extends BaseConfig<T> implements FilterRangeConfig<T> {
  protected rangeOptions!: RangeOptions

  protected defaultRangeOptions: RangeOptions = {
    rangeMin: 1,
    rangeMax: 100,
    rangeStep: 1,
    formatter: val => `${val}`
  }

  constructor (parameters: ScoringElementOptions | null) {
    super(parameters)
    this.rangeOptions = this.defaultRangeOptions

    if (this._scoringParameters) {
      this.rangeOptions = {
        rangeMin: this._scoringParameters.minValue,
        rangeMax: this._scoringParameters.maxValue,
        rangeStep: this._scoringParameters.step,
        formatter: val => val + (this._scoringParameters?.format ?? '')
      }
    }
  }

  get rangeMin (): number {
    return this.rangeOptions.rangeMin
  }

  set rangeMin (value: number) {
    this.rangeOptions.rangeMin = value
  }

  get rangeMax (): number {
    return this.rangeOptions.rangeMax
  }

  set rangeMax (value: number) {
    this.rangeOptions.rangeMax = value
  }

  get rangeStep (): number {
    return this.rangeOptions.rangeStep
  }

  set rangeStep (value: number) {
    this.rangeOptions.rangeStep = value
  }

  get formatter () {
    return (val: number) => this.rangeOptions.formatter(val)
  }

  get displayMarkers () {
    return true
  }

  get displayInput () {
    return true
  }
}

export class RangeConfig extends AbstractRangeConfig<ValueRange> implements FilterRangeConfig<ValueRange> {
  protected isValidRange (possibleRange: ValueRange | unknown): boolean {
    return Array.isArray(possibleRange) && possibleRange.length === 2
  }

  override formatValue (value: ValueRange): string {
    return (value || [])
      .map(val => this.rangeOptions.formatter(val))
      .join(' - ')
  }
}
