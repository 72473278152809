import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { extend } from 'lodash'
import { stringify, IStringifyOptions } from 'qs'
import { registerInterceptors } from './interceptors/interceptors'

const serializerOptions: IStringifyOptions = {
  indices: false
}

const defaultRequestConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json'
  },
  // Show Toast with error message by default.
  paramsSerializer: function (params): string {
    return stringify(params, serializerOptions)
  }
}

export function createApiClient (config: AxiosRequestConfig): AxiosInstance {
  const extendedConfig = extend({}, defaultRequestConfig, config)
  const http = axios.create(extendedConfig)

  registerInterceptors(http)

  return http
}
