import { Store } from '@/store/store'
import { CANCEL_CALLBACKS } from '@/store/getter-types'
import { CLEAR_CANCEL_CALLBACK } from '@/store/mutation-types'
import { Canceler } from 'axios'

type CancelCallbacks = [string, Canceler][]

export default function () {
  const callbacks: CancelCallbacks = Object.entries(Store.getters[CANCEL_CALLBACKS])

  if (callbacks.length) {
    callbacks.forEach(([key, callback]) => callback(key))

    Store.commit(CLEAR_CANCEL_CALLBACK)
  }
}
