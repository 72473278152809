import { i18n } from '@/locales/i18n'
import { ProviderUIConfig } from '@/lib/leaflet/control/tiles-source-control/index'

export const providersConfig: Record<'mapProviders' | 'overlayProviders', ProviderUIConfig> = {
  mapProviders: {
    get title () { return i18n.t('visualization.map.controls.mapProviders.tiles.header') as string },
    inputType: 'radio',
    options: [
      {
        get name () { return i18n.t('visualization.map.controls.mapProviders.tiles.options.default') as string },
        url: DEFAULT_MAP_TILE_URL,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; (<a href="https://opendatacommons.org/licenses/odbl/1-0/index.html">ODbL</a>)'
      },
      {
        get name () { return i18n.t('visualization.map.controls.mapProviders.tiles.options.topo') as string },
        url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-background/{z}/{x}/{y}{r}.png',
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      },
      {
        get name () { return i18n.t('visualization.map.controls.mapProviders.tiles.options.greyscale') as string },
        url: 'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      },
      {
        get name () { return i18n.t('visualization.map.controls.mapProviders.tiles.options.no-background') as string },
        url: '',
        attribution: ''
      }
    ]
  },
  overlayProviders: {
    get title () { return i18n.t('visualization.map.controls.mapProviders.overlays.header') as string },
    inputType: 'checkbox',
    options: [
      {
        get name () { return i18n.t('visualization.map.controls.mapProviders.overlays.options.roads') as string },
        url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lines/{z}/{x}/{y}{r}.png',
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      },
      {
        get name () { return i18n.t('visualization.map.controls.mapProviders.overlays.options.railways') as string },
        url: 'http://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png',
        attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy; <a href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      },
      {
        get name () { return i18n.t('visualization.map.controls.mapProviders.overlays.options.labels') as string },
        url: 'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      }
    ]
  }
}
