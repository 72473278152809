import { Vue, Component, Prop } from 'vue-property-decorator'

const directionValidator = val => ['horizontal', 'vertical'].includes(val)

@Component
export class DirectionAwareMixin extends Vue {
  @Prop({ default: 'horizontal', validator: directionValidator }) direction!: 'horizontal' | 'vertical'

  protected get isVertical (): boolean {
    return this.direction === 'vertical'
  }
}
