










































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import { acceptFileFilters, FileTypeConfigs } from '@/utils/file'
import FileUpload from '@/components/file-upload/file-upload.vue'
import { VueClassDef } from '@/types/vue'
import { UploadCallback } from '@/types/file-upload'
import { FileProcessModes } from '@/types/enums'
import { FormInputState } from '@/types/bootstrap'

@Component({
  components: { FileUpload }
})
export default class FileProcess extends Vue {
  @Prop({ required: true }) fileTypes!: FileTypeConfigs
  @Prop({ required: true }) uploadCallback!: UploadCallback
  @Prop({ default: null }) fileUploadState!: FormInputState
  @Prop({
    default: FileProcessModes.MODE_FULL,
    validator: (val) => Object.values(FileProcessModes).includes(val)
  }) mode!: FileProcessModes

  @Ref('fileUpload') fileUploadRef !: FileUpload

  private fileName: string | null = null
  private activeTab = 0

  private get allowedTypes (): string[] {
    return acceptFileFilters(this.fileTypes).allowedTypes
  }

  private get allowedExtensions (): string[] {
    return acceptFileFilters(this.fileTypes).allowedExtensions
  }

  private get wasImported (): boolean {
    return this.fileName !== null
  }

  private get isSearchEnabled (): boolean {
    return [FileProcessModes.MODE_FULL, FileProcessModes.MODE_SEARCH].includes(this.mode)
  }

  private get isUploadEnabled (): boolean {
    return [FileProcessModes.MODE_FULL, FileProcessModes.MODE_UPLOAD].includes(this.mode)
  }

  private get navClass (): VueClassDef {
    return {
      'file-process--no-tabs': this.mode !== FileProcessModes.MODE_FULL
    }
  }

  private importFile (file: File): void {
    this.fileName = file.name
  }

  resetFileUploadState (): void {
    this.fileUploadRef.resetFile()
  }
}
