import { PluginObject, VNode } from 'vue'
import { i18n } from '@/locales/i18n'
import EnterBookableUnits from './enter-bookable-units.vue'

type EnterBookableUnitsResult = {
  submitted: boolean;
  externalIds: string[];
}

type EnterBookableUnitsFunction = () => Promise<EnterBookableUnitsResult>;

declare module 'vue/types/vue' {
  interface Vue {
    $enterBookableUnits: EnterBookableUnitsFunction;
  }
}

function configureDialog (pickerVNode: VNode) {
  const props = {
    size: 'full',
    bodyClass: 'w-100 h-100 d-flex p-0',
    centered: true,
    scrollable: true
  }

  const slots = {
    default: pickerVNode,
    'modal-title': i18n.t('planning.bookable-units-import.labels.add-units-label') as string
  }

  return { props, slots }
}

export class EnterBookableUnitsPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$enterBookableUnits = async function (): Promise<EnterBookableUnitsResult> {
      const pickerVNode = this.$createElement(EnterBookableUnits, {
        props: {}
      })

      const { props, slots } = configureDialog(pickerVNode)

      const submitted = await this.$modalDialog.msgBoxConfirmValid(slots, props)

      return {
        submitted,
        externalIds: (pickerVNode.componentInstance as EnterBookableUnits).externalIds
      }
    }
  }
}
