import { Bounds, LatLng, LatLngBounds, Point } from 'leaflet'
import { ContextMenuCallbackEvent } from '@/types/leaflet'
import { cloneDeepWith } from 'lodash'
import { ProjectedShape } from '@/lib/leaflet/layer/projected-shape'
import { ZIndexedLayer, ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

export abstract class ShapesLayer<TElement extends ProjectedShape, TOptions extends ZIndexedPathOptions = ZIndexedPathOptions> extends ZIndexedLayer<TElement, TOptions> {
  protected _setLatLngs (latlngs: LatLng[]): void {
    this._bounds = new LatLngBounds([])
    this._latlngs = this._convertLatLngs(latlngs)
  }

  protected abstract _convertLatLngs (latlngs: LatLng[] | LatLng[][]): LatLng[] | LatLng[][]

  _showContextMenu (e: ContextMenuCallbackEvent): void {
    const originalItems = this.options.contextmenuItems ?? []

    this.options.contextmenuItems = originalItems.map(menuItem => {
      return cloneDeepWith(menuItem, (value, key) => {
        if (key === 'callback') {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const layer = this

          return (e) => {
            if (e.layerPoint) {
              layer.fire('group-hook.contextmenu.callback', {
                groupIndex: layer.getPointIndex(e.layerPoint),
                hookSubject: e
              } as ContextMenuCallbackEvent)
            }

            value(e)
          }
        }

        return undefined
      })
    })

    this._map.once('contextmenu.hide', () => {
      this.options.contextmenuItems = originalItems
    })

    ZIndexedLayer.prototype._showContextMenu.call(this, e)
  }

  protected clipShapes (): Bounds | null {
    let bounds = this._renderer._bounds
    const w = this.options.weight as number
    const p = new Point(w, w)

    // increase clip padding by stroke width to avoid a stroke on clip edges
    if (bounds.min && bounds.max) {
      bounds = new Bounds(bounds.min.subtract(p), bounds.max.add(p))
    }

    this._parts = []

    if (!this._pxBounds || !this._pxBounds.intersects(bounds)) {
      return null
    }

    return bounds
  }

  protected _containsPoint (p: Point): boolean {
    return this.getPointIndex(p) !== -1
  }

  public getPointIndex (p: Point): number {
    if (!this._pxBounds || !this._pxBounds.contains(p)) {
      return -1
    }

    const tolerance = this._clickTolerance()
    const len = this._parts.length

    for (let i = 0; i < len; i++) {
      const projectedShape = this._parts[i]

      if (projectedShape.containsPoint(p, tolerance)) {
        return projectedShape.index
      }
    }

    return -1
  }

  protected _updateBounds (): void {
    const w = this._clickTolerance()
    const p = new Point(w, w)

    if (this._rawPxBounds.min && this._rawPxBounds.max) {
      this._pxBounds = new Bounds([
        this._rawPxBounds.min.subtract(p),
        this._rawPxBounds.max.add(p)
      ])
    }
  }
}
