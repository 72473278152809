import { PpsFilterModeConfig } from '@/utils/filter-editor/value-config/select/pps-filter-mode-config'
import { PPSValueConfig } from '@/utils/filter-editor/value-config/range/pps-value-config'
import { ValueRange } from '@/types/planning/wizard/filter-editor'
import { PpsToAverageConfig } from '@/utils/filter-editor/value-config/slider/pps-to-average-config'
import { PPS_FILTER_MODE, PPS_RANGE, PPS_TO_AVERAGE } from '@/constants/functions/scoring-functions-param-names'
import { PpsFilterModes } from '@/types/planning/wizard/enums'
import { ScoringFunctionData } from '@/types/planning/scoring/functions'

export interface PpsFilterFormData extends ScoringFunctionData {
  [PPS_FILTER_MODE]: PpsFilterModes,
  [PPS_RANGE]: ValueRange,
  [PPS_TO_AVERAGE]: number
}

export const PpsFilterFormConfig = {
  [PPS_FILTER_MODE]: new PpsFilterModeConfig(null),
  [PPS_RANGE]: new PPSValueConfig(null),
  [PPS_TO_AVERAGE]: new PpsToAverageConfig(null)
}
