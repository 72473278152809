




























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ActionResults extends Vue {
  @Prop({ default: 0 }) errorsCount!: number
  @Prop({ default: 0 }) totalCount!: number

  private get hasErrors (): boolean {
    return this.errorsCount !== 0
  }
}
