import { FilterConfig } from '@/types/planning/wizard/filter-editor'
import { BaseConfig } from '../base-config'
import { groupBy, reduce } from 'lodash'
import { GeometryBase } from '@/types/common'

export class AreaKeysConfig extends BaseConfig<GeometryBase[]> implements FilterConfig<GeometryBase[]> {
  override formatValue (values: GeometryBase[]): string {
    const groupedByParent = groupBy(values, 'parentGeometrySetId')

    return reduce(groupedByParent, (result, values, key) => {
      result.push(key + ': ' + values
        .map(geometry => geometry.name)
        .join('; ')
      )

      return result
    }, [] as string[])
      .join('.  ')
  }
}
