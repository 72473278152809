import navigationStore from './navigation/navigation.store'
import stepsStore from './steps/vicinity-import-wizard-steps.store'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { getDefaultQuery } from '@/utils/query-builder'

const state = {
  visible: false,
  sourceDataset: null,
  query: getDefaultQuery(),
  wizardNamespace: 'vicinity-import'
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    wizardNavigation: navigationStore,
    wizardSteps: stepsStore
  }
}
