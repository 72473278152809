import { VisualizationLayer } from '@/types/visualization/layer'
import { getQueryFilterFunction } from '@/components/shared/query-builder/filter-func'

export function getDataRows<T extends VisualizationLayer> (layer: T): T['geometry'] {
  if (!layer.settings.query) {
    return layer.geometry
  }

  const queryFilterFunc = getQueryFilterFunction(layer.settings.query)

  return layer.geometry.filter((row) => queryFilterFunc(row.meta))
}
