












import { range } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SelectOption } from '@/types/bootstrap'

@Component
export default class SelectDecimals extends Vue {
  @Prop({ default: null }) private readonly value!: number

  private get decimalOptions (): SelectOption<number | null>[] {
    const emptyValue = { value: null, text: this.$t('visualization.layer-settings-modal.labels.no-decimals-limit') as string }
    const values = range(0, 5).map((value) => ({
      value,
      text: value.toString()
    }))

    return [emptyValue, ...values]
  }

  private get decimals (): number {
    return this.value
  }

  private set decimals (value: number) {
    this.$emit('input', value)
  }
}
