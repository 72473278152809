




import { Component, Vue, Prop } from 'vue-property-decorator'
import { EntitiesTranslationHelper } from '@/utils/entities-translation-helper'

@Component
export default class FilterParamName extends Vue {
  @Prop({ required: true }) funcName!: string
  @Prop({ required: true }) paramName!: string

  get paramDisplayName (): string {
    const translationPath = `planning.functions.${this.funcName}.parameters.${this.paramName}`
    return EntitiesTranslationHelper.translate(translationPath, this.paramName)
  }
}
