import { overEvery, overSome } from 'lodash'
import { AclRule, User } from '@/types/auth'
import { AuthRole } from '@/types/auth/roles'

export function anyRule (...rules: AclRule[]): AclRule {
  return overSome(rules)
}

export function everyRule (...rules: AclRule[]): AclRule {
  return overEvery(rules)
}

export function hasAnyRole (...roles: AuthRole[]): AclRule {
  const rolesSet = new Set(roles)

  return (user: User) => {
    return user.roles.some((role) => rolesSet.has(role))
  }
}
