import { ApiResponse } from '@/api/types'
import { Order } from '@/types/orders'
import { parseOrder } from './utils'
import { createTransformResponse } from '@/api/utils'

export const transformOrderListResponse = createTransformResponse<Order[] | string>((response: ApiResponse['data']): Order[] | string => {
  const orderList = JSON.parse(response)

  return orderList.map(parseOrder)
})
