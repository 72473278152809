


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MultiProgress extends Vue {
  @Prop({ default: 0 }) private readonly totalCount!: number
  @Prop({ default: 0 }) private readonly errorsCount!: number
  @Prop({ default: 0 }) private readonly successCount!: number
  @Prop({ default: false }) private readonly running!: number
}
