
















import ColorExample from './color-example.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ColorFormatHEX } from '@/types/color-data'

@Component({
  components: { ColorExample }
})
export default class Presets extends Vue {
  @Prop({ required: true }) colors!: ColorFormatHEX['hex'][]
}
