import { Component, Prop } from 'vue-property-decorator'
import { NamedPoints } from '@/types/common'
import { PointsSettings } from '../points-settings'
import { GridChildMixin } from '@/components/shared/ag-grid/mixins/grid-child.mixin'

@Component
export default class GridActionsMixin extends GridChildMixin {
  @Prop({ required: true }) protected readonly value!: NamedPoints
  @Prop({ required: true }) protected readonly fields!: PointsSettings

  protected updateValue (updatedValue: NamedPoints) {
    this.$emit('update', updatedValue)
  }
}
