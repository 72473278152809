
import { Component, Mixins } from 'vue-property-decorator'
import { LayerPoint } from '@/types/visualization/layer/geometry'
import { MultiPinMarker, MultiPinMarkerOptions } from '@/lib/leaflet/layer/multi-markers/shape-markers/multi-pin-marker/multi-pin-marker'
import { ItemsGroup } from '../types'
import { MultiMarkersMixin } from '@/components/map/mixins/grouped-layers/multi-markers-mixin'

@Component
export default class MultiPinMarkers extends Mixins<MultiMarkersMixin<LayerPoint, MultiPinMarker, MultiPinMarkerOptions>>(MultiMarkersMixin) {
  protected override getOptionsToMerge (): MultiPinMarkerOptions {
    return this.markerOptions
  }

  protected override createMapObject (group: ItemsGroup<LayerPoint, MultiPinMarkerOptions>, groupOptions: MultiPinMarkerOptions): MultiPinMarker {
    const latlngs = group.items.map(item => item.latLng)

    return new MultiPinMarker(latlngs, groupOptions)
  }
}
