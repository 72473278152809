import areaDatasetStep from './steps/area-dataset-step.store'
import areaLinkStep from './steps/area-link-step.store'
import areaUploadStep from './steps/area-upload-step.store'
import poiDatasetStep from './steps/poi-dataset-step.store'
import surroundingStep from './steps/surrounding-step.store'
import entrypointStep from './steps/entrypoint-step.store'
import geometryStep from './steps/geometry-step.store'
import granularityStep from './steps/granularity-step.store'
import propsStep from './steps/props-step/props-step.store'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    entrypointStep,
    geometryStep,
    granularityStep,
    propsStep,
    areaDatasetStep,
    areaLinkStep,
    areaUploadStep,
    poiDatasetStep,
    surroundingStep
  }
}
