import { CollectionItemArray } from '@/types/planning/wizard/filter-editor'
import { CollectionConfig } from './collection-config'

export class SimpleKeyValueCollection extends CollectionConfig {
  get options (): CollectionItemArray {
    const options = this._scoringParameters?.options || {}
    return Object.keys(options).map(key => ({ value: key, text: options[key] })) as CollectionItemArray
  }

  async initCollection (): Promise<void> {
    return Promise.resolve()
  }

  formatList (values: string[]): string[] {
    return this.options
      .filter(option => values.some(value => value === option.value))
      .map(option => option.text)
  }
}
