import { cloneDeep } from 'lodash'
import { LatLngBounds, LatLngBoundsLiteral } from 'leaflet'
import { freezeRecursive } from '@/utils/object'
import {
  GET_SCORE_REQUEST_ID,
  GET_SCORING_LAYERS_REQUEST_ID,
  scoringLocationsApi
} from '@/api/rest/scoring/scoring-locations.api'
import siteDetails from './site-details/site-details.store'
import scoresDetails from './scoring-details/scoring-details.store'
import appliedScoring from './applied-scoring/applied-scoring.store'
import scoringStatistics from './scoring-statistics/scoring-statistics.store'
import {
  CAMPAIGN_FLIGHTS_DISABLED,
  CAMPAIGN_ID,
  CAMPAIGN_SCORING_REQUEST_DATA,
  CAMPAIGN_STATUS, CANCEL_CALLBACKS,
  SCORING_BOUNDS,
  SCORING_CANCEL_CALLBACK,
  SCORING_GET_LAYERS_BY_FILTER,
  SCORING_HAS_LOCATIONS,
  SCORING_LAYERS,
  SCORING_LOCATIONS,
  SCORING_REPORTS,
  USER_ROLES
} from '@/store/getter-types'
import {
  GET_SCORING_LAYERS,
  GET_SCORING_SCORES,
  SET_APPLIED_SCORING_BODY,
  RESET_APPLIED_SCORING_BODY,
  RESET_SCORES_DATA,
  UPDATE_CAMPAIGN_SCORES
} from '@/store/action-types'
import {
  REMOVE_CANCEL_CALLBACK,
  SET_SCORING_DETAILS,
  SET_SCORING_LAYERS,
  SET_SCORING_LOCATIONS,
  SET_SCORING_REPORTS,
  SET_SCORING_STATISTICS
} from '@/store/mutation-types'
import { filterReportByVisibility } from '@/utils/reports'
import { getBoundsForLayers } from '@/utils/map'
import { setScoringLayersStyle } from '@/utils/scoring/layers'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { Color } from 'csstype'
import { UpdateCampaignDataResult } from '@/types/store/campaign'
import { ScoringRequestPayload } from '@/types/store/scoring'
import { PlanningStatus } from '@/types/planning/enums'
import { logger } from '@/utils/log'
import { i18n } from '@/locales/i18n'
import axios, { Canceler } from 'axios'

// initial state
const state = {
  scores: [],
  layers: [],
  statistics: [],
  reports: []
}

// getters
const getters = {
  [SCORING_LOCATIONS] (state) {
    return state.scores
  },
  [SCORING_LAYERS] (state) {
    return state.layers
  },
  [SCORING_HAS_LOCATIONS] (state) {
    return state.scores.length !== 0
  },
  [SCORING_BOUNDS] (_state, getters) {
    const layerBounds = getBoundsForLayers(getters[SCORING_LAYERS], undefined)

    if (!getters[SCORING_HAS_LOCATIONS]) {
      return layerBounds
    }

    const boundsArray: LatLngBoundsLiteral = [[91, 181], [-91, -181]]

    getters[SCORING_LOCATIONS].forEach(loc => {
      boundsArray[0][0] = Math.min(boundsArray[0][0], loc.latLng.lat)
      boundsArray[0][1] = Math.min(boundsArray[0][1], loc.latLng.lng)
      boundsArray[1][0] = Math.max(boundsArray[1][0], loc.latLng.lat)
      boundsArray[1][1] = Math.max(boundsArray[1][1], loc.latLng.lng)
    })

    const bounds = new LatLngBounds(boundsArray)

    if (layerBounds) {
      bounds.extend(layerBounds)
    }

    return bounds
  },
  [SCORING_CANCEL_CALLBACK] (_state, getters) {
    const callbacks = Object.entries(getters[CANCEL_CALLBACKS])
      .filter(([key]) => [GET_SCORE_REQUEST_ID, GET_SCORING_LAYERS_REQUEST_ID].includes(key))
      .map(([, callback]) => callback as Canceler)

    if (!callbacks.length) {
      return undefined
    }

    return () => callbacks.forEach((callback) => callback())
  },
  [SCORING_REPORTS] (state, getters) {
    return filterReportByVisibility(state.reports, getters[USER_ROLES])
  },
  [SCORING_GET_LAYERS_BY_FILTER] (_state, getters) {
    return (filter: ScoringFunctionNode): Color | null => {
      return getters[SCORING_LAYERS].filter(layer => layer.dataSource.scoringFunctionID === filter.scoringFunction.data[0].id)
    }
  }
}

// actions
const actions = {
  [UPDATE_CAMPAIGN_SCORES] ({ dispatch, getters }): UpdateCampaignDataResult {
    if (getters[CAMPAIGN_STATUS] === PlanningStatus.Draft) {
      return [Promise.resolve(), Promise.resolve()]
    }

    if (!getters[CAMPAIGN_ID]) {
      return dispatch(RESET_SCORES_DATA, null, { root: true })
    }

    const requestObj: ScoringRequestPayload = {
      campaign: getters[CAMPAIGN_SCORING_REQUEST_DATA],
      availability: !getters[CAMPAIGN_FLIGHTS_DISABLED]
    }

    const updateScores = dispatch(GET_SCORING_SCORES, requestObj, { root: true })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          logger.error(err)
          throw new Error(i18n.t('planning.errors.scoring-failed') as string)
        }
      })

    const updateLayers = dispatch(GET_SCORING_LAYERS, getters[CAMPAIGN_SCORING_REQUEST_DATA], { root: true })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          logger.error(err)
          throw new Error(i18n.t('planning.errors.scoring-layers-failed') as string)
        }
      })

    return [updateScores, updateLayers]
  },
  [GET_SCORING_SCORES] ({ commit, dispatch }, payload: ScoringRequestPayload) {
    return scoringLocationsApi.getScoring(payload.campaign, payload.availability)
      .then((response) => {
        dispatch(SET_APPLIED_SCORING_BODY, cloneDeep(payload))
        commit(SET_SCORING_LOCATIONS, response.units || [])
        commit(SET_SCORING_STATISTICS, response.statistics || [])
        commit(SET_SCORING_REPORTS, response.reports)
        commit(SET_SCORING_DETAILS, null)
      })
      .finally(() => commit(REMOVE_CANCEL_CALLBACK, GET_SCORE_REQUEST_ID))
  },
  [GET_SCORING_LAYERS] ({ commit, getters }, planningRequest) {
    return scoringLocationsApi.getScoringLayers(planningRequest)
      .then((layers) => {
        setScoringLayersStyle(layers, getters[SCORING_LAYERS])
        commit(SET_SCORING_LAYERS, freezeRecursive(layers))
      })
      .finally(() => commit(REMOVE_CANCEL_CALLBACK, GET_SCORING_LAYERS_REQUEST_ID))
  },
  [RESET_SCORES_DATA] ({ commit, dispatch }) {
    dispatch(RESET_APPLIED_SCORING_BODY)
    commit(SET_SCORING_LOCATIONS, [])
    commit(SET_SCORING_STATISTICS, [])
    commit(SET_SCORING_DETAILS, null)
    commit(SET_SCORING_REPORTS, [])
    commit(SET_SCORING_LAYERS, [])
  }
}

// mutations
const mutations = {
  [SET_SCORING_LOCATIONS] (state, locations) {
    state.scores = locations.map(freezeRecursive)
  },
  [SET_SCORING_REPORTS] (state, reports) {
    state.reports = reports
  },
  [SET_SCORING_LAYERS] (state, layers) {
    state.layers = layers
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    siteDetails,
    scoresDetails,
    appliedScoring,
    scoringStatistics
  }
}
