
import { Component } from 'vue-property-decorator'
import { orderBy, findIndex, pick } from 'lodash'
import { SearchType } from '@/types/api/data-delivery'
import { Geometry, Geometries } from '@/types/common'
import { BTable } from 'bootstrap-vue'
import { TranslateResult } from 'vue-i18n'
import SearchableTaggableList from '@/components/shared/searchable-list/searchable-taggable-list/searchable-taggable-list.vue'

@Component
export default class SearchableGeometryList extends SearchableTaggableList {
  protected override searchType: SearchType = 'geometry'

  protected override get placeholder (): TranslateResult {
    return this.$t('common.placeholders.search-geometry')
  }

  protected override get tableItems (): BTable['items'] {
    let items: BTable['items'] = []

    items = (this.items as Geometries)
      .reduce((results, current) => {
        return findIndex(results, item => this.compareGeometry(current, item)) !== -1 ? results : [...results, current]
      }, [] as Geometries)
      .map(item => pick(item, ['id', 'name', 'tags', 'parentGeometrySetId']))
    orderBy(items, ['name', 'id'])

    return items
  }

  protected override isValidQuery (query: string): boolean {
    return query.length >= 2
  }

  protected override selectRows (rows: Geometries): void {
    const selected = (this.items as Geometries).filter(item => rows.some(row => this.compareGeometry(row, item)))
    this.$emit('selected', selected)
  }

  private compareGeometry (geometry1: Geometry, geometry2: Geometry): boolean {
    return geometry1.name === geometry2.name && geometry1.parentGeometrySetId === geometry2.parentGeometrySetId
  }
}
