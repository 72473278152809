import { render, staticRenderFns } from "./path-list.vue?vue&type=template&id=b15a2d10&scoped=true&"
import script from "./path-list.vue?vue&type=script&lang=ts&"
export * from "./path-list.vue?vue&type=script&lang=ts&"
import style0 from "./path-list.vue?vue&type=style&index=0&id=b15a2d10&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b15a2d10",
  null
  
)

export default component.exports