import { TranslateResult } from 'vue-i18n'
import { RANKING_IMPORT_WIZARD_TAB_DATASET_ERRORS } from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-add-dataset/getters'

export const RANKING_IMPORT_WIZARD_TAB_DATASET_LEAVE = 'ranking-import/dataset-tab/leave'
export const RANKING_IMPORT_WIZARD_TAB_DATASET_VALIDATE = 'ranking-import/dataset-tab/validate'

// actions
export const actions = {
  [RANKING_IMPORT_WIZARD_TAB_DATASET_VALIDATE] ({ getters }): Promise<TranslateResult[]> {
    return Promise.resolve(getters[RANKING_IMPORT_WIZARD_TAB_DATASET_ERRORS])
  },
  async [RANKING_IMPORT_WIZARD_TAB_DATASET_LEAVE] () {
    return Promise.resolve()
  }
}
