import { CirclesLayer, LinesLayer, PointsLayer, PolygonsLayer, ShapesLayer, VisualizationLayer } from './index'
import { LayerType, ShapeType } from './enums'
import { FillAwareStyle, LayerStyle, PathAwareStyle } from './style'

export function isPointsLayer (layer: VisualizationLayer): layer is PointsLayer {
  return layer.type === LayerType.Points
}

export function isCirclesLayer (layer: VisualizationLayer): layer is CirclesLayer {
  return layer.type === LayerType.Circles
}

export function isShapesLayer (layer: VisualizationLayer): layer is ShapesLayer {
  return layer.type === LayerType.Shapes
}

export function isPolygonsLayer (layer: VisualizationLayer): layer is PolygonsLayer {
  return isShapesLayer(layer) && layer.shapeType === ShapeType.Polygon
}

export function isLinesLayer (layer: VisualizationLayer): layer is LinesLayer {
  return isShapesLayer(layer) && layer.shapeType === ShapeType.Line
}

export function isFillAwareStyle (style: LayerStyle): style is FillAwareStyle {
  return 'fill' in style
}

export function isGeometryStyle (style: LayerStyle): style is PathAwareStyle {
  return 'path' in style
}
