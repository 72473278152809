import { cloneDeep } from 'lodash'
import { VisualizationLayer } from '@/types/visualization/layer'
import { LayerType } from '@/types/visualization/layer/enums'

type ParseAndFilterLayerFunc = (layer: VisualizationLayer) => boolean

export const transformSingleDatasetRequest = function (data) {
  const parseLayer: ParseAndFilterLayerFunc = function (layer) {
    if (layer.id !== data.id) { return false }

    layer.dataSource.geometrysetId = undefined
    layer.dataSource.linkingDatasetColumnId = undefined
    layer.dataSource.sorroundingGeometryIDs = []
    layer.type = LayerType.Points
    return true
  }

  return serializeVisualizationRequestBody(data.request, parseLayer)
}

export const transformSingleGeometryRequest = function (data) {
  const parseLayer: ParseAndFilterLayerFunc = function (layer) {
    if (layer.id !== data.id) { return false }

    layer.dataSource.datasetId = undefined
    layer.type = LayerType.Shapes
    return true
  }

  return serializeVisualizationRequestBody(data.request, parseLayer)
}

export const transformVisualizationRequestBody = function (data) {
  return serializeVisualizationRequestBody(data, () => true)
}

const serializeVisualizationRequestBody = function (data, parseLayer: ParseAndFilterLayerFunc): string {
  // Clone original object to avoid it's modification.
  const requestObj = cloneDeep(data)

  requestObj.savedLocations = JSON.stringify(requestObj.savedLocations)
  requestObj.layers = requestObj.layers.filter(parseLayer)

  requestObj.layers && requestObj.layers.forEach(layer => {
    layer.style = JSON.stringify(layer.style)
    layer.settings = JSON.stringify(layer.settings)
  })

  return JSON.stringify(requestObj)
}
