














import { Component, Mixins } from 'vue-property-decorator'
import LegendValuesMixin from '../../../../legend/mixins/legend-values.mixin'
import LayerTypeEditorMixin from './layer-type-editor.mixin'
import LegendSublayerMixin from '../../../../legend/mixins/legend-sublayer.mixin'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { get } from 'lodash'
import { LegendValue } from '@/types/visualization/map-legend'

@Component
export default class ColorLegendEditor extends
  Mixins<LegendValuesMixin<BorderedGeometryLayer>, LayerTypeEditorMixin, LegendSublayerMixin>(LegendValuesMixin, LayerTypeEditorMixin, LegendSublayerMixin) {
  private get defaultLegendValue (): LegendValue {
    return get(this.legendValues, '0')
  }

  private get legendValue (): Partial<LegendValue> {
    return get(this.attrs.legendValues, '0') ?? this.defaultLegendValue
  }

  private get label (): string {
    return this.legendValue.label as string
  }

  private set label (value) {
    const updatedValue = {
      key: this.legendValue.key,
      label: value
    }

    this.$emit('update:attrs', { legendValues: [updatedValue] })
  }

  private get isButtonDisabled (): boolean {
    return this.defaultLegendValue.label === this.legendValue.label
  }

  private resetLegendValue () {
    this.label = this.defaultLegendValue.label
  }
}
