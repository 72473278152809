import {
  WIZ_TABS_POS_LEFT,
  WIZ_TABS_POSITIONS
} from '../constants'
import { DynamicStoreNamespaceMixin } from '@/mixins/dynamic-store/dynamic-store-namespace.mixin'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export class WizardContainerPropsMixin extends Mixins<DynamicStoreNamespaceMixin>(DynamicStoreNamespaceMixin) {
  @Prop({ default: true }) protected readonly showTabs!: boolean
  @Prop({ default: WIZ_TABS_POS_LEFT, validator: value => WIZ_TABS_POSITIONS.includes(value) }) protected readonly tabsPosition!: string
}
