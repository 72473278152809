












import { Component, Vue } from 'vue-property-decorator'

type SelectHelpLink = {
  label: string;
  event: string;
}

@Component
export default class QuickSelectLinks extends Vue {
  private get links (): SelectHelpLink[] {
    return [
      {
        label: this.$t('common.labels.select-all') as string,
        event: 'select-all'
      },
      {
        label: this.$t('common.labels.select-none') as string,
        event: 'select-none'
      },
      {
        label: this.$t('common.labels.invert') as string,
        event: 'invert'
      }
    ]
  }
}
