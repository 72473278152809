import { Component, Vue } from 'vue-property-decorator'
import { LegendValue } from '@/types/visualization/map-legend'

@Component
export default class ClusteringLegendMixin<TLayer> extends Vue {
  protected layer!: TLayer

  protected makeClusteringLegendValues (values, colorGetter, opacity): LegendValue[] {
    const colorMap = {}

    for (const value in values) {
      if (!this.isSublayerVisible(this.layer, value)) {
        continue
      }

      const clusterColor = colorGetter(value)

      if (!colorMap[clusterColor]) {
        colorMap[clusterColor] = {
          color: clusterColor,
          opacity,
          label: value, // Use the first found value as label.
          allLabels: [],
          key: value
        }
      }

      colorMap[clusterColor].allLabels.push(value)
    }

    return Object.values(colorMap)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected isSublayerVisible (_layer: TLayer, _value: string) {
    return true
  }
}
