import { Dataset } from '@/types/common'
import { DatasetDTO } from '@/types/api'

export const parseDatasetRows = function (parsedResponse) {
  parsedResponse.columnInfos = (parsedResponse.columnInfos || []).map(info => {
    return {
      name: (info.name || info.Name).trim(),
      type: (info.type || info.Type).trim().toLowerCase(),
      ...info
    }
  })

  const columns = parsedResponse.columnInfos.map(({ name }) => name)

  parsedResponse.datasetRows = (parsedResponse.datasetRows || [])
    .map(row => row.reduce((result, val, index) => {
      return Object.assign({}, result, { [columns[index]]: val })
    }, {}))

  return parsedResponse
}

export function formatDatasetRows (dataset: Dataset): DatasetDTO {
  const columnOrder = dataset.columnInfos.map(column => column.name)
  const updatedDatasetRows = dataset.datasetRows
    .map(row => columnOrder.map(column => row[column]))

  return {
    columnInfos: dataset.columnInfos,
    rows: updatedDatasetRows
  }
}
