import { pick } from 'lodash'
import { FillType } from '@/types/visualization/layer/enums'
import { FILL_MODE_CONFIGS, FillModes } from './fill'
import { DEFAULT_VIS_OPACITY } from './visualization-defaults'
import { HATCHING } from '@/components/visualization/layer-settings/constants/hatching'

export const FILL_MODES_CIRCLE: Partial<FillModes> = pick(FILL_MODE_CONFIGS, [
  FillType.Color,
  FillType.Gradient,
  FillType.Median,
  FillType.Quartile,
  FillType.Decile,
  FillType.Clustering,
  FillType.Range
])

export const DEFAULT_CIRCLE_FILL_MODE = FillType.Color
export const DEFAULT_CIRCLE_COLOR = '#00C000'
export const DEFAULT_CIRCLE_OPACITY = DEFAULT_VIS_OPACITY
export const DEFAULT_CIRCLE_HATCHING = HATCHING
