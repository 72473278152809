// Campaign module
export const LOAD_PLAN_BY_ID = 'campaign/loadPlanById'
export const REMOVE_PLAN_BY_ID = 'campaign/removePlanById'
export const LOAD_CAMPAIGN_BY_ID = 'campaign/loadFullCampaignById'
export const SAVE_CAMPAIGN = 'campaign/save'
export const UPDATE_CAMPAIGN_SCORES = 'campaign/updateScoresAndLayers'
export const LOAD_SCORES_DETAILS = 'campaign/scoresDetails'
export const CHECK_SCORES_FOR_UNCLEAR_ASSIGNMENTS = 'campaign/CheckScoresForUnclearAssignments'
export const RESET_CAMPAIGN = 'campaign/resetCampaign'
export const ADD_TO_CACHE = 'campaign/addToCache'
export const REMOVE_FROM_CACHE = 'campaign/removeFromCache'
export const LOAD_CACHE_VALUES = 'campaign/loadCacheValues'
export const RELOAD_CAMPAIGN_DEPENDENCIES = 'campaign/reloadDependencies'
export const RELOAD_CAMPAIGN_TIMEFRAME = 'campaign/reloadTimeFrame'
export const INIT_WIZARD_FROM_CAMPAIGN = 'campaign/initWizard'
export const SET_CAMPAIGN_FROM_WIZARD = 'campaign/setFromWizard'
export const SAVE_CAMPAIGN_AS = 'campaign/saveCampaignAs'
export const VALIDATE_CAMPAIGN = 'campaign/validateCampaign'
export const EXCLUDE_SITE_FROM_CAMPAIGN = 'campaign/excludeSiteFromCampaign'
export const INCLUDE_SITE_TO_CAMPAIGN = 'campaign/includeSiteToCampaign'
export const UPDATE_CUSTOM_TIME_FRAME = 'campaign/updateCustomTimeFrame'
export const RESET_QUICK_FILTERS = 'campaign/resetQuickfilters'
export const SET_CAMPAIGN_AFTER_LOAD = 'campaign/setCampaignAfterLoad'
export const UPDATE_AREA_ASSIGNMENTS = 'campaign/updateAreaAssignments'
export const SAVE_TABLE_VIEW = 'campaign/saveTableView'
export const REMOVE_TABLE_VIEW = 'campaign/removeTableView'
export const UPDATE_TABLE_VIEW = 'campaign/updateTableView'
export const EXPORT_TO_OPS_BOOKED_NETWORK_TIMEFRAMES = 'campaign/exportToOpsBookedNetworkTimeFrames'

// Collections module
export const LOAD_BANNED_PRODUCTS = 'collections/bannedProducts/load'
export const LOAD_CITY_DATASET = 'collections/cityNames/load'
export const LOAD_MEDIA_OWNER_NAMES = 'collections/mediaOwnerNames/load'
export const LOAD_SITE_TYPES = 'collections/siteTypes/load'
export const LOAD_MEDIA_FILTER = 'collections/mediaFilter/load'

// Collections module (Combined goals)
export const LOAD_GOAL_TYPES = 'collections/planningGoalTypes/load'

// Decades module
export const LOAD_DECADES = 'decades/loadDecades'

// Customers module
export const LOAD_CUSTOMERS = 'customers/loadCustomers'

// Scoring module
export const GET_SCORING_SCORES = 'scoring/getScores'
export const GET_SCORING_LAYERS = 'scoring/getLayers'
export const RESET_SCORES_DATA = 'scoring/resetScoresData'
export const GET_SCORING_DETAILS = 'scoring/getScoresDetails'
export const GET_SCORING_SITE_DETAILS = 'scoring/siteDetails/getSiteDetails'
export const LOAD_SCORING_SITE_DETAILS = 'scoring/siteDetails/loadSiteDetails'
export const RESET_APPLIED_SCORING_BODY = 'scoring/appliedScoring/reset'
export const SET_APPLIED_SCORING_BODY = 'scoring/appliedScoring/set'

// User module
export const SET_USER = 'user/setUser'

// Visualization module - current visualization
export const NEW_CUSTOM_VISUALIZATION = 'visualizations/newCustom'
export const CREATE_NEW_VISUALIZATION = 'visualizations/create'
export const SET_VISUALIZATION = 'visualizations/setVisualization'
export const SET_VISUALIZATION_BY_ID = 'visualizations/setVisualizationById'
export const DELETE_VISUALIZATION_BY_ID = 'visualizations/deleteVisualizationById'
export const LOAD_VISUALIZATION_DATA = 'visualizations/loadData'
export const LOAD_GEOMETRY_FULL_DATA = 'visualizations/loadGeometryData'
export const LOAD_DATASET_FULL_DATA = 'visualizations/loadDatasetData'
export const SAVE_VISUALIZATION = 'visualizations/save'
export const CREATE_VISUALIZATION_LAYER = 'visualizations/createLayer'
export const CREATE_VISUALIZATION_LAYERS = 'visualizations/createLayers'
export const UPDATE_VISUALIZATION_LAYER_DATASET = 'visualizations/updateLayerDataset'
export const UPDATE_AND_REPLACE_LAYER = 'visualizations/updateReplaceLayer'
export const SET_VISUALIZATION_LAYER_VISIBILITY = 'visualizations/setLayerVisibility'
export const SET_VISUALIZATION_LAYER_SUBLAYER_OPTIONS = 'visualizations/setLayerSublayerOptions'
export const SET_VISUALIZATION_LAYER_SUBLAYER_VISIBILITY = 'visualizations/setLayerSublayerVisibility'
export const SET_VISUALIZATION_LAYER_SUBLAYER_COLOR = 'visualizations/setLayerSublayerColor'
export const SET_VISUALIZATION_LAYER_RANGE_COLOR = 'visualizations/setLayerRangeColor'
export const CREATE_VISUALIZATION_FROM_PLANNING = 'visualizations/createVisualizationFromPlanning'
export const SAVE_VISUALIZATION_AS = 'visualizations/saveAs'
export const SET_VISUALIZATION_LAYER_Z_INDEX = 'visualizations/setLayerZIndex'
export const VALIDATE_VISUALIZATION = 'visualizations/validate'
export const RESET_VISUALIZATION = 'visualizations/reset'
export const UPDATE_DATASOURCE_TO_VISUALIZATION_LAYER = 'visualizations/updateDatasourceToLayer'
export const CHECK_AND_UPDATE_LAYER_STYLE = 'visualizations/checkAndUpdateLayerStyle'
export const RESET_VISUALIZATION_LAYER_STYLE = 'visualizations/resetLayerStyle'
export const UPDATE_LAYER_RANGE_QUERY = 'visualizations/updateLayerQuery'
export const UPDATE_LAYER_ICONS_SPRITE = 'visualizations/updateLayerIconsSprite'
export const COPY_VISUALIZATION_LAYER = 'visualizations/copyLayer'

// Booking time frame module
export const LOAD_BOOKING_TIME = 'bookingTime/loadBookingTime'

// Planning wizard module
export const PLAN_WIZARD_RESET = 'planningWizard/reset'
export const PLAN_WIZARD_INIT = 'planningWizard/init'
export const PLAN_WIZARD_SET_FILTER_DATA = 'planningWizard/setFilterData'
export const PLAN_WIZARD_REMOVE_FILTER_DATA = 'planningWizard/removeFilterData'
export const PLAN_WIZARD_MODIFY_FILTER = 'planningWizard/modifyFilter'
export const PLAN_WIZARD_ADD_FILTER = 'planningWizard/addFilter'
export const PLAN_WIZARD_REMOVE_FILTER = 'planningWizard/removeFilter'
export const PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS = 'planningWizard/removeFilterByParams'
export const PLAN_WIZARD_ADD_FILTER_NODES = 'planningWizard/addFilterNodes'
export const PLAN_WIZARD_SET_ACTIVE_FILTER = 'planningWizard/setActiveFilter'
export const PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK = 'planningWizard/callStepLeaveCallback'
export const PLAN_WIZARD_LOAD_NETWORKS_FOR_CITIES = 'planningWizard/loadNetworksForCities'
export const PLAN_WIZARD_RELOAD_NETWORKS = 'planningWizard/reloadNetworks'
export const PLAN_WIZARD_REMOVE_NETWORK_NODES_AFTER_RELOAD = 'planningWizard/removeNetworks'
export const PLAN_WIZARD_COPY_FILTER_RECURSIVELY = 'planningWizard/copyFilterRecursively'

// Planning wizard tabs - common
export const WIZARD_TAB_COMMON_VALIDATE = 'planningWizard/tabs/common/validate'
export const WIZARD_TAB_COMMON_LEAVE = 'planningWizard/tabs/common/beforeLeave'

// Planning wizard tabs - location
export const WIZARD_TAB_LOCATION_VALIDATE = 'planningWizard/tabs/location/validate'
export const WIZARD_TAB_LOCATION_LEAVE = 'planningWizard/tabs/location/beforeLeave'

// Planning wizard steps - location
export const WIZARD_STEP_LOCATION_REMOVE_LOCATION = 'planningWizard/steps/location/removeLocation'

// Planning wizard tabs - media-types
export const WIZARD_TAB_MEDIA_TYPE_VALIDATE = 'planningWizard/tabs/mediaType/validate'
export const WIZARD_TAB_MEDIA_TYPE_LEAVE = 'planningWizard/tabs/mediaType/beforeLeave'

// Planning wizard steps - media-types
export const WIZARD_STEP_MEDIA_TYPE_SET_TYPES = 'planningWizard/steps/mediaType/setTypes'
export const WIZARD_STEP_MEDIA_TYPE_SET_AREA_SINGLE_TYPES = 'planningWizard/steps/mediaType/setSingleTypes'
export const WIZARD_STEP_MEDIA_TYPE_SET_AREA_NETWORK_TYPES = 'planningWizard/steps/mediaType/setNetworkTypes'
export const WIZARD_STEP_MEDIA_TYPE_ASSIGN_FLIGHTS = 'planningWizard/steps/mediaType/assignFlights'
export const WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES = 'planningWizard/steps/mediaType/removeTypes'
export const WIZARD_STEP_MEDIA_TYPE_REMOVE_SINGLE_TYPES_NODE = 'planningWizard/steps/mediaType/removeSingleTypesNode'
export const WIZARD_STEP_MEDIA_TYPE_REMOVE_NETWORK_TYPES_NODE = 'planningWizard/steps/mediaType/removeNetworkTypesNode'

// Planning wizard tabs - flights
export const WIZARD_TAB_FLIGHTS_VALIDATE = 'planningWizard/tabs/flights/validate'
export const WIZARD_TAB_FLIGHTS_LEAVE = 'planningWizard/tabs/flights/beforeLeave'

// Planning wizard steps - flights
export const WIZARD_STEP_FLIGHTS_CREATE_FLIGHT = 'planningWizard/steps/flights/createFlight'
export const WIZARD_STEP_FLIGHTS_UPDATE_FLIGHT = 'planningWizard/steps/flights/updateFlight'
export const WIZARD_STEP_FLIGHTS_REFRESH_FLIGHTS_DATA = 'planningWizard/steps/flights/refreshFlightsData'
export const WIZARD_STEP_FLIGHTS_INIT_FLIGHTS_FOR_LOCATIONS = 'planningWizard/steps/flights/initFlights'
export const WIZARD_STEP_FLIGHTS_SYNC_MEDIA_FLIGHTS = 'planningWizard/steps/flights/syncMediaFlights'
export const WIZARD_STEP_FLIGHTS_UPDATE_SYNC_FLIGHTS = 'planningWizard/steps/flights/updateSyncFlights'

// Planning wizard tabs - filters
export const WIZARD_TAB_FILTERS_VALIDATE = 'planningWizard/tabs/filters/validate'
export const WIZARD_TAB_FILTERS_LEAVE = 'planningWizard/tabs/filters/beforeLeave'

// Planning wizard tabs - rankings
export const WIZARD_TAB_RANKINGS_VALIDATE = 'planningWizard/tabs/rankings/validate'
export const WIZARD_TAB_RANKINGS_LEAVE = 'planningWizard/tabs/rankings/beforeLeave'

// Planning wizard steps - filters
export const WIZARD_STEP_FILTERS_LINK_LOCATIONS = 'planningWizard/steps/filters/linkLocations'
export const WIZARD_STEP_FILTERS_UNLINK_LOCATIONS = 'planningWizard/steps/filters/unlinkLocations'
export const WIZARD_STEP_FILTERS_REMOVE_FILTER = 'planningWizard/steps/filters/removeFilter'

// Planning wizard steps - rankings
export const WIZARD_STEP_RANKINGS_LINK_LOCATIONS = 'planningWizard/steps/rankings/linkLocations'
export const WIZARD_STEP_RANKINGS_UNLINK_LOCATIONS = 'planningWizard/steps/rankings/unlinkLocations'
export const WIZARD_STEP_RANKINGS_REMOVE_RANKING = 'planningWizard/steps/rankings/removeRanking'

// Planning wizard tabs - goals
export const WIZARD_TAB_GOALS_VALIDATE = 'planningWizard/tabs/goals/validate'
export const WIZARD_TAB_GOALS_LEAVE = 'planningWizard/tabs/goals/beforeLeave'

// Planning wizard tabs - common goals
export const WIZARD_TAB_COMMON_SETTINGS_VALIDATE = 'planningWizard/tabs/commonSettings/validate'
export const WIZARD_TAB_COMMON_SETTINGS_LEAVE = 'planningWizard/tabs/commonSettings/beforeLeave'

// Planning wizard steps - goals
export const WIZARD_STEP_GOALS_LINK_LOCATIONS = 'planningWizard/steps/goals/linkLocations'
export const WIZARD_STEP_GOALS_UNLINK_LOCATIONS = 'planningWizard/steps/goals/unlinkLocations'
export const WIZARD_STEP_GOALS_REMOVE_GOAL = 'planningWizard/steps/goals/removeGoal'

// Planning wizard steps - common settings
export const WIZARD_STEP_COMMON_SETTINGS_REMOVE_SETTING_NODE = 'planningWizard/steps/commonSettings/removeSettingNode'

// Vicinity import wizard
export const VICINITY_IMPORT_WIZARD_CHANGE_DATASET = 'vicinity-import/changeDataset'
export const VICINITY_IMPORT_WIZARD_RESET = 'vicinity-import/resetWizard'
export const VICINITY_IMPORT_WIZARD_SHOW = 'vicinity-import/showWizard'
export const VICINITY_IMPORT_WIZARD_CHANGE_QUERY_DATA = 'vicinity-import/changeQuery'
export const VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA = 'vicinity-import/resetQuery'
export const VICINITY_IMPORT_WIZARD_GET_RESULTS = 'vicinity-import/getResults'

// Vicinity import - dataset tab
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_LEAVE = 'vicinity-import/dataset-tab/leave'
export const VICINITY_IMPORT_WIZARD_TAB_DATASET_VALIDATE = 'vicinity-import/dataset-tab/validate'

// Vicinity import - query dataset tab
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_LEAVE = 'vicinity-import/query-dataset-tab/leave'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_INVALIDATE = 'vicinity-import/query-dataset-tab/invalidate'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_VALIDATE = 'vicinity-import/query-dataset-tab/validate'
export const VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_SKIP = 'vicinity-import/query-dataset-tab/skip'

// Vicinity import - parse dataset tab
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_INVALIDATE = 'vicinity-import/parse-dataset-tab/invalidate'
export const VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_VALIDATE = 'vicinity-import/parse-dataset-tab/validate'

// Vicinity import - parse dataset step
export const VICINITY_IMPORT_WIZARD_POINTS_CHANGE_CONTENT_TYPE = 'vicinity-import/parse-dataset-step/changeContentType'
export const VICINITY_IMPORT_WIZARD_POINTS_HANDLE_RESULTS = 'vicinity-import/parse-dataset-step/handleResults'
export const VICINITY_IMPORT_WIZARD_POINTS_RESET = 'vicinity-import/parse-dataset-step/resetPoints'
export const VICINITY_IMPORT_WIZARD_POINTS_VALIDATE = 'vicinity-import/parse-dataset-step/validatePoints'
export const VICINITY_IMPORT_WIZARD_POINTS_RESET_GEOCODE_RESULTS = 'vicinity-import/parse-dataset-step/resetGeocoding'

// Wizard navigation module
export const WIZARD_TAB_NAVIGATE = 'wizard/navigate'
export const WIZARD_TAB_GO_PREV = 'wizard/goBack'
export const WIZARD_TAB_GO_NEXT = 'wizard/goNext'
export const WIZARD_TAB_SKIP_STEP = 'wizard/skipStep'
export const WIZARD_TAB_ACTIVATE = 'wizard/activateStep'
export const WIZARD_TAB_ENTER = 'wizard/enterStep'
export const WIZARD_TAB_VALIDATE = 'wizard/validateStep'
export const WIZARD_TAB_LEAVE = 'wizard/beforeStepLeave'
export const WIZARD_INIT = 'wizard/init'
export const WIZARD_RESET = 'wizard/reset'
export const WIZARD_INVALIDATE_AFTER = 'wizard/invalidateAfter'

// New visualization wizard
export const NEW_VISUALIZATION_WIZARD_RESET = 'new-visualization/resetWizard'
export const NEW_VISUALIZATION_WIZARD_SHOW = 'new-visualization/showWizard'
export const NEW_VISUALIZATION_WIZARD_CREATE = 'new-visualization/save'
export const NEW_VISUALIZATION_WIZARD_CREATE_CUSTOM = 'new-visualization/createCustom'
export const NEW_VISUALIZATION_WIZARD_CREATE_FROM_PLANS = 'new-visualization/createFromPlans'

// New visualization wizard steps - entrypoint
export const NEW_VISUALIZATION_WIZARD_STEP_ENTRYPOINT_INIT_CUSTOM_VIS = 'new-visualization/entrypoint-step/initCustomVis'
export const NEW_VISUALIZATION_WIZARD_STEP_ENTRYPOINT_INIT_VIS_FROM_PLAN = 'new-visualization/entrypoint-step/initVisFromPlan'

// New visualization wizard tabs - entrypoint
export const NEW_VISUALIZATION_WIZARD_TAB_ENTRYPOINT_VALIDATE = 'new-visualization/entrypoint-tab/validate'

// New visualization wizard tabs - plans
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_VALIDATE = 'new-visualization/plans-tab/validate'
export const NEW_VISUALIZATION_WIZARD_TAB_PLANS_ACTIVATE = 'new-visualization/plans-tab/beforeActivate'

// New visualization wizard tabs - props
export const NEW_VISUALIZATION_WIZARD_TAB_PROPS_VALIDATE = 'new-visualization/props-tab/validate'

// Layer wizard module
export const LAYER_WIZARD_INIT_LAYER_DATA = 'layerWizard/initLayerData'
export const LAYER_WIZARD_RESET = 'layerWizard/reset'
export const LAYER_WIZARD_RESET_DATA = 'layerWizard/resetData'
export const LAYER_WIZARD_SHOW = 'layerWizard/show'
export const LAYER_WIZARD_CHANGE_DATASET = 'layerWizard/changeDataset'
export const LAYER_WIZARD_CHANGE_GEOMETRYSET = 'layerWizard/changeGeometryset'
export const LAYER_WIZARD_IMPORT_DATASET = 'layerWizard/importDataset'
export const LAYER_WIZARD_IMPORT_GEOMETRYSET = 'layerWizard/importGeometryset'
export const LAYER_WIZARD_LOAD_DATASET_DETAILS = 'layerWizard/loadDatasetDetails'

// Layer wizard tabs - layer type
export const LAYER_WIZARD_TAB_ENTRYPOINT_VALIDATE = 'layerWizard/tabs/entrypoint/validate'
export const LAYER_WIZARD_TAB_ENTRYPOINT_LEAVE = 'layerWizard/tabs/entrypoint/beforeLeave'

// Layer wizard tabs - geometry type
export const LAYER_WIZARD_TAB_GEOMETRY_VALIDATE = 'layerWizard/tabs/geometry/validate'
export const LAYER_WIZARD_TAB_GEOMETRY_LEAVE = 'layerWizard/tabs/geometry/beforeLeave'

// Layer wizard tabs - surrounding geometries
export const LAYER_WIZARD_TAB_SURROUNDING_VALIDATE = 'layerWizard/tabs/surrounding/validate'
export const LAYER_WIZARD_TAB_SURROUNDING_LEAVE = 'layerWizard/tabs/surrounding/beforeLeave'
export const LAYER_WIZARD_TAB_SURROUNDING_SKIP = 'layerWizard/tabs/surrounding/skip'

// Layer wizard tabs - area upload
export const LAYER_WIZARD_TAB_AREA_UPLOAD_VALIDATE = 'layerWizard/tabs/areaUpload/validate'
export const LAYER_WIZARD_TAB_AREA_UPLOAD_LEAVE = 'layerWizard/tabs/areaUpload/beforeLeave'

// Layer wizard tabs - granularity
export const LAYER_WIZARD_TAB_GRANULARITY_VALIDATE = 'layerWizard/tabs/granularity/validate'
export const LAYER_WIZARD_TAB_GRANULARITY_LEAVE = 'layerWizard/tabs/granularity/beforeLeave'
export const LAYER_WIZARD_TAB_GRANULARITY_SKIP = 'layerWizard/tabs/granularity/skip'

// Layer wizard tabs - POI dataset
export const LAYER_WIZARD_TAB_POI_DATASET_VALIDATE = 'layerWizard/tabs/poi-dataset/validate'
export const LAYER_WIZARD_TAB_POI_DATASET_LEAVE = 'layerWizard/tabs/poi-dataset/beforeLeave'

// Layer wizard tabs - area dataset
export const LAYER_WIZARD_TAB_AREA_DATASET_VALIDATE = 'layerWizard/tabs/area-dataset/validate'
export const LAYER_WIZARD_TAB_AREA_DATASET_LEAVE = 'layerWizard/tabs/area-dataset/beforeLeave'
export const LAYER_WIZARD_TAB_AREA_DATASET_SKIP = 'layerWizard/tabs/area-dataset/skip'

// Layer wizard tabs - area link
export const LAYER_WIZARD_TAB_AREA_LINK_VALIDATE = 'layerWizard/tabs/area-link/validate'
export const LAYER_WIZARD_TAB_AREA_LINK_LEAVE = 'layerWizard/tabs/area-link/beforeLeave'

// Layer wizard tabs - layer props
export const LAYER_WIZARD_TAB_PROPS_VALIDATE = 'layerWizard/tabs/props/validate'
export const LAYER_WIZARD_TAB_PROPS_LEAVE = 'layerWizard/tabs/props/beforeLeave'

// Layer wizard steps - entrypoint
export const LAYER_WIZARD_STEP_ENTRYPOINT_INIT_GEOMETRY = 'layerWizard/steps/entrypoint/initGeometry'
export const LAYER_WIZARD_STEP_ENTRYPOINT_INIT_POI = 'layerWizard/steps/entrypoint/initPOI'

// Layer wizard steps - geometry
export const LAYER_WIZARD_STEP_GEOMETRY_INIT_LINKED_DATASET = 'layerWizard/steps/geometry/initLinkedDataset'
export const LAYER_WIZARD_STEP_GEOMETRY_INIT_AREAS_SELECT = 'layerWizard/steps/geometry/initAreasSelect'
export const LAYER_WIZARD_STEP_GEOMETRY_INIT_AREAS_UPLOAD = 'layerWizard/steps/geometry/initAreasUpload'

// Layer wizard steps - surrounding
export const LAYER_WIZARD_STEP_SURROUNDING_INVALIDATE = 'layerWizard/steps/surrounding/invalidate'

// Layer wizard steps - poi dataset
export const LAYER_WIZARD_STEP_POI_DATASET_INVALIDATE = 'layerWizard/steps/poi-dataset/invalidate'

// Layer wizard steps - area link
export const LAYER_WIZARD_STEP_AREA_LINK_INVALIDATE = 'layerWizard/steps/area-link/invalidate'

// Layer wizard steps - area link
export const LAYER_WIZARD_STEP_AREA_UPLOAD_INVALIDATE = 'layerWizard/steps/area-upload/invalidate'

// Layer wizard steps - granularity
export const LAYER_WIZARD_STEP_GRANULARITY_LOAD_DATA = 'layerWizard/steps/granularity/loadData'
export const LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSET = 'layerWizard/steps/granularity/setGeometryset'
export const LAYER_WIZARD_STEP_GRANULARITY_INVALIDATE = 'layerWizard/steps/granularity/invalidate'

// Layer wizard steps - area dataset
export const LAYER_WIZARD_STEP_AREA_DATASET_INVALIDATE = 'layerWizard/steps/area-dataset/invalidate'

// Layer wizard steps - layer props
export const LAYER_WIZARD_STEP_PROPS_INVALIDATE = 'layerWizard/steps/props/invalidate'

// Layer wizard steps - layer props - points
export const LAYER_WIZARD_POINTS_SET_LATITUDE_COLUMN = 'layerWizard/steps/props/points/setLatitudeColumn'
export const LAYER_WIZARD_POINTS_SET_LONGITUDE_COLUMN = 'layerWizard/steps/props/points/setLongitudeColumn'
export const LAYER_WIZARD_POINTS_HANDLE_RESULTS = 'layerWizard/steps/props/points/handleGeocodeResults'
export const LAYER_WIZARD_POINTS_VALIDATE = 'layerWizard/steps/props/points/validate'
export const LAYER_WIZARD_POINTS_CHANGE_CONTENT_TYPE = 'layerWizard/steps/props/points/changeContentType'
export const LAYER_WIZARD_POINTS_RESET = 'layerWizard/steps/props/points/reset'
export const LAYER_WIZARD_POINTS_RESET_GEOCODE_RESULTS = 'layerWizard/steps/props/points/resetGeocodeResults'

// Layer wizard steps - layer props - geometry
export const LAYER_WIZARD_GEOMETRY_VALIDATE = 'layerWizard/steps/props/geometry/validate'
export const LAYER_WIZARD_GEOMETRY_RESET = 'layerWizard/steps/props/geometry/reset'

// Geometries module
export const LOAD_GEOMETRIES_COORDS = 'geometriesCache/loadCoords'

// Orders Module
// Scoring functions module
export const LOAD_ORDERS = 'orders/load'
export const LOAD_ORDER_BY_ID = 'orders/loadOrderById'
export const CREATE_ORDER = 'orders/create'
export const UPDATE_ORDER = 'orders/update'
export const CONFIRM_ORDER_DROP = 'orders/orderDropConfirm'
export const ORDER_UPDATE_POSITION = 'orders/updatePosition'
export const DECLINE_ORDER_DROP = 'orders/revertDropAction'
export const START_ORDER_DRAG = 'orders/startDragging'
export const STOP_ORDER_DRAG = 'orders/stopDragging'
export const SHOW_ORDER_DETAILS = 'orders/showDetails'
export const SHOW_ACTIVE_ORDER_ITEMS = 'orders/showItems'
export const RESET_ORDER_DETAILS = 'orders/resetDetails'
export const UPDATE_ACTIVE_ORDER = 'orders/updateActiveOrder'
export const UPDATE_ORDER_IN_LIST = 'orders/updateOrderInList'

// Analytics module
export const LOAD_ANALYTICS_DATASET_BY_ID = 'analytics/loadDatasetById'
export const RESET_ANALYTICS_DATASET = 'analytics/resetAnalyticsDataset'
export const SAVE_ANALYTICS_DATASET = 'analytics/saveDatasetById'
export const REMOVE_ANALYTICS_DATASET_BY_ID = 'analytics/removeDatasetById'

// New dataset wizard
export const DATASET_WIZARD_RESET = 'datasetWizard/resetWizard'
export const DATASET_WIZARD_RESET_DATA = 'datasetWizard/resetData'
export const DATASET_WIZARD_SHOW = 'datasetWizard/show'
export const DATASET_WIZARD_CHANGE_DATASET = 'datasetWizard/changeDataset'
export const DATASET_WIZARD_CREATE_DATASET = 'datasetWizard/createDataset'

// New dataset wizard tabs - entrypoint
export const DATASET_WIZARD_TAB_ENTRYPOINT_VALIDATE = 'datasetWizard/tabs/entrypoint/validate'
export const DATASET_WIZARD_TAB_ENTRYPOINT_LEAVE = 'datasetWizard/tabs/entrypoint/leave'

// New dataset wizard tabs - geocoding
export const DATASET_WIZARD_TAB_GEOCODING_VALIDATE = 'datasetWizard/tabs/geocoding/validate'
export const DATASET_WIZARD_TAB_GEOCODING_LEAVE = 'datasetWizard/tabs/geocoding/leave'

// New dataset wizard tabs - set dataset
export const DATASET_WIZARD_TAB_SET_DATASET_VALIDATE = 'datasetWizard/tabs/setDataset/validate'
export const DATASET_WIZARD_TAB_SET_DATASET_LEAVE = 'datasetWizard/tabs/setDataset/leave'

// New dataset wizard tabs - dataset props
export const DATASET_WIZARD_TAB_DATASET_PROPS_VALIDATE = 'datasetWizard/tabs/datasetProps/validate'
export const DATASET_WIZARD_TAB_DATASET_PROPS_LEAVE = 'datasetWizard/tabs/datasetProps/leave'

// New dataset wizard steps - entrypoint
export const DATASET_WIZARD_STEP_ENTRYPOINT_INIT_GEOCODING = 'datasetWizard/steps/entries/initGeocoding'
export const DATASET_WIZARD_STEP_ENTRYPOINT_INIT_TDLINX = 'datasetWizard/steps/entries/initTdlinx'
export const DATASET_WIZARD_STEP_ENTRYPOINT_INIT_GEOMETRIES = 'datasetWizard/steps/entries/initGeometries'

// New dataset wizard steps - geocoding
export const DATASET_WIZARD_GEOCODING_HANDLE_RESULTS = 'datasetWizard/steps/geocoding/handleResults'
export const DATASET_WIZARD_GEOCODING_VALIDATE = 'datasetWizard/steps/geocoding/validate'
export const DATASET_WIZARD_GEOCODING_RESET = 'datasetWizard/steps/geocoding/reset'
