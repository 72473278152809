import { Bounds, LatLng, latLng as toLatLng, LineUtil, Point } from 'leaflet'
import { ShapesLayer } from '@/lib/leaflet/layer/shapes-layer'
import { ProjectedMarker } from '@/lib/leaflet/layer/multi-markers/projected-marker'
import { ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

export interface MultiPointsLayerOptions extends ZIndexedPathOptions {
  size?: number;
}

export abstract class MultiPointsLayer<TOptions extends MultiPointsLayerOptions = MultiPointsLayerOptions> extends ShapesLayer<ProjectedMarker, TOptions> {
  protected _points!: Point[]

  protected _convertLatLngs (latlngs: LatLng[]): LatLng[] {
    const result: LatLng[] = []

    for (let i = 0; i < latlngs.length; i++) {
      result[i] = toLatLng(latlngs[i])
      this._bounds.extend(result[i])
    }

    return result
  }

  protected projectLatlngs (latlngs: LatLng[]): Bounds {
    const projectedBounds = new Bounds([])
    this._points = []

    for (let i = 0; i < latlngs.length; i++) {
      const point = this._map.latLngToLayerPoint(latlngs[i])
      const markerSize = this.getMarkerSize(i)

      projectedBounds.extend(point.subtract([markerSize, markerSize]))
      projectedBounds.extend(point.add([markerSize, markerSize]))

      this._points[i] = point
    }

    return projectedBounds
  }

  _update (): void {
    if (!this._map) {
      return
    }

    const bounds = this.clipShapes()
    this.clipShapesToBounds(bounds)
    this._updatePath()
  }

  protected clipShapesToBounds (bounds: Bounds | null): void {
    if (bounds == null) {
      return
    }

    const boundsTopLeft = bounds.getTopLeft()
    const boundsTopRight = bounds.getTopRight()
    const boundsBottomLeft = bounds.getBottomLeft()
    const boundsBottomRight = bounds.getBottomRight()

    for (let i = 0; i < this._points.length; i++) {
      const markerSize = this.getMarkerSize(i)

      if (
        bounds.contains(this._points[i]) ||
        LineUtil.pointToSegmentDistance(this._points[i], boundsTopLeft, boundsTopRight) < markerSize ||
        LineUtil.pointToSegmentDistance(this._points[i], boundsTopRight, boundsBottomRight) < markerSize ||
        LineUtil.pointToSegmentDistance(this._points[i], boundsBottomRight, boundsBottomLeft) < markerSize ||
        LineUtil.pointToSegmentDistance(this._points[i], boundsBottomLeft, boundsTopLeft) < markerSize
      ) {
        this._parts.push(new ProjectedMarker(this._points[i], markerSize, i))
      }
    }
  }

  protected abstract getMarkerSize (index?: number): number
}
