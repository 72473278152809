import { sortBy, uniqBy } from 'lodash'
import moment from '@/lib/moment'
import { bookingTimeApi } from '@/api/rest/constants/booking-time-frames.api'
import { isDecade } from '@/types/planning/guards'
import { DECADES } from '@/store/getter-types'
import { ADD_DECADES } from '@/store/mutation-types'
import { LOAD_BOOKING_TIME, LOAD_DECADES } from '@/store/action-types'
import { PeriodsType } from '@/types/enums'

const DECADES_TEMPLATE = {
  [PeriodsType.DecadesA]: /A/ig,
  [PeriodsType.DecadesB]: /B/ig,
  [PeriodsType.DecadesC]: /C/ig
}

const state = {
  decades: []
}

const getters = {
  [DECADES] (state) {
    return (decadesType) => {
      return !decadesType ? state.decades
        : state.decades.filter(({ value }) => value.match(DECADES_TEMPLATE[decadesType]))
    }
  }
}

const mutations = {
  [ADD_DECADES] (state, decadesToAdd) {
    let decades = [...state.decades, ...decadesToAdd]
    decades = uniqBy(decades, (decade) => `${decade.value}:${decade.numberOfYear}`)
    decades = sortBy(decades, ['year', 'numberOfYear'])

    if (decades.length !== state.decades.length) {
      state.decades = decades
    }
  }
}

const actions = {
  async [LOAD_DECADES] ({ commit, getters, dispatch }, { startDate, endDate }) {
    const range = moment.range(startDate, endDate)
    const existingDecades = getters[DECADES]()
    const daysToLoad = []

    for (const day of range.by('days')) {
      if (!existingDecades.some(decade => decade.range.contains(day))) {
        daysToLoad.push(day)
      }
    }

    if (daysToLoad.length === 0) {
      return
    }

    const start = daysToLoad[0].clone().startOf('month')
    const end = daysToLoad[daysToLoad.length - 1].clone().endOf('month')

    const timeFrames = await bookingTimeApi.getBookingTimeFrames(start, end)

    const decades = timeFrames
      .filter(isDecade)
      .map(({ year, value, start, end, numberOfYear }) => {
        return {
          year,
          value,
          range: moment.range(start, end),
          numberOfYear
        }
      })

    if (!decades.length) {
      return
    }

    commit(ADD_DECADES, decades)

    await dispatch(LOAD_BOOKING_TIME, timeFrames)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
