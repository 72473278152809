






















































import { Component, Prop, Ref } from 'vue-property-decorator'
import { LatLng } from 'leaflet'
import {
  ContextMenuCallback,
  ContextMenuCallbackEvent,
  ContextMenuCallbackGroupedEvent,
  ContextMenuItem
} from '@/types/leaflet'
import MapView from '@/components/shared/map-view.vue'
import { MultiCircleMarkers as MultiCircleMarkersInterface } from '@/components/map/types'
import MultiCircleMarkers from '@/components/map/components/multi-circle-markers.vue'
import { pickerLocationOldColor, pickerLocationNewColor } from '@/assets/style-variables'
import LayerContextMenu from '@/components/map/components/layer-context-menu.vue'
import MapContextMenu from '@/components/map/components/map-context-menu.vue'
import LSearchControl from '@/components/map/controls/l-search-control.vue'
import { SearchAreasMixin } from '@/mixins/search-areas.mixin'

type LocationInfo = {
  latLng: LatLng;
  isNew: boolean;
}

type StatItem = {
  value: number;
  label: string;
  class?: string;
}

@Component({
  components: { LSearchControl, MapContextMenu, MultiCircleMarkers, LayerContextMenu, MapView }
})
export default class LocationPicker extends SearchAreasMixin {
  @Prop({ required: true }) latlngs!: LatLng[]

  @Ref('markers') readonly markersRef!: MultiCircleMarkersInterface
  @Ref('map') readonly mapRef!: MapView

  locations: LocationInfo[] = [] as LocationInfo[]

  created () {
    this.locations = this.latlngs.map((latLng) => ({ latLng, isNew: false }))
  }

  private get mapMenuItems (): ContextMenuItem[] {
    return [{
      text: this.$t('common.location-picker.buttons.add-location') as string,
      callback: this.onAddLocation
    }]
  }

  private get locationMenuItems (): ContextMenuItem[] {
    return [{
      text: this.$t('common.location-picker.buttons.delete-location') as string,
      callback: this.onDeleteLocation as ContextMenuCallback
    }]
  }

  get resultLatlngs (): LatLng[] {
    return this.locations.map(loc => loc.latLng)
  }

  private get totalCount (): number {
    return this.locations.length
  }

  private get removedCount (): number {
    return this.latlngs.length - this.locations.reduce((sum, location) => sum + (+(!location.isNew)), 0)
  }

  private get addedCount (): number {
    return this.locations.reduce((sum, location) => sum + (+location.isNew), 0)
  }

  private get statItems (): StatItem[] {
    return [
      {
        label: this.$t('common.location-picker.labels.origin') as string,
        value: this.latlngs.length
      },
      {
        label: this.$t('common.location-picker.labels.added') as string,
        value: this.addedCount,
        class: 'locations--added'
      },
      {
        label: this.$t('common.location-picker.labels.removed') as string,
        value: this.removedCount,
        class: 'locations--removed'
      },
      {
        label: this.$t('common.location-picker.labels.total') as string,
        value: this.totalCount,
        class: 'locations--total'
      }
    ]
  }

  mounted () {
    this.$on('shown', () => this.updateMapBounds())
  }

  private updateMapBounds (): void {
    const bounds = this.markersRef.mapObject.getBounds()

    if (bounds.isValid()) {
      this.mapRef.fitBounds(bounds)
    }
  }

  private onAddLocation (e: ContextMenuCallbackEvent): void {
    this.locations.push({
      latLng: e.latlng,
      isNew: true
    })
  }

  private onDeleteLocation (e: ContextMenuCallbackGroupedEvent<LocationInfo>): void {
    const deletedIndex = this.locations.findIndex((loc) => loc === e.item)
    this.locations.splice(deletedIndex, 1)
  }

  private getMarkerColor (marker: LocationInfo): string {
    return marker.isNew ? pickerLocationNewColor : pickerLocationOldColor
  }

  private locationGroupFunc (location) {
    return location.isNew
  }
}
