




















import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import { Dataset } from '@/types/common'
import { FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import { UiControlMixin } from '../mixins/ui-control.mixin'
import PointsGrid from '@/components/planning/wizard/filter-editor/ui-controls/points-list-ui-element/grid/points-grid.vue'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
import {
  pointListFieldsSettings,
  PointsSettings
} from '@/components/planning/wizard/filter-editor/ui-controls/points-list-ui-element/points-settings'
import { Validations } from '@/decorators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import { unique } from '@/utils/validation'

type PointsDataset = Pick<Dataset, 'columnInfos' | 'datasetRows'>

@Component({
  components: { PointsGrid, FilterParamName }
})
export default class PointsUiElement extends Mixins<UiControlMixin<PointsDataset>>(UiControlMixin) implements FilterUIComponent<PointsDataset> {
  @Ref('grid') private readonly gridRef!: PointsGrid

  private fields: PointsSettings = pointListFieldsSettings

  @Watch('selected')
  private onSelectedChange (value: PointsDataset): void {
    this.$emit('change', value)
  }

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: {
        datasetRows: {
          required,
          minLength: minLength(1),
          $each: {
            name: {
              required,
              unique: unique(this.checkUniqValue)
            },
            latitude: {
              required
            },
            longitude: {
              required
            }
          }
        }
      }
    }
  }

  private checkUniqValue (value) {
    return this.gridRef.amountOfNamesOccurrences[value] === 1
  }
}
