import { PluginObject, VNode } from 'vue'
import { Geometries } from '@/types/common'
import { i18n } from '@/locales/i18n'
import ImportAreas from './import-areas.vue'

export type ImportAreasResult = {
  submitted: boolean;
  geometries: Geometries;
}

type ImportAreasFunction = () => Promise<ImportAreasResult>;

declare module 'vue/types/vue' {
  interface Vue {
    $importAreas: ImportAreasFunction;
  }
}

function configureDialog (pickerVNode: VNode) {
  const props = {
    size: 'md',
    centered: true,
    scrollable: true
  }

  const slots = {
    'modal-title': i18n.t('common.areas-import.title'),
    default: pickerVNode
  }

  return { props, slots }
}

export class ImportAreasPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$importAreas = async function (): Promise<ImportAreasResult> {
      const pickerVNode = this.$createElement(ImportAreas)

      const { props, slots } = configureDialog(pickerVNode)
      const submitted = await this.$modalDialog.msgBoxConfirmValid(slots, props)

      let geometries: Geometries = []

      if (submitted) {
        const pickerComp: ImportAreas = pickerVNode.componentInstance

        geometries = pickerComp.geometries
      }

      return { submitted, geometries }
    }
  }
}
