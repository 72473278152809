
























import { Component, Mixins } from 'vue-property-decorator'
import FileUpload from '@/components/file-upload/file-upload.vue'
import { FileMixin } from '@/mixins/file.mixin'
import { acceptFileFilters, IMAGE_FILE_TYPES } from '@/utils/file'

@Component({
  components: { FileUpload }
})
export default class ImagePicker extends Mixins(FileMixin) {
  fileData: string|null = null

  data () {
    const fileFilters = acceptFileFilters(IMAGE_FILE_TYPES)

    return { ...fileFilters }
  }

  private readFile (file: File): void {
    const reader = new FileReader()

    reader.onload = (e) => {
      this.fileData = e.target?.result as string
    }

    reader.onerror = () => {
      this.$toastError(this.$t('common.errors.reading-file') as string)
    }

    reader.readAsDataURL(file)
  }

  private importFile (file: File): void {
    this.file = file
    this.readFile(file)
  }

  private fileUploadCallback (): Promise<void> {
    return Promise.resolve()
  }
}
