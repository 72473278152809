






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { SelectOptionObject } from '@/types/bootstrap'
import { ASSIGN_RANDOMLY, assignmentOptions } from '@/plugins/resolve-unclear-assignments/quick-assignment/constant'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import PathList from '@/plugins/resolve-unclear-assignments/quick-assignment/path-list.vue'
import { UnclearAssignmentVariant } from '@/types/planning/unclear-assignments'

@Component({
  components: { PathList }
})
export default class QuickAssignment extends Vue {
  @Prop({ required: true }) private readonly campaignAreas!: ScoringFunctionNode[]

  private pathList: Partial<UnclearAssignmentVariant>[] = []
  private selectedOption: string = this.options[0].value

  private get isDraggableDisabled (): boolean {
    return this.selectedOption === ASSIGN_RANDOMLY
  }

  private get options (): SelectOptionObject[] {
    return Object.entries(assignmentOptions).map(([key, obj]) => ({ text: obj.label, value: key }))
  }

  private handleAssignProcess () {
    const callback = assignmentOptions[this.selectedOption].callback

    this.pathList = callback(this.pathList)

    this.$emit('update:assign', this.pathList.map(item => item.scoringFunctionId))
  }

  mounted () {
    this.pathList = this.campaignAreas.map((area) => ({ name: area.name, scoringFunctionId: area.scoringFunction.data[0].id as string }))
  }
}
