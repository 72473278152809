import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translations from './translations'
import { get, set } from 'lodash'
import { getAllTranslationPaths } from './utils'

Vue.use(VueI18n)
const storageKey = 'locale'

export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  silentFallbackWarn: true
})

export function initLocales () {
  const savedLocale = window.localStorage.getItem(storageKey)
  if (savedLocale) {
    i18n.locale = savedLocale
  }

  function setLocaleMessage (translations, locale) {
    const newTranslationObj = {}
    const paths = getAllTranslationPaths(translations, locale)

    paths.forEach(path => {
      set(newTranslationObj, path.replace(new RegExp(`(.${locale})$`), ''), get(translations, path))
    })

    i18n.setLocaleMessage(locale, newTranslationObj)
  }

  setLocaleMessage(translations, 'en')
  setLocaleMessage(translations, 'de')
}

export function setLocale (locale) {
  if (i18n.locale === locale) {
    return
  }

  i18n.locale = locale
  window.localStorage.setItem(storageKey, locale)
}
