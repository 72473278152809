import { AclRule } from '@/types/auth'
import { AuthRole } from '@/types/auth/roles'
import { AuthPermission } from '@/types/auth/permissions'
import { isDataObjectOwner } from '../utils/rules'
import { everyRule, hasAnyRole } from '../utils/rule-builders'

const canCreatePlan: AclRule = hasAnyRole(AuthRole.Planning)

const canViewPlan: AclRule = hasAnyRole(AuthRole.Planning)

const canEditPlan: AclRule = everyRule(
  hasAnyRole(AuthRole.Planning),
  isDataObjectOwner
)

export const planningRules = {
  [AuthPermission.CreatePlanning]: canCreatePlan,
  [AuthPermission.ViewPlanning]: canViewPlan,
  [AuthPermission.EditPlanning]: canEditPlan,
  [AuthPermission.DeletePlanning]: canEditPlan
}
