import { ApiResponse } from '@/api/types'
import { VisualizationLayer } from '@/types/visualization/layer'
import { parseLayers } from '@/utils/visualization/parse-layers'
import { getApiColumnType } from '@/utils/visualization/parse-layers/columns'
import { parseDatasetRows } from '@/utils/parse-dataset-rows'
import { createTransformResponse } from '@/api/utils'

export const transformScoringLayersResponse = createTransformResponse<VisualizationLayer[]>((response: ApiResponse['data']): VisualizationLayer[] => {
  if (!response) {
    return []
  }

  const layers = JSON.parse(response)

  layers.forEach((layer) => {
    const datasetData = layer.data.find((col) => getApiColumnType(col) === null)
    datasetData && parseDatasetRows(datasetData)
  })

  return parseLayers(layers)
})
