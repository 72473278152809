import {
  LAYER_WIZARD_CHANGE_DATASET,
  LAYER_WIZARD_CHANGE_GEOMETRYSET,
  LAYER_WIZARD_IMPORT_DATASET,
  LAYER_WIZARD_IMPORT_GEOMETRYSET,
  LAYER_WIZARD_POINTS_HANDLE_RESULTS,
  LAYER_WIZARD_RESET_DATA,
  LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSET,
  LAYER_WIZARD_TAB_ENTRYPOINT_LEAVE,
  LAYER_WIZARD_TAB_GEOMETRY_LEAVE
} from '@/store/action-types'
import { LAYER_WIZARD_ENTRYPOINT } from '@/store/getter-types'
import {
  LAYER_WIZARD_ADD_SURROUNDING_GEOMETRY,
  LAYER_WIZARD_REMOVE_SURROUNDING_GEOMETRY,
  LAYER_WIZARD_STEP_AREA_DATASET_SET_UPDATE_STATE,
  LAYER_WIZARD_STEP_AREA_LINK_SET_UPDATE_STATE,
  LAYER_WIZARD_STEP_AREA_UPLOAD_SET_UPDATE_STATE,
  LAYER_WIZARD_STEP_GRANULARITY_SET_UPDATE_STATE,
  LAYER_WIZARD_STEP_POI_DATASET_SET_UPDATE_STATE,
  LAYER_WIZARD_STEP_SURROUNDING_SET_UPDATE_STATE,
  LAYER_WIZARD_SET_LAYER_NAME,
  LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE
} from '@/store/mutation-types'

const entrypointNavigationActions = [
  LAYER_WIZARD_TAB_ENTRYPOINT_LEAVE,
  LAYER_WIZARD_TAB_GEOMETRY_LEAVE
]

const updateStateActions = {
  [LAYER_WIZARD_IMPORT_DATASET]: [LAYER_WIZARD_STEP_AREA_DATASET_SET_UPDATE_STATE, LAYER_WIZARD_STEP_POI_DATASET_SET_UPDATE_STATE],
  [LAYER_WIZARD_CHANGE_DATASET]: [LAYER_WIZARD_STEP_AREA_DATASET_SET_UPDATE_STATE, LAYER_WIZARD_STEP_POI_DATASET_SET_UPDATE_STATE],
  [LAYER_WIZARD_IMPORT_GEOMETRYSET]: [LAYER_WIZARD_STEP_AREA_LINK_SET_UPDATE_STATE, LAYER_WIZARD_STEP_AREA_UPLOAD_SET_UPDATE_STATE],
  [LAYER_WIZARD_CHANGE_GEOMETRYSET]: [LAYER_WIZARD_STEP_AREA_LINK_SET_UPDATE_STATE, LAYER_WIZARD_STEP_AREA_UPLOAD_SET_UPDATE_STATE],
  [LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSET]: [LAYER_WIZARD_STEP_GRANULARITY_SET_UPDATE_STATE],
  [LAYER_WIZARD_POINTS_HANDLE_RESULTS]: [LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE]
}

const updateStateMutations = {
  [LAYER_WIZARD_ADD_SURROUNDING_GEOMETRY]: LAYER_WIZARD_STEP_SURROUNDING_SET_UPDATE_STATE,
  [LAYER_WIZARD_REMOVE_SURROUNDING_GEOMETRY]: LAYER_WIZARD_STEP_SURROUNDING_SET_UPDATE_STATE,
  [LAYER_WIZARD_SET_LAYER_NAME]: LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE
}

let currentEntryPoint = null

export default store => {
  store.subscribeAction({
    after: (action) => {
      if (entrypointNavigationActions.includes(action.type)) {
        const newEntryPoint = store.getters[LAYER_WIZARD_ENTRYPOINT]

        if (currentEntryPoint !== newEntryPoint) {
          store.dispatch(LAYER_WIZARD_RESET_DATA)
        }

        currentEntryPoint = newEntryPoint
      }

      if (action.type in updateStateActions) {
        updateStateActions[action.type].forEach(updateStateAction => store.commit(updateStateAction, true))
      }
    }
  })

  store.subscribe(mutation => {
    if (mutation.type in updateStateMutations) {
      store.commit(updateStateMutations[mutation.type], true)
    }
  })
}
