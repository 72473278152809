























import { SliderDraggableMixin } from '../mixin/slider-draggable.mixin'
import { DirectionAwareMixin } from '../mixin/direction-aware.mixin'
import { Component, Model, Mixins, Prop, Emit } from 'vue-property-decorator'
import { VueDynamicClassDef } from '@/types/vue'
import { Properties } from 'csstype'

@Component
export default class Slider extends Mixins(DirectionAwareMixin, SliderDraggableMixin) {
  @Model('change', { required: true }) readonly value!: number

  @Prop({ default: 0 }) min!: number
  @Prop({ default: 1 }) max!: number

  private get sliderClass (): VueDynamicClassDef {
    return {
      'color-picker__slider--vertical': this.isVertical,
      'color-picker__slider--horizontal': !this.isVertical
    }
  }

  private get pointerStyle (): Properties {
    const pointPos = `${(this.value - this.min) / (this.max - this.min) * 100}%`
    return {
      left: this.isVertical ? '50%' : pointPos,
      top: this.isVertical ? pointPos : '50%'
    }
  }

  @Emit('change')
  protected override emitSliderChange (x: number, y: number): number | void {
    const pos = this.isVertical ? y : x
    const newValue = this.min + pos * (this.max - this.min)

    if (newValue !== this.value) {
      return newValue
    }
  }
}
