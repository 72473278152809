






import { Component, Prop, Vue } from 'vue-property-decorator'
import { StaticLayerPosition } from '@/types/visualization/layer/enums'
import { Control, Map } from 'leaflet'
import { propsBinder } from 'vue2-leaflet'
import { PropsDefinition } from 'vue/types/options'
import { AttributionControl } from '@/lib/leaflet/control/attribution-control/attribuition-control'

@Component
export default class LAttributionControl extends Vue {
  @Prop({ default: StaticLayerPosition.BottomLeft }) private readonly position!: StaticLayerPosition

  ready = false
  mapObject!: Control.Attribution

  mounted () {
    this.mapObject = AttributionControl(this.$props)
    propsBinder(this, this.mapObject as unknown as Map, this.$options.props as PropsDefinition<Vue>)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.mapObject.addTo(this.$parent.mapObject)
    this.ready = true
  }
}
