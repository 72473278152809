import { difference, flatten, get } from 'lodash'
import {
  PLAN_WIZARD_FILTER_BY_ID,
  PLAN_WIZARD_GENERATE_FILTER_ID,
  PLAN_WIZARD_MEDIA_NETWORKS_COLLECTION,
  PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE,
  PLAN_WIZARD_SELECTED_TYPES,
  SITE_TYPES,
  WIZARD_STEP_MEDIA_TYPE_GET_AREA_NETWORK_TYPES,
  WIZARD_STEP_MEDIA_TYPE_GET_AREA_SINGLE_TYPES,
  WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES,
  WIZARD_STEP_MEDIA_TYPE_GET_MERGED_AREA_NETWORK_TYPE,
  WIZARD_STEP_MEDIA_TYPE_GET_MERGED_AREA_SINGLE_TYPE,
  WIZARD_STEP_MEDIA_TYPE_LOCATIONS_HAVE_TYPES,
  WIZARD_STEP_MEDIA_TYPE_IS_NETWORK_AVAILABLE
} from '@/store/getter-types'
import {
  PLAN_WIZARD_ADD_FILTER_NODES,
  PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS,
  WIZARD_STEP_FLIGHTS_INIT_FLIGHTS_FOR_LOCATIONS,
  WIZARD_STEP_MEDIA_TYPE_ASSIGN_FLIGHTS,
  WIZARD_STEP_MEDIA_TYPE_SET_AREA_NETWORK_TYPES,
  WIZARD_STEP_MEDIA_TYPE_SET_AREA_SINGLE_TYPES,
  WIZARD_STEP_MEDIA_TYPE_SET_TYPES,
  WIZARD_STEP_MEDIA_TYPE_REMOVE_SINGLE_TYPES_NODE,
  WIZARD_STEP_MEDIA_TYPE_REMOVE_NETWORK_TYPES_NODE,
  WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES
} from '@/store/action-types'
import { FILTER_MEDIAOWNERNETWORK, FILTER_MEDIA_TYPE, FILTER_CITY, FILTER_AREA } from '@/constants/scoring'
import { isNetworkMediaTypeFilterNode, isSingleMediaTypeFilterNode } from '@/utils/plan-wizard-steps'
import { areaFiltersDataPath, supportedGeometrysetsForMON } from '@/store/planning-wizard/media-owner-networks-store/constant'
import { NewScoringFunctionNode, ScoringFunctionNode } from '@/types/planning/scoring/functions'

const SINGLE_VALUES_PATH = 'data.0.SiteTypes'
const NETWORK_VALUES_PATH = 'data.0.MediaOwnerNetworks'

function getFlatParamListFromNodes (typeNodes: ScoringFunctionNode[], path: string) {
  const params = typeNodes.map((typeNode) => get(typeNode.scoringFunction, path), [])
  return flatten(params)
}

function buildSingleTypeNode (getters, areaId: string, values: string[]): NewScoringFunctionNode {
  const areaNode = getters[PLAN_WIZARD_FILTER_BY_ID](areaId) as ScoringFunctionNode

  return {
    id: null,
    areaId,
    name: get(areaNode, 'name', ''),
    scoringFunction: {
      name: FILTER_MEDIA_TYPE,
      data: [{
        SiteTypes: values
      }]
    }
  }
}

function buildNetworkTypeNode (getters, areaId: string, values: string[]): NewScoringFunctionNode {
  const areaNode = getters[PLAN_WIZARD_FILTER_BY_ID](areaId) as ScoringFunctionNode

  return {
    id: null,
    areaId,
    name: get(areaNode, 'name', ''),
    scoringFunction: {
      name: FILTER_MEDIAOWNERNETWORK,
      data: [{
        MediaOwnerNetworks: values
      }]
    }
  }
}

function typeValueParser (typeValue) {
  return typeValue
}

function networkTypeValueParser (typeValue) {
  return typeValue.id
}

// initial state
const state = {}

// getters
const getters = {
  [WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES] (_state, getters): (areaId: string) => ScoringFunctionNode[] {
    return (areaId) => {
      return getters[PLAN_WIZARD_SELECTED_TYPES].filter((node) => node.areaId === areaId)
    }
  },
  [WIZARD_STEP_MEDIA_TYPE_GET_AREA_SINGLE_TYPES] (_state, getters): (areaId: string) => ScoringFunctionNode[] {
    return (areaId) => {
      return getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES](areaId).filter(isSingleMediaTypeFilterNode)
    }
  },
  [WIZARD_STEP_MEDIA_TYPE_GET_AREA_NETWORK_TYPES] (_state, getters): (areaId: string) => ScoringFunctionNode[] {
    return (areaId) => {
      return getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES](areaId).filter(isNetworkMediaTypeFilterNode)
    }
  },
  [WIZARD_STEP_MEDIA_TYPE_GET_MERGED_AREA_SINGLE_TYPE] (_state, getters): (areaId: string) => NewScoringFunctionNode {
    return (areaId) => {
      const typeNodes = getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_SINGLE_TYPES](areaId)
      const siteTypes = getFlatParamListFromNodes(typeNodes, SINGLE_VALUES_PATH)

      return buildSingleTypeNode(getters, areaId, siteTypes)
    }
  },
  [WIZARD_STEP_MEDIA_TYPE_GET_MERGED_AREA_NETWORK_TYPE] (_state, getters): (areaId: string) => NewScoringFunctionNode {
    return (areaId) => {
      const networkNodes = getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_NETWORK_TYPES](areaId)
      const networks = getFlatParamListFromNodes(networkNodes, NETWORK_VALUES_PATH)

      return buildNetworkTypeNode(getters, areaId, networks)
    }
  },
  [WIZARD_STEP_MEDIA_TYPE_LOCATIONS_HAVE_TYPES] (_state, getters): boolean {
    const areasWithoutTypes = getters[PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE].filter(area => {
      return getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_TYPES](area.id).length === 0
    })
    return areasWithoutTypes.length === 0
  },
  [WIZARD_STEP_MEDIA_TYPE_IS_NETWORK_AVAILABLE] (_state, getters): (areaId: string) => boolean {
    return (areaId) => {
      const filterNode = getters[PLAN_WIZARD_FILTER_BY_ID](areaId) as ScoringFunctionNode

      if (filterNode?.scoringFunction.name === FILTER_CITY) {
        return true
      }

      const areaKeys = get(filterNode.scoringFunction.data, areaFiltersDataPath[FILTER_AREA], [])

      return areaKeys.some(area => supportedGeometrysetsForMON.includes(area.parentGeometrySetId))
    }
  }
}

// actions
const actions = {
  async [WIZARD_STEP_MEDIA_TYPE_SET_TYPES] (
    { dispatch, getters },
    { nodes, areaId, scoringFunction, valuesPath, buildNode, constantCollection, typeValueParser }
  ): Promise<void> {
    const existingValues = getFlatParamListFromNodes(nodes, valuesPath)
    const newValues = get(scoringFunction, valuesPath, [])
    let parentTypes = newValues
    let newTypeValues = newValues

    // remove child subtypes already covered by selection of a related parent
    if (valuesPath === SINGLE_VALUES_PATH) {
      parentTypes = newValues.filter(tag => tag.indexOf(' ') === -1)
      newTypeValues = newValues.filter(tag => parentTypes.includes(tag) || parentTypes.every(p => tag.indexOf(p) === -1))
    }

    const typesToRemove = difference(existingValues, newTypeValues)
    const typesToAdd = difference(newTypeValues, existingValues)

    const filtersToRemove = nodes
      .filter((filterNode) => {
        const nodeValue = get(filterNode.scoringFunction, `${valuesPath}.0`)
        return typesToRemove.includes(nodeValue)
      })

    await dispatch(WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES, filtersToRemove)
    const generateFilterId = getters[PLAN_WIZARD_GENERATE_FILTER_ID]

    const getConstantName = (value) => {
      const parsedValue = typeValueParser(value)
      const collectionItem = constantCollection.find((item) => item.value === parsedValue)
      return collectionItem?.text ?? parsedValue
    }

    const newNodes = typesToAdd.map((typeValue) => ({
      ...buildNode(getters, areaId, [typeValue]),
      id: generateFilterId(),
      name: getConstantName(typeValue)
    }))

    return dispatch(PLAN_WIZARD_ADD_FILTER_NODES, newNodes).then(() => {
      return dispatch(WIZARD_STEP_MEDIA_TYPE_ASSIGN_FLIGHTS)
    })
  },
  [WIZARD_STEP_MEDIA_TYPE_SET_AREA_SINGLE_TYPES] ({ dispatch, getters }, { areaId, scoringFunction }: ScoringFunctionNode): Promise<void> {
    return dispatch(WIZARD_STEP_MEDIA_TYPE_SET_TYPES, {
      nodes: getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_SINGLE_TYPES](areaId),
      areaId,
      scoringFunction,
      valuesPath: SINGLE_VALUES_PATH,
      constantCollection: getters[SITE_TYPES],
      buildNode: buildSingleTypeNode,
      typeValueParser
    })
  },
  [WIZARD_STEP_MEDIA_TYPE_SET_AREA_NETWORK_TYPES] ({ dispatch, getters }, { areaId, scoringFunction }: ScoringFunctionNode): Promise<void> {
    return dispatch(WIZARD_STEP_MEDIA_TYPE_SET_TYPES, {
      nodes: getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_NETWORK_TYPES](areaId),
      areaId,
      scoringFunction,
      valuesPath: NETWORK_VALUES_PATH,
      constantCollection: getters[PLAN_WIZARD_MEDIA_NETWORKS_COLLECTION],
      buildNode: buildNetworkTypeNode,
      typeValueParser: networkTypeValueParser
    })
  },
  [WIZARD_STEP_MEDIA_TYPE_ASSIGN_FLIGHTS] ({ dispatch }): Promise<void> {
    return dispatch(WIZARD_STEP_FLIGHTS_INIT_FLIGHTS_FOR_LOCATIONS)
  },
  async [WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES] ({ dispatch }, nodes: ScoringFunctionNode[]) {
    const filterIdsToRemove = nodes
      .map(filterNode => filterNode.id)

    const idsToRemove = new Set(filterIdsToRemove)
    await dispatch(PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS, (filterNode) => {
      return idsToRemove.has(filterNode.id) || idsToRemove.has(filterNode.typeId)
    })
  },
  async [WIZARD_STEP_MEDIA_TYPE_REMOVE_SINGLE_TYPES_NODE] ({ getters, dispatch }, areaId: string): Promise<void> {
    const typeNodes = getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_SINGLE_TYPES](areaId) as ScoringFunctionNode[]
    await dispatch(WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES, typeNodes)
  },
  async [WIZARD_STEP_MEDIA_TYPE_REMOVE_NETWORK_TYPES_NODE] ({ getters, dispatch }, areaId: string): Promise<void> {
    const networkNodes = getters[WIZARD_STEP_MEDIA_TYPE_GET_AREA_NETWORK_TYPES](areaId) as ScoringFunctionNode[]
    await dispatch(WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES, networkNodes)
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
