


















import { Component, Prop, Ref, Model, Vue, Watch } from 'vue-property-decorator'
import { ColDef, GridApi, ModelUpdatedEvent, RowNode, SelectionChangedEvent } from 'ag-grid-community'
import SourceSwitcher from './source-switcher.vue'
import AgGrid from '@/components/shared/ag-grid/ag-grid.vue'
import { DatasetRows } from '@/types/common'
import { isEqual, mergeWith, uniq } from 'lodash'
import { SourceSelectorMode } from '@/types/enums'
import { defaultGridOptions, configureSelectorGridColumnDefs } from './../grid-config'
import { VueInstanceProps } from '@/types/vue'

@Component({
  components: { AgGrid, SourceSwitcher }
})
export default class SelectorGrid extends Vue {
  @Model('change', { required: true }) private value!: DatasetRows
  @Prop({ default: () => null }) private tableProps!: VueInstanceProps | null
  @Prop({ default: () => null }) private tableListeners!: VueInstanceProps | null
  @Prop({ default: '' }) readonly allLabel!: string
  @Prop({ default: '' }) readonly selectedLabel!: string
  @Ref('grid') gridRef!: AgGrid

  private selectorDisplayOption: SourceSelectorMode = SourceSelectorMode.All
  private selectedItems: DatasetRows = [] as DatasetRows

  created () {
    this.selectedItems = this.value
  }

  @Watch('value')
  private onValueChange () {
    if (!isEqual(this.value, this.selectedItems)) {
      this.selectedItems = this.value
      this.$nextTick(() => {
        this.handleRowChangedEvent()
      })
    }
  }

  private get datasetRows (): DatasetRows {
    return this.tableProps ? this.tableProps.rowData : []
  }

  private get mergedTableListeners (): VueInstanceProps {
    return {
      rowDataChanged: this.handleRowChangedEvent,
      selectionChanged: this.handleSelectionChanged,
      modelUpdated: this.handleModelUpdatedEvent,
      ...(this.tableListeners ?? {})
    }
  }

  private get mergedTableProps (): VueInstanceProps {
    const mergedProps = mergeWith(this.tableProps, defaultGridOptions, (objValue, srcValue, key) => {
      if (key === 'statusPanels') {
        return uniq([...(objValue ?? []), ...srcValue])
      }

      return undefined
    })

    return {
      ...mergedProps,
      columnDefs: this.columnDefinitions,
      rowData: this.rowData
    }
  }

  private get columnDefinitions (): ColDef[] {
    const predefinedColDefs = this.tableProps ? this.tableProps.columnDefs : []

    return configureSelectorGridColumnDefs(predefinedColDefs)
  }

  private get rowData (): DatasetRows {
    if (this.selectorDisplayOption === SourceSelectorMode.All) {
      return this.datasetRows
    }

    return this.selectedItems
  }

  private async handleRowChangedEvent () {
    await this.gridRef.callGridApiOrQueue((gridApi: GridApi) => {
      if (this.selectorDisplayOption === SourceSelectorMode.Selected) {
        gridApi.selectAll()
        return
      }

      gridApi.forEachNode((node: RowNode) => {
        if (this.selectedItems.some(item => isEqual(item, node.data))) {
          node.setSelected(true)
        }
      })
    })
  }

  private handleSelectionChanged (event: SelectionChangedEvent) {
    const selectedRows = event.api.getSelectedRows()

    if (!isEqual(this.value, selectedRows)) {
      this.$emit('change', selectedRows)
    }
  }

  private handleSelectorModeChange (value: SourceSelectorMode): void {
    this.gridRef.callGridApiOrQueue((gridApi: GridApi) => {
      gridApi.setFilterModel(null)
      gridApi.setQuickFilter(null)
      this.selectorDisplayOption = value
    })
  }

  private handleModelUpdatedEvent (event: ModelUpdatedEvent): void {
    const displayedRowCount = event.api.getDisplayedRowCount()

    if (displayedRowCount === 0) {
      event.api.showNoRowsOverlay()
      return
    }

    event.api.hideOverlay()

    this.$emit('model-update', event)
  }
}
