import { nanoid } from 'nanoid'
import { castArray } from 'lodash'
import SaveAsComponent from './save-as.vue'
import { PluginObject } from 'vue'
import { TranslateResult } from 'vue-i18n'

interface SaveAsOptions {
  label: TranslateResult,
  name: string,
  title: TranslateResult,
  placeholder: TranslateResult,
  validator: (customName: string) => Promise<boolean>
}

type SaveAsFunction = (options: SaveAsOptions) => Promise<string>;

declare module 'vue/types/vue' {
  interface Vue {
    $saveAs: SaveAsFunction
  }
}

export class SaveAsPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$saveAs = function (options: SaveAsOptions): Promise<string> {
      return new Promise((resolve) => {
        const msgBoxId = `message-box-${nanoid()}`
        const h = this.$createElement
        const { label, name = '', title, placeholder, validator } = options
        const saveAsNode = h(SaveAsComponent, {
          props: {
            label,
            name,
            placeholder
          }
        })

        const showErrors = (errors) => {
          castArray(errors).forEach(error => {
            this.$toastError(this.$t(error))
          })
        }

        const closeListener = (bvEvent, modalId) => {
          if (bvEvent.trigger === 'ok' && modalId === msgBoxId) {
            bvEvent.preventDefault()

            const customName = saveAsNode.componentInstance.customName

            validator(customName)
              .then(() => {
                resolve(customName)

                this.$root.$off('bv::modal::hide', closeListener)
                this.$root.$emit('bv::hide::modal', msgBoxId)
              })
              .catch(errors => {
                showErrors(errors)
              })
          }

          if (bvEvent.trigger === 'cancel' || bvEvent.trigger === 'backdrop') {
            this.$root.$off('bv::modal::hide', closeListener)
          }
        }

        this.$root.$on('bv::modal::hide', closeListener)

        this.$bvModal.msgBoxConfirm([saveAsNode], {
          title: title,
          okVariant: 'success',
          cancelVariant: 'secondary',
          okTitle: this.$t('common.buttons.confirm'),
          cancelTitle: this.$t('common.buttons.cancel'),
          centered: true,
          id: msgBoxId
        })
      })
    }
  }
}
