











import LegendValuesMixin from '@/components/visualization/legend/mixins/legend-values.mixin'
import LegendSublayerMixin from '@/components/visualization/legend/mixins/legend-sublayer.mixin'
import LegendValuesTable from '@/components/visualization/legend/legend-views/legend-values-table.vue'
import { formatLegendValue } from '@/components/visualization/tiptap/utils'
import LayerTypeEditorMixin from '../layer-types/layer-type-editor.mixin'
import SelectDecimals from './components/select-decimals.vue'
import { Component, Mixins } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'

@Component({
  components: { SelectDecimals, LegendValuesTable }
})
export default class QuantileLegendEditor extends
  Mixins<LegendValuesMixin<BorderedGeometryLayer>, LegendSublayerMixin, LayerTypeEditorMixin>(LegendValuesMixin, LegendSublayerMixin, LayerTypeEditorMixin) {
  private get decimals (): number {
    return this.attrs.maxDecimals as number
  }

  private set decimals (value) {
    this.$emit('update:attrs', { maxDecimals: value })
  }

  override formatLegendValue (value) {
    return formatLegendValue<number>(value, this.attrs.maxDecimals)
  }
}
