

















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { cloneDeep } from 'lodash'
import { QueryRule } from '@/types/query-builder'
import { QueryOperator } from '@/types/query-builder/enums'
import { ConfiguredRule } from '../types'
import { QueryCompMixin } from '../mixins/query-comp.mixin'
import { ruleComponents } from '../constants/rule-components'
import TextRule from './rules/text-rule.vue'

type RuleTapCallback = (rule: QueryRule) => void

@Component({
  components: { TextRule }
})
export default class QueryBuilderRule extends Mixins(QueryCompMixin) {
  @Prop({ required: true }) readonly value!: QueryRule

  private get rule (): ConfiguredRule {
    return this.rules.find((rule) => rule.id === this.value.rule) as ConfiguredRule
  }

  private get hasOperands (): boolean {
    return (this.rule.operands?.length ?? 0) > 0
  }

  private get shouldSelectOperator (): boolean {
    return this.rule.operators.length > 1
  }

  private get ruleValue (): unknown {
    return this.value.value
  }

  private set ruleValue (value: unknown) {
    this.emitChangeWith((rule) => {
      rule.value = value
    })
  }

  private get ruleOperator (): QueryOperator {
    return this.value.operator
  }

  private set ruleOperator (value: QueryOperator) {
    this.emitChangeWith((rule) => {
      rule.operator = value
    })
  }

  private get ruleOperand (): string {
    return this.value.operand
  }

  private set ruleOperand (value: string) {
    this.emitChangeWith((rule) => {
      rule.operand = value
    })
  }

  private getRuleComponent (): VueConstructor {
    return ruleComponents[this.rule.type]
  }

  private emitChangeWith (callback: RuleTapCallback): void {
    const query = cloneDeep(this.value)
    callback(query)
    this.$emit('change', query)
  }
}

