import { pick } from 'lodash'
import { FillType, PathStyle } from '@/types/visualization/layer/enums'
import { FILL_MODE_CONFIGS, FillModes } from './fill'
import { DEFAULT_VIS_LINE_WIDTH } from './visualization-defaults'

const DEFAULT_OPACITY = 1

export const FILL_MODES_POINT: FillModes = pick(FILL_MODE_CONFIGS, [
  FillType.Color,
  FillType.Gradient,
  FillType.Median,
  FillType.Quartile,
  FillType.Decile,
  FillType.Heatmap,
  FillType.Clustering,
  FillType.Icon,
  FillType.Range
])

export const DEFAULT_POINT_FILL_MODE = FillType.Color
export const DEFAULT_POINT_COLOR = '#FFFF00'
export const DEFAULT_POINT_OPACITY = DEFAULT_OPACITY
export const DEFAULT_POINT_BORDER_WIDTH = DEFAULT_VIS_LINE_WIDTH
export const DEFAULT_POINT_BORDER_STYLE = PathStyle.Solid
export const DEFAULT_POINT_BORDER_COLOR = '#000000'
export const DEFAULT_POINT_BORDER_OPACITY = DEFAULT_OPACITY
