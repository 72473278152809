import ClusteringPathMixin from '@/components/visualization/map/mixins/clustering-path.mixin'
import ClusteringLegendMixin from '../mixins/clustering-legend.mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class ClusteringPathLegendMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  Mixins<ClusteringPathMixin<_TLayer>, ClusteringLegendMixin<_TLayer>>(ClusteringPathMixin, ClusteringLegendMixin) {
  protected get clusteringPathLegendValues () {
    return this.makeClusteringLegendValues(
      this.clusteringPathUniqueValues,
      this.getClusteringPathColor,
      this.layer.style.path.opacity
    )
  }
}
