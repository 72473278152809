// initial state
import {
  VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE
} from '@/store/mutation-types'
import { VICINITY_IMPORT_QUERY_UPDATED_STATE } from '@/store/getter-types'

const state = {
  wasUpdated: false
}

// getters
const getters = {
  [VICINITY_IMPORT_QUERY_UPDATED_STATE] (state) {
    return state.wasUpdated
  }
}

// mutations
const mutations = {
  [VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE] (state, wasUpdated) {
    state.wasUpdated = wasUpdated
  }
}

// actions
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
