export enum DataColumnType {
  Text = 'text',
  Number = 'number',
  Currency = 'currency'
}

export enum ApiDataColumnType {
  Text = 'text',
  Number = 'number',
}

export enum ApiGeometryColumnType {
  Linestring = 'linestring',
  Polygon = 'polygon',
}

export type ApiColumnType = ApiDataColumnType | ApiGeometryColumnType

export enum ApiColumnDataProp {
  Number = 'numberRows',
  Text = 'textRows',
  Polygon = 'polygonRows',
  Linestring = 'linestringRows',
}

export enum LayerSource {
  Custom = 'Custom'
}

export enum PeriodsType {
  DecadesA = 'decadesA',
  DecadesB = 'decadesB',
  DecadesC = 'decadesC',
  Weeks = 'weeks'
}

export enum ViewTypes {
  PLANNING_MAP = 'planning-map',
  PLANNING_STATISTICS = 'planning-statistics',
  PLANNING_TABLE = 'planning-table',
  PLANNING_REPORTS = 'planning-reports'
}

export enum LegendType {
  FillLegend = 'fill-legend',
  BorderLegend = 'border-legend'
}

export enum SortType {
  Asc = 'asc',
  Desc = 'desc'
}

export enum FileProcessModes {
  MODE_FULL = 'full',
  MODE_SEARCH = 'search',
  MODE_UPLOAD = 'upload'
}

export enum LegendSource {
  Fill = 'fill',
  Path = 'path'
}

export enum PathType {
  City = 'IWGgeometryGemeinden',
  OKZ = 'IWGgeometryOKZ',
  KKZ = 'IWGgeometryKKZ',
  PLZ2 = 'IWGgeometryPLZ2',
  PLZ5 = 'IWGgeometryPLZ5',
  PLZ8 = 'IWGgeometryPLZ8',
  Nielsen = 'IWGgeometryNielsen',
  Bundesland = 'IWGgeometryBundesländer',
  LandKreis = 'IWGgeometryLandkreise',
  Regierungsbezirk = 'IWGgeometryRegierungsbezirke',
  Country = 'IWGgeometryBRD',
  StreetSection = 'IWGgeometryStreetSections'
}

export enum SourceSelectorMode {
  All = 'all',
  Selected = 'selected'
}

export enum OrderSystem {
  NONE = 'none',
  OPS = 'ops',
  Plato = 'plato'
}
