import 'polyshim/polyfill/closest'
import 'polyshim/polyfill/object-assign'
import 'polyshim/polyfill/custom-event'
import 'polyshim/polyfill/event'
import 'form-association-polyfill/register-with-polyfills'

// See https://github.com/ai/nanoid for IE 11 support.
if (!window.crypto) {
  window.crypto = window.msCrypto
}
