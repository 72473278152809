import { FilterConfig } from '@/types/planning/wizard/filter-editor'
import { ScoringElementOptions, ScoringFunctionData } from '@/types/planning/scoring/functions'

export class BaseConfig<T> implements FilterConfig<T> {
  protected _defValue
  protected _scoringParameters: ScoringElementOptions | null

  constructor (parameters: ScoringElementOptions | null) {
    this._scoringParameters = parameters

    if (this._scoringParameters?.defaultValue) {
      this._defValue = this._scoringParameters?.defaultValue
    }
  }

  get defaultValue (): T {
    return this.getDefaultValue()
  }

  set defaultValue (value: T) {
    this.setDefaultValue(value)
  }

  formatValue (value: T): string {
    return String(value)
  }

  protected getDefaultValue (): T {
    return this._defValue
  }

  protected setDefaultValue (value: T): void {
    this._defValue = value
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isVisibleInGridView (_: ScoringFunctionData): boolean {
    return true
  }
}
