import { i18n } from '@/locales/i18n'
import { FillType, PathStyle } from '@/types/visualization/layer/enums'
import { pick } from 'lodash'
import { FILL_MODE_CONFIGS, FillModes } from './fill'
import { TranslateResult } from 'vue-i18n'

export type Pattern = number[]

export type PathTypeConfig = {
  text: TranslateResult | string,
  pattern: Pattern
  patternForWidth: (width: number) => Pattern
}

export type PathModes = Record<PathStyle, PathTypeConfig>

export const PATH_STYLE_CONFIGS: PathModes = {
  [PathStyle.Solid]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.solid') },
    // Leaflet doesn't like empty pattern.
    // This is a zero-width space hack for "solid" pattern.
    pattern: [1, 0],
    patternForWidth: function () {
      return this.pattern
    }
  },
  [PathStyle.Dotted]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.dotted') },
    pattern: [1, 5],
    patternForWidth: function (width) {
      return [this.pattern[0] * width, this.pattern[1]]
    }
  },
  [PathStyle.Dashed]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.dashed') },
    pattern: [6, 6],
    patternForWidth: function (width) {
      return [this.pattern[0] * width, this.pattern[1]]
    }
  },
  [PathStyle.None]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.none') },
    pattern: [0, 1],
    patternForWidth: function () {
      return this.pattern
    }
  }
}

export const PATH_WIDTHS = [
  {
    value: 1, text: '1'
  },
  {
    value: 2, text: '2'
  },
  {
    value: 3, text: '3'
  },
  {
    value: 4, text: '4'
  },
  {
    value: 5, text: '5'
  }
]

export type PathsFillModes = Partial<FillModes>

export const FILL_MODES_PATH: PathsFillModes = pick(FILL_MODE_CONFIGS, [
  FillType.Color,
  FillType.Gradient,
  FillType.Median,
  FillType.Quartile,
  FillType.Decile,
  FillType.Clustering,
  FillType.Range
])
