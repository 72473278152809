import { PluginObject, VNode } from 'vue'
import { i18n } from '@/locales/i18n'
import ResolveUnclearAssignments from './resolve-unclear-assignments.vue'
import { ScoringDetailsObject } from '@/types/planning'
import { ResolvedAssignments } from '@/types/planning/unclear-assignments'

type ResolveUnclearAssignmentsResult = {
  submitted: boolean;
  resolvedAssignments: ResolvedAssignments
}

type ResolveUnclearAssignmentsFunction = (scoreDetails: ScoringDetailsObject) => Promise<ResolveUnclearAssignmentsResult>;

declare module 'vue/types/vue' {
  interface Vue {
    $resolvePoiToAreaAssignments: ResolveUnclearAssignmentsFunction;
  }
}

function configureDialog (component: VNode) {
  const props = {
    size: 'xl',
    bodyClass: 'h-100',
    centered: true,
    scrollable: true
  }

  const slots = {
    default: component,
    'modal-title': i18n.t('planning.bookable-units-resolve.labels.modal-title') as string
  }

  return { props, slots }
}

export class ResolvePoiToAreaAssignmentsPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$resolvePoiToAreaAssignments = async function (scoreDetails: ScoringDetailsObject): Promise<ResolveUnclearAssignmentsResult> {
      const component = this.$createElement(ResolveUnclearAssignments, {
        props: { scoreDetails }
      })

      const { props, slots } = configureDialog(component)

      const submitted = await this.$modalDialog.msgBoxConfirmValid(slots, props)

      return {
        submitted,
        resolvedAssignments: (component.componentInstance as ResolveUnclearAssignments).resolvedAssignments
      }
    }
  }
}
