import { DirectiveOptions } from 'vue'

type ClickOutsideCallback = (e: MouseEvent) => void

interface ClickOutsideOptionsObject {
  active: boolean;
  callback: ClickOutsideCallback;
  capture?: boolean
}

export type ClickOutsideOptions = ClickOutsideOptionsObject | ClickOutsideCallback

interface CustomElement extends HTMLElement {
  clickOutsideEvent?: (event: Event) => void
  _clickOutsideListener?: (event: MouseEvent) => void
}

function updateListener (el: CustomElement, options: ClickOutsideOptions) {
  const optionsObj: ClickOutsideOptionsObject = typeof options === 'object' ? options : {
    active: true,
    callback: options,
    capture: false
  }

  if (optionsObj.active) {
    if (el._clickOutsideListener) {
      return
    }

    el._clickOutsideListener = (e: MouseEvent) => {
      if (el !== e.target && !el.contains(e.target as Node)) {
        optionsObj.callback(e)
      }
    }

    document.body.addEventListener('click', el._clickOutsideListener, { capture: Boolean(optionsObj.capture) })
  } else {
    if (el._clickOutsideListener) {
      document.body.removeEventListener('click', el._clickOutsideListener, { capture: Boolean(optionsObj.capture) })
      delete el._clickOutsideListener
    }
  }
}

export const ClickOutside: DirectiveOptions = {
  inserted (el, binding) {
    updateListener(el, binding.value)
  },
  update (el: CustomElement, binding) {
    updateListener(el, binding.value)
  },
  unbind: function (el: CustomElement, binding) {
    if (el._clickOutsideListener) {
      document.body.removeEventListener('click', el._clickOutsideListener, { capture: Boolean(binding.value.capture) })
    }
  }
}
