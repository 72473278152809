import {
  DATASET_WIZARD_STEP_ENTRYPOINT_INIT_GEOCODING,
  DATASET_WIZARD_STEP_ENTRYPOINT_INIT_TDLINX,
  DATASET_WIZARD_STEP_ENTRYPOINT_INIT_GEOMETRIES
} from '@/store/action-types'
import { DATASET_WIZARD_SET_ENTRYPOINT, DATASET_WIZARD_SET_DATASET_TYPE } from '@/store/mutation-types'
import { DatasetType } from '@/types/analytics/enums'
import { ENTRYPOINT_GEOCODING, ENTRYPOINT_TDLINX, ENTRYPOINT_GEOMETRIES } from '../../../constants/entrypoints'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {
  [DATASET_WIZARD_STEP_ENTRYPOINT_INIT_GEOCODING] ({ commit }) {
    commit(DATASET_WIZARD_SET_DATASET_TYPE, DatasetType.GeocodedDataset)
    commit(DATASET_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_GEOCODING)
  },
  [DATASET_WIZARD_STEP_ENTRYPOINT_INIT_TDLINX] ({ commit }) {
    commit(DATASET_WIZARD_SET_DATASET_TYPE, DatasetType.TDLinx)
    commit(DATASET_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_TDLINX)
  },
  [DATASET_WIZARD_STEP_ENTRYPOINT_INIT_GEOMETRIES] ({ commit }) {
    commit(DATASET_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_GEOMETRIES)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
