import { get } from 'lodash'
import Vue, { ComponentOptions, VueConstructor } from 'vue'
import { PropsDefinition } from 'vue/types/options'
import { VueInstanceProps } from '@/types/vue'

type MixinDef = VueConstructor | ComponentOptions<Vue>

export function getMixedPropsFromSelf (self: Vue, mixinObj: MixinDef): VueInstanceProps {
  let props: VueInstanceProps = {}
  let innerMixins: MixinDef[]
  let mixinPropDefs: PropsDefinition<Vue>

  if (typeof mixinObj === 'function') {
    // Vue constructor function has all required info in options object.
    innerMixins = get(mixinObj, 'options.mixins', [])
    mixinPropDefs = get(mixinObj, 'options.props', {})
  } else {
    // Vue component definition directly contains all info.
    innerMixins = get(mixinObj, 'mixins', [])
    mixinPropDefs = get(mixinObj, 'props', {}) as PropsDefinition<Vue>
  }

  // If mixin includes other mixins add props from them recursively.
  for (const innerMixin of innerMixins) {
    props = { ...props, ...getMixedPropsFromSelf(self, innerMixin) }
  }

  props = Object.keys(mixinPropDefs)
    .reduce((props, prop) => {
      props[prop] = self[prop]
      return props
    }, props)

  return props
}
