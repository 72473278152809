// inclusion/exclusion filters
export const EXTERNAL_IDS_VALUE = 'ExternalIDs'

// area filter
export const AREA_KEYS_VALUE = 'AreaKeys'
export const TOLERANCE_VALUE = 'Tolerance'

// city names filter
export const CITY_NAMES_VALUE = 'CityNames'

// vicinity & clustering
export const RANGE_TYPE_VALUE = 'RangeType'
export const RANGE_VALUE = 'RangeValue'
export const MIN_CLUSTER_SIZE_VALUE = 'MinClusterSize'
export const APPLY_DRIVING_DISTANCE_VALUE = 'ApplyDrivingDistance'
export const POI_VALUE = 'POIs'

// site types filter
export const SITE_TYPES_VALUE = 'SiteTypes'

// media owner networks filter
export const MEDIA_OWNER_NETWORKS_VALUE = 'MediaOwnerNetworks'

// all goals
export const TARGET_VALUE = 'TargetValue'

// all rankings
export const WEIGHT_VALUE = 'Weight'

// cpt ranking
export const TKP_VALUE = 'TKP'

// custom area ranking
export const PLZ_VALUE = 'PLZ'

// filter availability
export const BOOKING_TIME_FRAMES_VALUE = 'BookingTimeFrames'

// filter banned products
export const BANNED_PRODUCTS_VALUE = 'BannedProducts'

// build type filter
export const RESTRICTED_BUILD_TYPES_VALUE = 'RestrictedBuildTypes'

// include/exclude by media owner filter
export const MEDIA_OWNER_NAMES_VALUE = 'MediaOwnerNames'

// illumination filter
export const ILLUMINATION_VALUE = 'Illumination'

// isolation filter
export const MIN_DISTANCE_VALUE = 'MinDistance'

// media criteria filter
export const MEDIA_FILTERING_VALUE = 'MediaFilteringData'

// price filter
export const PRICE_VALUE = 'Price'

// pps filter
export const PPS_FILTER_MODE = 'PpsFilterMode'
export const PPS_TO_AVERAGE = 'PpsToAvgRelation'
export const PPS_RANGE = 'PpsRangeValues'

// multisite filter
export const NUMBER_OF_SITES_PER_UNIT = 'NumberOfSitesPerLocation'
