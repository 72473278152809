




















import { SliderDraggableMixin } from '../mixin/slider-draggable.mixin'
import { Component, Mixins, Prop, Emit } from 'vue-property-decorator'
import { BackgroundProperty, Properties } from 'csstype'
import { ColorModel } from '@/types/color-data'
import { ColorFormats } from 'tinycolor2'

@Component
export default class Saturation extends Mixins(SliderDraggableMixin) {
  @Prop({ required: true }) value!: ColorModel

  private get colors (): ColorModel {
    return this.value
  }

  private get bgColor (): BackgroundProperty<string> {
    return `hsl(${this.colors.hsv.h}, 100%, 50%)`
  }

  private get pointerStyle (): Properties {
    return {
      left: this.colors.hsv.s * 100 + '%',
      top: (-(this.colors.hsv.v * 100) + 1) + 100 + '%'
    }
  }

  @Emit('change')
  protected override emitSliderChange (x: number, y: number): ColorFormats.HSVA {
    const saturation = x
    const bright = 1 - y

    const data = {
      h: this.colors.hsv.h,
      s: saturation,
      v: bright,
      a: this.colors.hsv.a,
      source: 'hsva'
    }

    return data
  }
}
