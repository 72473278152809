import { PluginObject } from 'vue'
import { LatLng } from 'leaflet'
import LocationPicker from './location-picker.vue'

type PickLocationsResult = {
  submitted: boolean;
  locations: LatLng[];
}

type PickLocationsFunction = (locations?: LatLng[]) => Promise<PickLocationsResult>;

declare module 'vue/types/vue' {
  interface Vue {
    $pickLocations: PickLocationsFunction;
  }
}

export class LocationPickerPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$pickLocations = async function (latlngs: LatLng[] = []): Promise<PickLocationsResult> {
      const pickerVNode = this.$createElement(LocationPicker, {
        props: { latlngs }
      })

      const dialogProps = {
        size: 'xl',
        centered: true
      }

      const dialogSlots = {
        'modal-title': this.$t('common.location-picker.labels.pick-location')
      }

      return this.$modalDialog.msgBoxConfirm(pickerVNode, dialogProps, dialogSlots)
        .then((submitted): PickLocationsResult => ({
          submitted,
          locations: (pickerVNode.componentInstance as LocationPicker).resultLatlngs
        }))
    }
  }
}
