















import { Component, Vue } from 'vue-property-decorator'
import { ListPair } from '@/types/planning/wizard/value-types'
import RankingImportWizard from '@/components/planning/wizard/filter-editor/ui-controls/pairs-ui-element/components/trigger-ranking-import-process/components/ranking-import-wizard/ranking-import-wizard.vue'
import { LocalTranslations } from '@/decorators/local-translations'
import messages from './locales/locales'

@Component({
  components: { RankingImportWizard }
})
@LocalTranslations(messages)
export default class TriggerRankingImportProcess extends Vue {
  private visible = false

  private runWizard (): void {
    this.visible = true
  }

  private onFinish (results: ListPair) {
    this.$emit('import-finished', results)
  }
}
