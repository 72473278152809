import { createTransformResponse } from '@/api/utils'
import { AxiosResponse } from 'axios'
import { SiteTypesCollection } from '@/types/planning/wizard/filter-editor'

// TODO - Provide real granularities - SW-3391
const knownDurationTypes = ['D A', 'D B', 'D C', 'W02']

// Actually, a workaround. Correct granularity has to be provided by back-end. TODO - SW-3403
const notClearDurationTypes = ['W']

function parseGranularity (granularityType: string): string {
  const granularity = notClearDurationTypes.find(dt => granularityType.indexOf(dt) === 0)
  return granularity || granularityType
}

export const siteTypeSubTypeGranularityResponseTransform = createTransformResponse<SiteTypesCollection>((response: AxiosResponse['data']): SiteTypesCollection => {
  let fromServer = JSON.parse(response)
  const parsedResponse: SiteTypesCollection = []

  Array.isArray(fromServer) && fromServer.forEach((constant) => {
    const bookingTypes = constant.constantValue
      .split(',')
      .map(bt => bt.trim())
      .filter(bt => knownDurationTypes.includes(bt))
      .map(bt => parseGranularity(bt))
      .sort()

    if (bookingTypes.length) {
      parsedResponse.push({
        text: constant.constantName,
        value: constant.constantName,
        bookingTypes
      })
    }
  })

  fromServer = null

  return parsedResponse
})
