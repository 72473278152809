













import { Component, Prop } from 'vue-property-decorator'
import VueTagsInput from '@johmun/vue-tags-input'
import TagsAutocomplete from '@/directives/tags-autocomplete'
import { SelectOptionObject } from '@/types/bootstrap'
import { QueryRuleTagsConfig } from '@/types/query-builder/rules'
import { RuleMixin } from './rule.mixin'

type TagOption = SelectOptionObject<string | number | null>

@Component({
  components: { VueTagsInput },
  directives: { TagsAutocomplete }
})
export default class TagsRule extends RuleMixin<(string | number | null)[]> {
  @Prop({ required: true }) rule!: QueryRuleTagsConfig

  private searchString = ''
  private tags: TagOption[] = []

  private get allTags (): TagOption[] {
    return this.rule.choices.map((choice) => {
      if (typeof choice === 'string') {
        return {
          value: choice,
          text: choice
        }
      }

      return choice as TagOption
    })
  }

  private get filteredTags (): TagOption[] {
    const searchString = this.searchString.toLowerCase()

    return this.allTags.filter((tag) => {
      // Do not offer already selected items.
      if (this.internalValue.includes(tag.value)) {
        return false
      }

      // Force "(empty)" option to be always visible.
      if (tag.value == null) {
        return true
      }

      return tag.text.toLowerCase().includes(searchString)
    })
  }

  mounted (): void {
    this.tags = this.getInitialTags()
  }

  private getInitialTags () : TagOption[] {
    const valuesSet = new Set(this.internalValue)
    return this.allTags.filter((tag) => valuesSet.has(tag.value))
  }

  private onUpdateTags (tags: TagOption[]): void {
    this.internalValue = tags.map((tag) => tag.value)
  }
}
