import FlatbushBase from 'flatbush'

export default class Flatbush extends FlatbushBase {
  itemsTree () {
    if (this._pos !== this._boxes.length) {
      throw new Error('Data not yet indexed - call index.finish().')
    }

    const nodeIndex = this._boxes.length - 4
    const level = this._levelBounds.length - 1

    return this._childTree(nodeIndex, level)
  }

  _childTree (nodeIndex, level) {
    const result = []
    const end = Math.min(nodeIndex + this.nodeSize * 4, this._levelBounds[level])

    // iterate over child nodes
    for (let pos = nodeIndex; pos < end; pos += 4) {
      const index = this._indices[pos >> 2] | 0

      if (nodeIndex < this.numItems * 4) {
        result.push(index) // leaf item, just return original index.
      } else {
        result.push(this._childTree(index, level - 1))
      }
    }

    return result
  }
}
