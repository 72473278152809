import { Component, Prop, Vue } from 'vue-property-decorator'
import { FormInputSize } from '@/types/bootstrap'
import { DatasetRows } from '@/types/common'
import { QueryGroup } from '@/types/query-builder'
import { QueryRuleConfig } from '@/types/query-builder/rules'
import { DatasetBuilderSlotScope } from '../types/slots'

type ConsumerInterface = Readonly<DatasetBuilderSlotScope>

@Component
export class DatasetSlotConsumerMixin extends Vue implements ConsumerInterface {
  @Prop({ required: true }) readonly query!: QueryGroup
  @Prop({ required: true }) readonly rules!: QueryRuleConfig[]
  @Prop({ required: true }) readonly maxDepth!: number
  @Prop({ required: true }) readonly size!: FormInputSize
  @Prop({ required: true }) readonly rows!: DatasetRows
  @Prop({ required: true }) readonly filteredRows!: DatasetRows
}
