

















import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { orderBy } from 'lodash'
import { FormInputSize } from '@/types/bootstrap'
import { DataColumn, DatasetRows, NumberColumn, TextColumn } from '@/types/common'
import { isNumberColumn, isTextColumn } from '@/types/guards'
import { QueryGroup } from '@/types/query-builder'
import { QueryRuleConfig, RuleChoice } from '@/types/query-builder/rules'
import { BuilderSlotScope, DatasetBuilderSlotScope } from './types/slots'
import { getQueryFilterFunction } from './filter-func'
import QueryBuilder from './query-builder.vue'
import QueryBuilderStatistics from './components/query-builder-statistics.vue'

const MAX_TEXT_CHECKBOX_VALUES = 5

@Component({
  components: { QueryBuilderStatistics, QueryBuilder }
})
export default class DatasetQueryBuilder extends Vue {
  @Model('change', { required: true }) readonly value!: QueryGroup
  @Prop({ required: true }) readonly columns!: DataColumn[]
  @Prop({ required: true }) readonly rows!: DatasetRows
  @Prop({ default: 3, type: Number }) readonly maxDepth!: number
  @Prop({ default: 'md' }) readonly size!: FormInputSize
  @Prop({ default: false, type: Boolean }) readonly statistics!: boolean

  private get rules (): QueryRuleConfig[] {
    const result: QueryRuleConfig[] = []

    for (const column of this.columns) {
      if (isNumberColumn(column)) {
        result.push(this.getNumberColumnRule(column))
      }
      if (isTextColumn(column)) {
        result.push(this.getTextColumnRule(column))
      }
    }

    return result
  }

  private get filteredRows (): DatasetRows {
    const filterFunc = getQueryFilterFunction(this.value)
    return this.rows.filter(filterFunc)
  }

  protected getNumberColumnRule (column: NumberColumn): QueryRuleConfig {
    return {
      id: column.name,
      label: column.name,
      type: 'numeric'
    }
  }

  protected getTextColumnRule (column: TextColumn): QueryRuleConfig {
    const values = this.getDistinctChoices(column)

    if (values.length <= MAX_TEXT_CHECKBOX_VALUES) {
      return {
        type: 'checkbox',
        id: column.name,
        label: column.name,
        choices: values
      }
    }

    return {
      type: 'tags',
      id: column.name,
      label: column.name,
      choices: values
    }
  }

  private getDistinctChoices (column: DataColumn): RuleChoice[] {
    const columnName = column.name
    const valuesSet = new Set()
    let value
    let hasEmpty = false

    for (const row of this.rows) {
      value = row[columnName]

      if (value == null || value === '') {
        hasEmpty = true
      } else {
        valuesSet.add(value)
      }
    }

    const choices = orderBy(Array.from(valuesSet)) as RuleChoice[]

    if (hasEmpty) {
      choices.splice(0, 0, {
        value: null,
        text: this.$t('query-builder.labels.choice-empty') as string
      })
    }

    return choices
  }

  private getSlotScope (builderScope: BuilderSlotScope): DatasetBuilderSlotScope {
    return {
      ...builderScope,
      rows: this.rows,
      filteredRows: this.filteredRows
    }
  }
}
