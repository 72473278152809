import { LocaleMessages } from 'vue-i18n'

export default {
  en: {
    plz: 'PLZ 5/8',
    rating: 'Ranking value'
  },
  de: {
    plz: 'PLZ 5/8',
    rating: 'Rankingwert'
  }
} as LocaleMessages
