import { FilterRangeConfig } from '@/types/planning/wizard/filter-editor'
import { AbstractRangeConfig } from '../range/range-config'

export class SliderConfig extends AbstractRangeConfig<number> implements FilterRangeConfig<number> {
  override get displayMarkers () {
    return true
  }

  override get displayInput () {
    return false
  }

  override formatValue (value: number): string {
    return this.rangeOptions.formatter(value)
  }
}
