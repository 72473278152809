













































import { Component, Mixins, Prop } from 'vue-property-decorator'
import AgGrid from '@/components/shared/ag-grid/ag-grid.vue'
import { ColDef } from 'ag-grid-community'
import { pick } from 'lodash'
import { GeocodingDetails } from '@/components/shared/coordinates-service/types'
import { GRID_COLUMN_TYPE_STRING } from '@/components/shared/ag-grid/constants'
import { ModalMixin } from '@/mixins/modal.mixin'
import {
  GEOCODING_DETAILS_MODAL_OPEN,
  geocodingDetailsOptions
} from '@/components/shared/coordinates-service/components/constants'
import { GeocodingConfidence } from '@/components/shared/coordinates-service/types/enum'
import { SelectOption, SelectOptionObject } from '@/types/bootstrap'
import ColorExample from '@/components/shared/color-picker/common/color-example.vue'
import { geocodeSuccessfullyColor, geocodeUnsuccessfullyColor } from '@/assets/style-variables'

const geocodingDataTranslationKey = {
  queryString: 'addresses',
  confidence: 'confidence'
}

@Component({
  components: { ColorExample, AgGrid }
})
export default class GeocodingDetailsModal extends Mixins(ModalMixin) {
  @Prop({ default: () => [] }) private geocodingDetails!: GeocodingDetails[]
  private selectedConfidence: string = (this.geocodingSelectOptions[0] as SelectOptionObject).value

  override modalEventName: string = GEOCODING_DETAILS_MODAL_OPEN

  private geocodeSuccessfullyColor: string = geocodeSuccessfullyColor
  private geocodeUnsuccessfullyColor: string = geocodeUnsuccessfullyColor

  private readonly defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    flex: 1,
    minWidth: 100,
    filter: false
  }

  private get geocodingSelectOptions (): SelectOption[] {
    return Object.entries(geocodingDetailsOptions).map(([key, options]) => {
      return {
        text: options.label() as string,
        value: key
      }
    })
  }

  private get columnDefs (): ColDef[] {
    return Object.keys(this.tableItems[0] ?? {}).sort(() => -1).map(column => ({
      field: column,
      headerName: this.$t(`common.coordinates-service.labels.${geocodingDataTranslationKey[column]}`) as string,
      type: GRID_COLUMN_TYPE_STRING,
      flex: column === 'confidence' ? 1 : 2,
      cellClass: (params) => {
        switch (params.data.confidence) {
          case GeocodingConfidence.High:
          case GeocodingConfidence.Medium:
            return 'geocode-completed-successfully'

          case GeocodingConfidence.Low:
          case GeocodingConfidence.Bad:
          case GeocodingConfidence.Ambigous:
            return 'geocode-completed-unsuccessfully'

          default:
            return ''
        }
      }
    }))
  }

  private get tableItems () {
    return this.geocodingDetails
      .filter(details => geocodingDetailsOptions[this.selectedConfidence].filterFunc(details.confidence))
      .map(details => pick(details, ['confidence', 'queryString']))
  }
}
