import { get, isEqual } from 'lodash'
import { ApiGeometryColumnType } from '@/types/enums'
import { LayerType, ShapeType } from '@/types/visualization/layer/enums'
import {
  DEFAULT_LINE_LAYER_STYLE,
  DEFAULT_POLYGON_LAYER_STYLE
} from '@/components/visualization/layer-settings/constants'
import { getMainLayerFillOptions } from '@/utils/visualization'
import { DEFAULT_LAYER_SETTINGS } from '@/components/visualization/layer-settings/constants/layer-settings'
import { columnDataProp, getApiColumnType, parseLayerColumns } from './columns'
import { fixNumberColumns, getLayerTagsInfo, getPathCoordinates, parseLayerSettings, parseLayerStyle } from './common'

function buildKeysIndexMap (dataRows, keyColumn) {
  const map = {}
  let rowIndex, row, rowKey
  const rowsLength = dataRows.length

  for (rowIndex = 0; rowIndex < rowsLength; rowIndex++) {
    row = dataRows[rowIndex]
    rowKey = row[keyColumn]

    if (!Object.hasOwnProperty.call(map, rowKey)) {
      map[rowKey] = []
    }

    map[rowKey].push(rowIndex)
  }

  return map
}

function buildPathMetaRowsGetter (layer, dataRows) {
  const keyColumn = layer.dataSource.linkingDatasetColumnId
  const keyMap = buildKeysIndexMap(dataRows, keyColumn)
  const fillOptions = getMainLayerFillOptions(layer)
  const showDuplicates = fillOptions.showDuplicates

  return (rowKey) => {
    const dataIndices = rowKey == null ? [] : get(keyMap, [rowKey], [])
    const pathMetaRows = dataIndices.map((dataIndex) => dataRows[dataIndex])

    if (!pathMetaRows.length) {
      // If there is no matching data in dataset for the path just use it with an empty metadata.
      pathMetaRows.push({})
    }

    return showDuplicates ? pathMetaRows : pathMetaRows.slice(0, 1)
  }
}

function buildDuplicatePathChecker (getPathCoordinates) {
  const usedKeys = {}

  const isPathDuplicate = (pathRow) => {
    if (Object.prototype.hasOwnProperty.call(usedKeys, pathRow.key)) {
      for (const usedRow of usedKeys[pathRow.key]) {
        if (isEqual(getPathCoordinates(usedRow), getPathCoordinates(pathRow))) {
          // Eliminate duplicated path.
          return true
        }
      }
    } else {
      usedKeys[pathRow.key] = []
    }

    return false
  }

  const rememberUsedPath = (pathRow) => {
    usedKeys[pathRow.key].push(pathRow)
  }

  return { isPathDuplicate, rememberUsedPath }
}

export function matchGeometrysetWithDataset ({ layer, pathRows, dataRows, getPathCoordinates, makeGeometry }) {
  const getPathMetaRows = buildPathMetaRowsGetter(layer, dataRows)
  const { isPathDuplicate, rememberUsedPath } = buildDuplicatePathChecker(getPathCoordinates)
  const matchedGeometries = []

  for (const pathRow of pathRows) {
    if (isPathDuplicate(pathRow)) {
      continue
    }

    const rowKey = pathRow.key === 'unknown' ? null : pathRow.key
    const pathMetaRows = getPathMetaRows(rowKey)

    for (const meta of pathMetaRows) {
      matchedGeometries.push(makeGeometry(rowKey, pathRow, meta))
    }

    rememberUsedPath(pathRow)
  }

  return matchedGeometries
}

function buildGeometryMaker () {
  const pathsCache = new Map()

  return (key, pathRow, meta) => {
    if (!pathsCache.has(pathRow)) {
      pathsCache.set(pathRow, getPathCoordinates(pathRow))
    }

    const paths = pathsCache.get(pathRow)

    return {
      id: pathRow.id,
      key,
      paths,
      meta
    }
  }
}

function parsePathLayer (layer, columnType) {
  if (!Array.isArray(layer.data) || layer.data.length === 0) {
    throw new Error('Incorrect path layer structure.')
  }

  const pathColumnIndex = layer.data.findIndex(col => getApiColumnType(col) === columnType)
  const pathRows = layer.data[pathColumnIndex][columnDataProp(layer.data[pathColumnIndex])]

  let dataRows = []
  let columnTypes = []
  let dataColumnIndex = -1

  if (layer.data.length === 2) {
    dataColumnIndex = 1 - pathColumnIndex
    dataRows = layer.data[dataColumnIndex].datasetRows
    columnTypes = layer.data[dataColumnIndex].columnInfos
  }

  fixNumberColumns(dataRows, columnTypes)

  const makeGeometry = buildGeometryMaker()
  const getPathCoordinates = (pathRow) => pathRow.coordinates
  const geometries = matchGeometrysetWithDataset({
    layer,
    pathRows,
    dataRows,
    getPathCoordinates,
    makeGeometry
  })

  const settings = parseLayerSettings(layer.settings, DEFAULT_LAYER_SETTINGS)

  return {
    id: layer.id,
    name: layer.name,
    ...parseLayerColumns(columnTypes),
    type: LayerType.Shapes,
    dataset: dataRows,
    geometry: geometries,
    ...getLayerTagsInfo(layer.data, dataColumnIndex, pathColumnIndex),
    dataSource: layer.dataSource,
    settings
  }
}

function parsePolygonsLayer (layer) {
  const layerWithStyle = {
    ...layer,
    // To get the right fill options
    shapeType: ShapeType.Polygon,
    // Init default style values
    style: parseLayerStyle(layer.style, DEFAULT_POLYGON_LAYER_STYLE, {
      legend: {
        content: layer.name
      }
    })
  }

  const layerData = parsePathLayer(layerWithStyle, ApiGeometryColumnType.Polygon)

  return {
    ...layerData,
    shapeType: layerWithStyle.shapeType,
    style: layerWithStyle.style
  }
}

function parseLineStringsLayer (layer) {
  const layerWithStyle = {
    ...layer,
    // To get the right fill options
    shapeType: ShapeType.Line,
    // Init default style values
    style: parseLayerStyle(layer.style, DEFAULT_LINE_LAYER_STYLE, {
      legend: {
        content: layer.name
      }
    })
  }

  const layerData = parsePathLayer(layerWithStyle, ApiGeometryColumnType.Linestring)

  return {
    ...layerData,
    shapeType: layerWithStyle.shapeType,
    style: layerWithStyle.style
  }
}

export function parseShapesLayer (layer) {
  if (layer.data.some(column => getApiColumnType(column) === ApiGeometryColumnType.Linestring)) {
    return parseLineStringsLayer(layer)
  }

  if (layer.data.some(column => getApiColumnType(column) === ApiGeometryColumnType.Polygon)) {
    return parsePolygonsLayer(layer)
  }

  throw new Error('Unsupported shape layer type.')
}
