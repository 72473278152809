import { QueryGroup } from '@/types/query-builder'
import navigationStore from './modules/navigation/navigation.store'
import { actions } from '@/store/ranking-import-wizard/actions'
import { getters } from '@/store/ranking-import-wizard/getters'
import { mutations } from '@/store/ranking-import-wizard/mutations'
import { Dataset } from '@/types/common'
import { getDefaultQuery } from '@/utils/query-builder'

export type RankingImportWizardState = {
  sourceDataset: Dataset | null,
  query: QueryGroup,
  wizardNamespace: 'ranking-import',
  plzColumn: string | null
  ratingColumn: string | null
}

const state: RankingImportWizardState = {
  sourceDataset: null,
  query: getDefaultQuery(),
  wizardNamespace: 'ranking-import',
  plzColumn: null,
  ratingColumn: null
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    wizardNavigation: navigationStore
  }
}
