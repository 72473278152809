import { i18n } from '@/locales/i18n'
import {
  RANKING_IMPORT_DATASET,
  RANKING_IMPORT_PARSE,
  RANKING_IMPORT_QUERY
} from './steps'
import { WizardSteps } from '@/types/wizard'
import SetDatasetStep from '@/components/planning/wizard/filter-editor/ui-controls/pairs-ui-element/components/trigger-ranking-import-process/components/ranking-import-wizard/steps/set-dataset/set-dataset-step.vue'
import QueryDatasetStep from '@/components/planning/wizard/filter-editor/ui-controls/pairs-ui-element/components/trigger-ranking-import-process/components/ranking-import-wizard/steps/query-dataset/query-dataset-step.vue'
import SelectColumnsStep from '@/components/planning/wizard/filter-editor/ui-controls/pairs-ui-element/components/trigger-ranking-import-process/components/ranking-import-wizard/steps/select-columns/select-columns-step.vue'
import {
  RANKING_IMPORT_WIZARD_TAB_DATASET_CAN_SKIP,
  RANKING_IMPORT_WIZARD_TAB_DATASET_ERRORS,
  RANKING_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY,
  RANKING_IMPORT_WIZARD_TAB_DATASET_IS_ENABLED,
  RANKING_IMPORT_WIZARD_TAB_DATASET_IS_INVALID
} from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-add-dataset/getters'
import {
  RANKING_IMPORT_WIZARD_TAB_DATASET_LEAVE,
  RANKING_IMPORT_WIZARD_TAB_DATASET_VALIDATE
} from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-add-dataset/actions'
import {
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_CAN_SKIP,
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS,
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY,
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_ENABLED,
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_INVALID
} from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-query-dataset/getters'
import {
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_INVALIDATE,
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_LEAVE, RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_SKIP,
  RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_VALIDATE
} from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-query-dataset/actions'
import {
  RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_CAN_SKIP,
  RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_ERRORS,
  RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_EMPTY,
  RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_ENABLED,
  RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_INVALID
} from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-select-key-columns/getters'
import {
  RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_INVALIDATE,
  RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_VALIDATE
} from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-select-key-columns/actions'

export const stepsConfig: WizardSteps = {
  [RANKING_IMPORT_DATASET]: {
    id: RANKING_IMPORT_DATASET,
    getName: () => i18n.t('ranking-import.wizard.search-dataset') as string,
    component: SetDatasetStep,
    store: {
      getters: {
        isInvalid: RANKING_IMPORT_WIZARD_TAB_DATASET_IS_INVALID,
        isEnabled: RANKING_IMPORT_WIZARD_TAB_DATASET_IS_ENABLED,
        isEmpty: RANKING_IMPORT_WIZARD_TAB_DATASET_IS_EMPTY,
        errors: RANKING_IMPORT_WIZARD_TAB_DATASET_ERRORS,
        canSkip: RANKING_IMPORT_WIZARD_TAB_DATASET_CAN_SKIP
      },
      actions: {
        validate: RANKING_IMPORT_WIZARD_TAB_DATASET_VALIDATE,
        beforeLeave: RANKING_IMPORT_WIZARD_TAB_DATASET_LEAVE
      }
    }
  },
  [RANKING_IMPORT_QUERY]: {
    id: RANKING_IMPORT_QUERY,
    getName: () => i18n.t('ranking-import.wizard.query-dataset') as string,
    component: QueryDatasetStep,
    store: {
      getters: {
        isInvalid: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_INVALID,
        isEnabled: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_ENABLED,
        isEmpty: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY,
        errors: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS,
        canSkip: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_CAN_SKIP
      },
      actions: {
        validate: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_VALIDATE,
        beforeLeave: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_LEAVE,
        invalidate: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_INVALIDATE,
        skipStep: RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_SKIP
      }
    }
  },
  [RANKING_IMPORT_PARSE]: {
    id: RANKING_IMPORT_PARSE,
    getName: () => i18n.t('ranking-import.wizard.choose-columns-from-dataset') as string,
    component: SelectColumnsStep,
    store: {
      getters: {
        isInvalid: RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_INVALID,
        isEnabled: RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_ENABLED,
        isEmpty: RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_EMPTY,
        errors: RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_ERRORS,
        canSkip: RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_CAN_SKIP
      },
      actions: {
        validate: RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_VALIDATE,
        invalidate: RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_INVALIDATE
      }
    }
  }
}
