











































import { Component, Mixins, Prop } from 'vue-property-decorator'
import MatchRowsProcessing from '@/components/planning/wizard/filter-editor/ui-controls/components/match-rows-processing.vue'
import { ModalMixin } from '@/mixins/modal.mixin'

@Component({
  components: { MatchRowsProcessing }
})
export default class MatchDatasetModal extends Mixins<ModalMixin>(ModalMixin) {
  @Prop({ required: true }) private importProcessIsRunning!: boolean
  @Prop({ required: true }) private title!: string

  protected openDialog (): void {
    this.$emit('reset-import')
    this.openModal()
  }

  protected handleMatch () {
    this.$emit('handle-match')
  }
}
