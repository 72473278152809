














import { Component, Prop, Vue } from 'vue-property-decorator'
import ActionResults from '@/components/shared/action-results/action-results.vue'
  @Component({
    components: { ActionResults }
  })
export default class CoordinatesParseResults extends Vue {
    @Prop({ default: 0 }) errorsCount!: number
    @Prop({ default: 0 }) totalCount!: number
}
