import { Component, Mixins, Watch } from 'vue-property-decorator'
import { ExcelRow } from '@/types/common'
import { acceptFileFilters, EXCEL_FILE_TYPES, readExcelFile } from '@/utils/file'
import { freezeRecursive } from '@/utils/object'
import { FileMixin } from './file.mixin'

@Component
export class ReadExcelFileMixin extends Mixins(FileMixin) {
  protected fileRows: ExcelRow[] = []

  data () {
    return { ...acceptFileFilters(EXCEL_FILE_TYPES) }
  }

  @Watch('file')
  private onFileChange (file: File | null): void {
    if (file) {
      this.readExcelFile(file)
    }
  }

  protected async readExcelFile (file: File | null): Promise<ExcelRow[]> {
    this.fileRows = []

    if (!file) {
      throw new Error(this.$t('common.errors.reading-file') as string)
    }

    if (!this.validateFile()) {
      this.$toastError(this.$t('common.errors.valid-excel-file') as string)
      throw new Error(this.$t('common.errors.valid-excel-file') as string)
    }

    try {
      const rows = await readExcelFile(file, undefined, { raw: true })
      this.fileRows = freezeRecursive(rows as ExcelRow[])
      return this.fileRows
    } catch (e) {
      typeof e === 'string' && this.$toastError(e)
      throw e
    }
  }
}
