import { SelectOption } from '@/types/planning/wizard/filter-editor'
import { RangeType } from '@/types/planning/wizard/enums'
import { SelectionOptionsConfig } from '@/utils/filter-editor/value-config/select/selection-options-config'
import { i18n } from '@/locales/i18n'

export class RangeTypeConfig extends SelectionOptionsConfig<RangeType> {
  protected override _defValue = RangeType.Radius

  override get options (): SelectOption[] {
    return [
      { value: 'Radius', get text () { return i18n.t('planning.filters.labels.radius') as string } },
      { value: 'Distance', get text () { return i18n.t('planning.filters.labels.distance') as string } },
      { value: 'Time', get text () { return i18n.t('planning.filters.labels.time') as string } }
    ]
  }
}
