import { Component, Provide, Vue } from 'vue-property-decorator'
import { AgGridEvent, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community'
import { ColumnApiAction, GridApiAction, GridEventListener } from '../types'
import GridApiAdapter from '@/components/shared/ag-grid/grid-api-adapter'

@Component
export class GridApiMixin extends Vue {
  @Provide() onGridApi = this.listenOnGridApi
  @Provide() callGridApi = this.callGridApiOrQueue
  @Provide() callColumnApi = this.callColumnApiOrQueue

  private _gridApi?: GridApiAdapter<GridApi>
  private _columnApi?: GridApiAdapter<ColumnApi>

  created (): void {
    this._gridApi = new GridApiAdapter<GridApi>()
    this._columnApi = new GridApiAdapter<ColumnApi>()
  }

  destroyed (): void {
    delete this._gridApi
    delete this._columnApi
  }

  protected initGridApi (event: GridReadyEvent): void {
    this._gridApi?.initApi(event.api)
    this._columnApi?.initApi(event.columnApi)
  }

  callGridApiOrQueue<T = void> (action: GridApiAction<T>): Promise<T> {
    return this._gridApi ? this._gridApi.runOrQueueAction(action) : new Promise<T>(resolve => resolve({} as T))
  }

  callColumnApiOrQueue<T = void> (action: ColumnApiAction<T>): Promise<T> {
    return this._columnApi ? this._columnApi.runOrQueueAction(action) : new Promise<T>(resolve => resolve({} as T))
  }

  listenOnGridApi<E extends AgGridEvent> (event: string, handler: GridEventListener<E>): Promise<void> {
    return this.callGridApiOrQueue((gridApi) => gridApi.addEventListener(event, handler))
  }
}
