import Vue from 'vue'
import { Icon } from 'leaflet'
import 'leaflet-contextmenu'
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css'
import { LCircleMarker, LControl, LTileLayer } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import '@/lib/leaflet/util/MarkerCluster.js'
import LHeatmap from './heat-map/l-heat-map'

Vue.component('LTileLayer', LTileLayer)
Vue.component('LCircleMarker', LCircleMarker)
Vue.component('LControl', LControl)
Vue.component('VMarkerCluster', Vue2LeafletMarkerCluster)
Vue.component('LHeatMap', LHeatmap)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
