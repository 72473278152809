import VuexPersistence from 'vuex-persist'
import {
  CLEAR_USER_DATA,
  SET_USER_FIRST_NAME,
  SET_USER_LAST_NAME,
  SET_USER_ROLES,
  SET_USER_USERNAME
} from '@/store/mutation-types'

const userMutations = [
  SET_USER_USERNAME,
  SET_USER_FIRST_NAME,
  SET_USER_LAST_NAME,
  SET_USER_ROLES,
  CLEAR_USER_DATA
]

const persistence = new VuexPersistence({
  key: 'user',
  modules: ['user'],
  filter: mutation => {
    return userMutations.includes(mutation.type)
  }
})

export default persistence.plugin
