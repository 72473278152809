
import { Component, Prop } from 'vue-property-decorator'
import SelectorModal from '@/components/shared/selector-table/selector-modal.vue'
import { VueInstanceListeners, VueInstanceProps } from '@/types/vue'
import { DataColumn, DatasetRow, DatasetRows } from '@/types/common'
import { ColDef, ModelUpdatedEvent } from 'ag-grid-community'
import { ALLGEMEIN_STELLE_SELECTION_COLUMN, PRIMARY_FIELD } from '@/constants/unit/fields'
import { cloneDeep, isEqual, omit, get } from 'lodash'
import { configureColumnDefs } from '@/constants/unit/utils'
import { monColumnsOrder, monTableProps } from '../table-props'
import GridCellSelectInput, { GridCellSelectInputEvent } from '@/components/shared/ag-grid/components/grid-cell-select-input.vue'
import { monSheetSizeOptions } from '../utils'
import GridHeaderDisclaimer from '@/components/shared/ag-grid/components/grid-header-disclaimer.vue'

@Component
export default class MediaNetworksSelectorModal extends SelectorModal {
  @Prop({ required: true }) private datasetRows!: DatasetRows
  @Prop({ required: true }) private columnInfos!: DataColumn[]

  private get columnDefs (): ColDef[] {
    const columns = Object.keys(omit(this.datasetRows[0] ?? {}, PRIMARY_FIELD))

    return configureColumnDefs(columns, monColumnsOrder, this.columnInfos)
      .map((colDef, index) => {
        if (index === 0) {
          colDef.cellRenderer = 'agGroupCellRenderer'
        }

        if (this.needCustomRenderer && colDef.field === ALLGEMEIN_STELLE_SELECTION_COLUMN) {
          colDef.headerComponentFramework = GridHeaderDisclaimer
          colDef.headerComponentParams = {
            enableSorting: false,
            enableMenu: false,
            context: this.$t('planning.filters.labels.sheet-size-selection-disclaimer') as string
          }
          colDef.cellRendererFramework = GridCellSelectInput
          colDef.cellRendererParams = {
            getOptions: (row: DatasetRow) => {
              const { active, sheetSize } = row[ALLGEMEIN_STELLE_SELECTION_COLUMN] as DatasetRow

              return {
                disabled: this.selectedItems.findIndex(item => item[PRIMARY_FIELD] === row[PRIMARY_FIELD]) === -1,
                options: monSheetSizeOptions,
                selected: sheetSize,
                visible: active === 'True'
              }
            }
          }
        }

        return colDef
      })
  }

  override openModal () {
    this.selectedWasChanged = false
    this.selectedItems = cloneDeep(this.selected)
    this.showModal = true

    const _unwatch = this.$watch('selectedItems', () => {
      const selectedIds = this.selected.map(row => row[PRIMARY_FIELD])
      const selectedItemsIds = this.selectedItems.map(row => row[PRIMARY_FIELD])
      if (!isEqual(selectedIds, selectedItemsIds)) {
        this.selectedWasChanged = true
        _unwatch()
      }
    })
  }

  protected override get sourceSwitcherAllLabel (): string {
    return this.$t('planning.filters.labels.all-networks') as string
  }

  protected override get sourceSwitcherSelectedLabel (): string {
    return this.$t('planning.filters.labels.selected-networks') as string
  }

  protected override get tableProps (): VueInstanceProps {
    return {
      rowData: this.datasetRows,
      columnDefs: this.columnDefs,
      rowHeight: 35,
      ...monTableProps
    }
  }

  protected override get tableListeners (): VueInstanceListeners {
    return {
      'action:select-change': this.handleSelectSheetSize
    }
  }

  protected override get modalTitle (): string {
    return this.$t('planning.filters.labels.networks-selector') as string
  }

  protected get needCustomRenderer (): boolean {
    return this.datasetRows.some(row => get(row, [ALLGEMEIN_STELLE_SELECTION_COLUMN, 'active']) === 'True')
  }

  protected override handleModelUpdatedEvent (event: ModelUpdatedEvent) {
    event.columnApi.autoSizeAllColumns(false)
  }

  private handleSelectSheetSize (item: GridCellSelectInputEvent) {
    const { value, data } = item
    const itemIndex = this.selectedItems.findIndex(item => item[PRIMARY_FIELD] === data[PRIMARY_FIELD])

    this.$set(this.selectedItems[itemIndex][ALLGEMEIN_STELLE_SELECTION_COLUMN] as DatasetRow, 'sheetSize', value)
  }
}
