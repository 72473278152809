import MediaNetworksGranularityPriceBar from './selector-table/components/media-networks-granularity-price-bar.vue'
import {
  CITY_FIELD, SITE_TYPE_COUNT_FIELD, POPULATION_COLUMN, mediaOwnerNetworkColumnNameTranslations, LOCATIONS_FIELD, GRP_COLUMN,
  ALL_OVER_POPULATION_COLUMN, BANNED_PRODUCTS_COLUMN, BID_COLUMN, BOOKING_GRANULARITY_FIELD, ILLUMINATION_COLUMN,
  BOOKING_GRANULARITY_PRICE_FIELD, DAILY_PRICE_COLUMN, MAIN_SITE_TYPES_COLUMN, NIELSEN_COLUMN, OKZ_COLUMN,
  MARKETING_TEXT_COLUMN, MEDIA_OWNER_NAME_COLUMN, NETWORK_DESCRIPTION_COLUMN, NETWORK_SIZE_COLUMN,
  NETWORK_SIZE_SURROUNDINGS, PRICE_SURROUNDING_COLUMN, SUBNET_COLUMN, ALLGEMEIN_STELLE_SELECTION_COLUMN,
  MAIN_LOCATION_POPULATION_COLUMN, MAIN_SITE_TYPE_COLUMN, MEDIA_OWNER_COLUMN
} from '@/constants/unit/fields'
import { GRID_COLUMN_TYPE_NUMBER, GRID_COLUMN_TYPE_RIGHT_ALIGNED, GRID_COLUMN_TYPE_STRING } from '@/components/shared/ag-grid/constants'
import { ColDef } from 'ag-grid-community'
import { defaultColumnTypes } from '@/components/shared/ag-grid/constants/column-types'

const detailsColumnDefs: Record<string, ColDef> = {
  [CITY_FIELD]: {
    type: [GRID_COLUMN_TYPE_STRING],
    filter: 'agMultiColumnFilter',
    menuTabs: ['filterMenuTab', 'columnsMenuTab']
  }
}

export const monColumnsOrder = [
  MEDIA_OWNER_COLUMN,
  MEDIA_OWNER_NAME_COLUMN,
  CITY_FIELD,
  MAIN_SITE_TYPE_COLUMN,
  MAIN_SITE_TYPES_COLUMN,
  ALLGEMEIN_STELLE_SELECTION_COLUMN,
  NETWORK_SIZE_COLUMN,
  BOOKING_GRANULARITY_PRICE_FIELD,
  NETWORK_DESCRIPTION_COLUMN,
  'NetworkType1',
  OKZ_COLUMN,
  NIELSEN_COLUMN,
  GRP_COLUMN,
  ILLUMINATION_COLUMN,
  BOOKING_GRANULARITY_FIELD,
  DAILY_PRICE_COLUMN,
  PRICE_SURROUNDING_COLUMN,
  MAIN_LOCATION_POPULATION_COLUMN,
  ALL_OVER_POPULATION_COLUMN,
  'NetworkType2',
  'NetworkType3',
  SITE_TYPE_COUNT_FIELD,
  POPULATION_COLUMN,
  NETWORK_SIZE_SURROUNDINGS,
  BANNED_PRODUCTS_COLUMN,
  SUBNET_COLUMN,
  BID_COLUMN,
  MARKETING_TEXT_COLUMN
]

export const locationDetailsProps = {
  detailGridOptions: {
    columnTypes: defaultColumnTypes,
    columnDefs: [CITY_FIELD, SITE_TYPE_COUNT_FIELD, POPULATION_COLUMN, GRP_COLUMN]
      .map(column => ({
        field: column,
        type: [GRID_COLUMN_TYPE_NUMBER, GRID_COLUMN_TYPE_RIGHT_ALIGNED],
        menuTabs: ['filterMenuTab'],
        filter: 'agNumberColumnFilter',
        headerName: mediaOwnerNetworkColumnNameTranslations[column],
        ...(detailsColumnDefs[column] ?? {})
      })),
    defaultColDef: {
      flex: 1,
      sortable: true
    }
  },
  getDetailRowData: (params) => params.successCallback(params.data[LOCATIONS_FIELD])
}

export const monTableProps = {
  quickFilter: true,
  masterDetail: true,
  detailCellRendererParams: locationDetailsProps,
  frameworkComponents: {
    monGranularityPriceBar: MediaNetworksGranularityPriceBar
  },
  statusBar: {
    statusPanels: [{ statusPanel: 'monGranularityPriceBar' }]
  }
}
