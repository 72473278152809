

























import { MAX_LEGEND_COLUMNS } from '../constants'
import { LegendValuesTableMixin } from './legend-values-table.mixin'
import { Component, Prop } from 'vue-property-decorator'
import { VueClassDef, VueComponentDeclaration, VueInstanceListeners, VueInstanceProps, VueStyleDef } from '@/types/vue'

@Component
export default class LegendValuesTable extends LegendValuesTableMixin {
  @Prop({ default: 'div' }) private wrapperTag!: string | VueComponentDeclaration
  @Prop({ default: () => ({}) }) private wrapperProps!: VueInstanceProps
  @Prop({ default: () => ({}) }) private wrapperListeners!: VueInstanceListeners

  private get valuesStyle (): VueStyleDef | null {
    if (this.legendValues.length < MAX_LEGEND_COLUMNS) {
      return {
        gridTemplateColumns: `repeat(${this.legendValues.length}, minmax(auto, 1fr))`
      }
    }

    return null
  }

  private get labelClass (): VueClassDef {
    return {
      legend__label: true,
      legend__label__nowrap: !this.wrapLabels
    }
  }
}
