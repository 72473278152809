import { LocaleMessageObject } from 'vue-i18n'
import { ReportMsgId } from '@/types/report-enums'
import { OrderActions, OrderStatus } from '@/types/orders/enums'
import { OffergatewayErrorsMsgId } from '@/types/offergateway-enums'
import {
  FILTER_AREA,
  FILTER_AVAILABILITY,
  FILTER_BANNED_PRODUCTS,
  FILTER_BUILD_TYPE,
  FILTER_CITY,
  FILTER_CLUSTERING,
  FILTER_CPT,
  FILTER_EXCLUDE_MEDIA_OWNER,
  FILTER_EXCLUSION,
  FILTER_ILLUMINATION,
  FILTER_INCLUDE_MEDIA_OWNER,
  FILTER_INCLUSION,
  FILTER_ISOLATION,
  FILTER_MEDIA_CRITERIA,
  FILTER_MEDIA_TYPE,
  FILTER_MEDIAOWNERNETWORK,
  FILTER_MULTISITE_LOCATION,
  FILTER_PPS,
  FILTER_PRICE,
  FILTER_VICINITY,
  GOAL_BUDGET,
  GOAL_GRP,
  GOAL_QUANTITY,
  GOAL_QUOTA,
  OVERALL_GOAL_BUDGET,
  RANKING_CPT,
  RANKING_CUSTOM_AREA,
  RANKING_MEDIAOWNER,
  RANKING_PPS
} from '@/constants/scoring'
import {
  APPLY_DRIVING_DISTANCE_VALUE,
  AREA_KEYS_VALUE,
  BANNED_PRODUCTS_VALUE,
  BOOKING_TIME_FRAMES_VALUE,
  CITY_NAMES_VALUE,
  EXTERNAL_IDS_VALUE,
  ILLUMINATION_VALUE,
  MEDIA_FILTERING_VALUE,
  MEDIA_OWNER_NAMES_VALUE,
  MEDIA_OWNER_NETWORKS_VALUE,
  MIN_CLUSTER_SIZE_VALUE,
  MIN_DISTANCE_VALUE,
  NUMBER_OF_SITES_PER_UNIT,
  PLZ_VALUE,
  POI_VALUE,
  PPS_FILTER_MODE,
  PPS_RANGE,
  PPS_TO_AVERAGE,
  PRICE_VALUE,
  RANGE_TYPE_VALUE,
  RANGE_VALUE,
  RESTRICTED_BUILD_TYPES_VALUE,
  SITE_TYPES_VALUE,
  TARGET_VALUE,
  TKP_VALUE,
  TOLERANCE_VALUE,
  WEIGHT_VALUE
} from '@/constants/functions/scoring-functions-param-names'

const messages: LocaleMessageObject = {
  common: {
    buttons: {
      back: {
        en: 'Back',
        de: 'Zurück'
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen'
      },
      clear: {
        en: 'Clear',
        de: 'Löschen'
      },
      close: {
        en: 'Close',
        de: 'Schließen'
      },
      confirm: {
        en: 'Confirm',
        de: 'Bestätigen'
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten'
      },
      load: {
        en: 'Load',
        de: 'Laden'
      },
      logout: {
        en: 'Logout',
        de: 'Abmelden'
      },
      next: {
        en: 'Next',
        de: 'Weiter'
      },
      ok: {
        en: 'Ok',
        de: 'Ok'
      },
      remove: {
        en: 'Remove',
        de: 'Entfernen'
      },
      save: {
        en: 'Save',
        de: 'Speichern'
      },
      'save-as': {
        en: 'Save as',
        de: 'Speichern unter'
      },
      'upload-another': {
        en: 'Upload another',
        de: 'Hochladen einer anderen Datei'
      },
      skip: {
        en: 'Skip',
        de: 'Überspringen'
      },
      download: {
        en: 'Download',
        de: 'Herunterladen'
      },
      select: {
        en: 'Select',
        de: 'Auswählen'
      },
      import: {
        en: 'Import',
        de: 'Importieren'
      },
      copy: {
        en: 'Copy',
        de: 'Kopieren'
      },
      continue: {
        en: 'Continue',
        de: 'Fortfahren'
      }
    },
    confirm: {
      buttons: {
        'ignore-changes': {
          en: 'Ignore changes',
          de: 'Änderungen ignorieren'
        },
        'save-changes': {
          en: 'Save changes',
          de: 'Änderungen speichern'
        }
      },
      labels: {
        'unsaved-confirm-message': {
          en: 'This page has unsaved changes. If you proceed, the changes you have made will be lost',
          de: 'Ungespeicherte Änderungen vorhanden. Diese gehen beim Fortfahren verloren'
        },
        'unsaved-confirm-title': {
          en: 'Proceed with unsaved changes?',
          de: 'Fortfahren trotz ungespeicherter Änderungen?'
        }
      }
    },
    errors: {
      'reading-file': {
        en: 'File reading error. Please try again',
        de: 'Fehler beim Lesen der Datei'
      },
      'valid-excel-file': {
        en: 'Please upload valid Excel file',
        de: 'Bitte eine gültige Excel-Datei hochladen'
      },
      'valid-file': {
        en: 'Please upload a valid file',
        de: 'Bitte eine gültige Datei (.xlsx oder .csv) hochladen'
      },
      'invalid-image': {
        en: 'Invalid image supplied.',
        de: 'Ungültiges Bild'
      },
      'entity-not-found': {
        en: 'The {entity} you have requested is not available. It might be deleted or you don\'t have enough rights to view it',
        de: 'Die angefragte {entity} ist nicht verfügbar. Sie wurde vielleicht gelöscht oder Ihre Berechtigungen reichen für eine Anzeige nicht aus'
      },
      'import-file-failed': {
        en: 'An error occurred while importing the file (input file corrupt or unreadable). Please, try to import another file',
        de: 'Beim Import der Datei ist ein Fehler aufgetreten (Datei beschädigt oder unlesbar). Bitte überprüfe die Datei und versuch es erneut'
      },
      'visualization-not-available': {
        en: 'The visualisation is not loading. Please get in touch with your contact person at it works.',
        de: 'Die Visualisierung kann nicht geladen werden. Bitte wenden Sie sich an Ihre/n AnsprechpartnerIn bei it works.'
      }
    },
    'coordinates-service': {
      buttons: {
        parse: {
          en: 'Parse',
          de: 'Parse'
        },
        reset: {
          en: 'Reset',
          de: 'Zurücksetzen'
        },
        geocode: {
          en: 'Geocode',
          de: 'Geokodieren'
        },
        'see-details': {
          en: 'See more details',
          de: 'Mehr Details'
        }
      },
      labels: {
        'address-column': {
          en: 'Address columns',
          de: 'Adressenzeile'
        },
        addresses: {
          en: 'Addresses',
          de: 'Adressen'
        },
        confidence: {
          en: 'Confidence',
          de: 'Zuverlässigkeit'
        },
        'content-type': {
          en: 'Content type',
          de: 'Datentyp'
        },
        coordinates: {
          en: 'Coordinates',
          de: 'Koordinaten'
        },
        'select-valid-file': {
          en: 'Please select a valid file with one or more columns',
          de: 'Bitte eine gültige Datei auswählen'
        },
        'geocode-help': {
          en: 'Select columns from dataset that are parts of an address and press Geocode',
          de: 'Spalten auswählen, die Teil der Adresse sind und auf "Geokodieren" drücken'
        },
        'geocode-errors': {
          en: 'Geocoding was completed with errors. Found {errorsCount} invalid rows of total {totalCount} rows',
          de: 'Fehler bei der Geokodierung. {errorsCount} ungültige Zeilen von insgesamt {totalCount} Zeilen gefunden'
        },
        'geocode-successfully': {
          en: 'Geocoding was completed successfully. Totally parsed rows: {totalCount}',
          de: 'Geokodierung erfolgreich durchgeführt. {totalCount} geparsed '
        },
        'coordinates-parse-error': {
          en: 'Coordinates were parsed  with errors. Found {errorsCount} invalid rows out of total {totalCount} rows',
          de: 'Fehler beim Einlesen der Koordinaten. {errorsCount} ungültige Zeilen von insgesamt {totalCount} Zeilen gefunden'
        },
        'coordinates-parse-successfully': {
          en: 'Successfully parsed {totalCount} rows with coordinates',
          de: 'Überführung der Koordinaten von {totalCount} Zeilen erfolgreich '
        },
        'geocoding-details': {
          en: 'Geocoding details',
          de: 'Details Gekodierung'
        },
        'geocode-success-option': {
          en: 'Geocoding successful',
          de: 'Geokodierung erfolgreich'
        },
        'geocode-unsuccess-option': {
          en: 'Geocoding not successful',
          de: 'Geokodierung fehlgeschlagen'
        },
        'geocode-success-hint': {
          en: 'Geocoding of adress successful',
          de: 'Erfolgreiche Geokodierung der Adresse'
        },
        'geocode-unsuccess-hint': {
          en: 'Geocoding of adress not successful',
          de: 'Geokodierung der Adresse fehlgeschlagen'
        }
      }
    },
    labels: {
      all: {
        en: 'All',
        de: 'Alle'
      },
      reports: {
        en: 'Reports',
        de: 'Berichte'
      },
      'all-time': {
        en: 'All Time',
        de: 'Alle'
      },
      'choose-color': {
        en: 'Choose color',
        de: 'Farbe auswählen'
      },
      'color-picker': {
        en: 'Color picker',
        de: 'Farbpipette'
      },
      'current-color-is': {
        en: 'Current color is {color}',
        de: 'Aktuelle Farbe: {color}'
      },
      'drag-and-drop': {
        en: 'Drag and drop your files or',
        de: 'Datei hierhin ziehen und loslassen oder'
      },
      'drop-file': {
        en: 'Drop you files to upload',
        de: 'Datei zum Hochladen hier ablegen'
      },
      'file-uploaded': {
        en: 'File {file} has been successfully uploaded',
        de: 'Datei {file} wurde erfolgreich hochgeladen'
      },
      filter: {
        en: 'Filter',
        de: 'Filter'
      },
      id: {
        en: 'ID',
        de: 'ID'
      },
      'image-not-found': {
        en: 'Image not found',
        de: 'Bild nicht gefunden'
      },
      invert: {
        en: 'Invert',
        de: 'Auswahl umkehren'
      },
      'last-change': {
        en: 'Last change',
        de: 'Letzte Änderung'
      },
      latitude: {
        en: 'Latitude',
        de: 'Breitengrad'
      },
      'latitude-column': {
        en: 'Latitude column',
        de: 'Breitengrad-Spalte'
      },
      login: {
        en: 'Login',
        de: 'Einloggen'
      },
      longitude: {
        en: 'Longitude',
        de: 'Längengrad'
      },
      'longitude-column': {
        en: 'Longitude column',
        de: 'Längengrad-Spalte'
      },
      name: {
        en: 'Name',
        de: 'Name'
      },
      'no-data-found': {
        en: 'No data found',
        de: 'Keine Dateien gefunden'
      },
      'page-not-found': {
        en: 'Ooops! Page not found',
        de: 'Ups! Diese Seite konnte nicht gefunden werden'
      },
      password: {
        en: 'Password',
        de: 'Passwort'
      },
      planning: {
        en: 'G-Target',
        de: 'G-Target'
      },
      orders: {
        en: 'G-Buying',
        de: 'G-Buying'
      },
      analytics: {
        en: 'Analytics',
        de: 'Analytics'
      },
      search: {
        en: 'Search',
        de: 'Suchen'
      },
      'select-all': {
        en: 'Select all',
        de: 'Alle auswählen'
      },
      'remove-all': {
        en: 'Remove all',
        de: 'Alles entfernen'
      },
      'select-none': {
        en: 'Select none',
        de: 'Nichts auswählen'
      },
      'there-nothing': {
        en: 'There is nothing here',
        de: 'Es konnte nichts gefunden werden'
      },
      upload: {
        en: 'Upload',
        de: 'Hochladen'
      },
      'choose-file': {
        en: 'Choose file',
        de: 'Datei auswählen '
      },
      'upload-file': {
        en: 'Upload a file',
        de: 'Hochladen einer Datei'
      },
      'upload-or-drag': {
        en: 'Upload or drop file here',
        de: 'Datei hochladen oder hierhin ziehen'
      },
      'uploading-file': {
        en: 'Uploading file {file}',
        de: 'Datei {file} wird hochgeladen '
      },
      'user-name': {
        en: 'Username',
        de: 'Benutzername'
      },
      'user-not-found': {
        en: 'User not found',
        de: 'Benutzer nicht gefunden'
      },
      visualization: {
        en: 'G-Maps',
        de: 'G-Maps'
      },
      wizard: {
        en: 'Wizard',
        de: 'Assistent'
      },
      range: {
        en: 'Range',
        de: 'Bereich'
      },
      yes: {
        en: 'Yes',
        de: 'Ja '
      },
      no: {
        en: 'No',
        de: 'Nein '
      },
      granularity: {
        en: 'Granularity',
        de: 'Granularität '
      },
      error: {
        en: 'Error',
        de: 'Fehler'
      },
      warning: {
        en: 'Warning',
        de: 'Warnung'
      },
      none: {
        en: 'None',
        de: 'Keine'
      },
      'loading-image': {
        en: 'Loading image',
        de: 'Bild laden'
      },
      user: {
        en: 'User',
        de: 'Benutzer'
      },
      period: {
        en: 'Time period',
        de: 'Zeitraum'
      },
      sequenceNumber: {
        en: 'Planning-Nr.',
        de: 'Planungs-Nr.'
      },
      description: {
        en: 'Description',
        de: 'Beschreibung'
      },
      help: {
        en: 'Help',
        de: 'Hilfe'
      },
      actions: {
        en: 'Actions',
        de: 'Aktionen'
      },
      'loading-overlay-label': {
        en: 'Just a second. We are loading necessary data',
        de: 'Bitte warten, die Daten werden geladen'
      },
      'rows-selected': {
        en: 'Rows selected: {amount}',
        de: 'Selektierte Reihen: {amount}'
      },
      'sum-brutto-price': {
        en: 'Sum Brutto Price: {sum}',
        de: 'Summe Brutto Preis: {sum}'
      }
    },
    'location-picker': {
      buttons: {
        'add-location': {
          en: 'Add POI',
          de: 'POI hinzufügen'
        },
        'delete-location': {
          en: 'Delete POI',
          de: 'POI löschen'
        }
      },
      labels: {
        added: {
          en: 'Added',
          de: 'Hinzugefügt'
        },
        origin: {
          en: 'Origin',
          de: 'Ursprung'
        },
        'pick-location': {
          en: 'Pick a POI',
          de: 'POI auswählen'
        },
        removed: {
          en: 'Removed',
          de: 'Entfernt'
        },
        total: {
          en: 'Total',
          de: 'Gesamt'
        }
      }
    },
    'areas-import': {
      title: {
        en: 'Import area keys',
        de: 'Gebietsschlüssel importieren'
      },
      'file-label': {
        en: 'File',
        de: 'Datei'
      },
      'granularity-label': {
        en: 'Granularity',
        de: 'Granularität'
      },
      'key-column-label': {
        en: 'Key column',
        de: 'Schlüsselspalte'
      },
      'areas-import-successfully': {
        en: 'Successfully imported {totalCount} areas',
        de: '{totalCount} Gebiete erfolgreich importiert '
      },
      'areas-import-error': {
        en: 'Areas were imported with errors. Found {errorsCount} invalid areas of total {totalCount} areas.',
        de: 'Fehlerhafter Import der Gebiete. {errorsCount} fehlerhafte Gebiete von insgesamt {totalCount} '
      },
      'areas-import-need-to-import': {
        en: 'Please, click on "Import" button to import areas for selected granularity.',
        de: 'Bitte auf "Importieren" klicken um Gebiete entsprechender Granularität zu importieren'
      }
    },
    placeholders: {
      'choose-file': {
        en: 'Choose a file...',
        de: 'Datei auswählen...'
      },
      'drop-file': {
        en: 'Drop file here...',
        de: 'Datei hier ablegen...'
      },
      search: {
        en: 'Search...',
        de: 'Suchen nach...'
      },
      'search-dataset': {
        en: 'Search datasets...',
        de: 'Datei durchsuchen...'
      },
      'search-geometry': {
        en: 'Search geometries...',
        de: 'Geometrien durchsuchen...'
      },
      'search-geometrysets': {
        en: 'Search geometrysets...',
        de: 'Geometrien durchsuchen...'
      },
      'user-name': {
        en: 'Your username',
        de: 'Benutzername'
      },
      'add-comment': {
        en: 'Add a comment...',
        de: 'Kommentar hinzufügen)...'
      }
    },
    modals: {
      'list-modal': {
        'no-items': {
          en: 'No items',
          de: 'Keine Elemente vorhanden'
        },
        'no-filtered-items': {
          en: 'No items found',
          de: 'Keine Elemente gefunden'
        },
        'new-item': {
          en: 'New item',
          de: 'Neues Element'
        },
        'confirm-removal-title': {
          en: 'Confirm removal',
          de: 'Entfernen bestätigen'
        },
        'confirm-removal-msg': {
          en: 'Are you sure you want to remove {name}?',
          de: 'Soll {name} entfernt werden?'
        }
      },
      'select-columns-modal': {
        'select-columns': {
          en: 'Select columns',
          de: 'Spalten auswählen'
        },
        'available-columns': {
          en: 'Available columns',
          de: 'Verfügbare Spalten'
        },
        'no-columns-available': {
          en: 'No columns available',
          de: 'Keine Spalten verfürgbar'
        },
        'selected-columns': {
          en: 'Selected columns',
          de: 'Ausgewählte Spalten'
        },
        'please-select-columns': {
          en: 'Please select columns',
          de: 'Bitte Spalten auswählen'
        },
        'select-max-columns': {
          en: 'Please select up to {max} columns',
          de: 'Bitte bis zu {max} Spalten auswählen'
        }
      }
    },
    'ag-grid': {
      page: {
        en: 'Page',
        de: 'Seite'
      },
      more: {
        en: 'More',
        de: 'Mehr'
      },
      to: {
        en: 'To',
        de: 'Bis'
      },
      of: {
        en: 'Of',
        de: 'Von'
      },
      next: {
        en: 'Next',
        de: 'Nächster'
      },
      last: {
        en: 'Last',
        de: 'Letzte'
      },
      first: {
        en: 'First',
        de: 'Erste'
      },
      previous: {
        en: 'Previous',
        de: 'Bisherige'
      },
      loadingOoo: {
        en: 'Loading...',
        de: 'Werden geladen...'
      },

      // for set filter
      selectAll: {
        en: 'Select All',
        de: 'Alle auswählen'
      },
      searchOoo: {
        en: 'Search...',
        de: 'Suchen nach...'
      },
      blanks: {
        en: 'Blanks',
        de: 'Lücke'
      },

      // for number filter and text filter
      filterOoo: {
        en: 'Filter...',
        de: 'Filter...'
      },
      equals: {
        en: 'Equals',
        de: 'Gleich'
      },
      notEqual: {
        en: 'Not equal',
        de: 'Nicht gleich'
      },

      // for the date filter
      dateFormatOoo: {
        en: 'YYYY-MM-DD',
        de: 'JJJJ-MM-TT'
      },

      // for number filter
      lessThan: {
        en: 'Less than',
        de: 'Weniger als'
      },
      greaterThan: {
        en: 'Greater than',
        de: 'Größer als'
      },
      lessThanOrEqual: {
        en: 'Less than or equals',
        de: 'Weniger als oder gleich'
      },
      greaterThanOrEqual: {
        en: 'Greater than or equal',
        de: 'Größer als oder gleich'
      },
      inRange: {
        en: 'In range',
        de: 'Im Bereich'
      },
      inRangeStart: {
        en: 'Range start',
        de: 'Start Bereich'
      },
      inRangeEnd: {
        en: 'Range end',
        de: 'Ende Bereich'
      },

      // for text filter
      contains: {
        en: 'Contains',
        de: 'Enthält'
      },
      notContains: {
        en: 'Not contains',
        de: 'Enthält nicht'
      },
      startsWith: {
        en: 'Starts with',
        de: 'Beginnt mit'
      },
      endsWith: {
        en: 'Ends with',
        de: 'Endet mit'
      },

      // filter conditions
      andCondition: {
        en: 'AND',
        de: 'UND'
      },
      orCondition: {
        en: 'OR',
        de: 'ODER'
      },

      // filter buttons
      applyFilter: {
        en: 'Apply',
        de: 'Anwenden'
      },
      resetFilter: {
        en: 'Reset',
        de: 'Zurücksetzen'
      },
      clearFilter: {
        en: 'Clear',
        de: 'Löschen'
      },

      // the header of the default group column
      group: {
        en: 'Group',
        de: 'Gruppe'
      },

      // tool panel
      columns: {
        en: 'Columns',
        de: 'Spalten'
      },
      filters: {
        en: 'Filters',
        de: 'Filter'
      },
      rowGroupColumns: {
        en: 'Pivot Columns',
        de: 'Pivot-Spalten'
      },
      rowGroupColumnsEmptyMessage: {
        en: 'Drag here to set row groups',
        de: 'Hierhin ziehen, um Zeilengruppen festzulegen'
      },
      valueColumns: {
        en: 'Value Columns',
        de: 'Werte-Spalten'
      },
      pivotMode: {
        en: 'Pivot Mode',
        de: 'Pivot-Modus'
      },
      groups: {
        en: 'Groups',
        de: 'Gruppen'
      },
      values: {
        en: 'Values',
        de: 'Werte'
      },
      pivots: {
        en: 'Pivots',
        de: 'Drehpunkte'
      },
      valueColumnsEmptyMessage: {
        en: 'Drag here to aggregate',
        de: 'Zum Aggregieren hierhin ziehen'
      },
      pivotColumnsEmptyMessage: {
        en: 'Drag here to set column labels',
        de: 'Hierhin ziehen, um Spaltenbeschriftungen festzulegen'
      },
      toolPanelButton: {
        en: 'Tool Panel',
        de: 'Tool Panel'
      },

      // other
      noRowsToShow: {
        en: 'No Rows to show',
        de: 'Keine Zeilen anzuzeigen'
      },
      enabled: {
        en: 'Enabled',
        de: 'Aktiviert'
      },

      // enterprise menu
      pinColumn: {
        en: 'Pin Column',
        de: 'Pin-Spalte'
      },
      valueAggregation: {
        en: 'Value Aggregation',
        de: 'Wertaggregation'
      },
      autosizeThiscolumn: {
        en: 'Autosize this Column',
        de: 'Automatische Skalierung dieser Spalte'
      },
      autosizeAllColumns: {
        en: 'Autosize all Columns',
        de: 'Alle Spalten automatisch skalieren'
      },
      groupBy: {
        en: 'Group by',
        de: 'Gruppiere nach'
      },
      ungroupBy: {
        en: 'Un-Group by',
        de: 'Gruppierung aufheben nach'
      },
      resetColumns: {
        en: 'Reset Columns',
        de: 'Spalten zurücksetzen'
      },
      expandAll: {
        en: 'Expand All',
        de: 'Alle erweitern'
      },
      collapseAll: {
        en: 'Collapse All',
        de: 'Alle reduzieren'
      },
      toolPanel: {
        en: 'Tool Panel',
        de: 'Tool Panel'
      },
      export: {
        en: 'Export',
        de: 'Herunterladen'
      },
      csvExport: {
        en: 'CSV Export',
        de: 'CSV Herunterladen'
      },
      excelExport: {
        en: 'Excel Export (.xlsx)',
        de: 'Excel herunterladen (.xlsx)'
      },
      excelXmlExport: {
        en: 'Excel Export (.xml)',
        de: 'Excel herunterladen (.xml)'
      },
      onlySelected: {
        en: 'Only selected',
        de: 'Nur ausgewählte'
      },
      wholeTable: {
        en: 'Whole table',
        de: 'Komplette Tabelle'
      },
      createChart: {
        en: 'Create chart',
        de: 'Diagramm erstellen'
      },

      // enterprise menu (charts)
      pivotChartAndPivotMode: {
        en: 'Pivot Chart & Pivot Mode‎',
        de: 'Pivot-Chart & Pivot-Modus‎'
      },
      pivotChart: {
        en: 'Pivot Chart',
        de: 'Pivot-Chart'
      },
      chartRange: {
        en: 'Chart Range',
        de: 'Diagrammbereich'
      },

      columnChart: {
        en: 'Column',
        de: 'Säule'
      },
      groupedColumn: {
        en: 'Grouped‎',
        de: 'Gruppiert‎'
      },
      stackedColumn: {
        en: 'Stacked‎',
        de: 'Gestapelt‎'
      },
      normalizedColumn: {
        en: '100% stacked‎',
        de: '100% gestapelt‎'
      },

      barChart: {
        en: 'Bar',
        de: 'Balken'
      },
      groupedBar: {
        en: 'Grouped‎',
        de: 'Gruppiert‎'
      },
      stackedBar: {
        en: 'Stacked‎',
        de: 'Gestapelt‎'
      },
      normalizedBar: {
        en: '100% stacked‎',
        de: '100% gestapelt‎'
      },

      pieChart: {
        en: 'Pie',
        de: 'Kreisdiagramm'
      },
      pie: {
        en: 'Pie',
        de: 'Kreisdiagramm'
      },
      doughnut: {
        en: 'Doughnut',
        de: 'Ring'
      },

      line: {
        en: 'Line',
        de: 'Liniendiagramm'
      },

      xyChart: {
        en: 'X-/Y-(Scatter)',
        de: 'X-/Y-(Streuung)'
      },
      scatter: {
        en: 'Scatter‎',
        de: 'Streuung‎'
      },
      bubble: {
        en: 'Bubble‎',
        de: 'Blase‎'
      },

      areaChart: {
        en: 'Area',
        de: 'Bereich'
      },
      area: {
        en: 'Area',
        de: 'Bereich'
      },
      stackedArea: {
        en: 'Stacked‎',
        de: 'Gestapelt‎'
      },
      normalizedArea: {
        en: '100% stacked‎',
        de: '100% gestapelt‎'
      },

      // enterprise menu pinning
      pinLeft: {
        en: 'Pin left',
        de: 'Pin links'
      },
      pinRight: {
        en: 'Pin right',
        de: 'Pin rechts'
      },
      noPin: {
        en: 'No pin',
        de: 'Kein Pin'
      },

      // enterprise menu aggregation and status bar
      sum: {
        en: 'Sum',
        de: 'Summe'
      },
      min: {
        en: 'Min',
        de: 'Min'
      },
      max: {
        en: 'Max',
        de: 'Max'
      },
      none: {
        en: 'None',
        de: 'Keine'
      },
      count: {
        en: 'Count',
        de: 'Anzahl'
      },
      average: {
        en: 'Average',
        de: 'Durchschnittlich'
      },
      filteredRows: {
        en: 'Filtered',
        de: 'Gefiltert'
      },
      selectedRows: {
        en: 'Selected',
        de: 'Ausgewählt'
      },
      totalRows: {
        en: 'Total rows',
        de: 'Zeilen insgesamt'
      },
      totalAndFilteredRows: {
        en: 'Rows',
        de: 'Reihen'
      },
      availability: {
        en: 'Avb',
        de: 'Verfügbarkeit'
      },
      uniq: {
        en: 'Uniq',
        de: 'Uniqe'
      },

      // standard menu
      copy: {
        en: 'Copy',
        de: 'Kopieren'
      },
      copyWithHeaders: {
        en: 'Copy with headers',
        de: 'Mit Überschriften kopieren'
      },
      ctrlC: {
        en: 'Ctrl + C',
        de: 'Ctrl + C'
      },
      paste: {
        en: 'Paste',
        de: 'Einfügen'
      },
      ctrlV: {
        en: 'Ctrl + V',
        de: 'Ctrl + V'
      },

      // charts
      pivotChartTitle: {
        en: 'Pivot Chart',
        de: 'Pivot-Chart'
      },
      rangeChartTitle: {
        en: 'Range Chart',
        de: 'Reichweitendiagramm'
      },
      settings: {
        en: 'Settings',
        de: 'Einstellungen'
      },
      data: {
        en: 'Data',
        de: 'Daten'
      },
      format: {
        en: 'Format',
        de: 'Format'
      },
      categories: {
        en: 'Categories',
        de: 'Kategorien'
      },
      defaultCategory: {
        en: '(None)',
        de: '(Keine)'
      },
      series: {
        en: 'Series',
        de: 'Serie'
      },
      xyValues: {
        en: 'X-/Y-Values',
        de: 'X-/Y-Werte'
      },
      paired: {
        en: 'Paired mode',
        de: 'Gepaarter Modus'
      },
      axis: {
        en: 'Axis',
        de: 'Achse'
      },
      color: {
        en: 'Color',
        de: 'Farbe'
      },
      thickness: {
        en: 'Thickness',
        de: 'Dicke'
      },
      xType: {
        en: 'X-Type',
        de: 'X-Typ'
      },
      automatic: {
        en: 'Automatic',
        de: 'automatisch'
      },
      category: {
        en: 'Category',
        de: 'Kategorie'
      },
      number: {
        en: 'Number',
        de: 'Nummer'
      },
      time: {
        en: 'Time',
        de: 'Zeit'
      },
      xRotation: {
        en: 'X-Rotation',
        de: 'X-Drehung'
      },
      yRotation: {
        en: 'Y-Rotation',
        de: 'Y-Drehung'
      },
      ticks: {
        en: 'Ticks',
        de: 'Zecken'
      },
      width: {
        en: 'Width',
        de: 'Breite'
      },
      length: {
        en: 'Length',
        de: 'Länge'
      },
      padding: {
        en: 'Padding',
        de: 'Polsterung'
      },
      chart: {
        en: 'Chart',
        de: 'Diagramm'
      },
      title: {
        en: 'Title',
        de: 'Titel'
      },
      background: {
        en: 'Background',
        de: 'Hintergrund'
      },
      font: {
        en: 'Font',
        de: 'Schriftart'
      },
      top: {
        en: 'Top',
        de: 'Oben'
      },
      right: {
        en: 'Right',
        de: 'Rechts'
      },
      bottom: {
        en: 'Bottom',
        de: 'Unterseite'
      },
      left: {
        en: 'Left',
        de: 'Links'
      },
      labels: {
        en: 'Labels',
        de: 'Labels'
      },
      size: {
        en: 'Size',
        de: 'Größe'
      },
      minSize: {
        en: 'Minimum size',
        de: 'Minimale Größe'
      },
      maxSize: {
        en: 'Maximum size',
        de: 'Maximale Größe'
      },
      legend: {
        en: 'Legend',
        de: 'Legende'
      },
      position: {
        en: 'Position',
        de: 'Position'
      },
      markerSize: {
        en: 'Marker size',
        de: 'Markergröße'
      },
      markerStroke: {
        en: 'Marker stroke',
        de: 'Markierungsstrich'
      },
      markerPadding: {
        en: 'Marker padding',
        de: 'Markerfüllung'
      },
      itemPaddingX: {
        en: 'Item padding X',
        de: 'Artikelauffüllung X'
      },
      itemPaddingY: {
        en: 'Item padding Y',
        de: 'Artikelauffüllung Y'
      },
      strokeWidth: {
        en: 'Stroke width',
        de: 'Strichbreite'
      },
      offset: {
        en: 'Offset',
        de: 'Offset'
      },
      offsets: {
        en: 'Offsets',
        de: 'Offsets'
      },
      tooltips: {
        en: 'Tooltips',
        de: 'Tooltips'
      },
      callout: {
        en: 'Callout',
        de: 'Callout'
      },
      markers: {
        en: 'Markers',
        de: 'Marker'
      },
      shadow: {
        en: 'Shadow',
        de: 'Schatten'
      },
      blur: {
        en: 'Blur',
        de: 'Verwischen'
      },
      xOffset: {
        en: 'X-Offset',
        de: 'X-Versatz'
      },
      yOffset: {
        en: 'Y-Offset',
        de: 'Y-Versatz'
      },
      lineWidth: {
        en: 'Line width',
        de: 'Linienbreite'
      },
      normal: {
        en: 'Normal',
        de: 'Normal'
      },
      bold: {
        en: 'Bold',
        de: 'Fett'
      },
      italic: {
        en: 'Italic',
        de: 'Kursiv'
      },
      boldItalic: {
        en: 'Bold italic',
        de: 'Fett Kursiv'
      },
      predefined: {
        en: 'Predefined',
        de: 'Vordefiniert'
      },
      fillOpacity: {
        en: 'Fill opacity',
        de: 'Deckkraft füllen'
      },
      strokeOpacity: {
        en: 'Line Opacity',
        de: 'Linienopazität'
      },
      columnGroup: {
        en: 'Column',
        de: 'Säule'
      },
      barGroup: {
        en: 'Bar',
        de: 'Balken'
      },
      pieGroup: {
        en: 'Pie',
        de: 'Kreis'
      },
      lineGroup: {
        en: 'Line',
        de: 'Linie'
      },
      scatterGroup: {
        en: 'Scatter',
        de: 'Streuung'
      },
      areaGroup: {
        en: 'Area',
        de: 'Bereich'
      },
      groupedColumnTooltip: {
        en: 'Grouped',
        de: 'Gruppiert'
      },
      stackedColumnTooltip: {
        en: 'Stacked',
        de: 'Gestapelt'
      },
      normalizedColumnTooltip: {
        en: '100% stacked',
        de: '100% gestapelt'
      },
      groupedBarTooltip: {
        en: 'Grouped',
        de: 'Gruppiert'
      },
      stackedBarTooltip: {
        en: 'Stacked',
        de: 'Gestapelt'
      },
      normalizedBarTooltip: {
        en: '100% stacked',
        de: '100% gestapelt'
      },
      pieTooltip: {
        en: 'Pie',
        de: 'Kreisdiagramm'
      },
      doughnutTooltip: {
        en: 'Doughnut',
        de: 'Ring'
      },
      lineTooltip: {
        en: 'Line',
        de: 'Linie'
      },
      groupedAreaTooltip: {
        en: 'Grouped',
        de: 'Gruppiert'
      },
      stackedAreaTooltip: {
        en: 'Stacked',
        de: 'Gestapelt'
      },
      normalizedAreaTooltip: {
        en: '100% Stacked',
        de: '100% gestapelt'
      },
      scatterTooltip: {
        en: 'Scatter',
        de: 'Streuung'
      },
      bubbleTooltip: {
        en: 'Bubble',
        de: 'Blase'
      },
      noDataToChart: {
        en: 'No data available to be charted',
        de: 'Keine Daten zum Anzeigen der Tabelle verfügbar'
      },
      pivotChartRequiresPivotMode: {
        en: 'Pivot chart requires Pivot mode enabled',
        de: 'Für das Pivot-Diagramm muss der Pivot-Modus aktiviert sein'
      },

      // Custom extension

      'remove-selected': {
        en: 'Remove selected',
        de: 'Auswahl entfernen'
      },
      'pagination-label': {
        en: 'Showing {from} to {to} of {total} entries',
        de: 'Zeige {from} bis {to} von {total} Einträgen'
      }
    },
    calendar: {
      'prev-decade': {
        en: 'Previous decade',
        de: 'Vorherige Dekade'
      },
      'prev-year': {
        en: 'Previous year',
        de: 'Vorheriges Jahr'
      },
      'prev-month': {
        en: 'Previous month',
        de: 'Vorheriger Monat'
      },
      'current-month': {
        en: 'Current month',
        de: 'Aktueller Monat'
      },
      'next-month': {
        en: 'Next month',
        de: 'Nächster Monat'
      },
      'next-year': {
        en: 'Next year',
        de: 'Nächstes Jahr'
      },
      'next-decade': {
        en: 'Next decade',
        de: 'Nächste Dekade'
      },
      today: {
        en: 'Today',
        de: 'Heute'
      },
      selected: {
        en: 'Selected date',
        de: 'Ausgewähltes Datum'
      },
      'no-date-selected': {
        en: 'No date selected',
        de: 'Kein Datum ausgewählt'
      },
      calendar: {
        en: 'Calendar',
        de: 'Kalender'
      },
      nav: {
        en: 'Calendar navigation',
        de: 'Kalendernavigation'
      },
      help: {
        en: 'Use cursor keys to navigate calendar dates',
        de: 'Cursor zum Navigieren des Kalenders verwenden'
      }
    },
    reports: {
      labels: {
        category: {
          en: 'Category',
          de: 'Kategorie'
        },
        text: {
          en: 'Text',
          de: 'Text'
        },
        priority: {
          en: 'Priority',
          de: 'Priorität'
        },
        'report-type': {
          en: 'Type',
          de: 'Typ'
        }
      },
      // The report messages taken from IDSMessages.cs file
      messages: {
        [ReportMsgId.IDS_DATADELIVERY_IMPORT_COMPLETED_WITH_WARNINGS]: {
          en: 'Import completed with {warnings} warnings in {lineNo} rows.',
          de: 'Import mit {warnings} Warnungen in {lineNo} Reihen vollständig'
        },
        [ReportMsgId.IDS_DATADELIVERY_IMPORT_ERROR]: {
          en: 'Import completed with errors. Output may be uncompleted.',
          de: 'Import fehlerhaft. Ausgabe kann unvollständig sein'
        },
        [ReportMsgId.IDS_DATADELIVERY_IMPORT_STOP_WITH_ERRORS]: {
          en: 'Stopping import in line {lineNo} in cause of previous errors.',
          de: 'Import in Zeile {lineNo} aufgrund vorheriger Fehler gestoppt'
        },
        [ReportMsgId.IDS_DATADELIVERY_IMPORT_SUCCESS]: {
          en: '{lineNo} records successfully imported.',
          de: '{lineNo} Datensätze erfolgreich importiert'
        },
        [ReportMsgId.IDS_SHAPE_IMPORT_COORDINATE_ERROR]: {
          en: 'Record {RecordNo} invalid coordinate {Coordinate}',
          de: 'Eintrag {RecordNo} beinhaltet ungültige Koordiante {Coordinate} '
        },
        [ReportMsgId.IDS_DATADELIVERY_CONTAINS_EMTPY_ROWS]: {
          en: 'Excel file contains empty rows.',
          de: 'Excel-Datei beinhaltet leere Zeilen'
        },
        [ReportMsgId.IDS_DATADELIVERY_IMPORT_CSV]: {
          en: 'Import error in line {lineNo}.',
          de: 'Fehler Import in Zeile {lineNo}'
        },
        [ReportMsgId.IDS_DATADELIVERY_IMPORT_MATCHING_COLUMNCOUNT]: {
          en: 'Import warning in line {lineNo}. Input has not the expected number of columns ({columns}/{expectedcolumns})',
          de: 'Wichtige Warnung in Zeile {lineNo}. Eingabe hat nicht die erforderliche Anzahl an Spalten ({columns}/{expectedcolumns}) '
        },
        [ReportMsgId.IDS_DATADELIVERY_HEADER_FOUND_IN_LINE]: {
          en: 'Header found in line {lineNo}',
          de: 'Kopfzeile gefunden in Zeile {lineNo}'
        },
        [ReportMsgId.IDS_SCORING_SCORINGFUNCTION_EXCEPTION]: {
          en: 'An exception occured while processing {scoringfunction}',
          de: 'Bei der Bearbeitung von {scoringfunction} ist ein Fehler aufgetreten'
        },
        [ReportMsgId.IDS_SCORING_SCORINGCALCULATOR_INITIALIZATION_ERROR]: {
          en: 'We were not able to process the planning. Please refer to the Support for further help',
          de: 'Leider konnten wir die Planung nicht verarbeiten. Für weitere Hilfe wende dich bitte an den Support'
        }
      }
    }
  },
  planning: {
    confirm: {
      labels: {
        'unsaved-confirm-message': {
          en: 'Current campaign has unsaved changes. If you proceed, the changes you have made will be lost',
          de: 'Die aktuelle Kampagne beinhaltet ungespeicherte Elemente. Beim Fortfahren gehen diese unwiderruflich verloren'
        },
        'unsaved-selector-message': {
          en: 'You have unsaved changes. If you proceed, the changes you have made will be lost',
          de: 'Es bestehen Änderungen die noch nicht gespeichert wurden. Bitte speichere diese bevor du fortfährst'
        }
      }
    },
    functions: {
      [FILTER_MEDIA_TYPE]: {
        title: {
          en: 'Media type filter',
          de: 'Stellenartenfilter'
        },
        explanation: {},
        parameters: {
          [SITE_TYPES_VALUE]: {
            en: 'Media types',
            de: 'Stellenarten'
          }
        }
      },
      [FILTER_VICINITY]: {
        title: {
          en: 'Vicinity filter',
          de: 'Umkreisfilter'
        },
        explanation: {
          en: 'Define advertising areas by POIs and their vicinity',
          de: 'POI-Umkreise als Belegungsgebiete anlegen'
        },
        parameters: {
          [RANGE_TYPE_VALUE]: {
            en: 'Vicinity type parameter',
            de: 'Umgebungstypparameter'
          },
          [RANGE_VALUE]: {
            en: 'Range',
            de: 'Bereich'
          },
          [POI_VALUE]: {
            en: 'Points',
            de: 'Punkte'
          }
        }
      },
      [FILTER_CLUSTERING]: {
        title: {
          en: 'Clustering filter',
          de: 'Clusteringfilter'
        },
        explanation: {
          en: 'Definition of advertising areas by clustering POIs within a certain radius',
          de: 'Clusterzentren (z.B. 3 Autohäuser in einem Radius von 500m) als Belegungsgebiete anlegen'
        },
        parameters: {
          [RANGE_VALUE]: {
            en: 'Range',
            de: 'Bereich'
          },
          [MIN_CLUSTER_SIZE_VALUE]: {
            en: 'Minimal cluster size',
            de: 'Minimal Clustergröße'
          },
          [APPLY_DRIVING_DISTANCE_VALUE]: {
            en: 'Driving distance',
            de: 'Fahrdistanz'
          },
          [POI_VALUE]: {
            en: 'Points',
            de: 'Punkte'
          }
        }
      },
      [FILTER_AREA]: {
        title: {
          en: 'Area filter',
          de: 'Gebietsfilter'
        },
        explanation: {
          en: 'Definition of advertising areas by PLZ5, PLZ8 or OKZ',
          de: 'PLZ5, PLZ8 oder OKZ als Belegungsgebiete anlegen'
        },
        parameters: {
          [AREA_KEYS_VALUE]: {
            en: 'Areakeys',
            de: 'Gebietsschlüssel'
          },
          [TOLERANCE_VALUE]: {
            en: 'Tolerance',
            de: 'Abweichung'
          }
        }
      },
      [FILTER_CITY]: {
        title: {
          en: 'City filter',
          de: 'Städtefilter'
        },
        explanation: {
          en: 'Definition of advertising areas by cities',
          de: 'Städte als Belegungsgebiete anlegen'
        },
        parameters: {
          [CITY_NAMES_VALUE]: {
            en: 'City Names',
            de: 'Städtenamen'
          }
        }
      },
      [FILTER_AVAILABILITY]: {
        title: {
          en: 'Availability filter',
          de: 'Verfügbarkeitsfilter'
        },
        explanation: {},
        parameters: {
          [BOOKING_TIME_FRAMES_VALUE]: {
            en: 'Bookingtimeframe',
            de: 'Belegungszeitraum'
          }
        }
      },
      [FILTER_BANNED_PRODUCTS]: {
        title: {
          en: 'Banned products filter',
          de: 'Produktsperrenfilter'
        },
        explanation: {
          en: 'Exclusion of units, which are prohibited to advetise with by a certain category (e.g. cigarettes)',
          de: 'Ausschluss von Stellen, auf denen Werbung aufgrund einer bestimmten Kategorie (z. B. Zigaretten) verboten ist'
        },
        parameters: {
          [BANNED_PRODUCTS_VALUE]: {
            en: 'Banned products',
            de: 'Verbotene Produkte'
          }
        }
      },
      [RANKING_CUSTOM_AREA]: {
        title: {
          en: 'Custom area ranking',
          de: 'Benutzerdefiniertes-Ranking'
        },
        explanation: {
          en: 'Evaluation of sites by rating values connected to their plz index. For the custom area ranking only data sets with postcode PLZ 8 or PLZ 5 are valid. Units with higher ranking values are scored higher.',
          de: 'Bewertet Stellen nach einem Rankingwert in Verbindung zum PLZ-Index. Für das benutzerdefinierte Ranking sind nur Datensätze mit PLZ 8 oder PLZ 5 gültig. Stellen mit höherem Rankingwert werden besser bewertet.'
        },
        parameters: {
          [PLZ_VALUE]: {
            en: 'PLZ',
            de: 'PLZ'
          }
        }
      },
      [FILTER_EXCLUSION]: {
        title: {
          en: 'Exclusion filter',
          de: 'Stellenausschlussfilter'
        },
        explanation: {
          en: 'Exclusion of sites by single input or list upload',
          de: 'Ausschluss von Stellen per Eingabe oder per Liste'
        },
        parameters: {
          [EXTERNAL_IDS_VALUE]: {
            en: 'External IDs',
            de: 'Externe IDs'
          }
        }
      },
      [FILTER_INCLUSION]: {
        title: {
          en: 'Inclusion filter',
          de: 'Stelleneinschlussfilter'
        },
        explanation: {},
        parameters: {
          [EXTERNAL_IDS_VALUE]: {
            en: 'External IDs',
            de: 'Externe IDs'
          }
        }
      },
      [FILTER_ISOLATION]: {
        title: {
          en: 'Isolation filter',
          de: 'Abstandsfilter'
        },
        explanation: {
          en: 'Defines an isolation radius around a unit',
          de: 'Definiert einen Isolationsradius um eine Werbefläche'
        },
        parameters: {
          [MIN_DISTANCE_VALUE]: {
            en: 'Minimal distance between units (meters)',
            de: 'Minimaler Abstand zwischen Stellen (in Meter)'
          }
        }
      },
      [FILTER_INCLUDE_MEDIA_OWNER]: {
        title: {
          en: 'Media owner inclusion filter',
          de: 'Pächtereinschlussfilter'
        },
        explanation: {
          en: 'Inclusion of sites from certain media owners',
          de: 'Einschluss von Stellen bestimmter Medienanbieter'
        },
        parameters: {
          [MEDIA_OWNER_NAMES_VALUE]: {
            en: 'Media owner',
            de: 'Pächter'
          }
        }
      },
      [FILTER_EXCLUDE_MEDIA_OWNER]: {
        title: {
          en: 'Media owner exclusion filter',
          de: 'Pächterausschlussfilter'
        },
        explanation: {
          en: 'Exclusion of sites from certain media owners',
          de: 'Ausschluss von Stellen bestimmter Medienanbieter'
        },
        parameters: {
          [MEDIA_OWNER_NAMES_VALUE]: {
            en: 'Media owner',
            de: 'Pächter'
          }
        }
      },
      [FILTER_MEDIAOWNERNETWORK]: {
        title: {
          en: 'Media owner network filter',
          de: 'Netzanbieterfilter'
        },
        explanation: {},
        parameters: {
          [MEDIA_OWNER_NETWORKS_VALUE]: {
            en: 'Media owner networks',
            de: 'Pächternetzwerk'
          }
        }
      },
      [RANKING_MEDIAOWNER]: {
        title: {
          en: 'Media owner ranking',
          de: 'Pächter-Ranking'
        },
        explanation: {
          en: 'Controlling defined ranking of media owner which should be considered over others',
          de: 'Bewertet Stellen nach Medienanbietern, die bevorzugt werden sollen'
        },
        parameters: {
          [WEIGHT_VALUE]: {
            en: 'Weight',
            de: 'Gewichtung'
          }
        }
      },
      [FILTER_ILLUMINATION]: {
        title: {
          en: 'Illumination filter',
          de: 'Beleuchtungsfilter'
        },
        explanation: {
          en: 'Restriction of sites by illumination',
          de: 'Einschränkung des Stellenbestands über Beleuchtung der Stelle'
        },
        parameters: {
          [ILLUMINATION_VALUE]: {
            en: 'Illumination',
            de: 'Beleuchtung'
          }
        }
      },
      [FILTER_BUILD_TYPE]: {
        title: {
          en: 'Build type filter',
          de: 'Bauartfilter'
        },
        explanation: {
          en: 'Restriction of sites by build type',
          de: 'Einschränkung des Stellenbestands über Bauart der Stelle'
        },
        parameters: {
          [RESTRICTED_BUILD_TYPES_VALUE]: {
            en: 'Build types',
            de: 'Bauarten'
          }
        }
      },
      [RANKING_PPS]: {
        title: {
          en: 'PpS ranking',
          de: 'PpS-Ranking'
        },
        explanation: {
          en: 'Evaluation of sites by PpS value',
          de: 'Bewertet Stellen nach ihrem PpS-Wert'
        },
        parameters: {
          [WEIGHT_VALUE]: {
            en: 'Weight',
            de: 'Gewichtung'
          }
        }
      },
      [FILTER_PPS]: {
        title: {
          en: 'PpS filter',
          de: 'PpS-Filter'
        },
        explanation: {
          en: 'Restriction and evaluation of sites by PpS value',
          de: 'Ausschluss nach dem PpS-Wert'
        },
        parameters: {
          [PPS_FILTER_MODE]: {
            en: 'PpS filter mode',
            de: 'PpS Filter Art'
          },
          [PPS_RANGE]: {
            en: 'PpS range value',
            de: 'PpS-Bereich'
          },
          [PPS_TO_AVERAGE]: {
            en: 'To region average relation (%)',
            de: 'PpS-Ortsdurchschnitt (%)'
          }
        }
      },
      [FILTER_MULTISITE_LOCATION]: {
        title: {
          en: 'Multisite location filter',
          de: 'Mehrfachstandorte-Filter'
        },
        explanation: {
          en: 'Restriction of sites by number of positions at one location',
          de: 'Einschränkung des Stellenbestands über die Anzahl der Werbeflächen an einem Standort'
        },
        parameters: {
          [NUMBER_OF_SITES_PER_UNIT]: {
            en: 'Units with multiple positions in the same location',
            de: 'Stellen mit Mehrfachstandorten'
          }
        },
        values: {
          'single-location': {
            en: 'Single site',
            de: 'Einzelstelle'
          },
          'n-sites': {
            en: '{amount} sites',
            de: '{amount} Stellen'
          }
        }
      },
      [RANKING_CPT]: {
        title: {
          en: 'CPT ranking',
          de: 'TKP-Ranking'
        },
        explanation: {
          en: 'is an abstract measured value that indicates the basic price of a unit and the number of contacts taken into account. Below local average means that units are rated better if their CPT is a certain percentage "lower" than the average CPT. 0% in relative mode means that all units that die better than average will be scored.',
          de: 'ist ein abstrakter Messwert, der den Grundpreis einer Stelle und die Anzahl der Kontakte berücksichtigt. Unter Ortsdurchschnittbedeutet, dass Stellen besser bewertet werden, deren TKP um einen bestimmten Prozentsatz "niedriger" ist als der durchschnittliche TKP. 0% im relativen Modus bedeutet, dass alle Stellen bewertet werden, die besser als der Durchschnitt sind.'
        },
        parameters: {
          [TKP_VALUE]: {
            en: 'Below region average',
            de: 'Unter Ortsdurchschnitt'
          }
        }
      },
      [FILTER_CPT]: {
        title: {
          en: 'CPT filter',
          de: 'TKP-Filter'
        },
        explanation: {
          en: 'is an abstract measured value that takes into account the basic price of a unit and the number of contacts. The lower the value, the cheaper the price for 1000 contacts.',
          de: 'ist ein abstrakter Messwert, der den Grundpreis einer Stelle und die Anzahl der Kontakte berücksichtigt. Je niedriger der Wert ist, desto günstiger ist der Preis für 1000 Kontakte.'
        },
        parameters: {
          [TKP_VALUE]: {
            en: 'CPT filter values',
            de: 'TKP-Filterwerte'
          }
        }
      },
      [FILTER_PRICE]: {
        title: {
          en: 'Price filter',
          de: 'Preisfilter'
        },
        explanation: {
          en: 'Defines a minimum and a maximum price',
          de: 'Definiert einen Minimum- und Maximumpreis'
        },
        parameters: {
          [PRICE_VALUE]: {
            en: 'Price',
            de: 'Preis'
          }
        }
      },
      [FILTER_MEDIA_CRITERIA]: {
        title: {
          en: 'Media criteria filter',
          de: 'Stelleneigenschaftsfilter'
        },
        explanation: {
          en: 'Restriction of sites by certain site criteria',
          de: 'Ausschluss nach bestimmten Stellenmerkmalen'
        },
        parameters: {
          [MEDIA_FILTERING_VALUE]: {
            en: 'Mediafilteringdata',
            de: 'Daten Medienfilter'
          }
        }
      },
      [OVERALL_GOAL_BUDGET]: {
        title: {
          en: 'Overall budget goal',
          de: 'Gesamtbudget-Ziel'
        },
        explanation: {
          en: 'Determines the maximum budget to be used for the entire campaign.',
          de: 'Bestimmt das maximale Budget, welches für die gesamte Kampagne verwendet werden soll.'
        },
        parameters: {
          [TARGET_VALUE]: {
            en: 'Overall budget value',
            de: 'Gesamtbudgetwert'
          }
        }
      },
      [GOAL_BUDGET]: {
        title: {
          en: 'Budget goal',
          de: 'Budget-Ziel'
        },
        explanation: {
          en: 'Defines a maximum budget to be achieved within a planning path.',
          de: 'Definiert ein maximales Budget, welches innerhalb eines Planungspfades erreicht werden soll.'
        },
        parameters: {
          [TARGET_VALUE]: {
            en: 'Budget value',
            de: 'Budget-Zielwert'
          }
        }
      },
      [GOAL_GRP]: {
        title: {
          en: 'GRP goal',
          de: 'GRP-Ziel'
        },
        explanation: {
          en: 'Determines the maximum advertising pressure to be applied within a planning path.',
          de: 'Bestimmt den maximalen Werbedruck, welcher innerhalb eines Planungspfades ausgeübt werden soll.'
        },
        parameters: {
          [TARGET_VALUE]: {
            en: 'GRP value',
            de: 'GRP-Zielwert'
          }
        }
      },
      [GOAL_QUANTITY]: {
        title: {
          en: 'Quantity goal',
          de: 'Mengen-Ziel'
        },
        explanation: {
          en: 'Determines the maximum number of advertising spaces to be selected within a planning path.',
          de: 'Bestimmt die maximale Anzahl an Werbeflächen, welche innerhalb eines Planungspfades ausgewählt werden sollen.'
        },
        parameters: {
          [TARGET_VALUE]: {
            en: 'Quantity value',
            de: 'Mengen-Zielwert'
          }
        }
      },
      [GOAL_QUOTA]: {
        title: {
          en: 'Quota goal',
          de: 'Quoten-Ziel'
        },
        explanation: {
          en: 'Defines the number of advertising spaces to be selected in relation to the number of inhabitants (within an advertising area - defined in the planning path).',
          de: 'Definiert die Anzahl an Werbeflächen, welche im Verhältnis zu Einwohnern (innerhalb eines Werbegebietes - definert im Planungspfad) ausgewählt werden sollen.'
        },
        parameters: {
          [TARGET_VALUE]: {
            en: 'Quota value',
            de: 'Quoten-Zielwert'
          }
        }
      }
    },
    filters: {
      buttons: {
        add: {
          en: 'Add',
          de: 'Hinzufügen'
        },
        enter: {
          en: 'Enter',
          de: 'Eintragen'
        }
      },
      errors: {
        'booking-not-selected': {
          en: 'Please select at least one time frames to proceed',
          de: 'Bitte Kampagnen-Zeitraum bestimmen'
        },
        'networks-not-found': {
          en: 'Unable to match some BID values with the values from the table. Please check the file you have uploaded. Items not found: {bids}',
          de: 'Einige BID-Werte können nicht mit den Werten aus der Tabelle abgeglichen werden. Bitte überprüfen Sie die Datei, die Sie hochgeladen haben. Elemente nicht gefunden: {bids}'
        },
        'cities-not-found': {
          en: 'Some cities from the excel file were not found. Please check the file you have uploaded. Items not found: {cities}',
          de: 'Einige Städte wurden nicht gefunden. Bitte überprüfen Sie die Datei. Nicht gefundene Städte: {cities}'
        },
        'cities-import-imprecise': {
          en: 'Some cities from the excel file were matched imprecisely. Please check the following names among other similar ones: {cities}',
          de: 'Einige Städte konnten nicht präzise genug zugewiesen werden. Bitte überprüfen Sie die folgenden Städte auf Verwechslungen: {cities}'
        },
        'import-dataset-error': {
          en: 'Imported dataset has an empty values for some columns ({columns}). Please check the file you have uploaded',
          de: 'Die hochgeladene Datei beinhaltet leere Felder in den Spalten ({columns}). Bitte überprüf diese und lade die Datei erneut hoch'
        },
        'control-empty': {
          en: '{label} must not be empty. Please correct to proceed',
          de: '{label} darf nicht leer sein, bitte korrigieren'
        },
        'correct-lat-lng': {
          en: 'Please enter correct latitude and longitude values',
          de: 'Gültige Werte für Längen- und Breitengrad eingeben'
        },
        'media-filter-empty': {
          en: 'Media list must not be empty. Please add at least one media value to proceed',
          de: 'Medienliste darf nicht leer sein. Bitte mindestens einen Typen hinzufügen'
        },
        'points-list-empty': {
          en: 'Points list cannot be empty',
          de: 'Die Positionsliste darf nicht leer sein'
        },
        'tags-empty': {
          en: '{label} must not be empty. Please add at least one {label} to proceed',
          de: '{label} darf nicht leer sein. Bitte mindestens ein/e {label}  auswählen, um fortzufahren'
        },
        'ui-control-default-error': {
          en: '{label} should be valid. Please correct to proceed',
          de: '{label} muss gültig sein. Bitte korrigieren, um forzufahren'
        }
      },
      labels: {
        'choose-area-title': {
          en: 'Choose area',
          de: 'Gebiet auswählen'
        },
        'city-column': {
          en: 'City column',
          de: 'Städte-Spalte'
        },
        'okz-column': {
          en: 'OKZ column',
          de: 'OKZ Spalte'
        },
        'bid-column': {
          en: 'BID column',
          de: 'BID Spalte'
        },
        distance: {
          en: 'Distance (m)',
          de: 'Distanz (m)'
        },
        'file-coordinates': {
          en: 'Upload file with coordinates',
          de: 'Datei mit Koordinaten hochladen'
        },
        'id-column': {
          en: 'ID column',
          de: 'ID-Spalte'
        },
        key: {
          en: 'Key',
          de: 'Schlüssel'
        },
        'import-cities-columns-description': {
          en: 'OKZ column is optional. If you choose it, cities import will be more strict',
          de: 'Die OKZ Spalte ist optional. Wenn du diese mit angibst können wir deine Städte zuverlässiger identifizieren'
        },
        'select-cities-label': {
          en: 'Please select cities | {city} | {city}... and {amount} others',
          de: 'Bitte Städte auswählen | {city} | {city}... and {amount} andere'
        },
        'select-networks-label': {
          en: 'Please select networks | {city} | {city}... and {amount} others',
          de: 'Bitte wähle Netzewerke aus | {city} | {city}... und {amount} andere'
        },
        'networks-selector': {
          en: 'Networks selector',
          de: 'Netzwerk Auswahl'
        },
        'all-networks': {
          en: 'All networks',
          de: 'Alle Netzwerke'
        },
        'selected-networks': {
          en: 'Selected networks',
          de: 'Ausgewählte Netzwerke'
        },
        'sheet-size-selection-disclaimer': {
          en: 'Sheet sizes 4/1 and 6/1 are not always available',
          de: 'Die Bogengrößen 4/1 und 6/1 sind nicht bei allen Allgemeinstellen verfügbar.'
        },
        'match-rows-overlay': {
          en: 'Just a second. We process imported rows',
          de: 'Einen Moment bitte. Wir verarbeiten die importierten Zeilen'
        },
        'load-from-file': {
          en: 'or You can load {label} from file',
          de: 'oder {label} aus Datei hochladen'
        },
        'no-available-booking-time': {
          en: 'Sorry, there are no available booking time frames. Please select another planning time period',
          de: 'Keine Verfügbarkeiten vorhanden. Bitte einen anderen Zeitraum wählen'
        },
        'no-media-filter': {
          en: 'No added media filter',
          de: 'Kein Filter aktiv'
        },
        'no-pairs': {
          en: 'No added pairs',
          de: 'Keine Paare hinzugefügt'
        },
        parameter: {
          en: 'Parameter',
          de: 'Kennziffer'
        },
        'pick-locations': {
          en: 'Pick locations from the map',
          de: 'Standpuntk auf der Karte wählen'
        },
        'position-column': {
          en: 'Position column',
          de: 'Position-Spalte'
        },
        radius: {
          en: 'Radius (m)',
          de: 'Radius (m)'
        },
        search: {
          en: 'Search {type}',
          de: 'Suchen nach {type}'
        },
        'select-another': {
          en: 'Select another',
          de: 'Bitte erneut auswählen'
        },
        'select-areas': {
          en: 'Please select areas',
          de: 'Bitte alle Gebiete auswählen'
        },
        time: {
          en: 'Time (min)',
          de: 'Zeit (min)'
        },
        value: {
          en: 'Value',
          de: 'Wert'
        },
        illumination: {
          'with-illumination': {
            en: 'Only units with illumination',
            de: 'Nur Stellen mit Beleuchtung'
          },
          'without-illumination': {
            en: 'Only units without illumination',
            de: 'Nur Stellen ohne Beleuchtung'
          }
        },
        pps: {
          'mode-range': {
            en: 'Custom Pps Range',
            de: 'Benutzerdefinierter Bereich'
          },
          'mode-relative': {
            en: 'Relation To Region Average',
            de: 'In Relation zum Gebietsdurchschnitt'
          }
        }
      },
      placeholders: {
        'enter-latitude': {
          en: 'Enter Latitude',
          de: 'Breitengrad eingeben'
        },
        'enter-longitude': {
          en: 'Enter Longitude',
          de: 'Längengrad eingeben'
        },
        'select-options': {
          en: 'Select some options',
          de: 'Auswahl treffen'
        }
      }
    },
    map: {
      labels: {
        'planning-sites': {
          en: '{count} Sites',
          de: '{count} Stellen'
        },
        'exclude-bookable-unit': {
          en: 'Exclude bookable unit',
          de: 'Buchbare Einheit ausschließen'
        }
      }
    },
    modals: {
      buttons: {
        'new-planning': {
          en: 'New planning',
          de: 'Neue Planung'
        }
      },
      labels: {
        'capture-position': {
          en: 'Capture position',
          de: 'Position feststellen'
        },
        'custom-planning': {
          en: 'Custom planning',
          de: 'Benutzerdefinierte Planung'
        },
        'enter-planning-name': {
          en: 'Enter planning name',
          de: 'Namen für Planung vergeben'
        },
        'load-planning': {
          en: 'Load planning',
          de: 'Planung laden'
        }
      },
      placeholders: {
        'planning-name': {
          en: 'Planning name',
          de: 'Plan Name'
        }
      }
    },
    'scores-table': {
      buttons: {
        export: {
          en: 'Export',
          de: 'Herunterladen'
        },
        assign: {
          en: 'Assign',
          de: 'Zuordnen'
        },
        'save-view': {
          en: 'Save view',
          de: 'Ansicht speichern'
        },
        'update-view': {
          en: 'Update view',
          de: 'Ansicht aktualisieren'
        },
        update: {
          en: 'Update',
          de: 'Aktualisieren'
        }
      },
      errors: {
        'not-fields-export': {
          en: 'You haven\'t selected any fields to export sites',
          de: 'Keine Spalten ausgewählt'
        },
        'not-selected-sites': {
          en: 'You haven\'t selected  any sites. Please chose some sites to export',
          de: 'Keine Stelle ausgewählt! Bitte mindestens eine auswählen, um zu exportieren'
        }
      },
      placeholders: {
        'view-name': {
          en: 'View name',
          de: 'Namen anzeigen'
        }
      },
      labels: {
        'sum-per-area': {
          en: 'Sum per area',
          de: 'Gesamtübersicht'
        },
        'media-owner-summary': {
          en: 'Media Owner summary',
          de: 'Anbieterübersicht'
        },
        'media-owners': {
          en: 'Media Owners',
          de: 'Netzmedien'
        },
        'default-view': {
          en: 'Default',
          de: 'Standard'
        },
        'select-view': {
          en: 'Select view',
          de: 'Ansicht auswählen'
        },
        'single-units': {
          en: 'Single units',
          de: 'Einzelstellen'
        },
        'filter-by-time-frame': {
          en: 'Filter by time frame:',
          de: 'Filter nach Zeitraum'
        },
        'enter-file-name': {
          en: 'Please enter a file name',
          de: 'Bitte Dateinamen vergeben'
        },
        'export-units': {
          en: 'Export units',
          de: 'Stellen herunterladen'
        },
        'export-all-views': {
          en: 'Export all available views',
          de: 'Alle verfügbaren Ansichten exportieren'
        },
        'export-all-views-hint': {
          en: 'By default only active view will be exported',
          de: 'Standardmäßig wird nur die aktuell aktive Ansicht exportiert'
        },
        high: {
          en: 'High',
          de: 'Hoch'
        },
        low: {
          en: 'Low',
          de: 'Niedrig'
        },
        'quick-assignment-drag-list': {
          en: 'Drag to configure order of path assignment:',
          de: 'Ziehen, um die Reihenfolge der Pfadzuweisung zu konfigurieren:'
        },
        'quick-assignment-selector': {
          en: 'Choose a way to assign units:',
          de: 'Wähle eine Option, um die Stellen zuzuweisen:'
        },
        'enter-view-name': {
          en: 'Please, enter view name',
          de: 'Bitte Namen für die Ansicht eingeben'
        },
        'save-custom-view': {
          en: 'Save custom view',
          de: 'Ansicht speichern'
        },
        'update-custom-view': {
          en: 'Update custom view',
          de: 'Eingestellte Ansicht aktualisieren'
        },
        'save-view-description': {
          en: 'Please take into account that if you make some changes in current campaign, all dynamic data will be deleted to avoid data errors. This will effect custom views as well.',
          de: 'Achtung: Durch Änderungen an der aktuellen Kampagne werden alle dynamischen Daten gelöscht, um Datenfehler zu vermeiden. Diese beinhalten auch benutzerdefinierte Ansichten.'
        },
        'remove-custom-view': {
          en: 'Remove custom view',
          de: 'Ansicht entfernen'
        },
        'remove-custom-view-text': {
          en: 'Are you sure you want to remove "{view}" view?',
          de: 'Soll die Ansicht "{view}" entfernt werden?'
        }
      }
    },
    statistics: {
      buttons: {
        'show-legend': {
          en: 'Show legend',
          de: 'Legende Anzeigen'
        },
        'hide-legend': {
          en: 'Hide legend',
          de: 'Legende verbergen'
        }
      },
      labels: {
        'time-chart': {
          en: 'By Time',
          de: 'Nach Zeit'
        },
        'time-identifier': {
          en: 'Time identifier',
          de: 'Zeitkennzeichnung'
        },
        'areas-chart': {
          en: 'By Areas',
          de: 'Nach Gebiet'
        },
        'area-identifier': {
          en: 'Area identifier',
          de: 'Bereichskennzeichnung'
        },
        'reached-value': {
          en: 'Reached value',
          de: 'Erreichter Wert'
        },
        'target-value': {
          en: 'Target value',
          de: 'Ziel Wert'
        },
        'no-statistic-data': {
          en: 'Sorry. We don\'t have statistic data to display.',
          de: 'Entschuldige, aber wir haben keine statistischen Daten um diese hier anzeigen zu können.'
        }
      }
    },
    sidebar: {
      buttons: {
        'get-results': {
          en: 'Get results',
          de: 'Ergebnisse anzeigen'
        },
        'quick-filters': {
          en: 'Quick Filters',
          de: 'Schnellfilter'
        }
      },
      labels: {
        'current-plan': {
          en: 'Current plan',
          de: 'Aktuelle Planung'
        },
        'media-type': {
          en: 'Media type: {type}',
          de: 'Stellenart'
        },
        'media-type-flights': {
          en: 'Flights for {type}',
          de: 'Flights für {type}'
        },
        'no-scoring-functions': {
          en: 'Your campaign doesn\'t have scoring functions',
          de: 'Kampagne beinhaltet keine Bewertungskriterien oder Filter'
        },
        period: {
          en: 'Period',
          de: 'Zeitraum'
        },
        'plan-not-selected': {
          en: 'Campaign is not selected',
          de: 'Keine Kampagne ausgewählt'
        },
        'scoring-functions': {
          en: 'Scoring functions',
          de: 'Bewertungskriterien'
        },
        'export-plan': {
          en: 'Export plan',
          de: 'Export plan'
        },
        'ops-export': {
          en: 'Export to OPS - Networks',
          de: 'Export nach OPS - Netzwerke'
        },
        'view-type': {
          en: 'View type',
          de: 'Ansichtstyp'
        },
        map: {
          en: 'Map',
          de: 'Karte'
        },
        statistics: {
          en: 'Statistics',
          de: 'Statistik'
        },
        table: {
          en: 'Table',
          de: 'Tabelle'
        },
        'filter-sites': {
          en: 'Filter sites',
          de: 'Filter Standorte'
        },
        'quick-filters': {
          goals: {
            en: 'Goals',
            de: 'Ziele'
          },
          'goals-description': {
            en: 'Disable this to show all sites including those that do not match your Campaign Goals',
            de: 'Deaktivieren, um alle Standorte unabhängig vom Kampagnen-Ziel anzuzeigen'
          },
          availability: {
            en: 'Availability',
            de: 'Verfügbarkeit'
          },
          'availability-description': {
            en: 'Disable this to see all possible sites regardless of their availability within selected dates',
            de: 'Deaktivieren, um alle Standorte unabhängig ihrer Verfügbarkeit am ausgewählten Datum anzuzeigen'
          },
          locations: {
            en: 'Disable areas | {count} area disabled | {count} areas disabled',
            de: 'Gebiete deaktivieren | {count} sind deaktiviert | {count} sind deaktiviert'
          },
          'locations-description': {
            en: 'Disable some areas to exclude matched sites from the scoring result.',
            de: 'Gebiete deaktivieren, um übereinstimmende Medien von den Ergebnissen auszuschließen'
          },
          'locations-help-prompt': {
            en: 'Uncheck areas you want to exclude.',
            de: 'Gebiete zum Ausschließen abwählen'
          },
          'availability-by-time': {
            en: 'Availability by time',
            de: 'Zeitliche Verfügbarkeit'
          },
          'availability-by-time-description': {
            en: 'Edit this to see all available sites in selected time period',
            de: 'Bearbeiten, um  alle im Zeitraum vorhandenen Medien zu sehen'
          }
        },
        'order-in-process': {
          en: 'Order in process',
          de: 'Vorgang wird bearbeitet'
        }
      }
    },
    wizard: {
      buttons: {
        'new-setting': {
          en: 'Add new setting',
          de: 'Neue Einstellung hinzufügen'
        },
        finish: {
          en: 'Finish',
          de: 'Fertig'
        },
        'new-area': {
          en: 'Add area path',
          de: 'Planungsstrang hinzufügen'
        },
        'import-cities': {
          en: 'Import cities',
          de: 'Städte Importieren'
        },
        'import-networks': {
          en: 'Import media owner networks',
          de: 'Pächternetze Importieren'
        }
      },
      confirm: {
        labels: {
          'common-goal-remove-confirm-message': {
            en: 'The goal will be removed entirely from all areas. Do you want to proceed?',
            de: 'Das Strang-Ziel wird vollständig und unwiderruflich aus allen Planungssträngen gelöscht. Fortfahren?'
          },
          'scoring-function-remove-confirm-message': {
            en: 'If you remove this scoring function it will be removed from all assigned areas too. Do you want to proceed?',
            de: 'Alle zugewiesenen Planungsstränge werden mit dem Entfernen dieses Filters unwiderruflich gelöscht. Fortfahren?'
          },
          'goal-remove-confirm-message': {
            en: 'If you remove this goal it will be removed from all assigned areas. Do you want to proceed?',
            de: 'Das Entfernen des Strang-Ziels löscht alle diesem Ziel zugeordneten Planungsstränge. Fortfahren? '
          },
          'location-remove-confirm-message': {
            en: 'If you remove this area, all assigned types, flights, filters, scoring functions and Path Goals will be lost',
            de: 'Alle Stellenarten, Flights, Filter, Bewertungskriterien und Strang-Ziele werden mit dem Planungsstrang unwiderruflich gelöscht'
          },
          'remove-confirm-message': {
            en: 'Do you really want to remove selected item?',
            de: 'Soll der ausgewählte Posten entfernt werden? '
          },
          'remove-confirm-title': {
            en: 'Remove with losing data?',
            de: 'Wirklich löschen?'
          }
        }
      },
      errors: {
        'areas-missing': {
          en: 'Please select at least one area/network to proceed',
          de: 'Bitte mindestens einen Planungsstrang hinzufügen'
        },
        'areas-unique': {
          en: 'Areas/networks names must be unique. Please correct to proceed',
          de: 'Planungsstränge dürfen nicht denselben Namen haben'
        },
        'areas-valid': {
          en: 'All areas/networks must be valid. Please correct the wrong areas/networks to proceed',
          de: 'Alle Stränge müssen gültig sein, um forzufahren'
        },
        'campaign-name-required': {
          en: 'Campaign name must be set',
          de: 'Bitte Kampagnen-Namen vergeben'
        },
        'common-goals-unique': {
          en: 'Common goal names must be unique. Please rename common goals to proceed',
          de: 'Name für Ziele müssen eindeutig sein. Bitte umbenennen, um fortzufahren'
        },
        'common-goals-valid': {
          en: 'All common Path Goals must be valid. Please correct the wrong common goals to proceed',
          de: 'Alle Strang-Ziele müssen gültig sein. Bitte unzutreffende Ziele korrigieren, um fortzufahren'
        },
        'filters-unique': {
          en: 'Filter names must be unique. Please rename filters to proceed',
          de: 'Filternamen müssen eindeutig sein. Bitte Filter umbenennen, um fortzufahren'
        },
        'filters-valid': {
          en: 'All filters must be valid. Please correct the wrong filters to proceed',
          de: 'Alle Filter müssen gültig sein. Bitte ungültige Filter korrigieren, um fortzufahren'
        },
        'rankings-unique': {
          en: 'Ranking functions names must be unique. Please rename ranking functions to proceed.',
          de: 'Alle Rankings müssen eindeutig benannt werden. Bitte den Rankingnamen ändern, um fortzufahren.'
        },
        'rankings-valid': {
          en: 'All ranking functions must be valid. Please correct the wrong ranking functions to proceed.',
          de: 'Alle Rankings müssen gültig sein. Bitte ungültige Rankings korrigieren, um fortzufahren.'
        },
        'flights-not-assigned': {
          en: 'Not all area/type configuration in your selection have media types assigned',
          de: 'Nicht alle Planungsstränge in der Auswahl besitzen zugewiesene Stellenarten'
        },
        'goal-unique': {
          en: 'Path Goal names must be unique. Please rename goals to proceed',
          de: 'Namen von Strang-Zielen müssen eindeutig sein. Bitte Ziele umbenennen, um fortzufahren'
        },
        'goal-valid': {
          en: 'All Path Goals must be valid. Please correct the wrong goals to proceed',
          de: 'Alle Strang-Ziele müssen gültig sein. Bitte fehlerhafte Ziele korrigieren, um fortzufahren'
        },
        'goals-unique': {
          en: 'Path Goal names must be unique. Please rename goals to proceed',
          de: 'Name von Strang-Zielen müssen eindeutig sein. Bitte Zielnamen umbenennen, um fortzufahren'
        },
        'not-unique-name': {
          en: 'Please specify a unique planning name',
          de: 'Bitte einen eindeutigen Planungsnamen aussuchen'
        },
        'period-invalid': {
          en: 'Selected period is invalid',
          de: 'Der ausgewählte Zeitraum ist ungültig'
        },
        'period-no-decades': {
          en: 'Selected period is too short',
          de: 'Ausgewählter Zeitraum ist zu kurz'
        },
        'set-up-campaign': {
          en: 'Please set up the planning name',
          de: 'Bitte Planungsnamen angeben '
        },
        'types-not-assigned': {
          en: 'Not all areas in your selection have media types assigned',
          de: 'Nicht alle Planungsstränge in der Auswahl besitzen zugewiesene Stellenarten'
        },
        'validation-error-default': {
          en: 'Campaign options are invalid',
          de: 'Kampagnen-Einstellungen sind ungültig'
        },
        'editor-is-open-title': {
          en: 'Form not saved!',
          de: 'Formular nicht gespeichert'
        },
        'editor-is-open-message': {
          en: 'Please submit or cancel current form to proceed.',
          de: 'Bitte, speichern oder abbrechen um fortzufahren'
        },
        'loading-mon-information': {
          en: 'An error occurred while loading the Media Owner Networks information. Please, try again later',
          de: 'Bei dem Herunterladen der Netzinformationen ist ein Fehler aufgetreten. Bitte versuche es zu einem späteren Zeitpunkt erneut'
        }
      },
      labels: {
        'planning-period': {
          en: 'Planning period',
          de: 'Planungszeitraum'
        },
        'global-settings': {
          en: 'Global settings',
          de: 'Globale Einstellungen'
        },
        'consider-availability-status': {
          en: 'Consider availability status unknown',
          de: 'Status bezüglich Verfügbarkeit unbekannt'
        },
        'add-filter': {
          en: 'Add filter',
          de: 'Filter hinzufügen'
        },
        'add-ranking': {
          en: 'Add ranking function',
          de: 'Ranking hinzufügen'
        },
        'add-filters-hint': {
          en: 'Area has no custom filters applied',
          de: 'Keine benutzerdefinierten Filter für das Gebiet angewendet'
        },
        'add-rankings-hint': {
          en: 'Area has no custom ranking functions applied',
          de: 'Keine benutzerdefinierten Rankings für das Gebiet angewendet'
        },
        'add-goal': {
          en: 'Add goal',
          de: 'Ziel hinzufügen'
        },
        'add-goals-hint': {
          en: 'Area has no applied goals',
          de: 'Gebiet hat keine angewählten Ziele '
        },
        'assign-filters-to-location-description': {
          en: 'Select scoring filters that you want to assign to the selected area',
          de: 'Scoring-Filter auswählen, der zum angegebenen Gebiet zugeordnet werden soll'
        },
        'assign-rankings-to-location-description': {
          en: 'Select ranking functions that you want to assign to the selected area',
          de: 'Ranking auswählen, das zum angegebenen Gebiet zugeordnet werden soll'
        },
        'assign-filters-to-location-title': {
          en: 'Assign filters: {location}',
          de: 'Filter hinzufügen: {location}'
        },
        'assign-rankings-to-location-title': {
          en: 'Assign ranking functions: {location}',
          de: 'Ranking hinzufügen: {location}'
        },
        'assign-goals-to-location-description': {
          en: 'Select Path Goals that you want to assign to the selected area.',
          de: 'Strang-Ziele auswählen, die zum angegebenen Gebiet zugeordnet werden sollen.'
        },
        'assign-goals-to-location-title': {
          en: 'Assign goals: {location}',
          de: 'Ziele hinzufügen: {location}'
        },
        'assign-locations-to-filter-description': {
          en: 'Select areas to assign the selected scoring filter to them.',
          de: 'Bereich auswählen, um diesem die zugehörigen Scoring-Filter zuzuordnen.'
        },
        'assign-locations-to-ranking-description': {
          en: 'Select areas to assign the selected ranking function to them.',
          de: 'Bereich auswählen, um diesem das zugehörige Ranking zuzuordnen.'
        },
        'assign-locations-to-function-title': {
          en: 'Assign areas: {function}',
          de: 'Planungsstränge hinzufügen: {function}'
        },
        'assign-locations-to-goal-description': {
          en: 'Select areas to assign the selected goal to them',
          de: 'Gebiete auswählen, um diesen die zugehörigen Ziele zuzuordnen'
        },
        'available-filters': {
          en: 'Available filters',
          de: 'Verfügbare Filter'
        },
        'available-rankings': {
          en: 'Available ranking functions',
          de: 'Verfügbare Rankings'
        },
        'available-goals': {
          en: 'Available Path Goals',
          de: 'Verfügbare Strang-Ziele'
        },
        'campaign-name': {
          en: 'Campaign name',
          de: 'Kampagnen-Name'
        },
        discount: {
          en: 'Discount',
          de: 'Rabatt'
        },
        'time-frame-fit-to-planning-time-range': {
          en: 'Time frame of unit should fit to the boundaries of planning time range',
          de: 'Der Zeitraum der Einzelstelle sollte zu dem Rahmen des Planungszeitraum passen.'
        },
        'can-configure-common-settings': {
          en: 'You can configure common settings at this step',
          de: 'In diesem Schritt können allgemeine Einstellungen zur Kampagne vorgenommen werden.'
        },
        'common-settings-select-prompt': {
          en: 'To proceed please select any settings from the left or {new-setting} | To proceed please select any settings from the left',
          de: 'Bitte eine Einstellungn links oder {new-setting} auswählen, um fortzufahren | Bitte eine Einstellung links  auswählen, um fortzufahren'
        },
        'can-configure-site-types': {
          en: 'You can configure media types here',
          de: 'Konfiguration der Stellenart möglich'
        },
        'can-configure-flights': {
          en: 'You can configure desired flights here',
          de: 'Konfiguration der erwünschten Flights möglich'
        },
        'can-configure-locations': {
          en: 'You can configure custom area filters at this step',
          de: 'Benutzerdefinierten Planungsstrang konfigurieren'
        },
        'common-goals-select-prompt': {
          en: 'To proceed please select any goal from the left or {add-goal}',
          de: 'Bitte ein beliebiges Ziel von links auswählen oder {add-goal} '
        },
        'media-types-select-prompt': {
          en: 'Select any area on the left to manage it\'s media types',
          de: 'Gebiet auf der linken Seite auswählen, um zugehörige Stellenarten zu verwalten'
        },
        'flights-select-prompt': {
          en: 'Select any area on the left to manage it\'s flights',
          de: 'Gebiet auf der linken Seite auswählen, um zugehörige Flights zu verwalten'
        },
        'goal-set-prompt': {
          en: 'You can configure combined goals here.\n Select any area on the left to manage it\'s combined goal',
          de: 'Konfiguration von kombinierten Zielen möglich. Gebiete links auswählen, um die zugehörigen kombinierten Ziele zu verwalten'
        },
        'end-date': {
          en: 'Period end',
          de: 'Enddatum'
        },
        'filter-modal-title': {
          en: '{funcName}',
          de: '{funcName}'
        },
        'scoring-function-name': {
          en: 'Name',
          de: 'Name'
        },
        'scoring-function-settings': {
          en: 'Configuration',
          de: 'Konfiguration'
        },
        'filters-add-prompt': {
          en: 'You can {add-filter} and assign it to any area',
          de: 'Es kann {add-filter} zum Gebiet zugeordnet werden'
        },
        'flights-synchronized-path-checkbox': {
          en: 'Synchronized for path',
          de: 'Synchronisiert für Planungsstrang'
        },
        'flights-synchronized-type-checkbox': {
          en: 'Synchronise on Main site type level',
          de: 'Auf Hauptstellenart synchronisieren'
        },
        'flights-synchronized-filter-name': {
          en: 'Synchronized flights',
          de: 'Synchronisierte Flights'
        },
        'load-more': {
          en: 'load more',
          de: 'mehr laden'
        },
        'locations-select-prompt': {
          en: 'To proceed please select any area from the left or {add-area}',
          de: 'Bitte Planungsstrang auswählen oder {add-area}'
        },
        'no-filters-message': {
          en: 'No filters are currently configured',
          de: 'Momentan sind keine Filter eingestellt'
        },
        'no-rankings-message': {
          en: 'No ranking functions are currently configured.',
          de: 'Momentan sind keine Rankings eingestellt.'
        },
        'no-goals-message': {
          en: 'No Path Goals are currently configured',
          de: 'Momentan sind keine Strang-Ziele eingestellt'
        },
        'no-selected-common-goals': {
          en: 'No Path Goals selected',
          de: 'Keine Strang-Ziele ausgewählt'
        },
        'no-selected-locations': {
          en: 'No areas selected',
          de: 'Kein Planungsstrang ausgewählt'
        },
        'select-media-types': {
          en: 'Select media types per area',
          de: 'Stellenarten je Planungsstrang auswählen'
        },
        'select-flights': {
          en: 'Select flights per location/type',
          de: 'Flight je Strang / Stellenart auswählen '
        },
        'selected-area': {
          en: 'Selected area: {area}',
          de: 'Ausgewählter Planungsstrang: {area}'
        },
        'selected-common-goals': {
          en: 'Selected Path Goals',
          de: 'Ausgewählte Strang-Ziele'
        },
        'selected-locations': {
          en: 'Selected areas',
          de: 'Ausgewählte Planungsstränge'
        },
        'selected-settings': {
          en: 'Selected settings',
          de: 'Ausgwählten Einstellungen'
        },
        'no-selected-settings': {
          en: 'No settings selected',
          de: 'Keine ausgewählten Einstellungen'
        },
        'set-goal': {
          en: 'Set combined goal per location',
          de: 'Kombiniertes Ziel je Standort setzen'
        },
        'start-date': {
          en: 'Period start',
          de: 'Startdatum'
        },
        'step-areas': {
          en: 'Area',
          de: 'Planungsstrang'
        },
        'step-basics': {
          en: 'Campaign basics',
          de: 'Grundlagen Kampagne'
        },
        'step-common-settings': {
          en: 'Campaign settings',
          de: 'Einstellungen Kampagne'
        },
        'step-filters': {
          en: 'Filters',
          de: 'Filter'
        },
        'step-rankings': {
          en: 'Ranking functions',
          de: 'Rankings'
        },
        'step-flights': {
          en: 'Flights',
          de: 'Flights'
        },
        'step-goals': {
          en: 'Path Goals',
          de: 'Strang-Ziele'
        },
        'step-media-types': {
          en: 'Media types',
          de: 'Stellenarten'
        },
        'new-wizard-title': {
          en: 'Configure your new planning',
          de: 'Neue Planung konfigurieren'
        },
        'wizard-title': {
          en: 'Configure planning: {campaign-name}',
          de: 'Einstellung von Planung: {campaign-name}'
        },
        'step-not-implemented': {
          en: 'Step {step} is not supported or isn\'t implemented yet',
          de: 'Schritt  {step} wurde noch nicht implementiert'
        },
        'tab-media-types': {
          en: 'Single units',
          de: 'Einzeleinheit'
        },
        'tab-media-networks': {
          en: 'Media owner networks',
          de: 'Pächternetze'
        },
        'calendar-mode': {
          en: 'Display mode',
          de: 'Displaymodus'
        },
        'common-goals-prompt': {
          en: 'Here you can add common goal that applies to all areas.',
          de: 'Hier ein Ziel hinzufügen, dass auf alle Gebiete angewendet wird'
        },
        'save-draft': {
          en: 'Save draft',
          de: 'Entwurf speichern'
        },
        'filter-name-copy': {
          en: '{name} - Copy',
          de: '{name} - Kopieren'
        },
        'scoring-function-description-prompt': {
          en: 'Click to see scoring function description',
          de: 'Klicken zur Anzeige der Scoring-Funktion-Beschreibung'
        },
        'scoring-function-description': {
          en: 'Scoring function description',
          de: 'Scoring-Funktion-Beschreibung'
        },
        'budget-goals-group': {
          en: 'Budget goals:',
          de: 'Budget Ziele'
        },
        'quantity-goals-group': {
          en: 'GRP and Quota goals:',
          de: 'GRP und Quoten Ziele'
        },
        'import-cities-columns-select': {
          en: 'Select columns which contain city name and OKZ',
          de: 'Bitte wähle die Spalten aus die den Stadtnamen und die OKZ beinhalten'
        },
        'import-cities-search-prompt': {
          en: 'Find and import cities from predefined datasets',
          de: 'Suche und importiere Städte aus unseren vordefinierten Datensätzen'
        },
        'import-cities-upload-prompt': {
          en: 'Load file to import cities',
          de: 'Datei hochladen um Städte zu importieren'
        },
        'import-networks-columns-select': {
          en: 'Select column which contain BID value',
          de: 'Wähle die Spalte aus, welche den Wert der BID enthält'
        },
        'import-networks-upload-prompt': {
          en: 'Load file to import media owner networks',
          de: 'Datei hochladen um Pächternetze zu importieren'
        },
        'download-mon-file': {
          en: 'Download Media Owner Networks information',
          de: 'Netzinformationen herunterladen'
        },
        'missing-networks-title': {
          en: 'Missing Media Owner Networks',
          de: 'Fehlende Netze'
        },
        'missing-networks-description': {
          en: 'There are unavailable/missing network(s) for the following areas:',
          de: 'Für die folgenden Gebiete gibt es keine Netze, oder es sind keine Netze verfügbar:'
        }
      }
    },
    'vicinity-import': {
      errors: {
        'points-processing-error': {
          en: 'Something went wrong while processing points array',
          de: 'Fehlschlagen beim Verarbeiten des Layers'
        },
        'empty-dataset': {
          en: 'Query you\'ve set up results into empty table. Please weaken the conditions',
          de: 'Tabelle für geforderte Abfrage ist leer. Bitte Bedingungen anpassen'
        },
        'select-name-column': {
          en: 'Please select name column',
          de: 'Bitte wählen Sie die Namensspalte aus'
        }
      },
      labels: {
        'search-dataset': {
          en: 'Set up initial dataset',
          de: 'Ursprungsdaten einrichten'
        },
        'query-dataset': {
          en: 'Query the data from dataset',
          de: 'Daten aus dem Datensatz abfragen'
        },
        'parse-dataset': {
          en: 'Choose coordinate columns or geocode addresses',
          de: 'Koordinaten-Spalte auswählen oder Adressen geokodieren'
        },
        'name-column': {
          en: 'Name column',
          de: 'Namensspalte'
        }
      }
    },
    'bookable-units-import': {
      labels: {
        'add-units': {
          en: 'Add units',
          de: 'Einheiten hinzufügen'
        },
        'add-units-label': {
          en: 'Add bookable units manually',
          de: 'Buchbare Einheit manuell hinzufügen'
        },
        'add-row': {
          en: 'Add bookable unit',
          de: 'Buchbare Einheit hinzufügen'
        },
        'add-from-file': {
          en: 'Upload bookable units from file',
          de: 'Buchbare Einheiten aus Datei hochladen'
        }
      },
      errors: {
        'no-valid-rows': {
          en: 'Cannot get any valid row. Please, fill out all mandatory fields',
          de: 'Keine verfügbare Zeile. Bitte alle notwendigen Felder ausfüllen'
        },
        'server-result-empty': {
          en: 'Cannot save bookable units on the server right now. Please, try again later',
          de: 'Buchbare Einheiten können nicht auf dem Serve gespeichert werden. Bitte später nochmal versuchen'
        }
      },
      disclaimer: {
        title: {
          en: 'Adding bookable units manually',
          de: 'Buchbare Einheiten manuell hinzufügen'
        },
        content: {
          en: `Use the grid to enter custom bookable units via {example-button} button or by importing them via excel file.
                Mandatory fields are marked with {example-strong} font.
                {example-valid} rows are the ones with all mandatory fields fulfilled.
                {example-invalid} rows will not be saved in planning.`,
          de: `Gitter benutzen um buchbare Einheiten über {example-button} zu erstellen oder eine Excel-Datei importieren.
                notwendigen Felder sind mit der Schriftart {example-strong} markiert.
                {example-valid} Zeilen sind alle notwendigen Felder.
                {example-invalid} Zeilen werden nicht in der Planung gespeichert`
        },
        examples: {
          strong: {
            en: 'Bold',
            de: 'Fett'
          },
          valid: {
            en: 'Valid',
            de: 'Gültig'
          },
          invalid: {
            en: 'Invalid',
            de: 'Ungültig'
          }
        }
      }
    },
    'bookable-units-resolve': {
      labels: {
        'modal-title': {
          en: 'Assign unclear bookable units to groupings',
          de: 'Unklare Stellen einer Gruppierung zuweisen.'
        },
        'confirm-modal-title': {
          en: 'Confirm skipping assign',
          de: 'Zuweisung überspringen'
        },
        'confirm-modal-label': {
          en: 'You didn\'t assign {amount} bookable units. Are you sure that you want to continue?',
          de: 'Sie haben {amount} Stellen nicht explizit zugewiesen. Möchten sie dennoch fortfahren?'
        },
        'modal-hint': {
          en: 'To prevent troubles within communication or to Plato import you should assign a bookable unit to a specific area/cluster',
          de: 'Um Konflikte im Plato Import zu verhindern sollten alle Stellen einem bestimmten Gebiet/Cluster zugeordnet werden.'
        },
        'not-assigned': {
          en: 'Not assigned',
          de: 'Nicht zugewiesen'
        }
      },
      warnings: {
        'plan-contains-resolved-units': {
          en: 'This change will have an effect on your planning and score results.',
          de: 'Diese Änderung wirkt sich auf die Planung und das Ergebnis aus.'
        },
        'resolved-units-clearance': {
          en: 'By Updating the plan your previous area assignments will be lost. When you proceed you will have to assign your POI again in case of area conflicts.',
          de: 'Beim Update der Planung gehen alle bisherigen Gebietszuordnungen verloren. Wenn Sie fortfahren müssen in Zweifelsfall alle POI zu Gebiet Zuordnungen neu erstellt werden.'
        }
      }
    },
    errors: {
      'campaign-load-failed': {
        en: 'We weren\'t able to load your campaign right now. Please try again later.',
        de: 'Laden der Kampagne nicht möglich. Bitte später erneut versuchen'
      },
      'scoring-failed': {
        en: 'We can\'t score your campaign right now. Please try again later.',
        de: 'Scoring der Kampagne nicht möglich. Bitte später erneut versuchen'
      },
      'scoring-layers-failed': {
        en: 'We can\'t visualize your campaign options right now. Please try again later.',
        de: 'Visualisierung der Kampagnen-Optionen nicht möglich. Bitte später erneut versuchen'
      },
      'plan-has-linked-order': {
        en: 'This plan can not be deleted because it has a linked offer.',
        de: 'Löschen nicht möglich, da ein Angebot noch verknüpft ist'
      }
    }
  },
  visualization: {
    confirm: {
      labels: {
        'unsaved-confirm-message': {
          en: 'Current visualization has unsaved changes. If you proceed, the changes you have made will be lost',
          de: 'Aktuelle Visualisierung hat ungespeicherte Änderungen. Beim Fortfahren gehen alle vorherigen Änderung verloren'
        }
      }
    },
    'layer-actions-modal': {
      labels: {
        'edit-dataset': {
          en: 'Edit dataset',
          de: 'Datei bearbeiten'
        },
        'edit-dataset-description': {
          en: 'Edit linked dataset to manage displayed geometries',
          de: 'Verknüpfte Datei bearbeiten, um aufgeführte Geometrien zu verwalten'
        },
        'link-dataset': {
          en: 'Link dataset',
          de: 'Datei verknüpfen'
        },
        'exchange-dataset': {
          en: 'Exchange dataset',
          de: 'Datei austauschen'
        },
        'link-dataset-description': {
          en: 'Link & exchange a new dataset to combine it with current layer geometry',
          de: 'Neue Datei verknüpfen und austauschen, um mit dem aktuellen Geometrielayer zusammenzuführen'
        },
        'edit-areas': {
          en: 'Edit areas',
          de: 'Gebiete bearbeiten'
        },
        'edit-areas-description': {
          en: 'Add or remove areas where geometries are displayed',
          de: 'Hinzufügen oder Entfernen von Bereichen, die aufgezeigt werden'
        },
        'edit-query': {
          en: 'Edit query',
          de: 'Abfrage bearbeiten'
        },
        'edit-query-description': {
          en: 'Adjust queries to manage an amount of displayed data',
          de: 'Abfrage anpassen, um anzuzeigende Daten zu verwalten'
        },
        'edit-query-notification-title': {
          en: 'Conflict with layer fill mode settings',
          de: 'Überschneidung mit Layer-Fülleigenschaften'
        },
        'edit-query-notification': {
          en: 'Current query settings can conflict with the fill mode set for this layer. If you proceed, fill mode settings will be reset to initial values.',
          de: 'Aktuelle Abfrage überschneidet sich mit Fülleigenschaften des Layer. Fortfahren wird die Einstellungen zurücksetzen'
        }
      }
    },
    'edit-layer-modal': {
      buttons: {
        'add-row': {
          en: 'Add corresponding row to the table',
          de: 'Zugehörige Zeile zur Tabelle hinzufügen'
        },
        'remove-row': {
          en: 'Remove corresponding row from the table',
          de: 'Zugehörige Zeile aus der Tabelle entfernen '
        },
        'display-dataset-rows': {
          en: 'Display linked dataset rows',
          de: 'Verknüpfte Tabelleneinträge anzeigen'
        },
        'export-dataset': {
          en: 'Export dataset',
          de: 'Datei exportieren '
        },
        'save-dataset': {
          en: 'Save dataset',
          de: 'Datei speichern '
        },
        'edit-columns': {
          en: 'Manage columns',
          de: 'Spalten verwalten '
        },
        'show-preview-map': {
          en: 'Display Map',
          de: 'Karte darstellen '
        },
        'hide-preview-map': {
          en: 'Hide Map',
          de: 'Karte verbergen '
        },
        'show-details': {
          en: 'Show polygon details',
          de: 'Polygondetails anzeigen'
        },
        'refresh-map': {
          en: 'Refresh map to reflect changes from dataset',
          de: 'Karte aktualisieren, um hervorgenommene Änderungen wiederzugeben'
        }
      },
      labels: {
        'dataset-was-updated': {
          en: 'Dataset has updates. To update the preview, press the "refresh" button in the top right corner of the map',
          de: 'Dateiupdates verfügbar. "Aktualisiern" in der oberen rechten Ecke der Karte drücken, um Aktualisierung vorzunehmen'
        }
      },
      placeholders: {
        'filter-dataset': {
          en: 'Filter dataset...',
          de: 'Datensatz filtern...'
        }
      }
    },
    'layer-settings-modal': {
      labels: {
        border: {
          en: 'Border',
          de: 'Rand'
        },
        'cluster-union': {
          en: 'Remove cluster inner borders',
          de: 'Innere Grenzen entfernen'
        },
        clustering: {
          en: 'Clustering',
          de: 'Gruppieren'
        },
        color: {
          en: 'Color',
          de: 'Farbe'
        },
        'custom-images': {
          en: 'Custom images',
          de: 'Benutzerdefiniertes Bild'
        },
        'custom-images-column': {
          en: 'Please select data column before uploading custom images',
          de: 'Bitte Daten-Spalte auswählen, bevor eigene Bilder hochgeladen werden'
        },
        'custom-images-for': {
          en: 'Custom images for {column}',
          de: 'Eigene Bilder für {column}'
        },
        dashed: {
          en: 'Dashed',
          de: 'gestrichelt'
        },
        'data-column': {
          en: 'Data column',
          de: 'Daten-Spalte'
        },
        decile: {
          en: 'Decile',
          de: 'Dezile'
        },
        'default-image': {
          en: 'Default image',
          de: 'Standardbild'
        },
        'display-all-meta-data': {
          en: 'Display all available metadata',
          de: 'Alle verfügbaren Metadaten anzeigen'
        },
        dotted: {
          en: 'Dotted',
          de: 'gepunktet'
        },
        'enable-legend': {
          en: 'Enable legend',
          de: 'Legende aktivieren'
        },
        'end-color': {
          en: 'End color',
          de: 'Endfarbe'
        },
        fill: {
          en: 'Fill',
          de: 'Füllung'
        },
        'fill-range-similar': {
          en: 'You have similar ranges. Only first range will be applied',
          de: 'Gleiche Bereichsweiten vorhanden. Nur der erste Bereich wird angewendet'
        },
        'font-color': {
          en: 'Font color',
          de: 'Schriftfarbe'
        },
        geometries: {
          en: 'Geometries',
          de: 'Geometrien'
        },
        gradient: {
          en: 'Gradient',
          de: 'Farbverlauf'
        },
        heatmap: {
          en: 'Heatmap',
          de: 'Heatmap'
        },
        icon: {
          en: 'Icon',
          de: 'Symbol'
        },
        label: {
          en: 'Label',
          de: 'Beschriftung'
        },
        'label-content': {
          en: 'Label content',
          de: 'Inhalt beschriften'
        },
        'icon-show-always': {
          en: 'Always show icon',
          de: 'Symbol immer anzeigen '
        },
        'default-icon': {
          en: 'Default icon',
          de: 'Symbol-Standardeinstellung'
        },
        'custom-icon': {
          en: 'Custom icons',
          de: 'Symbole erstellen '
        },
        'label-show-always': {
          en: 'Always show label',
          de: 'Beschriftung immer anzeigen'
        },
        'label-style': {
          en: 'Label style',
          de: 'Beschriftungsstil'
        },
        'label-visibility': {
          en: 'Label visibility',
          de: 'Sichtbarkeit Beschriftung '
        },
        'label-text-size': {
          en: 'Label text size',
          de: 'Textgröße Label'
        },
        'layer-settings': {
          en: 'Style settings',
          de: 'Style Optionen'
        },
        legend: {
          en: 'Legend',
          de: 'Legende'
        },
        'legend-settings': {
          en: 'Legend settings',
          de: 'Einstellungen Legende'
        },
        line: {
          en: 'Line',
          de: 'Linie'
        },
        'line-style': {
          en: 'Line style',
          de: 'Linienstil'
        },
        'line-width': {
          en: 'Line width',
          de: 'Linien-Breite'
        },
        marker: {
          en: 'Marker',
          de: 'Marker'
        },
        median: {
          en: 'Median',
          de: 'Median'
        },
        'more-unique-values': {
          en: 'You have more than {max} unique values',
          de: 'Mehr als {max} einmalige Werte'
        },
        'not-enough-unique-values': {
          en: 'Column {column} doesn\'t have enough unique values to support {mode} fill mode.',
          de: 'Spalte {column} hat unzureichend Werte, um {mode} zu unterstützen'
        },
        'no-columns-select': {
          en: 'No columns were selected',
          de: 'Keine Spalten ausgewählt!'
        },
        none: {
          en: 'None',
          de: 'Keins'
        },
        stripes: {
          en: 'Stripes',
          de: 'Gestreift'
        },
        opacity: {
          en: 'Opacity',
          de: 'Deckkraft'
        },
        overlays: {
          en: 'Overlays',
          de: 'Overlays'
        },
        popup: {
          en: 'Popup',
          de: 'Popup'
        },
        'popup-content': {
          en: 'Popup content',
          de: 'Popup Inhalt'
        },
        quartile: {
          en: 'Quartile',
          de: 'Quartile'
        },
        'range-column': {
          en: 'Range column',
          de: 'Bereich-Spalte'
        },
        'range-display': {
          en: 'Range to display',
          de: 'Anzuzeigender Bereich'
        },
        'select-columns': {
          en: 'Select columns from a layer',
          de: 'Layer-Spalten auswählen'
        },
        'select-columns-description': {
          en: 'Select at least one column to construct a {entityType} description',
          de: 'Mindestens eine Spalte wählen, um eine {entityType} Beschreibung hinzuzufügen'
        },
        'select-columns-from': {
          en: 'Please select from 1 to {maxColumns} columns to construct a {entityType} label',
          de: 'Bitte zwischen 1 und {maxColumns} Spalten auswählen, um eine {entityType} Beschriftung zu erzeugen'
        },
        'select-columns-labels': {
          en: 'Select at least one column to construct a {entityType} label',
          de: 'Bitte mindestens eine Spalte für {entityType} Beschriftung auswählen '
        },
        'select-construct-columns': {
          en: 'Please select at least one column to construct a {entityType} description',
          de: 'Bitte mindestens eine Spalte wählen, um eine  {entityType} Beschreibung hinzuzufügen'
        },
        'show-decimals': {
          en: 'Show decimals',
          de: 'Dezimalstellen anzeigen'
        },
        'no-decimals-limit': {
          en: 'no limit',
          de: 'Keine Begrenzung '
        },
        'single-color': {
          en: 'Single color',
          de: 'Einfarbig'
        },
        solid: {
          en: 'Solid',
          de: 'voll'
        },
        'start-color': {
          en: 'Start color',
          de: 'Startfarbe'
        },
        'stroke-color': {
          en: 'Stroke color',
          de: 'Rahmenfarbe'
        },
        'stroke-width': {
          en: 'Stroke width',
          de: 'Rahmenbreite'
        },
        'upload-image': {
          en: 'Upload image',
          de: 'Bild hochladen'
        },
        'upload-image-file': {
          en: 'Please upload image file',
          de: 'Bitte Bilddatei hochladen'
        },
        'upload-table': {
          en: 'Upload table',
          de: 'Tabelle hochladen'
        },
        'upload-table-file': {
          en: 'Please upload table file',
          de: 'Bitte Tabelle hochladen'
        },
        'drag-legend-labels-to-rearrange': {
          en: 'Drag color squares below to rearrange them',
          de: 'Farbkreise ziehen, um sie neu anzuordnen'
        },
        'colors-preview': {
          en: 'Colors preview',
          de: 'Vorschau Farben '
        },
        'colors-preview-help': {
          en: 'Click on custom color to reset it',
          de: 'Auf Farbe klicken, um sie zurückzusetzen'
        },
        'reset-legend-value': {
          en: 'Reset to initial value',
          de: 'Auf den Anfangswert zurücksetzen'
        }
      }
    },
    'link-dataset-modal': {
      buttons: {
        'update-layer': {
          en: 'Update layer',
          de: 'Layer aktualisiern '
        }
      },
      labels: {
        'search-existing-dataset': {
          en: 'Search and select existing dataset to link it with the current geometryset',
          de: 'Existierende Datei suchen und auswählen, um sie mit dem aktuellen Geometrieset zu verknüpfen'
        },
        'upload-dataset-file': {
          en: 'Upload dataset file below to link it with the current geometryset',
          de: 'Unten aufgeführte Datei hochladen, um sie mit dem aktuellen Geometrieset zu verknüpfen'
        },
        'linked-confirm-message': {
          en: 'Current layer has linked dataset. Are you sure that you want to link a new one?',
          de: 'Aktueller Layer ist bereits mit einer Datei verknüpft. Soll wirklich eine weitere verknüpft werden? '
        },
        'linked-confirm-title': {
          en: 'Link another dataset?',
          de: 'Eine weitere Datei verknüpfen? '
        }
      }
    },
    map: {
      labels: {
        'no-metadata': {
          en: 'No metadata to display',
          de: 'Keine Daten zum Anzeigen vorhanden'
        },
        'metadata-not-selected': {
          en: 'No metadata selected',
          de: 'Keine Metadaten ausgewählt'
        },
        'show-all-values': {
          en: 'Show all values',
          de: 'Alle Werte anzeigen '
        }
      },
      controls: {
        locations: {
          labels: {
            locations: {
              en: 'Show saved locations',
              de: 'Gespeicherte Orte anzeigen'
            },
            'save-slide-location': {
              en: 'Save slide location',
              de: 'Folienposition speichern'
            }
          }
        },
        mapProviders: {
          tiles: {
            header: {
              en: 'Tiles source',
              de: 'Kartenkachel Quelle'
            },
            options: {
              default: {
                en: 'Geofabrik (default)',
                de: 'Geofabrik (Standard)'
              },
              topo: {
                en: 'Topographic background',
                de: 'Topographischer Hintergrund '
              },
              greyscale: {
                en: 'Greyscale background',
                de: 'Graustufen Hintergrund'
              },
              'no-background': {
                en: 'No background',
                de: 'Kein Hintergrund'
              }
            }
          },
          overlays: {
            header: {
              en: 'Overlays',
              de: 'Overlays'
            },
            options: {
              roads: {
                en: 'Roads & Highways',
                de: 'Autobahnen und Strassen'
              },
              railways: {
                en: 'Railways',
                de: 'Bahnstrecken'
              },
              labels: {
                en: 'Labels',
                de: 'Labels'
              },
              'no-background': {
                en: 'No background',
                de: 'Kein Hintergrund'
              }
            }
          }
        }
      }
    },
    modals: {
      buttons: {
        'planning-visualization': {
          en: 'Planning visualization',
          de: 'Planungsvisualisierung'
        }
      },
      errors: {
        'functions-not-selected': {
          en: 'Please select at least one planning function',
          de: 'Bitte mindestens eine Planungsfunktion auswählen '
        },
        'visualization-processing-error': {
          en: 'An error occurred while processing new visualization. Please try again later.',
          de: 'Fehler währen der Visualisierung aufgetreten. Bitte später erneut versuchen'
        },
        'not-unique-name': {
          en: 'Please specify the unique visualization name',
          de: 'Bitte den Visualisierungsnamen spezifizieren  '
        },
        'set-up-visualization': {
          en: 'Visualization name cannot be empty',
          de: 'Visualisierungsname darf nicht leer sein '
        },
        'column-has-empty-values': {
          en: 'Selected column has empty values. If you proceed, some polygons may not be displayed as expected',
          de: 'Ausgewählte Spalten haben leere Bereiche. Manche Polygone werden nicht wie erwünscht dargestellt, wenn fortgefahren wird'
        },
        'plannings-not-selected': {
          en: 'Campaign is not selected',
          de: 'Keine Kampagne ausgewählt'
        }
      },
      labels: {
        'choose-saved-plannings': {
          en: 'Select a planning to create a visualization from it',
          de: 'Bitte eine Planung auswählen, um diese zu visualisieren'
        },
        'custom-visualization': {
          en: 'Custom visualization',
          de: 'Benutzerdefinierte Visualisierung'
        },
        'custom-visualization-description': {
          en: 'Create an empty visualization',
          de: 'Leere Visualisierung erstellen'
        },
        'planning-visualization': {
          en: 'Visualization of plannings',
          de: 'Visualisierung von Plannungen'
        },
        'planning-visualization-description': {
          en: 'Create a visualization from a saved planning',
          de: 'Visualisierung aus gespeicherter Planung erzeugen'
        },
        'please-wait-visualization-processing': {
          en: 'Please wait while we are preparing a new visualization for you.',
          de: 'Bitte warten, solange die Visualisierung vorbereitet wird'
        },
        'processing-visualization': {
          en: 'Processing visualization',
          de: 'Visualisierung verarbeiten'
        },
        'enter-visualization-name': {
          en: 'Enter visualization name',
          de: 'Namen für Visualisierung eingeben'
        },
        'load-visualization': {
          en: 'Load visualization',
          de: 'Visualisierung laden'
        },
        'new-visualization': {
          en: 'New visualization',
          de: 'Neue Visualisierung'
        },
        'no-saved-visualizations': {
          en: "You don't have saved visualizations",
          de: 'Keine gespeicherten Visualisierungen vorhanden'
        },
        'no-visualizations-found': {
          en: 'No visualizations found',
          de: 'Es konnten keine Visualisierungen gefunden werden'
        },
        'planning-functions': {
          en: 'Planning functions',
          de: 'Planungsfunktionen'
        },
        'rename-layer': {
          en: 'Rename layer {name}',
          de: 'Layer umbenennen {name}'
        },
        'plans-select-help': {
          en: 'The selected plan will be displayed here.',
          de: 'Der ausgewählte Plan wird hier angezeigt.'
        },
        'create-new-visualization': {
          en: 'Create a new visualization',
          de: 'Neue Visualisierung erstellen'
        },
        'plan-selection': {
          en: 'Plan selection',
          de: 'Auswahl Plan'
        },
        'visualization-properties': {
          en: 'Visualization properties',
          de: 'Eigenschaften Visualisierung'
        }
      },
      placeholders: {
        'visualization-name': {
          en: 'Visualization name',
          de: 'Name Visualisierung'
        }
      }
    },
    'share-visualization': {
      labels: {
        'intro-message': {
          en: 'Use this link to share the visualization with your team members. They will not be able to either edit or delete this visualization.',
          de: 'Verwende den Link, um die Visualisierung mit deinen Team Mitgliedern zu teilen. Ändern, oder löschen der Visualisierung ist über diesen Link nicht möglich.'
        },
        'copy-link': {
          en: 'Copy link to clipboard',
          de: 'Link in die Ablage kopieren'
        },
        'link-validity': {
          de: 'Dieser Link ist 30 Tage lang gültig.',
          en: 'This link is valid for 30 days.'
        }
      },
      tooltips: {
        'copy-success': {
          en: 'Link was copied successfully',
          de: 'Kopieren des Links erfolgreich'
        },
        'copy-error': {
          en: 'Error occurred when tried to copy the link',
          de: 'Kopieren des Links fehlgeschlagen'
        }
      },
      errors: {
        'link-error': {
          en: 'There was some issue with getting the link. Try again later',
          de: 'Problem beim Fertigstellen des Links. Bitte später erneut versuchen'
        }
      }
    },
    'new-layer-modal': {
      errors: {
        'select-dataset': {
          en: 'Please select dataset or import a new one',
          de: 'Bitte Datei auswählen oder eine neue hochladen'
        },
        'select-geometry': {
          en: 'Please select geometryset or import a new one',
          de: 'Bitte Geometrie auswählen oder eine neue hochladen'
        },
        'select-least-geometry': {
          en: 'Please select at least one geometry',
          de: 'Bitte mindestens eine Geometrie auswählen'
        },
        'select-entrypoint': {
          en: 'Please select one of the options',
          de: 'Bitte eine der Optionen auswählen'
        },
        'empty-layer-name': {
          en: 'Layer name cannot be empty',
          de: 'Name des Layers darf nicht leer sein'
        },
        'no-latitude-longitude-selected': {
          en: 'Please select both Latitude and Longitude columns',
          de: 'Bitte sowohl Breiten- als auch Längengrad auswählen'
        },
        'addresses-not-geocoded': {
          en: 'Please geocode addresses to proceed',
          de: 'Bitte Adresse geokodieren, um fortzufahren'
        },
        'granularity-not-selected': {
          en: 'Please select granularity',
          de: 'Bitte Granularität auswählen'
        },
        'link-column-not-selected': {
          en: 'Please select key column from dataset',
          de: 'Bitte Spalte für Schlüsselvariable aus der Datei auswählen'
        },
        'geometry-not-uploaded': {
          en: 'Please upload your geometry file',
          de: 'Bitte Geometriedatei hochladen'
        },
        'layer-processing-error': {
          en: 'An error occurred while processing new layer. Please try again later',
          de: 'Beim Verarbeiten des Layers ist ein Fehler aufgetreten. Bitte später nochmal versuchen'
        },
        'layer-processing-state-default': {
          en: 'Preparing new layer',
          de: 'Vorbereiten eines neuen Layers'
        },
        'layer-processing-state-dataset-area-linking': {
          en: 'Filtering dataset by areas',
          de: 'Datei nach Gebieten filtern'
        },
        'layer-processing-state-loading': {
          en: 'Retrieving layer content',
          de: 'Layerinhalt abfragen'
        }
      },
      labels: {
        'choose-entrypoint': {
          en: 'Choose Entrypoint',
          de: 'Zugangspunkte auswählen'
        },
        geometry: {
          en: 'Create geometry layer',
          de: 'Geometrielayer erstellen'
        },
        'geometry-hint': {
          en: 'Choose between several ways of areas creation',
          de: 'Auswahl zwischen mehreren Möglichkeiten ein Gebiet zu erstellen'
        },
        'upload-poi': {
          en: 'Create POI layer',
          de: 'POI-Layer erstellen'
        },
        'choose-area': {
          en: 'Choose area to display in',
          de: 'Anzuzeigendes Gebiet auswählen'
        },
        'choose-area-help': {
          en: 'Type something above to search and select areas',
          de: 'Gebiete mit Hilfe von oberer Leiste suchen und auswählen'
        },
        granularity: {
          en: 'Choose granularity',
          de: 'Granularität auswählen'
        },
        'granularity-help': {
          en: 'Please select desired areas granularity. Bounding box will be split into smaller polygons depending on your choice',
          de: 'Bitte erwünschte Granularität der Gebiete wählen. Der Rahmen wird je nach Auswahl in kleinere Polygone unterteilt'
        },
        'step-props': {
          en: 'Layer properties',
          de: 'Layereigenschaften'
        },
        'click-next': {
          en: 'Click next to continue',
          de: '"weiter" klicken um fortzufahren'
        },
        'create-new-layer': {
          en: 'Create new layer {subtitle}',
          de: 'Neuer Layer {subtitle}'
        },
        'dataset-combine': {
          en: 'Dataset column to use for geometry and dataset combining',
          de: 'Datei-Spalte zur Verknüpfung mit Geometrie '
        },
        'poi-dataset': {
          en: 'Choose POI',
          de: 'POI auswählen'
        },
        'upload-poi-hint': {
          en: 'Upload Excel file with your POIs, either with coordinates or addresses',
          de: 'Excel-Datei mit POIs hochladen, die Koordinaten oder Adressen beinhaltet'
        },
        'poi-dataset-search-prompt': {
          en: 'Find and use POIs from predefined datasets',
          de: 'Finden und Verwenden der POIs aus den vordefinierten Dateien'
        },
        'poi-dataset-upload-prompt': {
          en: 'Upload your Excel file with POIs',
          de: 'Excel-Datei hochladen, die POIs beinhaltet'
        },
        'area-dataset': {
          en: 'Upload Excel file with area keys',
          de: 'Excel-Datei mit Gebietsschlüssel  hochladen'
        },
        'area-dataset-hint': {
          en: 'Upload Excel file and link it with areas by key column',
          de: 'Excel-Datei hochladen und mit Gebietsschlüssel verknüpfen'
        },
        'area-dataset-search-prompt': {
          en: 'Find and use marketing data from our predefined datasets',
          de: 'Marketingdaten aus vordefinierter Datei suchen und verwenden'
        },
        'area-dataset-upload-prompt': {
          en: 'Upload your Excel file with dataset',
          de: 'Excel-Datei mit zugehörigen Datenwerten hochladen'
        },
        'area-link': {
          en: 'Link geometry',
          de: 'Geometrie verknüpfen'
        },
        'area-link-search-prompt': {
          en: 'Find and display standard geometries on the map (e.g. PLZ)',
          de: 'Standardgeometrien auf der Karte finden und darstellen (z.B. PLZ)'
        },
        'area-link-upload-prompt': {
          en: 'Upload your own KML or Shape file with geometries to display them on the map',
          de: 'Hochladen einer eigenen KMI-Datei oder anderem Geometrieformat, um diese auf der Karte darzustellen'
        },
        'area-link-linking-column': {
          en: 'Key column from dataset',
          de: 'Schlüssel-Spalte aus der Datei'
        },
        'choose-areas': {
          en: 'Choose areas',
          de: 'Gebiete auswählen'
        },
        'choose-areas-hint': {
          en: 'Choose areas that you want to see on the map',
          de: 'Auf der Karte sichtbaren Bereiche auswählen'
        },
        'upload-areas': {
          en: 'Upload custom areas',
          de: 'Hochladen benutzerdefinierter Gebiete '
        },
        'upload-areas-hint': {
          en: 'Upload your custom areas to show them on the map',
          de: 'Hochladen von benutzerdefinierten Gebieten, um sie auf der Karte anzuzeigen'
        },
        'layer-properties': {
          en: 'Layer properties',
          de: 'Layereigenschaften'
        },
        'link-dataset': {
          en: 'Link dataset',
          de: 'Datei verknüpfen'
        },
        'new-layer-name': {
          en: 'New layer name',
          de: 'Neuer Layername'
        },
        'search-areas': {
          en: 'Search and select areas',
          de: 'Suche und Auswahl von Gebieten'
        },
        'search-existing-dataset': {
          en: 'Search and select existing dataset to link it with the geometryset you have just selected',
          de: 'Suche und Auswahl einer vorhandenen Datei, um diese mit der ausgewählten Geometrie zu verknüpfen'
        },
        'search-existing-geometry': {
          en: 'Search and select existing geometry to display it on the map',
          de: 'Suche und Auswahl einer vorhandenen Geometrie, um diese auf der Karte anzuzeigen'
        },
        'upload-area': {
          en: 'Upload Area',
          de: 'Hochladen Gebiete'
        },
        'upload-area-search-prompt': {
          en: 'Search and select standard geometries to display them on the map',
          de: 'Standardgeometrien suchen und auswählen, um diese auf der Karte darzustellen'
        },
        'upload-area-upload-prompt': {
          en: 'Upload your own KML or Shape file with geometries to display them on the map',
          de: 'Hochladen einer eigenen KMI-Datei oder anderem Geometrieformat, um diese auf der Karte darzustellen'
        },
        skip: {
          en: 'Skip',
          de: 'Überspringen'
        },
        'skip-dataset': {
          en: 'Or press {skip} to proceed to layer configuration',
          de: 'Oder {skip} klicken, um zur Layer Konfiguration zu gelangen'
        },
        'upload-dataset': {
          en: 'Upload dataset',
          de: 'Datei hochladen'
        },
        'upload-geometry': {
          en: 'Upload geometry',
          de: 'Geometrie hochladen'
        },
        'upload-geometry-file': {
          en: 'Upload new geometry file',
          de: 'Neue Geometrie hochladen'
        },
        'selected-geometries-help': {
          en: 'Selected geometries will be displayed here. Click on the geometry to find it on the map',
          de: 'Ausgewählte Geometrien werden hier dargestellt. Auf die Geometrien klicken, um diese auf der Karte zu finden'
        },
        'processing-layer': {
          en: 'Processing layer',
          de: 'Layerbearbeitung'
        },
        'please-wait-layer-processing': {
          en: 'Please wait while we are preparing a new layer for you',
          de: 'Bitte warten, während ein neuer Layer vorbereitet wird'
        },
        'back-to-search': {
          en: 'Back to search',
          de: 'Zurück zur Suche'
        },
        'what-is-here': {
          en: 'What\'s here?',
          de: 'Was ist hier?'
        },
        point: {
          en: 'Point',
          de: 'Punkt'
        },
        'remove-geometry': {
          en: 'Remove geometry',
          de: 'Geometrie entfernen'
        }
      },
      placeholders: {
        'layer-name': {
          en: 'Layer name',
          de: 'Layernamen'
        }
      }
    },
    sidebar: {
      buttons: {
        'delete-layer': {
          en: 'Delete layer',
          de: 'Layer löschen'
        },
        'edit-layer': {
          en: 'Edit layer',
          de: 'Layer bearbeiten'
        },
        'style-settings': {
          en: 'Style settings',
          de: 'Style-Optionen'
        },
        'change-icon': {
          en: 'Change icon',
          de: 'Symbol ändern'
        },
        'change-color': {
          en: 'Change color',
          de: 'Farbe ändern'
        },
        'share-visualization': {
          en: 'Share visualization',
          de: 'Visualisierung teilen'
        }
      },
      labels: {
        rename: {
          en: 'Rename',
          de: 'Umbenennen'
        },
        'visualization-layers': {
          en: 'Visualization layers',
          de: 'Visualisierungslayer'
        },
        'no-layers': {
          en: 'Your visualization doesn\'t have layers',
          de: 'Die Visualisierung besitzt keine Layer'
        },
        'quantity-of-objects-for-sublayers': {
          en: 'Sublayer doesn\'t include objects | Sublayer includes {count} object | Sublayer includes {count} objects',
          de: 'Sublayer enthält keine Objekte | Sublayer beinhaltet {count} Objekte | Sublayer beinhaltet {count} Objekte'
        }
      }
    },
    slides: {
      buttons: {
        'export-pdf': {
          en: 'Export all to PDF',
          de: 'Als PDF speichern'
        },
        'export-pptx': {
          en: 'Export all to PPTX',
          de: 'Als PPTX speichern'
        },
        'slide-save-image': {
          en: 'Save image',
          de: 'Bild speichern'
        }
      },
      labels: {
        'edit-slide': {
          en: 'Edit slide',
          de: 'Folie bearbeiten'
        },
        'export-slides': {
          en: 'Export slides',
          de: 'Folien exportieren'
        },
        'new-slide': {
          en: 'New slide',
          de: 'Neue Folie'
        },
        'take-screenshot': {
          en: 'Take Screenshot',
          de: 'Screenshot aufnehmen'
        },
        'show-background': {
          en: 'Show background',
          de: 'Hintergrund anzeigen'
        }
      }
    },
    errors: {
      'load-visualization-data-failed': {
        en: 'We can\'t load visualization data right now. Please try again later.',
        de: 'Visualisierungsdaten können nicht geladen werden. Bitte später erneut versuchen'
      },
      'load-geometries-data-failed': {
        en: 'During load geometries data occurred error. Please try again later.',
        de: 'Fehler während des Hochladens der Geometriedaten. Bitte später erneeut versuchen'
      },
      'search-error': {
        en: 'An error occurred while trying to find { searchType } by name. Please try again later.',
        de: 'Fehler beim Suchen von { searchType }. Bitte später erneut versuchen '
      },
      'load-dataset-details-failed': {
        en: 'An error occurred while trying to load dataset details. Please try again later.',
        de: 'Fehler beim Laden der Datensatz-Details. Bitte später erneut versuchen.'
      }
    },
    reports: {
      en: 'Reports for the layer "{layerName}"',
      de: 'Reports für den Layer {layerName}'
    }
  },
  orders: {
    board: {
      'empty-name': {
        en: 'Order',
        de: 'Bestellung'
      },
      unassigned: {
        en: 'unassigned',
        de: 'nicht zugewiesen'
      }
    },
    'create-order': {
      'create-order-hint': {
        en: 'Choose a saved planning to create an order from it.',
        de: 'Gespeicherte Planung verwenden, um Bestellung zu erstellen'
      },
      'create-order-label': {
        en: 'Create draft',
        de: 'Entwurf anlegen'
      },
      plan: {
        en: 'Plan',
        de: 'Plan'
      },
      'plan-description': {
        en: 'Begin typing to search for a plan. You can choose only one plan.',
        de: 'Suche eingeben. Nur ein Plan auswählbar'
      },
      assignee: {
        en: 'Assignee',
        de: 'Beauftragter'
      },
      create: {
        en: 'Create',
        de: 'Erstellen'
      }
    },
    status: {
      [OrderStatus.Draft]: {
        en: 'Draft',
        de: 'Entwurf'
      },
      [OrderStatus.OfferPrepared]: {
        en: 'Offer',
        de: 'Angebot'
      },
      [OrderStatus.OfferPending]: {
        en: 'Customer Feedback',
        de: 'Kundenfeedback'
      },
      [OrderStatus.Buying]: {
        en: 'Buying',
        de: 'Einkauf'
      },
      [OrderStatus.Distribution]: {
        en: 'Production',
        de: 'Produktionskosten'
      },
      [OrderStatus.Invoice]: {
        en: 'Invoicing',
        de: 'Rechnungsstellung'
      }
    } as { [key in OrderStatus]: LocaleMessageObject },
    actions: {
      [OrderActions.BuySites]: {
        en: 'Buy sites',
        de: 'Stellen einkaufen'
      },
      [OrderActions.SendOffer]: {
        en: 'Send offer',
        de: 'Angebot senden'
      },
      [OrderActions.AttachDocument]: {
        en: 'Attach',
        de: 'Anhängen'
      },
      [OrderActions.SendInfo]: {
        en: 'Send info',
        de: 'Informationen verschicken'
      },
      [OrderActions.CancelOrder]: {
        en: 'Cancel order',
        de: 'Order stornieren'
      },
      [OrderActions.ReScore]: {
        en: 'Re-score',
        de: 'Re-score'
      },
      [OrderActions.GetStatus]: {
        en: 'Get status',
        de: 'Status abfragen'
      },
      [OrderActions.RePlan]: {
        en: 'Re-Plan',
        de: 'Neu planen'
      },
      [OrderActions.ChangeOffer]: {
        en: 'Change offer',
        de: 'Angebot ändern'
      },
      [OrderActions.MakeOffer]: {
        en: 'Make offer',
        de: 'Angebot erstellen'
      },
      [OrderActions.AddCosts]: {
        en: 'Add costs',
        de: 'Kosten hinzufügen'
      },
      [OrderActions.Delete]: {
        en: 'Delete',
        de: 'Löschen'
      },
      [OrderActions.BuyingComplete]: {
        en: 'Buying complete',
        de: 'Einkauf abgeschloßen'
      },
      'confirm-drop': {
        en: 'Do you really want to change the order status from {from} to {to}?',
        de: 'Soll der Bestellstatus tatsächlich von {from} zu {to} geändert werden?'
      }
    },
    details: {
      items: {
        en: 'Items',
        de: 'Elemente'
      },
      'show-items': {
        en: 'Show items',
        de: 'Elemente anzeigen'
      },
      comments: {
        en: 'Comments',
        de: 'Kommentare'
      },
      documents: {
        en: 'Documents',
        de: 'Dokumente'
      },
      history: {
        en: 'History',
        de: 'Geschichte'
      },
      activities: {
        en: 'Activities',
        de: 'Aktivitäten'
      },
      'details-modal-title': {
        en: 'Order details',
        de: 'Bestelldetails'
      },
      assignee: {
        en: 'Assignee',
        de: 'Beauftragter'
      },
      watchers: {
        en: 'Watchers',
        de: 'Beobachter'
      },
      'internal-company': {
        en: 'Internal Company',
        de: 'Internes Unternehmen'
      },
      'source-campaign': {
        en: 'Source Campaign',
        de: 'Source Kampagne'
      },
      tags: {
        en: 'Tags',
        de: 'Tags'
      },
      price: {
        en: 'Price',
        de: 'Preis'
      }
    },
    items: {
      'items-modal-title': {
        en: 'Order items',
        de: 'Elemente bestellen'
      }
    },
    errors: {
      'orders-not-updated': {
        en: 'Something went wrong while processing the order(s). Please, try again',
        de: 'Etwas ist während der Durchführung schiefgelaufen. Bitte später erneut versuchen'
      },
      'order-not-found': {
        en: 'Order with such ID was not found',
        de: 'Order mit dieser ID nicht gefunden'
      }
    }
  },
  analytics: {
    buttons: {
      'new-dataset': {
        en: 'New dataset',
        de: 'Neuer Datensatz'
      }
    },
    labels: {
      'load-dataset': {
        en: 'Load dataset',
        de: 'Datensatz laden'
      }
    },
    placeholders: {
      'dataset-name': {
        en: 'Dataset name',
        de: 'Datensatz Name'
      }
    },
    'new-dataset-wizard': {
      labels: {
        'dataset-processing-label': {
          en: 'Please wait while we are preparing a new dataset for you',
          de: 'Bitte hab ein wenig Geduld. Wir bereiten einen neuen Datensatz für dich vor'
        },
        'dataset-processing-description': {
          en: 'Retrieving dataset content',
          de: 'Datensatz Inhalt wird vorbereitet'
        },
        'dataset-processing-title': {
          en: 'Processing dataset',
          de: 'Datensatz wird verarbeitet'
        },
        'upload-file': {
          en: 'Upload file',
          de: 'Datei hochladen'
        },
        'select-file-details': {
          en: 'Select file details',
          de: 'Datei Details wählen'
        },
        'select-tags': {
          en: 'Meta Tags',
          de: 'Meta Tags'
        },
        'dataset-props': {
          en: 'Dataset properties',
          de: 'Datensatz Eigenschaften'
        },
        'new-dataset-name': {
          en: 'New dataset name',
          de: 'Neuer Datensatz Name'
        },
        geocoding: {
          en: 'Geocoding',
          de: 'Geo Codierung'
        },
        'geocoding-description': {
          en: 'Encode Addresses and retrieve geo location data',
          de: 'Geo Codierung von Adress Daten, um genaue Standort Daten zu erhalten'
        },
        tdlinx: {
          en: 'TDLinx',
          de: 'TDLinx'
        },
        'tdlinx-description': {
          en: 'Enhance addresses and geo locations with consumer market information',
          de: 'Erweitere Adress und Standort Daten mit Verbrauchermarkt Informationen'
        },
        geometries: {
          en: 'Geometries',
          de: 'Geometrien'
        },
        'geometries-description': {
          en: 'Select your individual geometries and save or export for different purposes like planning or visualization',
          de: 'Erstelle deine individuelle Geometrie. Sichere, oder exportiere sie, um sie beispielsweise für Kampagnen zu verwenden'
        },
        'geocode-addresses-label': {
          en: 'Identified columns from file',
          de: 'In der Datei erkannte Spalten'
        },
        'upload-excel-file': {
          en: 'Upload your Excel file with Addresses',
          de: 'Lade eine Excel Datei mit Adress Daten hoch'
        }
      },
      errors: {
        'dataset-processing': {
          en: 'An error occurred while processing new dataset. Please try again later',
          de: 'Es ist während der Verarbeitung der Datei ein Fehler aufgetreten. Bitte versuche es später erneut'
        },
        'enter-valid-dataset-name': {
          en: 'Please, enter valid dataset name',
          de: 'Bitte einen gültigen Datensatznamen eingeben'
        },
        'select-dataset-type': {
          en: 'Please, select dataset type',
          de: 'Bitte einen Datensatz Typen auswählen'
        },
        'upload-dataset': {
          en: 'Please, upload dataset from file',
          de: 'Bitte einen Datensatz hochladen'
        }
      }
    }
  },
  'planning-export': {
    reports: {
      messages: {
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_FAIL]: {
          en: 'An unexpected error appeared. Export offer to plato failed',
          de: 'Es ist ein unerwarteter Fehler aufgetreten. Der Export nach Plato ist fehlgeschlagen'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_SUCCESS]: {
          en: '{success}/{count} offers successfully exported',
          de: '{success}/{count} Angebote erfolgreich exportiert'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_INVALID_DBSTRING]: {
          en: 'The given Order System was not recognised {identifier}',
          de: 'Das angegebene Buchungssystem ist unbekannt {identifier}'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_OPS_NOT_AVAILABLE]: {
          en: 'Direct export to OPS isn’t supported yet. Please choose the Excel Export/Import.',
          de: 'Der direkte Export in OPS wird noch nicht unterstützt. Bitte wählen Sie den Excel-Export/Import.'
        },

        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_MEDIAOWNERNETWORKS_UNKNOWN]: {
          en: 'Unknown networks found and ignored',
          de: 'Unbekannte Netze wurden erkannt und ignoriert'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_HELPERTABLE_NOT_FOUND]: {
          en: 'The database of Plato is not correctly set up',
          de: 'Die Plato Datenbank ist nicht korrekt ausgewählt worden'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_UNABLE_TO_OPEN_DATABASE_CONNECTION]: {
          en: 'Connecting to the database of Plato failed',
          de: 'Die Verbindung zur Plato Datenbank konnte nicht hergestellt werden'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_CUSTOMER_NOT_SET]: {
          en: 'The customer was not set',
          de: 'Der Kunde wurde nicht angegeben'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_CREATOR_NOT_SET]: {
          en: 'The author of this planning was not set',
          de: 'Der Ersteller der Planung wurde nicht angegeben'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_MISSING_GEBIETS_ID]: {
          en: 'At least one Belegungsgebiet could not be exported to Plato',
          de: 'Mindestens ein Belegungsgebiet konnte nicht nach Plato exportiert werden'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_MISSING_SITES]: {
          en: 'In Plato offer {OfferId} {NumberOfMissingSites} were not found',
          de: 'Das Angebot {OfferId} {NumberOfMissingSites} konnte nicht in Plato gefunden werden'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_SCORING_FAILED]: {
          en: 'Scoring failed',
          de: 'Scoring fehlgeschlagen'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_AREATAGS_NOT_UNIQUE]: {
          en: 'Unclear area assignments exist in the planning. Please remove unclear area assignments or allow their usage in the planning to continue',
          de: 'In der Planung wurden Konflikte in der Gebietszuweisung entdeckt. Bitte löse diese Konflikte, oder stimme zu, dass sie so in dem Angebot verwendet werden sollen'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_INVALID_TIMEFRAME]: {
          en: 'Some timeframes of the planning are invalid and could not be transfered to Plato. {Unit} : {timeRange}',
          de: 'Manche Zeiträume innerhalb der Planung sind nicht valide und konnten somit nicht nach Plato übertragen werden. {Unit} : {timeRange}'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_UPDATE_PLANNING_FAILED]: {
          en: 'The transfer to plato succeded but the planning has not been updated',
          de: 'Die Planung wurde nicht aktualisiert, aber die Übertragung nach Plato war erfolgreich'
        },
        [OffergatewayErrorsMsgId.IDS_OFFERGATEWAY_CONVERSION_FAILURE]: {
          en: 'The part of the planning that is set in year {PlatoOfferYear} could not be exported. Units : {Units}',
          de: 'Der Teil der Planung, welcher im Jahr {PlatoOfferYear} geplant wurde, konnte nicht exportiert werden. Units : {Units}'
        }
      }
    },
    confirm: {
      labels: {
        'export-confirm-title': {
          en: 'Cancel active export request?',
          de: 'Aktuelle Export-Anfrage abbrechen?'
        },
        'export-confirm-message': {
          en: 'You have the active export request. If you proceed, the export will be cancel.',
          de: 'Aktuellste Export-Anfrage gewählt. Durch Fortfahren, wird diese abgebrochen'
        },
        'use-default-schema-title': {
          en: 'Belegungsgebiet creation',
          de: 'Definition des Belegungsgebiets'
        },
        'use-default-schema-message': {
          en: 'Do you want to use default way to generate Belegungsgebiet (Pathname, Area-Key)?',
          de: 'Möchten Sie die Standardmethode zur Benennung von Belegungsgebieten verwenden (Pfadname, Gebiets-Key)?'
        }
      }
    },
    error: {
      'export-plan': {
        en: 'An error occurred while exporting plan. Please try again later',
        de: 'Es ist ein Fehler aufgetreten. Bitte später erneut versuchen'
      }
    },
    labels: {
      'plan-was-exported': {
        en: 'Plan was successfully exported. To view report, click on the button in the bottom of form.',
        de: 'Plan erfolgreich exportiert. Auf den Button klicken, um den Bericht zu sehen'
      },
      success: {
        en: 'Success',
        de: 'Erfolg'
      }
    },
    nav: {
      title: {
        en: 'Export plan',
        de: 'Plan exportieren'
      },
      'back-link': {
        en: 'Back to planning module',
        de: 'Zurück zum Planungsmodul'
      }
    },
    form: {
      field: {
        planning: {
          label: {
            en: 'Planning',
            de: 'Planung'
          },
          description: {
            en: 'Select the plan you want to export',
            de: 'Plan auswählen, der exportiert werden soll'
          }
        },
        'sales-office': {
          label: {
            en: 'Sales Office',
            de: 'Verkaufsbüro'
          },
          description: {
            en: 'Select the main sales office',
            de: 'Hauptverkaufsbüro auswählen'
          }
        },
        customer: {
          label: {
            en: 'Customer',
            de: 'Kunde'
          },
          description: {
            en: 'Select the customer to assign the selected plan',
            de: 'Kunden auswählen, dem der ausgewählte Plan zugewiesen wird’'
          }
        },
        'order-system': {
          label: {
            en: 'Order System',
            de: 'Ordersystem'
          },
          description: {
            en: 'Select an order system to receive the exported plan',
            de: 'Ordersystem auswählen, um den exportierten Plan zu erhalten'
          }
        }
      },
      message: {
        'customer-reference-broken': {
          en: 'The customer information is not available. Please retry with different entries.',
          de: 'Die Kundendaten sind nicht verfügbar. Bitte versuchen Sie es mit anderen Eingaben erneut.'
        }
      },
      button: {
        label: {
          en: 'Export and save',
          de: 'Exportieren und speichern'
        },
        'view-report': {
          en: 'View report',
          de: 'Bericht anzeigen'
        }
      }
    }
  },
  'query-builder': {
    labels: {
      'match-type': {
        en: 'Match type',
        de: 'Zusammenführungstyp'
      },
      'add-rule': {
        en: 'Add rule',
        de: 'Regel hinzufügen'
      },
      'add-group': {
        en: 'Add group',
        de: 'Gruppe hinzufügen'
      },
      'input-placeholder': {
        en: 'Enter value',
        de: 'Wert hinzufügen'
      },
      'choice-empty': {
        en: '(empty)',
        de: '(leer)'
      },
      'total-rows': {
        en: 'Total rows',
        de: 'Zeilen insgesamt'
      },
      'filtered-rows': {
        en: 'Filtered rows',
        de: 'Ausgewählte Zeilen'
      }
    },
    operators: {
      and: {
        en: 'AND',
        de: 'UND'
      },
      or: {
        en: 'OR',
        de: 'ODER'
      }
    }
  },
  validation: {
    errors: {
      required: {
        en: '{attribute} is required',
        de: '{attribute} notwendig'
      }
    }
  },
  'ranking-import': {
    wizard: {
      'search-dataset': {
        en: 'Choose the ranking data set',
        de: 'Ranking-Datensatz auswählen'
      },
      'query-dataset': {
        en: 'Query the data from dataset',
        de: 'Daten aus dem Datensatz auswählen'
      },
      'choose-columns-from-dataset': {
        en: 'Select plz and rating columns',
        de: 'Auswahl der PLZ und Ranking-Spalten'
      }
    },
    errors: {
      'empty-dataset': {
        en: 'Query you\'ve set up results into empty table. Please weaken the conditions',
        de: 'Die Tabelle für die angeforderte Abfrage ist leer. Bitte passen Sie die Eingaben an.'
      },
      'plz-column-not-defined': {
        en: 'Please, select plz5/8 column',
        de: 'Bitte wählen Sie eine Spalte für PLZ5/8-Werte aus.'
      },
      'rating-column-not-defined': {
        en: 'Please, select the rating column',
        de: 'Bitte wählen Sie Rankingwerte aus.'
      },
      'plz-column-wrong-format': {
        en: 'Wrong format of the plz column. PLZ values may contain only numeric characters',
        de: 'Die Spalte mit den PLZ-Werten beinhaltet ein falsches Format - nur Ziffern sind zugelassen.'
      },
      'rating-column-wrong-format': {
        en: 'Wrong format of the rating column. Rating values should be numbers',
        de: 'Die Spalte mit den Ranking-Werten beinhaltet ein falsches Format - nur numerische Werte sind zugelassen.'
      }
    }
  }
}
export default messages
