import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import '@/component-hooks'
import Home from '@/views/home/Home.vue'
import Login from '@/views/login/Login.vue'
import Error404 from '@/views/errors/404/error-404.vue'
import checkAuth from '@/router/middleware/checkAuth'
import cancelRequests from '@/router/middleware/cancel-requests'
import { AuthPermission } from '@/types/auth/permissions'
import { visualizationRoutes } from '@/router/routes-config/visualization'
import { planningRoutes } from '@/router/routes-config/planning'
import { analyticsRoutes } from './routes-config/analytics'
import { Routes } from '@/router/constants/routes'
import { ordersRoutes } from '@/router/routes-config/orders'
import ViewSelector from '@/components/view-selector/ViewSelector.vue'
import { UniqueRouteConfig } from '@/router/types'

const GTarget = () => import('@/views/planning/G-Target.vue')
const GMaps = () => import('@/views/visualization/G-Maps.vue')
const SharedVisualization = () => import('@/views/shared-visualization/SharedVisualization.vue')
const GBuying = () => import('@/views/orders/G-Buying.vue')
const Analytics = () => import('@/views/analytics/Analytics.vue')

Vue.use(Router)

export const getRoutesByItem = (routes: UniqueRouteConfig[]) => {
  return routes.reduce((allRoutes: RouteConfig[], route) => {
    return allRoutes
      .concat([route])
      .concat(getRoutesByItem(route.children || []))
  }, [])
}

export function getAllRoutes (): UniqueRouteConfig[] {
  return getRoutesByItem(routes)
}

export const routes: Array<UniqueRouteConfig> = [
  {
    path: '/',
    component: Home,
    beforeEnter: checkAuth,
    meta: {
      routeId: Routes.HOME_CONTAINER,
      title: 'Stave web App - Home page',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of stave web app.'
        }
      ]
    },
    children: [
      {
        path: '',
        name: Routes.HOME,
        meta: {
          routeId: Routes.HOME,
          name: 'Home'
        },
        component: ViewSelector
      },
      {
        path: 'planning',
        component: GTarget,
        meta: {
          routeId: Routes.PLANNING_CONTAINER,
          can: AuthPermission.ViewPlanning
        },
        children: planningRoutes
      },
      {
        path: 'visualization',
        component: GMaps,
        props: true,
        meta: {
          routeId: Routes.VISUALIZATION_CONTAINER,
          can: AuthPermission.ViewVisualization
        },
        children: visualizationRoutes
      },
      {
        path: 'orders',
        component: GBuying,
        props: true,
        meta: {
          routeId: Routes.ORDERS_CONTAINER,
          can: AuthPermission.ViewOrders
        },
        children: ordersRoutes
      },
      {
        path: 'analytics',
        component: Analytics,
        props: true,
        meta: {
          routeId: Routes.ANALYTICS_CONTAINER,
          can: AuthPermission.ViewDataset
        },
        children: analyticsRoutes
      },
      {
        path: 'shared-visualization/:shortLink',
        name: Routes.SHARED_VISUALIZATION,
        component: SharedVisualization,
        meta: {
          routeId: Routes.SHARED_VISUALIZATION,
          noAuth: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: Routes.LOGIN,
    component: Login,
    meta: {
      routeId: Routes.LOGIN
    }
  },
  {
    path: '*',
    name: Routes.ERROR404,
    component: Error404,
    meta: {
      routeId: Routes.ERROR404
    }
  }
]

export const router = new Router({
  mode: 'history',
  base: '/',
  routes
})

router.afterEach(cancelRequests)

export default router
