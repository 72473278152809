import { findLastIndex } from 'lodash'
import {
  WIZARD_NAVIGATION_ACTIVE_ID,
  WIZARD_NAVIGATION_ACTIVE_INDEX,
  WIZARD_NAVIGATION_ACTIVE_IS_FIRST,
  WIZARD_NAVIGATION_ACTIVE_IS_LAST,
  WIZARD_NAVIGATION_ACTIVE_STEP,
  WIZARD_NAVIGATION_CAN_SKIP_STEP,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID,
  WIZARD_NAVIGATION_IS_STEP_EMPTY,
  WIZARD_NAVIGATION_IS_STEP_ENABLED,
  WIZARD_NAVIGATION_IS_STEP_INVALID,
  WIZARD_NAVIGATION_LAST_ENABLED_STEP,
  WIZARD_NAVIGATION_NEXT_DISABLED,
  WIZARD_NAVIGATION_PREV_DISABLED,
  WIZARD_NAVIGATION_START_INDEX,
  WIZARD_NAVIGATION_STEP_BY_ID,
  WIZARD_NAVIGATION_VISIBLE_STEPS
} from '@/store/getter-types'

export const getters = {
  [WIZARD_NAVIGATION_VISIBLE_STEPS] (state, getters, rootState, rootGetters) {
    return state.steps.filter((step) => {
      const hiddenGetter = step.store.getters.isHidden
      return !hiddenGetter || !rootGetters[hiddenGetter]
    })
  },
  [WIZARD_NAVIGATION_STEP_BY_ID] (state, getters) {
    return (stepId) => getters[WIZARD_NAVIGATION_VISIBLE_STEPS].find(step => step.id === stepId)
  },
  [WIZARD_NAVIGATION_ACTIVE_INDEX] (state, getters) {
    return getters[WIZARD_NAVIGATION_VISIBLE_STEPS].findIndex(step => step.active === true)
  },
  [WIZARD_NAVIGATION_ACTIVE_ID] (state, getters) {
    const activeStep = getters[WIZARD_NAVIGATION_ACTIVE_STEP]

    return activeStep ? activeStep.id : null
  },
  [WIZARD_NAVIGATION_ACTIVE_STEP] (state, getters) {
    const activeIndex = getters[WIZARD_NAVIGATION_ACTIVE_INDEX]
    const activeStep = getters[WIZARD_NAVIGATION_VISIBLE_STEPS][activeIndex]

    return activeStep || null
  },
  [WIZARD_NAVIGATION_LAST_ENABLED_STEP] (state, getters, rootState, rootGetters) {
    return findLastIndex(getters[WIZARD_NAVIGATION_VISIBLE_STEPS], step => rootGetters[step.store.getters.isEnabled])
  },
  [WIZARD_NAVIGATION_ACTIVE_IS_FIRST] (state, getters) {
    return getters[WIZARD_NAVIGATION_ACTIVE_INDEX] === 0
  },
  [WIZARD_NAVIGATION_ACTIVE_IS_LAST] (state, getters) {
    return getters[WIZARD_NAVIGATION_ACTIVE_INDEX] === getters[WIZARD_NAVIGATION_VISIBLE_STEPS].length - 1
  },
  [WIZARD_NAVIGATION_PREV_DISABLED] (state, getters) {
    return getters[WIZARD_NAVIGATION_ACTIVE_IS_FIRST]
  },
  [WIZARD_NAVIGATION_NEXT_DISABLED] (state, getters) {
    return getters[WIZARD_NAVIGATION_ACTIVE_IS_LAST]
  },
  [WIZARD_NAVIGATION_IS_STEP_ENABLED] (state, getters, rootState, rootGetters) {
    return (stepId) => {
      return rootGetters[getters[WIZARD_NAVIGATION_STEP_BY_ID](stepId).store.getters.isEnabled]
    }
  },
  [WIZARD_NAVIGATION_IS_STEP_EMPTY] (state, getters, rootState, rootGetters) {
    return (stepId) => {
      return rootGetters[getters[WIZARD_NAVIGATION_STEP_BY_ID](stepId).store.getters.isEmpty]
    }
  },
  [WIZARD_NAVIGATION_IS_STEP_INVALID] (state, getters, rootState, rootGetters) {
    return (stepId) => {
      return rootGetters[getters[WIZARD_NAVIGATION_STEP_BY_ID](stepId).store.getters.isInvalid]
    }
  },
  [WIZARD_NAVIGATION_CAN_SKIP_STEP] (state, getters, rootState, rootGetters) {
    return (stepId) => {
      const step = getters[WIZARD_NAVIGATION_STEP_BY_ID](stepId)
      return step && step.store.getters.canSkip && rootGetters[step.store.getters.canSkip]
    }
  },
  [WIZARD_NAVIGATION_START_INDEX] (state) {
    return state.startIndex == null ? 0 : state.startIndex
  },
  [WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID] (state, getters) {
    const steps = getters[WIZARD_NAVIGATION_VISIBLE_STEPS]
    const isStepInvalid = getters[WIZARD_NAVIGATION_IS_STEP_INVALID]

    return (stepId) => {
      for (const step of steps) {
        if (step.id === stepId) {
          return false
        }

        if (isStepInvalid(step.id)) {
          return true
        }
      }

      // Step not found? Strange but ok.
      return false
    }
  }
}
