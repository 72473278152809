import { get, isEmpty } from 'lodash'
import { getBoundsForLayers } from '@/utils/map'
import { MAP_LAYER_TYPES } from '@/components/visualization/layer-settings/constants'
import {
  VISUALIZATION_BOUNDS,
  VISUALIZATION_GET_SUBLAYER_OPTION,
  VISUALIZATION_ID,
  VISUALIZATION_IS_SUBLAYER_VISIBLE,
  VISUALIZATION_IS_SET,
  VISUALIZATION_LAYER_GET_ICONS,
  VISUALIZATION_LAYERS,
  VISUALIZATION_NAME,
  VISUALIZATION_REQUEST_DATA,
  VISUALIZATION_SLIDES,
  VISUALIZATION_VISIBLE_MAP_LAYERS,
  VISUALIZATION_WAS_UPDATED,
  VISUALIZATION_WAS_VALIDATED,
  VISUALIZATION_CREATED_BY,
  VISUALIZATION_HAS_ERRORS,
  VISUALIZATION_GET_ERRORS,
  GET_VISUALISATION_LOCATIONS,
  VISUALIZATION_LAYER_GET_REPORTS,
  USER_ROLES
} from '@/store/getter-types'
import { getImagesFromSprite } from '@/utils/image'
import { getMainLayerStyleProp } from '@/utils/visualization/style'
import { filterReportByVisibility } from '@/utils/reports'

// getters
const getters = {
  [VISUALIZATION_ID] (state) {
    return state.id
  },
  [VISUALIZATION_NAME] (state) {
    return state.name
  },
  [VISUALIZATION_LAYERS] (state) {
    return state.layers
  },
  [VISUALIZATION_VISIBLE_MAP_LAYERS] (state) {
    return state.layers
      .filter(layer => MAP_LAYER_TYPES.includes(layer.type) && layer.style && layer.style.visible)
  },
  [VISUALIZATION_BOUNDS] (state, getters) {
    return getBoundsForLayers(getters[VISUALIZATION_VISIBLE_MAP_LAYERS])
  },
  [VISUALIZATION_REQUEST_DATA] (state, getters) {
    const layers = state.layers.map(item => ({
      id: item.id,
      dataSource: item.dataSource,
      name: item.name,
      type: item.type,
      style: item.style,
      shapeType: item.shapeType,
      settings: item.settings
    }))

    const data = {
      name: state.name,
      linkedPlanningIDs: state.linkedPlanningIDs,
      linkedPlanningFunctions: state.linkedPlanningFunctions,
      savedLocations: getters[GET_VISUALISATION_LOCATIONS],
      layers
    }

    if (state.id) {
      data.id = state.id
    }

    return data
  },
  [VISUALIZATION_SLIDES] (state) {
    return state.slides
  },
  [VISUALIZATION_WAS_UPDATED] (state) {
    return state.wasUpdated
  },
  [VISUALIZATION_WAS_VALIDATED] (state) {
    return state.wasValidated
  },
  [VISUALIZATION_IS_SET] () {
    return ({ name }) => {
      return !isEmpty(name)
    }
  },
  [VISUALIZATION_HAS_ERRORS] (state, getters) {
    const errors = getters[VISUALIZATION_GET_ERRORS]()
    return errors.length > 0
  },
  [VISUALIZATION_GET_ERRORS] (state, getters) {
    return (visualization = { name: getters[VISUALIZATION_NAME], visualizationId: getters[VISUALIZATION_ID] }) => {
      const errors = []

      if (!getters[VISUALIZATION_IS_SET](visualization)) {
        errors.push('visualization.modals.errors.set-up-visualization')
      }

      return errors
    }
  },
  [VISUALIZATION_GET_SUBLAYER_OPTION] () {
    return (layer, key, option, defaultValue = undefined) => {
      const styleProp = getMainLayerStyleProp(layer)
      return get(layer, ['style', styleProp, 'subLayers', key, option], defaultValue)
    }
  },
  [VISUALIZATION_IS_SUBLAYER_VISIBLE] (state, getters) {
    const getClusterOption = getters[VISUALIZATION_GET_SUBLAYER_OPTION]

    return (layer, key) => getClusterOption(layer, key, 'visible', true)
  },
  [VISUALIZATION_LAYER_GET_ICONS] () {
    const requests = {}

    return (layer) => {
      !requests[layer.id] && (requests[layer.id] = getImagesFromSprite(layer.style.fill))

      return requests[layer.id].finally(() => {
        requests[layer.id] = null
      })
    }
  },
  [VISUALIZATION_LAYER_GET_REPORTS] (state, getters) {
    return (layer) => {
      return filterReportByVisibility(state.reports, getters[USER_ROLES])
        .filter(report => report.params
          .some(reportParam => reportParam.key === 'layerId' && reportParam.value === layer.id)
        )
    }
  },
  [VISUALIZATION_CREATED_BY] (state) {
    return state.createdBy
  }
}

export default getters
