import { Component, Vue } from 'vue-property-decorator'

@Component
export class DynamicStoreMappingsMixin extends Vue {
  protected declare namespace: string

  protected get isStoreInitialized (): boolean {
    return this.$store.hasModule(this.namespace)
  }

  protected dispatchDynamicStoreAction (actionId: string, payload?: unknown): Promise<unknown> {
    return this.$store.dispatch(this.namespace + '/' + actionId, payload)
  }

  protected dynamicStoreGetter<T> (getterId: string): T {
    return this.$store.getters[this.namespace + '/' + getterId]
  }
}
