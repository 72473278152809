import { LegendFormatMixin } from './legend-format-mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class RangeLegendMixin<TLayer> extends Mixins<LegendFormatMixin>(LegendFormatMixin) {
  protected layer!: TLayer
  protected makeRangeLegendValues (ranges) {
    return ranges.slice()
      .sort((a, b) => a.from - b.from)
      .map((rangeConfig, index) => {
        if (!this.isSublayerVisible(this.layer, index)) {
          return null
        }
        return {
          label: rangeConfig.label || this.makeRangeLabel(rangeConfig.from),
          color: rangeConfig.color,
          opacity: rangeConfig.opacity,
          key: index
        }
      })
      .filter(value => value !== null)
  }

  protected makeRangeLabel (value) {
    const formattedValue = this.formatLegendValue(value)
    return `≥${formattedValue}`
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected isSublayerVisible (_layer: TLayer, _value: number) {
    return true
  }
}
