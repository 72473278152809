export const enum GroupOperator {
  OR = 'or',
  AND = 'and',
}

export const enum QueryOperator {
  // Number
  Less = '<',
  Greater = '>',
  LessOrEqual = '<=',
  GreaterOrEqual = '>=',
  Equal = '=',
  NotEqual = '<>',

  // Text
  In = 'in',
}
