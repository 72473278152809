import { GRID_COLUMN_TYPE_NUMBER, GRID_COLUMN_TYPE_STRING } from '@/components/shared/ag-grid/constants'

export enum BOOKABLE_UNIT_FIELDS {
  Name = 'name',
  ExternalID = 'ExternalID',
  Grp = 'grp',
  MediaOwner = 'media owner',
  SiteType = 'site type',
  Description = 'description',
  Quantity = 'quantity',
  Price = 'price',
  BookingTimeFrame = 'BookingTimeFrame'
}

export type EditableGridFieldSettings = { required: boolean, type: string }
type BookableUnitFieldSettings = Record<BOOKABLE_UNIT_FIELDS, EditableGridFieldSettings>

export const BOOKABLE_UNIT_FIELDS_SETTINGS: BookableUnitFieldSettings = {
  [BOOKABLE_UNIT_FIELDS.Name]: {
    required: true,
    type: GRID_COLUMN_TYPE_STRING
  },
  [BOOKABLE_UNIT_FIELDS.ExternalID]: {
    required: true,
    type: GRID_COLUMN_TYPE_NUMBER
  },
  [BOOKABLE_UNIT_FIELDS.Grp]: {
    required: false,
    type: GRID_COLUMN_TYPE_STRING
  },
  [BOOKABLE_UNIT_FIELDS.MediaOwner]: {
    required: true,
    type: GRID_COLUMN_TYPE_STRING
  },
  [BOOKABLE_UNIT_FIELDS.SiteType]: {
    required: true,
    type: GRID_COLUMN_TYPE_STRING
  },
  [BOOKABLE_UNIT_FIELDS.Description]: {
    required: false,
    type: GRID_COLUMN_TYPE_STRING
  },
  [BOOKABLE_UNIT_FIELDS.Quantity]: {
    required: true,
    type: GRID_COLUMN_TYPE_NUMBER
  },
  [BOOKABLE_UNIT_FIELDS.Price]: {
    required: true,
    type: GRID_COLUMN_TYPE_NUMBER
  },
  [BOOKABLE_UNIT_FIELDS.BookingTimeFrame]: {
    required: false,
    type: GRID_COLUMN_TYPE_STRING
  }
}
