import { getPathCoordinates } from '@/utils/visualization/parse-layers/common'
import { ShapeType } from '@/types/visualization/layer/enums'
import { createTransformResponse } from '@/api/utils'
import { AxiosResponse } from 'axios'
import { Geometry } from '@/types/common'

export const getGeometryTransformResponse = createTransformResponse<Geometry>((response: AxiosResponse['data']): Geometry => {
  const parsedResponse = JSON.parse(response)
  const { coordinates } = parsedResponse.geometry

  parsedResponse.paths = getPathCoordinates({ coordinates })
  parsedResponse.type = ShapeType.Polygon

  delete parsedResponse.geometry

  return parsedResponse
})
