
import { Component, Mixins } from 'vue-property-decorator'
import { MultiCircleMarker } from '@/lib/leaflet/layer/multi-markers/shape-markers/multi-circle-marker/multi-circle-marker'
import { LayerPoint } from '@/types/visualization/layer/geometry'
import { ItemsGroup } from '../types'
import { MultiMarkersMixin } from '../mixins/grouped-layers/multi-markers-mixin'
import { MultiPointsLayerOptions } from '@/lib/leaflet/layer/multi-markers/multi-points-layer'

@Component
export default class MultiCircleMarkers<TLayer extends MultiCircleMarker = MultiCircleMarker, TOptions extends MultiPointsLayerOptions = MultiPointsLayerOptions> extends Mixins<
    // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    MultiMarkersMixin<LayerPoint, TLayer, TOptions>
  >(MultiMarkersMixin) {
  protected override createMapObject (group: ItemsGroup<LayerPoint, TOptions>, groupOptions: TOptions): TLayer {
    const latlngs = group.items.map((item) => item.latLng)

    return new MultiCircleMarker(latlngs, groupOptions) as TLayer
  }
}
