import {
  WIZARD_INIT,
  WIZARD_INVALIDATE_AFTER,
  WIZARD_RESET,
  WIZARD_TAB_ACTIVATE,
  WIZARD_TAB_ENTER,
  WIZARD_TAB_GO_NEXT,
  WIZARD_TAB_GO_PREV,
  WIZARD_TAB_LEAVE,
  WIZARD_TAB_NAVIGATE,
  WIZARD_TAB_SKIP_STEP,
  WIZARD_TAB_VALIDATE
} from '@/store/action-types'
import {
  WIZARD_NAVIGATION_ACTIVE_INDEX,
  WIZARD_NAVIGATION_ACTIVE_STEP,
  WIZARD_NAVIGATION_STEP_BY_ID,
  WIZARD_NAVIGATION_VISIBLE_STEPS
} from '@/store/getter-types'
import {
  WIZARD_INIT_STEPS,
  WIZARD_SET_START_INDEX,
  WIZARD_SET_STEP_ACTIVE,
  WIZARD_SET_STEP_TOUCHED
} from '@/store/mutation-types'

export const actions = {
  [WIZARD_TAB_NAVIGATE] ({ getters, dispatch }, { amount }) {
    const getStepById = getters[WIZARD_NAVIGATION_STEP_BY_ID]
    const stepsCount = getters[WIZARD_NAVIGATION_VISIBLE_STEPS].length

    let newStepIndex = getters[WIZARD_NAVIGATION_ACTIVE_INDEX] + amount

    for (let stepIndex = newStepIndex; stepIndex >= 0 && stepIndex < stepsCount; stepIndex += amount) {
      const nextStepId = getters[WIZARD_NAVIGATION_VISIBLE_STEPS][stepIndex].id
      const isNextStepEnabled = getters[getStepById(nextStepId).store.getters.isEnabled]

      if (isNextStepEnabled) {
        newStepIndex = stepIndex
        break
      }
    }

    return dispatch(WIZARD_TAB_ACTIVATE, newStepIndex)
  },
  [WIZARD_TAB_GO_PREV] ({ dispatch }) {
    return dispatch(WIZARD_TAB_NAVIGATE, { amount: -1 })
  },
  [WIZARD_TAB_GO_NEXT] ({ dispatch }) {
    return dispatch(WIZARD_TAB_NAVIGATE, { amount: 1 })
  },
  [WIZARD_TAB_SKIP_STEP] ({ getters, dispatch }) {
    return dispatch(getters[WIZARD_NAVIGATION_ACTIVE_STEP].store.actions.skipStep, null, { root: true })
  },
  async [WIZARD_TAB_ACTIVATE] ({ getters, dispatch }, index) {
    const steps = getters[WIZARD_NAVIGATION_VISIBLE_STEPS]

    const activeIndex = getters[WIZARD_NAVIGATION_ACTIVE_INDEX]

    if (index === activeIndex) {
      return true
    }

    if (index < activeIndex) {
      // Just allow going backward without additional validation.
      const canLeave = await dispatch(WIZARD_TAB_LEAVE, steps[activeIndex].id)
      return canLeave === false ? false : dispatch(WIZARD_TAB_ENTER, index)
    }

    if (activeIndex >= 0) {
      await dispatch(WIZARD_TAB_VALIDATE, steps[activeIndex].id)
    }

    let stepIndex = Math.max(activeIndex, 0)

    while (stepIndex < index) {
      const canLeave = await dispatch(WIZARD_TAB_LEAVE, steps[stepIndex].id)
      if (canLeave === false) {
        return false
      }
      stepIndex++
    }

    await dispatch(WIZARD_TAB_ENTER, index)

    return true
  },
  async [WIZARD_TAB_ENTER] ({ commit, dispatch, getters }, newActiveIndex) {
    const steps = getters[WIZARD_NAVIGATION_VISIBLE_STEPS]
    const newStep = steps[newActiveIndex]
    await (newStep.store.actions.beforeActivate ? dispatch(newStep.store.actions.beforeActivate, null, { root: true }) : Promise.resolve())

    steps.forEach((step, index) => {
      commit(WIZARD_SET_STEP_ACTIVE, { step, active: index === newActiveIndex })
      commit(WIZARD_SET_STEP_TOUCHED, { step, touched: step.active })
    })
  },
  [WIZARD_TAB_VALIDATE] ({ getters, dispatch }, stepId) {
    const validate = getters[WIZARD_NAVIGATION_STEP_BY_ID](stepId).store.actions.validate

    return dispatch(validate, null, { root: true }).then(errors => {
      return errors.length ? Promise.reject(errors) : Promise.resolve(true)
    })
  },
  [WIZARD_TAB_LEAVE] ({ getters, dispatch }, stepId) {
    const beforeLeave = getters[WIZARD_NAVIGATION_STEP_BY_ID](stepId).store.actions.beforeLeave

    if (beforeLeave) {
      return dispatch(beforeLeave, null, { root: true })
    }
  },
  [WIZARD_INIT] ({ commit }, { steps, startIndex = null }) {
    commit(WIZARD_INIT_STEPS, steps)
    commit(WIZARD_SET_START_INDEX, startIndex)
  },
  [WIZARD_RESET] ({ commit }) {
    commit(WIZARD_INIT_STEPS, {})
    commit(WIZARD_SET_START_INDEX, null)
  },
  async [WIZARD_INVALIDATE_AFTER] ({ dispatch, getters }, stepIndex = null) {
    if (stepIndex === null) {
      stepIndex = getters[WIZARD_NAVIGATION_ACTIVE_INDEX] + 1
    } else {
      stepIndex++
    }

    const stepsToInvalidate = getters[WIZARD_NAVIGATION_VISIBLE_STEPS].slice(stepIndex)

    for await (const step of stepsToInvalidate) {
      if (step.touched && step.store.actions.invalidate) {
        await dispatch(step.store.actions.invalidate, null, { root: true })
      }
    }
  }
}
