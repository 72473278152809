import { ALLGEMEIN_STELLE_SELECTION_COLUMN } from '@/constants/unit/fields'
import { DatasetRows } from '@/types/common'
import { MediaOwnerNetworkObj } from '@/types/planning/wizard/filter-editor'

export const monSheetSizeOptions = [{ text: '1/1', value: '1' }, { text: '2/1', value: '2' }, { text: '4/1', value: '4' }, { text: '6/1', value: '6' }]

export function processMONDataset (datasetRows, selected, identifier): DatasetRows {
  return datasetRows.map(row => {
    const selectedItem = selected.find(item => item.id === row[identifier]) as MediaOwnerNetworkObj

    return {
      ...row,
      [ALLGEMEIN_STELLE_SELECTION_COLUMN]: {
        active: row[ALLGEMEIN_STELLE_SELECTION_COLUMN],
        sheetSize: selectedItem ? selectedItem.sheetSize : '1'
      }
    }
  })
}
