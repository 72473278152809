import { AxiosResponse } from 'axios'

type ResponseTransformFunc<T> = (response: AxiosResponse['data']) => T

export function createTransformResponse<T> (callback: ResponseTransformFunc<T>): ResponseTransformFunc<T> {
  return (response: AxiosResponse['data']) => {
    try {
      return callback(response)
    } catch (error) {
      return response
    }
  }
}
