

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { FormInputState, SelectOption } from '@/types/bootstrap'

type ColumnsMapping = {
  key: string,
  label: string,
  value: string | null,
  state: FormInputState
}[]

@Component
export default class ImportCitiesColumns extends Vue {
  @Prop({ default: null }) private cityColumn!: string | null
  @Prop({ default: null }) private cityColumnState!: FormInputState
  @Prop({ default: null }) private okzColumn!: string | null
  @Prop({ default: () => [] }) private options!: SelectOption[]

  private get columnsMapping (): ColumnsMapping {
    return [{
      key: 'city-column',
      label: this.$t('planning.filters.labels.city-column') as string,
      value: this.cityColumn,
      state: this.cityColumnState
    }, {
      key: 'okz-column',
      label: this.$t('planning.filters.labels.okz-column') as string,
      value: this.okzColumn,
      state: null
    }]
  }

  private get isDisabled (): boolean {
    return this.options.length === 0
  }

  private handleColumnSelect (key: string, column: string): void {
    this.$emit(`update:${key}`, column)
  }
}
