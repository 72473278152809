








import { Component, Prop } from 'vue-property-decorator'
import { QueryRuleCheckboxConfig } from '@/types/query-builder/rules'
import { RuleMixin } from './rule.mixin'

type TValue = Array<string | number>

@Component
export default class CheckboxRule extends RuleMixin<TValue> {
  @Prop({ required: true }) rule!: QueryRuleCheckboxConfig
}
