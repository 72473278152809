import {
  WIZARD_STEP_COMMON_SETTINGS_ALL_SETTINGS_VALID,
  WIZARD_STEP_COMMON_SETTINGS_ALL_NAMES_UNIQUE,
  WIZARD_STEP_COMMON_SETTINGS_SELECTED_SETTINGS,
  WIZARD_STEP_COMMON_SETTINGS_FUNCTIONS,
  PLAN_WIZARD_SELECTED_COMMON_SETTINGS,
  WIZARD_STEP_COMMON_SETTINGS_IS_UNIQUE_NAME
} from '@/store/getter-types'
import {
  PLAN_WIZARD_REMOVE_FILTER,
  WIZARD_STEP_COMMON_SETTINGS_REMOVE_SETTING_NODE
} from '@/store/action-types'
import { isCommonSettingsFunction } from '@/utils/plan-wizard-steps'
import { checkFilterNamesUnique, checkFiltersValidity, makeUniqueNameChecker } from '@/store/planning-wizard/utils'
import { FilterStoreQuery, ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { SCORING_FUNCTIONS } from '@/constants/functions/scoring-functions'

// initial state
const state = {}

// getters
const getters = {
  [WIZARD_STEP_COMMON_SETTINGS_FUNCTIONS] (): string[] {
    return SCORING_FUNCTIONS
      .filter(func => isCommonSettingsFunction(func))
      .map(func => func.name)
  },
  [WIZARD_STEP_COMMON_SETTINGS_SELECTED_SETTINGS] (_state, getters): ScoringFunctionNode[] {
    return getters[PLAN_WIZARD_SELECTED_COMMON_SETTINGS]
  },
  [WIZARD_STEP_COMMON_SETTINGS_ALL_SETTINGS_VALID] (_state, getters): boolean {
    return checkFiltersValidity([getters[WIZARD_STEP_COMMON_SETTINGS_SELECTED_SETTINGS]])
  },
  [WIZARD_STEP_COMMON_SETTINGS_IS_UNIQUE_NAME] (_state, getters): (name: string, filterId: string) => boolean {
    return makeUniqueNameChecker([getters[WIZARD_STEP_COMMON_SETTINGS_SELECTED_SETTINGS]])
  },
  [WIZARD_STEP_COMMON_SETTINGS_ALL_NAMES_UNIQUE] (_state, getters): boolean {
    return checkFilterNamesUnique([getters[WIZARD_STEP_COMMON_SETTINGS_SELECTED_SETTINGS]])
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [WIZARD_STEP_COMMON_SETTINGS_REMOVE_SETTING_NODE] ({ dispatch }, filter: FilterStoreQuery): Promise<void> {
    return dispatch(PLAN_WIZARD_REMOVE_FILTER, filter)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
