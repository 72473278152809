import { AxiosInstance } from 'axios'
import {
  ApiRequestInterceptorManager,
  ApiRequestInterceptorsConfig,
  ApiResponseInterceptorManager,
  ApiResponseInterceptorsConfig
} from './types'
import { blockingRequestInterceptors } from './blocking-requests.interceptor'
import { authInterceptors } from './auth.interceptor'
import { cancelRequestInterceptors } from './cancel-requests.interceptor'

function interceptRequest (request: ApiRequestInterceptorManager, config?: ApiRequestInterceptorsConfig): void {
  if (config) {
    request.use(config.onSuccess, config.onReject)
  }
}

function interceptResponse (response: ApiResponseInterceptorManager, config?: ApiResponseInterceptorsConfig): void {
  if (config) {
    response.use(config.onSuccess, config.onReject)
  }
}

function registerRequestInterceptors (request: ApiRequestInterceptorManager): void {
  interceptRequest(request, blockingRequestInterceptors.request)
  interceptRequest(request, authInterceptors.request)
  interceptRequest(request, cancelRequestInterceptors.request)
}

function registerResponseInterceptors (response: ApiResponseInterceptorManager): void {
  interceptResponse(response, blockingRequestInterceptors.response)
  interceptResponse(response, authInterceptors.response)
  interceptResponse(response, cancelRequestInterceptors.response)
}

export function registerInterceptors (axiosInstance: AxiosInstance): void {
  registerRequestInterceptors(axiosInstance.interceptors.request)
  registerResponseInterceptors(axiosInstance.interceptors.response)
}
