import { MarkRequired } from 'ts-essentials'
import { ApiRequestConfig } from '@/api/types'
import { ObjectId } from '@/types/common'
import { Order, OrderDocument, OrderItem, OrderTransitionDto } from '@/types/orders'
import { logger } from '@/utils/log'
import { ApiClient } from '@/api/api-client'
import { transformOrderListResponse } from './transform-response/order-list-response.transform'
import { transformOrderResponse } from './transform-response/single-order-response.transform'
import { transformOrderItemsResponse } from './transform-response/order-items-response.transform'
import { generateDocuments, generateOrderItems, generateOrders } from './mocks'
import { ImportProgressCallback } from '@/types/file-upload'

function warnNotImplemented () {
  logger.log('Orders Api not implemented. Using mocked values')
}

const ORDERS: Order[] = generateOrders(40)

class OrdersApi extends ApiClient {
  getOrders (): Promise<Order[]> {
    const config: ApiRequestConfig = {
      transformResponse: transformOrderListResponse
    }
    return this.get<Order[]>('query', config)
      .then(result => result.length ? result : ORDERS)
  }

  getOrder (orderId: ObjectId): Promise<Order> {
    const config: ApiRequestConfig = {
      transformResponse: transformOrderResponse
    }

    return this.get<Order>(`${orderId}`, config)
  }

  getOrderItems (orderId: ObjectId): Promise<OrderItem[]> {
    const config: ApiRequestConfig = {
      transformResponse: transformOrderItemsResponse
    }

    return this.get<OrderItem[]>(`${orderId}/OrderItems`, config)
      .catch(() => {
        warnNotImplemented()

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(generateOrderItems(Math.trunc(Math.random() * 5000)))
          }, 3000)
        })
      })
  }

  addDocumentToOrder (orderId: ObjectId, file: File, uploadCallback: ImportProgressCallback): Promise<void> {
    const formData = new FormData()
    formData.append('File', file, file.name)
    formData.append('Name', file.name)
    formData.append('MimeType', file.type)

    const onUploadProgress = (progress) => {
      const uploadPercent = Math.round(progress.loaded / progress.total * 100)
      uploadCallback(uploadPercent)
    }

    return this.post(`${orderId}/document`, formData, { onUploadProgress })
  }

  getOrderDocuments (orderId: ObjectId): Promise<OrderDocument[]> {
    return this.get<OrderDocument[]>(`${orderId}/document`)
      .catch(() => {
        warnNotImplemented()
        return generateDocuments()
      })
  }

  createOrder (planningId: ObjectId): Promise<Order> {
    return this.post<Order>(`create/${planningId}`)
  }

  updateOrder ({ id, ...orderProperties }: MarkRequired<Partial<Order>, 'id'>): Promise<void> {
    return this.put(`${id}`, orderProperties)
  }

  orderStatusUpdate (orderStatusUpdateDto: OrderTransitionDto): Promise<void> {
    return this.put('transition', orderStatusUpdateDto, { showSpinner: true })
  }
}

export const ordersApi = new OrdersApi({
  baseURL: API_CONF.api + '/api/Orders/'
})
