







import { Component, Vue } from 'vue-property-decorator'
import LogoSpinner from '@/components/shared/logo-spinner.vue'

@Component({
  components: { LogoSpinner }
})
export default class MatchRowsProcessing extends Vue {}
