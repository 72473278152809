import { DATASET_WIZARD_STEP_TAGS } from '@/components/analytics/dataset-wizard/constants/steps'
import { getters } from './tags-tab.getters'
import { actions } from './tags-tab.actions'

const state = {
  stepId: DATASET_WIZARD_STEP_TAGS
}

export default {
  state: state,
  getters,
  actions,
  mutations: {}
}
