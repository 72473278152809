import { i18n } from '@/locales/i18n'
import { isNumberColumn } from '@/types/guards'
import { FillType } from '@/types/visualization/layer/enums'
import { DataColumn } from '@/types/common'

export type FillTypeConfig = {
  text: string,
  supportSublayers: boolean,
  testColumn?: (column: DataColumn) => boolean,
  requiresDataset?: boolean,
  quantiles?: number[]
}

export type FillModes = Record<FillType, FillTypeConfig>

export const FILL_MODE_CONFIGS: FillModes = {
  [FillType.Color]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.single-color') as string },
    supportSublayers: false
  },
  [FillType.Gradient]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.gradient') as string },
    testColumn: isNumberColumn,
    supportSublayers: false
  },
  [FillType.Median]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.median') as string },
    testColumn: isNumberColumn,
    quantiles: [0.5],
    supportSublayers: true
  },
  [FillType.Quartile]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.quartile') as string },
    testColumn: isNumberColumn,
    quantiles: [0.25, 0.5, 0.75],
    supportSublayers: true
  },
  [FillType.Decile]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.decile') as string },
    testColumn: isNumberColumn,
    quantiles: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9],
    supportSublayers: true
  },
  [FillType.Heatmap]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.heatmap') as string },
    testColumn: isNumberColumn,
    supportSublayers: false
  },
  [FillType.Clustering]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.clustering') as string },
    testColumn: () => true,
    requiresDataset: true,
    supportSublayers: true
  },
  [FillType.Icon]: {
    get text () { return i18n.t('visualization.layer-settings-modal.labels.icon') as string },
    testColumn: () => true,
    requiresDataset: true,
    supportSublayers: true
  },
  [FillType.Range]: {
    get text () { return i18n.t('common.labels.range') as string },
    testColumn: isNumberColumn,
    supportSublayers: true
  }
}
