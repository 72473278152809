import { Store } from '@/store/store'
import { LOAD_CITY_DATASET } from '@/store/action-types'
import { DatasetRow, GeometryBase } from '@/types/common'
import { CITY_DATASET } from '@/store/getter-types'
import { GeometryCollectionConfig } from '@/utils/filter-editor/value-config/collection/collection-config'
import { BEZIRK_COLUMN, BUNDESLAND_COLUMN, CITY_FIELD, LANDKREIS_COLUMN, NIELSEN_COLUMN, OKZ_COLUMN, POPULATION_COLUMN } from '@/constants/unit/fields'
import { ColDef } from 'ag-grid-community'
import { configureColumnDefs } from '@/constants/unit/utils'

const citiesGridColumnsOrder = [CITY_FIELD, POPULATION_COLUMN, OKZ_COLUMN, BUNDESLAND_COLUMN, LANDKREIS_COLUMN, BEZIRK_COLUMN, NIELSEN_COLUMN]

export class CityNamesConfig extends GeometryCollectionConfig {
  async initCollection (): Promise<void> {
    return Store.dispatch(LOAD_CITY_DATASET)
  }

  get options (): DatasetRow[] {
    return Store.getters[CITY_DATASET]?.datasetRows ?? []
  }

  get columnDefs (): ColDef[] {
    const columnInfos = Store.getters[CITY_DATASET]?.columnInfos ?? []
    const columns = columnInfos.map(column => column.name)

    return configureColumnDefs(columns, citiesGridColumnsOrder, columnInfos)
  }

  override formatValue (values: GeometryBase[]): string {
    const cityRows = Store.getters[CITY_DATASET]?.datasetRows ?? []

    const findCityNameByOKZ = (okz: string) => {
      const matchingRow = cityRows.find(row => row[OKZ_COLUMN] === okz)
      return matchingRow ? matchingRow[CITY_FIELD] : ''
    }

    return values
      .map(g => `${g.name} - ${findCityNameByOKZ(g.name)}`)
      .join('; ')
  }
}
