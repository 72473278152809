

















import { Component, Mixins } from 'vue-property-decorator'
import { FileMixin } from '@/mixins/file.mixin'
import { acceptFileFilters, EXCEL_FILE_TYPES, readExcelFile } from '@/utils/file'
import FileUpload from '@/components/file-upload/file-upload.vue'
import { ExcelRow } from '@/types/common'

@Component({
  components: { FileUpload }
})
export default class TablePicker extends Mixins(FileMixin) {
  fileData: ExcelRow[] | null = null

  data () {
    const fileFilters = acceptFileFilters(EXCEL_FILE_TYPES)

    return { ...fileFilters }
  }

  async importFile (file: File) {
    this.file = file

    try {
      this.fileData = await readExcelFile(file)
    } catch (e) {
      typeof e === 'string' && this.$toastError(e)
    }
  }

  fileUploadCallback () {
    return Promise.resolve(true)
  }
}
