import { Component, Vue } from 'vue-property-decorator'
import { LeafletEventHandlerFnMap } from 'leaflet'
import { ZIndexedLayer } from '@/lib/leaflet/layer/z-indexed-layer'

@Component
export class GroupEventsMixin<TItem, TLayer extends ZIndexedLayer> extends Vue {
  protected makeGroupListeners (mapObject: TLayer, items: TItem[]): void {
    this.hookGroupEvents(mapObject, items)

    const wrapListener = (listener) => {
      return e => {
        const index = mapObject.getPointIndex(e.layerPoint) as number
        return listener({ item: items[index], event: e })
      }
    }

    const listeners: LeafletEventHandlerFnMap = Object.entries(this.$listeners)
      .reduce((events, [event, listener]) => {
        if (Array.isArray(listener)) {
          events[event] = wrapListener((e) => listener.forEach((l) => l(e)))
        } else {
          events[event] = wrapListener(listener)
        }

        return events
      }, {})

    mapObject.on(listeners)
  }

  protected getGroupEventsToHook (): string[] {
    return ['group-hook.contextmenu.callback']
  }

  protected hookGroupEvents (mapObject: TLayer, items: TItem[]): void {
    const hooks: LeafletEventHandlerFnMap = this.getGroupEventsToHook()
      .reduce((events, eventName) => {
        events[eventName] = e => {
          e.hookSubject.item = items[e.groupIndex]
        }

        return events
      }, {})

    mapObject.on(hooks)
  }
}
