/**
 * Prompt user to save blob content to file.
 *
 * @param {Blob} blob
 * @param {string} filename
 */
export function saveBlob (blob, filename) {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.style.display = 'none'

  const url = URL.createObjectURL(blob)

  link.download = filename
  link.href = url
  link.click()

  setTimeout(function () {
    URL.revokeObjectURL(url)
    document.body.removeChild(link)
  }, 100)
}

/**
 * Prompt user to save canvas element as an image.
 *
 * @param {HTMLCanvasElement} canvas
 * @param {string} filename
 */
export function saveCanvas (canvas: HTMLCanvasElement, filename: string) {
  canvas.toBlob(blob => saveBlob(blob, filename), 'image/png')
}

/**
 * Prompt user to save image.
 *
 * @param {Image} image
 * @param {string} filename
 */
export function saveImage (image: HTMLImageElement, filename: string) {
  const canvas: HTMLCanvasElement = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
  ctx.drawImage(image, 0, 0)

  saveCanvas(canvas, filename)
}

/**
 * Prompt user to save image using data url.
 *
 * @param {string} imageData
 * @param {string} filename
 */
export function saveImageData (imageData: string, filename: string) {
  const img: HTMLImageElement = new Image()

  img.addEventListener('load', () => {
    saveImage(img, filename)
  })

  img.src = imageData
}
