import { i18n } from '@/locales/i18n'
import {
  VICINITY_IMPORT_WIZARD_POINTS_CONTENT_TYPE,
  VICINITY_IMPORT_WIZARD_POINTS_GEOCODING_COLUMNS,
  VICINITY_IMPORT_WIZARD_POINTS_GEOCODING_FINISHED,
  VICINITY_IMPORT_WIZARD_POINTS_LATITUDE_COLUMN,
  VICINITY_IMPORT_WIZARD_POINTS_LONGITUDE_COLUMN,
  VICINITY_IMPORT_WIZARD_POINTS_LATLNG_TABLE,
  VICINITY_IMPORT_WIZARD_POINTS_NAME_COLUMN
} from '@/store/getter-types'
import {
  VICINITY_IMPORT_WIZARD_POINTS_CHANGE_CONTENT_TYPE,
  VICINITY_IMPORT_WIZARD_POINTS_HANDLE_RESULTS,
  VICINITY_IMPORT_WIZARD_POINTS_RESET,
  VICINITY_IMPORT_WIZARD_POINTS_VALIDATE,
  VICINITY_IMPORT_WIZARD_POINTS_RESET_GEOCODE_RESULTS
} from '@/store/action-types'
import {
  VICINITY_IMPORT_WIZARD_POINTS_SET_CONTENT_TYPE,
  VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_COLUMNS,
  VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_STATE,
  VICINITY_IMPORT_WIZARD_POINTS_SET_COORDINATE_COLUMNS,
  VICINITY_IMPORT_WIZARD_POINTS_SET_LATLNG_TABLE,
  VICINITY_IMPORT_WIZARD_POINTS_SET_NAME_COLUMN
} from '@/store/mutation-types'
import { ADDRESSES_CONTENT_TYPE, POINTS_CONTENT_TYPE } from '@/store/layer-wizard/constants/points-content-types'

const DEFAULT_CONTENT_TYPE = POINTS_CONTENT_TYPE

// initial state
const state = {
  geocodingColumns: [],
  coordinateColumns: null,
  geocodingFinished: true,
  contentType: DEFAULT_CONTENT_TYPE,
  latLngTable: null,
  nameColumn: null
}

// getters
const getters = {
  [VICINITY_IMPORT_WIZARD_POINTS_LATITUDE_COLUMN] (state) {
    return state.coordinateColumns ? state.coordinateColumns.latitudeColumn : null
  },
  [VICINITY_IMPORT_WIZARD_POINTS_LONGITUDE_COLUMN] (state) {
    return state.coordinateColumns ? state.coordinateColumns.longitudeColumn : null
  },
  [VICINITY_IMPORT_WIZARD_POINTS_GEOCODING_COLUMNS] (state) {
    return state.geocodingColumns
  },
  [VICINITY_IMPORT_WIZARD_POINTS_GEOCODING_FINISHED] (state) {
    return state.geocodingFinished
  },
  [VICINITY_IMPORT_WIZARD_POINTS_CONTENT_TYPE] (state) {
    return state.contentType
  },
  [VICINITY_IMPORT_WIZARD_POINTS_LATLNG_TABLE] (state) {
    return state.latLngTable
  },
  [VICINITY_IMPORT_WIZARD_POINTS_NAME_COLUMN] (state) {
    return state.nameColumn
  }
}

// mutations
const mutations = {
  [VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_STATE] (state, finished) {
    state.geocodingFinished = finished
  },
  [VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_COLUMNS] (state, columns) {
    state.geocodingColumns = columns
  },
  [VICINITY_IMPORT_WIZARD_POINTS_SET_CONTENT_TYPE] (state, contentType) {
    state.contentType = contentType
  },
  [VICINITY_IMPORT_WIZARD_POINTS_SET_COORDINATE_COLUMNS] (state, columns) {
    state.coordinateColumns = columns
  },
  [VICINITY_IMPORT_WIZARD_POINTS_SET_LATLNG_TABLE] (state, table) {
    state.latLngTable = table
  },
  [VICINITY_IMPORT_WIZARD_POINTS_SET_NAME_COLUMN] (state, column) {
    state.nameColumn = column
  }
}

// actions
const actions = {
  [VICINITY_IMPORT_WIZARD_POINTS_HANDLE_RESULTS] ({ commit, getters }, { coordinateColumns, rowData, selectedColumns }) {
    if (getters[VICINITY_IMPORT_WIZARD_POINTS_CONTENT_TYPE] === ADDRESSES_CONTENT_TYPE) {
      commit(VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_STATE, true)
    }

    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_COLUMNS, selectedColumns)
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_COORDINATE_COLUMNS, coordinateColumns)
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_LATLNG_TABLE, rowData)
  },
  [VICINITY_IMPORT_WIZARD_POINTS_VALIDATE] ({ getters }) {
    const errors = []
    const contentType = getters[VICINITY_IMPORT_WIZARD_POINTS_CONTENT_TYPE]

    if (!getters[VICINITY_IMPORT_WIZARD_POINTS_NAME_COLUMN]) {
      errors.push(i18n.t('planning.vicinity-import.errors.select-name-column'))
    }

    switch (contentType) {
      case POINTS_CONTENT_TYPE:
        if (!getters[VICINITY_IMPORT_WIZARD_POINTS_LATITUDE_COLUMN] || !getters[VICINITY_IMPORT_WIZARD_POINTS_LONGITUDE_COLUMN]) {
          errors.push(i18n.t('visualization.new-layer-modal.errors.no-latitude-longitude-selected'))
        }
        break
      case ADDRESSES_CONTENT_TYPE:
        if (!getters[VICINITY_IMPORT_WIZARD_POINTS_GEOCODING_FINISHED]) {
          errors.push(i18n.t('visualization.new-layer-modal.errors.addresses-not-geocoded'))
        }
        break
    }

    return Promise.resolve(errors)
  },
  async [VICINITY_IMPORT_WIZARD_POINTS_CHANGE_CONTENT_TYPE] ({ commit, dispatch }, contentType) {
    await dispatch(VICINITY_IMPORT_WIZARD_POINTS_RESET)
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_CONTENT_TYPE, contentType)
  },
  async [VICINITY_IMPORT_WIZARD_POINTS_RESET] ({ commit, dispatch }) {
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_CONTENT_TYPE, DEFAULT_CONTENT_TYPE)
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_COLUMNS, [])
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_NAME_COLUMN, null)
    await dispatch(VICINITY_IMPORT_WIZARD_POINTS_RESET_GEOCODE_RESULTS)
  },
  [VICINITY_IMPORT_WIZARD_POINTS_RESET_GEOCODE_RESULTS] ({ commit }) {
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_STATE, false)
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_COORDINATE_COLUMNS, null)
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_NAME_COLUMN, null)
    commit(VICINITY_IMPORT_WIZARD_POINTS_SET_LATLNG_TABLE, null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
