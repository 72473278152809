











import { Component, Mixins } from 'vue-property-decorator'
import { SelectionChangedEvent } from 'ag-grid-community'
import { GridChildMixin } from '../../mixins/grid-child.mixin'

@Component
export default class GridActionRemove extends Mixins(GridChildMixin) {
  private selectedRowsCount = 0

  mounted () {
    this.onGridApi('selectionChanged', this.onSelectionChanged)
  }

  private onSelectionChanged (event: SelectionChangedEvent): void {
    this.selectedRowsCount = event.api.getSelectedNodes().length
  }
}
