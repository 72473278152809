


















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { numeric, required } from 'vuelidate/lib/validators'
import { FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import { NumberConfig } from '@/utils/filter-editor/value-config/simple/number-config'
import { Validations } from '@/decorators'
import { UiControlMixin } from './mixins/ui-control.mixin'
import { RuleDecl } from '@/vendor/types/vuelidate'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
@Component({
  components: { FilterParamName }
})
export default class NumberUiElement extends Mixins<UiControlMixin<number>>(UiControlMixin)
  implements FilterUIComponent<number> {
  @Prop({ required: true }) readonly declare value: number
  @Prop({ required: true }) readonly declare config: NumberConfig

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: { required, numeric }
    }
  }

  @Watch('selected')
  private onSelectedChange (newValue: number | unknown): void {
    // Enforce valid number if input is cleared for example.
    if (typeof newValue !== 'number') {
      this.selected = this.config.defaultValue
      return
    }

    this.$emit('change', this.selected)
  }
}
