import { shuffle } from 'lodash'

export const ASSIGN_BY_PREFERENCE = 'By preference'
export const ASSIGN_RANDOMLY = 'Randomly'

export const assignmentOptions = {
  [ASSIGN_BY_PREFERENCE]: {
    label: ASSIGN_BY_PREFERENCE,
    callback: (order) => order
  },
  [ASSIGN_RANDOMLY]: {
    label: ASSIGN_RANDOMLY,
    callback: (order) => shuffle(order)
  }
}
