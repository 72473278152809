import { CLEAR_USER_DATA } from '@/store/mutation-types'
import { CLEAR_AUTH_TOKEN } from '@/store/auth/auth-mutations'

const authPlugin = store => {
  store.subscribe(mutation => {
    if (mutation.type === CLEAR_AUTH_TOKEN) {
      store.commit(CLEAR_USER_DATA)
    }
  })
}

export default authPlugin
