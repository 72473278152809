import {
  ADD_VISUALIZATION_LAYER,
  ADD_VISUALIZATION_LOCATION,
  REMOVE_VISUALIZATION_LAYER,
  REMOVE_VISUALIZATION_LOCATION,
  REPLACE_VISUALIZATION_LAYER,
  SET_VISUALIZATION_LAYER_NAME,
  SET_VISUALIZATION_LAYER_STYLE,
  SET_VISUALIZATION_LAYERS,
  SET_VISUALIZATION_NAME,
  SET_VISUALIZATION_UPDATED_STATE,
  SET_VISUALIZATION_VALIDATED_STATE,
  UPDATE_VISUALIZATION_LAYER_STYLE
} from '@/store/mutation-types'

import {
  SAVE_VISUALIZATION,
  RESET_VISUALIZATION
} from '@/store/action-types'

const visualizationUpdateMutations = [
  SET_VISUALIZATION_NAME,
  SET_VISUALIZATION_LAYER_STYLE,
  UPDATE_VISUALIZATION_LAYER_STYLE,
  SET_VISUALIZATION_LAYER_NAME,
  ADD_VISUALIZATION_LAYER,
  REMOVE_VISUALIZATION_LAYER,
  REPLACE_VISUALIZATION_LAYER,
  ADD_VISUALIZATION_LOCATION,
  REMOVE_VISUALIZATION_LOCATION
]

const visualizationSaveMutations = [
  SET_VISUALIZATION_LAYERS
]

const visualizationSaveAndResetActions = [
  SAVE_VISUALIZATION,
  RESET_VISUALIZATION
]

export default store => {
  store.subscribe(mutation => {
    // If current visualization is removed clear all it's data.
    if (visualizationUpdateMutations.includes(mutation.type)) {
      store.commit(SET_VISUALIZATION_UPDATED_STATE, true)
    }

    if (visualizationSaveMutations.includes(mutation.type)) {
      store.commit(SET_VISUALIZATION_VALIDATED_STATE, false)
      store.commit(SET_VISUALIZATION_UPDATED_STATE, false)
    }
  })

  store.subscribeAction({
    after: (action) => {
      if (visualizationSaveAndResetActions.includes(action.type)) {
        store.commit(SET_VISUALIZATION_UPDATED_STATE, false)
        store.commit(SET_VISUALIZATION_VALIDATED_STATE, false)
      }
    }
  })
}
