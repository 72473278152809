import {
  DATASET_WIZARD_TAB_SET_DATASET_IS_ENABLED,
  DATASET_WIZARD_TAB_SET_DATASET_IS_EMPTY,
  DATASET_WIZARD_DATASET_TYPE,
  DATASET_WIZARD_TAB_SET_DATASET_IS_INVALID,
  DATASET_WIZARD_TAB_SET_DATASET_ERRORS,
  DATASET_WIZARD_TAB_SET_DATASET_STEP_ID,
  DATASET_WIZARD_DATASET
} from '@/store/getter-types'
import { DATASET_WIZARD_TAB_SET_DATASET_VALIDATE, DATASET_WIZARD_TAB_SET_DATASET_LEAVE } from '@/store/action-types'
import { DATASET_WIZARD_STEP_SET_DATASET } from '@/components/analytics/dataset-wizard/constants/steps'
import { i18n } from '@/locales/i18n'

// initial state
const state = {
  stepId: DATASET_WIZARD_STEP_SET_DATASET
}

// getters
const getters = {
  [DATASET_WIZARD_TAB_SET_DATASET_IS_ENABLED] () {
    return true
  },
  [DATASET_WIZARD_TAB_SET_DATASET_IS_EMPTY] (_state, getters) {
    return getters[DATASET_WIZARD_DATASET_TYPE] == null
  },
  [DATASET_WIZARD_TAB_SET_DATASET_IS_INVALID] (_state, getters) {
    return getters[DATASET_WIZARD_TAB_SET_DATASET_ERRORS].length > 0
  },
  [DATASET_WIZARD_TAB_SET_DATASET_ERRORS] (_state, getters) {
    const errors: string[] = []

    if (!getters[DATASET_WIZARD_DATASET]) {
      errors.push(i18n.t('analytics.new-dataset-wizard.errors.upload-dataset') as string)
    }

    return errors
  },
  [DATASET_WIZARD_TAB_SET_DATASET_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [DATASET_WIZARD_TAB_SET_DATASET_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[DATASET_WIZARD_TAB_SET_DATASET_ERRORS])
  },
  [DATASET_WIZARD_TAB_SET_DATASET_LEAVE] () {
    // leave action
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
