import { PluginObject } from 'vue'
import { ModalDialog } from './modal-dialog'

declare module 'vue/types/vue' {
  interface Vue {
    readonly $modalDialog: ModalDialog;
  }
}

export class ModalDialogPlugin implements PluginObject<never> {
  install (Vue): void {
    Object.defineProperty(Vue.prototype, '$modalDialog', {
      get (): ModalDialog {
        if (!this._$modalDialog) {
          this._$modalDialog = new ModalDialog(this)
        }
        return this._$modalDialog
      }
    })
  }
}
