import Vue, { VNodeChildren } from 'vue'
import { MsgBoxProps, MsgBoxResolver, MsgBoxSlots } from '../types'
import { makeMsgBox } from './msg-box'

export type ConfirmResult = boolean | null

const confirmResolver: MsgBoxResolver<ConfirmResult> = function (bvModalEvt) {
  const trigger = bvModalEvt.trigger
  return trigger === 'ok' ? true : trigger === 'cancel' ? false : null
}

export function msgBoxConfirm (
  parent: Vue,
  message: VNodeChildren,
  props: MsgBoxProps = {},
  slots: MsgBoxSlots = {}
): Promise<ConfirmResult> {
  // Set the modal props we support from options
  const msgBoxProps = {
    ...props,
    // Add in overrides and our content prop
    okOnly: false,
    okDisabled: false,
    cancelDisabled: false,
    hideFooter: false
  }

  return makeMsgBox<ConfirmResult>(parent, message, confirmResolver, msgBoxProps, slots)
}
