import { castArray } from 'lodash'
import { FilterListConfig } from '@/types/planning/wizard/filter-editor'
import { BaseConfig } from '../base-config'

export abstract class ListConfig<T> extends BaseConfig<T[]> implements FilterListConfig<T> {
  protected override _defValue = []

  protected override setDefaultValue (value: T[]): void {
    super.setDefaultValue(castArray(value))
  }

  override formatValue (value: T[]): string {
    return value.length.toString()
  }

  abstract formatList (values: T[]): string[];
}
