import { MarkRequired } from 'ts-essentials'
import { QueryRuleConfig } from '@/types/query-builder/rules'
import { QueryOperator } from '@/types/query-builder/enums'

type RuleTypeConfig = MarkRequired<Pick<QueryRuleConfig, 'operators' | 'default'>, 'operators'>;
type RuleTypes = {
  [key in QueryRuleConfig['type']]: RuleTypeConfig
}

export const ruleTypes: RuleTypes = {
  text: {
    operators: [QueryOperator.Equal],
    default: ''
  },
  tags: {
    operators: [QueryOperator.In],
    default: []
  },
  numeric: {
    operators: [
      QueryOperator.Equal,
      QueryOperator.NotEqual,
      QueryOperator.Less,
      QueryOperator.LessOrEqual,
      QueryOperator.Greater,
      QueryOperator.GreaterOrEqual
    ],
    default: 0
  },
  checkbox: {
    operators: [QueryOperator.In]
  },
  radio: {
    operators: [QueryOperator.Equal]
  },
  select: {
    operators: [QueryOperator.Equal]
  }
}
