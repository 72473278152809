







import { Vue, Component, Prop } from 'vue-property-decorator'
import { BackgroundSizeProperty, StandardLonghandPropertiesHyphen } from 'csstype'
import { ColorFormatHEX } from '@/types/color-data'

/**
   * Get base64 encoded checkboard data.
   *
   * @param {String} color1 hex color
   * @param {String} color2 hex color
   * @param {Number} size
   *
   * @return {String} Base64 encoded checkboard image.
   */
function renderCheckboard (color1: ColorFormatHEX['hex'], color2: ColorFormatHEX['hex'], size: number): string {
  const canvas = document.createElement('canvas')
  canvas.width = canvas.height = size * 2

  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.fillStyle = color1
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = color2
    ctx.fillRect(0, 0, size, size)
    ctx.translate(size, size)
    ctx.fillRect(0, 0, size, size)
  }
  return canvas.toDataURL()
}

const checkboardCache = {}

/**
   * Get checkboard base64 data using cache mechanism.
   *
   * @param {String} color1 hex color
   * @param {String} color2 hex color
   * @param {Number} size
   */
function getCheckboard (color1: ColorFormatHEX['hex'], color2: ColorFormatHEX['hex'], size: number): string {
  const cacheKey = color1 + ',' + color2 + ',' + size

  if (checkboardCache[cacheKey]) {
    return checkboardCache[cacheKey]
  }

  return (checkboardCache[cacheKey] = renderCheckboard(color1, color2, size))
}

@Component
export default class Checkboard extends Vue {
  @Prop({ default: 8 }) size!: number
  @Prop({ default: '#ffffff' }) white!: ColorFormatHEX['hex']
  @Prop({ default: '#e6e6e6' }) grey!: ColorFormatHEX['hex']
  @Prop({ default: 'contain' }) backgroundSize!: BackgroundSizeProperty<string>

  private get bgStyle (): StandardLonghandPropertiesHyphen {
    return {
      'background-image': 'url(' + getCheckboard(this.white, this.grey, this.size) + ')',
      'background-size': this.backgroundSize
    }
  }
}
