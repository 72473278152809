import moment from '@/lib/moment'
import {
  BOOKING_TIME,
  PLAN_WIZARD_CAMPAIGN_NAME,
  PLAN_WIZARD_END_DATE,
  PLAN_WIZARD_IS_NEW_CAMPAIGN,
  PLAN_WIZARD_START_DATE,
  WIZARD_STEP_COMMON_IS_EMPTY,
  WIZARD_STEP_COMMON_NAME_VALID,
  WIZARD_STEP_COMMON_PERIOD_HAS_DECADES,
  WIZARD_STEP_COMMON_PERIOD_IS_SET,
  WIZARD_STEP_COMMON_PERIOD_IS_VALID
} from '@/store/getter-types'
import { isDecade, isWeek } from '@/types/planning/guards'

// initial state
const state = {}

// getters
const getters = {
  [WIZARD_STEP_COMMON_NAME_VALID] (_state, getters): boolean {
    return getters[PLAN_WIZARD_CAMPAIGN_NAME] && getters[PLAN_WIZARD_CAMPAIGN_NAME].trim()
  },
  [WIZARD_STEP_COMMON_PERIOD_IS_SET] (_state, getters): boolean {
    return !!getters[PLAN_WIZARD_START_DATE] && !!getters[PLAN_WIZARD_END_DATE]
  },
  [WIZARD_STEP_COMMON_PERIOD_IS_VALID] (_state, getters): boolean {
    const startDate = getters[PLAN_WIZARD_START_DATE] as Date
    const endDate = getters[PLAN_WIZARD_END_DATE] as Date

    if (!startDate || !endDate || startDate > endDate) {
      return false
    }

    if (getters[PLAN_WIZARD_IS_NEW_CAMPAIGN]) {
      const now = moment()

      return now.isSameOrBefore(startDate, 'day') && now.isSameOrBefore(endDate, 'day')
    }

    return true
  },
  [WIZARD_STEP_COMMON_PERIOD_HAS_DECADES] (_state, getters): boolean {
    const weeks = getters[BOOKING_TIME].filter(isWeek)
    const decades = getters[BOOKING_TIME].filter(isDecade)

    return weeks.length > 0 && decades.length > 0
  },
  [WIZARD_STEP_COMMON_IS_EMPTY] (_state, getters): boolean {
    return !getters[PLAN_WIZARD_CAMPAIGN_NAME] && !getters[PLAN_WIZARD_START_DATE] && !getters[PLAN_WIZARD_END_DATE]
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
