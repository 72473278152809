import { i18n } from '@/locales/i18n'
import {
  PLAN_WIZARD_LINK_NAMESPACE,
  PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE,
  PLAN_WIZARD_SELECTED_RANKINGS,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID,
  WIZARD_STEP_RANKINGS_ALL_NAMES_UNIQUE,
  WIZARD_STEP_RANKINGS_ALL_RANKINGS_VALID,
  WIZARD_TAB_RANKINGS_ERRORS,
  WIZARD_TAB_RANKINGS_IS_EMPTY,
  WIZARD_TAB_RANKINGS_IS_ENABLED,
  WIZARD_TAB_RANKINGS_IS_INVALID
} from '@/store/getter-types'
import {
  WIZARD_TAB_RANKINGS_LEAVE,
  WIZARD_TAB_RANKINGS_VALIDATE
} from '@/store/action-types'
import { WIZARD_STEP_RANKING_FUNCTIONS } from '@/components/planning/wizard/constants/steps'

// initial state
const state = {
  stepId: WIZARD_STEP_RANKING_FUNCTIONS
}

// getters
const getters = {
  [WIZARD_TAB_RANKINGS_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[PLAN_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId) && getters[PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE].length > 0
  },
  [WIZARD_TAB_RANKINGS_IS_EMPTY] (state, getters, rootState, rootGetters) {
    return rootGetters[PLAN_WIZARD_SELECTED_RANKINGS].length === 0
  },
  [WIZARD_TAB_RANKINGS_IS_INVALID] (state, getters) {
    return getters[WIZARD_TAB_RANKINGS_ERRORS].length > 0
  },
  [WIZARD_TAB_RANKINGS_ERRORS] (state, getters, rootState, rootGetters) {
    const errors = []

    if (!rootGetters[WIZARD_STEP_RANKINGS_ALL_RANKINGS_VALID]) {
      errors.push(i18n.t('planning.wizard.errors.rankings-valid'))
    }

    if (!rootGetters[WIZARD_STEP_RANKINGS_ALL_NAMES_UNIQUE]) {
      errors.push(i18n.t('planning.wizard.errors.ranking-unique'))
    }

    return errors
  }
}

// actions
const actions = {
  [WIZARD_TAB_RANKINGS_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[WIZARD_TAB_RANKINGS_ERRORS])
  },
  [WIZARD_TAB_RANKINGS_LEAVE] () {
    return Promise.resolve(true)
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
