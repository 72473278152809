import { MarkerOptions, Point, Bounds, setOptions, LatLng } from 'leaflet'
import { MultiPointsLayer } from '@/lib/leaflet/layer/multi-markers/multi-points-layer'
import { ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

export interface MultiPinMarkerOptions extends ZIndexedPathOptions, MarkerOptions {
  size: number
}

export class MultiPinMarker extends MultiPointsLayer<MultiPinMarkerOptions> {
  public heightRatio = 1.5

  initialize (latlngs: LatLng[], options: ZIndexedPathOptions): void {
    const mergedOptions: MultiPinMarkerOptions = {
      fill: true,
      fillColor: '#3883b7',
      size: 20,
      zIndex: 1,
      ...options
    }

    setOptions(this, mergedOptions)
    this._setLatLngs(latlngs)
  }

  protected override _updateBounds () {
    const w = this._clickTolerance()
    const p = new Point(w, w)

    const markerWidth = this.getMarkerSize()
    const markerHeight = this.getMarkerSize() * this.heightRatio
    const shadowHeight = markerHeight / 8

    const min = p.add([-markerWidth / 2, -markerHeight])
    const max = p.add([markerWidth / 2, shadowHeight])

    if (this._rawPxBounds.min && this._rawPxBounds.max) {
      this._pxBounds = new Bounds([
        this._rawPxBounds.min.add(min),
        this._rawPxBounds.max.add(max)
      ])
    }
  }

  public override getPointIndex (point): number {
    if (!this._pxBounds || !this._pxBounds.contains(point)) {
      return -1
    }

    const tolerance = this._clickTolerance()

    const markerWidth = this.getMarkerSize()
    const markerHeight = this.getMarkerSize() * this.heightRatio
    const shadowHeight = markerHeight / 8

    let resultIndex = -1

    for (let i = 0; i < this._parts.length; i++) {
      const markerPoint = this._parts[i]

      const markerBounds = new Bounds(
        markerPoint.point.add([-markerWidth / 2 - tolerance, -markerHeight - tolerance]),
        markerPoint.point.add([markerWidth / 2 + tolerance, shadowHeight + tolerance])
      )

      // TODO: Point belongs to marker check is inaccurate.
      if (markerBounds.contains(point)) {
        resultIndex = i
      }
    }

    return resultIndex
  }

  _updatePath (): void {
    this._renderer.updateMultiMarker(this)
  }

  public getMarkerSize (): number {
    return this.options.size * 2
  }
}
