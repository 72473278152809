import { CoordinatesServiceResult } from '@/components/shared/coordinates-service/types'
import { LATITUDE_COLUMN, LONGITUDE_COLUMN } from '@/constants/unit/fields'
import { i18n } from '@/locales/i18n'
import {
  DATASET_WIZARD_GEOCODING_HANDLE_RESULTS,
  DATASET_WIZARD_GEOCODING_VALIDATE,
  DATASET_WIZARD_GEOCODING_RESET
} from '@/store/action-types'
import {
  DATASET_WIZARD_GEOCODED_DATA,
  DATASET_WIZARD_GEOCODING_FINISHED,
  DATASET_WIZARD_GEOCODING_SELECTED_COLUMNS,
  DATASET_WIZARD_DATASET
} from '@/store/getter-types'
import {
  DATASET_WIZARD_SET_GEOCODED_DATA,
  DATASET_WIZARD_SET_GEOCODING_FINISHED,
  DATASET_WIZARD_SET_GEOCODING_SELECTED_COLUMNS,
  DATASET_WIZARD_SET_DATASET_UPDATE
} from '@/store/mutation-types'
import { DataColumn } from '@/types/common'
import { DataColumnType } from '@/types/enums'
import { omit } from 'lodash'

const CONFIDENCE_COLUMN = 'Confidence'

const geocodingColumnsInfo: DataColumn[] = [{
  name: LATITUDE_COLUMN,
  type: DataColumnType.Number
}, {
  name: LONGITUDE_COLUMN,
  type: DataColumnType.Number
}, {
  name: CONFIDENCE_COLUMN,
  type: DataColumnType.Text
}]

// initial state
const state = {
  geocodedData: [],
  geocodingFinished: false,
  selectedColumns: []
}

// getters
const getters = {
  [DATASET_WIZARD_GEOCODED_DATA] (state) {
    return state.geocodedData
  },
  [DATASET_WIZARD_GEOCODING_FINISHED] (state) {
    return state.geocodingFinished
  },
  [DATASET_WIZARD_GEOCODING_SELECTED_COLUMNS] (state) {
    return state.selectedColumns
  }
}

// mutations
const mutations = {
  [DATASET_WIZARD_SET_GEOCODED_DATA] (state, data) {
    state.geocodedData = data
  },
  [DATASET_WIZARD_SET_GEOCODING_FINISHED] (state, finished) {
    state.geocodingFinished = finished
  },
  [DATASET_WIZARD_SET_GEOCODING_SELECTED_COLUMNS] (state, columns) {
    state.selectedColumns = columns
  }
}

// actions
const actions = {
  [DATASET_WIZARD_GEOCODING_HANDLE_RESULTS] ({ commit, getters }, result: CoordinatesServiceResult) {
    const { rowData, selectedColumns, coordinateColumns, geocodingData } = result
    commit(DATASET_WIZARD_SET_GEOCODING_FINISHED, true)
    commit(DATASET_WIZARD_SET_GEOCODING_SELECTED_COLUMNS, selectedColumns)
    commit(DATASET_WIZARD_SET_GEOCODED_DATA, rowData)

    const updatedDataset = {
      columnInfos: [...getters[DATASET_WIZARD_DATASET].columnInfos, ...geocodingColumnsInfo],
      datasetRows: rowData.map(row => {
        const queryString = selectedColumns.map(col => row[col]).join(', ')
        const geocoding = geocodingData?.find(data => data.queryString === queryString)

        return {
          ...omit(row, Object.values(coordinateColumns)),
          [CONFIDENCE_COLUMN]: geocoding?.confidence,
          [LATITUDE_COLUMN]: row[coordinateColumns.latitudeColumn],
          [LONGITUDE_COLUMN]: row[coordinateColumns.longitudeColumn]
        }
      })
    }

    commit(DATASET_WIZARD_SET_DATASET_UPDATE, updatedDataset)
  },
  [DATASET_WIZARD_GEOCODING_VALIDATE] ({ getters }) {
    const errors: string[] = []

    if (!getters[DATASET_WIZARD_GEOCODING_FINISHED]) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.addresses-not-geocoded') as string)
    }

    return Promise.resolve(errors)
  },
  [DATASET_WIZARD_GEOCODING_RESET] ({ commit }) {
    commit(DATASET_WIZARD_SET_GEOCODING_FINISHED, false)
    commit(DATASET_WIZARD_SET_GEOCODING_SELECTED_COLUMNS, [])
    commit(DATASET_WIZARD_SET_GEOCODED_DATA, [])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
