import { MarkRequired } from 'ts-essentials'
import { ordersApi } from '@/api/rest/orders/orders.api'
import { planningApi } from '@/api/rest/planning/planning.api'
import { OrderStatus } from '@/types/orders/enums'
import { ObjectId } from '@/types/common'
import { Order, OrderTransitionDto } from '@/types/orders'
import { freezeRecursive } from '@/utils/object'
import {
  SET_ACTIVE_ORDER,
  SET_ACTIVE_ORDER_DETAILS_VISIBLE,
  SET_ACTIVE_ORDER_ITEMS,
  SET_ACTIVE_ORDER_ITEMS_VISIBLE,
  SET_DRAGGED_ORDER,
  SET_ORDERS,
  SET_ORDERS_CACHE,
  UPDATE_ACTIVE_ORDER_DATA
} from '@/store/mutation-types'
import {
  ACTIVE_ORDER,
  ACTIVE_ORDER_ITEMS,
  ALL_ORDERS,
  CUSTOMER_BY_VALUE,
  DRAGGED_ORDER,
  ORDERS_INITIALIZED
} from '@/store/getter-types'
import {
  CONFIRM_ORDER_DROP,
  CREATE_ORDER,
  DECLINE_ORDER_DROP,
  LOAD_ORDERS,
  ORDER_UPDATE_POSITION,
  RESET_ORDER_DETAILS,
  SHOW_ORDER_DETAILS,
  SHOW_ACTIVE_ORDER_ITEMS,
  START_ORDER_DRAG,
  STOP_ORDER_DRAG,
  UPDATE_ACTIVE_ORDER,
  UPDATE_ORDER,
  LOAD_ORDER_BY_ID,
  UPDATE_ORDER_IN_LIST,
  LOAD_CUSTOMERS
} from '@/store/action-types'
import { omit, cloneDeep } from 'lodash'
import Vue from 'vue'

let request: Promise<Order[]> | null = null

export const actions = {
  [LOAD_ORDERS] ({ getters, commit }) {
    if (getters[ORDERS_INITIALIZED]) {
      return getters[ALL_ORDERS]
    }

    if (request) {
      return request
    }

    request = ordersApi.getOrders() as Promise<Order[]>

    return request.then(orders => {
      commit(SET_ORDERS, orders)
      request = null

      return getters[ALL_ORDERS]
    }).catch(() => (request = null))
  },

  // eslint-disable-next-line no-empty-pattern
  [LOAD_ORDER_BY_ID] ({ }, orderId: Order['id']) {
    return ordersApi.getOrder(orderId)
  },

  async [CREATE_ORDER] ({ commit, getters }, planningId: ObjectId) {
    const order = await ordersApi.createOrder(planningId)
    commit(SET_ORDERS, [...getters[ALL_ORDERS], order])
  },

  // eslint-disable-next-line no-empty-pattern
  [UPDATE_ORDER] ({}, { id, ...orderProperties }: MarkRequired<Partial<Order>, 'id'>) {
    return ordersApi.updateOrder({ id, ...orderProperties })
  },

  [ORDER_UPDATE_POSITION] ({ getters, commit }, { newStatus, newIndex = 0 }) {
    const allStatuses = Object.values(OrderStatus)

    const newOrders = allStatuses.reduce((sum, status) => {
      const orderGroup = getters[ALL_ORDERS]
        .filter(order => order.status === status)
        .filter(order => order.id !== getters[DRAGGED_ORDER].id)

      if (newStatus === status) {
        orderGroup.splice(newIndex, 0, { ...getters[DRAGGED_ORDER], status: newStatus })
      }

      return sum.concat(orderGroup)
    }, [])

    commit(SET_ORDERS, newOrders)
  },

  async [CONFIRM_ORDER_DROP] ({ getters, dispatch }) {
    const { id: orderId } = getters[DRAGGED_ORDER]
    const newOrderPosition = getters[ALL_ORDERS].findIndex(order => order.id === orderId)
    const newOrderStatus = getters[ALL_ORDERS][newOrderPosition].status

    const newOrderBeforePosition = newOrderPosition + 1
    const newOrderBefore = getters[ALL_ORDERS][newOrderBeforePosition]

    const orderIdBefore = newOrderBefore && newOrderBefore.status === newOrderStatus ? newOrderBefore.id : ''

    const dto: OrderTransitionDto = {
      orderId,
      newStateName: newOrderStatus,
      orderIdBefore
    }

    return ordersApi.orderStatusUpdate(dto)
      // .then() - add success callback if needed
      .catch((err) => {
        dispatch(DECLINE_ORDER_DROP)
        // let component to catch an error and notify user
        return Promise.reject(err)
      })
  },

  [DECLINE_ORDER_DROP] ({ state, commit }) {
    commit(SET_ORDERS, state.cachedOrders)
  },

  [START_ORDER_DRAG] ({ commit, getters }, order: Order) {
    commit(SET_ORDERS_CACHE, getters[ALL_ORDERS])
    commit(SET_DRAGGED_ORDER, order)
  },

  [STOP_ORDER_DRAG] ({ commit }) {
    commit(SET_DRAGGED_ORDER, null)
    commit(SET_ORDERS_CACHE, [])
  },

  async [SHOW_ORDER_DETAILS] ({ commit, getters, dispatch }, orderId: ObjectId) {
    const orderObj = await dispatch(LOAD_ORDER_BY_ID, orderId)
    commit(SET_ACTIVE_ORDER, orderObj)
    commit(SET_ACTIVE_ORDER_DETAILS_VISIBLE, true)

    await dispatch(LOAD_CUSTOMERS)

    // Asynchronously load additional details.
    await planningApi.getPlan(orderObj.planningId).then((campaign) => {
      commit(UPDATE_ACTIVE_ORDER_DATA, {
        planning: campaign,
        customer: getters[CUSTOMER_BY_VALUE](campaign.metaData.customer)
      })
    })
  },

  async [SHOW_ACTIVE_ORDER_ITEMS] ({ commit, getters }) {
    commit(SET_ACTIVE_ORDER_ITEMS_VISIBLE, true)

    const items = getters[ACTIVE_ORDER_ITEMS]

    if (!items) {
      const order: Order = getters[ACTIVE_ORDER]
      const items = await ordersApi.getOrderItems(order.id)

      commit(SET_ACTIVE_ORDER_ITEMS, freezeRecursive(items))
    }
  },

  [RESET_ORDER_DETAILS] ({ commit }) {
    commit(SET_ACTIVE_ORDER, null)
    commit(SET_ACTIVE_ORDER_ITEMS, null)
  },

  async [UPDATE_ACTIVE_ORDER] ({ commit, dispatch }, order: MarkRequired<Partial<Order>, 'id'>) {
    commit(UPDATE_ACTIVE_ORDER_DATA, order)

    await dispatch(UPDATE_ORDER, { id: order.id, ...omit(order, 'id') })
      .then(() => {
        dispatch(UPDATE_ORDER_IN_LIST, order)
      })
  },

  [UPDATE_ORDER_IN_LIST] ({ commit, getters }, order: MarkRequired<Partial<Order>, 'id'>) {
    const orders: Order[] = cloneDeep(getters[ALL_ORDERS])
    const orderIndex = orders.findIndex(item => item.id === order.id)

    orderIndex !== -1 && (Vue.set(orders, orderIndex, { ...orders[orderIndex], ...order }))

    commit(SET_ORDERS, orders)
  }
}
