var render, staticRenderFns
import script from "./areas-selector.vue?vue&type=script&lang=ts&"
export * from "./areas-selector.vue?vue&type=script&lang=ts&"
import style0 from "./areas-selector.vue?vue&type=style&index=0&id=25e9dfac&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e9dfac",
  null
  
)

export default component.exports