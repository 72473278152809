import {
  VICINITY_IMPORT_WIZARD_VISIBLE,
  VICINITY_IMPORT_WIZARD_DATASET,
  VICINITY_IMPORT_NAMESPACE,
  VICINITY_IMPORT_LINK_NAMESPACE,
  VICINITY_IMPORT_WIZARD_QUERY_DATA,
  VICINITY_IMPORT_FILTERED_DATASET
} from '@/store/getter-types'
import { getQueryFilterFunction } from '@/components/shared/query-builder/filter-func'
import { get } from 'lodash'

export default {
  [VICINITY_IMPORT_WIZARD_VISIBLE] (state) {
    return state.visible
  },
  [VICINITY_IMPORT_WIZARD_DATASET] (state) {
    return state.sourceDataset
  },
  [VICINITY_IMPORT_FILTERED_DATASET] (state, getters) {
    const query = getters[VICINITY_IMPORT_WIZARD_QUERY_DATA]
    const rows = get(getters[VICINITY_IMPORT_WIZARD_DATASET], 'datasetRows', [])

    const queryFilterFunc = getQueryFilterFunction(query)
    return rows.filter(row => queryFilterFunc(row))
  },
  [VICINITY_IMPORT_WIZARD_QUERY_DATA] (state) {
    return state.query
  },
  [VICINITY_IMPORT_NAMESPACE] (state) {
    return state.wizardNamespace
  },
  [VICINITY_IMPORT_LINK_NAMESPACE] (state, getters) {
    return (getter) => {
      return getters[VICINITY_IMPORT_NAMESPACE] + '/' + getter
    }
  }
}
