



















































import { Component, Mixins } from 'vue-property-decorator'
import { ComponentSettingsMixin } from '../../mixins/component-settings.mixin'
import { CoordinatesParseMixin } from '../../mixins/coordinates-parse.mixin'
import CoordinatesParseResults from './coordinates-parse-results.vue'

@Component({
  components: { CoordinatesParseResults }
})
export default class Coordinates extends Mixins<ComponentSettingsMixin, CoordinatesParseMixin>(ComponentSettingsMixin, CoordinatesParseMixin) {
  override resetComponentData () {
    this.resetCoordinates()
  }
}
