import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community'
import {
  GRID_COLUMN_TYPE_LINK,
  GRID_COLUMN_TYPE_STRING
} from '@/components/shared/ag-grid/constants'
import { customValueWrapper } from '@/components/shared/ag-grid/utils'
import {
  FOTO_LINK_COLUMN, GRP_COLUMN, PATH_NAME_COLUMN, LOCATIONS_FIELD, OKZ_COLUMN,
  CITY_FIELD, SITE_TYPE_COUNT_FIELD, POPULATION_COLUMN, BOOKABLE_FROM_COLUMN, BOOKABLE_TO_COLUMN,
  ALLGEMEIN_STELLE_SELECTION_COLUMN, UNIT_TYPE_COLUMN, LONGITUDE_COLUMN, LATITUDE_COLUMN
} from '@/constants/unit/fields'
import { UnitType } from '@/types/planning/enums'

export const ADVANCED_COLUMN_DEFS_MAP: Record<string, ColDef> = {
  [PATH_NAME_COLUMN]: {
    field: PATH_NAME_COLUMN,
    type: GRID_COLUMN_TYPE_STRING,
    cellRendererSelector: (params: ICellRendererParams) => {
      if (params?.data && params.data[UNIT_TYPE_COLUMN] === UnitType.MediaOwnerNetwork) {
        return {
          component: 'agGroupCellRenderer'
        }
      }

      return {
        component: ''
      }
    }
  },
  [FOTO_LINK_COLUMN]: {
    field: FOTO_LINK_COLUMN,
    type: GRID_COLUMN_TYPE_LINK,
    cellRendererParams: {
      label: (params: CellClassParams) => {
        const callback = (params) => params.data.Bezeichnung
        return customValueWrapper(params, callback)
      }
    }
  },
  [GRP_COLUMN]: {
    field: GRP_COLUMN,
    valueGetter: (params: ValueGetterParams) => {
      const callback = params => {
        const value = params.data.GRP

        if (value) {
          return Math.round(value)
        }

        return value
      }

      return customValueWrapper(params, callback)
    }
  },
  [LATITUDE_COLUMN]: {
    field: LATITUDE_COLUMN,
    valueFormatter: (params: ValueFormatterParams) => params.value
  },
  [LONGITUDE_COLUMN]: {
    field: LONGITUDE_COLUMN,
    valueFormatter: (params: ValueFormatterParams) => params.value
  },
  [LOCATIONS_FIELD]: {
    field: LOCATIONS_FIELD,
    valueGetter: (params: ValueGetterParams) => {
      return params.data[LOCATIONS_FIELD]
        .map(location => {
          const line = [OKZ_COLUMN, CITY_FIELD, SITE_TYPE_COUNT_FIELD, POPULATION_COLUMN]
            .map(param => location[param])
            .join(' | ')

          return line.length > 0 ? `(${line})` : ''
        })
        .join(', ')
    },
    hide: true
  },
  [BOOKABLE_FROM_COLUMN]: {
    field: BOOKABLE_FROM_COLUMN,
    hide: true
  },
  [BOOKABLE_TO_COLUMN]: {
    field: BOOKABLE_TO_COLUMN,
    hide: true
  },
  [ALLGEMEIN_STELLE_SELECTION_COLUMN]: {
    field: ALLGEMEIN_STELLE_SELECTION_COLUMN,
    valueGetter: () => ''
  }
}
