import visualizationActions from './visualization-actions/visualization-actions'
import layerActions from './layer-actions/layer-actions'

// actions
const index = {
  ...visualizationActions,
  ...layerActions
}

export default index
