import { PlanningRequestDTO } from '@/types/api'
import { NodesTransitionsGenerator } from './nodes-transitions-generator'
import moment from 'moment'
import { transformTimeFrameEndRequest } from '@/store/planning-wizard/utils'
import { CampaignRequestBody } from '@/types/store/campaign'
import { formatScoringFunc } from '@/utils/planning/parse-planning/format-scoring-func'

export function transformPlanRequestBody (request: CampaignRequestBody): PlanningRequestDTO {
  const requestObj: PlanningRequestDTO = {
    metaData: {
      ...request.metaData,
      planningTimeFrames: request.metaData.planningTimeFrames.map((timeFrame) => ({
        start: moment(timeFrame.start).format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
        end: transformTimeFrameEndRequest(timeFrame.end).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
      })),
      tableViews: JSON.stringify(request.metaData.tableViews)
    },
    name: request.name,
    status: request.status,
    nodes: [],
    transitions: [],
    tags: []
  }

  if (request.id !== undefined) {
    requestObj.id = request.id
  }

  if (request.nodes.length) {
    const formattedRequestObj = formatScoringFunc<CampaignRequestBody>(request)
    const generator = new NodesTransitionsGenerator(formattedRequestObj.nodes)
    const { nodes, transitions } = generator.generate()

    requestObj.nodes = nodes
    requestObj.transitions = transitions
  }

  return requestObj
}

export function transformPlanRequest (request: CampaignRequestBody): string {
  const requestObj = transformPlanRequestBody(request)
  return JSON.stringify(requestObj)
}
