import { Component, Mixins } from 'vue-property-decorator'
import { MapCompProp } from '@/decorators'
import { ItemsGroup, ItemsGroupMapper, MapObjectOptionComputed, MapObjectOptionGetter } from '@/components/map/types'
import { PathsMixin } from './paths.mixin'
import { PathsColorGroupMixin } from './paths-color-group.mixin'
import { MultiMapObjectMixin } from '@/components/map/components/multi-map-object.mixin'
import { MultiPointsLayerOptions } from '@/lib/leaflet/layer/multi-markers/multi-points-layer'
import { get } from 'lodash'

type RadiusPropType<TItem> = MapObjectOptionComputed<TItem, number>
type RadiusGetter<TItem> = MapObjectOptionGetter<TItem, number>

@Component
export class MultiMarkersMixin<TItem, _TLayer, TOptions extends MultiPointsLayerOptions = MultiPointsLayerOptions> extends Mixins<
    // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    PathsMixin<TItem, _TLayer>,
    // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    PathsColorGroupMixin<TItem, _TLayer, TOptions>,
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    MultiMapObjectMixin<TItem, _TLayer, TOptions>
  >(PathsMixin, PathsColorGroupMixin, MultiMapObjectMixin) {
  @MapCompProp({ layerProp: true, default: true }) readonly declare fill: boolean
  @MapCompProp({ layerProp: true, default: 10 }) readonly markerSize!: RadiusPropType<TItem>

  protected override getOptionsToMerge (): TOptions {
    return this.markerOptions
  }

  protected get markerOptions (): TOptions {
    return {
      ...this.pathOptions,
      size: this.markerSize
    } as TOptions
  }

  // @ts-ignore - Overloaded getter property
  protected get groupMapper (): ItemsGroupMapper<TItem, TOptions> {
    const parentMapperGetter = get(PathsColorGroupMixin, 'options.computed.groupMapper.get') as () => ItemsGroupMapper<TItem, TOptions>
    const parentMapper: ItemsGroupMapper<TItem, TOptions> = parentMapperGetter.call(this)

    const markerSizeGetter = this.markerSize
    const getMarkerSize: RadiusGetter<TItem> = typeof markerSizeGetter === 'function'
      ? (item: TItem): number => markerSizeGetter(item)
      : (): number => markerSizeGetter

    return (items: TItem[]): ItemsGroup<TItem, TOptions> => {
      const result = parentMapper(items)

      return {
        items,
        options: {
          ...result.options,
          size: getMarkerSize(items[0])
        }
      }
    }
  }

  protected setRadius (): void {
    // Handled by grouping.
  }
}
