





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { nanoid } from 'nanoid'
import { BvTableVariant } from 'bootstrap-vue'
import { Placement } from '@/types/bootstrap'

@Component
export default class Disclaimer extends Vue {
  @Prop({ default: 'light' }) readonly variant!: BvTableVariant
  @Prop({ default: 'bottom' }) readonly placement!: Placement
  private uid = nanoid(6)
  private buttonId = 'disclaimer_' + this.uid
}
