<template>
  <l-control
    v-if="isShown"
    :position="position"
  >
    <logo-spinner />
  </l-control>
</template>

<script>
import LogoSpinner from '@/components/shared/logo-spinner.vue'

export default {
  name: 'ControlSpinner',
  components: { LogoSpinner },
  props: {
    position: {
      type: String,
      default: 'bottomright'
    },
    isShown: Boolean
  }
}
</script>
