import { DataColumn, NumberColumn, TextColumn } from '@/types/common'
import { DataColumnType } from '@/types/enums'

export function isNumberColumn (column: DataColumn): column is NumberColumn {
  return column.type === DataColumnType.Number
}

export function isTextColumn (column: DataColumn): column is TextColumn {
  return column.type === DataColumnType.Text
}
