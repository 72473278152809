import { ObjectTag, ObjectTagKey } from '@/types/common'

export const TAG_KEY_GRANULARITY: ObjectTagKey = 'granularity'
export const TAG_KEY_FUNCTION: ObjectTagKey = 'function'
export const TAG_KEY_ACCESS: ObjectTagKey = 'Access'
export const TAG_KEY_TYPE: ObjectTagKey = 'Type'
export const TAG_KEY_YEAR: ObjectTagKey = 'Year'
export const TAG_KEY_SOURCE: ObjectTagKey = 'Source'

export const TAG_OBJ_ACCESS_GLOBAL: ObjectTag = { key: TAG_KEY_ACCESS, value: 'Global' }
export const TAG_OBJ_GRANULARITY_DE: ObjectTag = { key: 'CountrySubdivision', value: 'de' }
