import { Component, Vue } from 'vue-property-decorator'

@Component
export class UploadFileMixin extends Vue {
  progressValue = -1

  uploadProgress (value: number): void {
    this.progressValue = value
  }
}
