import { i18n } from '@/locales/i18n'
import { LayerType } from '@/types/visualization/layer/enums'

export const LAYER_SETTINGS_OPEN = 'layer-settings::open'

export const MAP_LAYER_TYPES = [LayerType.Shapes, LayerType.Circles, LayerType.Points]

export const LAYER_TYPE_GROUPS = [
  {
    id: 'overlay-group',
    get name () { return i18n.t('visualization.layer-settings-modal.labels.overlays') },
    order: 1,
    iconClass: 'fas fa-layer-group',
    test: () => false
  },
  {
    id: 'map-group',
    get name () { return i18n.t('visualization.layer-settings-modal.labels.geometries') },
    order: 2,
    iconClass: 'fas fa-map-marked-alt',
    test: layer => MAP_LAYER_TYPES.includes(layer.type)
  }
]

export const LAYER_ICON_SIZE = 60
