import { AclRule } from '@/types/auth'
import { AuthRole } from '@/types/auth/roles'
import { AuthPermission } from '@/types/auth/permissions'
import { everyRule, hasAnyRole } from '../utils/rule-builders'
import { isDataObjectOwner } from '../utils/rules'

const canCreateVisualization: AclRule = hasAnyRole(AuthRole.Visualization)

const canViewVisualization: AclRule = hasAnyRole(
  AuthRole.Visualization,
  AuthRole.VisualizationReadonly
)

const canEditVisualization: AclRule = everyRule(
  hasAnyRole(AuthRole.Visualization),
  isDataObjectOwner
)

export const visualizationRules = {
  [AuthPermission.CreateVisualization]: canCreateVisualization,
  [AuthPermission.ViewVisualization]: canViewVisualization,
  [AuthPermission.EditVisualization]: canEditVisualization,
  [AuthPermission.DeleteVisualization]: canEditVisualization
}
