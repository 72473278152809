import { CANCEL_CALLBACKS, HAS_ACTIVE_REQUEST } from '@/store/getter-types'
import {
  START_REQUEST,
  STOP_REQUEST,
  RESET_REQUESTS,
  ADD_CANCEL_CALLBACK,
  CLEAR_CANCEL_CALLBACK,
  REMOVE_CANCEL_CALLBACK
} from '@/store/mutation-types'
import Vue from 'vue'

const state = {
  requestsArePending: 0,
  cancelCallbacks: {}
}

const getters = {
  [HAS_ACTIVE_REQUEST] (state) {
    return state.requestsArePending > 0
  },
  [CANCEL_CALLBACKS] (state) {
    return state.cancelCallbacks
  }
}

const mutations = {
  [START_REQUEST] (state) {
    state.requestsArePending++
  },
  [STOP_REQUEST] (state) {
    state.requestsArePending--
  },
  [RESET_REQUESTS] (state) {
    state.requestsArePending = 0
  },
  [ADD_CANCEL_CALLBACK] (state, payload) {
    state.cancelCallbacks = { ...state.cancelCallbacks, ...payload }
  },
  [REMOVE_CANCEL_CALLBACK] (state, requestId) {
    Vue.delete(state.cancelCallbacks, requestId)
  },
  [CLEAR_CANCEL_CALLBACK] (state) {
    state.cancelCallbacks = {}
  }
}

export default {
  state,
  getters,
  mutations
}
