import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { freezeRecursive } from '@/utils/object'
import {
  DATASET_WIZARD_RESET,
  DATASET_WIZARD_RESET_DATA,
  DATASET_WIZARD_SHOW,
  DATASET_WIZARD_CHANGE_DATASET,
  DATASET_WIZARD_CREATE_DATASET,
  DATASET_WIZARD_GEOCODING_RESET
} from '@/store/action-types'
import {
  DATASET_WIZARD_SET_ENTRYPOINT,
  DATASET_WIZARD_SET_DATASET_NAME,
  DATASET_WIZARD_SET_DATASET,
  DATASET_WIZARD_SET_VISIBLE,
  DATASET_WIZARD_SET_DATASET_TYPE
} from '@/store/mutation-types'
import {
  DATASET_WIZARD_DATASET_UPDATE,
  DATASET_WIZARD_DATASET_NAME
} from '@/store/getter-types'
import { formatDatasetRows } from '@/utils/parse-dataset-rows'
import { TAGS_EDITOR_GET_OBJECT_TAGS } from '@/modules/analytics/analytics-tags-editor/_store/tags-editor/tags-editor.getters'
import { ObjectTag } from '@/types/common'

export default {
  [DATASET_WIZARD_RESET] ({ commit, dispatch }) {
    commit(DATASET_WIZARD_SET_ENTRYPOINT, null)

    commit(DATASET_WIZARD_SET_DATASET_TYPE, null)
    dispatch(DATASET_WIZARD_RESET_DATA)
    dispatch(DATASET_WIZARD_GEOCODING_RESET)
  },
  [DATASET_WIZARD_RESET_DATA] ({ commit }) {
    commit(DATASET_WIZARD_SET_DATASET_NAME, null)
    commit(DATASET_WIZARD_SET_DATASET, null)
  },
  [DATASET_WIZARD_SHOW] ({ commit }) {
    commit(DATASET_WIZARD_SET_VISIBLE, true)
  },
  [DATASET_WIZARD_CHANGE_DATASET] ({ commit }, dataset) {
    if (dataset && dataset.datasetRows) {
      freezeRecursive(dataset.datasetRows)
    }

    commit(DATASET_WIZARD_SET_DATASET, dataset)
  },
  async [DATASET_WIZARD_CREATE_DATASET] ({ getters }) {
    const updatedDataset = getters[DATASET_WIZARD_DATASET_UPDATE]
    const datasetName = getters[DATASET_WIZARD_DATASET_NAME]
    const tags = getters[TAGS_EDITOR_GET_OBJECT_TAGS] as ObjectTag[]

    const formattedDataset = formatDatasetRows(updatedDataset)

    const requestBody = {
      ...formattedDataset,
      name: datasetName.trim(),
      tags
    }

    return await dataDeliveryApi.createDataset(requestBody)
  }
}
