import { Component, Vue } from 'vue-property-decorator'
import { Geometries, Geometry } from '@/types/common'
import { LatLngExpression } from 'leaflet'
import { flatten } from 'lodash'
import { SearchType } from '@/types/api/data-delivery'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { GeometryCore } from '@/utils/geometry-core'
import { mapActions } from 'vuex'
import { LOAD_GEOMETRIES_COORDS } from '@/store/action-types'

@Component({
  methods: {
    ...mapActions({
      loadGeometriesCoords: LOAD_GEOMETRIES_COORDS
    })
  }
})
export class SearchAreasMixin extends Vue {
  // mapActions
  private loadGeometriesCoords!: (geometries: Partial<Geometry>[]) => Promise<Geometries>
  private geometryCore = new GeometryCore()

  protected async getCoordinatesByAreaId (id: Geometry['id']): Promise<LatLngExpression[]> {
    const geometries = this.geometryCore.findGeometry(id)

    if (geometries.length === 0) {
      return Promise.resolve([])
    }

    const geometriesWithCoords = await this.loadGeometriesCoords(geometries) as Geometries
    const coordinates = geometriesWithCoords.map(geometry => {
      return flatten(geometry.paths)
    })

    return Promise.resolve(flatten(coordinates))
  }

  protected async searchGeometriesCallback (query: string) {
    const request = {
      type: 'geometry' as SearchType,
      query: `*${query}*`
    }
    const geometries = await dataDeliveryApi.globalSearch<Geometry>(request)

    this.geometryCore.setGeometries(geometries)

    return this.geometryCore.filteredGeometries
      .map(geometry => ({
        text: geometry.name,
        id: geometry.id,
        attr: geometry.tags.map(tag => tag.value)
      }))
  }
}
