import { NUMBER_OF_SITES_PER_UNIT } from '@/constants/functions/scoring-functions-param-names'
import { SitesPerLocationCollection } from '@/utils/filter-editor/value-config/collection/sites-per-location-collection'
import { ScoringFunctionData } from '@/types/planning/scoring/functions'

export interface MultisiteLocationFilterFormData extends ScoringFunctionData {
  [NUMBER_OF_SITES_PER_UNIT]: string[]
}

export const MultisiteLocationFilterFormConfig = {
  [NUMBER_OF_SITES_PER_UNIT]: new SitesPerLocationCollection(null)
}
