import { Store } from '@/store/store'
import { START_REQUEST, STOP_REQUEST } from '@/store/mutation-types'
import { isApiError } from '@/api/guards'
import { InterceptorsConfig } from './types'
import axios from 'axios'

export const blockingRequestInterceptors: InterceptorsConfig = {
  request: {
    onSuccess: function (config) {
      if (config.blockingRequest) {
        Store.commit(START_REQUEST)
      }

      return config
    }
  },
  response: {
    onSuccess: function (response) {
      if (response.config.blockingRequest) {
        Store.commit(STOP_REQUEST)
      }

      return response
    },
    onReject: function (error) {
      if ((isApiError(error) && error.config.blockingRequest) || axios.isCancel(error)) {
        Store.commit(STOP_REQUEST)
      }

      return Promise.reject(error)
    }
  }
}
