import {
  LAYER_WIZARD_STEP_GEOMETRY_INIT_AREAS_SELECT,
  LAYER_WIZARD_STEP_GEOMETRY_INIT_AREAS_UPLOAD,
  LAYER_WIZARD_STEP_GEOMETRY_INIT_LINKED_DATASET
} from '@/store/action-types'
import { LAYER_WIZARD_SET_ENTRYPOINT } from '@/store/mutation-types'
import {
  ENTRYPOINT_AREA_SELECT,
  ENTRYPOINT_AREA_DATASET,
  ENTRYPOINT_AREA_UPLOAD
} from '../../constants/entrypoints'
import {
  LAYER_WIZARD_ENTRYPOINT_IS_AREA_SELECT,
  LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD,
  LAYER_WIZARD_ENTRYPOINT_IS_LINKED_DATASET,
  LAYER_WIZARD_STEP_GEOMETRY_IS_VALID
} from '@/store/getter-types'

// initial state
const state = {}

// getters
const getters = {
  [LAYER_WIZARD_STEP_GEOMETRY_IS_VALID] (state, getters) {
    const entryPoints = [LAYER_WIZARD_ENTRYPOINT_IS_AREA_SELECT, LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD, LAYER_WIZARD_ENTRYPOINT_IS_LINKED_DATASET]
    return entryPoints.some(entrypoint => !!getters[entrypoint])
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [LAYER_WIZARD_STEP_GEOMETRY_INIT_LINKED_DATASET] ({ commit }) {
    commit(LAYER_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_AREA_DATASET)
  },
  [LAYER_WIZARD_STEP_GEOMETRY_INIT_AREAS_SELECT] ({ commit }) {
    commit(LAYER_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_AREA_SELECT)
  },
  [LAYER_WIZARD_STEP_GEOMETRY_INIT_AREAS_UPLOAD] ({ commit }) {
    commit(LAYER_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_AREA_UPLOAD)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
