











































































import { mapActions } from 'vuex'
import { authApi } from '@/api/rest/auth/auth.api'
import { SET_USER } from '@/store/action-types'
import { Component, Vue } from 'vue-property-decorator'
import { UserToken, UserAuthInfo, UserData } from './types'
import imgLogo from '@/assets/img/logo.svg'
import { setLocale } from '@/locales/i18n'
import { getLocales } from '@/constants/languages'
import { Routes } from '@/router/constants/routes'
import LogoOverlay from '@/components/overlay/logo-overlay.vue'
import { SET_AUTHENTICATION } from '@/store/auth/auth-actions'

@Component({
  components: { LogoOverlay },
  methods: {
    ...mapActions({
      setAuthentication: SET_AUTHENTICATION,
      setUser: SET_USER
    })
  }
})
export default class Login extends Vue {
  // mapActions
  private setAuthentication!: (token: UserToken) => void
  private setUser!: (user: UserAuthInfo) => void

  private authError = false
  private form = {
    username: '',
    password: ''
  }

  private showOverlay = false

  private setLocale = setLocale

  private get localeTitles () {
    return getLocales()
  }

  private get logoImage (): ImageBitmap {
    return imgLogo
  }

  private onSubmit (): void {
    this.showOverlay = true

    authApi.authenticate(this.form.username, this.form.password)
      .then(data => {
        this.setUserInfo(data as UserData)
        this.redirect()
      })
      .catch(() => {
        this.authError = true
      })
      .finally(() => (this.showOverlay = false))
  }

  private setUserInfo (data: UserData): void {
    this.setAuthentication({ token: data.token })

    this.setUser({
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      roles: data.roles
    })
  }

  private redirect (): void {
    if (this.$route.query.return_url) {
      this.$router.push(this.$route.query.return_url as string)
    } else {
      this.$router.push({ name: Routes.HOME })
    }
  }
}
