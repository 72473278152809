import { Vue, Component } from 'vue-property-decorator'
import { FormInputState } from '@/types/bootstrap'

@Component
export class FileMixin extends Vue {
  protected fileSubmitted = false
  protected file: File | null = null
  protected allowedTypes: string[] = []
  protected allowedExtensions: string[] = []

  protected get fileState (): FormInputState {
    return this.fileSubmitted ? this.validateFile() : null
  }

  protected get fileAcceptString (): string {
    return [...this.allowedTypes, ...this.allowedExtensions].join(', ')
  }

  protected get fileExtension (): string {
    if (!this.file) {
      return ''
    }
    return this.file.name.slice((this.file.name.lastIndexOf('.') - 1 >>> 0) + 1)
  }

  protected validateFile (): boolean {
    if (!(this.file instanceof File)) {
      return false
    }

    if (this.file.type) {
      return this.allowedTypes.includes(this.file.type.toLowerCase())
    }

    // Workaround for mime type that browser can't detect.
    // Just fallback to file extension.
    return this.allowedExtensions.includes(this.fileExtension.toLowerCase())
  }

  protected resetFile (): void {
    this.file = null
    this.fileSubmitted = false
  }
}
