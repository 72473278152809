

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LogoSpinner extends Vue {
  @Prop({ default: 60 }) private readonly size!: number

  private get containerStyle () {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`
    }
  }
}
