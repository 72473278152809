














import { Component, Prop, Vue } from 'vue-property-decorator'
import ActionResults from '@/components/shared/action-results/action-results.vue'
@Component({
  components: { ActionResults }
})
export default class ImportAreasResults extends Vue {
  @Prop({ default: 0 }) geometriesCount!: number
  @Prop({ default: 0 }) totalCount!: number

  private get errorsCount (): number {
    return Math.max(this.totalCount - this.geometriesCount, 0)
  }
}
