/// Remarks: The string value assigned to each constant must match the classname of the back-end.

export const FILTER_AREA = 'AreaFilter'
export const FILTER_CLUSTERING = 'ClusteringFilter'
export const FILTER_VICINITY = 'VicinityFilter'
export const FILTER_CITY = 'CityFilter'
export const FILTER_MEDIAOWNERNETWORK = 'MediaOwnerNetworkFilter'
export const FILTER_MEDIA_TYPE = 'MediaTypeFilter'
export const FILTER_AVAILABILITY = 'AvailabilityFilter'
export const FILTER_EXCLUSION = 'ExclusionFilter'
export const FILTER_ISOLATION = 'IsolationFilter'
export const FILTER_BANNED_PRODUCTS = 'BannedProductsFilter'
export const FILTER_BUILD_TYPE = 'BuildTypeFilter'
export const FILTER_EXCLUDE_MEDIA_OWNER = 'ExcludeMediaOwnerFilter'
export const FILTER_INCLUDE_MEDIA_OWNER = 'IncludeMediaOwnerFilter'
export const FILTER_ILLUMINATION = 'IlluminationFilter'
export const FILTER_MEDIA_CRITERIA = 'MediaCriteriaFilter'
export const FILTER_PPS = 'PpsFilter'
export const FILTER_MULTISITE_LOCATION = 'MultisiteLocationFilter'
export const FILTER_PRICE = 'PriceFilter'
export const FILTER_CPT = 'CptFilter'
export const FILTER_INCLUSION = 'InclusionFilter'

export const OVERALL_GOAL_BUDGET = 'BudgetOverallGoal'

export const GOAL_BUDGET = 'BudgetGoal'
export const GOAL_GRP = 'GrpGoal'
export const GOAL_QUANTITY = 'QuantityGoal'
export const GOAL_QUOTA = 'QuotaGoal'

export const RANKING_CUSTOM_AREA = 'CustomAreaRanking'
export const RANKING_MEDIAOWNER = 'MediaOwnerRanking'
export const RANKING_PPS = 'PpsRanking'
export const RANKING_CPT = 'CptRanking'
