import { BookingDecade, BookingTimeFrame, BookingWeek } from '@/types/planning/booking-time'
import { BookingTimeFrameGranularity } from '@/types/planning/enums'

export function isDecade (timeFrame: BookingTimeFrame): timeFrame is BookingDecade {
  return timeFrame?.type === BookingTimeFrameGranularity.Decade
}

export function isWeek (timeFrame: BookingTimeFrame): timeFrame is BookingWeek {
  return timeFrame?.type === BookingTimeFrameGranularity.Week
}

export function isPeriodOfAType (decadeTypes: string[]) {
  return (timeFrame: BookingTimeFrame) => {
    return decadeTypes.some(granularity => timeFrame.value.indexOf(granularity) === 0)
  }
}
