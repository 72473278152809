import entrypointTab from './tabs/layer-wizard-tab-entrypoint.store'
import geometryOptionsTab from './tabs/layer-wizard-tab-geometry.store'
import poiDatasetTab from './tabs/layer-wizard-tab-poi-dataset.store'
import areaDatasetTab from './tabs/layer-wizard-tab-area-dataset.store'
import areaLinkTab from './tabs/layer-wizard-tab-area-link.store'
import surroundingTab from './tabs/layer-wizard-tab-surrounding.store'
import areaUploadTab from './tabs/layer-wizard-tab-area-upload.store'
import granularityTab from './tabs/layer-wizard-tab-granularity.store'
import propsTab from './tabs/layer-wizard-tab-props.store'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    entrypointTab,
    geometryOptionsTab,
    poiDatasetTab,
    areaDatasetTab,
    areaLinkTab,
    surroundingTab,
    areaUploadTab,
    granularityTab,
    propsTab
  }
}
