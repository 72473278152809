import { round } from 'lodash'
import { Vue, Component } from 'vue-property-decorator'
import { Color, OpacityProperty } from 'csstype'
import { LayerPolygon } from '@/types/visualization/layer/geometry'
import { LayerColorGetter, LayerOpacityGetter } from '@/types/visualization/layer/style'
import { DatasetColumnKey } from '@/types/common'

@Component
export default class GroupItemsMixin extends Vue {
  protected makeColorGetter<TLayer> (colorValue: LayerColorGetter<TLayer> | Color): LayerColorGetter<TLayer> {
    if (typeof colorValue === 'function') {
      return colorValue
    }

    return () => colorValue
  }

  protected makeOpacityGetter<TLayer> (opacityValue: LayerOpacityGetter<TLayer> | OpacityProperty): LayerOpacityGetter<TLayer> {
    if (typeof opacityValue === 'function') {
      return (item) => {
        return round(opacityValue(item) as number, 2)
      }
    }

    return () => round(opacityValue as number, 2)
  }

  protected makeColorGroupFunc<TLayer> (colorValue: LayerColorGetter<TLayer> | Color, opacityValue: LayerOpacityGetter<TLayer> | OpacityProperty) {
    const getColor = this.makeColorGetter(colorValue)
    const getOpacity = this.makeOpacityGetter(opacityValue)

    return (item: TLayer) => `${getColor(item)}|${getOpacity(item)}`
  }

  protected makeClusterGroupFunc (clusterColumn: DatasetColumnKey) {
    // Group just by cluster key.
    return (item: LayerPolygon) => item.meta[clusterColumn]
  }
}
