import {
  PLAN_WIZARD_SET_MEDIA_NETWORKS
} from '@/store/mutation-types'
import {
  PLAN_WIZARD_END_DATE,
  PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA,
  PLAN_WIZARD_MEDIA_NETWORKS_NEEDS_UPDATE,
  PLAN_WIZARD_SELECTED_LOCATIONS,
  PLAN_WIZARD_SELECTED_TYPES,
  PLAN_WIZARD_START_DATE
} from '@/store/getter-types'
import {
  PLAN_WIZARD_LOAD_NETWORKS_FOR_CITIES,
  PLAN_WIZARD_RELOAD_NETWORKS,
  PLAN_WIZARD_REMOVE_NETWORK_NODES_AFTER_RELOAD,
  WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES
} from '@/store/action-types'
import { get, uniqWith, flatten, isEqual } from 'lodash'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { isNetworkMediaTypeFilterNode } from '@/utils/plan-wizard-steps'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { Dataset, GeometryBase } from '@/types/common'
import { MediaOwnerNetworksCache } from '@/types/planning/wizard/value-types'
import { supportedAreaFiltersForMON, areaFiltersDataPath } from './constant'
import { generateMediaOwnerNetworkFilterName } from '@/utils/planning/media-owner-network/media-owner-networks'
import { MediaOwnerNetworkObj } from '@/types/planning/wizard/filter-editor'

export const actions = {
  async [PLAN_WIZARD_RELOAD_NETWORKS] ({ dispatch, getters }) {
    const citiesLocations: string[][] = getters[PLAN_WIZARD_SELECTED_LOCATIONS]
      .filter(node => supportedAreaFiltersForMON.includes(node.scoringFunction.name))
      .map(node => get(node.scoringFunction.data, areaFiltersDataPath[node.scoringFunction.name], []))

    const allRequiredAreas = uniqWith(flatten(citiesLocations), isEqual)

    if (!getters[PLAN_WIZARD_MEDIA_NETWORKS_NEEDS_UPDATE](allRequiredAreas)) {
      return Promise.resolve()
    }

    await dispatch(PLAN_WIZARD_LOAD_NETWORKS_FOR_CITIES, allRequiredAreas)
    return dispatch(PLAN_WIZARD_REMOVE_NETWORK_NODES_AFTER_RELOAD)
  },
  async [PLAN_WIZARD_REMOVE_NETWORK_NODES_AFTER_RELOAD] ({ dispatch, getters, rootGetters }) {
    const appliedMediaOwnerNetworksNodes: ScoringFunctionNode[] = rootGetters[PLAN_WIZARD_SELECTED_TYPES]
      .filter(isNetworkMediaTypeFilterNode)

    const locations: ScoringFunctionNode[] = getters[PLAN_WIZARD_SELECTED_LOCATIONS]
      .filter((node) => supportedAreaFiltersForMON.includes(node.scoringFunction.name))

    const monNodesToRemove = locations.reduce((result, locationNode) => {
      const dataset: Dataset = getters[PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA](locationNode.id)

      const nodesToRemove = appliedMediaOwnerNetworksNodes
        .filter(networkNode => networkNode.areaId === locationNode.id)
        .filter(networkNode => {
          const relatedNetworks: MediaOwnerNetworkObj[] = get(networkNode.scoringFunction.data, '0.MediaOwnerNetworks', [])
          return relatedNetworks.every(networks => !dataset.datasetRows.some(row => row.ExternalID === networks.id))
        })

      return result.concat(nodesToRemove)
    }, [] as ScoringFunctionNode[])

    return dispatch(WIZARD_STEP_MEDIA_TYPE_REMOVE_TYPES, monNodesToRemove)
  },
  async [PLAN_WIZARD_LOAD_NETWORKS_FOR_CITIES] ({ commit, getters }, areaGeometries: GeometryBase[]) {
    const dateFrom = getters[PLAN_WIZARD_START_DATE]
    const dateTo = getters[PLAN_WIZARD_END_DATE]

    const networksDataset = await dataDeliveryApi.getMediaOwnerNetworkByGeometries(areaGeometries, dateFrom, dateTo)

    const networksCache: MediaOwnerNetworksCache = {
      dateFrom,
      dateTo,
      areaNameList: areaGeometries.map(geometry => geometry.name),
      networks: networksDataset.datasetRows.map((row) => ({
        text: generateMediaOwnerNetworkFilterName(row),
        value: row.ExternalID as string
      })),
      dataset: networksDataset
    }

    commit(PLAN_WIZARD_SET_MEDIA_NETWORKS, networksCache)
  }
}
