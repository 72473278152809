









import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class SaveAs extends Vue {
  @Prop({ required: true }) label!: TranslateResult
  @Prop({ required: true }) placeholder!: TranslateResult
  @Prop({ required: true }) name!: string

  private customName = ''

  created () {
    this.customName = this.name
  }
}
