import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import {
  LOAD_ANALYTICS_DATASET_BY_ID,
  RESET_ANALYTICS_DATASET,
  SAVE_ANALYTICS_DATASET,
  REMOVE_ANALYTICS_DATASET_BY_ID
} from '@/store/action-types'
import {
  ANALYTICS_DATASET,
  ANALYTICS_DATASET_EDITABLE,
  ANALYTICS_DATASET_ID,
  ANALYTICS_DATASET_NAME
} from '@/store/getter-types'
import { SET_ANALYTICS_DATASET } from '@/store/mutation-types'
import { formatDatasetRows } from '@/utils/parse-dataset-rows'
import { Dataset, DatasetRows, ObjectTag } from '@/types/common'

type AnalyticsStore = {
  dataset: Dataset | null
}

const state = {
  dataset: null
}

export const SAVE_DATASET_TAGS = 'analytics/saveDatasetTags'
export const SAVE_ANALYTICS_DATASET_ROWS = 'analytics/saveDatasetRows'

const getters = {
  [ANALYTICS_DATASET] (state: AnalyticsStore) {
    return state.dataset
  },
  [ANALYTICS_DATASET_ID] (state: AnalyticsStore) {
    return state.dataset?.id
  },
  [ANALYTICS_DATASET_NAME] (state: AnalyticsStore) {
    return state.dataset?.name
  },
  [ANALYTICS_DATASET_EDITABLE] (state: AnalyticsStore) {
    return state.dataset?.isEditable ?? false
  }
}

const mutations = {
  [SET_ANALYTICS_DATASET] (state: AnalyticsStore, dataset: Dataset) {
    state.dataset = dataset
  }
}

const actions = {
  async [LOAD_ANALYTICS_DATASET_BY_ID] ({ commit }, id: string) {
    const dataset = await dataDeliveryApi.getDataset(id)
    commit(SET_ANALYTICS_DATASET, dataset)
  },
  async [REMOVE_ANALYTICS_DATASET_BY_ID] (_, id: string): Promise<void> {
    return dataDeliveryApi.removeDataset(id)
  },
  async [SAVE_ANALYTICS_DATASET] ({ commit }, dataset: Dataset): Promise<void> {
    const requestBody = {
      ...formatDatasetRows(dataset),
      ...dataset
    }

    await dataDeliveryApi.updateDataset(requestBody, dataset.id)
    return commit(SET_ANALYTICS_DATASET, dataset)
  },
  async [SAVE_ANALYTICS_DATASET_ROWS] ({ dispatch, getters }, datasetRows: DatasetRows): Promise<void> {
    const dataset = { ...getters[ANALYTICS_DATASET], datasetRows }

    return dispatch(SAVE_ANALYTICS_DATASET, dataset)
  },
  async [SAVE_DATASET_TAGS] ({ dispatch, getters }, newTags: ObjectTag[]): Promise<void> {
    const dataset = { ...getters[ANALYTICS_DATASET], tags: newTags }

    return dispatch(SAVE_ANALYTICS_DATASET, dataset)
  },
  [RESET_ANALYTICS_DATASET] ({ commit }) {
    commit(SET_ANALYTICS_DATASET, null)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
