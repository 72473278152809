import { LoadingOverlayComponent, LoadingService } from '@/plugins/route-loading/type'
import { i18n } from '@/locales/i18n'

const DEFAULT_DELAY = 200

class RouteLoadingService implements LoadingService {
  delay: number
  componentInstance: LoadingOverlayComponent
  loading = false

  constructor (component, delay = DEFAULT_DELAY, Vue) {
    this.delay = delay

    const div = document.createElement('div')
    document.body.appendChild(div)

    const instance = new Vue({
      i18n,
      render: createElement => createElement(component)
    }).$mount(div)

    this.componentInstance = instance._vnode.componentInstance
  }

  start () {
    this.loading = true

    setTimeout(() => {
      if (this.loading) {
        this.componentInstance.showOverlay()
      }
    }, this.delay)
  }

  stop () {
    this.componentInstance.hideOverlay()

    this.loading = false
  }
}

export default RouteLoadingService
