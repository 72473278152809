











import { Component } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import { VICINITY_IMPORT_WIZARD_SHOW } from '@/store/action-types'
import { NamedPoints } from '@/types/common'
import { UPDATE_COORDINATES } from '@/components/planning/wizard/filter-editor/ui-controls/points-list-ui-element/constant'
import GridActionsMixin from '../grid-actions.mixin'

@Component({
  methods: {
    ...mapActions({
      showVicinityImportWizard: VICINITY_IMPORT_WIZARD_SHOW
    })
  }
})
export default class UploadFromFileAction extends GridActionsMixin {
  private showVicinityImportWizard!: () => Promise<void>

  mounted (): void {
    this.$root.$on(UPDATE_COORDINATES, this.addPoints)
  }

  destroyed (): void {
    this.$root.$off(UPDATE_COORDINATES, this.addPoints)
  }

  private addPoints (points: NamedPoints): void {
    this.updateValue([...this.value, ...points])
  }

  private showUploadForm (): void {
    this.showVicinityImportWizard()
  }
}
