import navigationStore from './navigation/navigation.store'
import stepsStore from './steps/dataset-wizard-steps.store'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  visible: false,
  entrypoint: null,
  dataset: null,
  datasetName: null,
  datasetType: null,
  datasetUpdate: null,
  wizardNamespace: 'dataset-wizard'
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    wizardNavigation: navigationStore,
    wizardSteps: stepsStore
  }
}
