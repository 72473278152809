import L from 'leaflet'
import { get, reduce } from 'lodash'
import { ApiResponse } from '@/api/types'
import { ScoringObject } from '@/types/planning'
import { parseStatisticsData } from '@/utils/statistics'
import { createTransformResponse } from '@/api/utils'

export const transformScoringLocationsResponse = createTransformResponse<ScoringObject>((response: ApiResponse['data']): ScoringObject => {
  if (!response) {
    return {} as ScoringObject
  }

  const parsedResponse = JSON.parse(response)

  if (!get(parsedResponse, 'units.0.coordinates', false)) {
    return parsedResponse
  }

  parsedResponse.units = reduce(parsedResponse.units, (sum, unit) => {
    return sum.concat(unit.coordinates.map(coordinate => {
      return {
        ...unit,
        coordinates: undefined,
        latLng: L.latLng(parseFloat(coordinate.latitude), parseFloat(coordinate.longitude))
      }
    }))
  }, [])

  parsedResponse.statistics = parseStatisticsData(parsedResponse.statistics)
  parsedResponse.unClearAssignments = parsedResponse.unClearAreaAssignments

  return parsedResponse
})
