import {
  RANKING_IMPORT_WIZARD_KEY_COLUMNS_PLZ_COLUMN,
  RANKING_IMPORT_WIZARD_KEY_COLUMNS_RATING_COLUMN,
  RANKING_IMPORT_WIZARD_RESULT_TABLE
} from '@/store/ranking-import-wizard/getters'
import { TranslateResult } from 'vue-i18n'
import { i18n } from '@/locales/i18n'
import { ListPair } from '@/types/planning/wizard/value-types'

export const RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_ERRORS = 'ranking-import/key-columns-tab/errors'
export const RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_EMPTY = 'ranking-import/key-columns-tab/isEmpty'
export const RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_ENABLED = 'ranking-import/key-columns-tab/isEnabled'
export const RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_INVALID = 'ranking-import/key-columns-tab/isInvalid'
export const RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_CAN_SKIP = 'ranking-import/key-columns-tab/canSkip'

export const getters = {
  [RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_ENABLED] (): boolean {
    return true
  },
  [RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_EMPTY] (): boolean {
    return false
  },
  [RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_CAN_SKIP] (): boolean {
    return false
  },
  [RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_IS_INVALID] (_state, getters): boolean {
    return getters[RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_ERRORS].length > 0
  },
  [RANKING_IMPORT_WIZARD_TAB_KEY_COLUMNS_ERRORS] (_state, getters): TranslateResult[] {
    const errors: TranslateResult[] = []
    const resultTable = getters[RANKING_IMPORT_WIZARD_RESULT_TABLE] as ListPair[]

    if (!getters[RANKING_IMPORT_WIZARD_KEY_COLUMNS_PLZ_COLUMN]) {
      errors.push(i18n.t('ranking-import.errors.plz-column-not-defined'))
    }

    if (!getters[RANKING_IMPORT_WIZARD_KEY_COLUMNS_RATING_COLUMN]) {
      errors.push(i18n.t('ranking-import.errors.rating-column-not-defined'))
    }

    if (resultTable.some(row => isNaN(parseInt(row.value)))) {
      errors.push(i18n.t('ranking-import.errors.rating-column-wrong-format'))
    }

    if (resultTable.some(row => isNaN(parseInt(row.key)))) {
      errors.push(i18n.t('ranking-import.errors.plz-column-wrong-format'))
    }

    return errors
  }
}
