

























import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { VueDynamicClassDef } from '@/types/vue'
import { round } from 'lodash'
import { nanoid } from 'nanoid'
import { FormInputState } from '@/types/bootstrap'

interface ChangedColorValue {
  [key: string]: string | number
}

@Component
export default class EditableInput extends Vue {
  @Prop({ required: true }) colorProp!: string
  @Prop({ default: null }) label!: string | null
  @Prop({ default: false, type: Boolean }) isNumber!: boolean
  @Prop({ default: '' }) value!: string | number
  @Prop({ default: null }) min!: number | null
  @Prop({ default: null }) max!: number | null
  @Prop({ default: 1 }) arrowOffset!: number
  @Prop({ default: null }) state!: FormInputState

  private uid = nanoid(6)

  private get inputId (): string {
    return `input__${this.uid}`
  }

  private get labelId (): string {
    return `input__label__${this.uid}`
  }

  private get inputClass (): VueDynamicClassDef {
    return {
      'color-picker__input--number': this.isNumber
    }
  }

  private onInputChange (e: number | string): void {
    this.handleChange(e)
  }

  @Emit('change')
  private handleChange (newVal: number | string): ChangedColorValue {
    if (this.isNumber && this.min != null) {
      newVal = Math.max(newVal as number, this.min)
    }

    if (this.isNumber && this.max != null) {
      newVal = Math.min(newVal as number, this.max)
    }

    const data = {
      [this.colorProp]: newVal
    }

    return data
  }

  private handleKeyDown (e: KeyboardEvent): void {
    if (this.isNumber) {
      let val = Number(this.value)
      const amount = this.arrowOffset || 1

      // Up
      if (e.keyCode === 38) {
        val = round(val + amount, 2)
        this.handleChange(val)
        e.preventDefault()
      }

      // Down
      if (e.keyCode === 40) {
        val = round(val - amount, 2)
        this.handleChange(val)
        e.preventDefault()
      }
    }
  }
}
