import { ApiClient } from '@/api/api-client'
import { constantsResponseTransform as transformResponse } from './transform-response/constants-response.transform'
import { siteTypeSubTypeGranularityResponseTransform } from './transform-response/site-type-subtype-granularity-response.transform'

class ConstantsApi extends ApiClient {
  getBannedProducts () {
    return this.get('BannedProducts', { transformResponse })
  }

  getMediaOwnerNames () {
    return this.get('MediaOwnerNames', { transformResponse })
  }

  getSiteTypesWithBookingInfo () {
    return this.get('SiteTypesBookingGranularity', { transformResponse: siteTypeSubTypeGranularityResponseTransform })
  }

  getCityNames () {
    return this.get('CityNames', { transformResponse })
  }

  getMediaFilter () {
    return this.get('MediaFilter', { transformResponse })
  }

  getMediaOwnerNetworks () {
    return this.get('MediaOwnerNetworks', { transformResponse })
  }

  getPlanningGoalType () {
    return this.get('PlanningGoalType', { transformResponse })
  }
}

export const constantsApi = new ConstantsApi({
  baseURL: API_CONF.api + '/api/Constants/GetConstants/'
})
