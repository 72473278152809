import { Component, Mixins } from 'vue-property-decorator'
import { AgGridEvent, BodyScrollEvent, GridApi } from 'ag-grid-community'
import { GridApiMixin } from './grid-api.mixin'

const RIGHT_SHADOW_CLASS = 'shadow-right'
const LEFT_SHADOW_CLASS = 'shadow-left'

@Component
export class GridScrollShadowMixin extends Mixins(GridApiMixin) {
  mounted () {
    this.initScrollShadow()
  }

  private initScrollShadow (): void {
    this.listenOnGridApi('bodyScroll', this.scrollShadowBodyScroll)
    this.listenOnGridApi('displayedColumnsChanged', this.scrollShadowGridEventHandler)
    this.listenOnGridApi('firstDataRendered', this.scrollShadowGridEventHandler)
    this.listenOnGridApi('rowDataChanged', this.scrollShadowGridEventHandler)
    this.listenOnGridApi('rowDataUpdated', this.scrollShadowGridEventHandler)
  }

  private scrollShadowBodyScroll (e: BodyScrollEvent): void {
    if (e.direction !== 'horizontal') {
      // We are interested only in horizontal scrolling.
      // Vertical scroll does not affect shadow appearance.
      return
    }

    this.scrollShadowGridEventHandler(e)
  }

  private scrollShadowGridEventHandler (e: AgGridEvent): void {
    this.updateScrollShadow(e.api)
  }

  private updateScrollShadow (gridApi: GridApi): void {
    setTimeout(() => {
      // @ts-ignore: Private, but there is no other way.
      const gridBodyComp = gridApi.gridBodyComp

      if (!gridBodyComp) {
        return
      }

      const scrollLeft = gridBodyComp.controller.bodyScrollFeature.scrollLeft
      const gridWidth = gridBodyComp.controller.columnController.bodyWidth
      const clientWidth = gridBodyComp.eBodyViewport.clientWidth
      const element = gridBodyComp.eBodyViewport.children.fullWidth

      if (clientWidth >= gridWidth) {
        element.classList.remove(LEFT_SHADOW_CLASS, RIGHT_SHADOW_CLASS)
        return
      }

      if (scrollLeft <= 0) {
        element.classList.remove(LEFT_SHADOW_CLASS)
      } else {
        element.classList.add(LEFT_SHADOW_CLASS)
      }

      if (scrollLeft + clientWidth >= gridWidth) {
        element.classList.remove(RIGHT_SHADOW_CLASS)
      } else {
        element.classList.add(RIGHT_SHADOW_CLASS)
      }
    }, 50) // Timeout to handle vertical scrollbar appearance.
  }
}
