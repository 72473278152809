import { i18n } from '@/locales/i18n'
import Addresses from './addresses/addresses.vue'
import Coordinates from './coordinates/coordinates.vue'
import { SettingObject } from '@/components/shared/coordinates-service/types'
import { ContentType, GeocodingConfidence } from '@/components/shared/coordinates-service/types/enum'

export const settingsMapping: SettingObject = {
  [ContentType.PointsContentType]: {
    getName () { return i18n.t('common.coordinates-service.labels.coordinates') },
    component: Coordinates
  },
  [ContentType.AddressesContentType]: {
    getName () { return i18n.t('common.coordinates-service.labels.addresses') },
    component: Addresses
  }
}

export const DEFAULT_LATITUDE_COLUMN_NAME = 'latitude'
export const DEFAULT_LONGITUDE_COLUMN_NAME = 'longitude'

export const SUPPORTED_LATITUDE_COLUMNS = [DEFAULT_LATITUDE_COLUMN_NAME, 'lat', 'xcor', 'breitengrad']
export const SUPPORTED_LONGITUDE_COLUMNS = [DEFAULT_LONGITUDE_COLUMN_NAME, 'lng', 'ycor', 'längengrad']

export const GEOCODING_DETAILS_MODAL_OPEN = 'geocoding-details-modal::open'

export const geocodingDetailsOptions = {
  all: {
    label: () => i18n.t('common.labels.all'),
    filterFunc: () => true
  },
  success: {
    label: () => i18n.t('common.coordinates-service.labels.geocode-success-option'),
    filterFunc: (confidence) => [GeocodingConfidence.High, GeocodingConfidence.Medium].includes(confidence)
  },
  unsuccess: {
    label: () => i18n.t('common.coordinates-service.labels.geocode-unsuccess-option'),
    filterFunc: (confidence) => [GeocodingConfidence.Ambigous, GeocodingConfidence.Low, GeocodingConfidence.Bad].includes(confidence)
  }
}
