import GradientAwareMixin from './gradient-aware.mixin'
import { FilledGeometryLayer } from '@/types/visualization/layer'
import { Component } from 'vue-property-decorator'
import { ColorModel } from '@/types/color-data'
import { Color } from 'csstype'
import { LayerGeometry, LayerPoint, LayerPolygon } from '@/types/visualization/layer/geometry'
import { LayerColorGetter, LayerOpacityGetter } from '@/types/visualization/layer/style'
import { DatasetColumnKey } from '@/types/common'

@Component
export default class GradientFillMixin<TLayer extends FilledGeometryLayer> extends GradientAwareMixin<TLayer> {
  private get gradientFillStartRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.fill.gradientStartColor, this.layer.style.fill.gradientStartOpacity)
  }

  private get gradientFillEndRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.fill.gradientEndColor, this.layer.style.fill.gradientEndOpacity)
  }

  private minGradientFillValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMinGradientValue(gradientColumn)
  }

  private maxGradientFillValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMaxGradientValue(gradientColumn)
  }

  protected get gradientFillColorFunc (): LayerColorGetter<LayerPolygon | LayerPoint> {
    const gradientColumn = this.layer.style.fill.gradientColumn
    // Make this property depend on gradientColumn, gradientFillStartRgb and gradientFillEndRgb.
    if (
      gradientColumn === undefined ||
      this.gradientFillStartRgb === undefined ||
      this.gradientFillEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.gradientFillColor(row, gradientColumn)
  }

  protected get gradientFillOpacityFunc (): LayerOpacityGetter<LayerPolygon | LayerPoint> {
    const gradientColumn = this.layer.style.fill.gradientColumn
    // Make this property depend on gradientColumn, gradientFillStartRgb and gradientFillEndRgb.
    if (
      gradientColumn === undefined ||
      this.gradientFillStartRgb === undefined ||
      this.gradientFillEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.gradientFillOpacity(row, gradientColumn)
  }

  private calculateGradientFillRatio (row, gradientColumn: DatasetColumnKey): number {
    return this.calculateGradientRatio(
      row,
      gradientColumn,
      this.minGradientFillValue(gradientColumn),
      this.maxGradientFillValue(gradientColumn)
    )
  }

  protected gradientFillColor (row: LayerGeometry, gradientColumn: DatasetColumnKey): Color {
    const ratio = this.calculateGradientFillRatio(row, gradientColumn)
    const color = this.calculateGradientColor(ratio, this.gradientFillStartRgb, this.gradientFillEndRgb)

    return color.toHexString()
  }

  protected gradientFillOpacity (row: LayerGeometry, gradientColumn: DatasetColumnKey): ColorModel['a'] {
    const ratio = this.calculateGradientFillRatio(row, gradientColumn)

    return this.calculateGradientOpacity(ratio, this.gradientFillStartRgb, this.gradientFillEndRgb)
  }
}
