



















import Checkboard from './checkboard.vue'
import Slider from './slider.vue'
import { DirectionAwareMixin } from '../mixin/direction-aware.mixin'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ColorModel } from '@/types/color-data'
import { BackgroundProperty } from 'csstype'

@Component({
  components: { Checkboard, Slider }
})
export default class Alpha extends Mixins(DirectionAwareMixin) {
  @Prop({ required: true }) color!: ColorModel

  private get alpha (): ColorModel['a'] {
    return this.color.a
  }

  private set alpha (value: ColorModel['a']) {
    if (this.color.a === value) {
      return
    }

    this.$emit('change', {
      h: this.color.hsl.h,
      s: this.color.hsl.s,
      l: this.color.hsl.l,
      a: value,
      source: 'rgba'
    })
  }

  private get gradientColor (): BackgroundProperty<string> {
    const rgba = this.color.rgba
    const rgbStr = [rgba.r, rgba.g, rgba.b].join(',')
    const direction = this.isVertical ? 'to bottom' : 'to right'

    return `linear-gradient(${direction}, rgba(${rgbStr}, 0) 0%, rgba(${rgbStr}, 1) 100%)`
  }
}
