export enum PlanningStatus {
  Draft = 'Draft',
  Completed = 'Completed',
  InBuying = 'InProcess'
}

export enum LocationAvailability {
  Available = 'Available',
  NotAvailable = 'NotAvailable',
  PartlyAvailable = 'PartlyAvailable',
  BlockedByItWorks = 'BlockedByItWorks',
  Unknown = 'Unknown'
}

export enum BookingTimeFrameGranularity {
  Decade = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  HalfYear = 5,
  Year = 6,
  Fortnight = 7,
}

export enum UnitType {
  MediaOwnerNetwork = 'MediaOwnerNetwork',
  SDAWData = 'SDAWData'
}

export enum StatisticChartType {
  ByArea = 'By Area',
  ByTime = 'By Time',
  Total = 'Total'
}

export enum PlanningTableViewTypes {
  Default = 'default',
  MediaOwnerSummary = 'media owner summary',
  SumPerArea = 'sum per area',
  MediaOwnerList = 'media owner list',
  SingleUnitList = 'single unit list'
}
