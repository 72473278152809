import { Component, Prop, Mixins } from 'vue-property-decorator'
import { DatasetRows } from '@/types/common'
import { ImportCitiesDataset } from '@/types/planning/wizard/filter-editor'
import { ValidationModel } from '@/vendor/types/vuelidate/model'
import { FormInputState, SelectOption } from '@/types/bootstrap'
import { ValidationProviderMixin } from '@/mixins/validation/validation-provider.mixin'
import { ModalMixin } from '@/mixins/modal.mixin'

@Component
export class MatchDatasetMixin extends Mixins<ModalMixin, ValidationProviderMixin>(ModalMixin, ValidationProviderMixin) {
  @Prop({ default: () => null }) protected datasetRows!: DatasetRows

  protected dataset: ImportCitiesDataset | null = null
  private importProcessIsRunning = false

  protected formInputState (model: ValidationModel): FormInputState {
    return this.validationModelToInputState(model)
  }

  protected get columnsOptions (): SelectOption[] {
    return this.dataset ? this.dataset?.columnInfos.map(column => ({ text: column.name, value: column.name })) : []
  }

  protected resetImportSettings (): void {
    this.resetOwnProperties()
    this.dataset = null
    this.importProcessIsRunning = false
    this.$v.$reset()
  }

  // to override in descendants
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetOwnProperties (): void {}

  // to override in descendants
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected runImportProcess (): void {}

  // to override in descendants
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected showFormInvalidErrorMsg (): void {}

  // to override in descendants
  protected datasetHasEmptyValues (): boolean {
    return true
  }

  protected handleImport () {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return
    }

    if (this.datasetHasEmptyValues()) {
      this.showFormInvalidErrorMsg()
      return
    }

    this.importProcessIsRunning = true

    setTimeout(() => {
      this.runImportProcess()
    })
  }
}
