import { orderBy, round, find } from 'lodash'
import { Editor } from 'tiptap'
import {
  defaultEditorExtensions,
  editorImageExtensions,
  editorTableExtensions,
  mergeEditorConfig
} from '@/components/shared/tiptap/utils'
import { LayerLegend } from './LayerLegend'
import { LegendValues } from '@/types/visualization/map-legend'
import { SortType } from '@/types/enums'
import { EditorConfig } from '@/components/shared/tiptap/types'

export function createLegendEditor (layer, config: EditorConfig | null = null): Editor {
  const defaultExtensions = [
    ...defaultEditorExtensions(),
    ...editorImageExtensions(),
    ...editorTableExtensions(),

    // Visualization legend specific stuff.
    new LayerLegend({ layer })
  ]

  const defaultConfig = {
    extensions: defaultExtensions,
    content: layer.style.legend.content,
    editable: layer.style.legend.enabled
  }

  const editorConfig = mergeEditorConfig(defaultConfig, config)

  return new Editor(editorConfig)
}

export function sortLegendValues (legendValues: LegendValues, sortOrder: string[] | SortType): LegendValues {
  if (Array.isArray(sortOrder)) {
    let sortedValues = legendValues.map(value => {
      let index = sortOrder.indexOf(value.label)

      if (index === -1) {
        index = Infinity
      }

      return ({
        value,
        index
      })
    })

    sortedValues = orderBy(sortedValues, ['index'], [SortType.Asc])

    return sortedValues.map(v => v.value)
  }

  if (sortOrder === SortType.Asc) {
    return orderBy(legendValues, ['label'], [SortType.Asc])
  }

  if (sortOrder === SortType.Desc) {
    return orderBy(legendValues, ['label'], [SortType.Desc])
  }

  return legendValues
}

export function formatLegendValues (legendValues: LegendValues, updatedLegendValues: Partial<LegendValues>): LegendValues {
  return legendValues.map(value => {
    const updatedValue = find(updatedLegendValues, ['key', value.key])

    if (!updatedValue) {
      return value
    }

    return {
      ...value,
      label: updatedValue.label
    }
  })
}

export function formatLegendValue<TValue extends number | string> (value: TValue, maxDecimals: number | null = null): TValue {
  const valueParsed = parseFloat(value as string)

  if (!isNaN(valueParsed) && maxDecimals != null) {
    return round(valueParsed, maxDecimals) as TValue
  }

  return value
}
