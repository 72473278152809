import { PermissionRuleMap } from '@/types/auth'
import { commonRules } from './common'
import { planningRules } from './planning'
import { visualizationRules } from './visualization'
import { ordersRules } from './orders'
import { analyticsRules } from './analytics'

export const globalRules: PermissionRuleMap = {
  ...commonRules,
  ...planningRules,
  ...visualizationRules,
  ...ordersRules,
  ...analyticsRules
}
