import { ApiClient } from '@/api/api-client'

class AuthApi extends ApiClient {
  authenticate (username: string, password: string): Promise<unknown> {
    return this.post(
      'Authenticate',
      { username, password },
      { showSpinner: true }
    )
  }

  authenticateStatus (): Promise<void> {
    return this.get('GetAuthorizationStatus', { noAuth: false })
  }
}

export const authApi = new AuthApi({
  baseURL: API_CONF.api + '/api/AuthenticationService',
  noAuth: true
})
