import { cloneDeep, uniqBy } from 'lodash'
import {
  PLAN_WIZARD_GENERATE_FILTER_ID,
  PLAN_WIZARD_SELECTED_GOALS,
  WIZARD_STEP_GOALS_ALL_GOALS,
  WIZARD_STEP_GOALS_ALL_GOALS_VALID,
  WIZARD_STEP_GOALS_ALL_NAMES_UNIQUE,
  WIZARD_STEP_GOALS_GOAL_PER_LOCATION,
  WIZARD_STEP_GOALS_HAS_LINKED_LOCATIONS,
  WIZARD_STEP_GOALS_IS_UNIQUE_NAME,
  WIZARD_STEP_GOALS_SELECTED_GOALS,
  WIZARD_STEP_GOALS_UNIQUE_SELECTED_GOALS
} from '@/store/getter-types'
import { isGoalFunction } from '@/utils/plan-wizard-steps'
import {
  PLAN_WIZARD_ADD_FILTER_NODES,
  PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS,
  WIZARD_STEP_GOALS_LINK_LOCATIONS,
  WIZARD_STEP_GOALS_REMOVE_GOAL,
  WIZARD_STEP_GOALS_UNLINK_LOCATIONS
} from '@/store/action-types'
import { checkFilterNamesUnique, checkFiltersValidity, makeUniqueNameChecker } from '@/store/planning-wizard/utils'
import { FilterStoreQuery, ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { LocationAssignmentEvent } from '@/types/planning/wizard'
import { SCORING_FUNCTIONS } from '@/constants/functions/scoring-functions'

// initial state
const state = {}

// getters
const getters = {
  [WIZARD_STEP_GOALS_SELECTED_GOALS] (_state, _getters, _rootState, rootGetters): ScoringFunctionNode[] {
    return rootGetters[PLAN_WIZARD_SELECTED_GOALS]
  },
  [WIZARD_STEP_GOALS_GOAL_PER_LOCATION] (_state, getters): (areaId: string) => ScoringFunctionNode[] {
    return (areaId) => {
      return getters[WIZARD_STEP_GOALS_SELECTED_GOALS].filter(node => node.areaId === areaId)
    }
  },
  [WIZARD_STEP_GOALS_ALL_GOALS] (): string[] {
    return SCORING_FUNCTIONS
      .filter(func => isGoalFunction(func))
      .map(func => func.name)
  },
  [WIZARD_STEP_GOALS_UNIQUE_SELECTED_GOALS] (_state, getters): ScoringFunctionNode[] {
    let nodes = getters[WIZARD_STEP_GOALS_SELECTED_GOALS] as ScoringFunctionNode[]

    nodes = uniqBy(nodes, node => node.name)

    nodes = nodes.map(node => {
      node = cloneDeep(node)
      delete node.areaId
      return node
    })

    return nodes
  },
  [WIZARD_STEP_GOALS_HAS_LINKED_LOCATIONS] (_state, getters): (location: ScoringFunctionNode) => boolean {
    return filter => {
      return getters[WIZARD_STEP_GOALS_SELECTED_GOALS].some(node => node.name === filter.name)
    }
  },
  [WIZARD_STEP_GOALS_ALL_GOALS_VALID] (_state, getters): boolean {
    return checkFiltersValidity(getters[WIZARD_STEP_GOALS_SELECTED_GOALS] as ScoringFunctionNode[])
  },
  [WIZARD_STEP_GOALS_IS_UNIQUE_NAME] (_state, getters): (name: string, filterId: string) => boolean {
    return makeUniqueNameChecker(getters[WIZARD_STEP_GOALS_SELECTED_GOALS] as ScoringFunctionNode[])
  },
  [WIZARD_STEP_GOALS_ALL_NAMES_UNIQUE] (_state, getters): boolean {
    return checkFilterNamesUnique(getters[WIZARD_STEP_GOALS_UNIQUE_SELECTED_GOALS] as ScoringFunctionNode[])
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [WIZARD_STEP_GOALS_LINK_LOCATIONS] ({ getters, dispatch }, locationGoals: LocationAssignmentEvent[] = []): Promise<void> {
    const newNodes = locationGoals.map(({ name, areaId, filterId, scoringFunction }) => ({
      id: getters[PLAN_WIZARD_GENERATE_FILTER_ID](),
      filterId,
      areaId,
      name,
      scoringFunction: cloneDeep(scoringFunction)
    }))

    return dispatch(PLAN_WIZARD_ADD_FILTER_NODES, newNodes)
  },
  async [WIZARD_STEP_GOALS_UNLINK_LOCATIONS] ({ dispatch }, locationGoals: LocationAssignmentEvent[] = []): Promise<void> {
    const searchNode = function (nodeName, areaId, node) {
      return node.name === nodeName && node.areaId === areaId
    }

    for await (const filter of locationGoals) {
      const query = searchNode.bind(null, filter.name, filter.areaId)
      dispatch(PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS, query, { root: true })
    }

    return Promise.resolve()
  },
  [WIZARD_STEP_GOALS_REMOVE_GOAL] ({ dispatch }, query: FilterStoreQuery): Promise<void> {
    return dispatch(PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS, query, { root: true })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
