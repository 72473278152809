import ClusteringFillMixin from '@/components/visualization/map/mixins/clustering-fill.mixin'
import { Component, Mixins } from 'vue-property-decorator'
import ClusteringLegendMixin from '../mixins/clustering-legend.mixin'

@Component
export default class ClusteringFillLegendMixin<_TLayer>
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  extends Mixins<ClusteringFillMixin<_TLayer>, ClusteringLegendMixin<_TLayer>>(ClusteringFillMixin, ClusteringLegendMixin) {
  protected get clusteringFillLegendValues () {
    return this.makeClusteringLegendValues(
      this.clusteringFillUniqueValues,
      this.getClusteringFillColor,
      this.layer.style.fill.opacity
    )
  }
}
