import { DirectiveOptions } from 'vue'
import { Store } from '@/store/store'
import { HAS_ACTIVE_REQUEST } from '@/store/getter-types'

type ElementsToDisable = HTMLButtonElement | HTMLInputElement | HTMLSelectElement

interface CustomElement extends HTMLElement {
  __processWatchers?: Array<() => void>
}

const tagsToDisable = ['BUTTON', 'INPUT', 'SELECT']

function canBeDisabled (element: HTMLElement): element is ElementsToDisable {
  return tagsToDisable.includes(element.tagName)
}

function getCurrentDisabledState (): boolean {
  return Store.getters[HAS_ACTIVE_REQUEST]
}

function applyDisabledState (element: HTMLElement, disabled: boolean): void {
  if (canBeDisabled(element)) {
    element.disabled = disabled
    return
  }

  // We trap here if element is a container.
  // So we try to disable not element itself, but it's children.
  element.childNodes.forEach((node) => {
    const elem = node as HTMLElement

    if (canBeDisabled(elem)) {
      elem.disabled = disabled
    }
  })
}

export const DisableWhenProcessing: DirectiveOptions = {
  bind (el): void {
    const element = el as CustomElement

    applyDisabledState(element, getCurrentDisabledState())

    const watcher = () => applyDisabledState(element, getCurrentDisabledState())

    element.__processWatchers = [
      Store.watch((_state, getters) => getters[HAS_ACTIVE_REQUEST], watcher)
    ]
  },

  unbind (el): void {
    const element = el as CustomElement

    if (Array.isArray(element.__processWatchers)) {
      for (const unwatch of element.__processWatchers) {
        unwatch()
      }
    }
  }
}
