import { i18n } from '@/locales/i18n'
import {
  VICINITY_IMPORT_FILTERED_DATASET,
  VICINITY_IMPORT_LINK_NAMESPACE,
  VICINITY_IMPORT_QUERY_UPDATED_STATE,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_CAN_SKIP,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_ENABLED,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_INVALID,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_STEP_ID,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID
} from '@/store/getter-types'
import {
  VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_INVALIDATE,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_LEAVE,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_SKIP,
  VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_VALIDATE,
  WIZARD_INVALIDATE_AFTER
} from '@/store/action-types'
import { VICINITY_IMPORT_QUERY } from '@/components/planning/vicinity-import-wizard/constants/steps'
import {
  VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE
} from '@/store/mutation-types'

// initial state
const state = {
  stepId: VICINITY_IMPORT_QUERY
}

// getters
const getters = {
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_ENABLED] (state, getters) {
    return !getters[WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID](state.stepId)
  },
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY] (state, getters) {
    return getters[VICINITY_IMPORT_FILTERED_DATASET].length === 0
  },
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_INVALID] (state, getters) {
    return getters[VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS].length > 0
  },
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_CAN_SKIP] () {
    return true
  },
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS] (state, getters) {
    const errors = []

    if (getters[VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY]) {
      errors.push(i18n.t('planning.vicinity-import.errors.empty-dataset'))
    }

    return errors
  },
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS])
  },
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_INVALIDATE] ({ dispatch }) {
    return dispatch(VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA)
  },
  [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_LEAVE] ({ getters, commit, dispatch }) {
    if (getters[VICINITY_IMPORT_QUERY_UPDATED_STATE]) {
      const namespacedAction = getters[VICINITY_IMPORT_LINK_NAMESPACE](WIZARD_INVALIDATE_AFTER)
      dispatch(namespacedAction)
      commit(VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE, false)
    }
  },
  async [VICINITY_IMPORT_WIZARD_TAB_QUERY_DATASET_SKIP] ({ dispatch }) {
    await dispatch(VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
