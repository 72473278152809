import { VueInstanceProps } from '@/types/vue'
import { ColDef } from 'ag-grid-community'
import SelectorStatusBar from './components/selector-status-bar.vue'

const defaultColDef = {
  flex: 1,
  minWidth: 100,
  sortable: true,
  resizable: true,
  menuTabs: ['filterMenuTab']
}

const frameworkComponents = {
  selectorStatusBar: SelectorStatusBar
}

const statusBar = {
  statusPanels: [{ statusPanel: 'selectorStatusBar' }]
}

const checkBoxColumnDef: ColDef = {
  colId: 'actions',
  headerName: '',
  sortable: false,
  checkboxSelection: true,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  maxWidth: 40,
  suppressMenu: true
}

export const configureSelectorGridColumnDefs = (predefinedColDefs: ColDef[]): ColDef[] => {
  return [checkBoxColumnDef, ...predefinedColDefs.map(colDef => {
    return {
      ...colDef,
      filter: 'agMultiColumnFilter'
    }
  })]
}

export const defaultGridOptions: VueInstanceProps = {
  defaultColDef,
  suppressRowClickSelection: true,
  pagination: true,
  quickFilter: true,
  frameworkComponents,
  statusBar
}
