import { Table as BaseTable } from 'tiptap-extensions'
import { tableNodeTypes, createCell } from './helpers'
import { TableCommandsConfig, Commands, TableContent, TableOptions } from './types'
import { Schema, Node } from 'prosemirror-model'

function createDataTable (schema: Schema, tableContent: TableContent[]): Node {
  const {
    cell: tableCell,
    header_cell: tableHeader,
    row: tableRow,
    table
  } = tableNodeTypes(schema)

  const rowsCount = tableContent.length
  const headerCells = Object.keys(tableContent[0]).map(item => {
    return createCell(tableHeader, schema.node('paragraph', undefined, [schema.text(item)]))
  }) as Node[]

  const rows: Node[] = []
  rows.push(tableRow.createChecked(null, headerCells))

  for (let i = 0; i < rowsCount; i++) {
    const cells = Object.values(tableContent[i]).map(item => {
      return createCell(tableCell, schema.node('paragraph', undefined, [schema.text(item.toString())]))
    }) as Node[]

    rows.push(tableRow.createChecked(null, cells))
  }

  return table.createChecked(null, rows)
}

export class Table extends BaseTable {
  // eslint-disable-next-line no-useless-constructor
  constructor (options?: TableOptions) {
    super(options)
  }

  commands (config: TableCommandsConfig): Commands {
    const { schema } = config

    return {
      ...super.commands(config),
      createTableFromContent: ({ tableContent }) =>
        (state, dispatch) => {
          const nodes = createDataTable(schema, tableContent)

          const tr = state.tr.replaceSelectionWith(nodes).scrollIntoView()
          dispatch(tr)
        }
    }
  }
}
