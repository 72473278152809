


























import { Vue, Component, Ref } from 'vue-property-decorator'
import { ValidatableModalComponent } from '@/plugins/modal-dialog/types'
import { unionBy } from 'lodash'
import { BOOKABLE_UNIT_FIELDS, BOOKABLE_UNIT_FIELDS_SETTINGS } from '@/plugins/enter-bookable-units/fileds-settings/fields-settings'
import BookableUnitsImport from '@/plugins/enter-bookable-units/bookable-units-import/bookable-units-import.vue'
import { GRID_COLUMN_TYPE_STRING } from '@/components/shared/ag-grid/constants'
import EditableBookableUnitsGrid from '@/plugins/enter-bookable-units/editable-bookable-units-grid/editable-bookable-units-grid.vue'

type ManualBookableUnit = Record<BOOKABLE_UNIT_FIELDS, string>

@Component({
  components: { EditableBookableUnitsGrid, BookableUnitsImport }
})
export default class EnterBookableUnits extends Vue implements ValidatableModalComponent {
  private fields = BOOKABLE_UNIT_FIELDS_SETTINGS
  private rows: ManualBookableUnit[] = []
  public externalIds: string[] = []

  @Ref('editableGrid') readonly editableGrid!: EditableBookableUnitsGrid

  private onRowsUploaded (rows: ManualBookableUnit[]) {
    this.rows = unionBy<ManualBookableUnit>(this.rows, rows, BOOKABLE_UNIT_FIELDS.ExternalID)
      .map(item => ({ ...item }))
  }

  private onRowAdded () {
    const newRow = Object.fromEntries(
      Object.keys(this.fields)
        .map(key => [key, this.fields[key].type === GRID_COLUMN_TYPE_STRING ? '' : 0])
    ) as ManualBookableUnit

    this.rows = [newRow].concat(this.rows)
  }

  private saveBookableUnits (validRows: ManualBookableUnit[]): Promise<string[]> {
    const units = validRows.map(row => row[BOOKABLE_UNIT_FIELDS.ExternalID])

    if (!validRows.length) {
      return Promise.reject(new Error(this.$t('planning.bookable-units-import.errors.no-valid-rows') as string))
    }

    return Promise.resolve(units)
      .then(units => {
        if (units.length === 0) {
          return Promise.reject(new Error(this.$t('planning.bookable-units-import.errors.server-result-empty') as string))
        }

        return units
      }, () => {
        return Promise.reject(new Error(this.$t('planning.bookable-units-import.errors.server-result-empty') as string))
      })
  }

  async validateModal (): Promise<boolean> {
    const validRows = this.editableGrid.getValidRows() as ManualBookableUnit[]

    // TODO - save points on the server
    try {
      this.externalIds = await this.saveBookableUnits(validRows)
    } catch (e) {
      this.$toastError((e as Error).toString())
      this.externalIds = []
    }

    return Promise.resolve(!!this.externalIds.length)
  }
}
