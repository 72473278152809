import { getters } from '@/store/planning-wizard/media-owner-networks-store/getters'
import { actions } from '@/store/planning-wizard/media-owner-networks-store/actions'
import { mutations } from '@/store/planning-wizard/media-owner-networks-store/mutations'
import { MediaOwnerNetworksCache } from '@/types/planning/wizard/value-types'

// initial state
const state: MediaOwnerNetworksCache = {
  networks: [],
  areaNameList: [],
  dataset: null,
  dateFrom: null,
  dateTo: null
}

export default {
  state,
  getters,
  actions,
  mutations
}
