


















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Report, ReportsList } from '@/types/report'

@Component
export default class FileUploadReports extends Vue {
  @Prop({ required: true, default: () => [] }) reports!: ReportsList

  getReportMessage (report: Report): string {
    if (report.msgNo) {
      return `common.reports.messages.${report.msgNo}`
    }

    return report.text
  }
}
