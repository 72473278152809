type ApiFunction<T, U = void> = (api: T) => U

export default class GridApiAdapter<T> {
  private _api!: T
  private _pendingActions: ApiFunction<T>[] = []

  initApi (api: T) {
    this._api = api

    for (const action of this._pendingActions) {
      action(this._api)
    }
  }

  runOrQueueAction<U = void> (action: ApiFunction<T, U>): Promise<U> {
    if (this._api) {
      return Promise.resolve(action(this._api))
    }

    let resolveActionPromise
    const actionPromise = new Promise<U>((resolve) => (resolveActionPromise = resolve))
    const actionExecutor: ApiFunction<T, U> = (api) => resolveActionPromise(action(api))

    this._pendingActions.push(actionExecutor)

    return actionPromise
  }
}
