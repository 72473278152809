import visualizationPlanningActions from './visualization-planning-actions/visualization-planning-actions'
import visualizationCrudActions from './visualization-crud-actions/visualization-crud-actions'

// actions
const actions = {
  ...visualizationPlanningActions,
  ...visualizationCrudActions
}

export default actions
