









import { Component, Prop, Vue } from 'vue-property-decorator'
import SurroundingGeometries from '@/components/visualization/surrounding-geometries/surrounding-geometries.vue'
import { Geometries, Geometry } from '@/types/common'
import { ValidatableModalComponent } from '@/plugins/modal-dialog/types'

@Component({
  components: { SurroundingGeometries }
})
export default class GeometryPicker extends Vue implements ValidatableModalComponent {
  @Prop({ required: true }) geometries!: Geometries
  @Prop({ default: false }) required!: boolean

  selectedGeometries: Geometries = [] as Geometries

  created () {
    this.selectedGeometries = this.geometries
  }

  private addGeometries (geometries: Geometries): void {
    const existingIds = new Set(this.selectedGeometries.map((geometry) => geometry.id))

    for (const geometry of geometries) {
      if (!existingIds.has(geometry.id)) {
        this.selectedGeometries.push(geometry)
      }
    }
  }

  private static isSameGeometryGroup (geometry1: Geometry, geometry2: Geometry): boolean {
    return geometry1.name === geometry2.name &&
      geometry1.parentGeometrySetId === geometry2.parentGeometrySetId
  }

  private removeGeometry (geometryToRemove: Geometry) {
    const geometriesToRemove = this.selectedGeometries.filter(
      (geometry) => GeometryPicker.isSameGeometryGroup(geometryToRemove, geometry)
    )

    if (geometriesToRemove.length) {
      this.selectedGeometries = this.selectedGeometries.filter((geometry) => !geometriesToRemove.includes(geometry))
    }
  }

  validateModal (): Promise<boolean> {
    if (!this.required) {
      return Promise.resolve(true)
    }

    if (!this.selectedGeometries.length) {
      this.$toastError(this.$t('visualization.new-layer-modal.errors.select-least-geometry') as string)
    }

    return Promise.resolve(!!this.selectedGeometries.length)
  }
}
