























import { Component, Mixins } from 'vue-property-decorator'
import TagsAutocomplete from '@/directives/tags-autocomplete'
import { FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import { TagsUiMixin } from './mixins/tags-ui.mixin'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'

@Component({
  components: { FilterParamName },
  directives: { TagsAutocomplete }
})
export default class TagsUiElement extends Mixins(TagsUiMixin) implements FilterUIComponent<string[]> {}
