import { TranslateResult } from 'vue-i18n'
import { RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS } from '@/store/ranking-import-wizard/modules/navigation/modules/wizard-tab-query-dataset/getters'
import { RANKING_IMPORT_WIZARD_RESET_QUERY_DATA } from '@/store/ranking-import-wizard/actions'

// actions
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_LEAVE = 'ranking-import/query-dataset-tab/leave'
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_INVALIDATE = 'ranking-import/query-dataset-tab/invalidate'
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_VALIDATE = 'ranking-import/query-dataset-tab/validate'
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_SKIP = 'ranking-import/query-dataset-tab/skip'

// actions
export const actions = {
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_VALIDATE] ({ getters }): Promise<TranslateResult[]> {
    return Promise.resolve(getters[RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS])
  },
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_INVALIDATE] ({ dispatch }) {
    return dispatch(RANKING_IMPORT_WIZARD_RESET_QUERY_DATA)
  },
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_LEAVE] () {
    return Promise.resolve()
  },
  async [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_SKIP] ({ dispatch }) {
    await dispatch(RANKING_IMPORT_WIZARD_RESET_QUERY_DATA)
  }
}
