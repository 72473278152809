import { FillType } from '@/types/visualization/layer/enums'
import ClusteringLegendEditor from './layer-types/clustering-legend-editor.vue'
import QuantileLegendEditor from './layer-types/quantile-legend-editor.vue'
import ColorLegendEditor from './layer-types/color-legend-editor.vue'
import { VueComponentMap } from '@/types/vue'

export const LAYER_EDITOR_COMPONENTS: VueComponentMap = {
  [FillType.Color]: ColorLegendEditor,
  [FillType.Clustering]: ClusteringLegendEditor,
  [FillType.Median]: QuantileLegendEditor,
  [FillType.Quartile]: QuantileLegendEditor,
  [FillType.Decile]: QuantileLegendEditor,
  [FillType.Range]: QuantileLegendEditor
}
