import {
  NEW_VISUALIZATION_WIZARD_SET_VISIBLE,
  NEW_VISUALIZATION_WIZARD_SET_NAME,
  NEW_VISUALIZATION_WIZARD_SET_PLANS,
  NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT
} from '@/store/mutation-types'

export default {
  [NEW_VISUALIZATION_WIZARD_SET_VISIBLE] (state, visible) {
    state.visible = visible
  },
  [NEW_VISUALIZATION_WIZARD_SET_NAME] (state, name) {
    state.name = name
  },
  [NEW_VISUALIZATION_WIZARD_SET_PLANS] (state, plans) {
    state.plans = plans
  },
  [NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT] (state, entrypoint) {
    state.entrypoint = entrypoint
  }
}
