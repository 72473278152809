import { PluginObject, VNode } from 'vue'
import { Geometries } from '@/types/common'
import { i18n } from '@/locales/i18n'
import GeometryPicker from './geometry-picker.vue'
import { ImportAreasResult } from '@/plugins/import-areas'

export type PickGeometryOptions = {
  geometries?: Geometries;
  required?: boolean;
}

type PickGeometryFunction = (options?: PickGeometryOptions) => Promise<ImportAreasResult>;

declare module 'vue/types/vue' {
  interface Vue {
    $pickGeometries: PickGeometryFunction;
  }
}

function configureDialog (pickerVNode: VNode) {
  const props = {
    size: 'full',
    bodyClass: 'w-100 h-100 d-flex p-0',
    centered: true,
    scrollable: true
  }

  const slots = {
    default: pickerVNode,
    'modal-title': i18n.t('planning.filters.labels.choose-area-title') as string
  }

  return { props, slots }
}

export class GeometryPickerPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$pickGeometries = async function (options: PickGeometryOptions = {}): Promise<ImportAreasResult> {
      const {
        required = false,
        geometries = []
      } = options

      const pickerVNode = this.$createElement(GeometryPicker, {
        props: { geometries, required }
      })

      const { props, slots } = configureDialog(pickerVNode)

      const submitted = await this.$modalDialog.msgBoxConfirmValid(slots, props)

      return {
        submitted,
        geometries: (pickerVNode.componentInstance as GeometryPicker).selectedGeometries
      }
    }
  }
}
