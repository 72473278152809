// initial state
import { ADD_TO_CACHE, LOAD_CACHE_VALUES, REMOVE_FROM_CACHE } from '@/store/action-types'
import { CAMPAIGN_CACHE } from '@/store/getter-types'
import { SET_CAMPAIGN_CACHE } from '@/store/mutation-types'
import { isEmpty, omit } from 'lodash'
import { LoadCacheValuesPayload } from '@/types/store/campaign'
import { ApiDataObject } from '@/types/common'

const state = {
  campaignCache: {}
}

const getters = {
  [CAMPAIGN_CACHE] (state) {
    return state.campaignCache
  }
}

const actions = {
  [ADD_TO_CACHE] ({ commit, getters }, cacheData) {
    commit(SET_CAMPAIGN_CACHE, Object.assign({}, getters[CAMPAIGN_CACHE], cacheData))
  },
  [REMOVE_FROM_CACHE] ({ commit, getters }, query) {
    const removeItems = Object.keys(getters[CAMPAIGN_CACHE]).filter(item => item.indexOf(query) !== -1)

    commit(SET_CAMPAIGN_CACHE, omit(getters[CAMPAIGN_CACHE], removeItems))
  },
  [LOAD_CACHE_VALUES] (
    { dispatch, getters },
    { type, values, loadCallback }: LoadCacheValuesPayload
  ) {
    if (!isEmpty(getters[CAMPAIGN_CACHE])) {
      const needData = values.filter(item => !getters[CAMPAIGN_CACHE][`${type}/${item}`])

      if (isEmpty(needData)) {
        return values.map(item => getters[CAMPAIGN_CACHE][`${type}/${item}`])
      }

      values = needData
    }

    const promises: Promise<ApiDataObject>[] = []
    values.forEach(item => {
      promises.push(loadCallback(item))
    })

    return Promise.all(promises).then(results => {
      const geometriesToCache = results.reduce((obj, item) => {
        obj[`${type}/${item.id}`] = omit(item, ['geometryList', 'geometry'])
        return obj
      }, {})
      dispatch(ADD_TO_CACHE, geometriesToCache)

      return values.map(item => getters[CAMPAIGN_CACHE][`${type}/${item}`])
    })
  }
}

const mutations = {
  [SET_CAMPAIGN_CACHE] (state, cache) {
    state.campaignCache = cache
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
