import { i18n } from '@/locales/i18n'
import {
  LAYER_WIZARD_DATASET,
  LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD,
  LAYER_WIZARD_ENTRYPOINT_IS_LINKED_DATASET,
  LAYER_WIZARD_GEOMETRYSET,
  LAYER_WIZARD_LINK_NAMESPACE,
  LAYER_WIZARD_NEED_TO_UPLOAD_DATASET_DETAILS,
  LAYER_WIZARD_STEP_AREA_UPLOAD_WAS_UPDATED,
  LAYER_WIZARD_TAB_AREA_DATASET_CAN_SKIP,
  LAYER_WIZARD_TAB_AREA_DATASET_ERRORS,
  LAYER_WIZARD_TAB_AREA_DATASET_IS_EMPTY,
  LAYER_WIZARD_TAB_AREA_DATASET_IS_ENABLED,
  LAYER_WIZARD_TAB_AREA_DATASET_IS_HIDDEN,
  LAYER_WIZARD_TAB_AREA_DATASET_IS_INVALID,
  LAYER_WIZARD_TAB_AREA_DATASET_STEP_ID,
  WIZARD_NAVIGATION_ACTIVE_ID,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID,
  LAYER_WIZARD_TAB_AREA_DATASET_STEP_WAS_SKIPPED
} from '@/store/getter-types'
import {
  LAYER_WIZARD_CHANGE_DATASET,
  LAYER_WIZARD_LOAD_DATASET_DETAILS,
  LAYER_WIZARD_TAB_AREA_DATASET_LEAVE,
  LAYER_WIZARD_TAB_AREA_DATASET_SKIP,
  LAYER_WIZARD_TAB_AREA_DATASET_VALIDATE,
  WIZARD_INVALIDATE_AFTER
} from '@/store/action-types'
import { LAYER_WIZARD_STEP_AREA_DATASET } from '@/components/visualization/layer-wizard/constants/steps'
import { isGeometryWithKeysFile } from '@/utils/file'
import { LAYER_WIZARD_STEP_AREA_DATASET_SET_UPDATE_STATE, LAYER_WIZARD_STEP_AREA_DATASET_STEP_WAS_SKIPPED } from '@/store/mutation-types'

// initial state
const state = {
  stepId: LAYER_WIZARD_STEP_AREA_DATASET,
  stepWasSkipped: false
}

// getters
const getters = {
  [LAYER_WIZARD_TAB_AREA_DATASET_IS_HIDDEN] (state, getters) {
    return !getters[LAYER_WIZARD_ENTRYPOINT_IS_LINKED_DATASET] &&
      (
        !getters[LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD] ||
        !getters[LAYER_WIZARD_GEOMETRYSET] ||
        !isGeometryWithKeysFile(getters[LAYER_WIZARD_GEOMETRYSET].name)
      )
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId)
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_IS_EMPTY] (state, getters, rootState, rootGetters) {
    return !rootGetters[LAYER_WIZARD_DATASET]
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_IS_INVALID] (state, getters) {
    return getters[LAYER_WIZARD_TAB_AREA_DATASET_ERRORS].length > 0
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_CAN_SKIP] (state, getters) {
    return getters[LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD]
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_ERRORS] (state, getters) {
    const errors = []

    if (!getters[LAYER_WIZARD_TAB_AREA_DATASET_STEP_WAS_SKIPPED] && getters[LAYER_WIZARD_TAB_AREA_DATASET_IS_EMPTY]) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.select-dataset'))
    }

    return errors
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_STEP_ID] (state) {
    return state.stepId
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_STEP_WAS_SKIPPED] (state) {
    return state.stepWasSkipped
  }
}

// mutations
const mutations = {
  [LAYER_WIZARD_STEP_AREA_DATASET_STEP_WAS_SKIPPED] (state, wasSkipped) {
    state.stepWasSkipped = wasSkipped
  }
}

// actions
const actions = {
  [LAYER_WIZARD_TAB_AREA_DATASET_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[LAYER_WIZARD_TAB_AREA_DATASET_ERRORS])
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_LEAVE] ({ dispatch, commit, getters }) {
    if (getters[LAYER_WIZARD_NEED_TO_UPLOAD_DATASET_DETAILS] && getters[LAYER_WIZARD_TAB_AREA_DATASET_IS_ENABLED]) {
      dispatch(LAYER_WIZARD_LOAD_DATASET_DETAILS)
    }

    const activeWizardStepId = getters[getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_ACTIVE_ID)]

    if (getters[LAYER_WIZARD_STEP_AREA_UPLOAD_WAS_UPDATED] && activeWizardStepId === getters[LAYER_WIZARD_TAB_AREA_DATASET_STEP_ID]) {
      const namespacedAction = getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_INVALIDATE_AFTER)
      dispatch(namespacedAction)
      commit(LAYER_WIZARD_STEP_AREA_DATASET_SET_UPDATE_STATE, false)
    }

    commit(LAYER_WIZARD_STEP_AREA_DATASET_STEP_WAS_SKIPPED, false)
  },
  [LAYER_WIZARD_TAB_AREA_DATASET_SKIP] ({ dispatch, commit }) {
    commit(LAYER_WIZARD_STEP_AREA_DATASET_STEP_WAS_SKIPPED, true)
    return dispatch(LAYER_WIZARD_CHANGE_DATASET, null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
