import { latLng } from 'leaflet'
import { LayerType } from '@/types/visualization/layer/enums'
import { DEFAULT_POINT_LAYER_STYLE } from '@/components/visualization/layer-settings/constants'
import { DEFAULT_LAYER_SETTINGS } from '@/components/visualization/layer-settings/constants/layer-settings'
import { fixNumberColumns, getLayerTagsInfo, parseLayerSettings, parseLayerStyle } from './common'
import { guessLatLngColumns, parseLayerColumns } from './columns'

export function parsePointsLayer (layer) {
  if (!Array.isArray(layer.data) || layer.data.length !== 1) {
    throw new Error('Incorrect point layer structure.')
  }

  const style = parseLayerStyle(layer.style, DEFAULT_POINT_LAYER_STYLE, {
    legend: {
      content: layer.name
    }
  })

  const settings = parseLayerSettings(layer.settings, DEFAULT_LAYER_SETTINGS)
  const columnTypes = layer.data[0].columnInfos
  const layerColumns = parseLayerColumns(columnTypes)
  const dataRows = layer.data[0].datasetRows
  const pointRows = []

  fixNumberColumns(dataRows, columnTypes)
  guessLatLngColumns(style, layerColumns.numberColumns)

  for (const row of dataRows) {
    const latitude = row[style.latitudeColumn]
    const longitude = row[style.longitudeColumn]

    if (latitude == null || longitude == null) {
      // Point can't be constructed without both coordinates.
      continue
    }

    pointRows.push({
      latLng: latLng(latitude, longitude),
      meta: row
    })
  }

  return {
    id: layer.id,
    name: layer.name,
    type: LayerType.Points,
    ...layerColumns,
    dataset: dataRows,
    geometry: pointRows,
    ...getLayerTagsInfo(layer.data, 0),
    style,
    settings,
    dataSource: layer.dataSource
  }
}
