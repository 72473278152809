import RangePathMixin from '@/components/visualization/map/mixins/range-path.mixin'
import RangeLegendMixin from '../mixins/range-legend.mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class RangePathLegendMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  Mixins<RangePathMixin<_TLayer>, RangeLegendMixin<_TLayer>>(RangePathMixin, RangeLegendMixin) {
  protected get rangePathLegendValues () {
    const values = this.rangePathAbsoluteValues
    return this.makeRangeLegendValues(values)
  }
}
