
import { orderBy, pick } from 'lodash'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { CancelToken } from 'axios'
import { Component, Prop } from 'vue-property-decorator'
import { Properties } from 'csstype'
import { ApiDataObject, ObjectTag, Geometries } from '@/types/common'
import { BTable } from 'bootstrap-vue'
import { SearchType, SearchRequest } from '@/types/api/data-delivery'
import SearchableList from '@/components/shared/searchable-list/searchable-list.vue'

@Component
export default class SearchableTaggableList extends SearchableList {
  @Prop({ default: () => [] }) filterTags!: ObjectTag[];
  @Prop({ default: () => ({ maxHeight: '400px' }) }) styles!: Properties;

  protected items: ApiDataObject[] | Geometries = []
  protected searchType!: SearchType;

  protected override get tableItems (): BTable['items'] {
    const items = (this.items as ApiDataObject[]).map(item => pick(item, ['id', 'name', 'tags']))
    orderBy(items, ['name', 'id'])

    return items
  }

  protected override clearItems (): void {
    this.items = []
  }

  protected override selectRows (rows: ApiDataObject[]): void {
    const selected = this.items.filter(item => rows.some(row => item.id === row.id))
    this.$emit('selected', selected)
  }

  protected override requestApi (query: string, cancelToken: CancelToken): Promise<void> {
    const request = {
      type: this.searchType,
      query: `*${query}*`,
      cancelToken
    }

    if (this.filterTags && this.filterTags.length > 0) {
      (request as SearchRequest).tags = this.filterTags
    }

    return dataDeliveryApi.globalSearch(request)
      .then(items => {
        this.items = items.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))
      }).catch(() => {
        this.$toastError(this.$t('visualization.errors.search-error', { searchType: this.searchType }) as string)
      })
  }
}
