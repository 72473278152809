




















import { cloneDeep } from 'lodash'
import { ModalMixin } from '@/mixins/modal.mixin'
import LegendValuesMixin from '@/components/visualization/legend/mixins/legend-values.mixin'
import LegendSublayerMixin from '@/components/visualization/legend/mixins/legend-sublayer.mixin'
import { getMainLayerFillOptions } from '@/utils/visualization'
import { LAYER_EDITOR_COMPONENTS } from './constants'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { NodeAttributes } from '@/components/shared/tiptap/types'
import { VueComponentDeclaration, VueComponentMap } from '@/types/vue'

@Component
export default class LayerLegendEditor extends
  Mixins<ModalMixin, LegendValuesMixin<BorderedGeometryLayer>, LegendSublayerMixin>(ModalMixin, LegendValuesMixin, LegendSublayerMixin) {
  @Prop({ required: true }) private readonly attrs!: NodeAttributes

  private layerTypes: VueComponentMap = {} as VueComponentMap
  private internalAttrs: NodeAttributes = {}

  override created () {
    this.layerTypes = cloneDeep(LAYER_EDITOR_COMPONENTS)
    this.internalAttrs = cloneDeep(this.attrs)
  }

  private get editorComponent (): VueComponentDeclaration | null {
    const layerFill = getMainLayerFillOptions(this.layer)
    return this.layerTypes[layerFill.type] || null
  }

  private emitChanges (): void {
    this.$emit('change', this.internalAttrs)
  }

  private initAttrs (): void {
    this.internalAttrs = cloneDeep(this.attrs)
  }
}
