























import { Component, Inject, Mixins, Prop, Watch } from 'vue-property-decorator'
import { MediaOwnerNetworksCollection } from '@/utils/filter-editor/value-config/collection/media-owner-networks-collection'
import { logger } from '@/utils/log'
import { Validations } from '@/decorators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { minLength, required } from 'vuelidate/lib/validators'
import MediaNetworksSelectorModal from './selector-table/media-networks-selector-modal.vue'
import LoadMonInformationBtn from './selector-table/components/load-mon-information-btn.vue'
import MissingNetworksFeedback from './selector-table/components/missing-networks-feedback.vue'
import { UiControlMixin } from '@/components/planning/wizard/filter-editor/ui-controls/mixins/ui-control.mixin'
import { DataColumn, Dataset, DatasetRow, DatasetRows } from '@/types/common'
import { ALLGEMEIN_STELLE_SELECTION_COLUMN, PRIMARY_FIELD } from '@/constants/unit/fields'
import { MediaOwnerNetworkObj } from '@/types/planning/wizard/filter-editor'
import { processMONDataset } from './utils'
import ImportNetworksModal from './selector-table/components/import/import-networks-modal.vue'

type MediaNetworksGetter = () => Dataset

const defaultMediaNetworksGetter = (): Dataset => {
  logger.error('MediaNetworksGetter not provided!')
  return {} as Dataset
}

const defMediaTypesFactory = (): MediaNetworksGetter => defaultMediaNetworksGetter

@Component({
  components: { ImportNetworksModal, LoadMonInformationBtn, MissingNetworksFeedback, MediaNetworksSelectorModal }
})
export default class MediaNetworksUIElement extends Mixins<UiControlMixin<MediaOwnerNetworkObj[]>>(UiControlMixin) {
  @Prop({ required: true }) readonly declare config: MediaOwnerNetworksCollection

  @Inject({ from: 'getMediaOwnerNetworks', default: defMediaTypesFactory })
  getMediaOwnerNetworks!: MediaNetworksGetter;

  private identifier = PRIMARY_FIELD;

  get options (): Dataset {
    return this.getMediaOwnerNetworks()
  }

  get dataset (): DatasetRows {
    return processMONDataset(this.options.datasetRows, this.selected, this.identifier)
  }

  get columnInfos (): DataColumn[] {
    return this.options.columnInfos
  }

  private get selectedRows (): DatasetRows {
    return this.dataset.filter(row => this.selected.some(item => item.id === row[this.identifier]))
  }

  private get networksLabel (): string {
    const amountOfNetworks = this.selected.length
    return this.$tc('planning.filters.labels.select-networks-label', amountOfNetworks, { city: this.selected[0]?.id ?? '', amount: amountOfNetworks - 1 })
  }

  @Watch('selected')
  private onSelectedChange (value: string[]): void {
    this.$emit('change', value)
  }

  @Validations()
  protected validations (): RuleDecl {
    return {
      selected: {
        required,
        minLength: minLength(1)
      }
    }
  }

  private handleSelectEvent (networks: DatasetRows) {
    const selected = networks.map(network => ({
      id: network[this.identifier],
      sheetSize: (network[ALLGEMEIN_STELLE_SELECTION_COLUMN] as DatasetRow).sheetSize
    }))

    this.setSelected(selected as MediaOwnerNetworkObj[])
  }
}
