

















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { FilterSelectConfig, SelectValue } from '@/types/planning/wizard/filter-editor'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { Validations } from '@/decorators'
import { UiControlMixin } from '../mixins/ui-control.mixin'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'

@Component({
  components: { FilterParamName }
})
export default class RadioBtnUiElement extends Mixins<UiControlMixin<SelectValue>>(UiControlMixin) {
  @Prop({ required: true }) readonly declare config: FilterSelectConfig

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: { required }
    }
  }

  private onChange (event: never): void {
    this.$emit('change', event)
  }
}
