import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import {
  LAYER_WIZARD_STEP_GRANULARITY_INVALIDATE,
  LAYER_WIZARD_STEP_GRANULARITY_LOAD_DATA,
  LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSET
} from '@/store/action-types'
import {
  LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSETS,
  LAYER_WIZARD_STEP_GRANULARITY_SET_UPDATE_STATE
} from '@/store/mutation-types'
import {
  LAYER_WIZARD_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSETS,
  LAYER_WIZARD_STEP_GRANULARITY_WAS_UPDATED
} from '@/store/getter-types'

// initial state
const state = {
  geometrysets: [],
  wasUpdated: false
}

// getters
const getters = {
  [LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSETS] (state) {
    return state.geometrysets
  },
  [LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSET] (state, getters) {
    return getters[LAYER_WIZARD_GRANULARITY_GEOMETRYSET]
  },
  [LAYER_WIZARD_STEP_GRANULARITY_WAS_UPDATED] (state) {
    return state.wasUpdated
  }
}

// mutations
const mutations = {
  [LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSETS] (state, geometrysets) {
    state.geometrysets = geometrysets
  },
  [LAYER_WIZARD_STEP_GRANULARITY_SET_UPDATE_STATE] (state, wasUpdated) {
    state.wasUpdated = wasUpdated
  }
}

// actions
const actions = {
  [LAYER_WIZARD_STEP_GRANULARITY_LOAD_DATA] ({ commit }) {
    return dataDeliveryApi.getAvailableGranularities().then(results => {
      const geometrysets = results.map(geometryset => ({
        id: geometryset.id,
        name: geometryset.name
      }))

      commit(LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSETS, geometrysets)
    })
  },
  [LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSET] ({ commit }, geometryset) {
    commit(LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET, geometryset)
  },
  [LAYER_WIZARD_STEP_GRANULARITY_INVALIDATE] ({ dispatch }) {
    return dispatch(LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSET, null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
