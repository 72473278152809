import { FillType, PathStyle } from '@/types/visualization/layer/enums'
import { PATH_STYLE_CONFIGS, PathModes } from './path'
import { DEFAULT_VIS_LINE_WIDTH, DEFAULT_VIS_OPACITY } from './visualization-defaults'

export const BORDER_STYLES: PathModes = PATH_STYLE_CONFIGS

export const DEFAULT_BORDER_WIDTH = DEFAULT_VIS_LINE_WIDTH
export const DEFAULT_BORDER_FILL_MODE = FillType.Color
export const DEFAULT_BORDER_STYLE = PathStyle.Solid
export const DEFAULT_BORDER_COLOR = '#000000'
export const DEFAULT_BORDER_OPACITY = DEFAULT_VIS_OPACITY
