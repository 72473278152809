import tinycolor from 'tinycolor2'
import { DatasetLayerMixin } from './dataset-layer.mixin'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { Component } from 'vue-property-decorator'
import { Color } from 'csstype'
import { ColorModel } from '@/types/color-data'
import { LayerGeometry } from '@/types/visualization/layer/geometry'
import { DatasetColumnKey } from '@/types/common'

@Component
export default class GradientAwareMixin<TLayer extends BorderedGeometryLayer> extends DatasetLayerMixin<TLayer> {
  protected calculateGradientColor (ratio, startRgb, endRgb): tinycolor.Instance {
    return tinycolor({
      r: startRgb.r + (endRgb.r - startRgb.r) * ratio,
      g: startRgb.g + (endRgb.g - startRgb.g) * ratio,
      b: startRgb.b + (endRgb.b - startRgb.b) * ratio
    })
  }

  protected calculateGradientOpacity (ratio, startRgb, endRgb): ColorModel['a'] {
    return startRgb.a + (endRgb.a - startRgb.a) * ratio
  }

  protected calculateMinGradientValue (column: string): number {
    let min = Infinity

    for (const row of this.dataRows) {
      min = Math.min(min, row.meta[column] as number)
    }

    return min
  }

  protected calculateMaxGradientValue (column: string): number {
    let max = -Infinity

    for (const row of this.dataRows) {
      max = Math.max(max, row.meta[column] as number)
    }

    return max
  }

  protected calculateRgb (color: Color | undefined, opacity: number | undefined): ColorModel['rgba'] | undefined {
    if (color === undefined || opacity === undefined) {
      return
    }

    return tinycolor(color)
      .setAlpha(opacity)
      .toRgb()
  }

  protected calculateGradientRatio (row: LayerGeometry, column: DatasetColumnKey, minValue: number, maxValue: number): number {
    // If all dataset values are the same we just use gradient start color.
    if (maxValue === minValue) {
      return 0
    }

    return (row.meta[column] as number - minValue) / (maxValue - minValue)
  }
}
