import { Routes } from '@/router/constants/routes'
import { AuthPermission } from '@/types/auth/permissions'
import { i18n } from '@/locales/i18n'
import { UniqueRouteConfig } from '@/router/types'
const VisualizationDashboard = () => import('@/views/visualization/children/Visualization-dashboard.vue')
const VisualizationDetails = () => import('@/views/visualization/children/visualization-details/Visualization-details.vue')

export const visualizationRoutes: Array<UniqueRouteConfig> = [
  {
    path: '',
    name: Routes.VISUALIZATION_DASHBOARD,
    component: VisualizationDashboard,
    props: true,
    meta: {
      routeId: Routes.VISUALIZATION_DASHBOARD,
      get name () { return i18n.t('common.labels.visualization') },
      can: AuthPermission.ViewVisualization
    }
  },
  {
    path: ':id',
    name: Routes.VISUALIZATION_DETAILS,
    component: VisualizationDetails,
    props: true,
    meta: {
      routeId: Routes.VISUALIZATION_DETAILS,
      can: AuthPermission.ViewVisualization
    }
  }
]
