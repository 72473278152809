import { Component, Mixins } from 'vue-property-decorator'
import { PolylineOptions } from 'leaflet'
import { MultiPolygon, MultiPolygonOptions } from '@/lib/leaflet/layer/multi-polygons/multi-polygon/multi-polygon'
import {
  DEFAULT_POLYGON_LABEL_FONT_COLOR,
  DEFAULT_POLYGON_LABEL_FONT_SIZE,
  DEFAULT_POLYGON_LABEL_STROKE_COLOR,
  DEFAULT_POLYGON_LABEL_STROKE_WIDTH,
  DEFAULT_POLYGON_HATCHING
} from '@/components/visualization/layer-settings/constants'
import { MapCompProp } from '@/decorators'
import { ItemsGroup, ItemsGroupMapper, MapObjectOptionComputed, MapObjectOptionGetter } from '../../types'
import { PathsColorGroupMixin } from './paths-color-group.mixin'
import { MultiPolylinesMixin } from './multi-polylines.mixin'
import { get } from 'lodash'
import { HatchingConfig } from '@/types/visualization/layer/style'

type LabelTextType<TItem> = MapObjectOptionComputed<TItem[], string>
type LabelTextGetter<TItem> = MapObjectOptionGetter<TItem[], string>

@Component
export class MultiPolygonsMixin<TItem>
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  extends Mixins<MultiPolylinesMixin<TItem, MultiPolygon>>(MultiPolylinesMixin) {
  @MapCompProp({ layerProp: true, default: true }) readonly declare fill: boolean
  @MapCompProp({ layerProp: true, default: undefined }) readonly labelText?: LabelTextType<TItem>
  @MapCompProp({ layerProp: true, default: DEFAULT_POLYGON_LABEL_FONT_COLOR }) readonly labelFontColor!: string
  @MapCompProp({ layerProp: true, default: DEFAULT_POLYGON_LABEL_STROKE_COLOR }) readonly labelStrokeColor!: string
  @MapCompProp({ layerProp: true, default: DEFAULT_POLYGON_LABEL_STROKE_WIDTH }) readonly labelStrokeWidth!: number
  @MapCompProp({ layerProp: true, default: DEFAULT_POLYGON_LABEL_FONT_SIZE }) readonly labelTextSize!: number
  @MapCompProp({ layerProp: true, default: () => DEFAULT_POLYGON_HATCHING }) readonly hatching!: HatchingConfig
  @MapCompProp({ layerProp: true, default: false }) readonly forceLabel!: boolean
  @MapCompProp({ layerProp: true, default: false }) readonly union!: boolean

  protected get polygonOptions (): MultiPolygonOptions {
    return {
      ...this.polyLineOptions,
      labelText: this.labelText as string,
      labelFontColor: this.labelFontColor,
      labelStrokeColor: this.labelStrokeColor,
      labelStrokeWidth: this.labelStrokeWidth,
      labelTextSize: this.labelTextSize,
      forceLabel: this.forceLabel,
      union: this.union,
      hatching: this.hatching
    }
  }

  protected override get groupMapper (): ItemsGroupMapper<TItem, MultiPolygonOptions> {
    const parentMapperGetter = get(PathsColorGroupMixin, 'options.computed.groupMapper.get') as () => ItemsGroupMapper<TItem, MultiPolygonOptions>
    const parentMapper: ItemsGroupMapper<TItem, MultiPolygonOptions> = parentMapperGetter.call(this)
    const getLabelText: LabelTextGetter<TItem> = this.makeLabelTextGetter()

    return (items: TItem[]): ItemsGroup<TItem, MultiPolygonOptions> => {
      const parentGroup = parentMapper(items)

      return {
        items,
        options: {
          ...parentGroup.options,
          labelText: getLabelText(items)
        }
      }
    }
  }

  protected makeLabelTextGetter (): LabelTextGetter<TItem> {
    const labelText = this.labelText

    if (labelText == null) {
      return (): string => ''
    }

    if (this.union) {
      if (typeof labelText === 'function') {
        return (items: TItem[]): string => labelText(items)
      } else {
        return (): string => labelText
      }
    }

    return typeof labelText === 'function'
      ? (items: TItem[]): string => labelText(items)
      : (): string => labelText
  }

  protected setLabelText (): void {
    // Handled by items grouping.
  }

  protected setLabelFontColor (newVal: string): void {
    this.mapObject.setStyle({ labelFontColor: newVal } as PolylineOptions)
  }

  protected setLabelStrokeColor (newVal: string): void {
    this.mapObject.setStyle({ labelStrokeColor: newVal } as PolylineOptions)
  }

  protected setLabelStrokeWidth (newVal: number): void {
    this.mapObject.setStyle({ labelStrokeWidth: newVal } as PolylineOptions)
  }

  protected setLabelTextSize (newVal: number): void {
    this.mapObject.setStyle({ labelTextSize: newVal } as PolylineOptions)
  }

  protected setForceLabel (newVal: boolean): void {
    this.mapObject.setStyle({ forceLabel: newVal } as PolylineOptions)
  }

  protected setUnion (newVal: boolean): void {
    this.mapObject.invoke('setUnion', newVal)
  }
}
