import { OrderDTO } from '@/types/api'
import { Order } from '@/types/orders'
import { OrderStatus } from '@/types/orders/enums'
import moment from '@/lib/moment'

export function parseOrder (dto: OrderDTO): Order {
  return {
    assignedTo: dto.assignedTo,
    createdBy: dto.createdBy,
    customerId: dto.customerId,
    id: dto.id,
    internalCompany: dto.internalCompany,
    lastEditBy: dto.lastEditBy,
    name: dto.name,
    planningId: dto.planningId,
    stampCreate: moment(dto.stampCreate).toDate(),
    stampEdit: moment(dto.stampEdit).toDate(),
    status: dto.orderState ?? dto.state ?? OrderStatus.Draft, // TODO: Unify status props in backend and use only one!
    tags: dto.tags ?? [],
    userComments: [],
    watchingUsers: dto.watchingUsers ?? []
  }
}
