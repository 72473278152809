import { Component, PluginObject, VNode } from 'vue'
import OrderDropValidator from './order-drop-validator.vue'
import {
  DropOrderResult,
  DropOrderValidationOptions,
  ValidateDropOrderFunction
} from '@/plugins/order-drop-validator/types'

declare module 'vue/types/vue' {
  interface Vue {
    $validateOrderDrop: ValidateDropOrderFunction;
  }
}

function configureDialog (validatorVNode: VNode, title: string) {
  const props = {
    size: 'lg',
    centered: true
  }

  const slots = {
    default: validatorVNode,
    'modal-title': title
  }

  return { props, slots }
}

export class OrderDropValidationPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$validateOrderDrop = async function (component: Component | null, title: string, options: DropOrderValidationOptions = {}): Promise<DropOrderResult> {
      if (component === null) {
        const submitted = await this.$modalDialog.msgBoxConfirm(title)

        return { submitted }
      }

      const validatorVNode = this.$createElement(component, {
        props: options
      })

      const { props, slots } = configureDialog(validatorVNode, title)

      const submitted = await this.$modalDialog.msgBoxConfirmValid(slots, props)

      return {
        submitted,
        result: (validatorVNode.componentInstance as OrderDropValidator).transferData
      }
    }
  }
}
