import { LocaleMessages } from 'vue-i18n'

export default {
  en: {
    'choose-plz-column': 'Choose a column containing plz5/8 values',
    'choose-rating-column': 'Choose a column containing rating values'
  },
  de: {
    'choose-plz-column': 'Auswahl einer Spalte mit PLZ 5/8-Werten',
    'choose-rating-column': 'Auswahl einer Spalte mit den Rankingwerten'
  }
} as LocaleMessages
