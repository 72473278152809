import { FILTER_AREA, FILTER_CITY } from '@/constants/scoring'
import { PathType } from '@/types/enums'

export const supportedGeometrysetsForMON = [
  PathType.Country,
  PathType.City,
  PathType.Bundesland,
  PathType.LandKreis,
  PathType.Nielsen,
  PathType.OKZ,
  PathType.KKZ,
  PathType.Regierungsbezirk
]

export const supportedAreaFiltersForMON = [FILTER_CITY, FILTER_AREA]

export const areaFiltersDataPath = {
  [FILTER_AREA]: '0.AreaKeys',
  [FILTER_CITY]: '0.CityNames'
}
