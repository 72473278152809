import { i18n } from '@/locales/i18n'
import {
  NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_ENABLED,
  NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_EMPTY,
  NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_INVALID,
  NEW_VISUALIZATION_WIZARD_NAME,
  NEW_VISUALIZATION_WIZARD_TAB_PROPS_ERRORS,
  NEW_VISUALIZATION_WIZARD_TAB_PROPS_STEP_ID,
  VISUALIZATION_GET_ERRORS
} from '@/store/getter-types'
import {
  NEW_VISUALIZATION_WIZARD_TAB_PROPS_VALIDATE
} from '@/store/action-types'
import { VICINITY_IMPORT_PARSE } from '@/components/planning/vicinity-import-wizard/constants/steps'

// initial state
const state = {
  stepId: VICINITY_IMPORT_PARSE
}

// getters
const getters = {
  [NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_ENABLED] () {
    return true
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_EMPTY] () {
    return false
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PROPS_IS_INVALID] (state, getters) {
    return getters[NEW_VISUALIZATION_WIZARD_TAB_PROPS_ERRORS].length > 0
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PROPS_ERRORS] (state, getters) {
    const customVisualization = {
      name: getters[NEW_VISUALIZATION_WIZARD_NAME],
      visualizationId: null
    }

    return getters[VISUALIZATION_GET_ERRORS](customVisualization).map(error => {
      return i18n.t(error)
    })
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PROPS_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [NEW_VISUALIZATION_WIZARD_TAB_PROPS_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[NEW_VISUALIZATION_WIZARD_TAB_PROPS_ERRORS])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
