import {
  BOOKING_TIME,
  PLAN_WIZARD_ACTIVE_FILTER,
  PLAN_WIZARD_CAMPAIGN_CUSTOMER,
  PLAN_WIZARD_CAMPAIGN_DISCOUNT,
  PLAN_WIZARD_CAMPAIGN_ID,
  PLAN_WIZARD_CAMPAIGN_NAME,
  PLAN_WIZARD_CAMPAIGN_STATUS,
  PLAN_WIZARD_CAMPAIGN_STATUS_INVALIDATED,
  PLAN_WIZARD_START_DATE,
  PLAN_WIZARD_END_DATE,
  PLAN_WIZARD_FILTER_BY_ID,
  PLAN_WIZARD_FILTERS,
  PLAN_WIZARD_GENERATE_FILTER_ID,
  PLAN_WIZARD_GET_BOOKING_TIME_BY_MEDIA_TYPE,
  PLAN_WIZARD_IS_FILTER_NAME_UNIQUE,
  PLAN_WIZARD_IS_NEW_CAMPAIGN,
  PLAN_WIZARD_IS_VALID,
  PLAN_WIZARD_LINK_NAMESPACE,
  PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA,
  PLAN_WIZARD_NAMESPACE,
  PLAN_WIZARD_SELECTED_COMMON_SETTINGS,
  PLAN_WIZARD_SELECTED_FILTERS,
  PLAN_WIZARD_SELECTED_FLIGHTS,
  PLAN_WIZARD_SELECTED_GOALS,
  PLAN_WIZARD_SELECTED_LOCATIONS,
  PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE,
  PLAN_WIZARD_SELECTED_TYPES,
  PLAN_WIZARD_STEP_LEAVE_CALLBACK,
  PLAN_WIZARD_WAS_UPDATED,
  SITE_TYPES,
  WIZARD_TAB_COMMON_IS_ENABLED,
  WIZARD_TAB_COMMON_IS_INVALID,
  WIZARD_TAB_COMMON_SETTINGS_IS_ENABLED,
  WIZARD_TAB_COMMON_SETTINGS_IS_INVALID,
  WIZARD_TAB_FILTERS_IS_ENABLED,
  WIZARD_TAB_FILTERS_IS_INVALID,
  WIZARD_TAB_FLIGHTS_IS_ENABLED,
  WIZARD_TAB_FLIGHTS_IS_INVALID,
  WIZARD_TAB_GOALS_IS_ENABLED,
  WIZARD_TAB_GOALS_IS_INVALID,
  WIZARD_TAB_LOCATION_IS_ENABLED,
  WIZARD_TAB_LOCATION_IS_INVALID,
  WIZARD_TAB_MEDIA_TYPE_IS_ENABLED,
  WIZARD_TAB_MEDIA_TYPE_IS_INVALID,
  PLAN_WIZARD_FORCE_CONTINUOUS_DISPLAY_FLAG,
  PLAN_WIZARD_CONSIDER_STATUS_UNKNOWN_FLAG,
  PLAN_WIZARD_SELECTED_RANKINGS,
  WIZARD_TAB_RANKINGS_IS_ENABLED,
  WIZARD_TAB_RANKINGS_IS_INVALID
} from '@/store/getter-types'
import {
  isCommonSettingsFilterNode,
  isFilterFunctionNode,
  isFlightFilterNode,
  isGoalFilterNode,
  isLocationFilterNode,
  isMediaTypeFilterNode,
  isRankingFunctionFilterNode,
  isSingleMediaTypeFilterNode
} from '@/utils/plan-wizard-steps'
import { generateFilterId, makeUniqueNameChecker } from '@/store/planning-wizard/utils'
import { isPeriodOfAType, isWeek, isDecade } from '@/types/planning/guards'
import { PlanningStatus } from '@/types/planning/enums'
import { WizardStore } from '@/store/planning-wizard/index'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { Customer } from '@/views/planning/children/campaign-export/types'

const getMONBookingTimeFilter = (filter, dataset) => {
  const networkId = filter.scoringFunction.data[0].MediaOwnerNetworks[0].id
  const networkObj = dataset.datasetRows.find(item => item.ExternalID === networkId)

  if (networkObj) {
    return networkObj.BookingGranularity.toLowerCase().includes('w') ? isWeek : isDecade
  }

  return isWeek
}

export default {
  [PLAN_WIZARD_CAMPAIGN_ID] (state: WizardStore) {
    return state.campaignId
  },
  [PLAN_WIZARD_IS_NEW_CAMPAIGN] (state: WizardStore) {
    return state.campaignId == null
  },
  [PLAN_WIZARD_CAMPAIGN_NAME] (state: WizardStore) {
    return state.campaignName
  },
  [PLAN_WIZARD_CAMPAIGN_CUSTOMER] (state: WizardStore): Customer | null {
    return state.customer
  },
  [PLAN_WIZARD_CAMPAIGN_DISCOUNT] (state: WizardStore): number {
    return state.discount
  },
  [PLAN_WIZARD_FORCE_CONTINUOUS_DISPLAY_FLAG] (state: WizardStore) {
    return state.forceContinuousDisplay
  },
  [PLAN_WIZARD_CONSIDER_STATUS_UNKNOWN_FLAG] (state: WizardStore) {
    return state.considerUnknownAvailability
  },
  [PLAN_WIZARD_START_DATE] (state: WizardStore) {
    return state.startDate
  },
  [PLAN_WIZARD_END_DATE] (state: WizardStore) {
    return state.endDate
  },
  [PLAN_WIZARD_FILTERS] (state: WizardStore) {
    return state.filters
  },
  [PLAN_WIZARD_ACTIVE_FILTER] (state: WizardStore): ScoringFunctionNode | null {
    return state.activeFilter
  },
  [PLAN_WIZARD_FILTER_BY_ID] (state: WizardStore) {
    return filterId => state.filters.find(filter => filter.id === filterId)
  },
  [PLAN_WIZARD_SELECTED_LOCATIONS] (state: WizardStore): ScoringFunctionNode[] {
    return state.filters.filter(isLocationFilterNode)
  },
  [PLAN_WIZARD_SELECTED_LOCATIONS_FILTERABLE] (_state: WizardStore, getters): ScoringFunctionNode[] {
    return getters[PLAN_WIZARD_SELECTED_LOCATIONS]
  },
  [PLAN_WIZARD_SELECTED_TYPES] (state: WizardStore): ScoringFunctionNode[] {
    return state.filters.filter(isMediaTypeFilterNode)
  },
  [PLAN_WIZARD_SELECTED_FLIGHTS] (state: WizardStore): ScoringFunctionNode[] {
    return state.filters.filter(isFlightFilterNode)
  },
  [PLAN_WIZARD_SELECTED_FILTERS] (state: WizardStore): ScoringFunctionNode[] {
    return state.filters.filter(isFilterFunctionNode)
  },
  [PLAN_WIZARD_SELECTED_RANKINGS] (state: WizardStore): ScoringFunctionNode[] {
    return state.filters.filter(isRankingFunctionFilterNode)
  },
  [PLAN_WIZARD_SELECTED_GOALS] (state: WizardStore): ScoringFunctionNode[] {
    return state.filters.filter(isGoalFilterNode)
  },
  [PLAN_WIZARD_SELECTED_COMMON_SETTINGS] (state: WizardStore): ScoringFunctionNode[] {
    return state.filters.filter(isCommonSettingsFilterNode)
  },
  [PLAN_WIZARD_GENERATE_FILTER_ID] () {
    return () => generateFilterId()
  },
  [PLAN_WIZARD_IS_FILTER_NAME_UNIQUE] (_state: WizardStore, getters): (name: string, filterId: string) => boolean {
    return makeUniqueNameChecker(getters[PLAN_WIZARD_FILTERS])
  },
  [PLAN_WIZARD_WAS_UPDATED] (state: WizardStore) {
    return state.wasUpdated
  },
  [PLAN_WIZARD_IS_VALID] (_state: WizardStore, _getters, _rootState, rootGetters) {
    const gettersToCheck = [
      [WIZARD_TAB_COMMON_IS_ENABLED, WIZARD_TAB_COMMON_IS_INVALID],
      [WIZARD_TAB_LOCATION_IS_ENABLED, WIZARD_TAB_LOCATION_IS_INVALID],
      [WIZARD_TAB_MEDIA_TYPE_IS_ENABLED, WIZARD_TAB_MEDIA_TYPE_IS_INVALID],
      [WIZARD_TAB_FLIGHTS_IS_ENABLED, WIZARD_TAB_FLIGHTS_IS_INVALID],
      [WIZARD_TAB_FILTERS_IS_ENABLED, WIZARD_TAB_FILTERS_IS_INVALID],
      [WIZARD_TAB_RANKINGS_IS_ENABLED, WIZARD_TAB_RANKINGS_IS_INVALID],
      [WIZARD_TAB_GOALS_IS_ENABLED, WIZARD_TAB_GOALS_IS_INVALID],
      [WIZARD_TAB_COMMON_SETTINGS_IS_ENABLED, WIZARD_TAB_COMMON_SETTINGS_IS_INVALID]
    ]

    return gettersToCheck.every(([enabledGetter, invalidGetter]) => {
      return rootGetters[enabledGetter] && !rootGetters[invalidGetter]
    })
  },
  [PLAN_WIZARD_GET_BOOKING_TIME_BY_MEDIA_TYPE] (_state: WizardStore, getters) {
    return (filter) => {
      if (!isSingleMediaTypeFilterNode(filter)) {
        const dataset = getters[PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA](filter.areaId)

        return getters[BOOKING_TIME].filter(getMONBookingTimeFilter(filter, dataset))
      }

      const originalSiteType = getters[SITE_TYPES].find(siteType => siteType.value === filter.name)
      return getters[BOOKING_TIME].filter(isPeriodOfAType(originalSiteType.bookingTypes))
    }
  },
  [PLAN_WIZARD_NAMESPACE] (state: WizardStore) {
    return state.wizardNamespace
  },
  [PLAN_WIZARD_LINK_NAMESPACE] (_state: WizardStore, getters) {
    return (getter) => {
      return getters[PLAN_WIZARD_NAMESPACE] + '/' + getter
    }
  },
  [PLAN_WIZARD_STEP_LEAVE_CALLBACK] (state: WizardStore) {
    return state.stepLeaveCallback
  },
  [PLAN_WIZARD_CAMPAIGN_STATUS] (state: WizardStore) {
    return state.campaignStatus
  },
  [PLAN_WIZARD_CAMPAIGN_STATUS_INVALIDATED] (state: WizardStore, getters) {
    const wizardIsValid = getters[PLAN_WIZARD_IS_VALID]

    if (!wizardIsValid) {
      // If current campaign state is invalid (wizard not finished or etc.)
      // the campaign can have only a draft status.
      return PlanningStatus.Draft
    }

    const initialStatus = state.campaignStatus

    if (initialStatus === PlanningStatus.Draft) {
      // If campaign is valid, but it's initial status was draft
      // then we assume it is filled now and can be moved further in the flow.
      return PlanningStatus.Completed
    }

    return initialStatus
  }
}
