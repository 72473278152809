















import { Component, Vue } from 'vue-property-decorator'
import LogoSpinner from '@/components/shared/logo-spinner.vue'
import { LoadingOverlayComponent } from '@/plugins/route-loading/type'

@Component({
  components: { LogoSpinner }
})
export default class RouteLoadingOverlay extends Vue implements LoadingOverlayComponent {
  private overlayIsShow = false

  showOverlay (): void {
    this.overlayIsShow = true
  }

  hideOverlay (): void {
    this.overlayIsShow = false
  }
}
