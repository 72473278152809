import { overSome, get } from 'lodash'
import { AnyScoringFunctionNode, ScoringFunction } from '@/types/planning/scoring/functions'
import {
  isCommonGoalFunction,
  isCommonSettingsFunction,
  isFilterScoringFunction,
  isFlightFunction,
  isGoalFunction,
  isLocationFunction,
  isMediaTypeFunction,
  isNetworkMediaTypeFunction,
  isRankingScoringFunction,
  isSingleMediaTypeFunction
} from './scoring-function-steps'
import { getScoringFunctionConfigByName } from '@/constants/functions/scoring-functions'

type FilterNodeTypeChecker = (filterNode: AnyScoringFunctionNode) => boolean

export function getFilterFunctionName (filterNode: AnyScoringFunctionNode): string {
  return get(filterNode, 'scoringFunction.name', '')
}

export function getFilterFunctionSettings (filterNode: AnyScoringFunctionNode): ScoringFunction {
  return getScoringFunctionConfigByName(getFilterFunctionName(filterNode))
}

export function isLocationFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isLocationFunction(getFilterFunctionSettings(filterNode))
}

export function isMediaTypeFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isMediaTypeFunction(getFilterFunctionSettings(filterNode))
}

export function isSingleMediaTypeFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isSingleMediaTypeFunction(getFilterFunctionSettings(filterNode))
}

export function isNetworkMediaTypeFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isNetworkMediaTypeFunction(getFilterFunctionSettings(filterNode))
}

export function isFlightFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isFlightFunction(getFilterFunctionSettings(filterNode))
}

export function isRankingFunctionFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isRankingScoringFunction(getFilterFunctionSettings(filterNode))
}

export function isFilterFunctionNode (filterNode: AnyScoringFunctionNode): boolean {
  return isFilterScoringFunction(getFilterFunctionSettings(filterNode))
}

export function isGoalFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isGoalFunction(getFilterFunctionSettings(filterNode)) && !!filterNode.areaId
}

export function isCommonSettingsFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isCommonSettingsFunction(getFilterFunctionSettings(filterNode)) && !filterNode.areaId
}

export function isCommonGoalFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isCommonGoalFunction(getFilterFunctionSettings(filterNode)) && !filterNode.areaId
}

export function isMergedFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isCommonGoalFilterNode(filterNode)
}

export function isExclusionFilterNode (filterNode: AnyScoringFunctionNode): boolean {
  return isCommonSettingsFilterNode(filterNode) && !isCommonGoalFilterNode(filterNode)
}

export const isRootFilterNode: FilterNodeTypeChecker = overSome(
  isLocationFilterNode,
  isCommonSettingsFilterNode
)

export const isSequentialNode: FilterNodeTypeChecker = overSome(
  isFilterFunctionNode,
  isRankingFunctionFilterNode,
  isGoalFilterNode,
  isCommonGoalFilterNode
)
