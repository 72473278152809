import objectHash from 'object-hash'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { omit } from 'lodash'

type NodeOwnKey = Required<Pick<ScoringFunctionNode, 'id'>>
type LocationChildKey = Required<Pick<ScoringFunctionNode, 'areaId'>>
type SiteTypeChildKey = LocationChildKey & Required<Pick<ScoringFunctionNode, 'typeId'>>

type PossibleNodeKey = NodeOwnKey & LocationChildKey & SiteTypeChildKey

export type NodeParentKey = LocationChildKey | SiteTypeChildKey
export type NodeKey = NodeOwnKey | (NodeOwnKey & NodeParentKey)

const possibleKeys: (keyof PossibleNodeKey)[] = ['areaId', 'typeId', 'id']

function * nodeKeyPairs (filterNode: ScoringFunctionNode): Generator<[string, string]> {
  for (const possibleKey of possibleKeys) {
    const keyValue = filterNode[possibleKey]

    if (keyValue !== undefined) {
      yield [possibleKey, keyValue]
    }
  }
}

export function getNodeParentKey (filterNode: ScoringFunctionNode): NodeParentKey {
  return omit(getNodeKey(filterNode), 'id') as NodeParentKey
}

export function getNodeKey (filterNode: ScoringFunctionNode): NodeKey {
  const result = {
    id: filterNode.id
  }

  for (const [keyName, keyValue] of nodeKeyPairs(filterNode)) {
    result[keyName] = keyValue
  }

  return result
}

export function getNodeKeyAsArray (filterNode: ScoringFunctionNode): string[] {
  const result: string[] = []

  for (const [, keyValue] of nodeKeyPairs(filterNode)) {
    result.push(keyValue)
  }

  return result
}

export function getNodeKeyAsString (filterNode: ScoringFunctionNode): string {
  return getNodeKeyAsArray(filterNode).join('|')
}

export function getNodeChildren (parent: ScoringFunctionNode, filters: ScoringFunctionNode[]): ScoringFunctionNode[] {
  const parentKey = getNodeKeyAsString(parent)

  return filters.filter((filter) => {
    const filterKey = getNodeKeyAsString(filter)
    const idx = filterKey.lastIndexOf('|')

    return idx === parentKey.length && filterKey !== parentKey && filterKey.startsWith(parentKey)
  })
}

export function isParentKey (filterNode: ScoringFunctionNode, key: string): boolean {
  const parentKeys = getNodeKeyAsArray(filterNode).slice(0, -1)
  return parentKeys.includes(key)
}

export function isSelfOrParentKey (filterNode: ScoringFunctionNode, key: string): boolean {
  return filterNode.id === key || isParentKey(filterNode, key)
}

export function willCampaignNodesProduceDifferentScore (filtersA: ScoringFunctionNode[], filtersB: ScoringFunctionNode[]) {
  if (filtersA.length !== filtersB.length) {
    return true
  }

  const filtersAHashArray = filtersA.map(filter => objectHash(filter.scoringFunction))
  const filtersBHashArray = filtersB.map(filter => objectHash(filter.scoringFunction))

  return !filtersAHashArray.every(hashA => filtersBHashArray.some(hashB => hashB === hashA))
}
