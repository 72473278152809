


















import { Component, Mixins } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { cloneDeep } from 'lodash'
import { VueClassDef } from '@/types/vue'
import { QueryBuilderItem } from '@/types/query-builder'
import { isQueryBuilderGroup, isQueryBuilderRule } from '@/types/query-builder/guards'
import { QueryGroupMixin } from '../mixins/query-group.mixin'
import QueryBuilderGroup from './query-builder-group.vue'
import QueryBuilderRule from './query-builder-rule.vue'

const DEPTH_COLORS_COUNT = 4

@Component
export default class QueryBuilderChildren extends Mixins(QueryGroupMixin) {
  private get childClass (): VueClassDef {
    return `query__child--${this.depth % DEPTH_COLORS_COUNT}`
  }

  protected getChildComponent (child: QueryBuilderItem): VueConstructor | void {
    if (isQueryBuilderGroup(child)) {
      return QueryBuilderGroup
    }

    if (isQueryBuilderRule(child)) {
      return QueryBuilderRule
    }
  }

  private onChange<T extends QueryBuilderItem> (index: number, child: T, query: T['query']): void {
    child = cloneDeep(child)
    child.query = query
    this.$emit('change', index, child)
  }
}

