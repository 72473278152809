



















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import MultiProgress from '@/components/shared/multi-progress.vue'
import { AddressesGeocodingMixin } from '../../../mixins/addresses-geocoding.mixin'
import GeocodingButton from './geocoding-button.vue'
import ActionResults from '@/components/shared/action-results/action-results.vue'
import GeocodingDetailsModal from './geocoding-details/geocoding-details-modal.vue'
import GeocodingDetailsBtn from './geocoding-details/geocoding-details-btn.vue'

@Component({
  components: { GeocodingDetailsBtn, GeocodingDetailsModal, ActionResults, GeocodingButton, MultiProgress }
})
export default class AddressesGeocoding extends Mixins(AddressesGeocodingMixin) {
  @Prop({ default: () => [] }) addresses!: string[]

  get hasErrors (): boolean {
    return this.errorAddressesCount > 0
  }

  startGeocoding (): void {
    this.$parent.$v.$touch()

    if (this.$parent.$v.$invalid) {
      return
    }

    this.geocodeAddresses(this.addresses).then(results => {
      this.$emit('finish', results)
    })
      .catch(() => { /* */ })
  }
}
