import { GET_CURRENT_VISUALISATION_LOCATION, GET_VISUALISATION_LOCATIONS } from '@/store/getter-types'
import {
  ADD_VISUALIZATION_LOCATION,
  REMOVE_VISUALIZATION_LOCATION,
  SET_CURRENT_VISUALISATION_LOCATION,
  SET_VISUALIZATION_LOCATIONS
} from '@/store/mutation-types'
import { nanoid } from 'nanoid'
import { VisualizationLocation } from '@/types/visualization/visualization-location'

const state = {
  currentLocation: {},
  locations: []
}

const getters = {
  [GET_VISUALISATION_LOCATIONS] (state): VisualizationLocation[] {
    return state.locations
  },
  [GET_CURRENT_VISUALISATION_LOCATION] (state) {
    return { ...state.currentLocation }
  }
}

const mutations = {
  [SET_VISUALIZATION_LOCATIONS] (state, payload) {
    state.locations = payload
  },
  [ADD_VISUALIZATION_LOCATION] (state, payload) {
    const newLocation: VisualizationLocation = { id: nanoid(6), ...payload }
    state.locations.unshift(newLocation)
  },
  [REMOVE_VISUALIZATION_LOCATION] (state, payload: VisualizationLocation) {
    state.locations = state.locations.filter(location => location.id !== payload.id)
  },
  [SET_CURRENT_VISUALISATION_LOCATION] (state, payload: VisualizationLocation) {
    state.currentLocation = payload
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
