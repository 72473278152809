import {
  PLAN_WIZARD_FILTERS,
  PLAN_WIZARD_SELECTED_LOCATIONS,
  WIZARD_STEP_LOCATION_ASSIGNED_FILTERS,
  WIZARD_STEP_LOCATION_FUNCTIONS,
  WIZARD_STEP_LOCATION_HAS_ASSIGNED_FILTERS,
  WIZARD_STEP_LOCATION_HAS_LOCATIONS,
  WIZARD_STEP_LOCATION_SELECTED_LOCATIONS,
  WIZARD_STEP_LOCATION_ALL_LOCATIONS_VALID,
  WIZARD_STEP_LOCATION_IS_UNIQUE_NAME,
  WIZARD_STEP_LOCATION_ALL_NAMES_UNIQUE
} from '@/store/getter-types'
import { isLocationFunction } from '@/utils/plan-wizard-steps'
import { PLAN_WIZARD_REMOVE_FILTER, WIZARD_STEP_LOCATION_REMOVE_LOCATION } from '@/store/action-types'
import { checkFilterNamesUnique, checkFiltersValidity, makeUniqueNameChecker } from '@/store/planning-wizard/utils'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { SCORING_FUNCTIONS } from '@/constants/functions/scoring-functions'

// initial state
const state = {}

// getters
const getters = {
  [WIZARD_STEP_LOCATION_SELECTED_LOCATIONS] (_state, getters): ScoringFunctionNode[] {
    return getters[PLAN_WIZARD_SELECTED_LOCATIONS]
  },
  [WIZARD_STEP_LOCATION_HAS_LOCATIONS] (_state, getters): boolean {
    return getters[WIZARD_STEP_LOCATION_SELECTED_LOCATIONS].length > 0
  },
  [WIZARD_STEP_LOCATION_FUNCTIONS] (): string[] {
    return SCORING_FUNCTIONS
      .filter(func => isLocationFunction(func))
      .map(func => func.name)
  },
  [WIZARD_STEP_LOCATION_ASSIGNED_FILTERS] (_state, getters): (locationId: string) => ScoringFunctionNode[] {
    return locationId => {
      return getters[PLAN_WIZARD_FILTERS].filter(item => item.areaId && item.areaId === locationId)
    }
  },
  [WIZARD_STEP_LOCATION_HAS_ASSIGNED_FILTERS] (_state, getters): (locationId: string) => boolean {
    return (locationId) => getters[WIZARD_STEP_LOCATION_ASSIGNED_FILTERS](locationId).length !== 0
  },
  [WIZARD_STEP_LOCATION_ALL_LOCATIONS_VALID] (_state, getters): boolean {
    return checkFiltersValidity(getters[WIZARD_STEP_LOCATION_SELECTED_LOCATIONS] as ScoringFunctionNode[])
  },
  [WIZARD_STEP_LOCATION_IS_UNIQUE_NAME] (_state, getters): (name: string, filterId: string) => boolean {
    return makeUniqueNameChecker(getters[WIZARD_STEP_LOCATION_SELECTED_LOCATIONS] as ScoringFunctionNode[])
  },
  [WIZARD_STEP_LOCATION_ALL_NAMES_UNIQUE] (_state, getters): boolean {
    return checkFilterNamesUnique(getters[WIZARD_STEP_LOCATION_SELECTED_LOCATIONS] as ScoringFunctionNode[])
  }
}

// actions
const actions = {
  async [WIZARD_STEP_LOCATION_REMOVE_LOCATION] ({ getters, dispatch }, filter: ScoringFunctionNode): Promise<void> {
    for await (const item of getters[WIZARD_STEP_LOCATION_ASSIGNED_FILTERS](filter.id)) {
      dispatch(PLAN_WIZARD_REMOVE_FILTER, item)
    }

    return dispatch(PLAN_WIZARD_REMOVE_FILTER, filter)
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
