import { Control, DomUtil, Map } from 'leaflet'
import { StaticLayerPosition } from '@/types/visualization/layer/enums'
import { MapProviderConfig } from '@/lib/leaflet/control/tiles-source-control'
import { providersConfig } from '@/lib/leaflet/control/tiles-source-control/providers'

export class CustomAttributionControl extends Control.Attribution {
  private _map!: Map
  private _defaultProvider = providersConfig.mapProviders.options.find(option => option.url === DEFAULT_MAP_TILE_URL) as MapProviderConfig
  private _attributionsSet = new Set([DEFAULT_MAP_TILE_URL])

  public override options = {
    position: StaticLayerPosition.BottomLeft
  }

  // Default attribution in custom position
  public override addTo = (map) => {
    this.remove()
    this._map = map

    const container = this.onAdd?.(map) as HTMLElement
    const pos = this.getPosition()
    const parent = map._controlContainer

    DomUtil.addClass(container, 'leaflet-control custom-attribution')

    parent.appendChild(container)

    if (pos.indexOf('bottom') !== -1) {
      DomUtil.addClass(container, 'custom-attribution__bottom')
    }

    if (pos.indexOf('right') !== -1) {
      DomUtil.addClass(container, 'custom-attribution__right')
    }

    this._map.on('unload', this.remove, this)

    return this
  }

  public updateDefaultMapProvider (mapProvider: MapProviderConfig): void {
    this.revokeMapProvider(this._defaultProvider)
    this.applyMapProvider(mapProvider)

    this._defaultProvider = mapProvider
  }

  public applyMapProvider (mapProvider: MapProviderConfig): void {
    this._attributionsSet.add(mapProvider.url)
    this.addAttribution(mapProvider.attribution)
  }

  public revokeMapProvider (mapProvider: MapProviderConfig): void {
    this._attributionsSet.delete(mapProvider.url)
    this.removeAttribution(mapProvider.attribution)
  }

  public isMapProviderApplied (mapProvider: MapProviderConfig): boolean {
    return this._attributionsSet.has(mapProvider.url)
  }
}

export function AttributionControl (props): Control.Attribution {
  const options = new CustomAttributionControl()
  const CustomAttribution = Control.Attribution.extend(options)
  return new CustomAttribution(props)
}
