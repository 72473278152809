































import { Component, Mixins, Prop, Watch, Ref } from 'vue-property-decorator'
import AddressesGeocoding from './geocoding/addresses-geocoding.vue'
import { ComponentSettingsMixin } from '../../mixins/component-settings.mixin'
import {
  DEFAULT_LATITUDE_COLUMN_NAME,
  DEFAULT_LONGITUDE_COLUMN_NAME
} from '@/components/shared/coordinates-service/components/constants'
import { ValidationProviderMixin } from '@/mixins/validation/validation-provider.mixin'
import { Validations } from '@/decorators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { required } from 'vuelidate/lib/validators'
import { ValidationModel } from '@/vendor/types/vuelidate/model'
import { FormInputState } from '@/types/bootstrap'
import { CoordinatesServiceResult, GeocodingDetails } from '@/components/shared/coordinates-service/types'
import { find } from 'lodash'

@Component({
  components: { AddressesGeocoding }
})
export default class Addresses extends Mixins<ComponentSettingsMixin, ValidationProviderMixin>(ComponentSettingsMixin, ValidationProviderMixin) {
  @Prop({ default: () => [] }) selectedColumns!: string[]
  @Ref('addressesGeocode') readonly addressesGeocodeRef!: AddressesGeocoding

  addressesColumns: string[] = []
  isAddressesDisabled = false

  get getAddressesForGeocoding (): string[] {
    return this.tableData.map(row => this.addressesColumns.map(col => row[col]).join(', '))
  }

  @Watch('selectedColumns')
  onSelectedColumnsChange (newColumns: string[]): void {
    this.addressesColumns = newColumns
    this.$v.$reset()
  }

  @Validations()
  private validations (): RuleDecl {
    return {
      addressesColumns: { required }
    }
  }

  private inputState (model: ValidationModel): FormInputState {
    return this.validationModelToInputState(model)
  }

  override mounted () {
    this.$watch(() => this.addressesGeocodeRef.geocodingInitialized, value => (this.isAddressesDisabled = value))
  }

  handleResultsGeocode (data: GeocodingDetails[]) {
    let rowData

    if (this.saveTableColumns) {
      rowData = data.map(row => {
        const tableData = find(this.tableData, item => {
          const addresses = this.addressesColumns.map(col => item[col]).join(', ')
          return addresses === row.queryString
        })

        return {
          ...row.coordinate,
          ...tableData
        }
      })
    } else {
      rowData = data.map(item => item.coordinate)
    }

    const resultObject: CoordinatesServiceResult = {
      hasErrors: this.addressesGeocodeRef.hasErrors,
      coordinateColumns: { latitudeColumn: DEFAULT_LATITUDE_COLUMN_NAME, longitudeColumn: DEFAULT_LONGITUDE_COLUMN_NAME },
      selectedColumns: this.addressesColumns,
      rowData,
      geocodingData: data
    }

    this.$emit('ready', resultObject)
  }

  override resetComponentData () {
    this.addressesColumns = []
    this.isAddressesDisabled = false
    this.addressesGeocodeRef.resetGeocode()
  }
}
