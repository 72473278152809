import { isEqual } from 'lodash'
import { Order } from '@/types/orders'

import {
  ACTIVE_ORDER,
  ACTIVE_ORDER_DETAILS_VISIBLE,
  ACTIVE_ORDER_ITEMS,
  ACTIVE_ORDER_ITEMS_VISIBLE,
  ALL_ORDERS,
  DRAGGED_ORDER,
  GET_ORDERS_BY_STATUS,
  ORDERS_INITIALIZED,
  PENDING_DROP_RESULT
} from '@/store/getter-types'

export const getters = {
  [ALL_ORDERS] (state) {
    return state.orders
  },
  [GET_ORDERS_BY_STATUS] (_state, getters) {
    return (status) => {
      return getters[ALL_ORDERS]
        .filter(order => order.status === status)
    }
  },
  [DRAGGED_ORDER] (state): Order {
    return state.draggedOrder
  },
  [PENDING_DROP_RESULT] (state) {
    return !isEqual(state.cachedOrders, state.orders)
  },
  [ORDERS_INITIALIZED] (state) {
    return state.initialized
  },
  [ACTIVE_ORDER] (state) {
    return state.activeOrder
  },
  [ACTIVE_ORDER_ITEMS] (state) {
    return state.activeItems
  },
  [ACTIVE_ORDER_DETAILS_VISIBLE] (state) {
    return state.detailsVisible
  },
  [ACTIVE_ORDER_ITEMS_VISIBLE] (state) {
    return state.itemsVisible
  }
}
