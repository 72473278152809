import { VNode, VNodeChildren } from 'vue'
import { Component, Vue } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'
import { MsgBoxProps, MsgBoxResolver, MsgBoxSlots } from '../types'

@Component
class MsgBox extends BModal {
  destroyed () {
    // Make sure we not in document any more
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  }

  mounted () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this

    const handleShown = function handleShown (e) {
      let comp = _this.$children[0]
      if (comp.$options.name === 'BTransporterSingle') {
        comp = comp.$children[0]
      }
      comp?.$emit?.('shown', e)
    }

    // Self destruct handler
    const handleDestroy = function handleDestroy () {
      const self = _this

      _this.$nextTick(function () {
        // In a `setTimeout()` to release control back to application
        setTimeout(function () {
          return self.$destroy()
        }, 0)
      })
    }

    // Self destruct if parent destroyed
    this.$parent.$once('hook:destroyed', handleDestroy)

    // Self destruct after hidden
    this.$once('hidden', handleDestroy)
    this.$once('shown', handleShown)

    this.show()
  }
}

function asyncMsgBox<T> ($parent: Vue, resolver: MsgBoxResolver<T>, props: MsgBoxProps, slots: MsgBoxSlots): Promise<T> {
  const msgBox = new MsgBox({
    // We set parent as the local VM so these modals can emit events on
    // the app `$root`, as needed by things like tooltips and popovers
    // And it helps to ensure `MsgBox` is destroyed when parent is destroyed
    parent: $parent,
    // Preset the prop values
    propsData: {
      // Defaults that user can override
      hideHeaderClose: true,
      hideHeader: !slots['modal-title'],
      ...props,
      // Props that can't be overridden
      lazy: false,
      busy: false,
      visible: false,
      noStacking: false,
      noEnforceFocus: false
    }
  })

  Object.keys(slots).forEach(key => {
    msgBox.$slots[key] = slots[key] as VNode[]
  })

  return new Promise<T>((resolve, reject) => {
    let resolved = false

    msgBox.$once('hook:destroyed', () => {
      if (!resolved) {
        reject(new Error('MsgBox destroyed before resolve'))
      }
    })

    msgBox.$on('hide', (bvModalEvt) => {
      if (!bvModalEvt.defaultPrevented) {
        const result = resolver(bvModalEvt)

        // If resolver didn't cancel hide, we resolve
        if (!bvModalEvt.defaultPrevented) {
          resolved = true
          resolve(result)
        }
      }
    })

    // Create a mount point (a DIV) and mount the msgBox which will trigger it to show
    const div = document.createElement('div')
    document.body.appendChild(div)
    msgBox.$mount(div)
  })
}

export function makeMsgBox<T> (
  $parent: Vue,
  content: VNodeChildren,
  resolver: MsgBoxResolver<T>,
  props: MsgBoxProps,
  slots: MsgBoxSlots
) {
  return asyncMsgBox($parent, resolver, props, {
    default: content,
    ...slots
  })
}
