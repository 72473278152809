// Campaign module
export const SET_CAMPAIGN_FILTERS = 'campaign/updateFilters'
export const SET_CAMPAIGN_GOALS_DISABLED = 'campaign/setGoalsDisabled'
export const SET_CAMPAIGN_FLIGHTS_DISABLED = 'campaign/setFlightsDisabled'
export const SET_CAMPAIGN_CUSTOM_TIME_FRAME = 'campaign/setCustomTimeFrame'
export const SET_CAMPAIGN_DISABLED_FILTER_IDS = 'campaign/setDisabledFilterIds'
export const DISABLE_CAMPAIGN_FILTER_IDS = 'campaign/disableFilterIds'
export const ENABLE_CAMPAIGN_FILTER_IDS = 'campaign/enableFilterIds'
export const SET_CAMPAIGN_ID = 'campaign/setId'
export const SET_CAMPAIGN_NAME = 'campaign/setName'
export const SET_CAMPAIGN_CUSTOMER = 'campaign/setCustomer'
export const SET_CAMPAIGN_DISCOUNT = 'campaign/setDiscount'
export const SET_CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG = 'campaign/setForceContinuousDisplayFlag'
export const SET_CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG = 'campaign/setConsiderStatusUnknownFlag'
export const SET_CAMPAIGN_START_DATE = 'campaign/setStartDate'
export const SET_CAMPAIGN_END_DATE = 'campaign/setEndDate'
export const SET_CAMPAIGN_CACHE = 'campaign/setCampaignCache'
export const SET_CAMPAIGN_STATUS = 'campaign/setStatus'
export const ADD_CAMPAIGN_FILTER = 'campaign/addFilter'
export const REMOVE_CAMPAIGN_FILTER = 'campaign/removeFilter'
export const UPDATE_CAMPAIGN_FILTER = 'campaign/updateFilter'
export const SET_CAMPAIGN_HAS_LINKED_OFFER = 'campaign/setCampaignHasLinkedOffer'
export const SET_CAMPAIGN_CREATED_BY = 'campaign/setCampaignCreatedBy'
export const SET_EXCLUDED_AREA_ASSIGNMENTS = 'scoring/setExcludedAssignments'
export const SET_CAMPAIGN_TABLE_VIEWS = 'campaign/setTableViews'

// Collections module
export const SET_BANNED_PRODUCTS = 'collections/bannedProducts/setCollection'
export const SET_CITY_DATASET = 'collections/cityNames/setDataset'
export const SET_MEDIA_OWNER_NAMES = 'collections/mediaOwnerNames/setCollection'
export const SET_SITE_TYPES = 'collections/siteTypes/setCollection'
export const SET_MEDIA_FILTER = 'collections/mediaFilter/setCollection'

// Collections module (Combined goals)
export const SET_GOAL_TYPES = 'collections/planningGoalTypes/setCollection'

// Decades module
export const ADD_DECADES = 'decades/addDecades'

// Customers module
export const SET_CUSTOMERS = 'filterFunctions/setCustomers'

// Scoring module
export const SET_SCORING_LOCATIONS = 'scoring/setLocations'
export const ADD_SCORING_SITE_DETAILS = 'scoring/siteDetails/addSiteDetails'
export const SET_SCORING_STATISTICS = 'scoring/statistics/setStatistics'
export const SET_SCORING_REPORTS = 'scoring/setReports'
export const SET_SCORING_LAYERS = 'scoring/setLayers'
export const SET_SCORING_DETAILS = 'scoring/setScoresDetails'
export const SET_SCORING_UNITS_TO_RESOLVE = 'scoring/setScoresToResolve'
export const SET_TIME_FRAME = 'scoring/appliedScoring/setTimeFrame'
export const SET_APPLIED_SCORING_FILTERS = 'scoring/appliedScoring/setFilters'
export const SET_APPLIED_FORCE_CONINUOUS_DISPLAY = 'scoring/appliedScoring/setForceContinuousDisplay'
export const SET_APPLIED_CONSIDER_STATUS_UNKNOWN = 'scoring/appliedScoring/setConsiderStatusUnknown'
export const SET_APPLIED_AVAILABILITY_FLAG = 'scoring/appliedScoring/setAvailabilityFlag'

// User module
export const SET_USER_USERNAME = 'user/setUsername'
export const SET_USER_FIRST_NAME = 'user/setFirstName'
export const SET_USER_LAST_NAME = 'user/setLastName'
export const SET_USER_ROLES = 'user/setRoles'
export const CLEAR_USER_DATA = 'user/clearData'

// Visualization module - current visualization
export const SET_VISUALIZATION_ID = 'visualizations/setId'
export const SET_VISUALIZATION_NAME = 'visualizations/setName'
export const SET_VISUALIZATION_CREATED_BY = 'visualizations/setCreatedBy'
export const SET_VISUALIZATION_LAYERS = 'visualizations/setLayers'
export const SET_VISUALIZATION_LAYER_STYLE = 'visualizations/setLayerStyle'
export const SET_VISUALIZATION_LAYER_GEOMETRIES = 'visualizations/setLayerGeometries'
export const UPDATE_VISUALIZATION_LAYER_STYLE = 'visualizations/updateLayerStyle'
export const UPDATE_VISUALIZATION_RANGE_QUERY = 'visualizations/setLayerQuery'
export const ADD_VISUALIZATION_LAYER = 'visualizations/addLayer'
export const REMOVE_VISUALIZATION_LAYER = 'visualizations/removeLayer'
export const REPLACE_VISUALIZATION_LAYER = 'visualizations/replaceLayer'
export const ADD_VISUALIZATION_SLIDE = 'visualizations/addSlide'
export const SET_VISUALIZATION_SLIDES = 'visualizations/setSlides'
export const REMOVE_VISUALIZATION_SLIDE = 'visualizations/removeSlide'
export const SET_VISUALIZATION_LINKED_PLANNING = 'visualizations/setLinkedPlanning'
export const SET_VISUALIZATION_LINKED_PLANNING_FUNCTIONS = 'visualizations/setLinkedPlanningFunctions'
export const SET_VISUALIZATION_LAYER_NAME = 'visualizations/setLayerName'
export const SET_VISUALIZATION_VALIDATED_STATE = 'visualizations/setValidatedState'
export const SET_VISUALIZATION_UPDATED_STATE = 'visualizations/setUpdatedState'
export const SET_VISUALIZATION_REPORTS = 'visualizations/setVisualizationReports'

// Requests module
export const START_REQUEST = 'request/startRequest'
export const STOP_REQUEST = 'request/stopRequest'
export const RESET_REQUESTS = 'requests/resetRequests'
export const ADD_CANCEL_CALLBACK = 'requests/addCancelCallback'
export const REMOVE_CANCEL_CALLBACK = 'requests/removeCancelCallback'
export const CLEAR_CANCEL_CALLBACK = 'requests/clearCancelCallback'

// Booking time frame module
export const SET_BOOKING_TIME = 'bookingTime/setBookingTime'

// Planning wizard module
export const PLAN_WIZARD_SET_CAMPAIGN_ID = 'planningWizard/setCampaignId'
export const PLAN_WIZARD_SET_CAMPAIGN_NAME = 'planningWizard/setCampaignName'
export const PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER = 'planningWizard/setCampaignCustomer'
export const PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT = 'planningWizard/setCampaignDiscount'
export const PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG = 'planningWizard/setForceContinuousDisplayFlag'
export const PLAN_WIZARD_SET_CONSIDER_STATUS_UNKNOWN_FLAG = 'planningWizard/setConsiderStatusUnknownFlag'
export const PLAN_WIZARD_SET_CAMPAIGN_STATUS = 'planningWizard/setCampaignStatus'
export const PLAN_WIZARD_SET_START_DATE = 'planningWizard/setStartDate'
export const PLAN_WIZARD_SET_END_DATE = 'planningWizard/setEndDate'
export const PLAN_WIZARD_SET_FILTERS = 'planningWizard/setFilters'
export const PLAN_WIZARD_ADD_FILTER_NODE = 'planningWizard/addFilterNode'
export const PLAN_WIZARD_REMOVE_FILTER_NODE = 'planningWizard/removeFilterNode'
export const PLAN_WIZARD_REPLACE_FILTER_NODE = 'planningWizard/replaceFilterNode'
export const SET_PLAN_WIZARD_UPDATED_STATE = 'planningWizard/setUpdatedState'
export const SET_PLAN_WIZARD_ACTIVE_FILTER = 'planningWizard/setActiveFilter'
export const PLAN_WIZARD_SET_STEP_LEAVE_CALLBACK = 'planningWizard/setStepLeaveCallback'
export const PLAN_WIZARD_SET_MEDIA_NETWORKS = 'planningWizard/setMediaNetworks'

// Vicinity import wizard
export const VICINITY_IMPORT_WIZARD_SET_VISIBLE = 'vicinity-wizard/setVisible'
export const VICINITY_IMPORT_WIZARD_SET_DATASET = 'vicinity-wizard/setDataset'
export const VICINITY_IMPORT_WIZARD_SET_QUERY_DATA = 'vicinity-import/setQueryData'

// Vicinity import - dataset step
export const VICINITY_IMPORT_WIZARD_DATASET_SET_UPDATE_STATE = 'vicinity-import/dataset-step/setUpdatedState'

// Vicinity import - query dataset step
export const VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE = 'vicinity-import/query-dataset-step/setUpdatedState'

// Vicinity import - parse dataset step
export const VICINITY_IMPORT_WIZARD_POINTS_SET_CONTENT_TYPE = 'vicinity-import/parse-dataset-step/setContentType'
export const VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_COLUMNS = 'vicinity-import/parse-dataset-step/setGeocodingColumns'
export const VICINITY_IMPORT_WIZARD_POINTS_SET_GEOCODING_STATE = 'vicinity-import/parse-dataset-step/setGeocodingState'
export const VICINITY_IMPORT_WIZARD_POINTS_SET_COORDINATE_COLUMNS = 'vicinity-import/parse-dataset-step/setCoordinateColumns'
export const VICINITY_IMPORT_WIZARD_POINTS_SET_LATLNG_TABLE = 'vicinity-import/parse-dataset-step/setLatLngTable'
export const VICINITY_IMPORT_WIZARD_POINTS_SET_NAME_COLUMN = 'vicinity-import/parse-dataset-step/setNameColumn'

// Wizard navigation module
export const WIZARD_SET_STEP_ACTIVE = 'wizard/setStepActive'
export const WIZARD_SET_STEP_TOUCHED = 'wizard/setStepTouched'
export const WIZARD_INIT_STEPS = 'wizard/initSteps'
export const WIZARD_SET_START_INDEX = 'wizard/setStartIndex'

// New visualization wizard
export const NEW_VISUALIZATION_WIZARD_SET_VISIBLE = 'new-visualization/setVisible'
export const NEW_VISUALIZATION_WIZARD_SET_NAME = 'new-visualization/setName'
export const NEW_VISUALIZATION_WIZARD_SET_PLANS = 'new-visualization/setPlan'
export const NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT = 'new-visualization/setEntrypoint'

// Layer wizard module
export const LAYER_WIZARD_SET_VISIBLE = 'layerWizard/setVisible'
export const LAYER_WIZARD_SET_LAYER_TYPE = 'layerWizard/setLayerType'
export const LAYER_WIZARD_SET_LAYER_NAME = 'layerWizard/setLayerName'
export const LAYER_WIZARD_ADD_SURROUNDING_GEOMETRY = 'layerWizard/addSurroundingGeometry'
export const LAYER_WIZARD_REMOVE_SURROUNDING_GEOMETRY = 'layerWizard/removeSurroundingGeometry'
export const LAYER_WIZARD_SET_SURROUNDING_GEOMETRIES = 'layerWizard/setSurroundingGeometries'
export const LAYER_WIZARD_SET_DATASET = 'layerWizard/setDataset'
export const LAYER_WIZARD_SET_DATASET_UPDATE = 'layerWizard/setDatasetUpdate'
export const LAYER_WIZARD_SET_GEOMETRYSET = 'layerWizard/setGeometryset'
export const LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET = 'layerWizard/setGranularityGeometryset'
export const LAYER_WIZARD_SET_LINK_COLUMN = 'layerWizard/setLinkColumn'
export const LAYER_WIZARD_SET_LAYER_STYLE = 'layerWizard/setLayerStyle'
export const LAYER_WIZARD_UPDATE_LAYER_STYLE = 'layerWizard/updateLayerStyle'
export const LAYER_WIZARD_SET_ENTRYPOINT = 'layerWizard/setEntrypoint'

// Layer wizard steps - surrounding
export const LAYER_WIZARD_STEP_SURROUNDING_SET_UPDATE_STATE = 'layerWizard/steps/surrounding/setUpdateState'

// Layer wizard steps - poi dataset
export const LAYER_WIZARD_STEP_POI_DATASET_SET_UPDATE_STATE = 'layerWizard/steps/poi-dataset/setUpdateState'

// Layer wizard steps - area link
export const LAYER_WIZARD_STEP_AREA_LINK_SET_UPDATE_STATE = 'layerWizard/steps/area-link/setUpdateState'

// Layer wizard steps - area link
export const LAYER_WIZARD_STEP_AREA_UPLOAD_SET_UPDATE_STATE = 'layerWizard/steps/area-upload/setUpdateState'

// Layer wizard steps - granularity
export const LAYER_WIZARD_STEP_GRANULARITY_SET_GEOMETRYSETS = 'layerWizard/steps/granularity/setGeometrysets'
export const LAYER_WIZARD_STEP_GRANULARITY_SET_UPDATE_STATE = 'layerWizard/steps/granularity/setUpdateState'

// Layer wizard steps - area dataset
export const LAYER_WIZARD_STEP_AREA_DATASET_SET_UPDATE_STATE = 'layerWizard/steps/area-dataset/setUpdateState'
export const LAYER_WIZARD_STEP_AREA_DATASET_STEP_WAS_SKIPPED = 'layerWizard/steps/area-dataset/setSkippedState'

// Layer wizard steps - layer props
export const LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE = 'layerWizard/steps/props/setUpdateState'

// Layer wizard steps - layer props - points
export const LAYER_WIZARD_POINTS_SET_GEOCODING_STATE = 'layerWizard/steps/props/points/setGeocodingState'
export const LAYER_WIZARD_POINTS_SET_GEOCODING_COLUMNS = 'layerWizard/steps/props/points/setGeocodingColumns'
export const LAYER_WIZARD_POINTS_SET_CONTENT_TYPE = 'layerWizard/steps/props/points/setContentType'

// Geometries module
export const ADD_GEOMETRIES_TO_CACHE = 'geometriesCache/addGeometries'
export const RESET_GEOMETRIES_CACHE = 'geometriesCache/resetCache'

// Orders module
export const SET_ORDERS = 'orders/setOrders'
export const SET_ORDERS_CACHE = 'orders/setOrdersCache'
export const SET_DRAGGED_ORDER = 'orders/setDraggedOrder'
export const SET_ACTIVE_ORDER = 'orders/setActiveOrder'
export const SET_ACTIVE_ORDER_ITEMS = 'orders/setActiveOrderItems'
export const UPDATE_ACTIVE_ORDER_DATA = 'orders/updateActiveOrderData'
export const SET_ACTIVE_ORDER_DETAILS_VISIBLE = 'orders/setDetailsVisible'
export const SET_ACTIVE_ORDER_ITEMS_VISIBLE = 'orders/setItemsVisible'

// visualization-location
export const SET_CURRENT_VISUALISATION_LOCATION = 'visualization-location/setCurrentVisualisationLocation'
export const SET_VISUALIZATION_LOCATIONS = 'visualization-location/setVisualisationLocations'
export const ADD_VISUALIZATION_LOCATION = 'visualization-location/addVisualisationLocation'
export const REMOVE_VISUALIZATION_LOCATION = 'visualization-location/removeVisualisationLocation'

// Analytics module
export const SET_ANALYTICS_DATASET = 'analytics/setDataset'

// New dataset wizard
export const DATASET_WIZARD_SET_DATASET = 'datasetWizard/setDataset'
export const DATASET_WIZARD_SET_DATASET_UPDATE = 'datasetWizard/setDatasetUpdate'
export const DATASET_WIZARD_SET_ENTRYPOINT = 'datasetWizard/setEntrypoint'
export const DATASET_WIZARD_SET_DATASET_NAME = 'datasetWizard/setDatasetName'
export const DATASET_WIZARD_SET_VISIBLE = 'datasetWizard/setVisible'
export const DATASET_WIZARD_SET_DATASET_TYPE = 'datasetWizard/setDatasetType'

// New dataset wizard steps - geocoding
export const DATASET_WIZARD_SET_GEOCODED_DATA = 'datasetWizard/steps/geocoding/setGeocodedData'
export const DATASET_WIZARD_SET_GEOCODING_FINISHED = 'datasetWizard/steps/geocoding/setGeocodingFinished'
export const DATASET_WIZARD_SET_GEOCODING_SELECTED_COLUMNS = 'datasetWizard/steps/geocoding/setSelectedColumns'
