import { flatten, merge, pick, omit } from 'lodash'
import { ParsingOptions, read as readXLSX, Sheet2JSONOpts, utils as XLSXUtils } from 'xlsx'
import { i18n } from '@/locales/i18n'
import { ExcelRow } from '@/types/common'

export const FILE_TYPE_KML = 'kml'
export const FILE_TYPE_KMZ = 'kmz'
export const FILE_TYPE_CSV = 'csv'
export const FILE_TYPE_XLS = 'xls'
export const FILE_TYPE_XLSX = 'xlsx'
export const FILE_TYPE_PNG = 'png'
export const FILE_TYPE_JPEG = 'jpeg'
export const FILE_TYPE_GIF = 'gif'
export const FILE_TYPE_ZIP = 'zip'
export const FILE_TYPE_MP4 = 'mp4'
export const FILE_TYPE_DOC = 'docx'
export const FILE_TYPE_PDF = 'pdf'
export const FILE_TYPE_PPTX = 'pptx'

export type FileTypeConfig = {
  ext: string[];
  mime: string[];
}

export type FileTypeConfigs = {
  [key: string]: FileTypeConfig;
}

const FILE_TYPES_CONFIG: FileTypeConfigs = {
  [FILE_TYPE_KML]: {
    ext: ['kml'],
    mime: ['application/vnd.google-earth.kml+xml']
  },
  [FILE_TYPE_KMZ]: {
    ext: ['kmz'],
    mime: ['application/vnd.google-earth.kmz+xml']
  },
  [FILE_TYPE_CSV]: {
    ext: ['csv'],
    mime: ['text/csv', 'text/comma-separated-values']
  },
  [FILE_TYPE_XLS]: {
    ext: ['xls'],
    mime: ['application/vnd.ms-excel']
  },
  [FILE_TYPE_XLSX]: {
    ext: ['xlsx'],
    mime: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  },
  [FILE_TYPE_PNG]: {
    ext: ['png'],
    mime: ['image/png']
  },
  [FILE_TYPE_JPEG]: {
    ext: ['jpg', 'jpeg'],
    mime: ['image/jpeg']
  },
  [FILE_TYPE_GIF]: {
    ext: ['gif'],
    mime: ['image/gif']
  },
  [FILE_TYPE_ZIP]: {
    ext: ['zip'],
    mime: ['application/x-zip', 'application/zip', 'application/x-zip-compressed', 'application/zip-compressed']
  },
  [FILE_TYPE_MP4]: {
    ext: ['mp4'],
    mime: ['video/mp4']
  },
  [FILE_TYPE_DOC]: {
    ext: ['docx', 'doc'],
    mime: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
  },
  [FILE_TYPE_PDF]: {
    ext: ['pdf'],
    mime: ['application/pdf']
  },
  [FILE_TYPE_PPTX]: {
    ext: ['pptx', 'ppt'],
    mime: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']
  }
}

function getFileExtension (fileName: string): string {
  return fileName.split('.').pop() as string
}

export const EXCEL_FILE_TYPES: FileTypeConfigs = pick(FILE_TYPES_CONFIG, [
  FILE_TYPE_CSV,
  FILE_TYPE_XLS,
  FILE_TYPE_XLSX
])

export const DATASET_FILE_TYPES: FileTypeConfigs = merge(
  {},
  EXCEL_FILE_TYPES,
  pick(FILE_TYPES_CONFIG, [FILE_TYPE_ZIP])
)

export const GEOMETRY_WITH_KEYS_FILE_TYPES: FileTypeConfigs = pick(FILE_TYPES_CONFIG, [FILE_TYPE_KML, FILE_TYPE_KMZ])
export const GEOMETRY_WITHOUT_KEYS_FILE_TYPES: FileTypeConfigs = pick(FILE_TYPES_CONFIG, [FILE_TYPE_ZIP])

export const GEOMETRY_FILE_TYPES: FileTypeConfigs = merge(
  {},
  GEOMETRY_WITH_KEYS_FILE_TYPES,
  GEOMETRY_WITHOUT_KEYS_FILE_TYPES
)

export const IMAGE_FILE_TYPES: FileTypeConfigs = pick(FILE_TYPES_CONFIG, [
  FILE_TYPE_PNG,
  FILE_TYPE_JPEG,
  FILE_TYPE_GIF
])

export const ORDER_DOCUMENT_TYPES: FileTypeConfigs = omit(FILE_TYPES_CONFIG, Object.keys(GEOMETRY_WITH_KEYS_FILE_TYPES))

export function acceptFileFilters (fileTypes: FileTypeConfigs) {
  return {
    allowedTypes: flatten(Object.values(fileTypes).map(typ => typ.mime)),
    allowedExtensions: flatten(Object.values(fileTypes).map(typ => typ.ext)).map(ext => `.${ext}`)
  }
}

export function getFileTypeByMimeType (mimeType: string): string | void {
  for (const key in FILE_TYPES_CONFIG) {
    if (FILE_TYPES_CONFIG[key].mime.includes(mimeType)) {
      return key
    }
  }
}

export function isGeometryWithKeysFile (fileName: string): boolean {
  return Object.keys(GEOMETRY_WITH_KEYS_FILE_TYPES).includes(getFileExtension(fileName))
}

export function readExcelFile (file: File, parseOpts?: ParsingOptions, sheetOpts?: Sheet2JSONOpts): Promise<ExcelRow[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      parseOpts = {
        type: 'array',
        ...parseOpts
      }
      const workbook = readXLSX(e?.target?.result, parseOpts)
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const rows: ExcelRow[] = XLSXUtils.sheet_to_json(worksheet, sheetOpts)

      resolve(rows)
    }

    reader.onerror = () => reject(i18n.t('common.errors.reading-file'))

    reader.readAsArrayBuffer(file)
  })
}
