import { i18n } from '@/locales/i18n'
import {
  WIZARD_TAB_COMMON_ERRORS,
  WIZARD_TAB_COMMON_IS_EMPTY,
  WIZARD_TAB_COMMON_IS_ENABLED,
  WIZARD_TAB_COMMON_IS_INVALID,
  WIZARD_STEP_COMMON_IS_EMPTY,
  WIZARD_STEP_COMMON_NAME_VALID,
  WIZARD_STEP_COMMON_PERIOD_IS_SET,
  WIZARD_STEP_COMMON_PERIOD_IS_VALID,
  WIZARD_STEP_COMMON_PERIOD_HAS_DECADES
} from '@/store/getter-types'
import {
  WIZARD_TAB_COMMON_LEAVE,
  WIZARD_TAB_COMMON_VALIDATE
} from '@/store/action-types'
import { WIZARD_STEP_COMMON } from '@/components/planning/wizard/constants/steps'

// initial state
const state = {
  stepId: WIZARD_STEP_COMMON
}

// getters
const getters = {
  [WIZARD_TAB_COMMON_IS_ENABLED] () {
    return true
  },
  [WIZARD_TAB_COMMON_IS_EMPTY] (state, getters) {
    return getters[WIZARD_STEP_COMMON_IS_EMPTY]
  },
  [WIZARD_TAB_COMMON_IS_INVALID] (state, getters) {
    return getters[WIZARD_TAB_COMMON_ERRORS].length > 0
  },
  [WIZARD_TAB_COMMON_ERRORS] (state, getters) {
    const errors = []

    if (!getters[WIZARD_STEP_COMMON_NAME_VALID]) {
      errors.push(i18n.t('planning.wizard.errors.campaign-name-required'))
    }

    if (!getters[WIZARD_STEP_COMMON_PERIOD_IS_SET] || !getters[WIZARD_STEP_COMMON_PERIOD_IS_VALID]) {
      errors.push(i18n.t('planning.wizard.errors.period-invalid'))
    }

    if (!getters[WIZARD_STEP_COMMON_PERIOD_HAS_DECADES]) {
      errors.push(i18n.t('planning.wizard.errors.period-no-decades'))
    }

    return errors
  }
}

// actions
const actions = {
  [WIZARD_TAB_COMMON_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[WIZARD_TAB_COMMON_ERRORS])
  },
  async [WIZARD_TAB_COMMON_LEAVE] () {
    return Promise.resolve(true)
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
