import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// initial state
const state = {
  id: null,
  name: '',
  layers: [],
  slides: [],
  linkedPlanningIDs: [],
  linkedPlanningFunctions: [],
  reports: [],
  wasValidated: false,
  wasUpdated: false,
  createdBy: null
}

export default {
  state,
  getters,
  mutations,
  actions
}
