import { FilterDatasetConfig } from '@/types/planning/wizard/filter-editor'
import { BaseConfig } from '../base-config'
import { Dataset } from '@/types/common'

export abstract class DatasetConfig<T extends Dataset = Dataset> extends BaseConfig<T> implements FilterDatasetConfig<T> {
  protected override _defValue = null

  formatRows (value: T): string {
    return value.datasetRows.toString()
  }
}
