import navigationStore from './navigation/navigation.store'
import stepsStore from './steps/layer-wizard-steps.store'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
  visible: false,
  entrypoint: null,
  layerType: null,
  layerName: null,
  surroundingGeometries: [],
  dataset: null,
  datasetUpdate: null,
  geometryset: null,
  granularityGeometryset: null,
  linkColumn: null,
  layerStyle: null,
  wizardNamespace: 'layer-wizard'
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    wizardNavigation: navigationStore,
    wizardSteps: stepsStore
  }
}
