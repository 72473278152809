import { LatLngBounds } from 'leaflet'
import { ApiRequestConfig } from '@/api/types'
import { ApiClient } from '@/api/api-client'
import { transformSingleDatasetResponse } from '@/api/rest/visualization/transform-response/get-dataset-response.transform'
import {
  transformSingleDatasetRequest,
  transformSingleGeometryRequest,
  transformVisualizationRequestBody as transformRequest
} from './transform-request/visualization-request.transform'
import { transformVisualizationDataResponse as transformResponse } from './transform-response/visualization-data-response.transform'
import { VisualizationLinkData } from '@/types/common'

class VisualizationDataApi extends ApiClient {
  private getBoundsUrl (bounds: LatLngBounds): string {
    const point1 = bounds.getNorthWest()
    const point2 = bounds.getSouthEast()

    return `${point1.lng}/${point1.lat}/${point2.lng}/${point2.lat}`
  }

  getVisualizationData (data, bounds: LatLngBounds) {
    const url = 'data/' + this.getBoundsUrl(bounds)

    const config: ApiRequestConfig = {
      transformRequest,
      transformResponse,
      blockingRequest: true,
      toastOnError: true
    }

    return this.post(url, data, config)
  }

  getSingleGeometry (data, bounds: LatLngBounds) {
    const url = 'data/' + this.getBoundsUrl(bounds)

    const config: ApiRequestConfig = {
      transformRequest: transformSingleGeometryRequest,
      blockingRequest: true
    }

    return this.post(url, data, config)
  }

  getSingleDataset (data, bounds: LatLngBounds) {
    const url = 'data/' + this.getBoundsUrl(bounds)

    const config: ApiRequestConfig = {
      transformRequest: transformSingleDatasetRequest,
      transformResponse: transformSingleDatasetResponse,
      blockingRequest: true
    }

    return this.post(url, data, config)
  }

  createLink (data) {
    const url = 'CreateLink'

    const config: ApiRequestConfig = {
      transformRequest,
      blockingRequest: true,
      toastOnError: true
    }

    return this.post(url, data, config)
  }

  checkLink (shortUrl: string): Promise<VisualizationLinkData> {
    const url = 'CheckLink/' + shortUrl

    return this.get(url)
  }
}

export const visualizationDataApi = new VisualizationDataApi({
  baseURL: API_CONF.api + '/api/visualization/'
})
