import { TagCollectionItem } from '@/types/planning/wizard/filter-editor'
import { uniq } from 'lodash'
import FuzzySet from 'fuzzyset.js'
import { SimilarityCheckResult } from '@/types/common'

const MIN_VALID_RATING = 0.6
const MIN_DIFF = 0.05

export const findSimilarity = <T extends TagCollectionItem | string>(values: T[], listOptions: string[]): SimilarityCheckResult<string> => {
  const foundValues: string[] = []
  const notFoundValues: string[] = []
  const fuzzySet = FuzzySet(uniq(listOptions), false)

  values.forEach((item: T) => {
    const results = fuzzySet.get((item as TagCollectionItem).text ?? item, null, MIN_VALID_RATING)

    if (!results) {
      notFoundValues.push((item as TagCollectionItem).text ?? item)
      return
    }

    const [rating, value] = results[0]

    const diff = rating - MIN_DIFF
    const similarities = results.filter(([rating]) => rating >= diff)

    if (similarities.length !== 1) {
      notFoundValues.push((item as TagCollectionItem).text ?? item)
      return
    }

    foundValues.push(value)
  })

  return { foundValues, notFoundValues }
}

export const findStringMatch = (values: string[], listOptions: string[]): Record<string, string> => {
  const foundValues: Record<string, string> = {}
  const fuzzySet = FuzzySet(uniq(listOptions), false)

  values.forEach(item => {
    const results = fuzzySet.get(item, null, MIN_VALID_RATING)

    if (!results) {
      foundValues[item] = item
      return
    }

    const [rating, value] = results[0]

    const diff = rating - MIN_DIFF
    const similarities = results.filter(([rating]) => rating >= diff)

    if (similarities.length !== 1) {
      foundValues[item] = item
      return
    }

    foundValues[item] = value
  })

  return foundValues
}
