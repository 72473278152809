import { i18n } from '@/locales/i18n'
import { WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID } from '@/store/getter-types'
import { TranslateResult } from 'vue-i18n'
import { RANKING_IMPORT_FILTERED_DATASET } from '@/store/ranking-import-wizard/getters'

// getters
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS = 'ranking-import/query-dataset-tab/errors'
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY = 'ranking-import/query-dataset-tab/isEmpty'
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_ENABLED = 'ranking-import/query-dataset-tab/isEnabled'
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_INVALID = 'ranking-import/query-dataset-tab/isInvalid'
export const RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_CAN_SKIP = 'ranking-import/query-dataset-tab/canSkip'

// getters
export const getters = {
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_ENABLED] (state, getters): boolean {
    return !getters[WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID](state.stepId)
  },
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY] (_state, getters): boolean {
    return getters[RANKING_IMPORT_FILTERED_DATASET].length === 0
  },
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_INVALID] (_state, getters): boolean {
    return getters[RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS].length > 0
  },
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_CAN_SKIP] (): boolean {
    return true
  },
  [RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_ERRORS] (_state, getters): TranslateResult[] {
    const errors: TranslateResult[] = []

    if (getters[RANKING_IMPORT_WIZARD_TAB_QUERY_DATASET_IS_EMPTY]) {
      errors.push(i18n.t('ranking-import.errors.empty-dataset'))
    }

    return errors
  }
}
