import { get, uniqBy, flatten, cloneDeep, isEqual } from 'lodash'

import {
  PLAN_WIZARD_START_DATE,
  PLAN_WIZARD_END_DATE,
  PLAN_WIZARD_FILTER_BY_ID,
  PLAN_WIZARD_MEDIA_NETWORKS_COLLECTION,
  PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA,
  PLAN_WIZARD_MEDIA_NETWORKS_NEEDS_UPDATE
} from '@/store/getter-types'
import { GeometryBase } from '@/types/common'
import { areaFiltersDataPath } from './constant'
import { matchGeometryToNetworkLocations } from '@/utils/planning/media-owner-network/media-owner-networks'

export const getters = {
  [PLAN_WIZARD_MEDIA_NETWORKS_COLLECTION] (state) {
    const networks = state.networks
    return uniqBy(flatten(networks), 'value')
  },
  [PLAN_WIZARD_MEDIA_NETWORKS_NEEDS_UPDATE] (state, getters) {
    const dateFrom = getters[PLAN_WIZARD_START_DATE]
    const dateTo = getters[PLAN_WIZARD_END_DATE]

    return (areas: GeometryBase[]) => {
      const loadedOkz = [...state.areaNameList].sort()
      const newOkz = areas.map(g => g.name).sort()

      return dateTo !== state.dateTo || dateFrom !== state.dateFrom || !isEqual(loadedOkz, newOkz)
    }
  },
  [PLAN_WIZARD_MEDIA_NETWORKS_DATASET_BY_AREA] (state, getters) {
    return (areaId) => {
      const areaNode = getters[PLAN_WIZARD_FILTER_BY_ID](areaId)
      const areas: GeometryBase[] = get(areaNode.scoringFunction.data, areaFiltersDataPath[areaNode.scoringFunction.name], [])

      const newDataset = cloneDeep(state.dataset)

      if (Array.isArray(newDataset?.datasetRows)) {
        newDataset.datasetRows = newDataset.datasetRows
          .filter(row => areas.some(area => matchGeometryToNetworkLocations(area, row.Locations)))

        return newDataset
      } else {
        return {}
      }
    }
  }
}
