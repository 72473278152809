export enum ScoringFunctionsTypes {
  AreaFilter,
  TypeFilter,
  NetworkFilter,
  FlightFilter,
  Ranking,
  Filter,
  Goal,
  OverallGoal,
  ManualScoring
}
