import {
  NEW_VISUALIZATION_WIZARD_STEP_ENTRYPOINT_INIT_CUSTOM_VIS,
  NEW_VISUALIZATION_WIZARD_STEP_ENTRYPOINT_INIT_VIS_FROM_PLAN
} from '@/store/action-types'
import { NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT } from '@/store/mutation-types'
import { ENTRYPOINT_CUSTOM_VISUALIZATION, ENTRYPOINT_VIS_FROM_PLAN } from '../../constants/entrypoints'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {
  [NEW_VISUALIZATION_WIZARD_STEP_ENTRYPOINT_INIT_CUSTOM_VIS] ({ commit }) {
    commit(NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_CUSTOM_VISUALIZATION)
  },
  [NEW_VISUALIZATION_WIZARD_STEP_ENTRYPOINT_INIT_VIS_FROM_PLAN] ({ commit }) {
    commit(NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT, ENTRYPOINT_VIS_FROM_PLAN)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
