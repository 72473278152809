










































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { isEmpty } from 'lodash'
import { ReadExcelFileMixin } from '@/mixins/read-file.mixin'
import { FormInputState } from '@/types/bootstrap'
import {
  FilterCollectionConfig,
  TagCollectionItem,
  TagImportSettings,
  TagsImporter
} from '@/types/planning/wizard/filter-editor'
import { findSimilarity } from '@/utils/similarity'
import { SimilarityCheckResult } from '@/types/common'

@Component
export default class TagsImport extends Mixins<ReadExcelFileMixin>(ReadExcelFileMixin) {
  @Prop({ default: '' }) label!: string
  @Prop({ required: true }) config!: FilterCollectionConfig & TagsImporter
  @Prop({ default: null }) state!: FormInputState

  private selectedColumns: string[] = []

  created () {
    this.selectedColumns = new Array<string>(this.config.tagImportSettings.columns.length)
  }

  protected override get fileState (): FormInputState {
    return this.file ? this.validateFile() : null
  }

  private get fileLabel (): string {
    return this.$t('planning.filters.labels.load-from-file', { label: this.label }) as string
  }

  private get listOptionsFull (): string[] {
    return this.config.options.map((item) => item.text)
  }

  private get listOptions (): string[] {
    return this.listOptionsFull.map((item) => item.split(',')[0])
  }

  private get fileProperties (): string[] {
    return Object.keys(this.fileRows[0] ?? {})
  }

  private get settings (): TagImportSettings {
    return this.config.tagImportSettings
  }

  private loadTags (): void {
    const columns = this.selectedColumns
    let values: TagCollectionItem[] = this.fileRows.map((row) => this.config.parseRowToTag(row, columns))

    if (this.settings.onlyAutocomplete) {
      const { foundValues: found, notFoundValues: notFound } = this.findSimilarity(values, this.listOptions)
      const { foundValues, notFoundValues } = this.findSimilarity(notFound, this.listOptionsFull)

      values = found.concat(foundValues)

      if (!isEmpty(notFoundValues)) {
        this.$toastError(this.$t('planning.filters.errors.cities-not-found', {
          cities: notFoundValues.map((val: TagCollectionItem) => val.text).join(', ')
        }) as string)
      }
    }

    this.$emit('change', values)
  }

  private findSimilarity (values: TagCollectionItem[], listOptions: string[]): SimilarityCheckResult<TagCollectionItem> {
    const { foundValues, notFoundValues } = findSimilarity<TagCollectionItem>(values, listOptions)

    return {
      foundValues: foundValues.map(item => {
        const index = listOptions.indexOf(item)
        return this.config.options[index] as TagCollectionItem
      }),
      notFoundValues: notFoundValues.map(value => {
        const index = values.findIndex(item => item.value === value)

        return values[index]
      })
    }
  }
}
