





















import WizardStepNavigatorMixin from '../mixins/wizard-steps-navigator.mixin'
import { WIZARD_NAVIGATION_IS_STEP_EMPTY, WIZARD_NAVIGATION_IS_STEP_ENABLED, WIZARD_NAVIGATION_IS_STEP_INVALID } from '@/store/getter-types'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { VueClassDef } from '@/types/vue'
import { WizardStoreGetterCallback, WizardTabConfig } from '@/types/wizard'

@Component
export default class WizardStepLink extends Mixins<WizardStepNavigatorMixin>(WizardStepNavigatorMixin) {
  @Prop({ required: true }) private readonly tab!: WizardTabConfig
  @Prop({ required: true }) private readonly tabIndex!: number

  protected get isTabEnabled (): WizardStoreGetterCallback<boolean, string> {
    return this.dynamicStoreGetter<WizardStoreGetterCallback<boolean, string>>(WIZARD_NAVIGATION_IS_STEP_ENABLED)
  }

  protected get isTabInvalid (): WizardStoreGetterCallback<boolean, string> {
    return this.dynamicStoreGetter<WizardStoreGetterCallback<boolean, string>>(WIZARD_NAVIGATION_IS_STEP_INVALID)
  }

  protected get isTabEmpty (): WizardStoreGetterCallback<boolean, string> {
    return this.dynamicStoreGetter<WizardStoreGetterCallback<boolean, string>>(WIZARD_NAVIGATION_IS_STEP_EMPTY)
  }

  protected get isActive () {
    return this.tab.active
  }

  protected get isInvalid (): boolean {
    return this.isTabInvalid(this.tab.id)
  }

  protected get isDisabled (): boolean {
    return !this.isTabEnabled(this.tab.id)
  }

  protected get isTouched (): boolean {
    return this.tab.touched || !this.isTabEmpty(this.tab.id)
  }

  protected get containerClass (): VueClassDef {
    return {
      active: this.isActive,
      invalid: this.isTouched && this.isInvalid,
      disabled: this.isDisabled
    }
  }

  protected activateCurrentStep (): void {
    !this.isDisabled && this.navigateToStep(this.tabIndex)
  }
}
