import {
  LAYER_WIZARD_SET_SURROUNDING_GEOMETRIES,
  LAYER_WIZARD_STEP_SURROUNDING_SET_UPDATE_STATE
} from '@/store/mutation-types'
import { LAYER_WIZARD_STEP_SURROUNDING_INVALIDATE } from '@/store/action-types'
import { LAYER_WIZARD_STEP_SURROUNDING_WAS_UPDATED } from '@/store/getter-types'

// initial state
const state = {
  wasUpdated: false
}

// getters
const getters = {
  [LAYER_WIZARD_STEP_SURROUNDING_WAS_UPDATED] (state) {
    return state.wasUpdated
  }
}

// mutations
const mutations = {
  [LAYER_WIZARD_STEP_SURROUNDING_SET_UPDATE_STATE] (state, wasUpdated) {
    state.wasUpdated = wasUpdated
  }
}

// actions
const actions = {
  [LAYER_WIZARD_STEP_SURROUNDING_INVALIDATE] ({ commit }) {
    commit(LAYER_WIZARD_SET_SURROUNDING_GEOMETRIES, [])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
