export enum OffergatewayErrorsMsgId {
  IDS_OFFERGATEWAY_SUCCESS = 'IDS_OFFERGATEWAY_SUCCESS',
  IDS_OFFERGATEWAY_FAIL = 'IDS_OFFERGATEWAY_FAIL',
  IDS_OFFERGATEWAY_INVALID_DBSTRING = 'IDS_OFFERGATEWAY_INVALID_DBSTRING',
  IDS_OFFERGATEWAY_OPS_NOT_AVAILABLE = 'IDS_OFFERGATEWAY_OPS_NOT_AVAILABLE',
  IDS_OFFERGATEWAY_MEDIAOWNERNETWORKS_UNKNOWN = 'IDS_OFFERGATEWAY_MEDIAOWNERNETWORKS_UNKNOWN',
  IDS_OFFERGATEWAY_HELPERTABLE_NOT_FOUND = 'IDS_OFFERGATEWAY_HELPERTABLE_NOT_FOUND',
  IDS_OFFERGATEWAY_UNABLE_TO_OPEN_DATABASE_CONNECTION = 'IDS_OFFERGATEWAY_UNABLE_TO_OPEN_DATABASE_CONNECTION',
  IDS_OFFERGATEWAY_CUSTOMER_NOT_SET = 'IDS_OFFERGATEWAY_CUSTOMER_NOT_SET',
  IDS_OFFERGATEWAY_CREATOR_NOT_SET = 'IDS_OFFERGATEWAY_CREATOR_NOT_SET',
  IDS_OFFERGATEWAY_MISSING_GEBIETS_ID = 'IDS_OFFERGATEWAY_MISSING_GEBIETS_ID',
  IDS_OFFERGATEWAY_MISSING_SITES = 'IDS_OFFERGATEWAY_MISSING_SITES',
  IDS_OFFERGATEWAY_SCORING_FAILED = 'IDS_OFFERGATEWAY_SCORING_FAILED',
  IDS_OFFERGATEWAY_AREATAGS_NOT_UNIQUE = 'IDS_OFFERGATEWAY_AREATAGS_NOT_UNIQUE',
  IDS_OFFERGATEWAY_INVALID_TIMEFRAME = 'IDS_OFFERGATEWAY_INVALID_TIMEFRAME',
  IDS_OFFERGATEWAY_UPDATE_PLANNING_FAILED = 'IDS_OFFERGATEWAY_UPDATE_PLANNING_FAILED',
  IDS_OFFERGATEWAY_CONVERSION_FAILURE = 'IDS_OFFERGATEWAY_CONVERSION_FAILURE'
}
