import { mapGetters } from 'vuex'
import { VISUALIZATION_IS_SUBLAYER_VISIBLE } from '@/store/getter-types'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  computed: {
    ...mapGetters({
      checkSublayerVisible: VISUALIZATION_IS_SUBLAYER_VISIBLE
    })
  }
})
export default class LegendSublayerMixin extends Vue {
  private checkSublayerVisible!: (layer, key) => boolean

  protected isSublayerVisible (layer, value) {
    return this.checkSublayerVisible(layer, value)
  }
}
