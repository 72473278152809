



























































import { Component, Inject, Mixins, Prop } from 'vue-property-decorator'
import { minLength, required } from 'vuelidate/lib/validators'
import { BookingTimeFrame, BookingTimeFrameBase, BookingTimeFrameValue } from '@/types/planning/booking-time'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import { Validations } from '@/decorators'
import { BookingTimeCollection } from '@/utils/filter-editor/value-config/collection/booking-time-collection'
import OverlayScrollbars from '@/components/shared/overlay-scrollbars/overlay-scrollbars.vue'
import QuickSelectLinks from '@/components/shared/quick-select-links.vue'
import { UiControlMixin } from './mixins/ui-control.mixin'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'

type BookingTableRow = {
  key: BookingTimeFrameValue;
  selected: boolean;
  timeFrame: BookingTimeFrame;
}

type BookingTimeFilterFunc = (v: BookingTimeFrame[]) => BookingTimeFrame[]

const defaultBookingTimeFilter = (v: BookingTimeFrame[]): BookingTimeFrame[] => v
const defBookingTimeFilterFactory = (): BookingTimeFilterFunc => defaultBookingTimeFilter

@Component({
  components: { FilterParamName, QuickSelectLinks, OverlayScrollbars }
})
export default class TimeUiElement extends Mixins<UiControlMixin<BookingTimeFrameBase[], BookingTimeCollection>>(UiControlMixin)
  implements FilterUIComponent<BookingTimeFrameBase[]> {
  @Prop({ default: () => [] }) readonly declare value: BookingTimeFrameBase[]

  @Inject({ from: 'filterTimeFrames', default: defBookingTimeFilterFactory })
  private filterTimeFrames!: BookingTimeFilterFunc

  private primaryKey: keyof BookingTableRow = 'key'

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: { required, minLength: minLength(1) }
    }
  }

  private get tableItems (): BookingTableRow[] {
    return this.options.map((item: BookingTimeFrame) => {
      const selected = Boolean(this.selected.find((val: BookingTimeFrameBase) => val.value === item.value))

      return {
        key: item.value,
        selected,
        timeFrame: item
      }
    })
  }

  private get options (): BookingTimeFrame[] {
    return this.filterTimeFrames(this.config.options)
  }

  private selectTime (time: BookingTableRow): void {
    const value = time.timeFrame.value

    if (time.selected) {
      this.setSelected(this.selected.filter((item: BookingTimeFrameBase) => item.value !== value))
      return
    }

    this.setSelected([...this.selected, time.timeFrame])
  }

  private selectAll (): void {
    this.setSelected(this.makeValues(this.options))
  }

  private selectNone (): void {
    this.setSelected([])
  }

  private invertSelection (): void {
    const selectedValues = new Set<BookingTimeFrameValue>(
      this.selected.map((val: BookingTimeFrameBase) => val.value)
    )

    const selected: BookingTimeFrame[] = this.options.filter((item: BookingTimeFrame) => {
      return !selectedValues.has(item.value)
    })

    this.setSelected(this.makeValues(selected))
  }

  private makeValues (timeFrames: BookingTimeFrame[]): BookingTimeFrameBase[] {
    return timeFrames.map(({ value, start, end }: BookingTimeFrame) => ({
      value,
      start,
      end
    }))
  }
}
