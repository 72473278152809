import { SliderConfig } from './slider-config'
import { RangeOptions } from '@/types/planning/wizard/filter-editor'

export class RangeValueConfig extends SliderConfig {
  protected override _defValue = 5000

  protected override rangeOptions: RangeOptions = {
    rangeMin: 0,
    rangeMax: 12000,
    rangeStep: 20,
    formatter: val => `${val}`
  }

  protected override getDefaultValue (): number {
    return super.getDefaultValue() ?? this._defValue
  }

  protected override setDefaultValue (value: number | string): void {
    super.setDefaultValue(parseInt(value as string))
  }

  override get displayMarkers () {
    return false
  }

  override get displayInput () {
    return true
  }

  override formatValue (value: number): string {
    return this.rangeOptions.formatter(value)
  }
}
