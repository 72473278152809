import {
  PLAN_WIZARD_SET_CAMPAIGN_NAME,
  PLAN_WIZARD_SET_CAMPAIGN_STATUS,
  PLAN_WIZARD_ADD_FILTER_NODE,
  PLAN_WIZARD_REMOVE_FILTER_NODE,
  PLAN_WIZARD_REPLACE_FILTER_NODE,
  PLAN_WIZARD_SET_END_DATE,
  PLAN_WIZARD_SET_START_DATE,
  SET_PLAN_WIZARD_UPDATED_STATE,
  PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER,
  PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT,
  PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG
} from '@/store/mutation-types'
import {
  SET_CAMPAIGN_FROM_WIZARD
} from '@/store/action-types'

const wizardUpdateMutations = [
  PLAN_WIZARD_SET_CAMPAIGN_NAME,
  PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER,
  PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT,
  PLAN_WIZARD_SET_CAMPAIGN_STATUS,
  PLAN_WIZARD_ADD_FILTER_NODE,
  PLAN_WIZARD_REMOVE_FILTER_NODE,
  PLAN_WIZARD_REPLACE_FILTER_NODE,
  PLAN_WIZARD_SET_END_DATE,
  PLAN_WIZARD_SET_START_DATE,
  PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG
]

export default store => {
  store.subscribe(mutation => {
    if (wizardUpdateMutations.includes(mutation.type)) {
      store.commit(SET_PLAN_WIZARD_UPDATED_STATE, true)
    }
  })

  store.subscribeAction({
    after: (action) => {
      if (action.type === SET_CAMPAIGN_FROM_WIZARD) {
        store.commit(SET_PLAN_WIZARD_UPDATED_STATE, false)
      }
    }
  })
}
