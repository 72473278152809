

















import Checkboard from './checkboard.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { BackgroundProperty } from 'csstype'

@Component({
  components: { Checkboard }
})
export default class ColorExample extends Vue {
  @Prop({ required: true }) color!: BackgroundProperty<string>
}
