import {
  VICINITY_IMPORT_WIZARD_SET_DATASET,
  VICINITY_IMPORT_WIZARD_SET_QUERY_DATA,
  VICINITY_IMPORT_WIZARD_SET_VISIBLE
} from '@/store/mutation-types'

export default {
  [VICINITY_IMPORT_WIZARD_SET_VISIBLE] (state, visible) {
    state.visible = visible
  },
  [VICINITY_IMPORT_WIZARD_SET_DATASET] (state, dataset) {
    state.sourceDataset = dataset
  },
  [VICINITY_IMPORT_WIZARD_SET_QUERY_DATA] (state, query) {
    state.query = query
  }
}
