import { latLng } from 'leaflet'
import { LayerType } from '@/types/visualization/layer/enums'
import { DEFAULT_CIRCLE_LAYER_STYLE } from '@/components/visualization/layer-settings/constants'
import { DEFAULT_LAYER_SETTINGS } from '@/components/visualization/layer-settings/constants/layer-settings'
import { fixNumberColumns, getLayerTagsInfo, parseLayerSettings, parseLayerStyle } from './common'
import { guessLatLngColumns, guessRadiusColumn, parseLayerColumns } from './columns'

export function parseCirclesLayer (layer) {
  if (!Array.isArray(layer.data) || layer.data.length !== 1) {
    throw new Error('Incorrect circle layer structure.')
  }

  const style = parseLayerStyle(layer.style, DEFAULT_CIRCLE_LAYER_STYLE, {
    legend: {
      content: layer.name
    }
  })

  const settings = parseLayerSettings(layer.settings, DEFAULT_LAYER_SETTINGS)
  const dataRows = layer.data[0].datasetRows
  const columnTypes = layer.data[0].columnInfos
  const layerColumns = parseLayerColumns(columnTypes)

  fixNumberColumns(dataRows, columnTypes)
  guessLatLngColumns(style, layerColumns.numberColumns)
  guessRadiusColumn(style, layerColumns.numberColumns)

  const circleRows = dataRows.map(row => ({
    latLng: latLng(row[style.latitudeColumn], row[style.longitudeColumn]),
    meta: row
  }))

  return {
    id: layer.id,
    name: layer.name,
    type: LayerType.Circles,
    ...layerColumns,
    dataset: dataRows,
    geometry: circleRows,
    ...getLayerTagsInfo(layer.data, 0),
    style,
    settings,
    dataSource: layer.dataSource
  }
}
