import { cloneDeep, get, isEmpty } from 'lodash'
import {
  CHECK_AND_UPDATE_LAYER_STYLE,
  RESET_VISUALIZATION_LAYER_STYLE,
  SET_VISUALIZATION_LAYER_RANGE_COLOR,
  SET_VISUALIZATION_LAYER_SUBLAYER_COLOR,
  SET_VISUALIZATION_LAYER_SUBLAYER_OPTIONS,
  SET_VISUALIZATION_LAYER_SUBLAYER_VISIBILITY,
  SET_VISUALIZATION_LAYER_VISIBILITY,
  SET_VISUALIZATION_LAYER_Z_INDEX,
  UPDATE_LAYER_ICONS_SPRITE,
  UPDATE_LAYER_RANGE_QUERY
} from '@/store/action-types'
import { VISUALIZATION_LAYERS } from '@/store/getter-types'
import {
  SET_VISUALIZATION_LAYER_GEOMETRIES,
  SET_VISUALIZATION_LAYER_STYLE,
  UPDATE_VISUALIZATION_LAYER_STYLE,
  UPDATE_VISUALIZATION_RANGE_QUERY
} from '@/store/mutation-types'
import {
  DEFAULT_LINE_LAYER_STYLE,
  DEFAULT_POLYGON_LAYER_STYLE,
  LAYER_TYPE_GROUPS
} from '@/components/visualization/layer-settings/constants'
import { getImagesFromSprite, redrawAndReturnSprite } from '@/utils/image'
import { getMainLayerFillOptions, getMainLayerStyleProp } from '@/utils/visualization'
import { getSubLayers } from '@/components/visualization/layers-tree/utils'
import { fillModeSupportsSubLayers } from '@/components/visualization/layer-settings/utils'
import { matchGeometrysetWithDataset } from '@/utils/visualization/parse-layers/shapes-layer'
import { LayerType, ShapeType } from '@/types/visualization/layer/enums'

// actions
const actions = {
  [SET_VISUALIZATION_LAYER_VISIBILITY] ({ commit }, { layer, visibility }) {
    const style = {
      visible: visibility
    }
    commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer, style })
  },
  [SET_VISUALIZATION_LAYER_SUBLAYER_OPTIONS] ({ commit }, { layer, key, options }) {
    const stylePath = getMainLayerStyleProp(layer)

    const style = {
      [stylePath]: {
        subLayers: {
          [key]: options
        }
      }
    }
    commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer, style })
  },
  [SET_VISUALIZATION_LAYER_SUBLAYER_VISIBILITY] ({ dispatch }, { layer, key, visible }) {
    return dispatch(SET_VISUALIZATION_LAYER_SUBLAYER_OPTIONS, {
      layer,
      key,
      options: { visible }
    })
  },
  [SET_VISUALIZATION_LAYER_SUBLAYER_COLOR] ({ dispatch }, { layer, key, color }) {
    return dispatch(SET_VISUALIZATION_LAYER_SUBLAYER_OPTIONS, {
      layer,
      key,
      options: { color }
    })
  },
  [SET_VISUALIZATION_LAYER_RANGE_COLOR] ({ commit }, { layer, key, color }) {
    const stylePath = getMainLayerStyleProp(layer)
    const rangeValues = cloneDeep(get(layer, ['style', stylePath, 'range', 'values']))
    rangeValues[key].color = color

    const style = {
      [stylePath]: {
        range: {
          values: rangeValues
        }
      }
    }
    commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer, style })
  },
  [SET_VISUALIZATION_LAYER_Z_INDEX] ({ getters, commit }, { layer, zIndex }) {
    const group = LAYER_TYPE_GROUPS.find(group => group.test(layer))
    if (!group) {
      return
    }

    const layers = getters[VISUALIZATION_LAYERS]
      .filter(group.test)
      .sort((a, b) => a.style.zIndex - b.style.zIndex)

    const oldIndex = layers.indexOf(layer)
    const newIndex = layers.findIndex(l => l.style.zIndex === zIndex)
    const step = (oldIndex - newIndex) / Math.abs((newIndex - oldIndex))

    if (newIndex === layers.length - 1) {
      // Edge case. If layer is moved to top we can just set (max + 1) z-index to it
      // and so bring it to front in one step.
      commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer, style: { zIndex: zIndex + 1 } })
      return
    }

    if (newIndex === 0 && zIndex > 1) {
      // Edge case. If layer is moved to bottom we can just set (min - 1) z-index to it
      // and so bring it to back in one step.
      // But only if (min - 1) is a positive value. That helps us to avoid negative z-index.
      commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer, style: { zIndex: zIndex - 1 } })
      return
    }

    let i = newIndex

    while (layers[i] && i !== oldIndex) {
      const l = layers[i]
      commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer: l, style: { zIndex: l.style.zIndex + step } })

      i += step

      if (layers[i] && layers[i].style.zIndex !== l.style.zIndex) {
        // No need to update next layer z-index if it is unique already.
        break
      }
    }

    commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer, style: { zIndex } })
  },
  [CHECK_AND_UPDATE_LAYER_STYLE] ({ commit }, { layer, newStyle }) {
    const stylePath = getMainLayerStyleProp(layer)
    const oldFillStyle = getMainLayerFillOptions(layer)
    const newFillStyle = cloneDeep(getMainLayerFillOptions(layer, newStyle))

    // reset sublayers on style update
    newStyle[stylePath].subLayers = {}

    // check styles to sublayer
    const recalculateSubLayers = fillModeSupportsSubLayers(newFillStyle.type) &&
      newFillStyle.gradientColumn === oldFillStyle.gradientColumn &&
      newFillStyle.showSubLayers &&
      newFillStyle.type === oldFillStyle.type

    if (recalculateSubLayers) {
      const customSubLayers = Object.keys(newFillStyle.subLayers)
        .reduce((settings, propName) => {
          if (!isEmpty(newFillStyle.subLayers[propName])) {
            settings[propName] = newFillStyle.subLayers[propName]
          }
          return settings
        }, {})

      const updatedLayer = { ...layer, style: { [stylePath]: newFillStyle } }
      const subLayers = getSubLayers(updatedLayer).reduce((settings, sublayer) => {
        settings[sublayer.id] = { color: sublayer.color }
        return settings
      }, {})

      newStyle[stylePath].subLayers = { ...customSubLayers, ...subLayers }
    }

    commit(SET_VISUALIZATION_LAYER_STYLE, { layer, style: newStyle })

    if (layer.type === LayerType.Shapes && oldFillStyle.showDuplicates !== newFillStyle.showDuplicates) {
      // Re-match geometryset with dataset if it's required.
      const makeGeometry = (key, pathRow, meta) => {
        return {
          ...pathRow,
          meta
        }
      }
      const getPathCoordinates = (pathRow) => pathRow.paths
      const geometries = matchGeometrysetWithDataset({
        layer,
        pathRows: layer.geometry,
        dataRows: layer.dataset,
        getPathCoordinates,
        makeGeometry
      })

      commit(SET_VISUALIZATION_LAYER_GEOMETRIES, { layer, geometries })
    }
  },
  [RESET_VISUALIZATION_LAYER_STYLE] ({ commit }, layer) {
    let style = null

    commit(SET_VISUALIZATION_LAYER_STYLE, { layer, style: {} })

    switch (layer.shapeType) {
      case ShapeType.Polygon:
        style = DEFAULT_POLYGON_LAYER_STYLE
        break
      case ShapeType.Line:
        style = DEFAULT_LINE_LAYER_STYLE
        break
    }

    commit(SET_VISUALIZATION_LAYER_STYLE, { layer, style })
  },
  [UPDATE_LAYER_ICONS_SPRITE] ({ commit }, { key, layer, imageData }) {
    return getImagesFromSprite(layer.style.fill).then(images => {
      images[key] = imageData

      return redrawAndReturnSprite(images).then(({ spriteIndex, spriteImage }) => {
        const style = {
          fill: {
            icon: {
              spriteIndex,
              spriteImage
            }
          }
        }

        commit(UPDATE_VISUALIZATION_LAYER_STYLE, { layer, style })
      })
    })
  },
  [UPDATE_LAYER_RANGE_QUERY] ({ commit }, { layer, query }) {
    commit(UPDATE_VISUALIZATION_RANGE_QUERY, { layer, query })
  }
}

export default actions
