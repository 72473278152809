import { BID_COLUMN, CITY_FIELD, OKZ_COLUMN } from '@/constants/unit/fields'
import { DatasetRow, DatasetRows, SimilarityCheckResult } from '@/types/common'
import { findSimilarity } from '@/utils/similarity'
import { uniq, trimStart } from 'lodash'

type DatasetValuesComparator = (row: DatasetRow, originalRow: DatasetRow) => boolean

const indexOfAll = function<T> (list: T[], searchValue: T) {
  const indexes: number[] = []

  for (let i = 0; i < list.length; i++) {
    if (list[i] === searchValue) {
      indexes.push(i)
    }
  }

  return indexes
}

export class MatchRowsHelper {
  private readonly _originalDatasetRows: Readonly<DatasetRows>

  constructor (originalDatasetRows: DatasetRows) {
    this._originalDatasetRows = originalDatasetRows
  }

  private matchRowsByColumnsMap (datasetRows: DatasetRows, columns: Map<string, DatasetValuesComparator>): SimilarityCheckResult<DatasetRow> {
    return datasetRows
      .reduce((result: SimilarityCheckResult<DatasetRow>, row) => {
        let matchIndex = -1
        const notFoundValue = {}

        columns.forEach((comparator, value) => {
          const rowIndex = this._originalDatasetRows.findIndex(item => comparator(row, item))

          if (rowIndex > -1) {
            matchIndex = rowIndex
          }

          notFoundValue[value] = row[value]
        })

        if (matchIndex !== -1) {
          result.foundValues.push(this._originalDatasetRows[matchIndex])
          return result
        }

        result.notFoundValues.push(notFoundValue)
        return result
      }, {
        foundValues: [],
        notFoundValues: []
      })
  }

  private importWithSimilarity (datasetRows: DatasetRows, column: string, columnName: string): SimilarityCheckResult<DatasetRow> {
    const rows: string[] = datasetRows.map(row => row[column] as string)

    const listOptionsFull = this._originalDatasetRows.map(item => item[columnName] as string)
    const listOptions = listOptionsFull.map(item => item.split(',')[0].trim())

    const { foundValues: found, notFoundValues: notFound, supposedValues: unclear } = this.findSimilarityByColumnName(columnName, rows, listOptions)
    const { foundValues, notFoundValues, supposedValues } = this.findSimilarityByColumnName(columnName, notFound.map(item => item[columnName] as string), listOptionsFull)

    return {
      foundValues: uniq(found.concat(foundValues)),
      supposedValues: uniq((unclear ?? []).concat(supposedValues ?? [])),
      notFoundValues
    }
  }

  private findSimilarityByColumnName (columnName: string, values: string[], listOptions: string[]): SimilarityCheckResult<DatasetRow> {
    const { foundValues, notFoundValues } = findSimilarity<string>(values, listOptions)

    const parsedFoundValues: DatasetRow[] = []
    const supposedValues: string[] = []

    foundValues.forEach(item => {
      const indexes = indexOfAll(listOptions, item)
      const parsedValue = this._originalDatasetRows[indexes[0]]

      if (indexes.length > 1) {
        supposedValues.push(parsedValue[columnName] as string)
      }

      parsedFoundValues.push(parsedValue)
    })

    return {
      foundValues: parsedFoundValues,
      notFoundValues: notFoundValues.map(item => ({ [columnName]: item })),
      supposedValues: supposedValues.map(item => ({ [columnName]: item }))
    }
  }

  importCitiesFromDataset (datasetRows: DatasetRows, cityColumn: string, okzColumn: string | null): SimilarityCheckResult<DatasetRow> {
    if (okzColumn) {
      const columns = new Map<string, DatasetValuesComparator>([
        [okzColumn, (row, originalRow) => originalRow[OKZ_COLUMN] === `${row[okzColumn]}`],
        [cityColumn, (row, originalRow) => originalRow[CITY_FIELD] === `${row[cityColumn]}`]
      ])

      return this.matchRowsByColumnsMap(datasetRows, columns)
    }

    return this.importWithSimilarity(datasetRows, cityColumn, CITY_FIELD)
  }

  importNetworksFromDataset (datasetRows: DatasetRows, bidColumn: string): SimilarityCheckResult<DatasetRow> {
    const columns = new Map<string, DatasetValuesComparator>([
      [bidColumn, (row, originalRow) => originalRow[BID_COLUMN] === trimStart(`${row[bidColumn]}`, '0')]
    ])
    return this.matchRowsByColumnsMap(datasetRows, columns)
  }
}
