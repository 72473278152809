




















































import { getMixedPropsFromSelf } from '@/utils/component'
import { WIZARD_NAVIGATION_ACTIVE_STEP } from '@/store/getter-types'
import { ModalMixin } from '@/mixins/modal.mixin'
import WizardFooterNav from './wizard-footer-nav/wizard-footer-nav.vue'
import WizardContainer from './wizard-container/wizard-container.vue'
import { WizardContainerPropsMixin } from './mixins/wizard-container-props.mixin'
import { WizardNavProps } from './mixins/wizard-nav-props.mixin'
import wizardStore from '@/store/wizard'
import { DynamicStoreMixin } from '@/mixins/dynamic-store.mixin'
import { Component, Mixins } from 'vue-property-decorator'
import { WizardStepConfig } from '@/types/wizard'
import { VueInstanceProps } from '@/types/vue'

@Component({
  components: { WizardContainer, WizardFooterNav }
})
export default class ModalWizard extends
  Mixins<ModalMixin, DynamicStoreMixin, WizardContainerPropsMixin, WizardNavProps>(ModalMixin, DynamicStoreMixin, WizardContainerPropsMixin, WizardNavProps) {
  private get activeStep (): WizardStepConfig {
    return this.dynamicStoreGetter<WizardStepConfig>(WIZARD_NAVIGATION_ACTIVE_STEP)
  }

  private get modalProps (): VueInstanceProps {
    return {
      centered: true,
      scrollable: true,
      size: this.size,
      bodyClass: 'wizard__body',
      ...this.$attrs
    }
  }

  private get containerProps (): VueInstanceProps {
    return getMixedPropsFromSelf(this, WizardContainerPropsMixin)
  }

  private get navProps (): VueInstanceProps {
    return getMixedPropsFromSelf(this, WizardNavProps)
  }

  private get wizardSlotScope (): VueInstanceProps {
    return {
      step: this.activeStep
    }
  }

  private resetWizard () {
    this.$store.unregisterModule(this.namespace)
  }

  private onShow () {
    if (!this.isStoreInitialized) {
      this.$store.registerModule(this.namespace, wizardStore)
    }

    this.$emit('wizard-ready')
  }
}
