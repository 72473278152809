export enum OrderStatus {
  Draft = 'Draft',
  OfferPrepared = 'PreparedOffer',
  OfferPending = 'OfferPending',
  Buying = 'BuyingAds',
  Distribution = 'DistributeProductionInfo',
  Invoice = 'InvoiceSettlement',
}

export enum OrderActions {
  Delete = 'Delete',
  MakeOffer = 'MakeOffer',
  SendOffer = 'SendOffer',
  AddCosts = 'AddCosts',
  ChangeOffer = 'ChangeOffer',
  BuySites = 'BuySites',
  RePlan = 'RePlan',
  GetStatus = 'GetStatus',
  BuyingComplete = 'BuyingComplete',
  ReScore = 'ReScore',
  CancelOrder = 'CancelOrder',
  SendInfo = 'SendProductionInfo',
  AttachDocument = 'AttachDocument'
}

export enum OrdersViewType {
  Board = 'Board',
  Table = 'Table'
}
