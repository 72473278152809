import { isEmpty } from 'lodash'
import { scoringLocationsApi } from '@/api/rest/scoring/scoring-locations.api'
import {
  CAMPAIGN_FILTERING_ENABLED,
  CAMPAIGN_REQUEST_DATA,
  CAMPAIGN_SCORING_REQUEST_DATA,
  SCORING_DETAILS,
  SCORING_UNITS_TO_RESOLVE,
  CAMPAIGN_FLIGHTS_DISABLED
} from '@/store/getter-types'
import {
  GET_SCORING_DETAILS,
  LOAD_SCORES_DETAILS,
  CHECK_SCORES_FOR_UNCLEAR_ASSIGNMENTS
} from '@/store/action-types'
import { SET_SCORING_DETAILS, SET_SCORING_UNITS_TO_RESOLVE } from '@/store/mutation-types'
import { UnclearAssignmentError } from '@/errors/scoring/unclear-assignment-error'
import { ScoringRequestPayload } from '@/types/store/scoring'

// initial state
const state = {
  unitsToResolve: [],
  scoresDetails: null
}

// getters
const getters = {
  [SCORING_DETAILS] (state) {
    return state.scoresDetails
  },
  [SCORING_UNITS_TO_RESOLVE] (state) {
    return state.unitsToResolve
  }
}

// actions
const actions = {
  async [CHECK_SCORES_FOR_UNCLEAR_ASSIGNMENTS] ({ getters, dispatch }) {
    await dispatch(LOAD_SCORES_DETAILS)

    let scoringDetailsResult = {
      table: getters[SCORING_DETAILS],
      unClearAssignments: getters[SCORING_UNITS_TO_RESOLVE]
    }

    if (getters[CAMPAIGN_FILTERING_ENABLED]) {
      const availability = !getters[CAMPAIGN_FLIGHTS_DISABLED]
      scoringDetailsResult = await scoringLocationsApi.getScoringDetails(getters[CAMPAIGN_REQUEST_DATA], availability)
    }

    if (!isEmpty(scoringDetailsResult.unClearAssignments)) {
      return Promise.reject(new UnclearAssignmentError('Scoring details response contains unclear assignments', scoringDetailsResult))
    }

    return scoringDetailsResult.table
  },
  [LOAD_SCORES_DETAILS] ({ dispatch, getters }) {
    const requestObj: ScoringRequestPayload = {
      campaign: getters[CAMPAIGN_SCORING_REQUEST_DATA],
      availability: !getters[CAMPAIGN_FLIGHTS_DISABLED]
    }

    return dispatch(GET_SCORING_DETAILS, requestObj)
  },
  async [GET_SCORING_DETAILS] ({ commit, getters }, payload: ScoringRequestPayload) {
    if (!isEmpty(getters[SCORING_DETAILS])) {
      return Promise.resolve(getters[SCORING_DETAILS])
    }

    const data = await scoringLocationsApi.getScoringDetails(payload.campaign, payload.availability)

    commit(SET_SCORING_DETAILS, data.table)
    commit(SET_SCORING_UNITS_TO_RESOLVE, data.unClearAssignments)

    return data.table
  }
}

// mutations
const mutations = {
  [SET_SCORING_DETAILS] (state, details) {
    state.scoresDetails = details
  },
  [SET_SCORING_UNITS_TO_RESOLVE] (state, unitsToResolve) {
    state.unitsToResolve = unitsToResolve
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
