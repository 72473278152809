import ClusteringAwareMixin from './clustering-aware.mixin'
import { FilledGeometryLayer } from '@/types/visualization/layer'
import { Component } from 'vue-property-decorator'
import { Color } from 'csstype'
import { LayerGeometry } from '@/types/visualization/layer/geometry'
import { LayerColorGetter } from '@/types/visualization/layer/style'
import { DatasetColumnKey } from '@/types/common'

@Component
export default class ClusteringFillMixin<TLayer extends FilledGeometryLayer> extends ClusteringAwareMixin<TLayer> {
  protected get clusteringFillDataRows (): LayerGeometry[] {
    const col = this.layer.style.fill.gradientColumn

    return this.getClusteringDataRows(col, this.layer.style.fill)
  }

  protected get clusteringFillColorFunc (): LayerColorGetter<LayerGeometry> {
    const gradientColumn = this.layer.style.fill.gradientColumn

    if (typeof gradientColumn === 'undefined') {
      return () => null
    }

    return row => this.getClusteringFillColor(row.meta[gradientColumn] as string)
  }

  protected get clusteringFillUniqueValues () {
    return this.getUniqueClusteringValues(this.layer.style.fill.gradientColumn)
  }

  protected get quantityOfFillValuesForCluster () {
    return this.calculateQuantityOfValuesForCluster(this.clusteringFillUniqueValues, this.layer.style.fill.gradientColumn)
  }

  protected getClusteringFillColor (clusterKey: DatasetColumnKey): Color {
    return this.calculateClusteringColor(this.layer.style.fill, clusterKey, this.clusteringFillUniqueValues)
  }
}
