import { parse as parseJson } from 'json5'
import { get, omit } from 'lodash'
import { ScoringFunctionConfig, ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { isFlightFilterNode, isLocationFilterNode, isMediaTypeFilterNode } from '@/utils/plan-wizard-steps'
import { COMBINED_NODE_FLIGHT_NAME } from '@/store/planning-wizard/planning-wizard-steps/steps/constants/flights-combined.constants'
import { NODE_ID_START } from '@/constants/scoring-graph'
import { generateFilterId } from '@/store/planning-wizard/utils'

function getNodeType (node) {
  const planningFunctionClassFull = get(node, 'planningFunctions.0.className', '').split('.')
  return planningFunctionClassFull[planningFunctionClassFull.length - 1]
}

function parseNodeFunctions (obj = {}) {
  const functions = get(obj, 'planningFunctions', []) // Handle possible undefined value.
  const scoringFunction: ScoringFunctionConfig = {
    name: getNodeType(obj),
    data: []
  }

  functions.forEach((func) => {
    // TODO - ask back-end to send valid JSON here
    const funcContent = func.content === '' ? '' : parseJson(func.content)
    if (funcContent) {
      scoringFunction.data.push(funcContent)
    }
  })

  return scoringFunction
}

function parseNode (node) {
  node.scoringFunction = parseNodeFunctions(node)
  delete node.planningFunctions
}

function updateNodeKey (nodeKey, newParent) {
  if (isLocationFilterNode(newParent)) {
    return { ...nodeKey, areaId: newParent.id }
  }

  if (isMediaTypeFilterNode(newParent)) {
    return { ...nodeKey, typeId: newParent.id }
  }

  if (isFlightFilterNode(newParent)) {
    return omit(nodeKey, 'typeId')
  }

  if (!newParent.scoringFunction.name) {
    // Reset key on meta nodes.
    return {}
  }

  return nodeKey
}

export function parseNodesAndTransitions (planResponse) {
  const nodes = get(planResponse, 'nodes', [])
  const transitions = get(planResponse, 'transitions', [])

  nodes.forEach(parseNode)

  const resultNodes: ScoringFunctionNode[] = []

  function processNodeRecursive (node: ScoringFunctionNode, nodeKey?: ScoringFunctionNode) {
    if (node.scoringFunction.name) {
      const customNodeAttrs: Partial<ScoringFunctionNode> = {}

      if (node.name === COMBINED_NODE_FLIGHT_NAME) {
        // quick fix for 'combined' flights
        customNodeAttrs.id = generateFilterId()
        customNodeAttrs.name = nodes.find((searchNode) => searchNode.id === nodeKey?.typeId).name
      }

      resultNodes.push({
        ...node,
        ...customNodeAttrs,
        ...nodeKey
      })
    }

    const childrenIds = transitions
      .filter(transition => transition.from === node.id)
      .map(transition => transition.to)
      .filter((id) => !resultNodes.some((resultNode) => resultNode.id === id))

    const childNodes = nodes.filter(possibleChild => childrenIds.includes(possibleChild.id))

    nodeKey = updateNodeKey(nodeKey, node)

    childNodes.forEach(childNode => {
      processNodeRecursive(childNode, nodeKey)
    })
  }

  const startNode: ScoringFunctionNode | undefined = nodes.find(node => node.id === NODE_ID_START)
  // Plan can be a draft without real nodes.
  if (startNode) {
    processNodeRecursive(startNode)
    planResponse.nodes = resultNodes
  } else {
    planResponse.nodes = []
  }

  delete planResponse.planningFunctions
  delete planResponse.transitions

  return planResponse
}
