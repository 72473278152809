
import { SelectOptionObject } from '@/types/bootstrap'
import { DatasetRow, DatasetRowValue } from '@/types/common'
import { AgEvent, ICellRendererParams } from 'ag-grid-community'
import { isEqual } from 'lodash'
import { CreateElement, VNode } from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import { CellRendererMixin } from '../mixins/grid-components/cell-renderer.mixin'

interface GridCellSelectInputOptions {
  selected: DatasetRowValue,
  options: SelectOptionObject[],
  disabled: boolean,
  visible: boolean
}

interface GridCellSelectInputParams extends ICellRendererParams {
  getOptions: (item: DatasetRow) => GridCellSelectInputOptions
}

export interface GridCellSelectInputEvent extends AgEvent {
  value: string;
  data: DatasetRow
}

@Component
export default class GridCellSelectInput extends Mixins<CellRendererMixin<GridCellSelectInputParams>>(CellRendererMixin) {
  private cellConfig: GridCellSelectInputOptions = {} as GridCellSelectInputOptions

  created () {
    this.cellConfig = this.params.getOptions(this.params.data)

    const watch = this.$watch(() => this.params.getOptions(this.params.data), (newConfig, oldConfig) => {
      this.cellConfig = isEqual(oldConfig, newConfig) ? this.cellConfig : newConfig
    })

    this.$once('hook:destroyed', watch)
  }

  private emitChangeEvent (type: string, value: DatasetRowValue): void {
    this.params.api.dispatchEvent({
      type,
      value,
      data: this.params.data
    } as GridCellSelectInputEvent)
  }

  private get selected (): DatasetRowValue {
    return this.cellConfig.selected
  }

  private get options (): SelectOptionObject[] {
    return this.cellConfig.options
  }

  private get disabled (): boolean {
    return this.cellConfig.disabled
  }

  renderActions (h: CreateElement): VNode | null {
    if (!this.cellConfig.visible) {
      return null
    }

    const selectBtn = h(
      'b-form-select',
      {
        class: 'selector-input my-1',
        props: {
          value: this.selected,
          options: this.options,
          size: 'sm',
          disabled: this.disabled
        },
        on: {
          change: (selected) => this.emitChangeEvent('action:select-change', selected)
        }
      }
    )

    return selectBtn
  }

  render (h: CreateElement): VNode {
    return h('div', {
      staticClass: 'select-input-cell'
    }, [this.renderActions(h) ?? []])
  }
}
