import { ApiRequestConfig } from '@/api/types'
import { PlanObject, ScoringDetailsObject, ScoringObject, VisualizationGenerationData } from '@/types/planning'
import { ObjectId } from '@/types/common'
import { VisualizationLayer } from '@/types/visualization/layer'
import { CampaignRequestBody } from '@/types/store/campaign'
import { ApiClient } from '@/api/api-client'
import { transformPlanRequest } from './transform-request/scoring-locations-request.transform'
import { transformScoringLocationsResponse } from './transform-response/scoring-locations-response.transform'
import { transformScoringFullResponse } from './transform-response/scoring-full-response.transform'
import { transformScoringLayersResponse } from './transform-response/scoring-layers-response.transform'
import { AxiosResponse } from 'axios'
import { BlobFileResponseWithName } from '@/types/api/data-delivery'
import { extractFileNameFromContentDisposition } from '@/utils/api/helpers'

const GET_SCORE_REQUEST_ID = 'scoring'
const GET_SCORING_LAYERS_REQUEST_ID = 'scoringLayers'

class ScoringLocationsApi extends ApiClient {
  getScoring (options: CampaignRequestBody, availability: boolean): Promise<ScoringObject> {
    const config: ApiRequestConfig = {
      transformRequest: transformPlanRequest,
      transformResponse: transformScoringLocationsResponse,
      blockingRequest: true,
      requestId: GET_SCORE_REQUEST_ID,
      params: {
        availability
      }
    }

    return this.post<ScoringObject>('', options, config)
  }

  getExcelFileFromBookedNetworkTimeFrames (plan: CampaignRequestBody): Promise<BlobFileResponseWithName> {
    const config: ApiRequestConfig = {
      transformRequest: transformPlanRequest,
      blockingRequest: true,
      requestId: GET_SCORE_REQUEST_ID,
      responseType: 'blob'
    }
    return this.http.post('ExcelScoringResults', plan, config)
      .then(({ data, headers }: AxiosResponse) => {
        const fileName = extractFileNameFromContentDisposition(headers)

        return {
          data,
          fileName
        }
      })
  }

  getScoringDetails (options: CampaignRequestBody, availability: boolean): Promise<ScoringDetailsObject> {
    const config: ApiRequestConfig = {
      transformRequest: transformPlanRequest,
      transformResponse: transformScoringFullResponse,
      blockingRequest: true,
      params: {
        availability
      }
    }

    return this.post<ScoringDetailsObject>('GetScoreResults/', options, config)
  }

  getVisualizationForPlanning ({ id, name }: VisualizationGenerationData): Promise<ObjectId> {
    return this.post<ObjectId>(`planning/${id}/visualization/generate/${name}`)
  }

  getScoringLayers (options: PlanObject): Promise<VisualizationLayer[]> {
    const config: ApiRequestConfig = {
      transformRequest: transformPlanRequest,
      transformResponse: transformScoringLayersResponse,
      requestId: 'GET_SCORING_LAYERS_REQUEST_ID',
      blockingRequest: true
    }

    return this.post<VisualizationLayer[]>('GetScoreLayers', options, config)
  }
}

export const scoringLocationsApi = new ScoringLocationsApi({
  baseURL: API_CONF.api + '/api/Scoring/'
})

export { GET_SCORE_REQUEST_ID, GET_SCORING_LAYERS_REQUEST_ID }
