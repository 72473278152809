







































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { UploadFileMixin } from '@/mixins/upload-file.mixin'
import { FileMixin } from '@/mixins/file.mixin'
import FileUploadZone from './file-upload-zone/file-upload-zone.vue'
import { FormInputState } from '@/types/bootstrap'
import { ReportsList } from '@/types/report'
import { get } from 'lodash'
import FileUploadReports from '@/components/file-upload/file-upload-reports.vue'
import { UploadCallback, UploadCallbackPayload } from '@/types/file-upload'

@Component({
  components: { FileUploadReports, FileUploadZone }
})
export default class FileUpload extends Mixins<FileMixin, UploadFileMixin>(FileMixin, UploadFileMixin) {
  @Prop({ required: true }) callback!: UploadCallback
  @Prop({ default: false }) progress!: boolean
  @Prop({ default: () => [] }) acceptTypes!: string[]
  @Prop({ default: () => [] }) acceptExtensions!: string[]
  @Prop({ default: null }) state!: FormInputState

  isUploaded = false
  reports: ReportsList = []

  created () {
    this.allowedTypes = this.acceptTypes
    this.allowedExtensions = this.acceptExtensions
  }

  get showProgress (): boolean {
    return this.progressValue !== -1
  }

  get needToDisplayReport (): boolean {
    return this.reports.length > 0
  }

  uploadFile (value: File) {
    this.file = value

    if (!this.validateFile()) {
      this.$toastError(this.$t('common.errors.valid-file') as string)
      this.file = null
      return
    }

    const requestBody: UploadCallbackPayload = {
      file: value
    }

    if (this.progress) {
      requestBody.callback = this.uploadProgress
    }

    this.callback(requestBody)
      .then(fileData => {
        this.progressValue = 100
        this.isUploaded = true
        this.extractionReport(fileData)
        this.importFile()
      })
      .catch(() => {
        this.resetFile()
        this.$toastError(this.$t('common.errors.import-file-failed') as string)
      })
  }

  override resetFile (): void {
    // @ts-ignore: The only way to access parent component method.
    FileMixin.options.methods.resetFile.call(this)
    this.progressValue = -1
    this.isUploaded = false
    this.$emit('reset', this.file)
  }

  importFile (): void {
    this.$emit('upload', this.file)
    this.fileSubmitted = true
  }

  extractionReport (fileData): void {
    this.reports = get(fileData, 'reports', [])
  }
}
