import './polyfills'
import Vue from 'vue'
import { Store } from './store/store'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import { AclPlugin } from './acl/acl'
import { i18n, initLocales } from './locales/i18n'
import { router } from './router/router'
import { ToastPlugin } from './plugins/toast'
import { LocationPickerPlugin } from './plugins/location-picker'
import { LayerPopupPlugin } from '@/plugins/layer-popup'
import { GeometryPickerPlugin } from './plugins/geometry-picker'
import { ImagePickerPlugin } from './plugins/image-picker'
import { TablePickerPlugin } from './plugins/table-picker'
import { OrderDropValidationPlugin } from '@/plugins/order-drop-validator'
import { SaveAsPlugin } from './plugins/save-as'
import { ModalDialogPlugin } from './plugins/modal-dialog'
import { ImportAreasPlugin } from './plugins/import-areas'
import { ColorPickerPlugin } from '@/plugins/color-picker'
import { AsyncModalPlugin } from '@/plugins/async-modal'
import { EnterBookableUnitsPlugin } from './plugins/enter-bookable-units'
import { ResolvePoiToAreaAssignmentsPlugin } from './plugins/resolve-unclear-assignments'
import { RouteLoadingPlugin } from '@/plugins/route-loading'
import { DisableWhenProcessing } from './directives/disable-when-procesing'
import './components/shared/index'
import './filters/index'
import './assets/styles/global.scss'
// fixed issue with focus for input elements
import '@/utils/form-group.ts'
import { logger } from '@/utils/log'
import './install-ag-grid-license'

Vue.use(BootstrapVue)
Vue.use(new ToastPlugin())
Vue.use(new AclPlugin())
Vue.use(new LocationPickerPlugin())
Vue.use(new GeometryPickerPlugin())
Vue.use(new ImagePickerPlugin())
Vue.use(new TablePickerPlugin())
Vue.use(new OrderDropValidationPlugin())
Vue.use(new SaveAsPlugin())
Vue.use(new ModalDialogPlugin())
Vue.use(new ImportAreasPlugin())
Vue.use(new ColorPickerPlugin())
Vue.use(new AsyncModalPlugin())
Vue.use(new EnterBookableUnitsPlugin())
Vue.use(new ResolvePoiToAreaAssignmentsPlugin())
Vue.use(new LayerPopupPlugin())
Vue.use(new RouteLoadingPlugin(), { router })
Vue.config.productionTip = false

Vue.directive('disable-when-processing', DisableWhenProcessing)

// Init locales
initLocales()

if (process.env.NODE_ENV === 'development') {
  logger.setDefaultLevel(logger.levels.DEBUG)
}

new Vue({
  router,
  store: Store,
  i18n,
  render: h => h(App)
}).$mount('#app')
