import { Component, Mixins } from 'vue-property-decorator'
import ColorLegendMixin from '@/components/visualization/legend/mixins/color-legend.mixin'
import LayerPathMixin from '@/components/visualization/map/mixins/layer-path.mixin'

@Component
export default class ColorPathLegendMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  Mixins<ColorLegendMixin<_TLayer>, LayerPathMixin<_TLayer>>(ColorLegendMixin, LayerPathMixin) {
  protected get colorPathLegendValues () {
    return this.makeColorLegendValues(
      this.layer.name,
      this.layerPathColor,
      this.layerPathOpacity
    )
  }
}
