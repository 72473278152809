import {
  Control,
  DomEvent,
  DomUtil,
  Util
} from 'leaflet'
import { searchCore } from './search-core'

export const SearchControl = Control.extend({
  options: {
    position: 'bottomleft',
    collapseAfterResult: true,
    expanded: false,
    placeholder: 'Search for places or addresses',
    title: 'Location Search'
  },

  initialize (options) {
    Util.setOptions(this, options)
    this._searchCore = searchCore(this, options)
    Control.prototype.initialize.call(this, options)
  },

  setOptions (options) {
    Util.setOptions(this, options)
  },

  _renderSuggestions (suggestions) {
    if (suggestions.length > 0) {
      this._suggestions.style.display = 'block'
    }

    const list = DomUtil.create('ul', 'geocoder-control-list', this._suggestions)

    for (let i = 0; i < suggestions.length; i++) {
      const suggestion = suggestions[i]

      const suggestionItem = DomUtil.create('li', 'geocoder-control-suggestion', list)
      const attrs = suggestion.attr.join(', ')
      suggestionItem.innerHTML = suggestion.text + ', ' + attrs
      suggestionItem.setAttribute('suggestion-id', suggestion.id)
    }

    if (this.getPosition().indexOf('top') > -1) {
      this._suggestions.style.maxHeight = (this._map.getSize().y - this._suggestions.offsetTop - this._wrapper.offsetTop - 10) + 'px'
    }

    if (this.getPosition().indexOf('bottom') > -1) {
      this._setSuggestionsBottomPosition()
    }
  },

  _setSuggestionsBottomPosition: function () {
    this._suggestions.style.maxHeight = (this._map.getSize().y - this._map._controlCorners[this.getPosition()].offsetHeight - this._wrapper.offsetHeight) + 'px'
    this._suggestions.style.top = (-this._suggestions.offsetHeight - this._wrapper.offsetHeight + 20) + 'px'
  },

  clear () {
    this._clearAllSuggestions()

    if (this.options.collapseAfterResult) {
      this._input.value = ''
      this._lastValue = ''
      this._input.placeholder = ''
      this._input.blur()
      DomUtil.removeClass(this._wrapper, 'geocoder-control-expanded')
    }

    if (!this._map.scrollWheelZoom.enabled() && this._map.options.scrollWheelZoom) {
      this._map.scrollWheelZoom.enable()
    }
  },

  _clearAllSuggestions () {
    this._suggestions.style.display = 'none'
    const list = this._suggestions.querySelector('.geocoder-control-list')

    if (list !== null) {
      DomUtil.remove(list)
    }

    this._searchCore._clearSuggestions()
  },

  _finalizeSuggestions (activeRequests, suggestionsLength) {
    if (!activeRequests) {
      DomUtil.removeClass(this._input, 'geocoder-control-loading')

      if (this.getPosition().indexOf('bottom') > -1) {
        this._setSuggestionsBottomPosition()
      }

      if (!suggestionsLength) {
        this._clearAllSuggestions()
      }
    }
  },

  _setupClick () {
    DomUtil.addClass(this._wrapper, 'geocoder-control-expanded')
    this._input.focus()
  },

  onAdd (map) {
    this.onRemove()

    this._map = map

    this._wrapper = DomUtil.create('div', 'geocoder-control')
    this._input = DomUtil.create('input', 'geocoder-control-input leaflet-bar', this._wrapper)
    this._input.title = this.options.title

    if (this.options.expanded) {
      DomUtil.addClass(this._wrapper, 'geocoder-control-expanded')
      this._input.placeholder = this.options.placeholder
    }

    this._suggestions = DomUtil.create('div', 'geocoder-control-suggestions leaflet-bar', this._wrapper)

    DomEvent.addListener(this._input, 'focus', function () {
      this._input.placeholder = this.options.placeholder
      DomUtil.addClass(this._wrapper, 'geocoder-control-expanded')
    }, this)

    DomEvent.addListener(this._wrapper, 'click', this._setupClick, this)

    DomEvent.addListener(this._input, 'blur', () => this.clear(), this)

    DomEvent.addListener(this._input, 'keydown', function () {
      DomUtil.addClass(this._wrapper, 'geocoder-control-expanded')
    }, this)

    DomEvent.addListener(this._suggestions, 'mousedown', (e) => {
      const suggestId = e.target.getAttribute('suggestion-id')
      const suggestion = this._searchCore._getSuggestionById(suggestId)

      DomUtil.addClass(this._input, 'geocoder-control-loading')

      this._searchCore._uploadCoordinatesToSuggestion(suggestion)
        .then(() => DomUtil.removeClass(this._input, 'geocoder-control-loading'))
    })

    DomEvent.addListener(this._input, 'keyup', Util.throttle(function (e) {
      const key = e.which || e.keyCode
      const text = e.target.value

      if (text.length < 2) {
        this._lastValue = this._input.value
        this._clearAllSuggestions()
        DomUtil.removeClass(this._input, 'geocoder-control-loading')
        return
      }

      // if this is the escape key it will clear the input so clear suggestions
      if (key === 27) {
        this._clearAllSuggestions()
        return
      }

      if (this._input.value !== this._lastValue) {
        this._lastValue = this._input.value
        DomUtil.addClass(this._input, 'geocoder-control-loading')
        this._clearAllSuggestions()
        this._searchCore._search(text)
      }
    }, 1500, this), this)

    DomEvent.disableClickPropagation(this._wrapper)

    // when mouse moves over suggestions disable scroll wheel zoom if its enabled
    DomEvent.addListener(this._suggestions, 'mouseover', function () {
      if (map.scrollWheelZoom.enabled() && map.options.scrollWheelZoom) {
        map.scrollWheelZoom.disable()
      }
    })

    // when mouse moves leaves suggestions enable scroll wheel zoom if its disabled
    DomEvent.addListener(this._suggestions, 'mouseout', function () {
      if (!map.scrollWheelZoom.enabled() && map.options.scrollWheelZoom) {
        map.scrollWheelZoom.enable()
      }
    })

    this._searchCore.on('load', () => {
      DomUtil.removeClass(this._input, 'geocoder-control-loading')
      this.clear()
      this._input.blur()
    }, this)

    return this._wrapper
  },

  onRemove () {
    if (this._wrapper) {
      DomUtil.remove(this._wrapper)
    }
  }
})
