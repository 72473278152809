import { i18n } from '@/locales/i18n'
import {
  NEW_VISUALIZATION_WIZARD_PLANS,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_ERRORS,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_EMPTY,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_ENABLED,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_INVALID,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_STEP_ID,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_HIDDEN,
  NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_VIS_FROM_PLAN
} from '@/store/getter-types'
import {
  LOAD_CUSTOMERS,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_ACTIVATE,
  NEW_VISUALIZATION_WIZARD_TAB_PLANS_VALIDATE
} from '@/store/action-types'
import { NEW_VISUALIZATION_PLANS } from '@/components/visualization/new-visualization-wizard/constant/steps'

// initial state
const state = {
  stepId: NEW_VISUALIZATION_PLANS
}

// getters
const getters = {
  [NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_ENABLED] (state, getters) {
    return getters[NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_VIS_FROM_PLAN]
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_EMPTY] (state, getters) {
    return !getters[NEW_VISUALIZATION_WIZARD_PLANS].length
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_HIDDEN] (state, getters) {
    return !getters[NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_VIS_FROM_PLAN]
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_INVALID] (state, getters) {
    return getters[NEW_VISUALIZATION_WIZARD_TAB_PLANS_ERRORS].length > 0
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PLANS_ERRORS] (state, getters) {
    const errors = []

    if (getters[NEW_VISUALIZATION_WIZARD_TAB_PLANS_IS_EMPTY]) {
      errors.push(i18n.t('visualization.modals.errors.plannings-not-selected'))
    }

    return errors
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PLANS_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  async [NEW_VISUALIZATION_WIZARD_TAB_PLANS_ACTIVATE] ({ dispatch }) {
    return await dispatch(LOAD_CUSTOMERS)
  },
  [NEW_VISUALIZATION_WIZARD_TAB_PLANS_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[NEW_VISUALIZATION_WIZARD_TAB_PLANS_ERRORS])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
