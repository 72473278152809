
import { Component, Mixins } from 'vue-property-decorator'
import { MultiPolygon, MultiPolygonOptions } from '@/lib/leaflet/layer/multi-polygons/multi-polygon/multi-polygon'
import { LayerPolygon } from '@/types/visualization/layer/geometry'
import { ItemsGroup } from '../types'
import { MultiPolygonsMixin } from '../mixins/grouped-layers/multi-polygons.mixin'
import { MultiMapObjectMixin } from './multi-map-object.mixin'

@Component
export default class MultiPolygons extends Mixins<
    MultiMapObjectMixin<LayerPolygon, MultiPolygon, MultiPolygonOptions>,
    MultiPolygonsMixin<LayerPolygon>
  >(MultiMapObjectMixin, MultiPolygonsMixin) {
  protected override getOptionsToMerge (): MultiPolygonOptions {
    return this.polygonOptions
  }

  protected override createMapObject (group: ItemsGroup<LayerPolygon, MultiPolygonOptions>, groupOptions: MultiPolygonOptions): MultiPolygon {
    const paths = group.items.map(item => item.paths)

    return new MultiPolygon(paths, groupOptions)
  }
}
