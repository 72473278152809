import { ApiResponse } from '@/api/types'
import { parseDatasetRows } from '@/utils/parse-dataset-rows'
import { ScoringDetailsObject } from '@/types/planning'
import { createTransformResponse } from '@/api/utils'
import { parseMONDataset } from '@/utils/planning/media-owner-network/media-owner-networks'

export const transformScoringFullResponse = createTransformResponse<ScoringDetailsObject | null>((response: ApiResponse['data']): ScoringDetailsObject | null => {
  if (!response) {
    return null
  }

  const resultResponse = JSON.parse(response)
  const parsedResponse = resultResponse.table

  // Fix - removing a dot at the end of column name if exists
  parsedResponse.columnInfos = parsedResponse.columnInfos
    .map(column => ({
      ...column,
      name: column.name.replace(/\.\s*$/, '')
    }))

  parsedResponse.datasetRows = parsedResponse.rows
  delete parsedResponse.rows

  const table = parseDatasetRows(parsedResponse)
  parseMONDataset(table)

  return {
    table: table,
    unClearAssignments: resultResponse.unclearAreaAssignments
  }
})
