import { freezeRecursive } from '@/utils/object'
import {
  VICINITY_IMPORT_WIZARD_CHANGE_DATASET,
  VICINITY_IMPORT_WIZARD_CHANGE_QUERY_DATA,
  VICINITY_IMPORT_WIZARD_GET_RESULTS,
  VICINITY_IMPORT_WIZARD_POINTS_RESET,
  VICINITY_IMPORT_WIZARD_RESET,
  VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA,
  VICINITY_IMPORT_WIZARD_SHOW
} from '@/store/action-types'
import {
  VICINITY_IMPORT_WIZARD_DATASET_SET_UPDATE_STATE,
  VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE,
  VICINITY_IMPORT_WIZARD_SET_DATASET,
  VICINITY_IMPORT_WIZARD_SET_QUERY_DATA,
  VICINITY_IMPORT_WIZARD_SET_VISIBLE
} from '@/store/mutation-types'
import {
  VICINITY_IMPORT_WIZARD_POINTS_LATLNG_TABLE,
  VICINITY_IMPORT_WIZARD_POINTS_NAME_COLUMN
} from '@/store/getter-types'
import {
  DEFAULT_LATITUDE_COLUMN_NAME,
  DEFAULT_LONGITUDE_COLUMN_NAME
} from '@/components/shared/coordinates-service/components/constants'
import { getDefaultQuery } from '@/utils/query-builder'

export default {
  async [VICINITY_IMPORT_WIZARD_RESET] ({ dispatch }) {
    await dispatch(VICINITY_IMPORT_WIZARD_CHANGE_DATASET, null)
    await dispatch(VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA)
    await dispatch(VICINITY_IMPORT_WIZARD_POINTS_RESET)
  },
  [VICINITY_IMPORT_WIZARD_SHOW] ({ commit }) {
    commit(VICINITY_IMPORT_WIZARD_SET_VISIBLE, true)
  },
  async [VICINITY_IMPORT_WIZARD_CHANGE_DATASET] ({ commit, dispatch }, dataset) {
    if (dataset && dataset.datasetRows) {
      freezeRecursive(dataset.datasetRows)
    }

    await dispatch(VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA)
    commit(VICINITY_IMPORT_WIZARD_SET_DATASET, dataset)
    commit(VICINITY_IMPORT_WIZARD_DATASET_SET_UPDATE_STATE, true)
  },
  [VICINITY_IMPORT_WIZARD_CHANGE_QUERY_DATA] ({ commit }, query) {
    commit(VICINITY_IMPORT_WIZARD_SET_QUERY_DATA, query)
    commit(VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE, true)
  },
  [VICINITY_IMPORT_WIZARD_RESET_QUERY_DATA] ({ commit }) {
    commit(VICINITY_IMPORT_WIZARD_SET_QUERY_DATA, getDefaultQuery())
    commit(VICINITY_IMPORT_WIZARD_QUERY_SET_UPDATE_STATE, false)
  },
  [VICINITY_IMPORT_WIZARD_GET_RESULTS] ({ getters }) {
    const rowData = getters[VICINITY_IMPORT_WIZARD_POINTS_LATLNG_TABLE]
    const nameColumn = getters[VICINITY_IMPORT_WIZARD_POINTS_NAME_COLUMN]

    return rowData.map(item => ({
      name: item[nameColumn],
      [DEFAULT_LATITUDE_COLUMN_NAME]: item.latitude,
      [DEFAULT_LONGITUDE_COLUMN_NAME]: item.longitude
    }))
  }
}
