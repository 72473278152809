import { Routes } from '@/router/constants/routes'
import { AuthPermission } from '@/types/auth/permissions'
import { i18n } from '@/locales/i18n'
import { UniqueRouteConfig } from '@/router/types'
const AnalyticsDashboard = () => import('@/views/analytics/children/analytics-dashboard/Analytics-dashboard.vue')
const AnalyticsDetails = () => import('@/views/analytics/children/analytics-details/Analytics-details.vue')

export const analyticsRoutes: Array<UniqueRouteConfig> = [
  {
    path: '',
    name: Routes.ANALYTICS_DASHBOARD,
    component: AnalyticsDashboard,
    props: true,
    meta: {
      routeId: Routes.ANALYTICS_DASHBOARD,
      get name () { return i18n.t('common.labels.analytics') },
      can: AuthPermission.ViewDataset
    }
  },
  {
    path: ':id',
    name: Routes.ANALYTICS_DETAILS,
    component: AnalyticsDetails,
    props: true,
    meta: {
      routeId: Routes.ANALYTICS_DETAILS,
      can: AuthPermission.ViewDataset
    }
  }
]
