export enum AuthPermission {
  // Common
  ViewHomepage = 'view-homepage',

  // Planning
  CreatePlanning = 'create-planning',
  ViewPlanning = 'view-planning',
  EditPlanning = 'edit-planning',
  DeletePlanning = 'delete-planning',

  // Visualization
  CreateVisualization = 'create-visualization',
  ViewVisualization = 'view-visualization',
  EditVisualization = 'edit-visualization',
  DeleteVisualization = 'delete-visualization',

  // Orders
  CreateOrders = 'create-orders',
  ViewOrders = 'view-orders',
  EditOrders = 'edit-orders',
  DeleteOrders = 'delete-orders',

  // Analytics
  CreateDataset = 'create-dataset',
  ViewDataset = 'view-dataset',
  EditDataset = 'edit-dataset',
}
