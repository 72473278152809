import { camelCase, mapKeys } from 'lodash'
import { BookingTimeFrameDTO } from '@/types/api'
import { BookingTimeFrame } from '@/types/planning/booking-time'
import moment from '@/lib/moment'

export function parseTimeFrame (dto: BookingTimeFrameDTO): BookingTimeFrame {
  // Keys sometimes are capitalized and sometimes are not...
  const transformedDto = mapKeys(dto, (_value, key) => camelCase(key)) as unknown as BookingTimeFrameDTO

  return {
    type: transformedDto.bookingTimeFrameType,
    value: transformedDto.bookingTimeFrameIdentifier,
    text: transformedDto.displayString,
    year: +transformedDto.businessYear,
    numberOfYear: transformedDto.numberOfYear,
    start: moment.utc(transformedDto.start).format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
    end: moment.utc(transformedDto.end).subtract({ ms: 1 }).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
  }
}

export function fixEndDateInTimeFrame (endDate: Date): Date {
  return moment(endDate).add({ ms: 1 }).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
}
