











import { Component, Vue } from 'vue-property-decorator'
import { GEOCODING_DETAILS_MODAL_OPEN } from '../../../constants'

  @Component
export default class GeocodingDetailsBtn extends Vue {
  private showDetailsModal (): void {
    this.$root.$emit(GEOCODING_DETAILS_MODAL_OPEN)
  }
}
