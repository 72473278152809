import { parseDatasetRows } from '@/utils/parse-dataset-rows'
import { createTransformResponse } from '@/api/utils'
import { Dataset } from '@/types/common'
import { AxiosResponse } from 'axios'

export const parseImportDatasetResponse = createTransformResponse<Dataset>((response: AxiosResponse['data']): Dataset => {
  const parsedResponse = JSON.parse(response)

  parsedResponse.datasetRows = parsedResponse.rows
  delete parsedResponse.rows

  return parseDatasetRows(parsedResponse)
})
