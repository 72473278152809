import { DirectiveOptions, VNode } from 'vue'

interface AutocompleteElement extends HTMLElement {
  _updateAutoCompletePosition: () => void;
  _showAutoComplete: () => void;
  _hideAutoComplete: () => void;
}

function getInput (element: HTMLElement): HTMLInputElement | null {
  return element.querySelector('.ti-new-tag-input')
}

const tagsAutocompleteDirective: DirectiveOptions = {
  bind (el, _binding, vnode: VNode) {
    const elem = el as AutocompleteElement

    elem._updateAutoCompletePosition = () => {
      vnode.componentInstance?.$nextTick(() => {
        const autocomplete: HTMLDivElement | null = el.querySelector('.ti-autocomplete')

        if (!autocomplete) {
          return
        }

        const rect = elem.getBoundingClientRect()

        autocomplete.style.position = 'fixed'
        autocomplete.style.top = `${rect.bottom}px`
        autocomplete.style.width = `${rect.width}px`
      })
    }

    elem._showAutoComplete = () => {
      getInput(elem)?.focus()
    }

    elem._hideAutoComplete = () => {
      getInput(elem)?.blur();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (vnode.componentInstance as any).focused = false
    }

    vnode.componentInstance?.$watch('autocompleteOpen', (value: boolean) => {
      if (value) {
        elem._updateAutoCompletePosition()
      }
    }, { immediate: true })

    elem.addEventListener('mouseenter', elem._showAutoComplete)
    elem.addEventListener('mouseleave', elem._hideAutoComplete)

    window.addEventListener('scroll', elem._updateAutoCompletePosition, {
      passive: true,
      capture: true
    })
  },
  // update (el) {
  //   const elem = el as AutocompleteElement
  //
  //   elem._updateAutoCompletePosition()
  // },
  unbind (el) {
    const elem = el as AutocompleteElement

    elem.removeEventListener('mouseenter', elem._showAutoComplete)
    elem.removeEventListener('mouseleave', elem._hideAutoComplete)

    window.removeEventListener('scroll', elem._updateAutoCompletePosition)
  }
}

export default tagsAutocompleteDirective
