import {
  CAMPAIGN_ENABLED_FILTERS,
  CAMPAIGN_FILTERS,
  CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG,
  PLAN_WIZARD_FILTERS,
  PLAN_WIZARD_FORCE_CONTINUOUS_DISPLAY_FLAG,
  SCORING_CONTINUOUS_DISPLAY_UPDATED_IN_WIZARD,
  SCORING_NODES_WILL_BE_UPDATED,
  SCORING_NODES_WERE_UPDATED,
  SCORING_WILL_BE_UPDATED,
  SCORING_IS_INVALIDATED,
  SCORING_CONSIDER_STATUS_UNKNOWN_UPDATED_IN_WIZARD,
  PLAN_WIZARD_CONSIDER_STATUS_UNKNOWN_FLAG,
  CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG,
  SCORING_TIME_FRAME_UPDATED_IN_WIZARD,
  SCORING_TIME_FRAME_UPDATED_IN_CAMPAIGN,
  PLAN_WIZARD_START_DATE,
  PLAN_WIZARD_END_DATE,
  CAMPAIGN_TIME_FRAME,
  SCORING_META_DATA_UPDATED,
  CAMPAIGN_FLIGHTS_DISABLED,
  SCORING_AVAILABILITY_DISABLED_FLAG_UPDATED
} from '@/store/getter-types'
import {
  RESET_APPLIED_SCORING_BODY,
  SET_APPLIED_SCORING_BODY
} from '@/store/action-types'
import {
  SET_TIME_FRAME,
  SET_APPLIED_SCORING_FILTERS,
  SET_APPLIED_FORCE_CONINUOUS_DISPLAY,
  SET_APPLIED_CONSIDER_STATUS_UNKNOWN,
  SET_APPLIED_AVAILABILITY_FLAG
} from '@/store/mutation-types'
import { willCampaignNodesProduceDifferentScore } from '@/utils/scoring/nodes'
import moment from '@/lib/moment'
import { AppliedScoringCache, ScoringRequestPayload } from '@/types/store/scoring'
import { PlanningTimeFrame } from '@/types/planning'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'

// initial state
const state: AppliedScoringCache = {
  flightsDisabled: null,
  timeFrame: null,
  appliedFilters: [],
  forceContinuousDisplay: null,
  considerUnknownAvailability: null
}

// getters
const getters = {
  [SCORING_AVAILABILITY_DISABLED_FLAG_UPDATED] (state: AppliedScoringCache, getters) {
    return state.flightsDisabled !== getters[CAMPAIGN_FLIGHTS_DISABLED]
  },
  [SCORING_TIME_FRAME_UPDATED_IN_WIZARD] (state: AppliedScoringCache, getters) {
    return !moment(state.timeFrame?.start).isSame(getters[PLAN_WIZARD_START_DATE]) || !moment(state.timeFrame?.end).isSame(getters[PLAN_WIZARD_END_DATE])
  },
  [SCORING_TIME_FRAME_UPDATED_IN_CAMPAIGN] (state: AppliedScoringCache, getters) {
    const campaignTimeFrame = getters[CAMPAIGN_TIME_FRAME]
    return !moment(state.timeFrame?.start).isSame(campaignTimeFrame.start) || !moment(state.timeFrame?.end).isSame(campaignTimeFrame.end)
  },
  [SCORING_CONTINUOUS_DISPLAY_UPDATED_IN_WIZARD] (state: AppliedScoringCache, getters) {
    return state.forceContinuousDisplay !== getters[PLAN_WIZARD_FORCE_CONTINUOUS_DISPLAY_FLAG]
  },
  [SCORING_NODES_WILL_BE_UPDATED] (_state: AppliedScoringCache, getters) {
    return willCampaignNodesProduceDifferentScore(getters[CAMPAIGN_FILTERS], getters[PLAN_WIZARD_FILTERS])
  },
  [SCORING_NODES_WERE_UPDATED] (state: AppliedScoringCache, getters) {
    return willCampaignNodesProduceDifferentScore(getters[CAMPAIGN_ENABLED_FILTERS], state.appliedFilters)
  },
  [SCORING_CONSIDER_STATUS_UNKNOWN_UPDATED_IN_WIZARD] (state: AppliedScoringCache, getters) {
    return state.considerUnknownAvailability !== getters[PLAN_WIZARD_CONSIDER_STATUS_UNKNOWN_FLAG]
  },
  [SCORING_WILL_BE_UPDATED] (_state: AppliedScoringCache, getters) {
    return getters[SCORING_META_DATA_UPDATED] ||
      getters[SCORING_NODES_WILL_BE_UPDATED] ||
      getters[SCORING_TIME_FRAME_UPDATED_IN_WIZARD]
  },
  [SCORING_IS_INVALIDATED] (_state: AppliedScoringCache, getters) {
    return getters[SCORING_AVAILABILITY_DISABLED_FLAG_UPDATED] ||
      getters[SCORING_META_DATA_UPDATED] ||
      getters[SCORING_NODES_WERE_UPDATED] ||
      getters[SCORING_TIME_FRAME_UPDATED_IN_CAMPAIGN]
  },
  [SCORING_META_DATA_UPDATED] (state: AppliedScoringCache, getters) {
    return state.forceContinuousDisplay !== getters[CAMPAIGN_FORCE_CONTINUOUS_DISPLAY_FLAG] ||
      state.considerUnknownAvailability !== getters[CAMPAIGN_CONSIDER_STATUS_UNKNOWN_FLAG]
  }
}

// actions
const actions = {
  async [SET_APPLIED_SCORING_BODY] ({ commit }, { campaign, availability }: ScoringRequestPayload) {
    commit(SET_TIME_FRAME, campaign.metaData.planningTimeFrames)
    commit(SET_APPLIED_SCORING_FILTERS, campaign.nodes)
    commit(SET_APPLIED_FORCE_CONINUOUS_DISPLAY, campaign.metaData.forceContinuousDisplay)
    commit(SET_APPLIED_CONSIDER_STATUS_UNKNOWN, campaign.metaData.considerUnknownAvailability)
    commit(SET_APPLIED_AVAILABILITY_FLAG, !availability)
  },
  async [RESET_APPLIED_SCORING_BODY] ({ commit }) {
    commit(SET_TIME_FRAME, null)
    commit(SET_APPLIED_SCORING_FILTERS, [])
    commit(SET_APPLIED_FORCE_CONINUOUS_DISPLAY, null)
    commit(SET_APPLIED_CONSIDER_STATUS_UNKNOWN, null)
    commit(SET_APPLIED_AVAILABILITY_FLAG, null)
  }
}

// mutations
const mutations = {
  [SET_TIME_FRAME] (state: AppliedScoringCache, timeFrame: PlanningTimeFrame[] | null) {
    if (Array.isArray(timeFrame)) {
      const { start, end } = timeFrame[0]
      state.timeFrame = { start, end }
    } else {
      state.timeFrame = timeFrame
    }
  },
  [SET_APPLIED_SCORING_FILTERS] (state: AppliedScoringCache, filters: ScoringFunctionNode[]) {
    state.appliedFilters = filters
  },
  [SET_APPLIED_FORCE_CONINUOUS_DISPLAY] (state: AppliedScoringCache, flag: boolean) {
    state.forceContinuousDisplay = flag
  },
  [SET_APPLIED_CONSIDER_STATUS_UNKNOWN] (state: AppliedScoringCache, flag: boolean) {
    state.considerUnknownAvailability = flag
  },
  [SET_APPLIED_AVAILABILITY_FLAG] (state: AppliedScoringCache, flag: boolean) {
    state.flightsDisabled = flag
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
