import { DataColumn, Dataset } from '@/types/common'
import {
  pointListFieldsSettings,
  PointsFields
} from '@/components/planning/wizard/filter-editor/ui-controls/points-list-ui-element/points-settings'
import { DatasetConfig } from '@/utils/filter-editor/value-config/dataset/dataset-config'

const columnTypeByField = {
  [PointsFields.Name]: 'text',
  [PointsFields.Latitude]: 'number',
  [PointsFields.Longitude]: 'number'
}

export class PointsConfig extends DatasetConfig {
  override formatRows (values: Dataset): string {
    return values.datasetRows.map(item => item.name).join(', ')
  }

  protected override getDefaultValue (): Dataset {
    const columnInfos: DataColumn[] = Object.keys(pointListFieldsSettings)
      .map(field => ({ name: field, type: columnTypeByField[field] }))

    return super.getDefaultValue() ?? {
      columnInfos,
      datasetRows: []
    }
  }
}
