import {
  NEW_VISUALIZATION_WIZARD_RESET,
  NEW_VISUALIZATION_WIZARD_SHOW,
  NEW_VISUALIZATION_WIZARD_CREATE,
  NEW_VISUALIZATION_WIZARD_CREATE_CUSTOM,
  NEW_VISUALIZATION_WIZARD_CREATE_FROM_PLANS,
  NEW_CUSTOM_VISUALIZATION,
  CREATE_VISUALIZATION_FROM_PLANNING
} from '@/store/action-types'

import {
  NEW_VISUALIZATION_WIZARD_SET_PLANS,
  NEW_VISUALIZATION_WIZARD_SET_NAME,
  NEW_VISUALIZATION_WIZARD_SET_VISIBLE,
  NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT
} from '@/store/mutation-types'
import {
  NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_CUSTOM_VISUALIZATION,
  NEW_VISUALIZATION_WIZARD_NAME,
  NEW_VISUALIZATION_WIZARD_PLANS
} from '@/store/getter-types'

export default {
  [NEW_VISUALIZATION_WIZARD_RESET] ({ commit }) {
    commit(NEW_VISUALIZATION_WIZARD_SET_ENTRYPOINT, null)
    commit(NEW_VISUALIZATION_WIZARD_SET_PLANS, [])
    commit(NEW_VISUALIZATION_WIZARD_SET_NAME, null)
  },
  [NEW_VISUALIZATION_WIZARD_SHOW] ({ commit }) {
    commit(NEW_VISUALIZATION_WIZARD_SET_VISIBLE, true)
  },
  async [NEW_VISUALIZATION_WIZARD_CREATE] ({ getters, dispatch }) {
    if (getters[NEW_VISUALIZATION_WIZARD_ENTRYPOINT_IS_CUSTOM_VISUALIZATION]) {
      return dispatch(NEW_VISUALIZATION_WIZARD_CREATE_CUSTOM)
    }

    return dispatch(NEW_VISUALIZATION_WIZARD_CREATE_FROM_PLANS)
  },
  async [NEW_VISUALIZATION_WIZARD_CREATE_CUSTOM] ({ getters, dispatch }) {
    const name = getters[NEW_VISUALIZATION_WIZARD_NAME]
    return dispatch(NEW_CUSTOM_VISUALIZATION, name)
  },
  async [NEW_VISUALIZATION_WIZARD_CREATE_FROM_PLANS] ({ getters, dispatch }) {
    const name = getters[NEW_VISUALIZATION_WIZARD_NAME]
    const plans = getters[NEW_VISUALIZATION_WIZARD_PLANS]

    return dispatch(CREATE_VISUALIZATION_FROM_PLANNING, { plans, name })
  }
}
