import { clamp, throttle } from 'lodash'
import { Vue, Component, Ref } from 'vue-property-decorator'

@Component
export class SliderDraggableMixin extends Vue {
  @Ref() readonly container!: Element

  protected $throttledUpdateSliderPos!: (e: MouseEvent | TouchEvent) => void

  created (): void {
    this.$throttledUpdateSliderPos = throttle(this.updateSliderPos, 20, {
      leading: true,
      trailing: false
    })
  }

  protected getSliderContainer (): Element | undefined {
    return this.container ? this.container : undefined
  }

  protected updateSliderPos (e: MouseEvent | TouchEvent): void {
    const container = this.getSliderContainer()
    if (container) {
      const containerWidth = container.clientWidth
      const containerHeight = container.clientHeight

      const xOffset = container.getBoundingClientRect().left + window.pageXOffset
      const yOffset = container.getBoundingClientRect().top + window.pageYOffset

      const pageX = (e as MouseEvent).pageX || ('touches' in e && e.touches ? e.touches[0].pageX : 0)
      const pageY = (e as MouseEvent).pageY || ('touches' in e && e.touches ? e.touches[0].pageY : 0)
      const x = clamp((pageX - xOffset) / containerWidth, 0, 1)
      const y = clamp((pageY - yOffset) / containerHeight, 0, 1)

      this.emitSliderChange(x, y)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected emitSliderChange (_x: number, _y: number): void {
    // Placeholder, to be overridden inside a component.
  }

  protected handleSliderMove (e: MouseEvent): void {
    e.preventDefault()
    this.$throttledUpdateSliderPos(e)
  }

  protected handleSliderMouseDown (e: MouseEvent): void {
    this.updateSliderPos(e)
    window.addEventListener('mouseup', this.handleSliderMouseUp)
    window.addEventListener('mousemove', this.handleSliderMove)
  }

  protected handleSliderMouseUp (): void {
    this.unbindSliderEventListeners()
  }

  protected unbindSliderEventListeners (): void {
    window.removeEventListener('mousemove', this.handleSliderMove)
    window.removeEventListener('mouseup', this.handleSliderMouseUp)
  }
}
