import { CancelTokenSource } from 'axios'
import { ApiRequestConfig } from '@/api/types'
import { ApiClient } from '@/api/api-client'
import { GeocodingDetails } from '@/components/shared/coordinates-service/types'
import { GeocodingConfidence } from '@/components/shared/coordinates-service/types/enum'

class GeocodingApi extends ApiClient {
  geocode (address: string[], cancelToken: CancelTokenSource): Promise<GeocodingDetails[]> {
    const config: ApiRequestConfig = {
      toastOnError: false,
      params: { q: address },
      cancelToken: cancelToken.token
    }

    return this.get('querycoords/ab8ba1b4-361c-4f89-a1ae-8118472bdfb4', config)
      .then(data => {
        const confidence = data.confidence.toLowerCase()
        if ([GeocodingConfidence.Low.toLowerCase(), GeocodingConfidence.Bad.toLocaleLowerCase(), GeocodingConfidence.Ambigous.toLowerCase()].includes(confidence)) {
          return Promise.reject(data)
        }

        return data
      })
  }
}

export const geocodingApi = new GeocodingApi({
  baseURL: API_CONF.api + '/api/geocoding'
})
