









import { WIZARD_NAVIGATION_ACTIVE_INDEX } from '@/store/getter-types'
import { DynamicStoreMixin } from '@/mixins/dynamic-store.mixin'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { WizardIndex } from '@/types/wizard'
import { VueClassDef } from '@/types/vue'

@Component
export default class WizardTab extends Mixins<DynamicStoreMixin>(DynamicStoreMixin) {
  @Prop({ required: true }) private readonly tabIndex!: string | number

  private get activeStep (): WizardIndex {
    return this.dynamicStoreGetter<WizardIndex>(WIZARD_NAVIGATION_ACTIVE_INDEX)
  }

  private get isActive (): boolean {
    return this.tabIndex === this.activeStep
  }

  private get contentClass (): VueClassDef {
    return {
      active: this.isActive
    }
  }
}
