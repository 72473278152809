

































import { cloneDeep } from 'lodash'
import { getMainLayerFillOptions } from '@/utils/visualization'
import LayerLegend from '../../legend/layer-legend.vue'
import LayerLegendEditor from './layer-legend-editor/layer-legend-editor.vue'
import { formatLegendValue, sortLegendValues, formatLegendValues } from '../utils'
import { LAYER_EDITOR_COMPONENTS } from './layer-legend-editor/constants'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Node } from 'prosemirror-model'
import { NodeAttributes, NodeOptions } from '@/components/shared/tiptap/types'
import { VueComponentMap } from '@/types/vue'
import { LegendValues } from '@/types/visualization/map-legend'
import { Editor } from 'tiptap'

@Component({
  components: { LayerLegendEditor, LayerLegend }
})
export default class LayerLegendView extends Vue {
  @Prop({ required: true }) private readonly editor!: Editor
  @Prop({ required: true }) private readonly options!: NodeOptions
  @Prop({ required: true }) private readonly node!: Node
  @Prop({ required: true }) private readonly updateAttrs!: (attrs: NodeAttributes) => void

  private showEditor = false
  private editorComponents: VueComponentMap = cloneDeep(LAYER_EDITOR_COMPONENTS)

  private get isEditorAvailable (): boolean {
    const layerFill = getMainLayerFillOptions(this.options.layer)
    return this.editor.view.editable && (layerFill.type in this.editorComponents)
  }

  private sortValues (legendValues): LegendValues {
    return sortLegendValues(legendValues, this.node.attrs.sortOrder)
  }

  private formatValue (value): number {
    return formatLegendValue<number>(value, this.node.attrs.maxDecimals)
  }

  private formatLegendValues (legendValues): LegendValues {
    return formatLegendValues(legendValues, this.node.attrs.legendValues)
  }
}
