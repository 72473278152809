import { TagsByCategoryGetter, AnalyticsTagsState } from '@/modules/analytics/analytics-tags-editor/_store/analytics-tags-cache/types'
import { uniq } from 'lodash'

export const GET_TAGS_CATEGORIES = 'tags/getters/categories'
export const GET_TAGS_BY_CATEGORY = 'tags/getters/getByCategory'

export const getters = {
  [GET_TAGS_CATEGORIES] (state: AnalyticsTagsState): string[] {
    if (state.tags === null) {
      return []
    }

    const nonUniqueCategories = state.tags.map(tag => tag.category)
    return uniq(nonUniqueCategories)
  },
  [GET_TAGS_BY_CATEGORY] (state: AnalyticsTagsState): TagsByCategoryGetter {
    return (category: string) => {
      if (state.tags === null) {
        return []
      }

      return state.tags.filter(tag => tag.category === category)
    }
  }
}
