import { i18n } from '@/locales/i18n'
import {
  PLAN_WIZARD_LINK_NAMESPACE,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID,
  WIZARD_STEP_LOCATION_ALL_LOCATIONS_VALID,
  WIZARD_STEP_LOCATION_ALL_NAMES_UNIQUE,
  WIZARD_STEP_LOCATION_HAS_LOCATIONS,
  WIZARD_TAB_LOCATION_ERRORS,
  WIZARD_TAB_LOCATION_IS_EMPTY,
  WIZARD_TAB_LOCATION_IS_ENABLED,
  WIZARD_TAB_LOCATION_IS_INVALID
} from '@/store/getter-types'
import {
  LOAD_SITE_TYPES,
  PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK,
  PLAN_WIZARD_RELOAD_NETWORKS,
  WIZARD_TAB_LOCATION_LEAVE,
  WIZARD_TAB_LOCATION_VALIDATE
} from '@/store/action-types'
import { WIZARD_STEP_LOCATION } from '@/components/planning/wizard/constants/steps'

// initial state
const state = {
  stepId: WIZARD_STEP_LOCATION
}

// getters
const getters = {
  [WIZARD_TAB_LOCATION_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[PLAN_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId)
  },
  [WIZARD_TAB_LOCATION_IS_EMPTY] (state, getters) {
    return !getters[WIZARD_STEP_LOCATION_HAS_LOCATIONS]
  },
  [WIZARD_TAB_LOCATION_IS_INVALID] (state, getters) {
    return getters[WIZARD_TAB_LOCATION_ERRORS].length > 0
  },
  [WIZARD_TAB_LOCATION_ERRORS] (state, getters) {
    const errors = []

    if (!getters[WIZARD_STEP_LOCATION_HAS_LOCATIONS]) {
      errors.push(i18n.t('planning.wizard.errors.areas-missing'))
    }

    if (!getters[WIZARD_STEP_LOCATION_ALL_LOCATIONS_VALID]) {
      errors.push(i18n.t('planning.wizard.errors.areas-valid'))
    }

    if (!getters[WIZARD_STEP_LOCATION_ALL_NAMES_UNIQUE]) {
      errors.push(i18n.t('planning.wizard.errors.areas-unique'))
    }
    return errors
  }
}

// actions
const actions = {
  [WIZARD_TAB_LOCATION_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[WIZARD_TAB_LOCATION_ERRORS])
  },
  async [WIZARD_TAB_LOCATION_LEAVE] ({ dispatch, state }) {
    const canLeave = await dispatch(PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK, state.stepId)

    if (!canLeave) {
      return false
    }

    await Promise.all([dispatch(LOAD_SITE_TYPES), dispatch(PLAN_WIZARD_RELOAD_NETWORKS)])
    return true
  }
}

// mutations
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
