import { CITY_DATASET, CITY_DATASET_INITIALIZED } from '@/store/getter-types'
import { SET_CITY_DATASET } from '@/store/mutation-types'
import { LOAD_CITY_DATASET } from '@/store/action-types'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import { orderBy } from 'lodash'
import { POPULATION_COLUMN } from '@/constants/unit/fields'
import { SortType } from '@/types/enums'
import { freezeRecursive } from '@/utils/object'
import { Dataset } from '@/types/common'

type CitiesDatasetState = {
  dataset: Dataset | null
}

const state: CitiesDatasetState = {
  dataset: null
}

// getters
const getters = {
  [CITY_DATASET]  (state: CitiesDatasetState): Dataset | null {
    return state.dataset
  },
  [CITY_DATASET_INITIALIZED] (state): boolean {
    return state.dataset != null
  }
}

// actions
const actions = {
  [LOAD_CITY_DATASET] ({ commit, getters }): Promise<Dataset> {
    if (getters[CITY_DATASET_INITIALIZED]) {
      return Promise.resolve(getters[CITY_DATASET])
    }

    return dataDeliveryApi.getCitiesDataset()
      .then((dataset: Dataset) => {
        dataset.datasetRows = orderBy(dataset.datasetRows, row => parseInt(row[POPULATION_COLUMN] as string), SortType.Desc)
        commit(SET_CITY_DATASET, freezeRecursive(dataset))

        return getters[CITY_DATASET]
      })
  }
}

// mutations
const mutations = {
  [SET_CITY_DATASET] (state: CitiesDatasetState, payload: Dataset) {
    state.dataset = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
