import { HatchingConfig } from '@/types/visualization/layer/style'

export enum HATCHING_TYPE {
  NONE = 'None',
  BACKWARD_DIAGONAL = 'Backward diagonal',
  HORIZONTAL = 'Horizontal',
  FORWARD_DIAGONAL = 'Forward diagonal',
  VERTICAL = 'Vertical'
}

export enum HATCHING_DENSITY{
  LOW = '54',
  MID = '36',
  HIGH = '18'
}

export enum HATCHING_LINE_WIDTH{
  THIN = '2',
  MEDIUM = '4',
  THICK = '6'
}

export const HATCHING: HatchingConfig = {
  type: HATCHING_TYPE.NONE,
  density: HATCHING_DENSITY.HIGH,
  lineColor: 'black',
  lineWidth: HATCHING_LINE_WIDTH.THIN,
  lineOpacity: '1'
}
