import { TableNodeTypes } from './types'
import { Schema, NodeType, Node } from 'prosemirror-model'

export const createCell = (cellType: NodeType, cellContent: Node): Node | null | undefined => {
  if (cellContent) {
    return cellType.createChecked(null, cellContent)
  }

  return cellType.createAndFill()
}

export const tableNodeTypes = (schema: Schema): TableNodeTypes => {
  if (schema.cached.tableNodeTypes) {
    return schema.cached.tableNodeTypes
  }

  const roles = {}
  Object.keys(schema.nodes).forEach(type => {
    const nodeType = schema.nodes[type]
    if (nodeType.spec.tableRole) {
      roles[nodeType.spec.tableRole] = nodeType
    }
  })

  schema.cached.tableNodeTypes = roles
  return roles
}
