























































import { PickerPropsMixin } from './mixin/picker-props.mixin'
import { _colorChange } from './mixin/picker.mixin'
import ColorPicker from './color-picker.vue'
import EditableInput from './common/editable-input.vue'
import { Component, Mixins, Prop, Ref, Emit } from 'vue-property-decorator'
import { BPopover } from 'bootstrap-vue'
import { ColorModel } from '@/types/color-data'

@Component({
  components: { ColorPicker, EditableInput }
})
export default class ColorPickerPopover extends Mixins(PickerPropsMixin) {
  @Prop({ required: true }) value!: string
  @Prop({ required: true }) target!: string | HTMLElement | SVGElement
  @Prop({ default: 'top' }) placement!: string
  @Prop({ default: 'click' }) triggers!: string | string[]

  @Ref() readonly popover!: BPopover

  private show = false
  private activeColor: ColorModel = {} as ColorModel
  private inputColor = ''

  created () {
    this.activeColor = _colorChange(this.value)
    this.inputColor = this.activeColor.hex
  }

  private get colorModel (): ColorModel {
    return this.activeColor
  }

  private set colorModel (value: ColorModel) {
    this.inputColor = value.hex

    if (this.inputValid) {
      this.activeColor = _colorChange(value)
    }
  }

  private changeColor (value: ColorModel) {
    this.colorModel = value
  }

  private get inputValid () {
    return /^(#)([0-9A-F]{6})$/i.test(this.inputColor)
  }

  private openPopover (): void {
    this.popover.$emit('open')
  }

  private saveColor (): void {
    this.$emit('input', this.activeColor)
    this.closePopover()
  }

  @Emit('close')
  private closePopover (): void {
    this.popover.$emit('close')
  }
}
