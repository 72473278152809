import { Store } from '@/store/store'
import { ADD_CANCEL_CALLBACK, REMOVE_CANCEL_CALLBACK } from '@/store/mutation-types'
import { InterceptorsConfig } from './types'
import axios from 'axios'
import { ApiRequestConfig } from '@/api/types'

const generateRequestId = (config: ApiRequestConfig): string => {
  if ('requestId' in config) {
    return config.requestId as string
  }

  const { baseURL, url } = config
  return baseURL as string + url as string
}

export const cancelRequestInterceptors: InterceptorsConfig = {
  request: {
    onSuccess: function (config) {
      if (!config.cancelToken) {
        const cancelToken = axios.CancelToken.source()
        const requestId = generateRequestId(config)
        config.cancelToken = cancelToken.token
        Store.commit(ADD_CANCEL_CALLBACK, { [requestId]: cancelToken.cancel })
      }

      return config
    }
  },
  response: {
    onSuccess: function (response) {
      const requestId = generateRequestId(response.config)

      Store.commit(REMOVE_CANCEL_CALLBACK, requestId)

      return response
    },
    onReject: function (error) {
      let requestId: string | null = null

      if (axios.isCancel(error) || !error.config) {
        requestId = error.message
      } else {
        requestId = generateRequestId(error.config)
      }

      Store.commit(REMOVE_CANCEL_CALLBACK, requestId)

      return Promise.reject(error)
    }
  }
}
