import queryDatasetTab from './tabs/vicinity-import-wizard-tab-query-dataset.store'
import datasetTab from './tabs/vicinity-import-wizard-tab-dataset.store'
import parseDatasetTab from './tabs/vicinity-import-wizard-tab-parse-dataset.store'

// initial state
const state = {}

// getters
const getters = {}

// mutations
const mutations = {}

// actions
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    datasetTab,
    queryDatasetTab,
    parseDatasetTab
  }
}
