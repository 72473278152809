import { Color } from 'csstype'

// Thanks to https://stackoverflow.com/a/20129594
function selectColor (index: number): Color {
  const hue = index * 137.508 // use golden angle approximation
  return `hsl(${hue - 360 * Math.floor(hue / 360)},80%,60%)`
}

function createPalette (size: number): Color[] {
  const palette = new Array<Color>(size)

  for (let i = size - 1; i >= 0; i--) {
    palette[i] = selectColor(i)
  }

  return palette
}

export const colorsPaletteSize = 500
export const colorsPalette: Color[] = createPalette(colorsPaletteSize)

export function * paletteColors (): Generator<Color> {
  let index = 0

  while (true) {
    yield colorsPalette[index % colorsPaletteSize]
    index++
  }
}
