import {
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_ERRORS,
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_EMPTY,
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_ENABLED,
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_CAN_SKIP,
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_INVALID,
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_STEP_ID
} from '@/store/getter-types'
import {
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_VALIDATE,
  VICINITY_IMPORT_WIZARD_POINTS_VALIDATE,
  VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_INVALIDATE,
  VICINITY_IMPORT_WIZARD_POINTS_RESET
} from '@/store/action-types'
import { VICINITY_IMPORT_PARSE } from '@/components/planning/vicinity-import-wizard/constants/steps'

// initial state
const state = {
  stepId: VICINITY_IMPORT_PARSE
}

// getters
const getters = {
  [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_ENABLED] () {
    return true
  },
  [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_EMPTY] () {
    return false
  },
  [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_CAN_SKIP] () {
    return false
  },
  [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_IS_INVALID] (state, getters) {
    return getters[VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_ERRORS].length > 0
  },
  [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_ERRORS] () {
    return []
  },
  [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  async [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_VALIDATE] ({ dispatch, getters }) {
    const errors = getters[VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_ERRORS]
    return errors.concat(await dispatch(VICINITY_IMPORT_WIZARD_POINTS_VALIDATE))
  },
  [VICINITY_IMPORT_WIZARD_TAB_PARSE_DATASET_INVALIDATE] ({ dispatch }) {
    return dispatch(VICINITY_IMPORT_WIZARD_POINTS_RESET)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
