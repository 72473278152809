import { DomUtil, LatLng, Layer, Util, Map } from 'leaflet'
import heatmap from 'heatmapjs'
import { LatLngData } from '@/components/shared/heat-map/types/lat-lng-data/lat-lng-data'
import { PropsDefinition } from 'vue/types/options'
import { BaseHeatmapConfiguration, DataPoint, HeatmapData } from '@/types/heatmapjs'
import { heatmap as HeatMap } from '@/components/shared/heat-map/types/heatmap/heatmap'

const BASE_CALCULATE_FIELD = 'count'
type HeatMapConfig = BaseHeatmapConfiguration<typeof BASE_CALCULATE_FIELD>
type HeatMapDataPoint = DataPoint<'radius'>

export const HeatmapOverlay = Layer.extend({
  options: {
    latLng: [],
    maxOpacity: 0.5,
    radius: 0.002,
    scaleRadius: true,
    useLocalExtrema: true,
    latField: 'lat',
    lngField: 'lng',
    valueField: BASE_CALCULATE_FIELD,
    zIndex: 1
  } as HeatMapConfig,

  initialize: function (options: HeatMapConfig) {
    this._el = DomUtil.create('div', 'leaflet-zoom-hide')
    this._data = [] as LatLngData[]
    this._max = 1
    this._min = 0
    this.options.container = this._el

    Util.setOptions(this, options)
    this.options.radius /= 500
  },

  onAdd: function (map: Map) {
    const size = map.getSize()

    this._map = map

    this._width = size.x
    this._height = size.y

    this._el.style.width = size.x + 'px'
    this._el.style.height = size.y + 'px'
    this._el.style.position = 'absolute'

    this.getPane().appendChild(this._el)

    if (!this._heatmap) {
      this._heatmap = heatmap.create(this.options)
    }

    map.on('moveend', this._reset, this)
    this._draw()
  },

  addTo: function (map: Map) {
    map.addLayer(this)
    return this
  },

  onRemove: function (map: Map) {
    map.getPanes().overlayPane.removeChild(this._el)
    map.off('moveend', this._reset, this)
  },

  _draw: function () {
    if (!this._map) { return }

    const mapPane = this._map.getPanes().mapPane
    const point = mapPane._leaflet_pos

    DomUtil.setTransform(this._el, point.multiplyBy(-1))

    // Fix to allow multiple heatmap layers to be displayed on the map at once
    this._el.style.position = 'absolute'

    this._update()
  },

  _setRadius (newRadius: string) {
    this.options.radius = parseInt(newRadius) / 500
    this._update()
  },

  _setMaxOpacity (newOpacity: string) {
    this.options.maxOpacity = newOpacity
    this._heatmap.configure(this.options)
  },

  _setZIndex (newZIndex: string) {
    this.options.zIndex = newZIndex
    this._heatmap.configure(this.options)
  },

  _update: function () {
    const generatedData = { max: this._max, min: this._min, data: [] as HeatMapDataPoint[] }

    const bounds = this._map.getBounds()
    const zoom = this._map.getZoom()
    const scale = Math.pow(2, zoom)

    if (this._data.length === 0) {
      if (this._heatmap) {
        this._heatmap.setData(generatedData)
      }
      return
    }

    const latLngPoints: HeatMapDataPoint[] = []
    const radiusMultiplier = this.options.scaleRadius ? scale : 1
    let localMax = 0
    let localMin = 0
    const valueField = this.options.valueField
    let len = this._data.length

    while (len--) {
      const entry = this._data[len]
      const value = entry[valueField]
      const latlng = entry.latlng

      if (!bounds.contains(latlng)) {
        continue
      }

      localMax = Math.max(value, localMax)
      localMin = Math.min(value, localMin)

      const point = this._map.latLngToContainerPoint(latlng)
      const latLngPoint: HeatMapDataPoint = { x: Math.round(point.x), y: Math.round(point.y), radius: 0 }
      latLngPoint[valueField] = value

      let radius

      if (entry.radius) {
        radius = entry.radius * radiusMultiplier
      } else {
        radius = (this.options.radius || 2) * radiusMultiplier
      }

      latLngPoint.radius = radius
      latLngPoints.push(latLngPoint)
    }

    if (this.options.useLocalExtrema) {
      generatedData.max = localMax
      generatedData.min = localMin
    }

    generatedData.data = latLngPoints

    this._heatmap.setData(generatedData)
  },

  setData: function (newData: HeatmapData<LatLngData>) {
    this._max = Math.max(...newData.data.map(item => item.count)) || this._max
    this._min = Math.min(...newData.data.map(item => item.count)) || this._min
    const latField = this.options.latField || 'lat'
    const lngField = this.options.lngField || 'lng'
    const valueField = this.options.valueField || 'value'

    const data = newData.data || []
    let len = data.length
    const d = [] as Array<{ latlng: LatLng }>

    while (len--) {
      const entry = data[len]
      const latlng = new LatLng(entry[latField], entry[lngField])
      const dataObj = { latlng }
      dataObj[valueField] = entry[valueField]

      d.push(dataObj)
    }

    this._data = d

    this._draw()
  },

  _reset: function () {
    const size = this._map.getSize()

    if (this._width !== size.x || this._height !== size.y) {
      this._width = size.x
      this._height = size.y

      this._el.style.width = this._width + 'px'
      this._el.style.height = this._height + 'px'

      this._heatmap._renderer.setDimensions(this._width, this._height)
    }
    this._draw()
  }
}) as new (props: PropsDefinition<unknown>) => HeatMap
