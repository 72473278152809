























import { getMixedPropsFromSelf } from '@/utils/component'
import LegendValuesTable from './legend-values-table.vue'
import LegendDetailsModal from './legend-details-modal.vue'
import { MAX_VISIBLE_LEGEND_VALUES } from '../constants'
import { LegendValuesTableMixin } from './legend-values-table.mixin'
import { Component } from 'vue-property-decorator'
import { VueInstanceProps } from '@/types/vue'
import { LegendValues } from '@/types/visualization/map-legend'

@Component({
  components: { LegendDetailsModal, LegendValuesTable }
})
export default class LegendView extends LegendValuesTableMixin {
  private showAllValuesModal = false

  private get canShowMore (): boolean {
    return this.legendValues.length > MAX_VISIBLE_LEGEND_VALUES ||
      this.legendValues.some(item => item.allLabels && item.allLabels.length !== 1)
  }

  private get visibleValues (): LegendValues {
    return this.legendValues.slice(0, MAX_VISIBLE_LEGEND_VALUES)
  }

  private get tableProps (): VueInstanceProps {
    return {
      ...getMixedPropsFromSelf(this, LegendValuesTableMixin),
      legendValues: this.visibleValues
    }
  }
}
