import { pick } from 'lodash'
import { FillType, PathStyle } from '@/types/visualization/layer/enums'
import { PATH_STYLE_CONFIGS, PathModes } from './path'
import { DEFAULT_VIS_LINE_WIDTH, DEFAULT_VIS_OPACITY } from './visualization-defaults'

export type LineStyleModes = Partial<PathModes>

export const LINE_STYLES: LineStyleModes = pick(PATH_STYLE_CONFIGS, [
  PathStyle.Solid,
  PathStyle.Dotted,
  PathStyle.Dashed
])

export const DEFAULT_LINE_WIDTH = DEFAULT_VIS_LINE_WIDTH
export const DEFAULT_LINE_FILL_MODE = FillType.Color
export const DEFAULT_LINE_STYLE = PathStyle.Solid
export const DEFAULT_LINE_COLOR = '#00A000'
export const DEFAULT_LINE_OPACITY = DEFAULT_VIS_OPACITY
