import tinycolor from 'tinycolor2'
import { Color } from 'csstype'
import { VisualizationLayer } from '@/types/visualization/layer'
import { isFillAwareStyle, isGeometryStyle } from '@/types/visualization/layer/guards'
import { paletteColors } from '@/utils/colors-palette'

const SCORING_LAYER_OPACITY = 0.4

export function setScoringLayersStyle (layers: VisualizationLayer[], oldLayers: VisualizationLayer[]): void {
  const colors = paletteColors()

  for (const layer of layers) {
    const fillColor: Color = colors.next().value
    const borderColor: Color = tinycolor(fillColor).darken(10).toHexString()

    const oldLayer = oldLayers
      .find(
        oldLayer => oldLayer.type === layer.type &&
        oldLayer.dataSource.scoringFunctionID === layer.dataSource.scoringFunctionID
      )

    if (oldLayer?.style) {
      layer.style = oldLayer.style
      continue
    }

    if (isGeometryStyle(layer.style)) {
      if (isFillAwareStyle(layer.style)) {
        layer.style.fill.color = fillColor
        layer.style.fill.opacity = SCORING_LAYER_OPACITY
      }

      layer.style.path.color = borderColor
      layer.style.path.opacity = SCORING_LAYER_OPACITY
    }
  }
}
