import { ApiResponse } from '@/api/types'
import { Order } from '@/types/orders'
import { parseOrder } from './utils'
import { isEmpty } from 'lodash'
import { createTransformResponse } from '@/api/utils'

export const transformOrderResponse = createTransformResponse<Order | ApiResponse['data']>((response: ApiResponse['data']): Order | ApiResponse['data'] => {
  if (isEmpty(response)) {
    return response
  }

  const dto = JSON.parse(response)

  return parseOrder(dto)
})
