import { createTransformResponse } from '@/api/utils'
import { AxiosResponse } from 'axios'
import { Dataset } from '@/types/common'
import { getDatasetTransformResponse } from '@/api/rest/data-delivery/transform-response/get-dataset-response.transform'
import { parseMONDataset } from '@/utils/planning/media-owner-network/media-owner-networks'

export const getMONDatasetTransformResponse = createTransformResponse<Dataset>((response: AxiosResponse['data']): Dataset => {
  const dataset = getDatasetTransformResponse(response)

  parseMONDataset(dataset)
  return dataset
})
