import { uniq, keys } from 'lodash'
import { DatasetLayerMixin } from './dataset-layer.mixin'
import { colorsPalette, colorsPaletteSize } from '@/utils/colors-palette'
import { Component } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { Color } from 'csstype'
import { SubLayerConfig } from '@/types/visualization/layer/style'
import { DatasetColumnKey } from '@/types/common'
import { LayerGeometry } from '@/types/visualization/layer/geometry'

type ClusteringUniqueValues = Record<string, number>

@Component
export default class ClusteringAwareMixin<TLayer extends BorderedGeometryLayer> extends DatasetLayerMixin<TLayer> {
  protected getClusteringDataRows (column: DatasetColumnKey | undefined, style: SubLayerConfig): LayerGeometry[] {
    if (column === undefined) {
      return []
    }

    const subLayers = style.subLayers ?? {}
    const hiddenSubLayers = new Set()

    Object.keys(subLayers).forEach(sublayer => {
      if (!(subLayers[sublayer].visible ?? true)) {
        hiddenSubLayers.add(sublayer)
      }
    })

    return this.dataRows.filter(row => !hiddenSubLayers.has(String(row.meta[column])))
  }

  protected getUniqueClusteringValues (column: DatasetColumnKey | undefined): ClusteringUniqueValues {
    if (!column) {
      return {}
    }

    const values = uniq(this.dataRows.map(item => {
      return (item.meta[column] ?? 'null').toString().trim()
    }))

    const quantifier = Math.floor(colorsPaletteSize / values.length) || 1
    const uniqueValues = {}

    values.forEach((value, index) => {
      uniqueValues[value] = ClusteringAwareMixin.calculateClusteringPaletteIndex(index, quantifier)
    })

    return uniqueValues
  }

  protected calculateClusteringColor (style: SubLayerConfig, clusterKey: DatasetColumnKey, uniqueValues: ClusteringUniqueValues): Color {
    const customClusters = style.subLayers ?? {}

    if (customClusters[clusterKey] && customClusters[clusterKey].color) {
      return customClusters[clusterKey].color as Color
    }

    return ClusteringAwareMixin.getClusteringColorFromPalette(clusterKey, uniqueValues)
  }

  protected calculateQuantityOfValuesForCluster (clusterValues: ClusteringUniqueValues, column: DatasetColumnKey | undefined): Record<string, number> {
    if (column === undefined) {
      return {}
    }

    const clusterKeys = keys(clusterValues)
    const quantityOfValues = {}

    clusterKeys.forEach(key => {
      quantityOfValues[key] = this.dataRows.filter(row => (row.meta[column] ?? 'null').toString().trim() === key).length
    })

    return quantityOfValues
  }

  private static getClusteringColorFromPalette (clusterKey: DatasetColumnKey, uniqueValues: ClusteringUniqueValues): Color {
    const rowIndex = ClusteringAwareMixin.calculateClusteringRowIndex(clusterKey, uniqueValues)
    return colorsPalette[rowIndex]
  }

  private static calculateClusteringRowIndex (clusterKey: DatasetColumnKey, uniqueValues: ClusteringUniqueValues) {
    const normalizedClusterKey = (clusterKey ?? 'null').toString().trim()
    return uniqueValues[normalizedClusterKey]
  }

  private static calculateClusteringPaletteIndex (index: number, quantifier: number) {
    return index * quantifier - colorsPaletteSize * Math.floor(index / colorsPaletteSize)
  }
}
