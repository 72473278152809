import { Routes } from '@/router/constants/routes'
import planningLogo from '@/assets/img/planning_logo.svg'
import visualizationLogo from '@/assets/img/visualization_logo.svg'
import ordersLogo from '@/assets/img/orders_logo.svg'
import analyticsLogo from '@/assets/img/analytics_logo.svg'
import ordersImage from '@/assets/img/orders_image.svg'
import planningImage from '@/assets/img/planning_image.svg'
import visualizationImage from '@/assets/img/visualization_image.svg'

export interface MainRouteConfig {
  title: string,
  description: string,
  routeName: Routes,
  image: ImageData,
  headerLogo: ImageData,
  active: boolean
}

const modules = [
  { title: 'common.labels.planning', routeName: Routes.PLANNING_DASHBOARD, headerLogo: planningLogo, image: planningImage, active: true, description: 'Planning tool for (D)OOH marketing campaigns' },
  { title: 'common.labels.visualization', routeName: Routes.VISUALIZATION_DASHBOARD, headerLogo: visualizationLogo, image: visualizationImage, active: true, description: 'Visualization tool for (D)OOH marketing campaigns' },
  { title: 'common.labels.orders', routeName: Routes.ORDERS_DASHBOARD, headerLogo: ordersLogo, image: ordersImage, active: true, description: 'Order tool for (D)OOH marketing campaigns' },
  { title: 'common.labels.analytics', routeName: Routes.ANALYTICS_DASHBOARD, headerLogo: analyticsLogo, image: analyticsLogo, active: true, description: 'Independent tool for analytical topics like consumer market evaluation, geo coding addresses or creation of individual geometries' }
]

export const getModules = (): MainRouteConfig[] => {
  return modules.filter(item => {
    return item.active
  })
}
