import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { SUPPORTED_LATITUDE_COLUMNS, SUPPORTED_LONGITUDE_COLUMNS } from '../components/constants'
import { isNumber, get } from 'lodash'
import { CoordinateColumns, CoordinatesServiceResult } from '@/components/shared/coordinates-service/types'
import { DatasetRows, DatasetRowValue, PointCoordinate } from '@/types/common'
import { ValidationProviderMixin } from '@/mixins/validation/validation-provider.mixin'
import { Validations } from '@/decorators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { required } from 'vuelidate/lib/validators'
import { ValidationModel } from '@/vendor/types/vuelidate/model'
import { FormInputState } from '@/types/bootstrap'

@Component
export class CoordinatesParseMixin extends Mixins(ValidationProviderMixin) {
  @Prop({ default: () => null }) coordinatesColumns!: CoordinateColumns

  latitudeColumn: string | null = null
  longitudeColumn: string | null = null
  errorsCount = 0
  dataWasParsed = false
  saveTableColumns!: boolean

  columns!: string[]
  tableData!: DatasetRows

  @Validations()
  private validations (): RuleDecl {
    return {
      latitudeColumn: { required },
      longitudeColumn: { required }
    }
  }

  private inputState (model: ValidationModel): FormInputState {
    return this.validationModelToInputState(model)
  }

  @Watch('columns')
  onColumnsChange (): void {
    this.initColumns()
  }

  get isDisabled (): boolean {
    return !this.columns.length
  }

  override mounted () {
    this.initColumns()
  }

  initColumns (): void {
    if (get(this.coordinatesColumns, 'latitudeColumn') && get(this.coordinatesColumns, 'longitudeColumn')) {
      this.latitudeColumn = this.coordinatesColumns.latitudeColumn
      this.longitudeColumn = this.coordinatesColumns.longitudeColumn
      return
    }

    this.latitudeColumn = this.columns.find(col => SUPPORTED_LATITUDE_COLUMNS.includes(col.toLowerCase())) || null
    this.longitudeColumn = this.columns.find(col => SUPPORTED_LONGITUDE_COLUMNS.includes(col.toLowerCase())) || null
  }

  parseResults () {
    this.errorsCount = 0

    this.$v.$touch()

    if (this.$v.$invalid) {
      return
    }

    const latColumn = this.latitudeColumn as string
    const lngColumn = this.longitudeColumn as string

    const results = this.tableData.map(result => {
      const point: PointCoordinate = {
        latitude: isNumber(result[latColumn])
          ? result[latColumn] as number : this.parsePoint(result[latColumn]),
        longitude: isNumber(result[lngColumn])
          ? result[lngColumn] as number : this.parsePoint(result[lngColumn])
      }

      if (isNaN(point.latitude) || isNaN(point.longitude)) {
        this.errorsCount++
        return null
      }

      return this.saveTableColumns ? { ...result, ...point } : point
    })

    const resultObject: CoordinatesServiceResult = {
      hasErrors: this.errorsCount !== 0,
      coordinateColumns: {
        latitudeColumn: this.latitudeColumn as string,
        longitudeColumn: this.longitudeColumn as string
      },
      selectedColumns: [],
      rowData: results as DatasetRows
    }

    this.dataWasParsed = true

    this.$emit('ready', resultObject)
  }

  parsePoint (point: DatasetRowValue): number {
    return parseFloat((point as string).replace(',', '.'))
  }

  resetCoordinates () {
    this.latitudeColumn = null
    this.longitudeColumn = null
    this.errorsCount = 0
    this.dataWasParsed = false
  }
}
