






import { Component, Mixins } from 'vue-property-decorator'
import { GridChildMixin } from '@/components/shared/ag-grid/mixins/grid-child.mixin'
import { RowNode, SelectionChangedEvent } from 'ag-grid-community'
import { BOOKING_GRANULARITY_PRICE_FIELD } from '@/constants/unit/fields'
import { parseGermanNumber } from '@/components/shared/ag-grid/utils'

@Component
export default class MediaNetworksGranularityPriceBar extends Mixins<GridChildMixin>(GridChildMixin) {
  private sum = 0

  private get currency (): string {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.sum)
  }

  mounted () {
    this.onGridApi('selectionChanged', (event: SelectionChangedEvent) => {
      // @ts-ignore use this private property to faster get the amount of selected rows
      this.sum = Object.values(event.api.selectionController.selectedNodes).filter(item => item).reduce((sum: number, node: RowNode) => {
        const price = parseGermanNumber(node.data[BOOKING_GRANULARITY_PRICE_FIELD])
        sum += price

        return sum
      }, 0)
    })
  }
}
