import { AclRule, User } from '@/types/auth'
import { AuthRole } from '@/types/auth/roles'
import { ApiPersistenceObject } from '@/types/common'

const allRoles = new Set(Object.values(AuthRole))

export const anyValidRole: AclRule = (user: User): boolean => {
  return user.roles.some((role) => allRoles.has(role))
}

export const isDataObjectOwner: AclRule = (user: User, obj: ApiPersistenceObject): boolean => {
  return user.username === obj.createdBy
}
