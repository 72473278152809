import { isObjectLike } from 'lodash'

export function freezeRecursive<T> (obj: T): T {
  if (Array.isArray(obj)) {
    obj.forEach(freezeRecursive)
  } else if (isObjectLike(obj)) {
    Object.keys(obj).forEach(key => freezeRecursive(obj[key]))
  }

  return Object.freeze(obj)
}
