import { LegendFormatMixin } from './legend-format-mixin'
import { Component } from 'vue-property-decorator'
import { BaseFillConfig } from '@/types/visualization/layer/style'

type QuantileColorGetter = (index: number) => BaseFillConfig

@Component
export class QuantileLegendMixin<TLayer> extends LegendFormatMixin {
  protected layer!: TLayer

  protected makeQuantileLegendValues (values: number[], colorGetter: QuantileColorGetter, maxValue: number) {
    return values
      .map((_value, index) => {
        if (!this.isSublayerVisible(this.layer, index)) {
          return null
        }

        return {
          label: this.makeQuantileLabel(values, index, maxValue),
          color: colorGetter(index).color,
          opacity: colorGetter(index).opacity,
          key: index
        }
      })
      .filter(value => value !== null)
      .reverse()
  }

  protected makeQuantileLabel (values: number[], index: number, maxValue: number) {
    const current = values[index]
    const next = index === values.length - 1 ? maxValue : values[index + 1]

    const range = [
      this.lowerQuantileBoundValue(current, next, index),
      this.formatLegendValue(next)
    ]

    if (range[0] === undefined) {
      return `≤ ${range[1]}`
    } else if (range[1] === undefined) {
      return `≥ ${range[0]}`
    } else if (range[0] === range[1]) {
      return `${range[0]}`
    } else {
      return `${range[0]} - ${range[1]}`
    }
  }

  protected lowerQuantileBoundValue (current: number, next: number, index: number) {
    if (index === 0 || current === next) {
      return this.formatLegendValue(current)
    }

    const strValue = String(current)
    const dotPos = strValue.indexOf('.')
    const len = dotPos === -1 ? 0 : strValue.length - dotPos - 1

    const updatedValue = +(current + Math.pow(10, -len)).toFixed(len)
    // We have the new value that needs to be formatted again.
    return this.formatLegendValue(updatedValue)
  }

  // TODO - has to be redefined in descendants
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected isSublayerVisible (_layer: TLayer, _index: number): boolean {
    return true
  }
}
