










import WizardStepNavigatorMixin from '../../mixins/wizard-steps-navigator.mixin'
import { WIZARD_TAB_SKIP_STEP } from '@/store/action-types'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class WizardSkipButton extends Mixins<WizardStepNavigatorMixin>(WizardStepNavigatorMixin) {
  protected skipStepData (): Promise<unknown> {
    return this.dispatchDynamicStoreAction(WIZARD_TAB_SKIP_STEP)
  }

  protected skipStep (): void {
    this.skipStepData().then(() => {
      this.moveNext()
    })
  }
}
