






import { findRealParent, propsBinder } from 'vue2-leaflet'
import { DomEvent, Map } from 'leaflet'
import { HeatmapOverlay } from '@/lib/leaflet/layer/overlays/heat-map-overlay'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { PropsDefinition } from 'vue/types/options'
import { eventHandler } from '@/components/shared/heat-map/types/event-handler/event-handler'
import { heatmap } from '@/components/shared/heat-map/types/heatmap/heatmap'
import { LatLngData } from '@/components/shared/heat-map/types/lat-lng-data/lat-lng-data'
import { ParentContainer } from '@/components/map/mixins/layer-mixin/types'

@Component
export default class LHeatMap extends Vue {
  @Prop({ default: () => ({}) }) readonly latLng!: LatLngData[];
  @Prop({ default: 0.5 }) readonly maxOpacity!: number;
  @Prop({ default: 0.002 }) readonly radius!: number;
  @Prop({ default: 1 }) readonly zIndex!: number;

  ready = false;
  mapObject!: heatmap;
  parentContainer!: ParentContainer<LHeatMap>;

  @Watch('latLng')
  private onLatLngChange (latLng: LatLngData[]): void {
    this.setLatLng(latLng)
  }

  @Watch('maxOpacity')
  private onMaxOpacityChange (maxOpacity: number): void {
    this.setMaxOpacity(maxOpacity)
  }

  @Watch('radius')
  private onRadiusChange (radius: number): void {
    this.setRadius(radius)
  }

  @Watch('zIndex')
  private onZIndexChange (zIndex: number): void {
    this.setZIndex(zIndex)
  }

  mounted () {
    this.mapObject = new HeatmapOverlay(this.$props)
    DomEvent.on(this.mapObject, this.$listeners as eventHandler)
    propsBinder(this, this.mapObject as unknown as Map, this.$options.props as PropsDefinition<Vue>)
    this.ready = true
    this.mapObject.setData(this.latLng)
    this.parentContainer = findRealParent(this.$parent)
    this.parentContainer.addLayer(this)
  }

  beforeDestroy () {
    this.parentContainer.removeLayer(this)
  }

  setLatLng (newValue: LatLngData[]): void {
    this.mapObject.setData(newValue)
  }

  setMaxOpacity (newValue: number): void {
    this.mapObject._setMaxOpacity(newValue)
  }

  setRadius (newValue: number): void {
    this.mapObject._setRadius(newValue)
  }

  setZIndex (newValue: number): void {
    this.mapObject._setZIndex(newValue)
  }
}
