import { SAVE_CAMPAIGN, UPDATE_AREA_ASSIGNMENTS } from '@/store/action-types'
import { ExcludedAreaTag } from '@/types/planning/unclear-assignments'
import {
  CAMPAIGN_EXCLUDED_AREA_TAGS,
  CAMPAIGN_EXCLUDED_AREA_TAGS_SHOULD_CLEAR,
  SCORING_WILL_BE_UPDATED
} from '@/store/getter-types'
import { SET_EXCLUDED_AREA_ASSIGNMENTS } from '@/store/mutation-types'
import { isEqual, uniqWith } from 'lodash'

// initial state
const state = {
  excludedAreaAssignments: []
}

const getters = {
  [CAMPAIGN_EXCLUDED_AREA_TAGS] (state) {
    return state.excludedAreaAssignments
  },
  [CAMPAIGN_EXCLUDED_AREA_TAGS_SHOULD_CLEAR] (_state, getters) {
    return getters[SCORING_WILL_BE_UPDATED] && getters[CAMPAIGN_EXCLUDED_AREA_TAGS].length > 0
  }
}

const actions = {
  async [UPDATE_AREA_ASSIGNMENTS] ({ commit, dispatch, getters }, excludedAreaTagsNew: ExcludedAreaTag[]) {
    const excludedAreaTagsPrev = getters[CAMPAIGN_EXCLUDED_AREA_TAGS]
    const excludedAreaTags = uniqWith([...excludedAreaTagsNew, ...excludedAreaTagsPrev], isEqual)

    commit(SET_EXCLUDED_AREA_ASSIGNMENTS, excludedAreaTags)
    return dispatch(SAVE_CAMPAIGN)
  }
}

const mutations = {
  [SET_EXCLUDED_AREA_ASSIGNMENTS] (state, excludedAssignments: ExcludedAreaTag[]) {
    state.excludedAreaAssignments = excludedAssignments
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
