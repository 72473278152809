import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { get } from 'lodash'
import VueTagsInput from '@johmun/vue-tags-input'
import { minLength, required } from 'vuelidate/lib/validators'
import { VueClassDef } from '@/types/vue'
import { TagCollectionItem } from '@/types/planning/wizard/filter-editor'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { Validations } from '@/decorators'
import { UiControlMixin } from './ui-control.mixin'
import { TagsControlOptions } from '@/components/planning/wizard/filter-editor/types'
import { nanoid } from 'nanoid'

@Component({
  components: { VueTagsInput }
})
export class TagsUiMixin extends Mixins<UiControlMixin<string[]>>(UiControlMixin) {
  @Prop({ default: () => [] }) declare value: string[]

  @Ref('tagsInput') protected readonly tagsInput!: Vue

  // @ts-ignore - TS-2729 value already initialized here as Vue uses its own class initialization logic
  protected tags: TagCollectionItem[] = this.value ? this.value.map(val => ({ text: val })) : []
  protected tag = ''
  protected customOptions: TagCollectionItem[] | null = null
  private uid = nanoid(6)

  @Validations()
  protected validations (): RuleDecl {
    return {
      selected: {
        required,
        minLength: minLength(1)
      }
    }
  }

  @Watch('value', { immediate: true })
  private onValueChange (newValue: string[]): void {
    this.initTags(newValue)
  }

  get options (): TagCollectionItem[] {
    return this.customOptions || get(this.config, 'options', [])
  }

  set options (options: TagCollectionItem[]) {
    this.customOptions = options
  }

  protected get stateClass (): VueClassDef {
    return {
      'is-invalid': this.selectedInputState === false,
      'is-valid': this.selectedInputState === true
    }
  }

  get autocompleteItems (): TagCollectionItem[] {
    const searchString = this.tag.toLowerCase()

    return this.options.filter(i => {
      return i.text && i.text.toLowerCase().indexOf(searchString) !== -1
    }).slice(0, 20)
  }

  get elementClass (): string {
    return `tags-element-${this.uid}`
  }

  get settings (): TagsControlOptions {
    return {
      autocomplete: true
    }
  }

  @Watch('tags')
  onTagsChange (tags: TagCollectionItem[]): void {
    this.setSelected(tags.map((tag) => tag?.value ?? tag?.text ?? tag))
  }

  initTags (tagsValue: string[]): void {
    if (this.settings.autocomplete) {
      this.tags = this.options.filter(option => (option.value && tagsValue.indexOf(option.value) > -1))
      // This change is not from user interaction so reset validation state.
      this.$v.selected.$reset()
    }
  }

  tagsChanged (newTags: TagCollectionItem[]): void {
    this.tags = newTags

    // Fix focus issue.
    setTimeout(() => {
      if (document.activeElement === this.tagsInput.$refs.newTagInput) {
        const el = this.tagsInput.$refs.newTagInput as HTMLElement
        const event = new FocusEvent('focus')

        el.dispatchEvent(event)
      }
    }, 0)
  }
}
