






















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { clone, sortBy } from 'lodash'
import { minLength, required } from 'vuelidate/lib/validators'
import { BvTableFieldArray } from 'bootstrap-vue'
import { FormInputState } from '@/types/bootstrap'
import { MediaFilterParameter } from '@/types/planning/wizard/value-types'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { MediaFilterCollection } from '@/utils/filter-editor/value-config/collection/media-filter-collection'
import { Validations } from '@/decorators'
import { UiControlMixin } from './mixins/ui-control.mixin'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
@Component({
  components: { FilterParamName }
})
export default class MediaFilterListUiElement extends Mixins<UiControlMixin<MediaFilterParameter[], MediaFilterCollection>>(UiControlMixin) {
  @Prop({ default: () => [] }) readonly declare value: MediaFilterParameter[]

  // @ts-ignore - TS-2729 value already initialized here as Vue uses its own class initialization logic
  selected: MediaFilterParameter[] = this.value ? clone(this.value) : this.config.defaultValue

  private mediaValue = ''
  private mediaParameter = ''

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: { required, minLength: minLength(1) }
    }
  }

  private get inputsState (): FormInputState {
    const state = this.selectedInputState

    // Suppress "success" state.
    return state ? null : state
  }

  private get options () {
    const selectedKeys = this.selected.map((item) => item.key)

    const options = this.config.options
      .filter(item => !selectedKeys.includes(String(item.value)))
      .map((item) => ({ text: item.text, value: item.value }))

    return sortBy(options, 'text')
  }

  private get fields (): BvTableFieldArray {
    return [
      {
        key: 'key',
        label: this.$t('planning.filters.labels.parameter') as string,
        formatter: (key: string) => {
          const option = this.config.options.find((option) => option.value === key)
          return option ? this.config.formatCollectionOption(option) : key
        }
      },
      {
        key: 'value',
        label: this.$t('planning.filters.labels.value') as string
      }
    ]
  }

  @Watch('selected')
  private onSelectedChange (value: MediaFilterParameter[]): void {
    this.$emit('change', value)
  }

  private addParameter (): void {
    const newParam: MediaFilterParameter = {
      key: this.mediaParameter,
      value: this.mediaValue
    }
    this.setSelected([...this.selected, newParam])
    this.mediaValue = ''
  }
}
