import {
  DATASET_WIZARD_VISIBLE,
  DATASET_WIZARD_DATASET_NAME,
  DATASET_WIZARD_DATASET,
  DATASET_WIZARD_ENTRYPOINT,
  DATASET_WIZARD_ENTRYPOINT_IS_GEOCODING,
  DATASET_WIZARD_ENTRYPOINT_IS_TDLINX,
  DATASET_WIZARD_ENTRYPOINT_IS_GEOMETRIES,
  DATASET_WIZARD_NAMESPACE,
  DATASET_WIZARD_LINK_NAMESPACE,
  DATASET_WIZARD_DATASET_TYPE,
  DATASET_WIZARD_DATASET_UPDATE
} from '@/store/getter-types'
import {
  ENTRYPOINT_GEOCODING,
  ENTRYPOINT_TDLINX,
  ENTRYPOINT_GEOMETRIES
} from './constants/entrypoints'
import { TAG_KEY_ACCESS, TAG_KEY_SOURCE, TAG_KEY_TYPE, TAG_KEY_YEAR } from '@/constants/tags'
import moment from 'moment'
import { DatasetType } from '@/types/analytics/enums'
import { ObjectTag } from '@/types/common'

export const DATASET_WIZARD_READONLY_TAGS = 'datasetWizard/getters/readonlyTags'
export const DATASET_WIZARD_DEFAULT_TAGS = 'datasetWizard/getters/defaultTags'

export default {
  [DATASET_WIZARD_VISIBLE] (state) {
    return state.visible
  },
  [DATASET_WIZARD_DATASET_NAME] (state) {
    return state.datasetName
  },
  [DATASET_WIZARD_DATASET] (state) {
    return state.dataset
  },
  [DATASET_WIZARD_DATASET_UPDATE] (state) {
    return state.datasetUpdate
  },
  [DATASET_WIZARD_ENTRYPOINT] (state) {
    return state.entrypoint
  },
  [DATASET_WIZARD_DATASET_TYPE] (state) {
    return state.datasetType
  },
  [DATASET_WIZARD_ENTRYPOINT_IS_GEOCODING] (_state, getters) {
    return getters[DATASET_WIZARD_ENTRYPOINT] === ENTRYPOINT_GEOCODING
  },
  [DATASET_WIZARD_ENTRYPOINT_IS_TDLINX] (_state, getters) {
    return getters[DATASET_WIZARD_ENTRYPOINT] === ENTRYPOINT_TDLINX
  },
  [DATASET_WIZARD_ENTRYPOINT_IS_GEOMETRIES] (_state, getters) {
    return getters[DATASET_WIZARD_ENTRYPOINT] === ENTRYPOINT_GEOMETRIES
  },
  [DATASET_WIZARD_NAMESPACE] (state) {
    return state.wizardNamespace
  },
  [DATASET_WIZARD_LINK_NAMESPACE] (_state, getters) {
    return (getter) => {
      return getters[DATASET_WIZARD_NAMESPACE] + '/' + getter
    }
  },
  [DATASET_WIZARD_READONLY_TAGS] (): ObjectTag[] {
    return [
      { value: 'Global', key: TAG_KEY_ACCESS },
      { value: 'Analytics', key: TAG_KEY_SOURCE }
    ]
  },
  [DATASET_WIZARD_DEFAULT_TAGS] (_state: unknown, getters): ObjectTag[] {
    const tags: ObjectTag[] = [
      { value: moment().year().toString(), key: TAG_KEY_YEAR }
    ]

    if (getters[DATASET_WIZARD_DATASET_TYPE] === DatasetType.GeocodedDataset) {
      tags.push({ value: 'POI', key: TAG_KEY_TYPE })
    }

    return getters[DATASET_WIZARD_READONLY_TAGS].concat(tags)
  }
}
