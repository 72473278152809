import { latLngBounds } from 'leaflet'
import { omit } from 'lodash'
import { visualizationDataApi } from '@/api/rest/visualization/visualization-data.api'
import { dataDeliveryApi } from '@/api/rest/data-delivery/data-delivery.api'
import {
  CHECK_AND_UPDATE_LAYER_STYLE,
  COPY_VISUALIZATION_LAYER,
  CREATE_VISUALIZATION_LAYER,
  CREATE_VISUALIZATION_LAYERS,
  LOAD_DATASET_FULL_DATA,
  LOAD_GEOMETRY_FULL_DATA,
  LOAD_VISUALIZATION_DATA,
  UPDATE_AND_REPLACE_LAYER,
  UPDATE_DATASOURCE_TO_VISUALIZATION_LAYER,
  UPDATE_VISUALIZATION_LAYER_DATASET
} from '@/store/action-types'
import { VISUALIZATION_REQUEST_DATA } from '@/store/getter-types'
import { ADD_VISUALIZATION_LAYER, REPLACE_VISUALIZATION_LAYER, RESET_GEOMETRIES_CACHE } from '@/store/mutation-types'

// actions
const actions = {
  [LOAD_DATASET_FULL_DATA] ({ getters }, layer) {
    // Use default map bounds.
    const bounds = latLngBounds(DEFAULT_MAP_BOUNDS.northWest, DEFAULT_MAP_BOUNDS.southEast)
    const request = getters[VISUALIZATION_REQUEST_DATA]

    return visualizationDataApi.getSingleDataset({ request, id: layer.id }, bounds)
  },
  [LOAD_GEOMETRY_FULL_DATA] ({ getters }, layer) {
    // Use default map bounds.
    const bounds = latLngBounds(DEFAULT_MAP_BOUNDS.northWest, DEFAULT_MAP_BOUNDS.southEast)
    const request = getters[VISUALIZATION_REQUEST_DATA]

    return visualizationDataApi.getSingleGeometry({ request, id: layer.id }, bounds)
  },
  [CREATE_VISUALIZATION_LAYER] ({ dispatch }, { layer }) {
    dispatch(CREATE_VISUALIZATION_LAYERS, [layer]).then((layers) => layers[0])
  },
  [CREATE_VISUALIZATION_LAYERS] ({ commit, dispatch }, layers) {
    return dispatch(LOAD_VISUALIZATION_DATA, { layers })
      .then((data) => {
        for (const layer of data.layers) {
          commit(ADD_VISUALIZATION_LAYER, layer)
        }
        commit(RESET_GEOMETRIES_CACHE)

        return data.layers
      })
  },
  [UPDATE_VISUALIZATION_LAYER_DATASET] ({ dispatch }, { layer, dataset }) {
    const rows = dataset.datasetRows.map(row => Object.values(row))

    return dataDeliveryApi.createDataset({ columnInfos: dataset.columnInfos, rows }).then((datasetId) => {
      const dataSource = { ...layer.dataSource, datasetId }
      return dispatch(UPDATE_DATASOURCE_TO_VISUALIZATION_LAYER, { layer, dataSource })
    })
  },
  [UPDATE_DATASOURCE_TO_VISUALIZATION_LAYER] ({ getters, dispatch }, { layer, dataSource }) {
    const storedLayers = getters[VISUALIZATION_REQUEST_DATA].layers
    const replacementLayer = storedLayers.find(storedLayer => storedLayer.id === layer.id)

    replacementLayer.dataSource = dataSource

    return dispatch(UPDATE_AND_REPLACE_LAYER, replacementLayer)
  },
  [UPDATE_AND_REPLACE_LAYER] ({ commit, dispatch }, newLayer) {
    return dispatch(LOAD_VISUALIZATION_DATA, { layers: [newLayer] })
      .then(data => {
        const layer = data.layers[0]

        return dispatch(CHECK_AND_UPDATE_LAYER_STYLE, { layer, newStyle: layer.style }).then(() => {
          commit(REPLACE_VISUALIZATION_LAYER, layer)
          return layer
        })
      })
  },
  [COPY_VISUALIZATION_LAYER] ({ dispatch }, layer) {
    return dispatch(CREATE_VISUALIZATION_LAYER, { layer: omit(layer, ['id']) })
  }
}

export default actions
