import { sortBy, sortedUniqBy } from 'lodash'
import moment from '@/lib/moment'
import {
  BOOKING_TIME,
  BOOKING_TIME_VALUES,
  BOOKING_TIME_WEEKS,
  CAMPAIGN_END_DATE,
  CAMPAIGN_START_DATE,
  PLAN_WIZARD_END_DATE,
  PLAN_WIZARD_START_DATE
} from '@/store/getter-types'
import { SET_BOOKING_TIME } from '@/store/mutation-types'
import { LOAD_BOOKING_TIME } from '@/store/action-types'
import { isWeek } from '@/types/planning/guards'

const state = {
  bookingTimes: []
}

const getters = {
  [BOOKING_TIME] (state, getters) {
    const startDate = getters[PLAN_WIZARD_START_DATE] ? moment(getters[PLAN_WIZARD_START_DATE]) : moment(getters[CAMPAIGN_START_DATE])
    const endDate = getters[PLAN_WIZARD_END_DATE] ? moment(getters[PLAN_WIZARD_END_DATE]) : moment(getters[CAMPAIGN_END_DATE])

    if (!startDate || !endDate) {
      return []
    }
    const filteredBookingTimes = state.bookingTimes
      .filter(({ start, end }) =>
        startDate.isSameOrBefore(start, 'day') &&
        endDate.isSameOrAfter(end, 'day')
      )

    return sortBy(filteredBookingTimes, frame => `${frame.numberOfYear}`)
  },
  [BOOKING_TIME_VALUES] (state) {
    return values => {
      return state.bookingTimes
        .filter(item => values.includes(item.value))
        .map(({ value, start, end }) => ({ value, start, end }))
    }
  },
  [BOOKING_TIME_WEEKS] (state) {
    return state.bookingTimes.filter(isWeek)
      .map(({ year, value, start, end, numberOfYear }) => {
        return {
          year,
          value,
          range: moment.range(start, end),
          numberOfYear
        }
      })
  }
}

const mutations = {
  [SET_BOOKING_TIME] (state, bookingTimes) {
    const newBookingTimes = [...state.bookingTimes, ...bookingTimes]
    const sortedTimeFrames = sortBy(newBookingTimes, ['value', 'year', 'numberOfYear'])
    state.bookingTimes = sortedUniqBy(sortedTimeFrames, (frame) => `${frame.value}:${frame.year}:${frame.numberOfYear}`)
  }
}

const actions = {
  [LOAD_BOOKING_TIME] ({ commit, getters }, timeFrames) {
    commit(SET_BOOKING_TIME, timeFrames)
    return getters[BOOKING_TIME]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
