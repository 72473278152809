import { Component, Mixins, Watch } from 'vue-property-decorator'
import { CreateElement, VNode } from 'vue'
import { findRealParent, optionsMerger } from 'vue2-leaflet'
import { featureGroup, Layer } from 'leaflet'
import { ItemsGroup } from '../types'
import { LayerGroupMixin } from '../mixins/grouped-layers/layer-group.mixin'
import { GroupByMixin } from '../mixins/grouped-layers/group-by.mixin'
import { ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

@Component
export class MultiMapObjectMixin<TItem, TLayer extends Layer, TOptions extends ZIndexedPathOptions> extends Mixins<
    // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    LayerGroupMixin<TItem, TLayer>,
    // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
    GroupByMixin<TItem, TLayer, TOptions>
  >(LayerGroupMixin, GroupByMixin) {
  @Watch('groupedItems')
  private onGroupedItemsChange (groups: ItemsGroup<TItem, TOptions>[]): void {
    this.parentContainer.removeLayer(this)
    this.mapObject.clearLayers()

    const options: TOptions = optionsMerger<TOptions>(this.getOptionsToMerge(), this)

    groups.forEach(group => this.mapObject.addLayer(this.createMultiLayer(group, options)))

    this.parentContainer.addLayer(this, !this.visible)
  }

  created (): void {
    this.mapObject = featureGroup()
  }

  mounted (): void {
    const options: TOptions = optionsMerger<TOptions>(this.getOptionsToMerge(), this) as TOptions

    for (const group of this.groupedItems) {
      this.mapObject.addLayer(this.createMultiLayer(group, options))
    }

    this.bindProps()
    this.parentContainer = findRealParent(this.$parent)
    this.parentContainer.addLayer(this, !this.visible)
    this.$nextTick(() => {
      this.$emit('ready', this.mapObject)
    })
  }

  protected getOptionsToMerge (): TOptions {
    return {} as TOptions
  }

  protected createMultiLayer (group: ItemsGroup<TItem, TOptions>, options: TOptions): TLayer {
    const mapObject = this.createMapObject(group, this.makeGroupOptions(group, options))
    this.makeGroupListeners(mapObject, group.items)

    return mapObject
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected createMapObject (_group: ItemsGroup<TItem, TOptions>, _groupOptions: TOptions): TLayer {
    throw new Error('createMapObject not implemented')
  }

  render (createElement: CreateElement): VNode {
    return createElement('div', this.$slots.default)
  }
}
