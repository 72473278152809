import { RangeOptions, ValueRange } from '@/types/planning/wizard/filter-editor'
import { RangeConfig } from './range-config'
import { ScoringFunctionData } from '@/types/planning/scoring/functions'
import { PpsFilterModes } from '@/types/planning/wizard/enums'
import { PPS_FILTER_MODE } from '@/constants/functions/scoring-functions-param-names'

export class PPSValueConfig extends RangeConfig {
  protected override _defValue = [20_000, 250_000]

  protected override rangeOptions: RangeOptions = {
    rangeMin: 0,
    rangeMax: 500_000,
    rangeStep: 100,
    formatter: val => `${val}`
  }

  protected override setDefaultValue (value: ValueRange): void {
    // Should be a two element array - lower and upper bounds of a range.
    if (!this.isValidRange(value)) {
      value = this._defValue as ValueRange
    }

    super.setDefaultValue(value)
  }

  override isVisibleInGridView (data: ScoringFunctionData): boolean {
    return data[PPS_FILTER_MODE] === PpsFilterModes.Absolute
  }
}
