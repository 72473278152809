import { PropOptions } from 'vue'
import { Prop } from 'vue-property-decorator'

interface MapPropOptions extends PropOptions {
  layerProp?: boolean;
}

export function MapCompProp (options: MapPropOptions): PropertyDecorator {
  return Prop(options) as PropertyDecorator
}
