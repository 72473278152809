








import { Component, Prop } from 'vue-property-decorator'
import { QueryRuleRadioConfig } from '@/types/query-builder/rules'
import { RuleMixin } from './rule.mixin'

@Component
export default class RadioRule extends RuleMixin<string | number> {
  @Prop({ required: true }) rule!: QueryRuleRadioConfig
}
