import { Store } from '@/store/store'
import { BOOKING_TIME } from '@/store/getter-types'
import { getColumnDefs } from './column-defenitions'
import { customValueWrapper } from '@/components/shared/ag-grid/utils'
import { BookingTimeFrame } from '@/types/planning/booking-time'
import { sortBy, uniqBy, flatten } from 'lodash'
import { ColDef } from 'ag-grid-community'
import { Dataset } from '@/types/common'
import { NETWORK_SIZE_COLUMN, TIME_FRAME_COLUMNS, TOTAL_PRICE_COLUMN } from '@/constants/unit/fields'

export function isTimeFrameColumn (column): boolean {
  return isDecadeColumn(column) || isWeekColumn(column)
}

export function isWeekColumn (column): boolean {
  return /^[W|K]{1,2} \d+$/.test(column)
}

export function isDecadeColumn (column): boolean {
  return /^[D] \d+$/.test(column)
}

export function isTimeFramePriceColumn (column): boolean {
  return isDecadePriceColumn(column) || isWeekPriceColumn(column)
}

export function isWeekPriceColumn (column): boolean {
  return /^.{1,}[W] \d+$/.test(column)
}

export function isDecadePriceColumn (column): boolean {
  return /^.{1,}[D] \d+$/.test(column)
}

export function isDynamicColumn (column: string | undefined): boolean {
  return isTimeFramePriceColumn(column) || isTimeFrameColumn(column)
}

export function timeFrameColumns (columns: string[]): string[] {
  return columns.filter(column => isTimeFrameColumn(column))
}

export function calculateTotalUnitsPerPeriodsSize (params, aggColumnFilter) {
  const callback = (params) => {
    if (params.columnApi) {
      return params.columnApi.getAllColumns()
        .filter(column => aggColumnFilter(column.getColId()))
        .reduce((result, column) => {
          const colId = column.getColId()

          result += params.data && params.data[colId] ? params.data[colId] : 0
          return result
        }, 0)
    }

    return params.data[NETWORK_SIZE_COLUMN]
  }

  return customValueWrapper(params, callback)
}

export function calculateTotalPriceValue (params, priceColumnsFilter) {
  const callback = (params) => {
    if (params.columnApi) {
      return params.columnApi.getAllColumns()
        .filter(column => priceColumnsFilter(column.getColId()))
        .reduce((price, column) => {
          const parseNumberFunc = column.getColDef().valueGetter

          price += parseNumberFunc({ ...params, colDef: column.getColDef() }) || 0
          return price
        }, 0)
    }
    return params.data[TOTAL_PRICE_COLUMN]
  }

  return customValueWrapper(params, callback)
}

function handleTimeFrameSorting (columns: string[]): string[] {
  const parseTimeFrameValue = (value) => value.replace(/ [A-C]/, '')
  let timeFrames: Partial<BookingTimeFrame>[] = []
  const bookingTime = Store.getters[BOOKING_TIME]

  for (let i = 0; i < bookingTime.length; i++) {
    const frame = bookingTime[i]
    const parsedValue = parseTimeFrameValue(frame.value)

    if (columns.includes(parsedValue)) {
      timeFrames.push({
        year: frame.year,
        numberOfYear: frame.numberOfYear,
        type: frame.type,
        value: parsedValue
      })
    }
  }

  timeFrames = uniqBy(timeFrames, 'value')

  return sortBy(timeFrames, ['year', 'type', 'numberOfYear']).map(frame => frame.value) as string[]
}

export function sortTimeFrameColumns (columns: string[]): string[] {
  const sortedTimeFrame = handleTimeFrameSorting(columns)

  const timeFrameColumnsWithPrice = sortedTimeFrame.map(timeFrame => {
    const timeFrameColumn = columns.find(column => isTimeFramePriceColumn(column) && column.includes(timeFrame))

    if (timeFrameColumn) {
      return [timeFrame, timeFrameColumn]
    }

    return timeFrame
  })

  return flatten(timeFrameColumnsWithPrice)
}

export function getRemainingColumns (columns: string[], order: string[]): string[] {
  return columns.filter(colDef => !order.includes(colDef))
}

export function processOrder (order: string[], columns: string[] = []): string[] {
  const copiedOrder = [...order]
  const timeFrameColumnsPosition = order.indexOf(TIME_FRAME_COLUMNS)

  if (timeFrameColumnsPosition === -1) {
    return order
  }

  copiedOrder.splice(timeFrameColumnsPosition, 1, ...sortTimeFrameColumns(columns))
  return copiedOrder
}

export function sortColumnsInOrder (columns: string[], order: string[]): string[] {
  const columnsToOrder = columns.filter(column => order.includes(column))
  return columnsToOrder.sort((colA, colB) => order.indexOf(colA) - order.indexOf(colB))
}

export function configureColumnOrder (columns: string[], order: string[]) {
  const columnsOrder = processOrder(order, columns)
  const sortedColumns = sortColumnsInOrder(columns, columnsOrder)
  const remainingColumns = getRemainingColumns(columns, columnsOrder)

  return [sortedColumns, remainingColumns]
}

export function configureColumnDefs (columns: string[], order: string[], columnInfos: Dataset['columnInfos']): ColDef[] {
  const [sortedColumns, remainingColumns] = configureColumnOrder(columns, order)

  return [
    ...getColumnDefs(sortedColumns, columnInfos),
    ...getColumnDefs(remainingColumns, columnInfos).map(colDef => ({ ...colDef, hide: true }))
  ]
}

export function getColumnValueType (column: string, columnInfos: Dataset['columnInfos'] = []) {
  return columnInfos.find(info => info.name === column)?.type
}
