import { LocaleMessages } from 'vue-i18n'

export default {
  en: {
    'rating-dataset-search-prompt': 'Search for a data set from Analytics containing PLZ 5/8 and ranking values.',
    'rating-dataset-upload-prompt': 'Upload local file containing PLZ 5/8 and ranking values.'
  },
  de: {
    'rating-dataset-search-prompt': 'Auswahl eines Datensatzes aus Analytics mit PLZ 5/8 und Rankingwerten.',
    'rating-dataset-upload-prompt': 'Hochladen einer Datei mit PLZ 5/8 und Rankingwerten.'
  }
} as LocaleMessages
