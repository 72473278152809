import moment from '@/lib/moment'

export function dateFilter (possibleDate, format: string): string {
  if (!possibleDate) {
    return ''
  }

  const date = moment(possibleDate)

  return date.isValid() ? date.format(format) : ''
}
