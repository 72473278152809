import GradientAwareMixin from './gradient-aware.mixin'
import { Component } from 'vue-property-decorator'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { ColorModel } from '@/types/color-data'
import { Color } from 'csstype'
import { LayerGeometry } from '@/types/visualization/layer/geometry'
import { LayerColorGetter, LayerOpacityGetter } from '@/types/visualization/layer/style'
import { DatasetColumnKey } from '@/types/common'

@Component
export default class GradientPathMixin<TLayer extends BorderedGeometryLayer> extends GradientAwareMixin<TLayer> {
  private get gradientPathStartRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.path.gradientStartColor, this.layer.style.path.gradientStartOpacity)
  }

  private get gradientPathEndRgb (): ColorModel['rgba'] | undefined {
    return this.calculateRgb(this.layer.style.path.gradientEndColor, this.layer.style.path.gradientEndOpacity)
  }

  private minGradientPathValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMinGradientValue(gradientColumn)
  }

  private maxGradientPathValue (gradientColumn: DatasetColumnKey): number {
    return this.calculateMaxGradientValue(gradientColumn)
  }

  protected get gradientPathColorFunc (): LayerColorGetter<LayerGeometry> {
    const gradientColumn = this.layer.style.path.gradientColumn
    // Make this property depend on gradientColumn, gradientPathStartRgb and gradientPathEndRgb.
    if (
      gradientColumn === undefined ||
      this.gradientPathStartRgb === undefined ||
      this.gradientPathEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.gradientPathColor(row, gradientColumn)
  }

  protected get gradientPathOpacityFunc (): LayerOpacityGetter<LayerGeometry> {
    const gradientColumn = this.layer.style.path.gradientColumn
    // Make this property depend on gradientColumn, gradientPathStartRgb and gradientPathEndRgb.
    if (
      gradientColumn === undefined ||
      this.gradientPathStartRgb === undefined ||
      this.gradientPathEndRgb === undefined
    ) {
      return () => null
    }

    return row => this.gradientPathOpacity(row, gradientColumn)
  }

  protected calculateGradientPathRatio (row: LayerGeometry, gradientColumn: DatasetColumnKey): number {
    return this.calculateGradientRatio(
      row,
      gradientColumn,
      this.minGradientPathValue(gradientColumn),
      this.maxGradientPathValue(gradientColumn)
    )
  }

  protected gradientPathColor (row: LayerGeometry, gradientColumn: DatasetColumnKey): Color {
    const ratio = this.calculateGradientPathRatio(row, gradientColumn)
    const color = this.calculateGradientColor(ratio, this.gradientPathStartRgb, this.gradientPathEndRgb)

    return color.toHexString()
  }

  protected gradientPathOpacity (row: LayerGeometry, gradientColumn: DatasetColumnKey): ColorModel['a'] {
    const ratio = this.calculateGradientPathRatio(row, gradientColumn)

    return this.calculateGradientOpacity(ratio, this.gradientPathStartRgb, this.gradientPathEndRgb)
  }
}
