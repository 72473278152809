














































import { Component, Mixins, Ref } from 'vue-property-decorator'
import { unionBy, get } from 'lodash'
import TagsAutocomplete from '@/directives/tags-autocomplete'
import { TagsUiMixin } from './mixins/tags-ui.mixin'
import TagsImport from './components/tags-import.vue'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
import { TagsImporter } from '@/types/planning/wizard/filter-editor'
import { TagsControlOptions } from '@/components/planning/wizard/filter-editor/types'
import { FILTER_INCLUSION } from '@/constants/scoring'
import { mapActions } from 'vuex'
import { VICINITY_IMPORT_WIZARD_SHOW } from '@/store/action-types'

@Component({
  components: { FilterParamName, TagsImport },
  directives: { TagsAutocomplete },
  methods: {
    ...mapActions({
      showBookableUnitsWizard: VICINITY_IMPORT_WIZARD_SHOW
    })
  }
})
export default class TagsFileUiElement extends Mixins<TagsUiMixin>(TagsUiMixin) {
  @Ref('filterParamName') filterParamNameRef !: FilterParamName

  private tagsImportLabel = ''

  private updateTagsFromFile (tags): void {
    this.tags = unionBy(this.tags, tags, 'text')
  }

  private get uploadByWizard () {
    return this.funcName === FILTER_INCLUSION
  }

  override get settings (): TagsControlOptions {
    const tagsSettings = (this.config as Partial<TagsImporter>).tagImportSettings
    const onlyAutocomplete = get(tagsSettings, 'onlyAutocomplete', true)

    return {
      autocomplete: onlyAutocomplete
    }
  }

  private async showUploadOptions (): Promise<void> {
    const result = await this.$enterBookableUnits()

    if (result.submitted) {
      const newTags = result.externalIds.map(id => ({ text: id, value: id }))
      this.updateTagsFromFile(newTags)
    }
  }

  override mounted (): void {
    this.tagsImportLabel = this.filterParamNameRef.paramDisplayName
  }
}
