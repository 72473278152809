
import { Component, Prop } from 'vue-property-decorator'
import SelectorModal from '@/components/shared/selector-table/selector-modal.vue'
import { VueInstanceProps } from '@/types/vue'
import { DatasetRows } from '@/types/common'
import { ColDef } from 'ag-grid-community'
import { LocalTranslations } from '@/decorators/local-translations'
import messages from './locales/locales'

@Component
@LocalTranslations(messages)
export default class CitiesSelectorModal extends SelectorModal {
  @Prop({ required: true }) private datasetRows!: Readonly<DatasetRows>
  @Prop({ required: true }) private columnDefs!: Readonly<ColDef[]>

  protected override get tableProps (): VueInstanceProps {
    return {
      rowData: this.datasetRows,
      columnDefs: this.columnDefs
    }
  }

  protected override get sourceSwitcherAllLabel (): string {
    return this.$t('all-cities') as string
  }

  protected override get sourceSwitcherSelectedLabel (): string {
    return this.$t('selected-cities') as string
  }

  protected override get modalTitle (): string {
    return this.$t('modal-title') as string
  }
}
