import { parseLayers } from '@/utils/visualization/parse-layers'
import { getApiColumnType } from '@/utils/visualization/parse-layers/columns'
import { parseDatasetRows } from '@/utils/parse-dataset-rows'
import { AxiosResponse } from 'axios'
import { VisualizationObject } from '@/types/visualization'
import { createTransformResponse } from '@/api/utils'

export const transformVisualizationDataResponse = createTransformResponse<VisualizationObject | undefined>((response: AxiosResponse['data']): VisualizationObject | undefined => {
  if (!response) {
    return
  }

  const visualization = JSON.parse(response)

  if (visualization.layers) {
    visualization.layers.forEach(layer => {
      const datasetData = layer.data.find(col => getApiColumnType(col) === null)
      datasetData && parseDatasetRows(datasetData)
    })

    visualization.layers = parseLayers(visualization.layers)
  }

  return visualization
})
