






























import dragscroll from 'vue-dragscroll/src/directive'
import OverlayScrollbarsLib, { Options, ScrollEventCallback } from 'overlayscrollbars'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DragScrollOptions } from './types'
import { VueDynamicClassDef } from '@/types/vue'

@Component({
  directives: { dragscroll }
})
export default class OverlayScrollbars extends Vue {
  @Prop({ default: true }) dragScroll!: boolean;
  @Prop({ default: '' }) scrollClass!: string | Array<string>;
  @Prop({ default: 'default' }) theme!: string;
  @Prop({ default: false, type: Boolean }) flex!: boolean;

  private _osInstance!: OverlayScrollbarsLib;

  private get scrollbarClassName (): string {
    let className = `os-theme-${this.theme} `
    className += Array.isArray(this.scrollClass) ? this.scrollClass.join(' ') : this.scrollClass
    return className.trim()
  }

  private get scrollbarOptions (): Options {
    return {
      className: this.scrollbarClassName,
      resize: 'none',
      scrollbars: {
        autoHide: 'never',
        clickScrolling: true
      },
      callbacks: {
        onScroll: this.onScroll as ScrollEventCallback
      }
    }
  }

  private get dragScrollOptions (): DragScrollOptions | boolean {
    if (OverlayScrollbarsLib.valid(this._osInstance)) {
      return {
        target: '.os-viewport',
        active: this.dragScroll
      }
    }
    return false
  }

  private get hostClass (): VueDynamicClassDef {
    return {
      'os-host-flexbox': this.flex
    }
  }

  @Watch('scrollbarOptions')
  private onScrollbarOptionsChange (options: Options): void {
    if (OverlayScrollbarsLib.valid(this._osInstance)) {
      this._osInstance.options(options)
    }
  }

  mounted () {
    this._osInstance = OverlayScrollbarsLib(this.$el, this.scrollbarOptions)
  }

  beforeDestroy () {
    if (OverlayScrollbarsLib.valid(this._osInstance)) {
      this._osInstance.destroy()
    }
  }

  public getOSInstance (): OverlayScrollbarsLib | null {
    return OverlayScrollbarsLib.valid(this._osInstance) ? this._osInstance : null
  }

  private onScroll (event: UIEvent): void {
    this.$emit('scroll', { event, osInstance: this._osInstance })
  }
}
