









import { Component, Prop } from 'vue-property-decorator'
import { QueryRuleTextConfig } from '@/types/query-builder/rules'
import { RuleMixin } from './rule.mixin'

@Component
export default class TextRule extends RuleMixin<string> {
  @Prop({ required: true }) rule!: QueryRuleTextConfig
}
