import {
  DATASET_WIZARD_DATASET_TYPE,
  DATASET_WIZARD_TAB_ENTRYPOINT_ERRORS,
  DATASET_WIZARD_TAB_ENTRYPOINT_IS_EMPTY,
  DATASET_WIZARD_TAB_ENTRYPOINT_IS_ENABLED,
  DATASET_WIZARD_TAB_ENTRYPOINT_IS_INVALID,
  DATASET_WIZARD_TAB_ENTRYPOINT_STEP_ID
} from '@/store/getter-types'
import { DATASET_WIZARD_TAB_ENTRYPOINT_VALIDATE, DATASET_WIZARD_TAB_ENTRYPOINT_LEAVE } from '@/store/action-types'
import { DATASET_WIZARD_STEP_ENTRYPOINT } from '@/components/analytics/dataset-wizard/constants/steps'
import { i18n } from '@/locales/i18n'

// initial state
const state = {
  stepId: DATASET_WIZARD_STEP_ENTRYPOINT
}

// getters
const getters = {
  [DATASET_WIZARD_TAB_ENTRYPOINT_IS_ENABLED] () {
    return true
  },
  [DATASET_WIZARD_TAB_ENTRYPOINT_IS_EMPTY] (_state, getters) {
    return getters[DATASET_WIZARD_DATASET_TYPE] == null
  },
  [DATASET_WIZARD_TAB_ENTRYPOINT_IS_INVALID] (_state, getters) {
    return getters[DATASET_WIZARD_TAB_ENTRYPOINT_ERRORS].length > 0
  },
  [DATASET_WIZARD_TAB_ENTRYPOINT_ERRORS] (_state, getters) {
    const errors: string[] = []

    if (getters[DATASET_WIZARD_DATASET_TYPE] == null) {
      errors.push(i18n.t('analytics.new-dataset-wizard.errors.select-dataset-type') as string)
    }

    return errors
  },
  [DATASET_WIZARD_TAB_ENTRYPOINT_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [DATASET_WIZARD_TAB_ENTRYPOINT_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[DATASET_WIZARD_TAB_ENTRYPOINT_ERRORS])
  },
  [DATASET_WIZARD_TAB_ENTRYPOINT_LEAVE] () {
    // leave action
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
