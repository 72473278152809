






import GridActionsMixin from '../grid-actions.mixin'
import { Component } from 'vue-property-decorator'
import GridActionsMenu from '@/components/shared/ag-grid/components/actions/grid-actions-menu.vue'
import GridActionRemove from '@/components/shared/ag-grid/components/actions/grid-action-remove.vue'

@Component({
  components: { GridActionRemove, GridActionsMenu }
})
export default class RemoveRowAction extends GridActionsMixin {
  private removeSelectedRows (): void {
    this.callGridApi((gridApi) => {
      const selectedRowIndexes = gridApi.getSelectedNodes().map(node => +(node.id as string))
      const updatedValue = this.value.filter((_item, index) => !selectedRowIndexes.includes(index))

      this.updateValue(updatedValue)
    })
  }
}
