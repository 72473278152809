import { constantsApi } from '@/api/rest/constants/constants.api'
import { GOAL_TYPES, GOAL_TYPES_INITIALIZED } from '@/store/getter-types'
import { SET_GOAL_TYPES } from '@/store/mutation-types'
import { LOAD_GOAL_TYPES } from '@/store/action-types'

const state = {
  initialized: false,
  collection: []
}

// getters
const getters = {
  [GOAL_TYPES] (state) {
    return state.collection
  },
  [GOAL_TYPES_INITIALIZED] (state) {
    return state.initialized
  }
}

// actions
const actions = {
  [LOAD_GOAL_TYPES] ({ commit, getters }) {
    if (getters[GOAL_TYPES_INITIALIZED]) {
      return Promise.resolve(getters[GOAL_TYPES])
    } else {
      return constantsApi.getPlanningGoalType()
        .then(collection => {
          commit(SET_GOAL_TYPES, collection)

          return getters[GOAL_TYPES]
        })
    }
  }
}

// mutations
const mutations = {
  [SET_GOAL_TYPES] (state, payload) {
    if (Array.isArray(payload)) {
      state.collection = payload
      state.initialized = true
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
