import { Vue, Component, Prop } from 'vue-property-decorator'
import { ColorFormatHEX } from '@/types/color-data'

const presetColors = [
  '#8A360F', '#E3170D', '#FFA07A', '#E99936',
  '#FFFF00', '#7FFF00', '#308014', '#C645FF',
  '#7200FF', '#1E30FF', '#66CDAA', '#BDFCC9',
  '#000000', '#4F4F4F', '#778899', '#FFFFFF'
]

const presetModeValidator = val => ['full', 'color'].includes(val)

@Component
export class PickerPropsMixin extends Vue {
  @Prop({ default: () => presetColors }) presetColors!: ColorFormatHEX['hex'][]
  @Prop({ default: false }) disableAlpha!: boolean
  @Prop({ default: false }) disableFields!: boolean
  @Prop({ default: 'full', validator: presetModeValidator }) presetMode!: 'full' | 'color'
}
