import { ApiResponse } from '@/api/types'
import { OrderItemDTO } from '@/types/api'
import { OrderItem } from '@/types/orders'
import { parseTimeFrame } from '@/utils/booking-time'
import { createTransformResponse } from '@/api/utils'

export const transformOrderItemsResponse = createTransformResponse<OrderItem[]>((response: ApiResponse['data']): OrderItem[] => {
  const items: OrderItemDTO[] = JSON.parse(response)

  return items.map((item): OrderItem => {
    return {
      bookableUnitId: item.bookableUnitId,
      grossPrice: item.grossPrice,
      iwScore: item.iwScore,
      mediaOwnerId: item.mediaOwnerId,
      timeFrame: parseTimeFrame(item.timeFrame)
    }
  })
})
