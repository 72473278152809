





































import { Component, Mixins, Prop, Watch, Ref } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import MapView from '@/components/shared/map-view.vue'
import { LOAD_GEOMETRIES_COORDS } from '@/store/action-types'
import { GET_GEOMETRIES, HAS_ACTIVE_REQUEST } from '@/store/getter-types'
import MultiPolygons from '@/components/map/components/multi-polygons.vue'
import { getBoundForGeometries } from '@/utils/map'
import ControlSpinner from '@/components/map/controls/control-spinner.vue'
import { FIND_GEOMETRY } from '../constants'
import { surroundingGeometryFillColor, surroundingGeometryPathColor } from '@/assets/style-variables'
import MapContextMenu from '@/components/map/components/map-context-menu.vue'
import { GeometriesMapContextMenuMixin } from '../mixins/geometries-map-context-menu.mixin'
import LayerContextMenu from '@/components/map/components/layer-context-menu.vue'
import { GeometriesLayerContextMenuMixin } from '../mixins/geometries-layer-context-menu.mixin'
import MultiPinMarkers from '@/components/map/components/multi-pin-markers.vue'
import { Geometries, Geometry } from '@/types/common'
import { LatLng } from 'leaflet'
import { Color } from 'csstype'
import { LayerPoint } from '@/types/visualization/layer/geometry'
import { delay } from 'lodash'

type LabelTextFunc = (geometries: Geometries) => string | null

@Component({
  components: { MultiPinMarkers, LayerContextMenu, MapContextMenu, ControlSpinner, MapView, MultiPolygons },
  computed: {
    ...mapGetters({
      loadedGeometries: GET_GEOMETRIES,
      hasRequests: HAS_ACTIVE_REQUEST
    })
  },
  methods: {
    ...mapActions({
      loadGeometriesCoords: LOAD_GEOMETRIES_COORDS
    })
  }
})
export default class GeometriesMapPreview extends Mixins(GeometriesMapContextMenuMixin, GeometriesLayerContextMenuMixin) {
  @Prop({ default: () => [] }) private geometries!: Geometries
  @Prop({ default: () => null }) private selectedPoint!: null | LatLng
  @Ref('map') private mapRef!: MapView

  private isLoaded = false
  surroundingGeometryFillColor: Color = surroundingGeometryFillColor
  surroundingGeometryPathColor: Color = surroundingGeometryPathColor

  private loadedGeometries!: Geometries
  private hasRequests!: boolean

  private loadGeometriesCoords!: (geometries: Geometries) => Promise<void>

  private get labelText (): LabelTextFunc {
    return (items: Geometries) => items.length ? items[0].name : null
  }

  private get selectedMarkers (): Partial<LayerPoint>[] {
    if (!this.selectedPoint) {
      return []
    }

    return [{ latLng: this.selectedPoint }]
  }

  private get previewGroupFunc () {
    return (item: Geometry) => `${item.parentGeometrySetId}|${item.name}`
  }

  @Watch('geometries', { immediate: true })
  onGeometriesChange (updatedGeometries: Geometries): void {
    this.isLoaded = true
    this.loadGeometriesCoords(updatedGeometries).then(() => {
      updatedGeometries.length > 0 && this.fitBounds(this.geometriesToDisplay(updatedGeometries))
    }).catch((error) => {
      this.$toastError(error.message)
    }).finally(() => {
      this.isLoaded = false
    })
  }

  mounted () {
    this.$parent.$on(FIND_GEOMETRY, (geometries) => {
      this.fitBounds(this.geometriesToDisplay(geometries))
    })
  }

  destroy () {
    this.$parent.$off(FIND_GEOMETRY)
  }

  fitBounds (geometries) {
    const geometriesBounds = getBoundForGeometries(geometries)
    // Hack to avoid incorrect fitting to bounds
    delay(() => {
      this.mapRef.fitBounds(geometriesBounds)
    }, 100)
  }

  geometriesToDisplay (geometries) {
    return this.loadedGeometries
      .filter(item => Boolean(geometries.find(geometry => (geometry.id === item.id))))
  }
}
