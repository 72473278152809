import tinycolor, { ColorFormats, ColorInputWithoutInstance } from 'tinycolor2'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ColorFormatHEX, ColorFormatHSL, ColorInputData, ColorModel } from '@/types/color-data'
import { round } from 'lodash'

function isColorFormat<T extends ColorFormatHSL | ColorFormatHEX> (data: ColorInputData, format: string): data is T {
  return data && typeof data !== 'string' && format in data && !!data[format]
}

export function _colorChange (data: ColorInputData, oldHue?: ColorModel['oldHue']): ColorModel {
  const alpha = data && (data as ColorFormats.Alpha).a
  let color

  // hsl is better than hex between conversions
  if (isColorFormat<ColorFormatHSL>(data, 'hsl')) {
    color = tinycolor(data.hsl)
  } else if (isColorFormat<ColorFormatHEX>(data, 'hex')) {
    color = tinycolor(data.hex)
  } else {
    color = tinycolor(data as ColorInputWithoutInstance)
  }

  if (color && (color._a === undefined || color._a === null)) {
    color.setAlpha(alpha || 1)
  }

  const hsl = color.toHsl()
  const hsv = color.toHsv()

  if (hsl.s === 0) {
    const hue = (data as ColorFormats.HSL).h || (isColorFormat<ColorFormatHSL>(data, 'hsl') && data.hsl.h)
    hsv.h = hsl.h = hue || oldHue || 0
  }

  return {
    hsl: hsl,
    hex: color.toHexString().toUpperCase(),
    hex8: color.toHex8String().toUpperCase(),
    rgba: color.toRgb(),
    hsv: hsv,
    oldHue: (data as ColorFormats.HSL).h || oldHue || hsl.h,
    source: data.source,
    a: round((data as ColorFormats.Alpha).a || color.getAlpha(), 2)
  }
}

@Component
export class PickerMixin extends Vue {
  @Prop({ required: true }) value!: ColorFormatHEX['hex']

  protected startColor: ColorFormatHEX['hex'] = ''
  protected val: ColorModel = {} as ColorModel

  created () {
    this.startColor = this.value
    this.val = _colorChange(this.value)
  }

  protected get colorModel (): ColorModel {
    return this.val
  }

  protected set colorModel (newVal: ColorModel) {
    this.val = newVal
    this.$emit('input', newVal)
  }

  @Watch('value')
  protected onValueChange (newVal: ColorFormatHEX['hex']): void {
    this.val = _colorChange(newVal)
  }

  protected colorChange (data: ColorInputData, oldHue?: ColorModel['oldHue']): void {
    this.colorModel = _colorChange(data, oldHue || this.colorModel.hsl.h)
  }

  protected isValidHex (hex: ColorModel['hex']): boolean {
    return tinycolor(hex).isValid()
  }
}
