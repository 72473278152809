import { helpers } from 'vuelidate/lib/validators'
import { Params, ValidationRule } from '@/vendor/types/vuelidate/lib/validators'

export type UniqueCheckerFunc<T> = (value: T) => boolean
export type UniqueValues<T> = T[] | UniqueCheckerFunc<T>

export function unique<T> (values: UniqueValues<T>): ValidationRule {
  const params: Params = {
    type: 'unique'
  }

  const func = (value: T) => {
    if (!helpers.req(value)) {
      return true
    }

    if (typeof values === 'function') {
      return values(value)
    }

    return values.indexOf(value) === -1
  }

  return helpers.withParams(params, func)
}
