import { authApi } from '@/api/rest/auth/auth.api'

function routeParams (userNotAuthorized, to) {
  return userNotAuthorized
    ? {
      name: 'login',
      query: {
        return_url: to.fullPath
      }
    }
    : {}
}

export default function (to, _from, next) {
  if (to.meta.noAuth === true) {
    return next()
  }

  return authApi.authenticateStatus().then(() => {
    return next()
  }, (err) => {
    const params = routeParams(err.response.status === 401, to)
    return next(params)
  })
}
