





















import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { UnclearAssignmentVariant } from '@/types/planning/unclear-assignments'

@Component({
  components: { Draggable }
})
export default class PathList extends Vue {
  @Model('change', { required: true }) readonly value!: Partial<UnclearAssignmentVariant>[]
  @Prop({ default: false }) readonly disabled!: boolean

  private get innerValue (): Partial<UnclearAssignmentVariant>[] {
    return this.value
  }

  private set innerValue (newValue) {
    this.$emit('change', newValue)
  }
}
