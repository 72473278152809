import {
  CollectionItem,
  CollectionItemValue,
  FilterCollectionConfig,
  MediaOwnerNetworkObj
} from '@/types/planning/wizard/filter-editor'
import { BaseConfig } from '../base-config'
import { DatasetRow, GeometryBase } from '@/types/common'
import { ScoringElementOptions } from '@/types/planning/scoring/functions'

export abstract class AbstractCollectionConfig<T, C> extends BaseConfig<T[]> implements FilterCollectionConfig<T, C> {
  abstract get options (): Array<C>

  constructor (parameters: ScoringElementOptions | null) {
    super(parameters)
    this.initCollection()
  }

  abstract initCollection (): Promise<void>

  override formatValue (value: Array<T>): string {
    return this.options
      .filter(option => value.some((value) => this.isValueOptionEqual(value, option)))
      .map(this.formatCollectionOption)
      .join('; ')
  }

  abstract isValueOptionEqual (value: T, option: C): boolean

  abstract formatCollectionOption (option: C): string
}

export abstract class CollectionConfig<
    V extends CollectionItemValue = CollectionItemValue,
    I extends CollectionItem = CollectionItem
  >
  extends AbstractCollectionConfig<V, I> {
  abstract override initCollection (): Promise<void>

  isValueOptionEqual (value: V, option: I): boolean {
    return value === option.value
  }

  formatCollectionOption (option: I): string {
    return option.text
  }
}

export abstract class GeometryCollectionConfig<
  V extends GeometryBase = GeometryBase,
  I extends DatasetRow = DatasetRow
  > extends AbstractCollectionConfig<V, I> {
  abstract override initCollection (): Promise<void>

  isValueOptionEqual (value: V, option: I): boolean {
    return Object.values(option).includes(value.name)
  }

  formatCollectionOption (option: DatasetRow): string {
    return option.toString()
  }
}

export abstract class NetworksCollectionConfig<
  V extends MediaOwnerNetworkObj = MediaOwnerNetworkObj,
  I extends DatasetRow = DatasetRow
  > extends AbstractCollectionConfig<V, I> {
  abstract override initCollection (): Promise<void>

  isValueOptionEqual (value: V, option: I): boolean {
    return Object.values(option).includes(value.id)
  }

  formatCollectionOption (option: DatasetRow): string {
    return option.text as string
  }
}
