import { Component, Prop, Vue } from 'vue-property-decorator'
import { InteractiveLayerOptions } from 'leaflet'

@Component
export class InteractiveLayerMixin extends Vue {
  @Prop({ default: true }) interactive!: boolean
  @Prop({ default: true }) bubblingMouseEvents!: boolean

  protected get interactiveLayerOptions (): InteractiveLayerOptions {
    return {
      interactive: this.interactive,
      bubblingMouseEvents: this.bubblingMouseEvents
    }
  }
}
