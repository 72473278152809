







































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { GridReadyEvent } from 'ag-grid-community'
import { VueClassDef } from '@/types/vue'
import GridQuickFilter from './components/grid-quick-filter.vue'
import GridPagination from './components/grid-pagination.vue'
import GridActionsPanel from './components/grid-actions-panel.vue'
import { GridApiMixin } from './mixins/grid-api.mixin'
import { GridPropsMixin } from './mixins/grid-props.mixin'
import { GridOverlayMixin } from './mixins/grid-overlay.mixin'
import { GridScrollShadowMixin } from './mixins/grid-scroll-shadow.mixin'
import 'ag-grid-enterprise'

@Component({
  components: { GridActionsPanel, GridQuickFilter, GridPagination, AgGridVue }
})
export default class AgGrid extends Mixins(GridApiMixin, GridPropsMixin, GridOverlayMixin, GridScrollShadowMixin) {
  @Prop({ default: false }) large!: boolean

  private get showHeader (): boolean {
    return this.quickFilter || !!this.$slots.actions
  }

  private get showFooter (): boolean {
    return this.gridProps.pagination ?? false
  }

  private get gridClass (): VueClassDef {
    return {
      'ag-theme-stave': !this.large,
      'ag-theme-stave-lg': this.large
    }
  }

  private onGridReady (event: GridReadyEvent) {
    this.initGridApi(event)
  }

  private translateGridText (key: string, defaultValue: string): string {
    const translationKey = `common.ag-grid.${key}`

    return this.$te(translationKey) ? this.$t(translationKey) as string : defaultValue
  }
}
