import { BaseConfig } from '../base-config'

export class NumberConfig extends BaseConfig<number> {
  protected override getDefaultValue (): number {
    return super.getDefaultValue() ?? 0
  }

  protected override setDefaultValue (value: number): void {
    super.setDefaultValue(parseFloat(value as unknown as string))
  }
}
