import { ScoringDetailsObject } from '@/types/planning'

export class UnclearAssignmentError extends Error {
  public readonly scoreDetails!: ScoringDetailsObject

  constructor (message, scoreDetails: ScoringDetailsObject) {
    super(message)

    this.name = 'UnclearAssignmentError'
    this.scoreDetails = scoreDetails
  }
}
