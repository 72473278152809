import { QueryBuilderGroup, QueryBuilderRule, QueryGroup, QueryRule } from '@/types/query-builder'
import { GroupOperator } from '@/types/query-builder/enums'
import { logger } from '@/utils/log'

type DeprecatedRuleItem = {
  type: 'query-builder-rule';
  query: QueryRule;
}

type DeprecatedGroupItem = {
  type: 'query-builder-group';
  // eslint-disable-next-line no-use-before-define
  query: DeprecatedGroup;
}

type DeprecatedGroup = {
  logicalOperator: GroupOperator;
  children?: (DeprecatedRuleItem | DeprecatedGroupItem)[];
}

function convertGroupItem (group: DeprecatedGroupItem): QueryBuilderGroup {
  return {
    type: 'group',
    query: convertDeprecatedGroup(group.query)
  }
}

function convertRuleItem (rule: DeprecatedRuleItem): QueryBuilderRule {
  return {
    type: 'rule',
    query: rule.query
  }
}

function convertDeprecatedGroup (group: DeprecatedGroup): QueryGroup {
  return {
    operator: group.logicalOperator,
    children: (group.children ?? []).map((child) => {
      if (child.type === 'query-builder-group') {
        return convertGroupItem(child)
      }

      return convertRuleItem(child)
    })
  }
}

export function convertDeprecatedQuery (query: DeprecatedGroup | QueryGroup): QueryGroup {
  if ('logicalOperator' in query) {
    logger.debug('Found deprecated query builder syntax.', query)
    return convertDeprecatedGroup(query)
  }

  return query
}
