import { ApiRequestConfig } from '@/api/types'
import { ObjectId } from '@/types/common'
import { PlanObject } from '@/types/planning'
import { ApiClient } from '@/api/api-client'
import { transformSinglePlanResponse } from './transform-response/get-single-plan-response.transform'
import { transformPlanRequest } from '../scoring/transform-request/scoring-locations-request.transform'
import { CampaignRequestBody } from '@/types/store/campaign'

class PlanningApi extends ApiClient {
  searchPlans (query: string): Promise<PlanObject[]> {
    return this.post<PlanObject[]>(`search/*${query}*`)
  }

  getPlan (id: ObjectId, blockingRequest = false): Promise<PlanObject> {
    const config: ApiRequestConfig = {
      transformResponse: transformSinglePlanResponse,
      blockingRequest
    }

    return this.get<PlanObject>(id, config)
  }

  storePlan (plan: Omit<CampaignRequestBody, 'id'>): Promise<PlanObject> {
    const config: ApiRequestConfig = {
      transformRequest: transformPlanRequest,
      transformResponse: transformSinglePlanResponse,
      blockingRequest: true
    }

    return this.post<PlanObject>('', plan, config)
  }

  updatePlan (plan: PlanObject): Promise<void> {
    const config: ApiRequestConfig = {
      transformRequest: transformPlanRequest,
      blockingRequest: true
    } as ApiRequestConfig

    return this.put('', plan, config)
  }

  removePlan (id: ObjectId): Promise<void> {
    return this.delete(id)
  }
}

export const planningApi = new PlanningApi({
  baseURL: API_CONF.api + '/api/Persistence/Planning/'
})
