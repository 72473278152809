














































import { Vue, Component, Prop } from 'vue-property-decorator'
import AgGrid from '@/components/shared/ag-grid/ag-grid.vue'
import { ColDef } from 'ag-grid-community'
import { EditableGridFieldSettings } from '@/plugins/enter-bookable-units/fileds-settings/fields-settings'
import Disclaimer from '@/components/shared/disclaimer/disclaimer.vue'

// TODO - probably to create a custom reusable 'EditableGrid' component from it
@Component({
  components: { Disclaimer, AgGrid }
})
export default class EditableBookableUnitsGrid extends Vue {
  @Prop({ required: true }) readonly value!: Record<string, unknown>[];
  @Prop({ required: true }) readonly fields!: Record<string, EditableGridFieldSettings>

  private defaultColDef: ColDef = {
    sortable: true,
    editable: true,
    resizable: false,
    flex: 1,
    minWidth: 100,
    filter: false
  }

  private get rowClassRules () {
    return {
      'bg-success': params => this.mandatoryFields.every(field => !!params.data[field]),
      'bg-warning': params => this.mandatoryFields.some(field => !params.data[field])
    }
  }

  private get rows () {
    return this.value
  }

  private set rows (rows) {
    this.$emit('change', rows)
  }

  private get mandatoryFields (): string[] {
    return Object.keys(this.fields).filter(key => this.fields[key].required)
  }

  private get columnDefs (): ColDef[] {
    return Object.keys(this.fields).map((column) => ({
      headerName: column,
      field: column,
      headerClass: this.mandatoryFields.includes(column) ? 'text-dark' : 'text-black-50',
      valueGetter: (params) => params.data[column],
      type: this.fields[column].type
    }))
  }

  public getValidRows (): Record<string, unknown>[] {
    return this.value.filter(row => this.mandatoryFields.every(field => !!row[field]))
  }
}
