import Vue, { VNode, VNodeChildren } from 'vue'
import { BvModalEvent } from 'bootstrap-vue'
import { nanoid } from 'nanoid'
import { MsgBoxConfirmValidSlots, MsgBoxProps, MsgBoxResolver, MsgBoxSlots, ValidatableModalComponent } from './types'
import { makeMsgBox } from './dialogs/msg-box'
import { ConfirmResult, msgBoxConfirm } from './dialogs/msg-box-confirm'
import { ConfirmSaveResult, msgBoxConfirmSave } from './dialogs/msg-box-confirm-save'

type ModalCloseListener = (e: BvModalEvent, modalId: string) => Promise<void>;

function validateOnCloseResolver (e: BvModalEvent): boolean {
  return e.trigger === 'event'
}

export class ModalDialog {
  private readonly _vm: Vue;

  constructor (vm: Vue) {
    this._vm = vm
  }

  msgBoxCustom<T> (resolver: MsgBoxResolver<T>, props: MsgBoxProps = {}, slots: MsgBoxSlots = {}): Promise<T> {
    return makeMsgBox<T>(this._vm, '', resolver, props, slots)
  }

  msgBoxConfirm (message: VNodeChildren, props: MsgBoxProps = {}, slots: MsgBoxSlots = {}): Promise<ConfirmResult> {
    return msgBoxConfirm(this._vm, message, props, slots)
  }

  msgBoxConfirmSave (message, props: MsgBoxProps = {}, slots: MsgBoxSlots = {}): Promise<ConfirmSaveResult> {
    return msgBoxConfirmSave(this._vm, message, props, slots)
  }

  msgBoxConfirmValid (slots: MsgBoxConfirmValidSlots, props: MsgBoxProps = {}): Promise<boolean> {
    const modalId = (props.id as string) ?? nanoid(8)
    const modalProps: MsgBoxProps = {
      ...props,
      id: modalId
    }

    const closeListener = this.makeValidateOnCloseListener(modalId, slots.default)

    this._vm.$root.$on('bv::modal::hide', closeListener)

    return this.msgBoxCustom<boolean>(validateOnCloseResolver, modalProps, slots)
  }

  private makeValidateOnCloseListener (modalId: string, innerVNode: VNode): ModalCloseListener {
    const root = this._vm.$root

    const closeListener: ModalCloseListener = async (e: BvModalEvent, eventModalId: string): Promise<void> => {
      if (eventModalId !== modalId) {
        return
      }

      if (e.trigger !== 'ok') {
        root.$off('bv::modal::hide', closeListener)
        return
      }

      e.preventDefault()

      const innerComp = innerVNode.componentInstance as ValidatableModalComponent
      const isValid = await innerComp.validateModal()

      if (isValid) {
        root.$off('bv::modal::hide', closeListener)
        root.$emit('bv::hide::modal', modalId)
      }
    }

    return closeListener
  }
}
