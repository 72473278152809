import { createDecorator, VueDecorator } from 'vue-class-component'
import Vue, { ComponentOptions } from 'vue'
import { LocaleMessages } from 'vue-i18n'

export function LocalTranslations (translations: LocaleMessages): VueDecorator {
  return createDecorator((options: ComponentOptions<Vue>): void => {
    options.i18n = {
      messages: translations
    }
  })
}
