


























import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { clone, unionWith } from 'lodash'
import { minLength, required } from 'vuelidate/lib/validators'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { FilterUIComponent } from '@/types/planning/wizard/filter-editor'
import { Validations } from '@/decorators'
import { ColDef } from 'ag-grid-community'
import { PairListConfig } from '@/utils/filter-editor/value-config/list/pair-list-config'
import { freezeRecursive } from '@/utils/object'
import { UiControlMixin } from '../mixins/ui-control.mixin'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
import { ListPair } from '@/types/planning/wizard/value-types'
import PairsActionsCell from '@/components/planning/wizard/filter-editor/ui-controls/components/pairs-actions-cell.vue'
import TriggerRankingImportProcess from '@/components/planning/wizard/filter-editor/ui-controls/pairs-ui-element/components/trigger-ranking-import-process/trigger-ranking-import-process.vue'
import AgGrid from '@/components/shared/ag-grid/ag-grid.vue'
import { LocalTranslations } from '@/decorators/local-translations'
import messages from './locales/locales'

@Component({
  components: { TriggerRankingImportProcess, AgGrid, FilterParamName }
})
@LocalTranslations(messages)
export default class PairsUiElement extends Mixins<UiControlMixin<ListPair[], PairListConfig>>(UiControlMixin) implements FilterUIComponent<ListPair[]> {
  @Prop({ required: true }) readonly declare value: ListPair[]

  // @ts-ignore - TS-2729 value already initialized here as Vue uses its own class initialization logic
  selected: ListPair[] = freezeRecursive(this.value ? clone(this.value) : [])

  @Validations()
  private validations (): RuleDecl {
    return {
      selected: { required, minLength: minLength(1) }
    }
  }

  private get columnDefs (): ColDef[] {
    return [
      { field: 'key', headerName: this.$t('plz') as string, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
      { field: 'value', headerName: this.$t('rating') as string, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
      {
        field: 'actions',
        headerName: '',
        headerClass: 'fas fa-trash-alt',
        width: 36,
        cellRendererFramework: PairsActionsCell,
        sortable: false
      }
    ].map(def => ({ ...def, menuTabs: ['filterMenuTab'] }))
  }

  @Watch('selected')
  private onSelectedChange (value: ListPair[]): void {
    if (value.length) {
      this.$emit('change', value)
    }
  }

  private onImportFinished (pairsToAdd: ListPair[]): void {
    const pairComparator = (a: ListPair, b: ListPair) => a.key === b.key && a.value === b.value
    this.setSelected(freezeRecursive(unionWith(this.selected, pairsToAdd, pairComparator)))
  }
}
