















import { Component, Prop, Vue } from 'vue-property-decorator'
import { GeocodingState } from '@/components/shared/coordinates-service/types/enum'
import { TranslateResult } from 'vue-i18n'

@Component
export default class GeocodingButton extends Vue {
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: null }) geocodeStatus!: GeocodingState
  @Prop({ required: true }) startCallback!: () => void
  @Prop({ required: true }) cancelCallback!: () => void
  @Prop({ required: true }) resetCallback!: () => void

  get showSpinner (): boolean {
    return this.geocodeStatus === GeocodingState.inProgress
  }

  get buttonTitle (): TranslateResult {
    switch (this.geocodeStatus) {
      case GeocodingState.inProgress:
        return this.$t('common.buttons.cancel')
      case GeocodingState.finished:
        return this.$t('common.coordinates-service.buttons.reset')
      default:
        return this.$t('common.coordinates-service.buttons.geocode')
    }
  }

  get buttonVariant (): string {
    switch (this.geocodeStatus) {
      case GeocodingState.inProgress:
        return 'danger'
      case GeocodingState.finished:
        return 'secondary'
      default:
        return 'success'
    }
  }

  get clickCallback (): () => void {
    switch (this.geocodeStatus) {
      case GeocodingState.inProgress:
        return this.cancelCallback
      case GeocodingState.finished:
        return this.resetCallback
      default:
        return this.startCallback
    }
  }
}
