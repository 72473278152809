import { Routes } from '@/router/constants/routes'
import { AuthPermission } from '@/types/auth/permissions'
import { i18n } from '@/locales/i18n'
import { UniqueRouteConfig } from '@/router/types'
const OrdersDashboard = () => import('@/views/orders/children/Orders-dashboard.vue')
const OrderDetails = () => import('@/views/orders/children/Order-details.vue')

export const ordersRoutes: Array<UniqueRouteConfig> = [
  {
    path: '',
    name: Routes.ORDERS_DASHBOARD,
    component: OrdersDashboard,
    props: true,
    meta: {
      routeId: Routes.ORDERS_DASHBOARD,
      get name () { return i18n.t('common.labels.orders') },
      can: AuthPermission.ViewOrders
    }
  },
  {
    path: ':id',
    name: Routes.ORDER_DETAILS,
    component: OrderDetails,
    props: true,
    meta: {
      routeId: Routes.ORDER_DETAILS,
      can: AuthPermission.ViewOrders
    }
  }
]
