import { isEmpty, uniqWith } from 'lodash'
import { nanoid } from 'nanoid'
import moment from '@/lib/moment'
import { Moment } from 'moment/moment'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'

export function transformTimeFrameEndRequest (date: Date): Moment {
  return moment(date).add({ day: 1 })
}

export function transformTimeFrameEndResponse (date: Date): Moment {
  return moment(date).subtract({ day: 1 })
}

export function generateFilterId (): string {
  return nanoid(6)
}

export function checkFiltersValidity (filters: ScoringFunctionNode[]): boolean {
  return filters.every(node => {
    return !isEmpty(node.name) && !isEmpty(node.scoringFunction.data)
  })
}

export function checkFilterNamesUnique (filters: ScoringFunctionNode[]): boolean {
  const uniqueNodes = uniqWith(filters, function (nodeA, nodeB) {
    return nodeA.name === nodeB.name && nodeA.areaId === nodeB.areaId
  })

  return uniqueNodes.length === filters.length
}

export function makeUniqueNameChecker (filters: ScoringFunctionNode[]): (name: string, filterId: string) => boolean {
  return (name, filterId) => {
    if (isEmpty(name)) {
      return true
    }

    return !filters.some(node => node.name === name && node.id !== filterId)
  }
}
