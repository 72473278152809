import Vue from 'vue'
import Vuex from 'vuex'
import customersStore from './customers/customers.store'
import campaignStore from './campaign/campaign.store'
import scoringStore from './scoring/scoring.store'
import collectionsStore from '@/store/collections/collections.store'
import authStore from '@/store/auth/auth.store'
import authPlugins from '@/store/auth/plugins/auth.plugins'
import userStore from '@/store/user/user.store'
import userPlugins from '@/store/user/user.plugins'
import visualizationStore from './visualizations/visualization.store'
import visualizationsPlugins from './visualizations/visualization.plugins'
import decadesStore from './decades/decades.store'
import requestsStore from './requests/requests.store'
import bookingTimeStore from './booking-time/booking-time.store'
import planningWizardStore from './planning-wizard'
import planningWizardPlugins from './planning-wizard/planning-wizard.plugins'
import layerWizardPlugins from './layer-wizard/layer-wizard.plugins'
import layerWizardStore from './layer-wizard'
import vicinityImportStore from './vicinity-import-wizard'
import geometriesCacheStore from './geometries-cache/geometries-cache.store'
import newVisualizationStore from './new-visualization-wizard'
import ordersStore from './orders/orders.store'
import visualizationLocationStore from './visualization-location/visualization-location.store'
import analyticsStore from './analytics/analytics.store'
import datasetWizardStore from './dataset-wizard'
import rankingImportStore from './ranking-import-wizard'

Vue.use(Vuex)

export const Store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    customers: customersStore,
    campaign: campaignStore,
    scoring: scoringStore,
    collections: collectionsStore,
    auth: authStore,
    user: userStore,
    visualization: visualizationStore,
    decades: decadesStore,
    requests: requestsStore,
    bookingTime: bookingTimeStore,
    planningWizard: planningWizardStore,
    vicinityImport: vicinityImportStore,
    layerWizard: layerWizardStore,
    geometriesCache: geometriesCacheStore,
    newVisualization: newVisualizationStore,
    orders: ordersStore,
    visualisationLocation: visualizationLocationStore,
    analytics: analyticsStore,
    datasetWizard: datasetWizardStore,
    rankingImportWizard: rankingImportStore
  },
  plugins: [
    ...authPlugins,
    ...userPlugins,
    ...visualizationsPlugins,
    ...planningWizardPlugins,
    ...layerWizardPlugins
  ]
})
