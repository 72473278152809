import { Order, OrderItem } from '@/types/orders'

import {
  SET_ACTIVE_ORDER,
  SET_ACTIVE_ORDER_DETAILS_VISIBLE,
  SET_ACTIVE_ORDER_ITEMS,
  SET_ACTIVE_ORDER_ITEMS_VISIBLE,
  SET_DRAGGED_ORDER,
  SET_ORDERS,
  SET_ORDERS_CACHE,
  UPDATE_ACTIVE_ORDER_DATA
} from '@/store/mutation-types'

// mutations
export const mutations = {
  [SET_ORDERS] (state, orders: Order[]) {
    if (Array.isArray(orders)) {
      state.orders = orders
      state.initialized = true
    }
  },
  [SET_ORDERS_CACHE] (state, orders: Order[]) {
    state.cachedOrders = orders
  },
  [SET_DRAGGED_ORDER] (state, order: Order) {
    state.draggedOrder = order
  },
  [SET_ACTIVE_ORDER] (state, order: Order) {
    state.activeOrder = order
  },
  [SET_ACTIVE_ORDER_ITEMS] (state, items: OrderItem[]) {
    state.activeItems = items
  },
  [UPDATE_ACTIVE_ORDER_DATA] (state, order: Partial<Order>) {
    state.activeOrder = {
      ...state.activeOrder,
      ...order
    }
  },
  [SET_ACTIVE_ORDER_DETAILS_VISIBLE] (state, visible: boolean) {
    state.detailsVisible = visible
  },
  [SET_ACTIVE_ORDER_ITEMS_VISIBLE] (state, visible: boolean) {
    state.itemsVisible = visible
  }
}
