import { Component, Vue } from 'vue-property-decorator'
import { ContextMenuCallback, ContextMenuItem } from '@/types/leaflet'

@Component
export class GeometriesLayerContextMenuMixin extends Vue {
  protected get layerContextMenuItems (): ContextMenuItem[] {
    return [{
      text: this.$t('visualization.new-layer-modal.labels.remove-geometry') as string,
      callback: this.emitRemoveGeometry as unknown as ContextMenuCallback
    }]
  }

  protected emitRemoveGeometry ({ item }):void {
    this.$emit('remove-geometry', item)
  }
}
