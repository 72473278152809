
































import WizardStepper from '@/components/shared/wizard/wizard-stepper/wizard-stepper.vue'
import WizardTab from '@/components/shared/wizard/wizard-tab/wizard-tab.vue'
import {
  WIZARD_NAVIGATION_START_INDEX,
  WIZARD_NAVIGATION_VISIBLE_STEPS
} from '@/store/getter-types'
import { WizardContainerPropsMixin } from '../mixins/wizard-container-props.mixin'
import WizardStepsNavigatorMixin from '../mixins/wizard-steps-navigator.mixin'
import { WIZ_TABS_POS_BOTTOM, WIZ_TABS_POS_LEFT, WIZ_TABS_POS_RIGHT, WIZ_TABS_POS_TOP } from '../constants'
import { Component, Mixins } from 'vue-property-decorator'
import { WizardSteps } from '@/types/wizard'
import { VueClassDef } from '@/types/vue'

@Component({
  components: { WizardTab, WizardStepper }
})
export default class WizardContainer extends Mixins<WizardContainerPropsMixin, WizardStepsNavigatorMixin>(WizardContainerPropsMixin, WizardStepsNavigatorMixin) {
  protected get steps (): WizardSteps {
    return this.dynamicStoreGetter(WIZARD_NAVIGATION_VISIBLE_STEPS)
  }

  protected get startIndex (): number {
    return this.dynamicStoreGetter(WIZARD_NAVIGATION_START_INDEX)
  }

  protected get containerClass (): VueClassDef {
    return {
      'tabs-left': this.showTabs && this.tabsPosition === WIZ_TABS_POS_LEFT,
      'tabs-top': this.showTabs && this.tabsPosition === WIZ_TABS_POS_TOP,
      'tabs-right': this.showTabs && this.tabsPosition === WIZ_TABS_POS_RIGHT,
      'tabs-bottom': this.showTabs && this.tabsPosition === WIZ_TABS_POS_BOTTOM
    }
  }

  mounted (): void {
    this.navigateToStep(this.startIndex)
  }
}
