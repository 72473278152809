import { FilterFunction, QueryGroup } from '@/types/query-builder'
import { isQueryBuilderGroup } from '@/types/query-builder/guards'
import { GroupOperator } from '@/types/query-builder/enums'
import { ComparisonImpl } from './comparison-impl'
import { GroupLogicImpl } from './group-logic-impl'

export function getQueryFilterFunction (query: QueryGroup): FilterFunction {
  const operator = query.operator ?? GroupOperator.AND
  const children = query?.children ?? []

  // each child in a group produces a high-order filter function(s)
  const functions: FilterFunction[] = children.map((child) => {
    if (isQueryBuilderGroup(child)) {
      return getQueryFilterFunction(child.query)
    }

    return (row) => ComparisonImpl[child.query.operator](row[child.query.rule], child.query.value)
  })

  if (!functions.length) {
    // Add default filter that just checks if row exists.
    functions.push((row) => Boolean(row))
  }

  return GroupLogicImpl[operator](functions)
}
