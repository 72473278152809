import { getters } from '@/store/auth/auth-getters'
import { actions } from '@/store/auth/auth-actions'
import { mutations } from '@/store/auth/auth-mutations'
import { AuthStoreState } from '@/store/auth/types/auth.store'

const state: AuthStoreState = {
  token: null,
  tempToken: null
}

export default {
  state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
