import { Component, Mixins, Prop } from 'vue-property-decorator'
import { FilterRangeConfig, FilterRangeTypes } from '@/types/planning/wizard/filter-editor'
import { UiControlMixin } from './ui-control.mixin'
import { MarksProp, TooltipFormatterFunc } from 'vue-slider-component/typings/typings'

@Component
// @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
export class SliderUiMixin<T extends FilterRangeTypes> extends Mixins<UiControlMixin<T, FilterRangeConfig<T>>>(UiControlMixin) {
  @Prop({ required: true }) readonly declare value: T

  protected get sliderMarkers (): MarksProp {
    if (!this.config.displayMarkers) {
      return false
    }

    return {
      [this.config.rangeMin]: { label: this.config.formatter(this.config.rangeMin), labelStyle: { left: '0', transform: 'none', fontSize: '1rem' } },
      [this.config.rangeMax]: { label: this.config.formatter(this.config.rangeMax), labelStyle: { right: '0', left: 'auto', transform: 'none', fontSize: '1rem' } }
    }
  }

  protected get tooltipFormatter (): TooltipFormatterFunc {
    return (value: number | string) => this.config.formatter(value as number)
  }

  protected sliderChange (value: T): void {
    this.$emit('change', value)
  }
}
