import { FilterSelectConfig, SelectOption, SelectValue } from '@/types/planning/wizard/filter-editor'
import { BaseConfig } from '../base-config'

export abstract class SelectionOptionsConfig<T extends SelectValue> extends BaseConfig<T> implements FilterSelectConfig<T> {
  protected override setDefaultValue (value: T): void {
    const values = this.options.map(option => option.value)

    if (!values.includes(value)) {
      value = this.getDefaultValue()
    }

    super.setDefaultValue(value)
  }

  override formatValue (value: T): string {
    return this.options
      .find(option => option.value === value)
      ?.text ?? ''
  }

  get options (): SelectOption[] {
    return []
  }
}
