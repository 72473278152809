import { PluginObject } from 'vue'
import TablePicker from './table-picker.vue'
import { ExcelRow } from '@/types/common'

type PickTableResult = {
  submitted: boolean;
  rows?: ExcelRow[];
}

type PickTableFunction = () => Promise<PickTableResult>;

declare module 'vue/types/vue' {
  interface Vue {
    $pickTable: PickTableFunction;
  }
}

export class TablePickerPlugin implements PluginObject<never> {
  install (Vue) {
    Vue.prototype.$pickTable = async function (): Promise<PickTableResult> {
      const pickerVNode = this.$createElement(TablePicker)

      const dialogProps = {
        size: 'md',
        scrollable: true,
        centered: true
      }

      const dialogSlots = {
        'modal-title': this.$t('visualization.layer-settings-modal.labels.upload-table')
      }

      return this.$modalDialog.msgBoxConfirm(pickerVNode, dialogProps, dialogSlots)
        .then((submitted): PickTableResult => ({
          submitted,
          rows: pickerVNode.componentInstance.fileData
        }))
    }
  }
}
