import { HatchingConfig } from '@/types/visualization/layer/style'
import { Point } from 'leaflet'
import {
  HATCHING_TYPE

} from '@/components/visualization/layer-settings/constants'

export class HatchingFactory {
  static createHatchingBlock (hatching: HatchingConfig): AbstractHatching | null {
    switch (hatching.type) {
      case HATCHING_TYPE.HORIZONTAL:
        return new HorizontalHatching(hatching)
      case HATCHING_TYPE.BACKWARD_DIAGONAL:
        return new BackwardDiagonalHatching(hatching)
      case HATCHING_TYPE.VERTICAL:
        return new VerticalHatching(hatching)
      case HATCHING_TYPE.FORWARD_DIAGONAL:
        return new ForwardDiagonalHatching(hatching)
      default:
        return null
    }
  }
}

export class AbstractHatching {
  protected _hatchingCanvas: HTMLCanvasElement;
  protected _hatchingContext;
  protected _correction;
  protected _hatchingSize;

  constructor (hatching: HatchingConfig) {
    this._hatchingSize = hatching.density

    this._hatchingCanvas = document.createElement('canvas')
    this._hatchingCanvas.width = this._hatchingCanvas.height = this._hatchingSize

    this._hatchingContext = this._hatchingCanvas.getContext('2d')
    this._hatchingContext.fillStyle = hatching.lineColor
    this._hatchingContext.strokeStyle = hatching.lineColor
    this._hatchingContext.lineWidth = hatching.lineWidth
    this._hatchingContext.globalAlpha = hatching.lineOpacity
    this._correction = Math.sqrt(2 * ((+hatching.lineWidth / 2) ** 2))
  }

  getHatching () {
    return this._hatchingCanvas
  }

  protected drawLine (p1: Point, p2: Point) {
    this._hatchingContext.beginPath()
    this._hatchingContext.moveTo(p1.x, p1.y)
    this._hatchingContext.lineTo(p2.x, p2.y)
    this._hatchingContext.closePath()
    this._hatchingContext.stroke()
  }

  protected fillCorner (p1: Point, p2: Point, p3: Point): void {
    this._hatchingContext.beginPath()
    this._hatchingContext.moveTo(p1.x, p1.y)
    this._hatchingContext.lineTo(p2.x, p2.y)
    this._hatchingContext.lineTo(p3.x, p3.y)
    this._hatchingContext.closePath()
    this._hatchingContext.fill()
  }
}

export class HorizontalHatching extends AbstractHatching {
  constructor (hatching: HatchingConfig) {
    super(hatching)
    super.drawLine(
      new Point(0, this._hatchingContext.lineWidth / 2),
      new Point(this._hatchingSize, this._hatchingContext.lineWidth / 2)
    )
  }
}

export class BackwardDiagonalHatching extends AbstractHatching {
  constructor (hatching: HatchingConfig) {
    super(hatching)
    this.drawLine(
      new Point(0, this._hatchingSize),
      new Point(this._hatchingSize, 0)
    )
  }

  protected override drawLine (p1: Point, p2: Point) {
    super.drawLine(p1, p2)
    // draw triangle in the upper left corner
    super.fillCorner(
      new Point(0, 0),
      new Point(0, this._correction),
      new Point(this._correction, 0)
    )
    // draw triangle in the bottom right corner
    super.fillCorner(
      new Point(this._hatchingSize, this._hatchingSize),
      new Point(this._hatchingSize - this._correction, this._hatchingSize),
      new Point(this._hatchingSize, this._hatchingSize - this._correction)
    )
  }
}

export class VerticalHatching extends AbstractHatching {
  constructor (hatching: HatchingConfig) {
    super(hatching)
    super.drawLine(
      new Point(this._hatchingContext.lineWidth / 2, 0),
      new Point(this._hatchingContext.lineWidth / 2, this._hatchingSize)
    )
  }
}

export class ForwardDiagonalHatching extends AbstractHatching {
  constructor (hatching: HatchingConfig) {
    super(hatching)
    this.drawLine(
      new Point(0, 0),
      new Point(this._hatchingSize, this._hatchingSize)
    )
  }

  protected override drawLine (p1: Point, p2: Point) {
    super.drawLine(p1, p2)
    // draw triangle in the upper right corner
    super.fillCorner(
      new Point(0, this._hatchingSize),
      new Point(0, this._hatchingSize - this._correction),
      new Point(this._correction, this._hatchingSize)
    )
    // draw triangle in the bottom left corner
    super.fillCorner(
      new Point(this._hatchingSize - this._correction, 0),
      new Point(this._hatchingSize, 0),
      new Point(this._hatchingSize, this._correction)
    )
  }
}
