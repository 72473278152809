import { ApiResponse } from '@/api/types'

export function successCallback<T> (response: ApiResponse<T>): T {
  return response.data
}

export function errorCallback (error) {
  // TODO: fix toastOnError, it is lost when axios merges default config
  // if (isApiError(error) && error.config.toastOnError) {
  //   Vue.$toastError(error.message)
  // }

  return Promise.reject(error)
}
