import { i18n } from '@/locales/i18n'
import { LAYER_WIZARD_STEP_PROPS } from '@/components/visualization/layer-wizard/constants/steps'
import { LayerType } from '@/types/visualization/layer/enums'
import {
  LAYER_WIZARD_LAYER_NAME,
  LAYER_WIZARD_LAYER_TYPE,
  LAYER_WIZARD_LINK_NAMESPACE,
  LAYER_WIZARD_STEP_PROPS_WAS_UPDATED,
  LAYER_WIZARD_TAB_PROPS_ERRORS,
  LAYER_WIZARD_TAB_PROPS_IS_EMPTY,
  LAYER_WIZARD_TAB_PROPS_IS_ENABLED,
  LAYER_WIZARD_TAB_PROPS_IS_HIDDEN,
  LAYER_WIZARD_TAB_PROPS_IS_INVALID,
  LAYER_WIZARD_TAB_PROPS_STEP_ID,
  WIZARD_NAVIGATION_ACTIVE_ID,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID
} from '@/store/getter-types'
import {
  LAYER_WIZARD_GEOMETRY_VALIDATE,
  LAYER_WIZARD_POINTS_VALIDATE,
  LAYER_WIZARD_TAB_PROPS_LEAVE,
  LAYER_WIZARD_TAB_PROPS_VALIDATE,
  WIZARD_INVALIDATE_AFTER
} from '@/store/action-types'
import { LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE } from '@/store/mutation-types'

const LAYER_TYPE_VALIDATORS = {
  [LayerType.Points]: LAYER_WIZARD_POINTS_VALIDATE,
  [LayerType.Shapes]: LAYER_WIZARD_GEOMETRY_VALIDATE
}

// initial state
const state = {
  stepId: LAYER_WIZARD_STEP_PROPS
}

// getters
const getters = {
  [LAYER_WIZARD_TAB_PROPS_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId)
  },
  [LAYER_WIZARD_TAB_PROPS_IS_EMPTY] () {
    return false
  },
  [LAYER_WIZARD_TAB_PROPS_IS_HIDDEN] () {
    return false
  },
  [LAYER_WIZARD_TAB_PROPS_IS_INVALID] (state, getters) {
    return getters[LAYER_WIZARD_TAB_PROPS_ERRORS].length > 0
  },
  [LAYER_WIZARD_TAB_PROPS_ERRORS] (state, getters, rootState, rootGetters) {
    const errors = []

    if (rootGetters[LAYER_WIZARD_LAYER_NAME] == null || rootGetters[LAYER_WIZARD_LAYER_NAME].trim().length === 0) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.empty-layer-name'))
    }

    return errors
  },
  [LAYER_WIZARD_TAB_PROPS_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  async [LAYER_WIZARD_TAB_PROPS_VALIDATE] ({ dispatch, getters }) {
    let errors = getters[LAYER_WIZARD_TAB_PROPS_ERRORS]

    const layerType = getters[LAYER_WIZARD_LAYER_TYPE]
    const layerTypeValidator = LAYER_TYPE_VALIDATORS[layerType]

    if (layerTypeValidator) {
      errors = errors.concat(await dispatch(layerTypeValidator))
    }

    return errors
  },
  [LAYER_WIZARD_TAB_PROPS_LEAVE] ({ dispatch, commit, getters }) {
    const activeStepId = getters[getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_ACTIVE_ID)]

    if (getters[LAYER_WIZARD_STEP_PROPS_WAS_UPDATED] && activeStepId === getters[LAYER_WIZARD_TAB_PROPS_STEP_ID]) {
      const namespacedAction = getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_INVALIDATE_AFTER)
      dispatch(namespacedAction)
      commit(LAYER_WIZARD_STEP_PROPS_SET_UPDATE_STATE, false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
