export enum RangeType {
  Radius = 'Radius',
  Distance = 'Distance',
  Time = 'Time'
}

export enum AssignmentMode {
  Location = 'location',
  Filter = 'filter'
}

export enum CitiesSelectorGridMode {
  AllCities = 'all',
  SelectedCities = 'selected'
}

export enum PpsFilterModes {
  Relative,
  Absolute
}
