import {
  FILTER_AREA, FILTER_AVAILABILITY, FILTER_BANNED_PRODUCTS, FILTER_BUILD_TYPE, FILTER_CITY, FILTER_CLUSTERING,
  FILTER_CPT, FILTER_EXCLUDE_MEDIA_OWNER, FILTER_EXCLUSION, FILTER_ILLUMINATION, FILTER_INCLUDE_MEDIA_OWNER,
  FILTER_INCLUSION, FILTER_ISOLATION, FILTER_MEDIA_CRITERIA, FILTER_MEDIA_TYPE, FILTER_MEDIAOWNERNETWORK, FILTER_PPS,
  FILTER_PRICE, FILTER_VICINITY, GOAL_BUDGET, GOAL_GRP, GOAL_QUANTITY, GOAL_QUOTA, OVERALL_GOAL_BUDGET, RANKING_CPT,
  RANKING_CUSTOM_AREA, RANKING_MEDIAOWNER, RANKING_PPS, FILTER_MULTISITE_LOCATION
} from '@/constants/scoring'
import { ScoringElementOptions, ScoringFunction } from '@/types/planning/scoring/functions'
import { PpsFilterFormConfig } from '@/components/planning/wizard/filter-editor/filter-form/pps-filter/pps-filter-form-config'
import {
  APPLY_DRIVING_DISTANCE_VALUE, AREA_KEYS_VALUE, CITY_NAMES_VALUE, EXTERNAL_IDS_VALUE, MIN_CLUSTER_SIZE_VALUE, TARGET_VALUE, POI_VALUE,
  RANGE_TYPE_VALUE, RANGE_VALUE, TOLERANCE_VALUE, TKP_VALUE, PLZ_VALUE, WEIGHT_VALUE, BOOKING_TIME_FRAMES_VALUE, BANNED_PRODUCTS_VALUE,
  RESTRICTED_BUILD_TYPES_VALUE, MEDIA_OWNER_NAMES_VALUE, ILLUMINATION_VALUE, MIN_DISTANCE_VALUE, MEDIA_FILTERING_VALUE,
  SITE_TYPES_VALUE, PRICE_VALUE, MEDIA_OWNER_NETWORKS_VALUE
} from '@/constants/functions/scoring-functions-param-names'
import MediaNetworksUIElement from '@/components/planning/wizard/filter-editor/ui-controls/media-networks-ui-element/media-networks-ui-element.vue'
import RangeUiElement from '@/components/planning/wizard/filter-editor/ui-controls/range-ui-element/range-ui-element.vue'
import TagsFileUiElement from '@/components/planning/wizard/filter-editor/ui-controls/tags-file-ui-element.vue'
import GeometriesUiElement from '@/components/planning/wizard/filter-editor/ui-controls/geometries-ui-element.vue'
import NumberUiElement from '@/components/planning/wizard/filter-editor/ui-controls/number-ui-element.vue'
import SliderUiElement from '@/components/planning/wizard/filter-editor/ui-controls/slider-ui-element/slider-ui-element.vue'
import PointsUiElement from '@/components/planning/wizard/filter-editor/ui-controls/points-list-ui-element/points-ui-element.vue'
import RadioBtnUiElement from '@/components/planning/wizard/filter-editor/ui-controls/radio-btn-ui-element/radio-btn-ui-element.vue'
import PairsUiElement from '@/components/planning/wizard/filter-editor/ui-controls/pairs-ui-element/pairs-ui-element.vue'
import TimeUiElement from '@/components/planning/wizard/filter-editor/ui-controls/time-ui-element.vue'
import TagsUiElement from '@/components/planning/wizard/filter-editor/ui-controls/tags-ui-element.vue'
import MediaFilterListUiElement from '@/components/planning/wizard/filter-editor/ui-controls/media-filter-list-ui-element.vue'
import SiteTypesUIElement from '@/components/planning/wizard/filter-editor/ui-controls/site-types-ui-element'
import { StringListCollection } from '@/utils/filter-editor/value-config/collection/string-list-collection'
import { AreaKeysConfig } from '@/utils/filter-editor/value-config/simple/area-keys-config'
import { NumberConfig } from '@/utils/filter-editor/value-config/simple/number-config'
import { BannedProductsCollection } from '@/utils/filter-editor/value-config/collection/banned-products-collection'
import { RangeValueConfig } from '@/utils/filter-editor/value-config/slider/range-value-config'
import { RangeTypeConfig } from '@/utils/filter-editor/value-config/select/range-type-config'
import { IlluminationTypeConfig } from '@/utils/filter-editor/value-config/select/illumination-type-config'
import { MediaOwnerNamesCollection } from '@/utils/filter-editor/value-config/collection/media-owner-names-collection'
import { SiteTypesCollection } from '@/utils/filter-editor/value-config/collection/site-types-collection'
import { CityNamesConfig } from '@/utils/filter-editor/value-config/simple/city-names-config'
import { MediaOwnerNetworksCollection } from '@/utils/filter-editor/value-config/collection/media-owner-networks-collection'
import { PointsConfig } from '@/utils/filter-editor/value-config/dataset/points-config'
import { SliderConfig } from '@/utils/filter-editor/value-config/slider/slider-config'
import { PairListConfig } from '@/utils/filter-editor/value-config/list/pair-list-config'
import { BookingTimeCollection } from '@/utils/filter-editor/value-config/collection/booking-time-collection'
import { SimpleKeyValueCollection } from '@/utils/filter-editor/value-config/collection/simple-key-value-collection'
import { RangeConfig } from '@/utils/filter-editor/value-config/range/range-config'
import { MediaFilterCollection } from '@/utils/filter-editor/value-config/collection/media-filter-collection'
import { ScoringFunctionsTypes } from '@/constants/functions/scoring-functions-types'
import { MultisiteLocationFilterFormConfig } from '@/components/planning/wizard/filter-editor/filter-form/multisite-location-filter/multisite-location-filter-form-config'
import CityNamesUiElement from '@/components/planning/wizard/filter-editor/ui-controls/city-names-ui-element/city-names-ui-element.vue'

export const getScoringFunctionConfigByName = function (funcName: string): ScoringFunction {
  return SCORING_FUNCTIONS.find(func => func.name === funcName) ?? {} as ScoringFunction
}

export const SCORING_FUNCTIONS: ScoringFunction[] = [
  {
    name: FILTER_AREA,
    icon: 'fas fa-globe',
    type: ScoringFunctionsTypes.AreaFilter,
    parameters: {
      [AREA_KEYS_VALUE]: {
        uiElement: GeometriesUiElement,
        settings: AreaKeysConfig,
        scoringParameters: {} as ScoringElementOptions
      },
      [TOLERANCE_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {
          minValue: 0.0,
          maxValue: 3000.0,
          defaultValue: 0.0,
          step: 1.0,
          format: ''
        }
      }
    }
  },
  {
    name: FILTER_CITY,
    icon: 'fas fa-city',
    type: ScoringFunctionsTypes.AreaFilter,
    parameters: {
      [CITY_NAMES_VALUE]: {
        scoringParameters: {} as ScoringElementOptions,
        settings: CityNamesConfig,
        uiElement: CityNamesUiElement
      }
    }
  },
  {
    name: FILTER_CLUSTERING,
    icon: 'fas fa-vector-square',
    type: ScoringFunctionsTypes.AreaFilter,
    parameters: {
      [RANGE_VALUE]: {
        uiElement: SliderUiElement,
        settings: RangeValueConfig,
        scoringParameters: {} as ScoringElementOptions
      },
      [MIN_CLUSTER_SIZE_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {} as ScoringElementOptions
      },
      [APPLY_DRIVING_DISTANCE_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {} as ScoringElementOptions
      },
      [POI_VALUE]: {
        uiElement: PointsUiElement,
        settings: PointsConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_VICINITY,
    icon: 'fas fa-crosshairs',
    type: ScoringFunctionsTypes.AreaFilter,
    parameters: {
      [RANGE_TYPE_VALUE]: {
        uiElement: RadioBtnUiElement,
        settings: RangeTypeConfig,
        scoringParameters: {} as ScoringElementOptions
      },
      [RANGE_VALUE]: {
        uiElement: SliderUiElement,
        settings: RangeValueConfig,
        scoringParameters: {} as ScoringElementOptions
      },
      [POI_VALUE]: {
        uiElement: PointsUiElement,
        settings: PointsConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_MEDIA_TYPE,
    icon: 'fas fa-photo-video',
    type: ScoringFunctionsTypes.TypeFilter,
    parameters: {
      [SITE_TYPES_VALUE]: {
        scoringParameters: {} as ScoringElementOptions,
        settings: SiteTypesCollection,
        uiElement: SiteTypesUIElement
      }
    }
  },
  {
    name: FILTER_MEDIAOWNERNETWORK,
    icon: 'fas fa-ad',
    type: ScoringFunctionsTypes.NetworkFilter,
    parameters: {
      [MEDIA_OWNER_NETWORKS_VALUE]: {
        scoringParameters: {} as ScoringElementOptions,
        settings: MediaOwnerNetworksCollection,
        uiElement: MediaNetworksUIElement
      }
    }
  },
  {
    name: FILTER_AVAILABILITY,
    icon: 'far fa-calendar-alt',
    type: ScoringFunctionsTypes.FlightFilter,
    parameters: {
      [BOOKING_TIME_FRAMES_VALUE]: {
        scoringParameters: {} as ScoringElementOptions,
        settings: BookingTimeCollection,
        uiElement: TimeUiElement
      }
    }
  },
  {
    name: FILTER_BANNED_PRODUCTS,
    icon: 'fas fa-ban',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [BANNED_PRODUCTS_VALUE]: {
        uiElement: TagsUiElement,
        settings: BannedProductsCollection,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_BUILD_TYPE,
    icon: 'fas fa-shapes',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [RESTRICTED_BUILD_TYPES_VALUE]: {
        uiElement: TagsUiElement,
        settings: SimpleKeyValueCollection,
        scoringParameters: {
          minValue: 0,
          maxValue: 100,
          step: 1,
          format: '',
          options: {
            FL: 'Fläche (FL)',
            HS: 'Halbsäule (HS)',
            MD: 'Mobildisplay (MD)',
            MF: 'GF mit Monofuß (MF)',
            '  ': 'Normal',
            PW: 'Wendeanlage (PW)',
            SB: 'StretchBoard (SB)',
            SI: 'Stadtinfoanlage (SI)',
            SL: 'Säule (SL)',
            SZ: 'Stretch-Säule (SZ)',
            TT: 'Toptronic (TT)',
            WH: 'Wartehalle (WH)',
            WX: 'Wechsler (WX)',
            40: '40/1 Superposter Aushang möglich (40)'
          },
          defaultValue: null
        }
      }
    }
  },
  {
    name: FILTER_CPT,
    icon: 'far fa-chart-bar',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [TKP_VALUE]: {
        uiElement: RangeUiElement,
        settings: RangeConfig,
        scoringParameters: {
          minValue: 0.0,
          maxValue: 100.0,
          defaultValue: [1, 15],
          step: 1.0,
          format: '€'
        }
      }
    }
  },
  {
    name: FILTER_ILLUMINATION,
    icon: 'far fa-lightbulb',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [ILLUMINATION_VALUE]: {
        uiElement: RadioBtnUiElement,
        settings: IlluminationTypeConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_INCLUDE_MEDIA_OWNER,
    icon: 'fas fa-user-plus',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [MEDIA_OWNER_NAMES_VALUE]: {
        uiElement: TagsUiElement,
        settings: MediaOwnerNamesCollection,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_EXCLUDE_MEDIA_OWNER,
    icon: 'fas fa-user-minus',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [MEDIA_OWNER_NAMES_VALUE]: {
        uiElement: TagsUiElement,
        settings: MediaOwnerNamesCollection,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_MEDIA_CRITERIA,
    icon: 'fas fa-folder-plus',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [MEDIA_FILTERING_VALUE]: {
        scoringParameters: {} as ScoringElementOptions,
        settings: MediaFilterCollection,
        uiElement: MediaFilterListUiElement
      }
    }
  },
  {
    name: FILTER_PRICE,
    icon: 'fas fa-wallet',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [PRICE_VALUE]: {
        uiElement: RangeUiElement,
        settings: RangeConfig,
        scoringParameters: {
          minValue: 0.0,
          maxValue: 500.0,
          defaultValue: [
            50.0,
            150.0
          ],
          step: 1.0,
          format: '€'
        }
      }
    }
  },
  {
    name: FILTER_PPS,
    icon: 'fas fa-glasses',
    type: ScoringFunctionsTypes.Filter,
    config: PpsFilterFormConfig
  },
  {
    name: FILTER_MULTISITE_LOCATION,
    icon: 'fas fa-th',
    type: ScoringFunctionsTypes.Filter,
    config: MultisiteLocationFilterFormConfig
  },
  {
    name: FILTER_ISOLATION,
    icon: 'far fa-dot-circle',
    type: ScoringFunctionsTypes.Filter,
    parameters: {
      [MIN_DISTANCE_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {
          minValue: 0.0,
          maxValue: 3000.0,
          defaultValue: 0.0,
          step: 1.0,
          format: ''
        }
      }
    }
  },
  {
    name: RANKING_CPT,
    icon: 'fas fa-chart-line',
    type: ScoringFunctionsTypes.Ranking,
    parameters: {
      [TKP_VALUE]: {
        uiElement: SliderUiElement,
        settings: SliderConfig,
        scoringParameters: {
          minValue: 0.0,
          maxValue: 100.0,
          defaultValue: 10.0,
          step: 1.0,
          format: '%'
        }
      }
    }
  },
  {
    name: RANKING_CUSTOM_AREA,
    icon: 'fas fa-table',
    type: ScoringFunctionsTypes.Ranking,
    parameters: {
      [PLZ_VALUE]: {
        uiElement: PairsUiElement,
        settings: PairListConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: RANKING_MEDIAOWNER,
    icon: 'fas fa-medal',
    type: ScoringFunctionsTypes.Ranking,
    parameters: {
      [WEIGHT_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {
          minValue: 0.0,
          maxValue: 2.0,
          defaultValue: 1.0,
          step: 1.0,
          format: ''
        }
      }
    }
  },
  {
    name: RANKING_PPS,
    icon: 'fas fa-eye',
    type: ScoringFunctionsTypes.Ranking,
    parameters: {
      [WEIGHT_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {
          minValue: 0.0,
          maxValue: 2.0,
          defaultValue: 1.0,
          step: 1.0,
          format: ''
        }
      }
    }
  },
  {
    name: GOAL_BUDGET,
    icon: 'fas fa-wallet',
    type: ScoringFunctionsTypes.Goal,
    parameters: {
      [TARGET_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: GOAL_GRP,
    icon: 'fas fa-percent',
    type: ScoringFunctionsTypes.Goal,
    parameters: {
      [TARGET_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: GOAL_QUANTITY,
    icon: 'fas fa-tasks',
    type: ScoringFunctionsTypes.Goal,
    parameters: {
      [TARGET_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: GOAL_QUOTA,
    icon: 'fas fa-ellipsis-h',
    type: ScoringFunctionsTypes.Goal,
    parameters: {
      [TARGET_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: OVERALL_GOAL_BUDGET,
    icon: 'fas fa-briefcase',
    type: ScoringFunctionsTypes.OverallGoal,
    parameters: {
      [TARGET_VALUE]: {
        uiElement: NumberUiElement,
        settings: NumberConfig,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_INCLUSION,
    icon: 'fas fa-sort-amount-up',
    type: ScoringFunctionsTypes.ManualScoring,
    parameters: {
      [EXTERNAL_IDS_VALUE]: {
        uiElement: TagsFileUiElement,
        settings: StringListCollection,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  },
  {
    name: FILTER_EXCLUSION,
    icon: 'fas fa-sort-amount-down',
    type: ScoringFunctionsTypes.ManualScoring,
    parameters: {
      [EXTERNAL_IDS_VALUE]: {
        uiElement: TagsFileUiElement,
        settings: StringListCollection,
        scoringParameters: {} as ScoringElementOptions
      }
    }
  }
]
