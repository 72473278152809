





















import { Vue, Component, Prop } from 'vue-property-decorator'
import AgGrid from '@/components/shared/ag-grid/ag-grid.vue'
import { ValidatableModalComponent } from '@/plugins/modal-dialog/types'
import { ExternalId, ResolvedAssignments, UnclearAssignments } from '@/types/planning/unclear-assignments'
import { mapGetters } from 'vuex'
import { CAMPAIGN_SELECTED_LOCATIONS } from '@/store/getter-types'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { ColDef } from 'ag-grid-community'
import AreasSelector from '@/plugins/resolve-unclear-assignments/areas-selector/areas-selector.vue'
import { ScoringDetailsObject } from '@/types/planning'
import { DatasetRow } from '@/types/common'
import LogoOverlay from '@/components/overlay/logo-overlay.vue'
import { transform, cloneDeep, padStart } from 'lodash'
import { GRID_COLUMN_TYPE_CURRENCY } from '@/components/shared/ag-grid/constants'
import QuickAssignment from '@/plugins/resolve-unclear-assignments/quick-assignment/quick-assignment.vue'
import { ConfirmResult } from '@/plugins/modal-dialog/dialogs/msg-box-confirm'
import { DAILY_PRICE_COLUMN } from '@/constants/unit/fields'

function getSDAWItemId (item: DatasetRow): ExternalId {
  return `${item.SDAWNummer}_${padStart(item.Position as string, 2, '0')}`
}

@Component({
  components: { QuickAssignment, LogoOverlay, AgGrid },
  computed: {
    ...mapGetters({
      campaignAreas: CAMPAIGN_SELECTED_LOCATIONS
    })
  }
})
export default class ResolveUnclearAssignments extends Vue implements ValidatableModalComponent {
  @Prop({ required: true }) readonly scoreDetails!: ScoringDetailsObject

  private readonly campaignAreas!: ScoringFunctionNode[]
  public resolvedAssignments: ResolvedAssignments = {}

  private wasValidated = false

  private defaultColDef: ColDef = {
    sortable: false,
    resizable: false,
    flex: 1,
    filter: false
  }

  private get scoresDetails (): DatasetRow[] {
    return this.scoreDetails.table.datasetRows
  }

  private get unclearAssignments (): UnclearAssignments {
    return this.scoreDetails.unClearAssignments ?? {}
  }

  private get tableItems (): DatasetRow[] | null {
    if (this.scoresDetails.length === 0) {
      return null
    }

    return this.scoresDetails.filter(item => getSDAWItemId(item) in this.unclearAssignments)
  }

  private get columnDefs (): ColDef[] {
    return [
      {
        field: 'SDAWNummer'
      },
      {
        field: 'Position'
      },
      {
        field: 'Bezeichnung',
        flex: 2
      },
      {
        headerName: 'Tagespreis',
        field: DAILY_PRICE_COLUMN,
        type: GRID_COLUMN_TYPE_CURRENCY
      },
      {
        field: 'Stellenart'
      },
      {
        colId: 'actions',
        headerName: 'Assignment',
        cellRendererFramework: AreasSelector,
        cellRendererParams: {
          getOptions: (item: DatasetRow) => {
            const externalId = getSDAWItemId(item)

            return {
              externalId,
              unclearAssignments: this.unclearAssignments[externalId],
              locations: this.campaignAreas,
              selected: this.resolvedAssignments[externalId]?.scoringFunctionId,
              validated: this.wasValidated
            }
          }
        },
        flex: 1
      }
    ]
  }

  private onAssignmentChange (e) {
    if (e.variant) {
      this.$set(this.resolvedAssignments, e.id, e.variant)
      return
    }
    this.$delete(this.resolvedAssignments, e.id)
  }

  private handleQuickAssignment (assignmentOrder: string[]) {
    this.resolvedAssignments = transform(cloneDeep(this.unclearAssignments), (result, value, key) => {
      // Sort available variants by order and take first one
      const sortedVariants = value.sort((colA, colB) => assignmentOrder.indexOf(colA.scoringFunctionId) - assignmentOrder.indexOf(colB.scoringFunctionId))
      Object.assign(result, { [key]: sortedVariants[0] })
    }, {})
  }

  async validateModal (): Promise<boolean> {
    this.wasValidated = true
    const amountUnresolved = Object.keys(this.unclearAssignments).length - Object.keys(this.resolvedAssignments).length

    if (amountUnresolved === 0) {
      return Promise.resolve(true)
    }

    const isConfirmed = await this.confirmationModal(amountUnresolved)
    return Promise.resolve(!!isConfirmed)
  }

  private confirmationModal (amount: number): Promise<ConfirmResult> {
    const dialogProps = {
      okVariant: 'danger',
      centered: true,
      noCloseOnBackdrop: true
    }

    const dialogSlots = {
      'modal-title': this.$t('planning.bookable-units-resolve.labels.confirm-modal-title') as string
    }

    const dialogMessage = this.$t('planning.bookable-units-resolve.labels.confirm-modal-label', { amount }) as string

    return this.$modalDialog.msgBoxConfirm(dialogMessage, dialogProps, dialogSlots)
  }
}
