import { constantsApi } from '@/api/rest/constants/constants.api'
import { MEDIA_FILTER, MEDIA_FILTER_INITIALIZED } from '@/store/getter-types'
import { SET_MEDIA_FILTER } from '@/store/mutation-types'
import { LOAD_MEDIA_FILTER } from '@/store/action-types'

const state = {
  initialized: false,
  collection: []
}

// getters
const getters = {
  [MEDIA_FILTER]  (state) {
    return state.collection
  },
  [MEDIA_FILTER_INITIALIZED] (state) {
    return state.initialized
  }
}

// actions
const actions = {
  [LOAD_MEDIA_FILTER] ({ commit, getters }) {
    if (getters[MEDIA_FILTER_INITIALIZED]) {
      return Promise.resolve(getters[MEDIA_FILTER])
    } else {
      return constantsApi.getMediaFilter()
        .then(collection => {
          commit(SET_MEDIA_FILTER, collection)

          return getters[MEDIA_FILTER]
        })
    }
  }
}

// mutations
const mutations = {
  [SET_MEDIA_FILTER] (state, payload) {
    if (Array.isArray(payload)) {
      state.collection = payload
      state.initialized = true
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
