import { LocaleMessages } from 'vue-i18n'

export default {
  en: {
    'modal-title': 'Cities selector',
    'all-cities': 'All cities',
    'selected-cities': 'Selected cities'
  },
  de: {
    'modal-title': 'Städte Auswahl',
    'all-cities': 'Alle Städte',
    'selected-cities': 'Ausgewählte Städte'
  }
} as LocaleMessages
