import { Store } from '@/store/store'
import { LOAD_MEDIA_OWNER_NAMES } from '@/store/action-types'
import { MEDIA_OWNER_NAMES } from '@/store/getter-types'
import { CollectionItemArray } from '@/types/planning/wizard/filter-editor'
import { CollectionConfig } from './collection-config'

export class MediaOwnerNamesCollection extends CollectionConfig {
  get options (): CollectionItemArray {
    return Store.getters[MEDIA_OWNER_NAMES]
  }

  async initCollection (): Promise<void> {
    await Store.dispatch(LOAD_MEDIA_OWNER_NAMES)
  }
}
