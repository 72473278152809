import {
  PLAN_WIZARD_SET_MEDIA_NETWORKS
} from '@/store/mutation-types'
import { MediaOwnerNetworksCache } from '@/types/planning/wizard/value-types'

// mutations
export const mutations = {
  [PLAN_WIZARD_SET_MEDIA_NETWORKS] (state: MediaOwnerNetworksCache, networks: MediaOwnerNetworksCache | null) {
    state.dateFrom = networks?.dateFrom ?? null
    state.dateTo = networks?.dateTo ?? null
    state.dataset = networks?.dataset ?? null
    state.networks = networks?.networks ?? []
    state.areaNameList = networks?.areaNameList ?? []
  }
}
