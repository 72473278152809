import { ApiRequestConfig } from '@/api/types'
import { ObjectId } from '@/types/common'
import { SiteDetails } from '@/types/planning'
import { ApiClient } from '@/api/api-client'
import { getSiteDetailsTransformResponse } from './transform-response/site-details-response.transform'

class SiteDetailsApi extends ApiClient {
  getSiteDetails (id: ObjectId): Promise<SiteDetails> {
    const config: ApiRequestConfig = {
      blockingRequest: true,
      transformResponse: getSiteDetailsTransformResponse
    }

    return this.get<SiteDetails>(id, config)
  }
}

export const siteDetailsApi = new SiteDetailsApi({
  baseURL: API_CONF.api + '/api/SiteDetail/GetSiteDetail/'
})
