import { Component, Mixins, Prop } from 'vue-property-decorator'
import { assign, upperFirst } from 'lodash'
import { DomEvent, FeatureGroup, Layer, LayerOptions, Path, Util } from 'leaflet'
import { LayerMixin } from '@/components/map/mixins/layer-mixin/layer.mixin'
import { ZIndexMixin } from '@/components/map/mixins/z-index.mixin'

type LeafletEventMap = {[eventName: string]: DomEvent.EventHandlerFn}

@Component
export class LayerGroupMixin<TItem, TLayer extends Layer, TOptions extends LayerOptions = LayerOptions>
  extends Mixins<LayerMixin<FeatureGroup>, ZIndexMixin<FeatureGroup>>(LayerMixin, ZIndexMixin) {
  @Prop({ required: true }) readonly items!: TItem[]

  protected declare mapObject: FeatureGroup<TLayer>

  protected override unbindTooltip (): void {
    if (!this.mapObject) {
      return
    }

    this.mapObject.invoke('unbindTooltip')
  }

  protected override unbindPopup (): void {
    if (!this.mapObject) {
      return
    }

    this.mapObject.invoke('unbindPopup')
  }

  protected makeScopedListeners (item: TItem): LeafletEventMap {
    const scopeListener = (listener): DomEvent.EventHandlerFn => (e) => listener({ item, event: e })

    return Object.entries(this.$listeners)
      .reduce((events, [event, listener]) => {
        if (Array.isArray(listener)) {
          events[event] = scopeListener((e) => listener.forEach((l) => l(e)))
        } else {
          events[event] = scopeListener(listener)
        }

        return events
      }, {})
  }

  protected makeItemOptions (item: TItem, options: TOptions): TOptions {
    options = assign({}, options)

    for (const prop in options) {
      const propValue = options[prop]

      if (typeof propValue === 'function') {
        options[prop] = propValue(item)
      }
    }

    return options
  }

  protected setStyleProp (prop: string, value: unknown): void {
    if (typeof value === 'function') {
      const layers = this.mapObject.getLayers()

      this.items.forEach((item, index) => {
        if (layers[index]) {
          (layers[index] as Path).setStyle({ [prop]: value(item) })
        }
      })
    } else {
      this.mapObject.setStyle({ [prop]: value })
    }
  }

  protected override updateLayerProp (prop: string, newVal: unknown): void {
    const setMethodName = 'set' + upperFirst(prop)

    if (setMethodName === 'setOptions') {
      this.mapObject.eachLayer(layer => Util.setOptions(layer, newVal))
      return
    }

    this.mapObject.invoke(setMethodName, newVal)
  }
}
