import { VueConstructor } from 'vue'
import { QueryRuleConfig } from '@/types/query-builder/rules'
import TextRule from '../components/rules/text-rule.vue'
import NumericRule from '../components/rules/numeric-rule.vue'
import CheckboxRule from '../components/rules/checkbox-rule.vue'
import SelectRule from '../components/rules/select-rule.vue'
import RadioRule from '../components/rules/radio-rule.vue'
import TagsRule from '../components/rules/tags-rule.vue'

type RuleComponentMap = {
  [key in QueryRuleConfig['type']]: VueConstructor;
}

export const ruleComponents: RuleComponentMap = {
  text: TextRule,
  numeric: NumericRule,
  checkbox: CheckboxRule,
  radio: RadioRule,
  select: SelectRule,
  tags: TagsRule
}
