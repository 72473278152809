











































import { Component, Mixins, Watch } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import { maxValue, minValue, required, decimal } from 'vuelidate/lib/validators'
import { FilterRangeConfig } from '@/types/planning/wizard/filter-editor'
import { RuleDecl } from '@/vendor/types/vuelidate'
import { Validations } from '@/decorators'
import FilterParamName from '@/components/planning/wizard/filter-editor/filter-name/filter-param-name.vue'
import { SliderUiMixin } from '@/components/planning/wizard/filter-editor/ui-controls/mixins/slider-ui.mixin'

@Component({
  components: { FilterParamName, VueSlider }
})
export default class SliderUiElement extends Mixins<SliderUiMixin<number>>(SliderUiMixin) {
  @Validations()
  private validations (): RuleDecl {
    return {
      selected: {
        required,
        decimal,
        minValue: minValue(this.config.rangeMin),
        maxValue: maxValue(this.config.rangeMax)
      }
    }
  }

  @Watch('value')
  private onValueChange (newValue: number): void {
    this.setSelected(newValue, true)
  }

  @Watch('config')
  private onConfigChange (newConfig: FilterRangeConfig<number>): void {
    this.setSelected(newConfig.defaultValue, true)
  }
}
