import { cloneDeep, difference, filter } from 'lodash'
import {
  PLAN_WIZARD_ADD_FILTER_NODE,
  PLAN_WIZARD_REMOVE_FILTER_NODE,
  PLAN_WIZARD_REPLACE_FILTER_NODE,
  PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER,
  PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT,
  PLAN_WIZARD_SET_CAMPAIGN_ID,
  PLAN_WIZARD_SET_CAMPAIGN_NAME,
  PLAN_WIZARD_SET_CAMPAIGN_STATUS,
  PLAN_WIZARD_SET_FILTERS,
  PLAN_WIZARD_SET_MEDIA_NETWORKS,
  PLAN_WIZARD_SET_START_DATE,
  PLAN_WIZARD_SET_END_DATE,
  PLAN_WIZARD_SET_STEP_LEAVE_CALLBACK,
  SET_PLAN_WIZARD_ACTIVE_FILTER,
  SET_PLAN_WIZARD_UPDATED_STATE,
  PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG,
  PLAN_WIZARD_SET_CONSIDER_STATUS_UNKNOWN_FLAG
} from '@/store/mutation-types'
import {
  PLAN_WIZARD_ADD_FILTER,
  PLAN_WIZARD_ADD_FILTER_NODES,
  PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK,
  PLAN_WIZARD_COPY_FILTER_RECURSIVELY,
  PLAN_WIZARD_INIT,
  PLAN_WIZARD_MODIFY_FILTER,
  PLAN_WIZARD_RELOAD_NETWORKS,
  PLAN_WIZARD_REMOVE_FILTER,
  PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS,
  PLAN_WIZARD_REMOVE_FILTER_DATA,
  PLAN_WIZARD_RESET,
  PLAN_WIZARD_SET_ACTIVE_FILTER,
  PLAN_WIZARD_SET_FILTER_DATA
} from '@/store/action-types'
import {
  PLAN_WIZARD_FILTER_BY_ID,
  PLAN_WIZARD_FILTERS,
  PLAN_WIZARD_GENERATE_FILTER_ID,
  PLAN_WIZARD_STEP_LEAVE_CALLBACK
} from '@/store/getter-types'
import { PlanningStatus } from '@/types/planning/enums'
import { ScoringFunctionNode } from '@/types/planning/scoring/functions'
import { getNodeChildren, getNodeParentKey, NodeParentKey } from '@/utils/scoring/nodes'
import { CopyFilterActionPayload } from '@/types/store/planning-wizard'

export default {
  [PLAN_WIZARD_RESET] ({ commit }) {
    commit(PLAN_WIZARD_SET_CAMPAIGN_ID, null)
    commit(PLAN_WIZARD_SET_CAMPAIGN_NAME, '')
    commit(PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER, null)
    commit(PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT, 0)
    commit(PLAN_WIZARD_SET_CAMPAIGN_STATUS, PlanningStatus.Draft)
    commit(PLAN_WIZARD_SET_CONSIDER_STATUS_UNKNOWN_FLAG, false)
    commit(PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG, false)
    commit(PLAN_WIZARD_SET_START_DATE, null)
    commit(PLAN_WIZARD_SET_END_DATE, null)
    commit(PLAN_WIZARD_SET_FILTERS, [])
    commit(SET_PLAN_WIZARD_ACTIVE_FILTER, null)
    commit(SET_PLAN_WIZARD_UPDATED_STATE, false)
    commit(PLAN_WIZARD_SET_STEP_LEAVE_CALLBACK, null)
    commit(PLAN_WIZARD_SET_MEDIA_NETWORKS, null)
  },
  [PLAN_WIZARD_INIT] ({ commit, dispatch }, { id, name, customer, discount, forceContinuousDisplay, considerUnknownAvailability, status, startDate, endDate, filters }) {
    commit(PLAN_WIZARD_SET_CAMPAIGN_ID, id)
    commit(PLAN_WIZARD_SET_CAMPAIGN_NAME, name)
    commit(PLAN_WIZARD_SET_CAMPAIGN_CUSTOMER, customer)
    commit(PLAN_WIZARD_SET_CAMPAIGN_DISCOUNT, discount)
    commit(PLAN_WIZARD_SET_FORCE_CONTINUOUS_DISPLAY_FLAG, forceContinuousDisplay)
    commit(PLAN_WIZARD_SET_CONSIDER_STATUS_UNKNOWN_FLAG, considerUnknownAvailability)
    commit(PLAN_WIZARD_SET_CAMPAIGN_STATUS, status)
    commit(PLAN_WIZARD_SET_START_DATE, startDate)
    commit(PLAN_WIZARD_SET_END_DATE, endDate)
    commit(PLAN_WIZARD_SET_FILTERS, filters)
    commit(SET_PLAN_WIZARD_ACTIVE_FILTER, {})
    commit(SET_PLAN_WIZARD_UPDATED_STATE, false)
    dispatch(PLAN_WIZARD_RELOAD_NETWORKS)
  },
  [PLAN_WIZARD_SET_FILTER_DATA] ({ dispatch }, { query, name, data }) {
    const customizer = node => {
      node.name = name
      node.scoringFunction.data = data
      return node
    }

    return dispatch(PLAN_WIZARD_MODIFY_FILTER, { query, customizer })
  },
  [PLAN_WIZARD_REMOVE_FILTER_DATA] ({ dispatch }, { filterId, rowIndex }) {
    if (rowIndex < 0) {
      return
    }

    const customizer = node => {
      node.scoringFunction.data.splice(rowIndex, 1)
      return node
    }

    return dispatch(PLAN_WIZARD_MODIFY_FILTER, { query: { id: filterId }, customizer })
  },
  [PLAN_WIZARD_MODIFY_FILTER] ({ commit, getters }, { query, customizer }) {
    const nodesToUpdate = filter(getters[PLAN_WIZARD_FILTERS], query)

    const result: ScoringFunctionNode[] = []

    for (const oldNode of nodesToUpdate) {
      const newNode = customizer(cloneDeep(oldNode))

      commit(PLAN_WIZARD_REPLACE_FILTER_NODE, { oldNode, newNode })
      result.push(newNode)
    }

    return result
  },
  [PLAN_WIZARD_ADD_FILTER] ({ commit, getters }, filter) {
    const filterToAdd = { ...filter, id: getters[PLAN_WIZARD_GENERATE_FILTER_ID]() }
    commit(PLAN_WIZARD_ADD_FILTER_NODE, filterToAdd)

    return filterToAdd
  },
  [PLAN_WIZARD_REMOVE_FILTER] ({ commit }, filter) {
    commit(PLAN_WIZARD_REMOVE_FILTER_NODE, filter)
  },
  [PLAN_WIZARD_REMOVE_FILTER_BY_PARAMS] ({ getters, commit }, predicate) {
    const filtersToRemove = filter(getters[PLAN_WIZARD_FILTERS], predicate)
    filtersToRemove.forEach(node => commit(PLAN_WIZARD_REMOVE_FILTER_NODE, node))
  },
  [PLAN_WIZARD_ADD_FILTER_NODES] ({ commit }, nodes) {
    for (const node of nodes) {
      commit(PLAN_WIZARD_ADD_FILTER_NODE, node)
    }
  },
  [PLAN_WIZARD_SET_ACTIVE_FILTER] ({ commit }, filter) {
    return commit(SET_PLAN_WIZARD_ACTIVE_FILTER, filter)
  },
  async [PLAN_WIZARD_CALL_STEP_LEAVE_CALLBACK] ({ getters }, stepId) {
    const callback = getters[PLAN_WIZARD_STEP_LEAVE_CALLBACK]

    if (callback) {
      return callback(stepId)
    }

    return []
  },
  async [PLAN_WIZARD_COPY_FILTER_RECURSIVELY] ({ dispatch, getters }, { filterId, customizer }: CopyFilterActionPayload) {
    const filterNode = getters[PLAN_WIZARD_FILTER_BY_ID](filterId)
    const nodesToAdd: ScoringFunctionNode[] = []

    const generateId = getters[PLAN_WIZARD_GENERATE_FILTER_ID]
    const allScoringFunctions: ScoringFunctionNode[] = getters[PLAN_WIZARD_FILTERS]

    const cloneNode = (nodeToClone: ScoringFunctionNode, newParentKey?: NodeParentKey) => {
      let nodeCopy = {
        ...cloneDeep(nodeToClone),
        ...newParentKey
      }

      if (filterNode === nodeToClone) {
        nodeCopy = customizer(nodeCopy)
      }

      nodeCopy.id = generateId()
      nodesToAdd.push(nodeCopy)

      const children = getNodeChildren(nodeToClone, allScoringFunctions)

      if (!children.length) {
        return
      }

      const parentKey = getNodeParentKey(children[0])
      const keyDiff = difference(Object.keys(parentKey), Object.keys(newParentKey ?? {}))

      if (keyDiff.length !== 1) {
        throw new Error('Unable to determine filter node self key name.')
      }

      const keyForChildren = {
        ...newParentKey,
        [keyDiff[0]]: nodeCopy.id
      }

      for (const childNode of children) {
        cloneNode(childNode, keyForChildren as NodeParentKey)
      }
    }

    cloneNode(filterNode)

    await dispatch(PLAN_WIZARD_ADD_FILTER_NODES, nodesToAdd)
    return nodesToAdd[0]
  }
}
