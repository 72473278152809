import { FillType } from '@/types/visualization/layer/enums'
import GradientFillMixin from './gradient-fill.mixin'
import QuantileFillMixin from './quantile-fill.mixin'
import ClusteringFillMixin from './clustering-fill.mixin'
import RangeFillMixin from './range-fill.mixin'
import GroupItemsMixin from './group-items.mixin'
import { Component, Mixins } from 'vue-property-decorator'
import { FilledGeometryLayer } from '@/types/visualization/layer'

@Component
export default class LayerFillMixin<_TLayer extends FilledGeometryLayer = FilledGeometryLayer> extends Mixins<
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  GradientFillMixin<_TLayer>, QuantileFillMixin<_TLayer>, ClusteringFillMixin<_TLayer>, RangeFillMixin<_TLayer>, GroupItemsMixin
    >(GradientFillMixin, QuantileFillMixin, ClusteringFillMixin, RangeFillMixin, GroupItemsMixin) {
  protected get fillDataRows () {
    switch (this.layer.style.fill.type) {
      case FillType.Median:
      case FillType.Quartile:
      case FillType.Decile:
        return this.quantileFillDataRows

      case FillType.Clustering:
        return this.clusteringFillDataRows

      case FillType.Range:
        return this.rangeFillDataRows
    }

    return this.dataRows
  }

  protected get layerFillColor () {
    switch (this.layer.style.fill.type) {
      case FillType.Gradient:
        return this.gradientFillColorFunc || this.layer.style.fill.color

      case FillType.Median:
      case FillType.Quartile:
      case FillType.Decile:
        return this.quantileFillColorFunc || this.layer.style.fill.color

      case FillType.Clustering:
        return this.clusteringFillColorFunc || this.layer.style.fill.color

      case FillType.Range:
        return this.rangeFillColorFunc || this.layer.style.fill.color
    }

    return this.layer.style.fill.color as string
  }

  protected get layerFillOpacity () {
    switch (this.layer.style.fill.type) {
      case FillType.Gradient:
        return this.gradientFillOpacityFunc || this.layer.style.fill.opacity

      case FillType.Median:
      case FillType.Quartile:
      case FillType.Decile:
        return this.quantileFillOpacityFunc || this.layer.style.fill.opacity

      case FillType.Range:
        return this.rangeFillOpacityFunc || this.layer.style.fill.opacity
    }

    return this.layer.style.fill.opacity as number
  }

  protected get layerFillGroupFunc () {
    if (this.layer.style.fill.type === FillType.Clustering) {
      return this.makeClusterGroupFunc(this.layer.style.fill.gradientColumn)
    }

    return this.makeColorGroupFunc(this.layerFillColor, this.layerFillOpacity)
  }
}
