import { FillType, LayerType, ShapeType } from '@/types/visualization/layer/enums'
import ClusteringFillLegendMixin from './clustering-fill-legend.mixin'
import ClusteringPathLegendMixin from './clustering-path-legend.mixin'
import QuantileFillLegendMixin from './quantile-fill-legend.mixin'
import QuantilePathLegendMixin from './quantile-path-legend.mixin'
import RangeFillLegendMixin from './range-fill-legend.mixin'
import RangePathLegendMixin from './range-path-legend.mixin'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { FormatLegendValuesFunc } from '@/types/visualization/map-legend'
import { LegendType } from '@/types/enums'
import ColorFillLegendMixin from '@/components/visualization/legend/mixins/color-fill-legend.mixin'
import ColorPathLegendMixin from '@/components/visualization/legend/mixins/color-path-legend.mixin'

type LegendValuesMixinUnion<TLayer> =
    ClusteringFillLegendMixin<TLayer> & ClusteringPathLegendMixin<TLayer>
    & QuantileFillLegendMixin<TLayer> & QuantilePathLegendMixin<TLayer>
    & RangeFillLegendMixin<TLayer> & RangePathLegendMixin<TLayer>
    & ColorFillLegendMixin<TLayer> & ColorPathLegendMixin<TLayer>

@Component
export default class LegendValuesMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  // eslint-disable-next-line func-call-spacing
  Mixins<LegendValuesMixinUnion<_TLayer>>
  (ClusteringFillLegendMixin, ClusteringPathLegendMixin, QuantileFillLegendMixin, QuantilePathLegendMixin, RangeFillLegendMixin, RangePathLegendMixin, ColorFillLegendMixin, ColorPathLegendMixin) {
  @Prop({ default: () => null }) private readonly legendValuesSorter!: FormatLegendValuesFunc
  @Prop({ default: () => null }) private readonly legendValuesFormatter!: FormatLegendValuesFunc
  @Prop({ default: LegendType.FillLegend }) private readonly legendType!: LegendType

  protected get legendLayerTypeValuesGetters () {
    return {
      [LayerType.Shapes]: () => this.legendShapeValues,
      [LayerType.Points]: () => this.legendFillValues
    }
  }

  protected get legendShapeValuesGetters () {
    return {
      [ShapeType.Polygon]: () => this.legendFillValues,
      [ShapeType.Line]: () => this.legendPathValues
    }
  }

  protected get legendFillValuesGetters () {
    return {
      [FillType.Color]: () => this.colorFillLegendValues,
      [FillType.Median]: () => this.quantileFillLegendValues,
      [FillType.Quartile]: () => this.quantileFillLegendValues,
      [FillType.Decile]: () => this.quantileFillLegendValues,
      [FillType.Clustering]: () => this.clusteringFillLegendValues,
      [FillType.Range]: () => this.rangeFillLegendValues
    }
  }

  protected get legendPathValuesGetters () {
    return {
      [FillType.Color]: () => this.colorPathLegendValues,
      [FillType.Median]: () => this.quantilePathLegendValues,
      [FillType.Quartile]: () => this.quantilePathLegendValues,
      [FillType.Decile]: () => this.quantilePathLegendValues,
      [FillType.Clustering]: () => this.clusteringPathLegendValues,
      [FillType.Range]: () => this.rangePathLegendValues
    }
  }

  protected get sortedLegendValues () {
    return this.legendValuesSorter ? this.legendValuesSorter(this.formattedLegendValues) : this.formattedLegendValues
  }

  protected get formattedLegendValues () {
    return this.legendValuesFormatter ? this.legendValuesFormatter(this.legendValues) : this.legendValues
  }

  protected get legendValues () {
    switch (this.legendType) {
      case LegendType.FillLegend:
        return this.makeLegendValuesComputedProp(this.legendLayerTypeValuesGetters, this.layer.type)
      case LegendType.BorderLegend:
        return this.legendPathValues
      default:
        return this.makeLegendValuesComputedProp(this.legendLayerTypeValuesGetters, this.layer.type)
    }
  }

  protected get legendShapeValues () {
    return this.makeLegendValuesComputedProp(this.legendShapeValuesGetters, this.layer.shapeType)
  }

  protected get legendFillValues () {
    return this.makeLegendValuesComputedProp(this.legendFillValuesGetters, this.layer.style.fill.type)
  }

  protected get legendPathValues () {
    return this.makeLegendValuesComputedProp(this.legendPathValuesGetters, this.layer.style.path.type)
  }

  protected makeLegendValuesComputedProp (getters, key) {
    const getter = getters[key]
    return getter ? getter() : []
  }
}
