
















































import { mapGetters } from 'vuex'
import { USER_ROLES } from '@/store/getter-types'
import { Vue, Component } from 'vue-property-decorator'
import { getAllRoutes } from '@/router/router'
import AdvImage from '@/components/shared/adv-image.vue'
import { getModules, MainRouteConfig } from '@/constants/modules'
import InlineSvg from 'vue-inline-svg'
import { Routes } from '@/router/constants/routes'

@Component({
  components: { AdvImage, InlineSvg },
  computed: {
    ...mapGetters({
      userRoles: USER_ROLES
    })
  }
})

export default class ViewSelector extends Vue {
  private get views (): MainRouteConfig[] {
    return this.activeModules.filter(module => this.hasAccess(module.routeName))
  }

  private get activeModules (): MainRouteConfig[] {
    return getModules()
  }

  private hasAccess (routeName: Routes): boolean | void {
    const allRoutes = getAllRoutes()
    const route = allRoutes.find(item => item.name === routeName)
    if (route && route.meta) {
      return !route.meta.can || this.$can(route.meta.can, {})
    }
  }
}
