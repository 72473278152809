import { Store } from 'vuex'
import {
  PLAN_WIZARD_SET_START_DATE,
  PLAN_WIZARD_SET_END_DATE
} from '@/store/mutation-types'
import { WIZARD_STEP_FLIGHTS_REFRESH_FLIGHTS_DATA } from '@/store/action-types'

const campaignDateUpdateMutations = [
  PLAN_WIZARD_SET_START_DATE,
  PLAN_WIZARD_SET_END_DATE
]

export default (store: Store<never>) => {
  store.subscribe(mutation => {
    if (campaignDateUpdateMutations.includes(mutation.type)) {
      store.dispatch(WIZARD_STEP_FLIGHTS_REFRESH_FLIGHTS_DATA)
    }
  })
}
