import { AclRule } from '@/types/auth'
import { hasAnyRole } from '@/acl/utils/rule-builders'
import { AuthRole } from '@/types/auth/roles'
import { AuthPermission } from '@/types/auth/permissions'

const canCreateOrders: AclRule = hasAnyRole(AuthRole.Planning)

const canViewOrders: AclRule = hasAnyRole(AuthRole.Planning)

const canEditOrders: AclRule = hasAnyRole(AuthRole.Planning)

export const ordersRules = {
  [AuthPermission.CreateOrders]: canCreateOrders,
  [AuthPermission.ViewOrders]: canViewOrders,
  [AuthPermission.EditOrders]: canEditOrders,
  [AuthPermission.DeleteOrders]: canEditOrders
}
