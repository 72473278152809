import { i18n } from '@/locales/i18n'
import {
  LAYER_WIZARD_ENTRYPOINT_IS_AREA_SELECT,
  LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD,
  LAYER_WIZARD_LINK_NAMESPACE,
  LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_STEP_GRANULARITY_WAS_UPDATED,
  LAYER_WIZARD_TAB_GRANULARITY_CAN_SKIP,
  LAYER_WIZARD_TAB_GRANULARITY_ERRORS,
  LAYER_WIZARD_TAB_GRANULARITY_IS_EMPTY,
  LAYER_WIZARD_TAB_GRANULARITY_IS_ENABLED,
  LAYER_WIZARD_TAB_GRANULARITY_IS_HIDDEN,
  LAYER_WIZARD_TAB_GRANULARITY_IS_INVALID,
  LAYER_WIZARD_TAB_GRANULARITY_STEP_ID,
  WIZARD_NAVIGATION_ACTIVE_ID,
  WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID
} from '@/store/getter-types'
import {
  LAYER_WIZARD_TAB_GRANULARITY_LEAVE,
  LAYER_WIZARD_TAB_GRANULARITY_SKIP,
  LAYER_WIZARD_TAB_GRANULARITY_VALIDATE,
  WIZARD_INVALIDATE_AFTER
} from '@/store/action-types'
import { LAYER_WIZARD_STEP_GRANULARITY } from '@/components/visualization/layer-wizard/constants/steps'
import {
  LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET,
  LAYER_WIZARD_STEP_GRANULARITY_SET_UPDATE_STATE
} from '@/store/mutation-types'

// initial state
const state = {
  stepId: LAYER_WIZARD_STEP_GRANULARITY
}

// getters
const getters = {
  [LAYER_WIZARD_TAB_GRANULARITY_IS_HIDDEN] (state, getters) {
    return !getters[LAYER_WIZARD_ENTRYPOINT_IS_AREA_SELECT] &&
      !getters[LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD]
  },
  [LAYER_WIZARD_TAB_GRANULARITY_IS_ENABLED] (state, getters) {
    const namespacedGetter = getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_IS_ANY_STEP_BEFORE_INVALID)
    return !getters[namespacedGetter](state.stepId)
  },
  [LAYER_WIZARD_TAB_GRANULARITY_IS_EMPTY] (state, getters) {
    return !getters[LAYER_WIZARD_STEP_GRANULARITY_GEOMETRYSET]
  },
  [LAYER_WIZARD_TAB_GRANULARITY_IS_INVALID] (state, getters) {
    return getters[LAYER_WIZARD_TAB_GRANULARITY_ERRORS].length > 0
  },
  [LAYER_WIZARD_TAB_GRANULARITY_CAN_SKIP] (state, getters) {
    return getters[LAYER_WIZARD_ENTRYPOINT_IS_AREA_UPLOAD]
  },
  [LAYER_WIZARD_TAB_GRANULARITY_ERRORS] (state, getters) {
    const errors = []

    if (!getters[LAYER_WIZARD_TAB_GRANULARITY_CAN_SKIP] && getters[LAYER_WIZARD_TAB_GRANULARITY_IS_EMPTY]) {
      errors.push(i18n.t('visualization.new-layer-modal.errors.granularity-not-selected'))
    }

    return errors
  },
  [LAYER_WIZARD_TAB_GRANULARITY_STEP_ID] (state) {
    return state.stepId
  }
}

// mutations
const mutations = {}

// actions
const actions = {
  [LAYER_WIZARD_TAB_GRANULARITY_VALIDATE] ({ getters }) {
    return Promise.resolve(getters[LAYER_WIZARD_TAB_GRANULARITY_ERRORS])
  },
  [LAYER_WIZARD_TAB_GRANULARITY_SKIP] ({ commit }) {
    return commit(LAYER_WIZARD_SET_GRANULARITY_GEOMETRYSET, null)
  },
  [LAYER_WIZARD_TAB_GRANULARITY_LEAVE] ({ dispatch, commit, getters }) {
    const activeWizardStepId = getters[getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_NAVIGATION_ACTIVE_ID)]

    if (getters[LAYER_WIZARD_STEP_GRANULARITY_WAS_UPDATED] && activeWizardStepId === getters[LAYER_WIZARD_TAB_GRANULARITY_STEP_ID]) {
      const namespacedAction = getters[LAYER_WIZARD_LINK_NAMESPACE](WIZARD_INVALIDATE_AFTER)
      dispatch(namespacedAction)
      commit(LAYER_WIZARD_STEP_GRANULARITY_SET_UPDATE_STATE, false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
