import { findLastIndex } from 'lodash'
import { DatasetLayerMixin } from '@/components/visualization/map/mixins/dataset-layer.mixin'
import { BorderedGeometryLayer } from '@/types/visualization/layer'
import { RangeFillValueConfig, SubLayerConfig } from '@/types/visualization/layer/style'
import { Component } from 'vue-property-decorator'
import { DatasetColumnKey } from '@/types/common'
import { LayerGeometry } from '@/types/visualization/layer/geometry'

@Component
export default class RangeAwareMixin<TLayer extends BorderedGeometryLayer> extends DatasetLayerMixin<TLayer> {
  protected getRangeDataRows (col: DatasetColumnKey | undefined, style: SubLayerConfig, absoluteValues: RangeFillValueConfig[]): LayerGeometry[] {
    if (!col) {
      return []
    }

    const subLayers = style.subLayers ?? {}
    const hiddenSubLayers = new Set()

    Object.keys(subLayers).forEach(sublayer => {
      if (!(subLayers[sublayer].visible ?? true)) {
        hiddenSubLayers.add(sublayer)
      }
    })

    return this.dataRows.filter(row => {
      const rowIndex = RangeAwareMixin.calculateRangeIndex(row.meta[col] as number, absoluteValues)
      return !hiddenSubLayers.has(rowIndex.toString())
    })
  }

  protected calculateRangeColor (rangeValue: number, absoluteValues: RangeFillValueConfig[]): RangeFillValueConfig {
    const rowIndex = RangeAwareMixin.calculateRangeIndex(rangeValue, absoluteValues)
    return this.calculateRangeColorByIndex(rowIndex, absoluteValues)
  }

  protected calculateRangeColorByIndex (index: number, absoluteValues: RangeFillValueConfig[] = []): RangeFillValueConfig {
    return absoluteValues[index]
  }

  protected calculateQuantityOfValuesForRange (absoluteValues: RangeFillValueConfig[], rangeColumn: DatasetColumnKey | undefined): number[] {
    if (!rangeColumn) {
      return []
    }

    const quantityOfValues = new Array(absoluteValues.length).fill(0)

    this.dataRows.forEach(row => {
      const index = RangeAwareMixin.calculateRangeIndexToQuantile(row.meta[rangeColumn] as number, absoluteValues)
      quantityOfValues[index] += 1
    })

    return quantityOfValues
  }

  private static calculateRangeIndex (rangeValue: number, absoluteValues: RangeFillValueConfig[]): number {
    return findLastIndex(absoluteValues, item => item.from <= rangeValue)
  }

  private static calculateRangeIndexToQuantile (rangeValue: number, absoluteValues: RangeFillValueConfig[]): number {
    const rangesValues = absoluteValues.map(item => item.from)
    let index = rangesValues.length - 1

    while (index > 0) {
      if (rangeValue > rangesValues[index]) {
        break
      }
      index--
    }

    return index
  }
}
