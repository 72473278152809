import { AuthStoreState } from '@/store/auth/types/auth.store'

export const IS_AUTHENTICATED = 'auth/isAuthenticated'
export const AUTH_TOKEN = 'auth/token'
export const TEMP_TOKEN = 'auth/tempToken'

export const getters = {
  [IS_AUTHENTICATED] (state: AuthStoreState): boolean {
    return state.token !== null
  },
  [AUTH_TOKEN] (state: AuthStoreState): string | null {
    return state.token
  },
  [TEMP_TOKEN] (state: AuthStoreState): string | null {
    return state.tempToken
  }
}
