import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

// initial state
const state = () => ({
  steps: [],
  startIndex: null
})

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
