import QuantileFillMixin from '@/components/visualization/map/mixins/quantile-fill.mixin'
import { QuantileLegendMixin } from './quantile-legend-mixin'
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class QuantileFillLegendMixin<_TLayer> extends
  // @ts-ignore: TS2562 not relevant because we don't use a constructor https://github.com/microsoft/TypeScript/issues/26154#issuecomment-410848076
  Mixins<QuantileFillMixin<_TLayer>, QuantileLegendMixin<_TLayer>>(QuantileFillMixin, QuantileLegendMixin) {
  protected get quantileFillLegendValues () {
    const quantileColorGetter = (index) => ({
      color: this.calculateQuantileColorByIndex(index, this.quantileFillColors).color,
      opacity: this.calculateQuantileColorByIndex(index, this.quantileFillColors).opacity
    })

    return this.makeQuantileLegendValues(
      this.quantileFillValues,
      quantileColorGetter,
      this.maxQuantileFillValue(this.layer.style.fill.gradientColumn)
    )
  }
}
