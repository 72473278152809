import { Vue, Component, Prop } from 'vue-property-decorator'

type LegendValueFormatter<T = number> = (value: T) => T

@Component
export class LegendFormatMixin extends Vue {
  @Prop({ default: null }) readonly legendValueFormatter!: LegendValueFormatter

  formatLegendValue (value: number) {
    return this.legendValueFormatter ? this.legendValueFormatter(value) : value
  }
}
