






import { Component, Mixins } from 'vue-property-decorator'
import { GridChildMixin } from '@/components/shared/ag-grid/mixins/grid-child.mixin'
import { SelectionChangedEvent } from 'ag-grid-community'

@Component
export default class SelectorStatusBar extends Mixins<GridChildMixin>(GridChildMixin) {
  private amountOfSelectedRows = 0

  mounted () {
    this.onGridApi('selectionChanged', (event: SelectionChangedEvent) => {
      // @ts-ignore use this private property to faster get the amount of selected rows
      this.amountOfSelectedRows = Object.values(event.api.selectionController.selectedNodes).filter(item => item).length
    })
  }
}
