











import { latLng } from 'leaflet'
import { Component } from 'vue-property-decorator'
import GridActionsMixin from '../grid-actions.mixin'
import { find, difference, intersectionBy } from 'lodash'
import { NamedPoints } from '@/types/common'

const createNameGeneratorFunc = () => {
  let index = 1
  const isNameUniq = (existingLocations, name) =>
    intersectionBy(existingLocations, [{ name }], 'name').length === 0

  return function generateUniqName (existingLocations) {
    const nameTemplate = `Picked location ${index}`

    if (isNameUniq(existingLocations, nameTemplate)) {
      return nameTemplate
    }

    index++
    return generateUniqName(existingLocations)
  }
}

@Component
export default class PickLocationAction extends GridActionsMixin {
  private async pickLocations (): Promise<void> {
    const latlngs = this.value.filter(item => (item.latitude && item.longitude)).map(p => latLng(p.latitude, p.longitude))

    const { submitted, locations } = await this.$pickLocations(latlngs)
    const pointsWasUpdated = difference(latlngs, locations).length > 0 || difference(locations, latlngs).length > 0

    if (submitted && pointsWasUpdated) {
      const generateLocationName = createNameGeneratorFunc()

      let newPoints: NamedPoints = locations.reduce((result: NamedPoints, location) => {
        const existingLocation = find(this.value, item => location.equals(latLng(item.latitude, item.longitude)))

        result.push({
          name: existingLocation ? existingLocation.name : generateLocationName(result),
          latitude: location.lat,
          longitude: location.lng
        })

        return result
      }, [])

      if (latlngs.length !== this.value.length) {
        const lostEmptyPoints = this.value.length - latlngs.length
        const emptyPoints = Array(lostEmptyPoints).fill({ name: null, latitude: null, longitude: null })

        newPoints = newPoints.concat(emptyPoints)
      }

      this.$emit('update', newPoints)
    }
  }
}
