import { getters } from '@/store/orders/getters'
import { actions } from '@/store/orders/actions'
import { mutations } from '@/store/orders/mutations'

// initial state
const state = {
  orders: [],
  cachedOrders: [],
  draggedOrder: null,
  initialized: false,
  activeOrder: null,
  activeItems: null,
  detailsVisible: false,
  itemsVisible: false
}

export default {
  state,
  getters,
  actions,
  mutations
}
