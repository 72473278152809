import { parse as parseJson } from 'json5'
import { isEmpty } from 'lodash'
import { ApiResponse } from '@/api/types'
import { PlanObject } from '@/types/planning'
import { transformTimeFrameEndResponse } from '@/store/planning-wizard/utils'
import { parseNodesAndTransitions } from '@/utils/planning/parse-planning/parse-nodes'
import { processScoringFunc } from '@/utils/planning/parse-planning/process-scoring-func'
import { createTransformResponse } from '@/api/utils'

export const transformSinglePlanResponse = createTransformResponse<PlanObject | ApiResponse['data']>((response: ApiResponse['data']): PlanObject | ApiResponse['data'] => {
  if (isEmpty(response)) {
    return response
  }

  let plan = parseJson(response)

  parseNodesAndTransitions(plan)

  if (plan.metaData.planningTimeFrames && plan.metaData.planningTimeFrames.length > 0) {
    plan.metaData.planningTimeFrames[0].end = transformTimeFrameEndResponse(plan.metaData.planningTimeFrames[0].end).toDate()
  }

  plan.metaData.tableViews = plan.metaData.tableViews ? JSON.parse(plan.metaData.tableViews) : []

  plan = processScoringFunc(plan)

  return plan
})
