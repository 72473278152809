import jsVariables from '@/assets/styles/js-variables.module.scss'

export const animationDuration = parseInt(jsVariables.animationDuration)
export const slidesMargin = jsVariables.slidesMargin
export const slidesAnimationDuration = parseInt(jsVariables.slidesAnimationDuration)

export const mapLabelFont = jsVariables.mapLabelFont
export const mapLabelLineHeight = parseFloat(jsVariables.mapLabelLineHeight)
export const mapClusterFont = jsVariables.mapClusterFont

export const planMarkerColorAvailable = jsVariables.planMarkerColorAvailable
export const planMarkerColorAvailablePartially = jsVariables.planMarkerColorAvailablePartially
export const planMarkerColorNotAvailable = jsVariables.planMarkerColorNotAvailable
export const planMarkerColorUnknown = jsVariables.planMarkerColorUnknown

export const planMarkerOpacity = parseFloat(jsVariables.planMarkerOpacity)
export const planMarkerSize = parseInt(jsVariables.planMarkerSize)
export const planNetworkMarkerSize = parseInt(jsVariables.planNetworkMarkerSize)
export const planMarkerBorderWidth = parseInt(jsVariables.planMarkerBorderWidth)
export const planMarkerSelectedSize = parseInt(jsVariables.planMarkerSelectedSize)
export const planMarkerBorderColor = jsVariables.planMarkerBorderColor
export const planMarkerSelectedBorderWidth = parseInt(jsVariables.planMarkerSelectedBorderWidth)
export const planMarkerSelectedBorderColor = jsVariables.planMarkerSelectedBorderColor

export const pickerLocationOldColor = jsVariables.gray900
export const pickerLocationNewColor = jsVariables.green

export const surroundingGeometryFillColor = jsVariables.surroundingGeometryFillColor
export const surroundingGeometryPathColor = jsVariables.surroundingGeometryPathColor

export const geocodeSuccessfullyColor = jsVariables.geocodeSuccessfully
export const geocodeUnsuccessfullyColor = jsVariables.geocodeUnsuccessfully

export const bookedUnitsColor = jsVariables.bookedUnitsColor

export const sidebarBtnOffset = parseInt(jsVariables.sidebarBtnOffset)
