import { Component, Vue, Prop, Model, Watch, Ref } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'

@Component
export class ModalMixin extends Vue {
  @Model('change', { type: Boolean }) readonly visible!: boolean
  @Prop({ default: 'lg' }) readonly size!: string | null
  @Prop({ default: false }) readonly static!: boolean

  @Ref('modal') readonly modalRef!: BModal

  private isVisible = false
  protected modalEventName: string | null = null

  protected get showModal (): boolean {
    return this.isVisible
  }

  protected set showModal (value: boolean) {
    if (this.isVisible === value) {
      return
    }

    this.isVisible = value
    this.$emit('change', value)
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange (newVal: boolean, oldVal: boolean): void {
    if (newVal === oldVal) {
      return
    }

    this.showModal = newVal
  }

  created (): void {
    if (this.modalEventName) {
      this.$root.$on(this.modalEventName, this.openModal)
    }
  }

  mounted (): void {
    this.proxyModalEvents()
  }

  destroyed (): void {
    if (this.modalEventName) {
      this.$root.$off(this.modalEventName, this.openModal)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openModal (..._data): void {
    this.showModal = true
  }

  closeModal (): Promise<void> {
    return this.makeCloseModalPromise()
  }

  protected makeCloseModalPromise (): Promise<void> {
    return new Promise(resolve => {
      if (!this.showModal) {
        return resolve()
      }

      const modalRef = this.modalRef

      // If modal ref is not set just resolve promise immediately and return.
      if (!modalRef) {
        this.showModal = false
        return resolve()
      }

      // If modal ref exists resolve promise after modal window is really closed.
      // We use 'hidden' event for this purpose because it is emitted
      // after fade animation is completed.
      modalRef.$once('hidden', () => {
        resolve()
      })

      this.showModal = false
    })
  }

  private proxyModalEvents (): void {
    const modalRef = this.modalRef

    if (!modalRef) {
      return
    }
    const modalEvents = ['show', 'shown', 'hide', 'hidden', 'ok', 'cancel', 'close']

    for (const event of modalEvents) {
      modalRef.$on(event, (...args) => this.$emit(event, ...args))
    }
  }
}
