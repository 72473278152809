






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { StaticLayerPosition } from '@/types/visualization/layer/enums'
import { Control, Map } from 'leaflet'
import { propsBinder } from 'vue2-leaflet'
import { PropsDefinition } from 'vue/types/options'
import { SearchControl } from '@/lib/leaflet/control/search-control/search-control'

type MapSearchControl = Control & {
  setOptions: (options) => void
}

@Component
export default class LSearchControl extends Vue {
  @Prop({ default: StaticLayerPosition.TopLeft }) private readonly position!: StaticLayerPosition
  @Prop({ default: 'Search...' }) private readonly placeholder!: string
  @Prop({ default: 'Search' }) private readonly title!: string
  @Prop({ default: () => null }) private readonly searchCallback!: unknown
  @Prop({ default: () => null }) private readonly uploadCoordinatesCallback!: unknown

  ready = false
  mapObject!: MapSearchControl

  @Watch('placeholder')
  onPlaceholderChange (value) {
    this.mapObject.setOptions({ placeholder: value })
  }

  mounted () {
    this.mapObject = new SearchControl(this.$props)
    propsBinder(this, this.mapObject as unknown as Map, this.$options.props as PropsDefinition<Vue>)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.mapObject.addTo(this.$parent.mapObject)
    this.ready = true
  }
}
