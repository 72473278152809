import { isString, merge } from 'lodash'
import { latLng } from 'leaflet'
import { ApiDataColumnType } from '@/types/enums'
import { convertDeprecatedQuery } from '@/components/shared/query-builder/filter-func/deprecated-adapter'

export function fixNumberColumns (dataRows, columnTypes) {
  for (let colIdx = columnTypes.length - 1; colIdx >= 0; colIdx--) {
    if (columnTypes[colIdx].type === ApiDataColumnType.Number) {
      const colName = columnTypes[colIdx].name

      for (let rowIdx = dataRows.length - 1; rowIdx >= 0; rowIdx--) {
        if (isString(dataRows[rowIdx][colName])) {
          dataRows[rowIdx][colName] = +(dataRows[rowIdx][colName].replace(',', '.'))
        }
      }
    }
  }
}

export function getLayerTagsInfo (data, dataColumnIndex = -1, pathColumnIndex = -1) {
  const tags = {
    geometry: pathColumnIndex,
    dataset: dataColumnIndex
  }

  Object.entries(tags).forEach(([key, value]) => {
    const tagsArray = value !== -1 ? data[value].tags : []

    // Converts array [ { x: key1, y: value1}, { x: key2, y: value2}, ... ] to object { key1: value1, key2: value2, ... }
    tags[key] = Object.fromEntries((tagsArray || []).map(tag => Object.values(tag)))
  })

  return { tags }
}

export function getPathCoordinates ({ coordinates }) {
  const paths = []

  const len = coordinates.length

  for (let pc = 0; pc < len; pc++) {
    if (!Array.isArray(coordinates[pc])) {
      paths.push(latLng(coordinates[pc].latitude, coordinates[pc].longitude))
    } else {
      // Polygon can have holes inside so coordinates is an array of 1+ arrays where
      // 0: outer path - array of points that form polygon outer bounds
      // 1..n: inner paths - array of points that form hole bounds
      paths.push(coordinates[pc].map(point => latLng(point.latitude, point.longitude)))
    }
  }

  return paths
}

function parseJsonValue (value) {
  if (value == null || JSON.parse(value) == null) {
    return {}
  }

  if (typeof value === 'object') {
    return value
  }

  return typeof value === 'string' ? JSON.parse(value) : {}
}

export function parseLayerStyle (value, ...defaultStyles) {
  return merge({}, ...defaultStyles, parseJsonValue(value))
}

export function parseLayerSettings (value, ...defaultSettings) {
  const settings = parseJsonValue(value)

  if (settings.query) {
    settings.query = convertDeprecatedQuery(settings.query)
  }

  return merge({}, ...defaultSettings, settings)
}
