import { Bounds, LineUtil, Point, point, PolyUtil } from 'leaflet'
import polylabel from '@mapbox/polylabel'
import { ProjectedShape } from '@/lib/leaflet/layer/projected-shape'

const LBL_MIN_POLYGON_WIDTH = 50
const LBL_MIN_POLYGON_HEIGHT = 20

export default class ProjectedPolygon implements ProjectedShape {
  public rings: Point[][]
  public readonly index: number
  public bounds!: Bounds

  private _labelPos: Point | null | undefined

  constructor (rings, index) {
    this.rings = rings
    this.index = index
    this.initBounds()
  }

  initBounds (): void {
    // @ts-ignore Leaflet types are incorrect here, constructor accepts zero arguments.
    this.bounds = new Bounds()

    const len = this.rings.length
    let i, j, ringLength

    for (i = 0; i < len; i++) {
      ringLength = this.rings[i].length

      for (j = 0; j < ringLength; j++) {
        this.bounds.extend(this.rings[i][j])
      }
    }
  }

  public clip (bounds: Bounds): ProjectedPolygon | null {
    const len = this.rings.length
    let i
    const rings: Point[][] = []

    for (i = 0; i < len; i++) {
      const clipped = PolyUtil.clipPolygon(this.rings[i], bounds, true)

      if (clipped.length) {
        rings.push(clipped)
      }
    }

    if (rings.length) {
      return new ProjectedPolygon(rings, this.index)
    }

    return null
  }

  simplify (tolerance: number): void {
    const len = this.rings.length

    for (let i = 0; i < len; i++) {
      this.rings[i] = LineUtil.simplify(this.rings[i], tolerance)

      while (this.rings[i].length > 1 && this.rings[i][0].equals(this.rings[i][this.rings[i].length - 1])) {
        this.rings[i].pop()
      }
    }
  }

  containsPoint (p: Point, tolerance: number): boolean {
    if (!this.bounds.contains(p)) {
      return false
    }

    let inside = false

    // ray casting algorithm for detecting if point is in polygon
    for (let i = 0, len = this.rings.length; i < len; i++) {
      const ring = this.rings[i]

      for (let j = 0, len2 = ring.length, k = len2 - 1; j < len2; k = j++) {
        const p1 = ring[j]
        const p2 = ring[k]

        if (((p1.y > p.y) !== (p2.y > p.y)) && (p.x < (p2.x - p1.x) * (p.y - p1.y) / (p2.y - p1.y) + p1.x)) {
          inside = !inside
        }
      }
    }

    // also check if it's on polygon stroke
    return inside || this._pointOnBorder(p, tolerance)
  }

  private _pointOnBorder (p: Point, tolerance: number): boolean {
    // hit detection for polylines
    for (let i = 0, len = this.rings.length; i < len; i++) {
      const ring = this.rings[i]

      for (let j = 0, len2 = ring.length, k = len2 - 1; j < len2; k = j++) {
        if (LineUtil.pointToSegmentDistance(p, ring[k], ring[j]) <= tolerance) {
          return true
        }
      }
    }

    return false
  }

  public ringsToArray (closed = false): number[][][] {
    const ringsLength = this.rings.length
    const result: number[][][] = []

    for (let i = 0; i < ringsLength; i++) {
      const ring = this.rings[i]
      const pointsLength = ring.length

      result[i] = []

      for (let j = 0; j < pointsLength; j++) {
        result[i][j] = [ring[j].x, ring[j].y]
      }

      if (closed && result[i][0][0] !== result[i][pointsLength - 1][0] && result[i][0][1] !== result[i][pointsLength - 1][1]) {
        result[i].push(result[i][0].slice())
      }
    }

    return result
  }

  public getLabelPos (ignoreSize = false): Point | null {
    if (this._labelPos !== undefined) {
      return this._labelPos
    }

    // @ts-ignore Leaflet types are incorrect here, isValid() does exist in Bounds type
    if (this.rings.length === 0 || !this.bounds.isValid()) {
      return (this._labelPos = null)
    }

    const size = this.bounds.getSize()

    // If polygon is very small let's assume that we can't place a label inside.
    if (!ignoreSize && (size.x < LBL_MIN_POLYGON_WIDTH || size.y < LBL_MIN_POLYGON_HEIGHT)) {
      return (this._labelPos = null)
    }

    // Polylabel library expects coordinates in array [x, y] instead of Point object
    // so here we prepare correct format to pass points to polylabel.
    const paths: number[][][] = []
    const ringsLength = this.rings.length

    for (let i = 0; i < ringsLength; i++) {
      const ring = this.rings[i]
      const pointsLength = ring.length

      paths[i] = []

      for (let j = 0; j < pointsLength; j++) {
        paths[i][j] = [ring[j].x, ring[j].y]
      }
    }

    const center = polylabel(paths, 1.0)

    return (this._labelPos = point(center))
  }
}
