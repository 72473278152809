import { Bounds, LatLng, LineUtil, Point, Polyline, PolylineOptions, setOptions } from 'leaflet'
import { ShapesLayer } from '@/lib/leaflet/layer/shapes-layer'
import { ContextMenuCallbackEvent } from '@/types/leaflet'
import { CustomMapRenderer } from '@/lib/leaflet/renderer'
import { ZIndexedPathOptions } from '@/lib/leaflet/layer/z-indexed-layer'

export interface MultiPolylineOptions extends PolylineOptions, ZIndexedPathOptions {}

export interface MultiPolyline extends Polyline {
  options: MultiPolylineOptions,
  contextmenuItems?: { disabled: boolean }[]
}

export class MultiPolyline extends Polyline {
  private _renderer!: CustomMapRenderer

  private _parts!: Point[][]
  private _rings!: Point[][]
  private _ringsPartsMap!: Point[]
  private _pxBounds!: Bounds

  private _clickTolerance!: () => number

  initialize (latlngs: LatLng[], options: PolylineOptions): void {
    const mergedOptions: MultiPolylineOptions = {
      zIndex: 1,
      ...options
    }

    setOptions(this, mergedOptions)
    // @ts-ignore - Polyline has method _setLatLngs
    this._setLatLngs(latlngs)
  }

  _showContextMenu (e: ContextMenuCallbackEvent) {
    ShapesLayer.prototype._showContextMenu.call(this, e)
  }

  setContextMenuItemDisabled (index: number, isDisabled: boolean): void {
    if (Array.isArray(this.options?.contextmenuItems) && this.options?.contextmenuItems[index]) {
      this.options.contextmenuItems[index].disabled = isDisabled
    }
  }

  _clipPoints () {
    const bounds = this._renderer._bounds

    this._parts = []
    this._ringsPartsMap = []

    if (!this._pxBounds || !this._pxBounds.intersects(bounds)) {
      return
    }

    if (this.options.noClip) {
      this._parts = this._rings
      return
    }

    const parts = this._parts
    let i; let j; let k; let len; let len2; let segment; let points

    for (i = 0, k = 0, len = this._rings.length; i < len; i++) {
      points = this._rings[i]

      for (j = 0, len2 = points.length; j < len2 - 1; j++) {
        segment = LineUtil.clipSegment(points[j], points[j + 1], bounds, j, true) as [Point, Point] | undefined

        if (!segment) {
          continue
        }

        parts[k] = parts[k] || []
        parts[k].push(segment[0])

        this._ringsPartsMap[k] = i

        // if segment goes out of screen, or it's the last one, it's the end of the line part
        if ((segment[1] !== points[j + 1]) || (j === len2 - 2)) {
          parts[k].push(segment[1])
          k++
        }
      }
    }
  }

  _containsPoint (p, closed) {
    return this.containsPointIndex(p, closed) !== -1
  }

  protected containsPointIndex (p, closed) {
    const w = this._clickTolerance()

    if (!this._pxBounds || !this._pxBounds.contains(p)) {
      return -1
    }

    // hit detection for polylines
    for (let i = 0, len = this._parts.length; i < len; i++) {
      const part = this._parts[i]

      for (let j = 0, len2 = part.length, k = len2 - 1; j < len2; k = j++) {
        if (!closed && (j === 0)) {
          continue
        }

        if (LineUtil.pointToSegmentDistance(p, part[k], part[j]) <= w) {
          return this._ringsPartsMap[i]
        }
      }
    }

    return -1
  }
}
